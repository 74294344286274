import * as React from 'react'
export const SvgCircularInstagram = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <g fill="none">
      <circle fill="#ED0375" cx={20} cy={20} r={20} />
      <path
        d="M19.997 10.006c-2.715 0-3.056.012-4.122.06-1.064.049-1.79.218-2.426.465a4.9 4.9 0 00-1.771 1.153 4.9 4.9 0 00-1.153 1.77c-.247.637-.416 1.363-.465 2.427-.048 1.067-.06 1.407-.06 4.122s.012 3.056.06 4.122c.049 1.064.218 1.79.465 2.426a4.9 4.9 0 001.153 1.771 4.9 4.9 0 001.77 1.153c.636.247 1.363.416 2.427.465 1.066.048 1.407.06 4.122.06s3.055-.012 4.122-.06c1.064-.049 1.79-.218 2.426-.465a4.9 4.9 0 001.77-1.153 4.9 4.9 0 001.154-1.77c.247-.636.416-1.363.465-2.427.048-1.066.06-1.407.06-4.122s-.012-3.055-.06-4.122c-.049-1.064-.218-1.79-.465-2.426a4.9 4.9 0 00-1.153-1.77 4.9 4.9 0 00-1.77-1.154c-.637-.247-1.363-.416-2.427-.465-1.067-.048-1.407-.06-4.122-.06zm0 1.802c2.67 0 2.985.01 4.04.058.974.044 1.503.207 1.856.344.466.181.8.398 1.15.748.349.35.566.683.747 1.15.137.352.3.88.344 1.856.048 1.054.058 1.37.058 4.04 0 2.668-.01 2.985-.058 4.039-.044.974-.207 1.504-.344 1.856-.181.467-.398.8-.748 1.15-.35.35-.683.566-1.15.747-.352.137-.88.3-1.856.344-1.054.049-1.37.059-4.04.059-2.669 0-2.985-.01-4.039-.059-.974-.044-1.504-.207-1.856-.344a3.097 3.097 0 01-1.15-.748 3.097 3.097 0 01-.747-1.149c-.137-.352-.3-.882-.344-1.856-.049-1.054-.059-1.37-.059-4.04s.01-2.985.059-4.04c.044-.974.207-1.503.344-1.856.181-.466.398-.8.748-1.15.35-.349.682-.566 1.149-.747.352-.137.882-.3 1.856-.344 1.054-.048 1.37-.058 4.04-.058z"
        fill="#FFF"
      />
      <path
        d="M19.997 14.87a5.133 5.133 0 110 10.267 5.133 5.133 0 010-10.267z"
        fill="#FFF"
      />
      <path
        d="M19.997 23.335a3.332 3.332 0 110-6.664 3.332 3.332 0 010 6.664z"
        fill="#ED0375"
      />
      <path
        d="M25.333 13.467a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
        fill="#FFF"
      />
    </g>
  </svg>
)
export default SvgCircularInstagram
