import * as React from 'react'
import * as Cookies from 'js-cookie'

import { HeaderZIndexLevel } from '@thg-commerce/enterprise-components/Header/types'
import { useLogger } from '@thg-commerce/enterprise-core'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import {
  mq,
  spacing,
  styled,
  Text,
  zIndex,
} from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/gravity-elements'

import { useCustomer } from '../CustomerContext'
import { useLoginCheck } from '../EnterpriseContext'
import { i18n } from '../i18n/'
import { useWishlistNotificationContext } from '../WishlistNotificationProvider'

const StyledNotificationWrapper = styled.div`
  position: absolute;
  background: ${(props) => props.theme.colors.palette.greys.white};
  width: 272px;
  border: 1px solid ${(props) => props.theme.colors.palette.greys.grey};
  top: 44px;
  right: -16px;
  padding: 16px;
  ${zIndex(HeaderZIndexLevel.Dropdown)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 300px;
  }

  &:before {
    position: absolute;
    display: block;
    top: -8px;
    width: 16px;
    height: 16px;
    left: auto;
    right: 29px;
    background: ${(props) => props.theme.colors.palette.greys.white};
    content: '';
    transform: rotate(45deg);
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.palette.greys.grey};
    border-right-color: transparent;
    border-bottom-color: transparent;

    ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
      right: 64px;
    }
  }
`

const WishlistNotificationWelcome = styled.div`
  ${Text('bodyText', 'alternate')};
`

const WishlistNotificationSubtext = styled.div`
  ${Text('bodyText', 'default')};
  padding-top: ${spacing(2)};
`

const GotItButton = styled(Button)`
  flex: 1;
  margin-top: ${spacing(3)};
  width: 100%;
`

const WishListLink = styled(Button)`
  flex: 1;
  margin-top: ${spacing(2)};
  width: 100%;
`

const WISHLIST_SHOWN_COOKIE_NAME = 'wishlistsTooltipModalCookie'

export const WishlistNotification: React.FunctionComponent = () => {
  const customer = useCustomer()
  const isLoggedIn = useLoginCheck()
  const logger = useLogger()

  const { showWishlistNotification } = useWishlistNotificationContext()

  const [visible, setVisible] = React.useState(false)

  const wishlistCookie = Cookies.get(WISHLIST_SHOWN_COOKIE_NAME)

  React.useEffect(() => {
    if (!visible) {
      return
    }

    pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventCategory: 'Wishlist Engagement',
        eventAction: 'Wishlists Modal | Shown',
        eventLabel: 'Wishlists Modal Message',
      },
    })
  }, [visible])

  React.useEffect(() => {
    if (!visible) {
      return
    }

    Cookies.set(WISHLIST_SHOWN_COOKIE_NAME, WISHLIST_SHOWN_COOKIE_NAME, {
      expires: 7,
    })
  }, [visible])

  const i18nText = {
    welcome: i18n('wishlists.tooltip.modal.welcome.text'),
    message: i18n('wishlists.tooltip.modal.text.message'),
    gotit: i18n('wishlists.tooltip.modal.acknowledge.button'),
    explore: i18n('wishlists.tooltip.modal.explore.button'),
  }

  if (!showWishlistNotification) {
    logger.warn(
      'Failed to initialise WishlistNotification because no WishListNotificationProvider could be found',
    )
    return null
  }

  showWishlistNotification.current = () => {
    if (
      wishlistCookie ||
      !isLoggedIn ||
      customer.wishlist?.itemCount ||
      0 > 0
    ) {
      return
    }

    setVisible(true)
  }

  if (!visible) {
    return null
  }

  return (
    <StyledNotificationWrapper data-testid={'wishlist-notification'}>
      <WishlistNotificationWelcome data-testid="wishlist-notification-welcome">
        {i18nText.welcome}, {customer.firstName}
      </WishlistNotificationWelcome>
      <WishlistNotificationSubtext>
        {i18nText.message}
      </WishlistNotificationSubtext>
      <GotItButton
        emphasis="high"
        onClick={() => {
          setVisible(false)

          pushToDataLayer({
            type: 'elysiumEvent',
            eventData: {
              eventCategory: 'Wishlist Engagement',
              eventAction: 'Wishlists Modal | Close',
            },
          })
        }}
        data-testid="wishlist-notification-gotit"
      >
        {i18nText.gotit}
      </GotItButton>
      <WishListLink
        renderedAs={'a'}
        emphasis={'medium'}
        href={`/wishlists.account`}
        data-testid={'wishlist-notification-explore'}
        onClick={() =>
          pushToDataLayer({
            type: 'elysiumEvent',
            eventData: {
              eventCategory: 'Wishlist Engagement',
              eventAction: 'Wishlists Modal | Clicked explore button',
            },
          })
        }
      >
        {i18nText.explore}
      </WishListLink>
    </StyledNotificationWrapper>
  )
}

export default WishlistNotification
