import * as React from 'react'
export const SvgReferralsShop = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="&ns_svg;"
    xmlnsXlink="&ns_xlink;"
    width={29}
    height={29}
    viewBox="-1.355 0 29 29"
    overflow="visible"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <defs>
        <path
          id="XMLID_4_"
          d="M18.924 8.405V9.73c.349.201.582.568.582.998a1.161 1.161 0 0 1-2.322 0c0-.43.234-.797.582-.998V8.405H8.477V9.73c.347.201.581.568.581.998a1.161 1.161 0 0 1-2.322 0c0-.43.234-.797.582-.998V8.405H3.78L1.586 27.563h23.073L22.463 8.405h-3.539zm0-2.32v1.161h4.591l.055.508 2.322 20.313.069.651H.278l.073-.651L2.673 7.753l.054-.508h4.59v-1.16A5.796 5.796 0 0 1 13.102.28h.018a5.798 5.798 0 0 1 5.806 5.789l-.002.016zm-10.447 0v1.161h9.288V6.085a4.605 4.605 0 0 0-4.569-4.643h-.076a4.607 4.607 0 0 0-4.643 4.57v.073z"
        />
      </defs>
      <clipPath id="XMLID_6_">
        <use xlinkHref="#XMLID_4_" />
      </clipPath>
      <path clip-path="url(#XMLID_6_)" d="M0,0h26.245v29H0V0z" />
    </g>
  </svg>
)
export default SvgReferralsShop
