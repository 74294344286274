import * as React from 'react'

import { Dropdown, DropdownOption } from '@thg-commerce/gravity-elements'
import { styled, useTheme } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing } from '@thg-commerce/gravity-theme'

type CountryDomainList = [string, string][]

export interface CountrySelectorProps {
  brand: string
  domain: string
  i18nLabel: string
  countryDomainList?: CountryDomainList
  flagPath?: string
}

const Flag = (props: { flagPath: string }) => {
  const theme = useTheme()

  return (
    <img
      src={props.flagPath}
      alt="flag"
      style={{
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        border: `1px solid ${theme.colors.palette.greys.white}`,
        marginRight: spacing(1),
      }}
    />
  )
}

const CountryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${spacing(2)} 0;
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin: ${spacing(2)};
  }
`

const StyledDropdown = styled(Dropdown)`
  width: 100%;

  select {
    background: none;
  }
`

const getDropdownOptions = (
  brand: string,
  countryDomainList?: CountryDomainList,
) => {
  if (!countryDomainList) {
    return null
  }
  return countryDomainList.map((countryDomain) => {
    if (countryDomain.length < 2) {
      return null
    }

    return (
      <DropdownOption
        data-testid="country-select-option"
        key={countryDomain[0]}
        value={countryDomain[0]}
      >
        {`${brand.charAt(0).toUpperCase()}${brand.slice(1)} ${
          countryDomain[1]
        }`}
      </DropdownOption>
    )
  })
}

export const CountrySelector = (props: CountrySelectorProps) => {
  const theme = useTheme()

  const changeLocale = (event: React.ChangeEvent<HTMLSelectElement>) => {
    window.location.href = `https://${event.target.value}`
  }

  const dropDownStyleOverride = {
    textColor: theme.patterns.footer.navigation.countrySelectorColor,
    borderColor: theme.patterns.footer.navigation.countrySelectorColor,
    textSize: 'small',
  }

  return (
    <CountryContainer>
      {props.flagPath && <Flag flagPath={props.flagPath} />}
      <StyledDropdown
        data-testid="country selector-dropdown"
        defaultValue={props.domain}
        label={props.i18nLabel}
        labelHidden={true}
        onChange={changeLocale}
        ariaLabel={props.i18nLabel}
        styleOverride={dropDownStyleOverride}
      >
        {getDropdownOptions(props.brand, props.countryDomainList)}
      </StyledDropdown>
    </CountryContainer>
  )
}
