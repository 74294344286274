import * as React from 'react'
export const SvgChatOnlineCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    {...props}
    viewBox="0 0 16 16"
  >
    <circle fill="#007831" cx="8" cy="8" r="8" />
  </svg>
)
export default SvgChatOnlineCircle
