import * as React from 'react'
export const CopyrightThgIngenuity = (props: React.SVGProps<SVGSVGElement>) => {
  const zeroStrokeWidthStyle = {
    strokeWidth: '0px',
  }

  return (
    <svg
      viewBox="0 0 426.67 94.26"
      aria-label="Powered by THG Ingenuity"
      role="img"
      height="26px"
      {...props}
    >
      <defs></defs>
      <rect
        style={zeroStrokeWidthStyle}
        x="88.19"
        y="12.85"
        width="6.01"
        height="37.22"
      />
      <polygon
        style={zeroStrokeWidthStyle}
        points="143.21 12.85 143.21 50.06 137.79 50.06 117.03 23.14 117 50.06 110.99 50.06 110.99 12.85 116.42 12.85 137.2 39.78 137.2 12.85 143.21 12.85"
      />
      <path
        style={zeroStrokeWidthStyle}
        d="m174.29,12.16c2.94,0,5.65.52,8.13,1.57,2.48,1.05,4.57,2.52,6.27,4.44l-4.41,4.28c-1.19-1.42-2.64-2.52-4.36-3.32-1.72-.8-3.6-1.2-5.64-1.2-3.83,0-6.98,1.26-9.46,3.78-2.48,2.52-3.72,5.77-3.72,9.76s1.23,7.22,3.68,9.74c2.45,2.53,5.53,3.79,9.24,3.79,3.35,0,6.02-.83,8.01-2.5,1.99-1.67,3.23-4.05,3.71-7.15h-9.86v-5.58h16.38c.04.64.05,1.12.05,1.43,0,2.68-.42,5.2-1.26,7.56-.84,2.37-2.02,4.44-3.55,6.21-1.52,1.77-3.45,3.18-5.78,4.21-2.33,1.04-4.89,1.55-7.69,1.55-1.93,0-3.82-.27-5.68-.8-1.85-.53-3.6-1.34-5.24-2.42-1.64-1.08-3.08-2.38-4.31-3.89-1.23-1.52-2.21-3.32-2.92-5.41-.72-2.09-1.08-4.34-1.08-6.75,0-2.76.49-5.34,1.46-7.74.98-2.39,2.32-4.44,4.03-6.13,1.71-1.69,3.78-3.02,6.19-3.99,2.42-.97,5.02-1.45,7.8-1.45"
      />
      <polygon
        style={zeroStrokeWidthStyle}
        points="231.73 44.43 231.73 50.06 204.59 50.06 204.59 12.85 231.39 12.85 231.39 18.48 210.6 18.48 210.6 28.51 230.67 28.51 230.67 34.14 210.6 34.14 210.6 44.43 231.73 44.43"
      />
      <polygon
        style={zeroStrokeWidthStyle}
        points="277.4 12.85 277.4 50.06 271.98 50.06 251.22 23.14 251.19 50.06 245.18 50.06 245.18 12.85 250.61 12.85 271.4 39.78 271.4 12.85 277.4 12.85"
      />
      <path
        style={zeroStrokeWidthStyle}
        d="m322.3,12.85v22.54c0,2.52-.41,4.76-1.22,6.74-.82,1.98-1.94,3.58-3.38,4.82-1.44,1.24-3.1,2.18-5,2.82-1.9.64-3.96.96-6.19.96s-4.3-.32-6.19-.96c-1.9-.64-3.56-1.58-5-2.82-1.43-1.24-2.56-2.85-3.38-4.82-.82-1.98-1.22-4.22-1.22-6.74V12.85h6.01v22.09c0,3.4.87,5.93,2.62,7.56,1.74,1.64,4.13,2.46,7.16,2.46s5.42-.82,7.17-2.46c1.74-1.64,2.62-4.16,2.62-7.56V12.85h6.01Z"
      />
      <rect
        style={zeroStrokeWidthStyle}
        x="335.67"
        y="12.85"
        width="6.01"
        height="37.22"
      />
      <polygon
        style={zeroStrokeWidthStyle}
        points="384.48 12.85 384.48 18.54 372.44 18.54 372.44 50.06 366.43 50.06 366.43 18.54 354.39 18.54 354.39 12.85 384.48 12.85"
      />
      <polygon
        style={zeroStrokeWidthStyle}
        points="419.68 12.85 409.33 29.52 399.02 12.85 391.84 12.85 406.3 34.86 406.3 50.06 412.31 50.06 412.31 34.97 426.67 12.85 419.68 12.85"
      />
      <path
        style={zeroStrokeWidthStyle}
        d="m342.06,4.59c0,1.87-1.51,3.38-3.38,3.38s-3.38-1.51-3.38-3.38,1.51-3.38,3.38-3.38,3.38,1.51,3.38,3.38"
      />
      <path
        style={zeroStrokeWidthStyle}
        d="m94.57,58.29c0,1.87-1.51,3.38-3.38,3.38s-3.38-1.51-3.38-3.38,1.51-3.38,3.38-3.38,3.38,1.51,3.38,3.38"
      />
      <path
        style={zeroStrokeWidthStyle}
        d="m237.23,77.6c2.09,0,3.95.96,5.1,2.63l.12.17,2.89-1.93-.11-.17c-1.51-2.5-4.58-4.05-8-4.05-5.85,0-10.1,4.21-10.1,10s4.25,10,10.1,10c3.29,0,6.25-1.46,7.92-3.91l.12-.17-2.82-2.07-.12.18c-1.17,1.69-2.97,2.62-5.08,2.62-3.81,0-6.48-2.74-6.48-6.65s2.66-6.65,6.48-6.65"
      />
      <path
        style={zeroStrokeWidthStyle}
        d="m268.15,84.25c0,3.92-2.66,6.65-6.48,6.65s-6.48-2.74-6.48-6.65,2.66-6.65,6.48-6.65,6.48,2.73,6.48,6.65m-6.48-10c-5.85,0-10.1,4.21-10.1,10s4.25,10,10.1,10,10.1-4.21,10.1-10-4.25-10-10.1-10"
      />
      <polygon
        style={zeroStrokeWidthStyle}
        points="295.22 74.6 289.87 87.73 284.52 74.6 279.49 74.6 279.49 93.9 282.96 93.9 282.96 79.39 288.12 92.06 291.62 92.06 296.78 79.25 296.78 93.9 300.25 93.9 300.25 74.6 295.22 74.6"
      />
      <polygon
        style={zeroStrokeWidthStyle}
        points="324.72 74.6 319.38 87.73 314.03 74.6 309 74.6 309 93.9 312.47 93.9 312.47 79.39 317.63 92.06 321.13 92.06 326.29 79.25 326.29 93.9 329.76 93.9 329.76 74.6 324.72 74.6"
      />
      <polygon
        style={zeroStrokeWidthStyle}
        points="338.5 74.6 338.5 93.9 352.7 93.9 352.7 90.62 341.98 90.62 341.98 85.83 352.16 85.83 352.16 82.54 341.98 82.54 341.98 77.88 352.53 77.88 352.53 74.6 338.5 74.6"
      />
      <path
        style={zeroStrokeWidthStyle}
        d="m372.22,80.27c0,1.7-1.08,2.5-3.39,2.5h-4.43v-4.89h4.43c2.31,0,3.39.76,3.39,2.39m3.6,0c0-3.66-2.45-5.67-6.9-5.67h-7.98v19.31h3.47v-7.94h1.22l6.02,7.94h4.32l-6.23-7.97c3.72-.27,6.09-2.47,6.09-5.67"
      />
      <path
        style={zeroStrokeWidthStyle}
        d="m392.55,77.6c2.09,0,3.95.96,5.1,2.63l.12.17,2.89-1.93-.11-.17c-1.51-2.5-4.58-4.05-8-4.05-5.85,0-10.1,4.21-10.1,10s4.25,10,10.1,10c3.29,0,6.25-1.46,7.92-3.91l.12-.17-2.82-2.07-.12.18c-1.17,1.69-2.97,2.62-5.08,2.62-3.81,0-6.48-2.74-6.48-6.65s2.66-6.65,6.48-6.65"
      />
      <polygon
        style={zeroStrokeWidthStyle}
        points="408.19 74.6 408.19 93.9 422.38 93.9 422.38 90.62 411.66 90.62 411.66 85.83 421.84 85.83 421.84 82.54 411.66 82.54 411.66 77.88 422.21 77.88 422.21 74.6 408.19 74.6"
      />
      <path
        style={zeroStrokeWidthStyle}
        d="m39.71,21.51c-1.62,0-2.93-1.31-2.93-2.93s1.31-2.93,2.93-2.93,2.93,1.31,2.93,2.93-1.31,2.93-2.93,2.93m2.61,26.42h-5.21v-22.21h5.21v22.21Zm-18.46,0c-1.62,0-2.93-1.31-2.93-2.93s1.31-2.93,2.93-2.93,2.93,1.31,2.93,2.93-1.31,2.93-2.93,2.93m-2.61-32.28h5.21v22.21h-5.21V15.65ZM56.92,6.66C48.03-2.22,15.55-2.22,6.66,6.66-2.22,15.55-2.22,48.03,6.66,56.91c8.88,8.88,41.37,8.88,50.25,0,8.88-8.88,8.88-41.37,0-50.25"
      />
    </svg>
  )
}
export default CopyrightThgIngenuity
