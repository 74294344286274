import * as React from 'react'
export const SvgContentPinterest = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <path
      fill={props.fill || '#333'}
      d="M15.667 29.939c-.169-1.845-.16-3.17.028-3.977.242-1.039 1.56-6.62 1.56-6.62s-.397-.798-.397-1.978c0-1.851 1.072-3.234 2.408-3.234 1.137 0 1.685.853 1.685 1.876 0 1.142-.728 2.85-1.103 4.435-.313 1.326.665 2.407 1.97 2.407 2.366 0 4.186-2.496 4.186-6.1 0-3.19-2.291-5.419-5.561-5.419-3.787 0-6.01 2.842-6.01 5.78 0 1.145.44 2.372.991 3.04.096.102.13.247.093.382-.102.422-.327 1.326-.37 1.511-.06.243-.193.296-.446.177C13.038 21.446 12 19.014 12 17.06 12 12.858 15.05 9 20.794 9 25.41 9 29 12.293 29 16.692c0 4.59-2.891 8.286-6.907 8.286-1.35 0-2.616-.703-3.051-1.531 0 0-.667 2.544-.829 3.167-.21.805-1.005 2.268-2.386 4.386l-.16-1.061z"
    />
  </svg>
)
export default SvgContentPinterest
