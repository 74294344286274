import * as React from 'react'
export const SvgReturns = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M4.455 5.437L5.964 6.75A7.964 7.964 0 004 12a8 8 0 008 8v-2l4 3-4 3v-2C6.477 22 2 17.523 2 12c0-2.445.882-4.756 2.455-6.563zM12 2c5.523 0 10 4.477 10 10a9.964 9.964 0 01-2.455 6.563l-1.509-1.313A7.964 7.964 0 0020 12a8 8 0 00-8-8V2zm0-2v6L8 3l4-3z"
      fill={props.fill || '#333'}
      fillRule="nonzero"
    />
  </svg>
)
export default SvgReturns
