import * as React from 'react'
export const SvgCircularYoutube = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <g fill="none">
      <circle cx={20} cy={20} r={20} fill="#FF0402" />
      <path
        fill="#FFF"
        d="M14 13h12a4 4 0 014 4v6a4 4 0 01-4 4H14a4 4 0 01-4-4v-6a4 4 0 014-4z"
      />
      <path fill="#FF0402" d="M24.092 19.875l-6.4 3.76v-7.52l6.4 3.76" />
    </g>
  </svg>
)
export default SvgCircularYoutube
