import * as React from 'react'
import { Property } from 'csstype'

import { Modal, ModalProps, SafeHtml } from '@thg-commerce/gravity-elements'
import { MarketedSpecialOffer } from '@thg-commerce/gravity-patterns/MarketedSpecialOfferSummary'
import { styled, Text } from '@thg-commerce/gravity-theme'
import { Padding } from '@thg-commerce/gravity-theme/padding'
import { Margin } from '@thg-commerce/gravity-theme/margin'

import { IconStyling } from '../../Header/theme'
import { Font } from '../../index'

const StyledSafeHtml = styled(SafeHtml)<{ textStyle?: Font }>`
  ${(props) =>
    props.textStyle && Text(props.textStyle?.entry, props.textStyle?.style)};
  color: ${(props) => props.theme.colors.system.pap.title};

  > b {
    ${(props) =>
      props.textStyle && Text(props.textStyle?.entry, props.textStyle?.style)};
  }
`

export interface MarketedSpecialOfferModalProps {
  title: string
  description?: string
  cta?: {
    text: string
    href: string
    openExternally: boolean
    noFollow: boolean
  }
  freeGiftProduct?: { images; title; price }
  i18nText: {
    closeI18nText: ModalProps['i18nText']
    freeGiftMessage?: string
  }
  onClose: () => void
  open: boolean
  shouldRemoveScrollLock?: boolean
  badgeStyle: {
    icon?: IconStyling
    textStyle: Font
    padding: Padding
    margin: Margin
  }
  maxWidth?: string
  margin?: Margin
  textAlign?: string
  modalStyle?: {
    display?: string
    direction?: string
    alignment?: string
    maxWidth?: string
    margin?: Margin
    textStyle?: Font
    textAlign?: string
    textColor?: Property.Color
  }
}

export const MarketedSpecialOfferModal = (
  props: MarketedSpecialOfferModalProps,
) => {
  const { title, ...specialOfferProps } = props

  return (
    <Modal
      i18nText={props.i18nText.closeI18nText}
      open={props.open}
      gridColSpan={[12, 12, 10, 6]}
      onClose={props.onClose}
      data-testid="marketed-special-offer-modal"
      verticalAlignment="center"
      showHeader={true}
      stickyHeader={true}
      shouldRemoveScrollLock={props.shouldRemoveScrollLock}
      maxWidth={props.maxWidth}
      margin={props.margin}
      textAlign={props.textAlign}
    >
      <StyledSafeHtml
        content={props.title}
        renderedAs={'p'}
        textStyle={props.modalStyle?.textStyle}
      />
      <MarketedSpecialOffer
        {...specialOfferProps}
        i18nFreeGiftMessage={props.i18nText.freeGiftMessage}
        imageWidth={196}
        direction="row"
        badgeStyle={props.badgeStyle}
        modalStyle={{ descriptionColorText: props.modalStyle?.textColor }}
      />
    </Modal>
  )
}
