import * as Cookies from 'js-cookie'

import { prepareDomain, setCookie } from '../utils/CookieHelper'
import { COOKIE_GROUP_PERSISTENT_NAVIGATION } from '../utils/CookieHelper/types'

export const SELECTED_TOP_LEVEL_NAVIGATION_ITEM_COOKIE_NAME =
  'selectedTopLevelNavigationItem'

export const selectTopLevelNavigationItem = (path: string) => {
  if (typeof window === 'undefined') {
    return
  }

  const cookieExpires = new Date()
  cookieExpires.setDate(cookieExpires.getDate() + 30)

  setCookie({
    name: SELECTED_TOP_LEVEL_NAVIGATION_ITEM_COOKIE_NAME,
    value: path,
    group: COOKIE_GROUP_PERSISTENT_NAVIGATION,
    domain: window.location.hostname,
    expires: cookieExpires,
    path: '/',
  })
}

export const removeSelectedTopLevelNavigationItem = () => {
  if (typeof window === 'undefined') {
    return
  }

  Cookies.remove(SELECTED_TOP_LEVEL_NAVIGATION_ITEM_COOKIE_NAME, {
    domain: prepareDomain(window.location.hostname, false),
  })
}
