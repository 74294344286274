import * as React from 'react'
export const SvgTrustPay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={40} {...props}>
    <g fill="none">
      <path
        d="M29.586 18.333l.245-1.117h.867l.37-1.671h1.61l-.371 1.671h1.01l-.245 1.117h-1.01L31.36 21.5c-.053.246-.054.4-.003.46s.243.09.573.09a4.734 4.734 0 00.326-.01l-.263 1.17-.776.028c-.772.026-1.268-.102-1.489-.385-.14-.18-.171-.456-.091-.83l.816-3.69h-.867zM24.764 21.242c-.026.279.006.477.097.594.152.209.5.313 1.044.313.32 0 .584-.045.793-.137.21-.092.335-.23.377-.413.038-.176-.01-.31-.143-.401-.133-.092-.663-.25-1.591-.473-.666-.169-1.115-.38-1.347-.632-.236-.25-.3-.609-.194-1.078.122-.554.452-1.03.99-1.427.538-.398 1.216-.597 2.034-.597.776 0 1.375.15 1.797.448.422.299.59.815.502 1.548h-1.626c.02-.201-.006-.36-.074-.478-.133-.213-.415-.32-.844-.32-.354 0-.618.054-.79.16-.174.107-.275.231-.306.374-.042.18.01.31.154.39.141.085.673.23 1.598.435.612.147 1.048.369 1.306.666.255.3.333.676.234 1.127-.133.594-.47 1.079-1.01 1.455-.54.375-1.29.563-2.248.563-.977 0-1.655-.199-2.034-.596-.378-.398-.5-.905-.368-1.521h1.65zM19.298 17.161l-.793 3.614c-.076.34-.09.597-.045.77.08.304.336.456.77.456.555 0 .983-.216 1.284-.65.16-.234.281-.543.365-.928l.719-3.262h1.649l-1.318 5.995h-1.58l.182-.847a3.901 3.901 0 00-.149.165c-.08.091-.169.172-.268.242-.304.22-.585.37-.841.45-.257.081-.545.122-.865.122-.92 0-1.47-.32-1.649-.957-.098-.352-.072-.87.08-1.556l.793-3.614h1.666zM17.17 17.03l-.354 1.605a4.183 4.183 0 00-.462-.027c-.654 0-1.139.205-1.455.616-.175.23-.315.586-.422 1.067l-.627 2.865h-1.638l1.318-5.995h1.552l-.228 1.045c.338-.4.616-.672.833-.82.361-.245.785-.368 1.272-.368.03 0 .056.001.077.003.021.002.066.005.134.008zM14.175 15.05l-.32 1.435H11.34l-1.466 6.67H8.104l1.466-6.67H7.043l.32-1.435z"
        fill="#08A5E0"
      />
      <path
        d="M46.092 24.305l.205.011c.156.007.308.002.457-.016.148-.019.28-.06.393-.127.11-.062.228-.192.354-.39.125-.198.184-.32.177-.363l-.902-6.259h1.809l.382 4.422 2.26-4.422h1.728l-3.434 5.896c-.662 1.136-1.142 1.841-1.441 2.114-.299.273-.807.41-1.526.41a9.78 9.78 0 01-.736-.022l.274-1.254zM43.815 17.018c.68 0 1.257.13 1.729.39.471.261.627.752.468 1.475l-.61 2.75c-.043.19-.088.421-.138.692-.034.206-.033.345.003.418a.415.415 0 00.197.182l-.046.23H43.65a1.544 1.544 0 01-.029-.34c.004-.107.015-.228.034-.363-.277.234-.58.434-.907.6a2.81 2.81 0 01-1.255.29c-.563 0-.994-.154-1.292-.464-.299-.31-.385-.749-.26-1.317.164-.737.576-1.27 1.238-1.6.362-.18.866-.308 1.512-.385l.565-.066c.312-.037.538-.083.679-.138.25-.095.4-.244.445-.445.053-.246.002-.415-.154-.509-.156-.093-.405-.14-.748-.14-.384 0-.677.091-.878.275-.14.135-.257.319-.348.55h-1.57c.153-.525.4-.955.743-1.293.547-.528 1.344-.792 2.39-.792zm.268 3.223a2.145 2.145 0 01-.342.151 3.181 3.181 0 01-.457.108l-.376.066c-.358.058-.62.13-.787.214a.936.936 0 00-.52.665c-.06.268-.027.461.1.58.128.12.302.18.522.18.35 0 .694-.1 1.033-.298.338-.198.572-.559.702-1.083l.125-.583zM38.258 15.05c.844 0 1.47.209 1.88.627.409.418.518 1.065.328 1.94-.213.958-.614 1.634-1.204 2.03-.59.396-1.35.594-2.282.594h-1.786l-.639 2.915H32.81l1.786-8.106h3.663zm-.593 1.408h-1.638l-.525 2.386h1.638c.414 0 .758-.097 1.032-.291.274-.195.457-.503.548-.924.095-.422.047-.722-.145-.902-.193-.18-.496-.27-.91-.27z"
        fill="#000"
      />
    </g>
  </svg>
)
export default SvgTrustPay
