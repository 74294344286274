import { SubscriptionItem } from '../src/ApolloProvider/resolvers/Query/Customer/Subscription/SubscriptionEdit'
import { Scalars } from '../src/generated/Content/graphql'
import {
  Country,
  Currency,
  CustomerOrdersArgs,
  CustomerSubscriptionsArgs,
  FormType,
  Order,
  OrderProduct,
  Orders,
  OrdersFilterInput,
  Product,
  ProductImage,
  ProductSubscription,
  RichContentItem,
  SubscriptionFrequency,
  SubscriptionFrequencyDuration,
  SubscriptionStatus,
  Validator,
  VariantOptions,
} from '../src/generated/graphql'

export enum ProductType {
  // Product with no variants
  SIMPLE = 'simple',
  // Product with variants
  COMPLEX = 'complex',
}

export enum ProductOptionKeys {
  COLOUR = 'Colour',
  FLAVOUR = 'Flavour',
  SIZE = 'Size',
  SHADE = 'Shade',
}

export interface EnterpriseProduct extends Product {
  inStock?: boolean
  price: {
    displayValue: string
    amount: string
  }
  hasFromPrice?: boolean
  productType: ProductType
}

export interface EnterpriseOrderProduct extends OrderProduct {
  title: string
  image: string
  url: string
  price?: {
    displayValue: string
  }
  externalIdentifier?: string
  specialOfferGroupTitle?: RichContentItem
}

export interface AvailableContractAndBillingDate {
  minDate: string
  maxDate: string
  contract: {
    id: string
    frequency: SubscriptionFrequency
  }
}
export interface MarketingPreferencesSelected {
  email: boolean
  sms: boolean
}

export interface SubscriptionFrequencyData {
  subscriptionFrequency: {
    availableContractsAndBillingDates?: AvailableContractAndBillingDate[]
    schedule: {
      paymentFrequency: SubscriptionFrequency
    }
  }
}

export interface EnterpriseOrder extends Order {
  buylist: string
  deliveryDateRange?: {
    from: Date
    to: Date
  }
  deliveryMethod?: string | null
  products: EnterpriseOrderProduct[]
  placedProducts: EnterpriseOrderProduct[]
  processingProducts: EnterpriseOrderProduct[]
  paymentProblemProducts: EnterpriseOrderProduct[]
  cancelledProducts: EnterpriseOrderProduct[]
  pendingCancellationProducts: EnterpriseOrderProduct[]
  dispatchedProducts: EnterpriseOrderProduct[]
  collectedProducts: EnterpriseOrderProduct[]
  hasMissingDetails?: boolean
  readyToCollectProducts: EnterpriseOrderProduct[]
  purchasedInStoreProducts: EnterpriseOrderProduct[]
  purchasedStore?: {
    id?: string
    displayName?: string
  }
}

export type EnterpriseGroupedOrderProducts = Pick<
  EnterpriseOrder,
  | 'products'
  | 'placedProducts'
  | 'processingProducts'
  | 'dispatchedProducts'
  | 'paymentProblemProducts'
  | 'cancelledProducts'
  | 'pendingCancellationProducts'
  | 'collectedProducts'
  | 'readyToCollectProducts'
  | 'purchasedInStoreProducts'
>

export type EnterpriseOrderItem = Order & {
  defaultImage: ProductImage
  quantity: string
}

export interface EnterpriseSubscription extends ProductSubscription {
  currentDelay: number
  productSwapAllowed: boolean
  productAddOnAllowed: boolean
  nextGiftProductId?: Scalars['Int']
  totalCost: string
}

export interface EditSubscription {
  id: Scalars['ID']
  productSwapAllowed: boolean
  productRemovalAllowed: boolean
  subscriptionItems: SubscriptionItem[]
  availableFrequencyChanges: SubscriptionFrequencyDuration[]
  schedule: {
    dispatchFrequencyDuration: SubscriptionFrequencyDuration
  }
  nextBillingDate: string
  nextGiftProductId: Scalars['Int']
  childOrders: { orderNumber: string }[]
}

export interface EnterpriseOrders extends Orders {
  orders: EnterpriseOrder[]
  offlineOrders?: EnterpriseOrder[]
}

export interface EnterpriseCustomerOrderHistory {
  customer: {
    completedOrders?: EnterpriseOrders
    offlineOrders?: EnterpriseOrders
    ordersSpotlight?: EnterpriseOrders
  }
}

export interface SubscriptionEditVariables {
  subscriptionId: Scalars['ID']
  subscriptionDelaysEnabled: boolean
}

export interface EnterpriseProductSummaryPriceArgs extends VariantOptions {
  sku: Scalars['Int']
}

export interface EnterpriseCustomerSubscriptionArgs
  extends CustomerSubscriptionsArgs {
  activeFilter: {
    id?: Scalars['ID']
    status?: SubscriptionStatus[]
  }
  completedFilter: {
    id?: Scalars['ID']
    status?: SubscriptionStatus[]
  }
  shippingDestination: Country
  currency: Currency
}

export interface SubscriptionProductBlockData {
  subscriptionProductBlock: {
    subscriptionItems: {
      inStock: boolean
      title: string
      image: string
      url: string
    }[]
    price: string
    dispatchFrequency: SubscriptionFrequency
    productSwapAllowed: boolean
    nextBillingDate: string
    status?: string
    delayable?: boolean
    cancellable?: boolean
    delayCancellable?: boolean
    orderNumber?: string
  }
}

export interface SubscriptionBillingData {
  subscriptionProductBlock: {
    nextBillingDate: string
    availableBillingDateChanges: {
      to: string
      from: string
    }[]
  }
}

export interface SubscriptionEditVariables {
  subscriptionId: string
  subscriptionDelaysEnabled: boolean
}

export interface SubscriptionProductBlockVariables
  extends SubscriptionEditVariables {
  currency: Currency
  shippingDestination: Country
}

export interface EnterpriseCustomerOrderArgs extends CustomerOrdersArgs {
  outstandingFilter: OrdersFilterInput
  completedFilter: OrdersFilterInput
  enableOfflineOrders?: Boolean
}

export interface AsgardPost {
  id: string
  title: string
  titleLink: string
  type: string
  typeLink: string
  typeLabel: string
  categoryLabel: string
  excerpt: string
  content: string
  date: string
  author: string
  authorLink: string
  authorName: string
  imageSource?: {
    'data-featured-image-large': string
    'data-featured-image': string
    'data-featured-image-scaled': string
    'data-featured-image-crop': string
    'data-featured-image-thumbnail': string
    'data-top-ten-crop': string
    'data-featured-image-thumbnail-small': string
    'data-image-grid-thumbnail': string
    'data-image-grid-full-size': string
    'data-shop-category-image': string
    'data-image-grid-large': string
    'data-image-grid-small': string
    'data-default': string
  }
  featuredImage: string
}

export interface RecentPostsArgs {
  slug: string
  token: string
  offset: number
  limit: number
  exclude: number[]
}

export interface ContentSearchArgs {
  search: string
  link: string
  offset: number
  token: string
  limit: number
}

export interface ColourSwatch {
  key: string
  colour: string
  title: string
  href: string
  __typename: string
}

export interface AccountFormField extends FormField {
  translation: string
}
export interface AccountProfile {
  identifier: string
  type: FormType
  canSubmit: boolean
  fields: AccountFormField[]
  validators: Validator[]
}

export interface FormField {
  name: string
  disabled: boolean
  answerOptions: {
    optionKey: string
    translation: string
  }[]
}

export interface OrderReviewForm {
  identifier: string
  type: string
  fields: FormField[]
}

export interface OrderReviewTemplate {
  name: string
  features: {
    name: string
    label: string
    hasDefaultMeasurement: boolean
    measurementValues: {
      value: string
      label: string
      order: number
      mappingValue: number
    }[]
  }[]
}
