import * as React from 'react'
export const SvgReferralsFriend = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={25} height={25} viewBox="0 0 25 25" {...props}>
    <g stroke="none" stroke-width="1">
      <g transform="translate(-305.000000, -1976.000000)">
        <g transform="translate(305.000000, 1976.000000)">
          <g>
            <path d="M23.9693167,0.00297620523 C23.9045465,0.0109272941 23.8420262,0.032005345 23.7854932,0.0649503639 L0.256081438,12.9555754 C0.0851310546,13.0482431 -0.0148572202,13.2349534 0.00180125607,13.4303981 C0.0184597323,13.6258429 0.148566027,13.7924909 0.332674576,13.8542007 L7.01159614,16.0697768 L6.36821379,22.762986 C6.34088319,22.9803843 6.45933872,23.1897716 6.65849412,23.2760984 C6.85764952,23.3624253 7.08925651,23.3047779 7.22605693,23.1348309 L11.4386795,18.3163401 L17.7959099,24.6531978 C17.9202397,24.7770744 18.1007557,24.8238972 18.2686842,24.7758276 C18.4366127,24.727758 18.5660937,24.5921984 18.6077971,24.4207947 L24.4901501,0.622717792 C24.5334339,0.465691595 24.4968224,0.297176009 24.3924382,0.172968816 C24.2880539,0.048761622 24.1295154,-0.0149325849 23.9693167,0.00297620523 L23.9693167,0.00297620523 Z M22.9333862,2 L17.7426553,23 L12.1696044,17.4376771 L20.27185,6.11968839 C20.387426,5.99747926 20.4297609,5.82219027 20.3829075,5.65985108 C20.3360541,5.49751189 20.2071306,5.37278569 20.0447012,5.33265562 C19.8822718,5.29252555 19.7110135,5.34308833 19.5954375,5.46529745 L7.7582184,15.1621813 L2.24398593,13.3477337 L22.9333862,2 Z M17,9 L11.0741383,17.2402827 C11.0585644,17.2579819 11.0444111,17.2768865 11.0318107,17.2968198 L11.0035923,17.3250883 C10.9933903,17.3386746 10.9839689,17.3528318 10.9753739,17.3674912 L7.74436833,21 L8.22408095,16.1095406 L17,9 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default SvgReferralsFriend
