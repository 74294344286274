import { SiteConfigInterface } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'

export const getCookie = (cname, cookieString) => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(cookieString)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
const isCookieConsentShown = (
  siteConfig: SiteConfigInterface,
  customerLocation: string,
) => {
  const { availableLocationsForCookies, enableCookieMessage } = siteConfig

  if (getCookie('cookieNoticeShown', document.cookie) === 'cookieNoticeShown') {
    return false
  }

  if (!enableCookieMessage) {
    return false
  }

  const availableLocationsForCookiesArray = availableLocationsForCookies
    ? availableLocationsForCookies.toLowerCase().replace(' ', '').split(',')
    : []
  if (availableLocationsForCookiesArray.length === 0) {
    return true
  }
  if (!!!customerLocation || customerLocation.toLowerCase() === 'unknown') {
    return true
  }

  return availableLocationsForCookiesArray.includes(
    customerLocation.toLowerCase(),
  )
}

export const getDeviceObject = (
  siteConfig: SiteConfigInterface,
  customerLocation: string,
) => {
  return {
    cookies_consent_shown: isCookieConsentShown(siteConfig, customerLocation),
  }
}
