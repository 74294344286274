import { CartItem } from './types'

export interface BaseEventType {
  type: string
  subtype?: string
  items?: CartItem[]
  customer?: {
    customerReceiveNewsletter?: boolean
    login_state?: 'not_recognised' | 'recognised' | 'logged_in'
  }
}

export const getEventObject = (eventData: BaseEventType) => {
  return {
    type: eventData.type,
    subtype: eventData.subtype || null,
    items: eventData.items || null,
  }
}
