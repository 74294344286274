import * as React from 'react'

export const SvgLoyaltyCardOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.50929 4.74536C3.47693 4.26154 4.26154 3.47693 4.74536 2.50929L5 2L5.25464 2.50929C5.73846 3.47693 6.52307 4.26154 7.49071 4.74536L8 5L7.49071 5.25464C6.52307 5.73846 5.73846 6.52307 5.25464 7.49071L5 8L4.74536 7.49071C4.26154 6.52307 3.47693 5.73846 2.50929 5.25464L2 5L2.50929 4.74536Z"
      fill={props.fill || '#333333'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.820471 10.5077C1.37011 10.1779 1.79968 9.68077 2.04621 9.08908L2.5 8L2.95379 9.08908C3.20032 9.68077 3.62988 10.1779 4.17953 10.5077L5 11L4.17953 11.4923C3.62989 11.8221 3.20032 12.3192 2.95379 12.9109L2.5 14L2.04621 12.9109C1.79968 12.3192 1.37012 11.8221 0.820471 11.4923L0 11L0.820471 10.5077Z"
      fill={props.fill || '#333333'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0007 9.49121C22.553 9.49121 23.0007 9.93893 23.0007 10.4912V20.2003C23.0007 20.7526 22.553 21.2003 22.0007 21.2003H7.27344C6.72115 21.2003 6.27344 20.7526 6.27344 20.2003V10.4912C6.27344 9.93893 6.72115 9.49121 7.27344 9.49121H22.0007ZM21.3271 11.1641H7.94531V12.4189H21.3271V11.1641ZM21.3271 14.0917H7.94531V19.5277H21.3271V14.0917Z"
      fill={props.fill || '#333333'}
    />
  </svg>
)
export default SvgLoyaltyCardOutline
