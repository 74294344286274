import { spacing, styled, Text } from '@thg-commerce/gravity-theme'

export const Title = styled.h2`
  ${Text('large1', 'alternate')};
  width: 100%;
  display: block;
  text-align: left;
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(4)};
`

export const Subtitle = styled.p`
  ${Text('bodyText', 'default')};
  width: 100%;
  display: block;
  text-align: left;
  margin-bottom: ${spacing(3)};
`

export const Label = styled.p`
  ${Text('bodyText', 'alternate')};
  margin-bottom: ${spacing(1)};
`

export const ModalContainer = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.light};
`
