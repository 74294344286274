import * as React from 'react'
export const SvgGiftCard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="61"
    height="40"
    viewBox="0 0 61 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" width="60" height="40" rx="4" />
    <path d="M21.5 28V16H39.5V28H21.5Z" stroke="#FFFFFF" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0043 16.5054V19.5001H39V20.5001H28.0043V27.5054H27.0043V20.5001H22V19.5001H27.0043V16.5054H28.0043Z"
      stroke="#FFFFFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5 12C26.0523 12 26.5523 12.2239 26.9142 12.5858C27.2761 12.9477 27.5 13.4477 27.5 14V16H25.5C24.9477 16 24.4477 15.7761 24.0858 15.4142C23.7239 15.0523 23.5 14.5523 23.5 14C23.5 13.4477 23.7239 12.9477 24.0858 12.5858C24.4477 12.2239 24.9477 12 25.5 12Z"
      stroke="#FFFFFF"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.5 12C28.9477 12 28.4477 12.2239 28.0858 12.5858C27.7239 12.9477 27.5 13.4477 27.5 14V16H29.5C30.0523 16 30.5523 15.7761 30.9142 15.4142C31.2761 15.0523 31.5 14.5523 31.5 14C31.5 13.4477 31.2761 12.9477 30.9142 12.5858C30.5523 12.2239 30.0523 12 29.5 12Z"
      stroke="#FFFFFF"
      strokeWidth="2"
    />
  </svg>
)
export default SvgGiftCard
