import { TextStyling } from '@thg-commerce/gravity-patterns'

import { Direction } from '../../Product/types'

export interface ProductPriceInterface {
  price: TextStyling
  priceWithRRP: TextStyling
  rrp: TextStyling
  saveUpTo: TextStyling
  align: string
  direction?: Direction
}

export const theme: ProductPriceInterface = {
  align: 'flex-start',
  price: {
    entry: 'medium1',
    style: 'alternate',
    textDecoration: 'none',
    transform: 'none',
    textColor: '#333333',
  },
  priceWithRRP: {
    entry: 'bodyText',
    style: 'alternate',
    textDecoration: 'none',
    transform: 'none',
    textColor: '#333333',
  },
  rrp: {
    entry: 'small',
    style: 'default',
    textDecoration: 'line-through',
    transform: 'none',
    textColor: '#333333',
  },
  saveUpTo: {
    entry: 'bodyText',
    style: 'alternate',
    textDecoration: 'none',
    transform: 'none',
    textColor: '#C42C21',
  },
}
