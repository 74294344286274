export type eventDataTypes = {
  type: string
  subtype?: string
  contents: { html_element: string }[] | { widget_id: number }[]
}
export const getEventObject = (eventData: eventDataTypes) => {
  return {
    ...eventData,
    viewport_width: window.innerWidth,
  }
}
