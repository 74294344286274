import { PublicConfiguration } from '../ConfigurationLoader/types'
import { clearCookie, setCookie } from '../utils/CookieHelper'
import {
  COOKIE_GROUP_ADVERTISING,
  COOKIE_GROUP_AFFILIATE,
  COOKIE_GROUP_PRESERVED_REFERER,
  CookieGroup,
} from '../utils/CookieHelper/types'

const TRACKING_PARAMETERS: {
  parameterName: string
  cookieName: string
  configRequired?: string
  valueValidationRegex?: RegExp
  cookieGroup: CookieGroup
  encode: boolean
  deletePreviouslySetCookies: boolean
}[] = [
  {
    parameterName: 'cjevent',
    cookieName: 'CJ_CJEVENT_V6',
    configRequired: 'cjAffiliateEnabled',
    valueValidationRegex: /^[a-zA-Z0-9-_=]{0,64}$/,
    cookieGroup: COOKIE_GROUP_AFFILIATE,
    encode: true,
    deletePreviouslySetCookies: true,
  },
  {
    parameterName: 'cjevent',
    cookieName: 'cje',
    configRequired: 'cjAffiliateUTSEnabled',
    valueValidationRegex: /^[a-zA-Z0-9-_=]{0,64}$/,
    cookieGroup: COOKIE_GROUP_AFFILIATE,
    encode: false,
    deletePreviouslySetCookies: true,
  },
  {
    parameterName: 'awc',
    cookieName: 'awin_awc_V6',
    configRequired: 'awinAffiliateEnabled',
    valueValidationRegex: /^([0-9]{1,20})_([0-9]{1,15})_([a-zA-Z0-9-_=]{1,64})$/,
    cookieGroup: COOKIE_GROUP_AFFILIATE,
    encode: true,
    deletePreviouslySetCookies: true,
  },
  {
    parameterName: 'clickref',
    cookieName: 'thg-society-clickref_V6',
    cookieGroup: COOKIE_GROUP_AFFILIATE,
    encode: true,
    deletePreviouslySetCookies: true,
  },
  {
    parameterName: 'gclid',
    cookieName: 'google_gclid_V6',
    configRequired: 'googleTrackingEnabled',
    valueValidationRegex: /^[a-zA-Z0-9-_]{0,55}$/,
    cookieGroup: COOKIE_GROUP_AFFILIATE,
    encode: true,
    deletePreviouslySetCookies: true,
  },
  {
    parameterName: 'affil',
    cookieName: 'affil_V6',
    cookieGroup: COOKIE_GROUP_AFFILIATE,
    encode: false,
    deletePreviouslySetCookies: true,
  },
  {
    parameterName: 'preservedReferer',
    cookieName: 'preservedReferer_V6',
    cookieGroup: COOKIE_GROUP_PRESERVED_REFERER,
    encode: true,
    deletePreviouslySetCookies: true,
  },
  {
    parameterName: 'partnerref',
    cookieName: 'preservedPartnerRef_V6',
    cookieGroup: COOKIE_GROUP_PRESERVED_REFERER,
    encode: false,
    deletePreviouslySetCookies: true,
  },
  {
    parameterName: 'fbclid',
    cookieName: 'fb_fbc_V6',
    configRequired: 'facebookTrackingEnabled',
    valueValidationRegex: /^[a-zA-Z0-9-_]{61}$/,
    cookieGroup: COOKIE_GROUP_ADVERTISING,
    encode: true,
    deletePreviouslySetCookies: false,
  },
  {
    parameterName: 'utm_source',
    cookieName: 'utm_source_V6',
    cookieGroup: COOKIE_GROUP_AFFILIATE,
    encode: true,
    deletePreviouslySetCookies: false,
  },
  {
    parameterName: 'utm_medium',
    cookieName: 'utm_medium_V6',
    cookieGroup: COOKIE_GROUP_AFFILIATE,
    encode: true,
    deletePreviouslySetCookies: false,
  },
  {
    parameterName: 'utm_campaign',
    cookieName: 'utm_campaign_V6',
    cookieGroup: COOKIE_GROUP_AFFILIATE,
    encode: true,
    deletePreviouslySetCookies: false,
  },
]

const COOKIE_EXPIRATION_DAYS = 30
const FACEBOOK_FBC_VERSION = 'fb'
const FACEBOOK_FBC_SUBDOMAIN_INDEX = '1'

const cookieExpires = new Date()
cookieExpires.setDate(cookieExpires.getDate() + COOKIE_EXPIRATION_DAYS)

const generateFacebookFbc = (param: string) => {
  return `${FACEBOOK_FBC_VERSION}.${FACEBOOK_FBC_SUBDOMAIN_INDEX}.${Date.now()}.${param}`
}

export const handleAffiliateParamsCookies = (config: PublicConfiguration) => {
  if (typeof window === 'undefined') {
    return
  }

  const isOneTrustAvailable = config.siteConfig.showOptanonFooterLink
  const url = new URL(window.location.toString())

  TRACKING_PARAMETERS.forEach((parameterConfig) => {
    let param = url.searchParams.get(parameterConfig.parameterName)

    if (parameterConfig.parameterName === 'preservedReferer') {
      const partnerref = url.searchParams.get('partnerref')
      if (!partnerref) {
        return
      }

      const referer = window.document.referrer
      if (referer) {
        param =
          new URL(referer)?.hostname === config.siteDefinition.domain
            ? null
            : window.document.referrer
      }
    }

    if (typeof param !== 'string') {
      return
    }

    if (
      parameterConfig.configRequired &&
      !config.siteConfig[parameterConfig.configRequired]
    ) {
      return
    }

    if (
      parameterConfig.valueValidationRegex &&
      !parameterConfig.valueValidationRegex.test(param)
    ) {
      return
    }

    if (parameterConfig.deletePreviouslySetCookies) {
      clearCookie(parameterConfig.cookieName, config.siteDefinition.domain)
    }

    if (parameterConfig.parameterName === 'fbclid') {
      param = generateFacebookFbc(param)
    }

    const value = parameterConfig.encode ? btoa(param) : param

    setCookie(
      {
        value,
        domain: config.siteDefinition.domain,
        name: parameterConfig.cookieName,
        expires: cookieExpires,
        secure: true,
        path: '/',
        group: parameterConfig.cookieGroup,
      },
      undefined,
      undefined,
      false,
      isOneTrustAvailable,
    )
  })
}
