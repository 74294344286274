import { css, mq, spacing, styled, Text } from '@thg-commerce/gravity-theme'

import { SwatchShape, SwatchSize } from './types'

export interface SwatchStyledProps {
  background: string
  selected: boolean
  swatchShape: SwatchShape
  swatchSize: SwatchSize
  disabled?: boolean
  borderColor?: string
}

export const SwatchesLeftText = styled.div`
  ${Text('small', 'default')};
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${(props) => props.theme.colors.palette.greys.dark};
`

export const SwatchesLeftNumber = styled.span<{
  colours: number
  text: string
}>`
  ::after {
    ${(props) =>
      props.colours > 4
        ? `content: " + ${props.colours - 4} ${props.text}"`
        : `content :  "" `}
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ::after {
      ${(props) =>
        props.colours > 5
          ? `content: " + ${props.colours - 5} ${props.text}"`
          : `content :  "" `}
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ::after {
      ${(props) =>
        props.colours > 5
          ? `content: "+ ${props.colours - 5} ${props.text}"`
          : `content :  "" `}
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ::after {
      ${(props) =>
        props.colours > 7
          ? `content: "+ ${props.colours - 7} ${props.text}"`
          : `content :  "" `}
    }
  }
`

export const SwatchElementContainer = styled.div<{
  swatchSize: SwatchSize
}>`
  margin: ${(props) =>
    props.swatchSize === SwatchSize.Small
      ? '0'
      : `0 ${spacing(1)} ${spacing(1)} 0`};
`

const PersistOnOneRowStyle = css`
  ${SwatchElementContainer}:nth-of-type(n + 4),
  button:nth-of-type(n + 3):not(:last-child) {
    display: none;
  }
  ${SwatchElementContainer}:nth-of-type(n + 5),
  button:nth-of-type(n + 4):not(:last-child) {
    display: none;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${SwatchElementContainer}:nth-of-type(n + 5),
    button:nth-of-type(n + 6):not(:last-child) {
      display: block;
    }

    ${SwatchElementContainer}:nth-of-type(n + 6),
    button:nth-of-type(n + 5):not(:last-child) {
      display: none;
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${SwatchElementContainer}:nth-of-type(n + 6),
    button:nth-of-type(n + 6):not(:last-child) {
      display: block;
    }

    ${SwatchElementContainer}:nth-of-type(n + 8),
    button:nth-of-type(n + 8):not(:last-child) {
      display: none;
    }
  }
`

export const SwatchContainer = styled.div<{
  hideColours?: boolean
  showOnlyTwoRows?: boolean
  hideSwatches?: boolean
  paddingBottom?: number
  persistOnOneRow?: boolean
}>`
  width: 100%;
  display: ${(props) => (props.hideSwatches ? 'none' : 'flex')};
  flex-wrap: wrap;
  ${(props) =>
    !props.showOnlyTwoRows &&
    props.paddingBottom &&
    `padding-bottom: ${spacing(props.paddingBottom || 2.5)};`}

  ${(props) =>
    props.hideColours &&
    `
      ${mq(props.theme.breakpointUtils.map, 'xs')} {
        max-height: 112px;
        overflow: hidden;
      }
      ${mq(props.theme.breakpointUtils.map, 'sm')} {
        max-height: none;
        overflow: visible;
        padding-bottom: ${spacing(props.paddingBottom || 2.5)};
      }
    `}
  
  ${(props) => props.persistOnOneRow && PersistOnOneRowStyle}
`
export const ExpandButton = styled.button`
  padding: 0 0 ${spacing(2)};
  text-decoration: underline
    ${(props) => props.theme.colors.palette.greys.darker};
  ${(props) =>
    Text(props.theme.formgroup.label.scale, props.theme.formgroup.label.style)}
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: none;
  }
`

const circleSwatchAttribute = css`
  border-radius: 50%;
`

const selectedAttribute = css`
  background-color: #fff;
  border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
`

export const SwatchElementButton = styled.button<SwatchStyledProps>`
  display: inline-flex;
  ${(props) =>
    props.swatchShape === SwatchShape.CIRCLE && circleSwatchAttribute}

  &:focus {
    outline: none;
    & > span {
      border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
    }
  }
`

export const OuterSwatchElement = styled.span<SwatchStyledProps>`
  display: inline-flex;

  ${(props) =>
    props.swatchSize === SwatchSize.Small
      ? `
      height: 32px;
      width: 32px;`
      : `
      height: 48px;
      width: 48px;
    `}

  ${(props) =>
    props.swatchShape === SwatchShape.CIRCLE && circleSwatchAttribute}

  ${(props) => props.selected && selectedAttribute}

  &:hover {
    background-color: ${(props) =>
      props.selected
        ? props.theme.colors.palette.brand.lighter
        : props.theme.colors.palette.brand.lightest};
    border: ${(props) => (props.selected ? '3' : '2')}px solid
      ${(props) => props.theme.colors.palette.brand.base};
  }

  &:focus {
    outline: none;
    background-color: ${(props) =>
      props.selected
        ? props.theme.colors.palette.brand.lighter
        : props.theme.colors.palette.brand.lightest};
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }

  &:active {
    background-color: ${(props) =>
      props.selected
        ? props.theme.colors.palette.brand.dark
        : props.theme.colors.palette.brand.base};
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

interface DisabledStyleProps {
  borderWidth: string
  elementSize: string
  swatchShape: SwatchShape
}

export const InnerSwatchElement = styled.span<
  SwatchStyledProps & DisabledStyleProps
>`
  margin: auto;
  ${(props) => `
    height: ${props.elementSize};
    width: ${props.elementSize};
    `}
  background: ${(props) => props.background};

  ${(props) =>
    props.swatchShape === SwatchShape.CIRCLE && circleSwatchAttribute}
  border: 1px solid ${(props) =>
    props.borderColor || props.theme.colors.palette.brand.base};

  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`
export const SwatchDisableWrapper = styled.div<{ borderWidth: string }>`
  position: absolute;
  z-index: 5;
  ${(props) => `
    top: calc(${props.borderWidth}/2);
    left: calc(${props.borderWidth}/2);
    width: calc(${props.borderWidth}*11);
    height: calc(${props.borderWidth}*11);
  `}
`
