import { Property } from 'csstype'

export interface SiteInterface {
  siteWidth: Property.Width<string>
  headerHeight: number
  favicon: string
}

export const site: SiteInterface = {
  siteWidth: '1400px',
  headerHeight: 87,
  favicon: '',
}
