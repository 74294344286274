import promClient, { Histogram } from 'prom-client'

export type Labels = Record<string, string>

export type HistogramOptions = {
  name: string
  labels?: string[]
  buckets?: number[]
  help?: string
}

export const getHistogram = (options: HistogramOptions) => {
  let histogram = promClient.register.getSingleMetric(options.name) as Histogram
  const defaultBuckets = [100, 250, 500, 1000, 3000, 10000]

  if (!histogram || !('observe' in histogram)) {
    histogram = new promClient.Histogram({
      name: options.name,
      labelNames: options.labels || [],
      help: options.help || '',
      buckets: options.buckets || defaultBuckets,
    })
  }

  return histogram
}
