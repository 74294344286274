import crypto from 'crypto'

export enum ObjectCacheKey {
  PAGE = 'Page',
  PRODUCT = 'Product',
  HEADER = 'Header',
  FOOTER = 'Footer',
}

export const generateObjectCacheKey = (
  cacheKey: ObjectCacheKey,
  value?: string,
) => `${cacheKey}${value ? `:${value}` : ''}`

export const getCacheKeyFromQueryVariables = (
  queryVariables: Record<string, any>,
) => {
  return Object.keys(queryVariables)
    .sort()
    .map((key) => `${key}:${JSON.stringify(queryVariables[key])}`)
    .join('_')
}

export const createKeyHash = (key: string) =>
  crypto.createHash('sha1').update(key).digest('hex')

export const getCacheKeyFromHeaders = (
  requestHeaders: Record<string, any>,
): string => {
  const cacheableHeaders = [
    'x-customer-state',
    'x-customer-list',
    'x-customer-location',
    'x-experiments',
  ]

  return cacheableHeaders
    .reduce<string[]>((accumulator, headerName) => {
      const headerValue = requestHeaders[headerName]

      if (headerValue) {
        accumulator.push(`${headerName}=${headerValue}`)
      }
      return accumulator
    }, [])
    .join(';')
}
