import * as React from 'react'
export const SvgBellDisable = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.37479 19H8.99997L9.00506 19.1763C9.0963 20.7511 10.4023 22 12 22C13.6568 22 15 20.6569 15 19H20L18.0043 14.0045V11C18.0043 10.4991 17.9429 10.0126 17.8273 9.5475L16.0043 11.3705V14.3892L17.047 17H10.3748L8.37479 19ZM12 20C11.4477 20 11 19.5523 11 19H13L12.9932 19.1166C12.9355 19.614 12.5128 20 12 20Z"
      fill={props.fill || '#FFFFFF'}
    />
    <path
      d="M13.9391 7.4982C13.4204 7.21102 12.8311 7.03572 12.2039 7.0049L12.0043 7C9.8621 7 8.11321 8.68397 8.00919 10.8004L8.00429 11V13.433L5.04712 16.3901L6.00429 14.0045V11C6.00429 8.2665 7.83223 5.95994 10.3324 5.23602C10.1223 5.00806 9.99997 4.73455 9.99997 4.44045C9.99997 3.64491 10.8954 3 12 3C13.1045 3 14 3.64491 14 4.44045C14 4.7337 13.8783 5.00648 13.6693 5.23404C14.2905 5.41308 14.8702 5.68977 15.3908 6.04641L13.9391 7.4982Z"
      fill={props.fill || '#FFFFFF'}
    />
    <rect
      x="3"
      y="19.9706"
      width="24"
      height="2"
      transform="rotate(-45 3 19.9706)"
      fill={props.fill || '#FFFFFF'}
    />
  </svg>
)
export default SvgBellDisable
