import * as React from 'react'
export const SvgStore = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={26} height={16} {...props}>
    <path
      d="M.269 0A.268.268 0 000 .266v3.68c0 .145.12.265.268.265h1.418c.148 0 .268.118.268.264l-.025 11.262a.264.264 0 00.266.263h21.631a.27.27 0 00.272-.268V4.475c0-.145.12-.264.268-.264h1.366A.266.266 0 0026 3.947V.265A.267.267 0 0025.732 0H.269zM12 13.732c0 .147-.113.268-.25.268l-4.5-.003c-.137 0-.25-.12-.25-.267V6.268C7 6.121 7.113 6 7.25 6h4.5c.137 0 .25.12.25.268v7.464zm7 0c0 .147-.09.268-.2.268l-4.6-.003c-.111 0-.2-.12-.2-.267V6.268c0-.147.09-.268.199-.268H18.8c.111 0 .2.12.2.268v7.464z"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgStore
