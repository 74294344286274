import * as React from 'react'

import {
  CustomAnimatePresence,
  MotionDiv,
} from '@thg-commerce/gravity-animations'
import { DropdownDirection } from '@thg-commerce/gravity-patterns/Header/theme'
import { Order } from '@thg-commerce/gravity-patterns/Header/types'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { useScrollLock } from '@thg-commerce/gravity-system'
import {
  BreakpointKey,
  KeyboardKeys,
  mq,
  zIndex,
} from '@thg-commerce/gravity-theme'
import { BreakpointType } from '@thg-commerce/gravity-theme/breakpoints'

import { DropdownOptions, HeaderZIndexLevel } from '../types'

import { HeaderDropdownTrigger } from './HeaderDropdownTrigger'

const TRANSITION_TIME = 0.3

export interface HeaderDropdownProps {
  isMobile: boolean
  trigger: { icon: React.ReactElement; text: string; triggerHref: string }
  dropdownWidth?: string
  dropdownDirection?: BreakpointType<DropdownDirection>
  content: (close?: () => void) => React.ReactElement
  i18nAriaLabels: { trigger: string; close: string }
  disableFocusLogic: boolean
  disableAnimation?: boolean
  notification?: React.FunctionComponent
  tabIndex?: number
  dropdownType: DropdownOptions
  inSubmenu?: boolean
  mobileSearch?: boolean
  isDesktop?: boolean
  setDisplaySocialEngagement?: (value: boolean) => void
}

const StyledHeaderWrapper = styled.div<{
  isShowing: boolean
  inSubmenu: boolean
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.inSubmenu && props.isShowing
      ? zIndex(HeaderZIndexLevel.Dropdown + 10)
      : props.isShowing
      ? zIndex(HeaderZIndexLevel.Dropdown)
      : ''};

  height: ${(props) =>
    props.inSubmenu
      ? props.theme.patterns.header.subNavigation.dropdowns.triggerHeight
      : props.theme.patterns.header.dropdowns.triggerHeight};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    min-width: initial;
  }
`

const dropdownDirection = (
  breakpoint,
  desktopOrder,
  dropdownDirection?: BreakpointType<DropdownDirection>,
) => {
  if (dropdownDirection) {
    return dropdownDirection[breakpoint] === DropdownDirection.LEFT
      ? `left: unset;
         right: 0;`
      : `left: 0;
         right: unset;`
  }

  return desktopOrder === Order.one
    ? `right: unset;
       left: 0;`
    : `right: 0;
       left: unset;`
}

const DropdownWrapper = styled.div<{
  dropdownDirection?: BreakpointType<DropdownDirection>
  mobileSearch?: boolean
}>`
  overflow: hidden;
  position: absolute;
  ${(props) => props.mobileSearch && 'height: 600px'}
  ${(props) =>
    props.theme.breakpointUtils.keys.map((breakpoint) => {
      return `
      ${mq(props.theme.breakpointUtils.map, breakpoint as BreakpointKey)} {
      ${dropdownDirection(
        breakpoint,
        props.theme.patterns.header.dropdowns.order,
        props?.dropdownDirection,
      )}
    }`
    })};
`

const DropdownContainer = styled(MotionDiv)<{
  dropdownWidth: string | undefined
  isSearch?: boolean
}>`
  position: ${(props) => (props.dropdownWidth ? 'relative' : 'fixed')};
  width: ${(props) =>
    props.dropdownWidth ? `${props.dropdownWidth}` : '100%'};

  top: ${(props) =>
    props.isSearch
      ? props.theme.patterns.header.headerLogo.mobile.headerHeight
      : undefined};

  right: 0;
  background-color: ${(props) => props.theme.colors.palette.greys.white};
`

const Overlay = styled(MotionDiv)<{ isMobile: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  ${zIndex(HeaderZIndexLevel.Overlay)};
  ${(props) =>
    props.isMobile ? 'touch-action: none' : 'pointer-events: none'};
`

export const HeaderDropdown = (props: HeaderDropdownProps) => {
  const [isShowing, setIsShowing] = React.useState<boolean>(false)
  const Notification = props.notification

  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const triggerRef = React.useRef<HTMLButtonElement>(null)
  const setScrollLock = useScrollLock()

  const documentLoaded =
    typeof document !== 'undefined' && typeof window !== 'undefined'

  React.useEffect(() => {
    setIsShowing(false)
  }, [props.isMobile])

  const toggleScrollLock = React.useMemo(() => {
    return (valueToToggle: boolean) => {
      props.isMobile &&
        documentLoaded &&
        setScrollLock(valueToToggle, document, window)
    }
  }, [documentLoaded, props.isMobile, setScrollLock])

  React.useEffect(() => {
    if (props.isMobile) {
      !isShowing && triggerRef?.current?.focus()
    }
  }, [isShowing, props.isMobile])

  const open = () => {
    setIsShowing(true)
    props.setDisplaySocialEngagement &&
      props.setDisplaySocialEngagement(isShowing)
    toggleScrollLock(true)
  }

  const close = () => {
    setIsShowing(false)
    props.setDisplaySocialEngagement &&
      props.setDisplaySocialEngagement(isShowing)
    toggleScrollLock(false)
  }

  const toggle = () => {
    setIsShowing(!isShowing)
    props.setDisplaySocialEngagement &&
      props.setDisplaySocialEngagement(!isShowing)
    toggleScrollLock(!isShowing)
  }

  const keyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case KeyboardKeys.Escape:
      case KeyboardKeys.Esc:
        setIsShowing(false)
        props.setDisplaySocialEngagement &&
          props.setDisplaySocialEngagement(isShowing)
        break
    }
  }
  const onFocusOrBlur = (newIsShowing) => {
    !props.isMobile && setIsShowing(newIsShowing)
    props.setDisplaySocialEngagement &&
      props.setDisplaySocialEngagement(isShowing)
  }
  return (
    <React.Fragment>
      <StyledHeaderWrapper
        isShowing={isShowing}
        inSubmenu={props.inSubmenu || false}
      >
        <div
          role="none"
          ref={dropdownRef}
          onFocus={
            !props.disableFocusLogic ? () => onFocusOrBlur(true) : undefined
          }
          onBlur={
            !props.disableFocusLogic
              ? (event) => {
                  !event.currentTarget.contains(event.relatedTarget as Node) &&
                    onFocusOrBlur(false)
                }
              : undefined
          }
          onKeyDown={keyDown}
          onMouseLeave={() => !props.isMobile && close()}
        >
          <HeaderDropdownTrigger
            tabIndex={0}
            isShowing={isShowing}
            toggle={() => toggle()}
            open={() => open()}
            isMobile={props.isMobile}
            i18nAriaLabels={props.i18nAriaLabels}
            icon={props.trigger.icon}
            href={props.trigger.triggerHref}
            text={props.trigger.text}
            dropdownType={props.dropdownType}
            inSubmenu={props.inSubmenu}
          />
          <DropdownWrapper
            mobileSearch={props?.mobileSearch}
            dropdownDirection={props?.dropdownDirection}
            data-testid="header-dropdown-wrapper"
          >
            {isShowing && (
              <DropdownContainer
                data-testid="header-dropdown"
                dropdownWidth={props.dropdownWidth}
                initial="closed"
                animate="open"
                isSearch={props.dropdownType === DropdownOptions.SEARCH}
                variants={
                  props.disableAnimation
                    ? {}
                    : {
                        closed: { opacity: 0, y: '-100%' },
                        open: {
                          opacity: 1,
                          y: 0,
                          transition: {
                            duration: TRANSITION_TIME,
                            ease: 'easeInOut',
                          },
                        },
                      }
                }
              >
                {props.content(close)}
              </DropdownContainer>
            )}
          </DropdownWrapper>
        </div>
        {Notification && <Notification />}
      </StyledHeaderWrapper>
      <CustomAnimatePresence>
        {isShowing && (
          <Overlay
            isMobile={props.isMobile}
            key="overlay"
            data-testid="header-dropdown-overlay"
            animate={isShowing ? 'open' : 'closed'}
            initial="closed"
            exit="closed"
            variants={{
              open: {
                opacity: 1,
                transition: { duration: TRANSITION_TIME, ease: 'easeInOut' },
              },
              closed: {
                opacity: 0,
                transition: {
                  duration: TRANSITION_TIME,
                  ease: 'easeInOut',
                },
              },
            }}
            onClick={() => close()}
          />
        )}
      </CustomAnimatePresence>
    </React.Fragment>
  )
}

export default HeaderDropdown
