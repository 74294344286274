import * as React from 'react'
import {
  FulfilmentMethod,
  StoreOpeningTime,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { AutocompletePrediction } from '@thg-commerce/enterprise-core'

import { Search } from './ClickAndCollectModalSearch'
import { Title, Subtitle, Label } from './styles'
import { SearchResults } from './ClickAndCollectModalSearchResults'
import { Footer } from './ClickAndCollectModalFooter'
import { ErrorTypes } from '../ClickAndCollectModalPresenter/ClickAndCollectModalPresenter'

export interface ClickAndCollectModalProps {
  product: {
    image: string
    title: string
    sku: string
    isClickAndCollect: boolean
    isCheckStock: boolean
    isOrderInStore: boolean
    productFulfilmentMethod?: FulfilmentMethod
  }
  i18nText: {
    title: string
    subtitle: string
    locationSearch: {
      searchLabel: string
      buttonText: string
      stockDefaultMessage: string
    }
    searchResults: {
      clickAndCollectButton: string
      deliverToStoreButton: string
      deliverToStoreAvailability: string
      inStockText: (_: string) => string
      outOfStockText: string
      unavailableStore: string
      unavailableClickAndCollectProduct: string
      unavailableClickAndCollectStore: string
      productNotRanged: string
    }
    errorMessages: {
      POSTCODE_REQUIRED: string
      NO_RESULTS_FOUND: (_: string) => string
    }
  }
  onClick: (fulfilmentType: FulfilmentMethod, storeId: string) => void
  setError: React.Dispatch<React.SetStateAction<ErrorTypes | undefined>>
  setSearchLocations: React.Dispatch<
    React.SetStateAction<AutocompletePrediction[]>
  >
  setSearchPerformed: React.Dispatch<React.SetStateAction<boolean>>
  searchPerformed: boolean
  getClickAndCollectStoresCallback: (locationData: string) => void
  stores?: {
    id: string
    displayName: string
    distance: Number
    fulfilmentMethods: FulfilmentMethod[]
    openingTimes: [StoreOpeningTime, StoreOpeningTime, StoreOpeningTime]
    stock: number
    ranged: boolean
  }[]
  searchLocations?: AutocompletePrediction[]
  error?: ErrorTypes
  isProductPage?: boolean
  productStoreId?: string
  isGoogleMapsLoaded: boolean
}

export const ClickAndCollectModal = ({
  i18nText,
  product,
  onClick,
  isProductPage,
  setError,
  error,
  stores,
  getClickAndCollectStoresCallback,
  searchLocations,
  setSearchLocations,
  searchPerformed,
  setSearchPerformed,
  productStoreId,
  isGoogleMapsLoaded,
}: ClickAndCollectModalProps) => {
  return (
    <React.Fragment>
      <Title>{i18nText.title}</Title>
      <Subtitle>{i18nText.subtitle}</Subtitle>
      <Label>{i18nText.locationSearch.searchLabel}</Label>
      <Search
        i18nText={{
          ...i18nText.locationSearch,
          errorMessages: i18nText.errorMessages,
        }}
        getClickAndCollectStores={getClickAndCollectStoresCallback}
        setError={setError}
        error={error}
        searchLocations={searchLocations}
        setSearchLocations={setSearchLocations}
        searchPerformed={searchPerformed}
        setSearchPerformed={setSearchPerformed}
        currentProductSku={product.sku}
        isGoogleMapsLoaded={isGoogleMapsLoaded}
        stores={stores}
      />
      {stores && error === undefined && (
        <SearchResults
          i18nText={i18nText.searchResults}
          productStoreId={productStoreId}
          stores={stores}
          onClick={onClick}
          productIsCheckStock={product.isCheckStock}
          productIsOrderInStore={product.isOrderInStore}
          productIsClickAndCollect={product.isClickAndCollect}
          isProductPage={isProductPage}
          productFulfilmentMethod={product.productFulfilmentMethod}
        />
      )}
      <Footer image={product.image} title={product.title} />
    </React.Fragment>
  )
}
