import * as React from 'react'
export const SvgWishlistSelected = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Wishlist-Selected_svg__wishlist-selected-svg"
    data-name="wishlist-selected-svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path d="M12.17 6.79c-1.55-1.69-4-3.45-5.72-3.21-4.17.58-5.08 4.58-3 9.16 1.56 3.38 7 7.82 8.69 7.82s6.86-4.16 8.49-7.83c2-4.43.94-8.45-2.91-9.07-1.72-.28-3.99 1.41-5.55 3.13z" />
  </svg>
)
export default SvgWishlistSelected
