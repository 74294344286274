import * as React from 'react'
export const SvgAfterpay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={40} {...props}>
    <path
      fill={props.fill || '#363636'}
      d="M30.423 18.715a2.608 2.608 0 010 3.688 2.581 2.581 0 01-1.844.753c-.459 0-.9-.175-1.233-.491l-.054-.043v2.924l-1.32.417v-7.901h1.32v.392l.054-.054c.392-.382.84-.437 1.233-.437a2.618 2.618 0 011.844.752zm-.551 1.842a1.288 1.288 0 10-1.288 1.32 1.319 1.319 0 001.288-1.32z"
    />
    <path d="M32.425 22.404a2.608 2.608 0 010-3.688 2.581 2.581 0 011.844-.753c.459 0 .9.175 1.233.491l.054.043v-.435h1.32v4.996h-1.32v-.392l-.054.054c-.392.383-.84.437-1.233.437a2.62 2.62 0 01-1.844-.753zm.556-1.847a1.288 1.288 0 101.287-1.32 1.319 1.319 0 00-1.287 1.32z" />
    <path
      fill={props.fill || '#363636'}
      d="M32.425 22.404a2.608 2.608 0 010-3.688 2.581 2.581 0 011.844-.753c.459 0 .9.175 1.233.491l.054.043v-.435h1.32v4.996h-1.32v-.392l-.054.054c-.392.383-.84.437-1.233.437a2.62 2.62 0 01-1.844-.753zm.556-1.847a1.288 1.288 0 101.287-1.32 1.319 1.319 0 00-1.287 1.32z"
    />
    <path d="M38.198 25.96l1.183-2.89-1.99-5.008h1.46l1.248 3.11 1.264-3.11h1.451l-3.186 7.9z" />
    <path
      fill={props.fill || '#363636'}
      d="M38.198 25.96l1.183-2.89-1.99-5.008h1.46l1.248 3.11 1.264-3.11h1.451l-3.186 7.9zm-31.53-2.857a2.572 2.572 0 01-1.81-.732 2.57 2.57 0 010-3.619 2.572 2.572 0 011.814-.733c.69 0 1.272.365 1.638.672l.106.089v-.655h.81v4.869h-.814v-.651l-.105.088c-.366.306-.948.672-1.639.672zm0-4.3a1.755 1.755 0 10-.022 3.511 1.755 1.755 0 00.022-3.51zm4.022 4.191V18.89h-.743v-.766h.744v-1.439a1.566 1.566 0 011.587-1.573H13.3l-.204.767H12.3a.84.84 0 00-.806.828v1.417h1.542v.767h-1.539v4.102h-.807zm5.4 0c-.88 0-1.593-.714-1.594-1.595v-2.508h-.743v-.766h.741v-3.012h.812v3.012h1.54v.767h-1.54v2.486c0 .444.385.85.806.85h.794l.206.766H16.09zm3.486.087a2.174 2.174 0 01-1.642-.743 2.504 2.504 0 01-.666-1.532 2.658 2.658 0 01.053-.802 2.57 2.57 0 01.607-1.181 2.224 2.224 0 013.299 0c.306.33.515.738.605 1.18.046.236.066.478.058.72h-3.855v.085c.113.862.764 1.516 1.515 1.527.457-.02.893-.2 1.234-.506l.68.404a2.86 2.86 0 01-.599.486 2.761 2.761 0 01-1.29.362zm0-4.245c-.626 0-1.208.454-1.446 1.123l-.008.015-.047.093h3.003l-.057-.114c-.225-.664-.805-1.117-1.443-1.117h-.002zm3.116 4.158v-4.869h.813v.617l.114-.123c.286-.317 1.134-.575 1.692-.6l-.198.81c-.895.027-1.608.717-1.608 1.567v2.598h-.813z"
    />
    <path
      fill={props.fill || '#2779AD'}
      d="M49.805 19.44l1.418-.82c-.157-.275-.114-.209-.26-.469-.148-.26-.095-.383.217-.385.907-.007 1.817-.007 2.73 0 .27 0 .34.113.199.355-.454.791-.907 1.58-1.36 2.365-.147.251-.275.251-.428 0-.153-.25-.114-.189-.284-.476l-1.417.826c.025.06.056.116.093.17.35.609.506.9.868 1.502.429.714 1.327.757 1.837.098.057-.072.109-.148.156-.227a1782.49 1782.49 0 002.57-4.465c.091-.15.16-.313.202-.484a1.07 1.07 0 00-1.047-1.308c-1.84-.01-3.68-.012-5.52 0a1.082 1.082 0 00-.974 1.587c.12.24.264.466.4.693l.6 1.039z"
    />
    <path
      fill={props.fill || '#0F4675'}
      d="M45.714 24.036v-1.632h-.532c-.305 0-.375-.113-.227-.379.448-.784.901-1.565 1.361-2.343.136-.226.256-.251.405 0 .453.783.907 1.564 1.35 2.349.142.25.079.36-.208.37h-.543v1.64h1.914c.825-.013 1.31-.763.999-1.53a2.06 2.06 0 00-.121-.248 4871.64 4871.64 0 00-2.553-4.428 1.765 1.765 0 00-.314-.414 1.061 1.061 0 00-1.645.246 426.149 426.149 0 00-2.746 4.737 1.073 1.073 0 00.874 1.624c.266.017 1.4.008 1.986.008z"
    />
  </svg>
)
export default SvgAfterpay
