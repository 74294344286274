import * as React from 'react'
export const SvgPropositionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || '#1d8634'}
      d="M8.581 17.367l-.006.005L4 12.855l1.39-1.372 3.827 3.778L19.61 5 21 6.372 9.223 18l-.642-.633z"
    />
  </svg>
)
export default SvgPropositionIcon
