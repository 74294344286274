import * as React from 'react'
import * as entities from 'html-entities'

import { vsprintf } from 'sprintf-js'

import { I18nStringMap } from '../types'

const I18nContext = React.createContext<{
  map: I18nStringMap
}>({ map: {} })
const I18nShowKeysContext = React.createContext<boolean>(false)

const I18nProvider = (props: {
  value: I18nStringMap
  showKeys?: boolean
  children?: React.ReactNode
}) => {
  return (
    <I18nContext.Provider value={{ map: props.value }}>
      <I18nShowKeysContext.Provider value={props.showKeys || false}>
        {props.children}
      </I18nShowKeysContext.Provider>
    </I18nContext.Provider>
  )
}

export const useI18n = (logger: any) => {
  const i18nContext = React.useContext(I18nContext)
  const showKeys = React.useContext(I18nShowKeysContext)

  if (typeof i18nContext === 'undefined') {
    throw new Error('i18n must be used within an I18nProvider')
  }

  return (key: string, variables?: string[] | string): string => {
    return getValue(logger, i18nContext.map, key, showKeys, variables)
  }
}

/**
 * Translate property keys to strings
 *
 * @param key site properties key e.g. account.login.message.error
 * @param variables value(s) to replace in keys which include either %s or %d
 * @link https://www.npmjs.com/package/sprintf-js
 *
 * Example: Single variable
 *
 * i18n('account.login.message.error', 'email.address@somewhere.com')
 *
 * key: account.login.message.error would be defined as "Sorry but %s isn't registered"
 *
 * result -> "Sorry but email.address@somewhere.com isn't registered"
 *
 * Example: Multiple variables
 *
 * i18n('account.login.message.welcome', ['David', 'email.address@somewhere.com'])
 *
 * key: account.login.message.welcome would be defined as "Welcome %s, %s was registered"
 *
 * result -> "Welcome David, email.address@somewhere.com was registered"
 */
const getValue = (
  logger: any,
  keys: any,
  key: string,
  showKeys?: boolean,
  variables?: string[] | string,
): string => {
  const result = keys[key]

  const getShowKeysVal = (key: string) => {
    if (result) {
      return `${key}(${result.location})`
    }

    logger.warn(`Property is missing. Key: ${key}`)

    return `${key}(UNKNOWN)`
  }

  return showKeys
    ? getShowKeysVal(key)
    : result
    ? variables && (result.value.includes('%s') || result.value.includes('%d'))
      ? vsprintf(
          entities.AllHtmlEntities.decode(result.value),
          variables as any[],
        )
      : entities.AllHtmlEntities.decode(result.value)
    : ''
}

export { I18nProvider }
