import { spacing, css, BreakpointArray, mediaQueryRenderer } from './'

type PaddingValue = string | number | BreakpointArray<string | number>

export interface Padding {
  top?: PaddingValue
  right?: PaddingValue
  bottom?: PaddingValue
  left?: PaddingValue
}

export const padding = (padding: Padding) => css`
  ${Object.entries(padding).map(([side, padding]: [string, PaddingValue]) => {
    if (padding === undefined) return null
    if (Array.isArray(padding)) {
      return mediaQueryRenderer(
        padding,
        (value) =>
          `padding-${side}: ${
            typeof value === 'number' ? spacing(value) : value
          };`,
      )
    }
    return `padding-${side}: ${
      typeof padding === 'number' ? spacing(padding) : padding
    };`
  })}
`
