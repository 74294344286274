import { breakpointUtils } from '@thg-commerce/gravity-theme/breakpoints'
import {
  CarouselReducerActions,
  CarouselReducerActionTypes,
  CarouselState,
} from './types'
import { CONTROLS_OFFSET } from './styles'

export const carouselReducer = (
  state: CarouselState,
  action: CarouselReducerActions,
) => {
  switch (action.type) {
    case CarouselReducerActionTypes.Resize:
      let itemsPerSlide: number
      if (typeof action.itemsPerSlide === 'number') {
        itemsPerSlide = action.itemsPerSlide
      } else {
        let breakpointIndex = 0
        Object.values(breakpointUtils.map).forEach((width, index) => {
          if (window.innerWidth > width) {
            breakpointIndex = index
          }
        })
        itemsPerSlide =
          action.itemsPerSlide[breakpointIndex] ||
          action.itemsPerSlide[action.itemsPerSlide.length - 1] ||
          1
      }

      const totalSlides = Math.ceil(action.itemsLength / itemsPerSlide)

      // This check fires on resize to prevent current slide state being greater than the total #slides
      const currentSlide =
        state.currentSlide >= totalSlides ? totalSlides - 1 : state.currentSlide

      let showControls = true
      if (action.hideControlsOnSingleSlide) {
        if (currentSlide === totalSlides - 1 && totalSlides < 2) {
          showControls = false
        }
      }

      const offset =
        action.hideSlidePreview || !showControls
          ? 0
          : action.hideControlsOnSingleSlide
          ? action.containerWidth /
            (itemsPerSlide === 1 ? 2 : itemsPerSlide) /
            2
          : CONTROLS_OFFSET

      const itemWidth = (action.containerWidth - offset) / itemsPerSlide
      const internetExplorerWidth = action.itemsLength * itemWidth

      state.onChange?.(currentSlide)

      return {
        ...state,
        totalSlides,
        itemsPerSlide,
        itemWidth,
        internetExplorerWidth,
        showControls,
        currentSlide,
      }

    case CarouselReducerActionTypes.SlideLeft: {
      if (state.loopSlides) {
        const currentSlide =
          state.currentSlide - 1 < 0
            ? state.totalSlides - 1
            : state.currentSlide - 1
        state.onChange?.(currentSlide)
        return { ...state, currentSlide }
      }
      const currentSlide = Math.max(0, state.currentSlide - 1)

      state.onChange?.(currentSlide)
      return { ...state, currentSlide }
    }

    case CarouselReducerActionTypes.SlideRight: {
      if (state.loopSlides) {
        const currentSlide =
          state.currentSlide + 1 > state.totalSlides - 1
            ? 0
            : state.currentSlide + 1
        state.onChange?.(currentSlide)
        return { ...state, currentSlide }
      }

      const currentSlide = Math.min(
        state.totalSlides - 1,
        state.currentSlide + 1,
      )
      state.onChange?.(currentSlide)
      return { ...state, currentSlide }
    }

    case CarouselReducerActionTypes.SlideTo: {
      const currentSlide = action.toIndex
      state.onChange?.(currentSlide)
      return { ...state, currentSlide }
    }

    case CarouselReducerActionTypes.Pause: {
      return { ...state, paused: !state.paused }
    }

    default:
      return state
  }
}
