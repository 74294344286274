import { css, mq } from '@thg-commerce/gravity-theme'

export const DrawFocusTitleMixin = () => css`
  outline: none;
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.dark};
  }
`

export const DrawFocusTitle = () => css`
  border: 2px solid transparent;
  margin-top: -2px;
  margin-left: -2px;
  padding: 2px;

  &:focus {
    ${DrawFocusTitleMixin()}
  }
`

export const DrawFocus = () => css`
  border: 2px solid transparent;
  margin: -2px;

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }
`
