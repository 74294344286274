import * as React from 'react'
export const SvgYandex = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={48} height={18} {...props}>
    <g fill="none">
      <path
        fill="#DA3332"
        d="M6.288 1.432h-.922c-1.394 0-2.743.903-2.743 3.524 0 2.533 1.237 3.348 2.743 3.348h.922V1.432zM4.85 9.714l-2.743 6.035H.104l3.014-6.454C1.7 8.59.756 7.313.756 4.955.756 1.653 2.893 0 5.434 0H8.02v15.75H6.288V9.714H4.85z"
      />
      <path
        fill="#000105"
        d="M41.661 10.198c0-3.48 1.372-5.705 4.025-5.705 1.012 0 1.597.265 2.092.573l-.248 1.63c-.472-.397-1.147-.77-1.866-.77-1.26 0-2.159 1.387-2.159 4.184 0 2.775.675 4.361 2.091 4.361.855 0 1.507-.33 1.912-.683l.405 1.278c-.608.507-1.327.837-2.362.837-2.428 0-3.89-1.916-3.89-5.705zm-5.127.022v5.529H34.78V4.647h1.754V9.78l2.72-5.133h1.777l-2.81 5.22 3.215 5.882h-1.911l-2.991-5.529zM28.28 9.185h2.9c0-1.894-.314-3.282-1.326-3.282-1.08 0-1.461 1.3-1.574 3.282zm1.934 6.718c-2.339 0-3.778-1.828-3.778-5.242 0-3.568 1.012-6.168 3.418-6.168 1.89 0 3.126 1.388 3.126 5.155v.947h-4.722c0 2.51.72 3.832 2.113 3.832.99 0 1.777-.44 2.227-.749L33.003 15c-.72.55-1.664.903-2.788.903zm-7.466-9.868h-1.956v.198c0 2.335-.113 5.75-1.057 8.106h3.013V6.035zM25.38 17.93h-1.574v-2.18h-4.857v2.18h-1.575v-3.59h.698c1.034-2.357 1.147-5.793 1.147-8.744v-.948h5.262v9.692h.9v3.59zM16.363 4.647V15.75H14.63v-4.98h-2.609v4.978H10.27V4.647h1.753v4.714h2.61V4.647h1.73z"
      />
    </g>
  </svg>
)
export default SvgYandex
