import * as React from 'react'

import { NavigationItem } from '../../../../NavigationTree'
import {
  DetachedFeatureWrapper,
  DetachedLink,
  DetachedList,
  DetachedListItem,
  HeadingWrapper,
} from '../styles'

const getDetachedNavigationItems = (navigationItems: NavigationItem[]) => {
  return navigationItems.map((navigationItem, listIndex) => {
    return (
      <DetachedList key={`detached-list-${listIndex}`}>
        <HeadingWrapper>{navigationItem.displayName}</HeadingWrapper>
        {navigationItem.subNavigation?.map(
          (levelThreeNavigationItem, linkIndex) => (
            <DetachedListItem key={`detached-link-${linkIndex}`}>
              <DetachedLink href={levelThreeNavigationItem.link?.url}>
                {levelThreeNavigationItem.displayName}
              </DetachedLink>
            </DetachedListItem>
          ),
        )}
      </DetachedList>
    )
  })
}

export const DetachedFeatureMenu = (props: {
  navigationItems: NavigationItem[]
  showDetachedList?: boolean | undefined
}) => {
  return (
    <DetachedFeatureWrapper showDetachedList={props.showDetachedList}>
      {getDetachedNavigationItems(props.navigationItems)}
    </DetachedFeatureWrapper>
  )
}
