import * as React from 'react'
export const SvgReferralsOrder = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={23} height={22} viewBox="0 0 23 22" {...props}>
    <g stroke="none" stroke-width="1">
      <g transform="translate(-452.000000, -1935.000000)">
        <g transform="translate(92.000000, 1887.000000)">
          <g transform="translate(360.000000, 48.000000)">
            <g>
              <path d="M3.92548077,0 L3.67668269,0.442307692 L1.18870192,4.58894231 C1.04402414,4.70137068 0.937780602,4.85590674 0.884615385,5.03125 L0.884615385,5.05889423 C0.837059246,5.22135085 0.837059246,5.39403377 0.884615385,5.55649038 L0.884615385,20.3461538 L0.884615385,21.2307692 L1.76923077,21.2307692 L11.5,21.2307692 L21.2307692,21.2307692 L22.1153846,21.2307692 L22.1153846,20.3461538 L22.1153846,5.47355769 C22.1205788,5.41839123 22.1205788,5.36285877 22.1153846,5.30769231 L22.1153846,5.19711538 C22.1189893,5.15111217 22.1189893,5.10489744 22.1153846,5.05889423 L22.0877404,5.03125 C22.0878844,5.02203582 22.0878844,5.01281995 22.0877404,5.00360577 L22.0600962,4.94831731 C22.005756,4.84221104 21.9305323,4.74818136 21.8389423,4.671875 L19.3233173,0.442307692 L19.0745192,0 L18.5769231,0 L4.42307692,0 L3.92548077,0 Z M4.89302885,1.76923077 L10.8365385,1.76923077 L10.6706731,4.42307692 L3.31730769,4.42307692 L4.89302885,1.76923077 Z M12.1634615,1.76923077 L18.1069712,1.76923077 L19.6826923,4.42307692 L12.3293269,4.42307692 L12.1634615,1.76923077 Z M2.65384615,6.19230769 L20.3461538,6.19230769 L20.3461538,19.4615385 L11.5,19.4615385 L2.65384615,19.4615385 L2.65384615,6.19230769 Z M7.79567308,8.84615385 C7.30711349,8.89195631 6.94818792,9.32514234 6.99399038,9.81370192 C7.03979285,10.3022615 7.47297888,10.6611871 7.96153846,10.6153846 L15.0384615,10.6153846 C15.3574856,10.6198964 15.6542258,10.4522834 15.8150545,10.1767279 C15.9758832,9.90117234 15.9758832,9.56036613 15.8150545,9.2848106 C15.6542258,9.00925507 15.3574856,8.84164205 15.0384615,8.84615385 L7.96153846,8.84615385 C7.93390943,8.84485744 7.9062348,8.84485744 7.87860577,8.84615385 C7.85097671,8.84485873 7.82330214,8.84485873 7.79567308,8.84615385 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default SvgReferralsOrder
