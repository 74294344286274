import { Property } from 'csstype'
import { colors } from '../colors'

export interface StyleInterface {
  backgroundColor: Property.Color
}

export interface SurfaceInterface {
  style: StyleInterface
}

export const surface: SurfaceInterface = {
  style: {
    backgroundColor: colors.palette.greys.white,
  },
}
