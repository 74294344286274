export type BorderStyles = {
  borderRadius: number
  borderWidth: number
}

export type IconStyles = {
  minus: {
    svgPath: string
  }
  plus: {
    svgPath: string
  }
  width: string
  height: string
  viewBox: string
}

export interface QuantitySelectorThemeInterface {
  border: BorderStyles
  icon: IconStyles
}

export const theme: QuantitySelectorThemeInterface = {
  border: {
    borderRadius: 4,
    borderWidth: 1,
  },
  icon: {
    minus: {
      svgPath: '',
    },
    plus: {
      svgPath: '',
    },
    width: '24px',
    height: '24px',
    viewBox: '0 0 24 24',
  },
}
