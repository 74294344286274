import React from 'react'

export const InfoClickAndCollect = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8446 1.86082L19.8946 6.23082C20.1446 7.25082 19.9146 8.30082 19.2746 9.12082C19.221 9.18789 19.1538 9.25046 19.0883 9.31155L19.0883 9.31157L19.0882 9.31158L19.0882 9.3116C19.056 9.34158 19.0243 9.3712 18.9946 9.40082V16.3408C18.9946 17.4408 18.0946 18.3408 16.9946 18.3408H2.99464C1.89464 18.3408 0.994636 17.4408 0.994636 16.3408V9.40082C0.958635 9.36082 0.919435 9.32402 0.880234 9.28722C0.821435 9.23202 0.762635 9.17682 0.714636 9.11082C0.0746356 8.29082 -0.145364 7.25082 0.0946355 6.23082L1.14464 1.86082C1.35464 0.97082 2.14464 0.34082 3.04464 0.34082H16.9346C17.8446 0.34082 18.6246 0.96082 18.8446 1.86082ZM17.9546 6.70082L16.9046 2.33082L14.9746 2.34082L15.5546 7.20082C15.6246 7.85082 16.1546 8.34082 16.7646 8.34082C17.2646 8.34082 17.5646 8.05082 17.7046 7.87082C17.9646 7.54082 18.0546 7.12082 17.9546 6.70082ZM10.9946 2.34082H12.9546L13.4946 6.86082C13.5446 7.25082 13.4246 7.64082 13.1646 7.93082C12.9446 8.19082 12.6246 8.34082 12.2146 8.34082C11.5446 8.34082 10.9946 7.75082 10.9946 7.03082V2.34082ZM7.03464 2.34082L6.48464 6.86082C6.44464 7.25082 6.56464 7.64082 6.81464 7.93082C7.05464 8.19082 7.36464 8.34082 7.70464 8.34082C8.44464 8.34082 8.99464 7.75082 8.99464 7.03082V2.34082H7.03464ZM2.03453 6.70082L3.04453 2.34082H5.01453L4.43453 7.20082C4.35453 7.85082 3.83453 8.34082 3.22453 8.34082C2.73453 8.34082 2.42453 8.05082 2.29453 7.87082C2.02453 7.55082 1.93453 7.12082 2.03453 6.70082ZM2.99464 10.3108V16.3408H17.0046V10.3108C16.9766 10.3143 16.9497 10.3191 16.9233 10.3237C16.8743 10.3324 16.8266 10.3408 16.7746 10.3408C15.9046 10.3408 15.1146 9.98082 14.5346 9.39082C13.9346 9.99082 13.1346 10.3408 12.2946 10.3408C11.3946 10.3408 10.5946 9.98082 10.0046 9.41082C9.42464 9.98082 8.64464 10.3408 7.77464 10.3408C6.86464 10.3408 6.06464 9.99082 5.46464 9.39082C4.88464 9.98082 4.09464 10.3408 3.22464 10.3408C3.17271 10.3408 3.12499 10.3324 3.07602 10.3237C3.04955 10.3191 3.02271 10.3143 2.99464 10.3108Z"
      fill={props.fill || '#333333'}
    />
    <rect x="1" y="16.3408" width="2" height="2" />
    <rect x="17" y="16.3408" width="2" height="2" />
    <rect x="17" y="0.34082" width="2" height="2" />
    <rect x="1" y="0.34082" width="2" height="2" />
  </svg>
)
export default InfoClickAndCollect
