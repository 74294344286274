import * as React from 'react'
import useMap from 'react-hanger/array/useMap'
import withHydrationOnDemand from 'react-hydration-on-demand'
import ReactResizeDetector from 'react-resize-detector'

import { Overlay } from '@thg-commerce/gravity-elements'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'
import { KeyboardKeys } from '@thg-commerce/gravity-theme'

import {
  NavigationItem,
  NavigationItemType,
  NavigationTree,
} from '../NavigationTree'
import { HeaderZIndexLevel } from '../types'

import { Brand } from './NavigationContent/Brands/Brands'
import { NavigationContent } from './NavigationContent'
import { NavigationTabPanel } from './NavigationTabPanel'
import {
  ContentPanelWrapper,
  NavigationWrapper,
  NavItemContainer,
  StyledEmptyNavigation,
  StyledLink,
  StyledNavigation,
} from './styles'

const PrefetchableLink = withPrefetch('href', StyledLink)

interface NavigationProps {
  truncateNavigationItems?: boolean
  navigationTree: NavigationTree
  headerPromotionUrl?: string
  headerPromotionUrlTwo?: string
  hasDynamicHeaderDropdown?: boolean
  hasWaterfallMenu?: boolean
  i18nText: {
    categoryHomeText: string
    viewAllBrandsText: string
    navAriaLabel: string
  }
  brandsData: { brands: Brand[] }
  routes: { viewAllBrandsLink: string; listExtension: string }
  navWrapperHeight: string
  navHeaderHeight?: number
  headerRef?: React.RefObject<HTMLDivElement>
  fullWidthSeparator?: boolean
  enableThreeTierNav?: boolean
  selectTopLevelNavigationItem?: (path: string) => void
  selectedTopLevelNavigationItemIndex?: number
  navigationOnClickCallback?: (data: NavigationEventData) => void
  enableAlternateNavGrid?: boolean
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  enableMasonryGridMenu?: boolean
}

export interface NavigationEventData {
  selectedTopLevelNavigation?: string
  selectedSecondLevelNavigation?: string
  selectedThirdLevelNavigation?: string
}

export type AnimationActions = [
  string,
  'close' | 'open' | 'closeInstant' | 'openInstant',
]
const navigationWrapper =
  typeof document !== 'undefined'
    ? document.querySelector("[data-testid='navigation-wrapper']")
    : null

const NavigationTabPanelComponent = withHydrationOnDemand({
  on: [
    ['mouseenter', () => navigationWrapper],
    ['focus', () => navigationWrapper],
    ['keydown', () => navigationWrapper],
  ],
})(NavigationTabPanel)

const LEFT_OFFSET = 0

const firstSubNav = (item: NavigationItem) =>
  item?.subNavigation && item.subNavigation[0]
const secondSubNav = (item: NavigationItem) =>
  item?.subNavigation && item.subNavigation[1]

export const Navigation = (props: NavigationProps) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(-1)
  const [activeClickedIndex, setActiveClickedIndex] = React.useState<number>(-1)
  const [headerWidth, setHeaderWidth] = React.useState<number>(1400)

  const navWrapperRef = React.useRef<HTMLDivElement>(null)
  const threeTierNavWrapperRef = React.useRef<HTMLDivElement>(null)
  const debounceTimeoutRef = React.useRef<
    ReturnType<typeof setTimeout> | number
  >(0)

  const theme = useTheme()
  const topLevel =
    props.navigationTree && props.navigationTree.navigation.topLevel

  const navigationMenus = props.enableThreeTierNav
    ? topLevel?.[activeClickedIndex]?.subNavigation || []
    : topLevel

  const startingArray: AnimationActions[] = Array.from(
    { length: navigationMenus?.length },
    (_, index) => {
      return [`${index}`, 'close']
    },
  )

  const [animations, animationActions] = useMap(
    new Map<string, 'open' | 'close' | 'openInstant' | 'closeInstant'>(
      startingArray,
    ),
  )

  React.useEffect(() => {
    if (typeof props.selectedTopLevelNavigationItemIndex === 'number') {
      setActiveClickedIndex(props.selectedTopLevelNavigationItemIndex)
    }
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current)
        debounceTimeoutRef.current = 0
      }
    }
  }, [props.selectedTopLevelNavigationItemIndex])

  if (!props.navigationTree) {
    return <StyledEmptyNavigation />
  }

  const hasAllClosed = (
    animationArray: Map<
      string,
      'open' | 'close' | 'openInstant' | 'closeInstant'
    >,
  ) => {
    return !Array.from(animationArray.values()).some((el) => {
      return el === 'open' || el === 'openInstant'
    })
  }

  const onLinkHover = (index: number, showContent: boolean) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current)
      debounceTimeoutRef.current = 0
    }
    if (!showContent) {
      debounceTimeoutRef.current = setTimeout(() => {
        animationActions.initialize(startingArray)
      }, 200)
      return
    }
    if (hasAllClosed(animations) || !animations.get(`${index}`)) {
      debounceTimeoutRef.current = setTimeout(() => {
        animationActions.set(`${index}`, 'open')
        setActiveIndex(index)
      }, 200)
    } else {
      debounceTimeoutRef.current = setTimeout(() => {
        animationActions.initialize(
          startingArray.map((_, itemIndex) =>
            index === itemIndex
              ? [`${itemIndex}`, 'openInstant']
              : [`${itemIndex}`, 'closeInstant'],
          ),
        )
        setActiveIndex(index)
      }, 200)
    }
  }

  const onLinkKeyDown = (event: React.KeyboardEvent, index: number) => {
    switch (event.key) {
      case KeyboardKeys.ArrowRight:
        const nextTab: HTMLElement | null = document.querySelector(
          `[data-tab-index="${index + 1}"]`,
        )
        if (nextTab) {
          nextTab.focus()
        }
        break
      case KeyboardKeys.ArrowLeft:
        const prevTab: HTMLElement | null = document.querySelector(
          `[data-tab-index="${index - 1}"]`,
        )
        if (prevTab) {
          prevTab.focus()
        }
        break
    }
  }

  const onContentKeyDown = (event: React.KeyboardEvent, index: number) => {
    switch (event.key) {
      case KeyboardKeys.Escape:
        const currentTab: HTMLElement | null = document.querySelector(
          `[data-tab-index="${index}"]`,
        )
        if (currentTab) {
          currentTab.focus()
        }
        break
    }
  }

  const handleClick = (item: NavigationItem, index: number) => {
    props.selectTopLevelNavigationItem &&
      item.link?.url &&
      props.selectTopLevelNavigationItem(item.link.url)
    props.enableThreeTierNav && setActiveClickedIndex(index)
    props.navigationOnClickCallback &&
      props.navigationOnClickCallback({
        selectedTopLevelNavigation: item?.displayName ?? '',
      })
  }

  const promotionsBackground = (item: NavigationItem) =>
    props.headerPromotionUrl && props.headerPromotionUrl === item.link?.url
      ? theme?.patterns.header.navigation.promotionItem.backgroundColour
      : ''

  const promotionsBackgroundTwo = (item: NavigationItem) =>
    props.headerPromotionUrlTwo &&
    props.headerPromotionUrlTwo === item.link?.url
      ? theme?.patterns.header.navigation.promotionItem.backgroundColourTwo
      : ''

  const promotionsTextColorOne = (item: NavigationItem) =>
    props.headerPromotionUrl && props.headerPromotionUrl === item.link?.url
      ? theme?.patterns.header.navigation.promotionItem.textColourOne ||
        theme?.colors.system.pap.sale ||
        theme?.colors.system.pap.title
      : ''

  const promotionsTextStyleOne = (item: NavigationItem) =>
    props.headerPromotionUrl && props.headerPromotionUrl === item.link?.url
      ? theme?.patterns.header.navigation.promotionItem.textStyleOne
      : ''

  const promotionsTextColorTwo = (item: NavigationItem) =>
    props.headerPromotionUrlTwo &&
    props.headerPromotionUrlTwo === item.link?.url
      ? theme?.patterns.header.navigation.promotionItem.textColourTwo
      : ''

  const newNavLinks = topLevel.map((item, index) => {
    const showContent =
      item.type === 'BRANDS' ||
      (item?.subNavigation && item?.subNavigation?.length > 0)

    return (
      <NavItemContainer key={`nav-item-${index}`}>
        <PrefetchableLink
          onMouseEnter={() => {
            !props.enableThreeTierNav && onLinkHover(index, !!showContent)
          }}
          onClick={() => handleClick(item, index)}
          onFocus={() => {
            !props.enableThreeTierNav && onLinkHover(index, !!showContent)
          }}
          onKeyDown={(e) => {
            onLinkKeyDown(e, index)
          }}
          promotionTextColour={promotionsTextColorOne(item)}
          promotionTextStyle={promotionsTextStyleOne(item)}
          promotionBackgroundColour={promotionsBackground(item)}
          promotionBackgroundColourTwo={promotionsBackgroundTwo(item)}
          promotionTextColourTwo={promotionsTextColorTwo(item)}
          ref={null}
          href={item.link?.url}
          data-tab-index={`${index}`}
          data-testid={`navigation-link-${index}`}
          isActive={
            props.enableThreeTierNav
              ? activeClickedIndex === index
              : activeIndex === index
          }
          enableThreeTierNav={props.enableThreeTierNav}
          firstTier={true}
        >
          {item.displayName}
        </PrefetchableLink>
        <ContentPanelWrapper
          isOpen={
            animations.get(`${index}`) === 'open' ||
            animations.get(`${index}`) === 'openInstant'
          }
          positionTopOffset={props.navWrapperHeight}
          type={item.type}
          onKeyDown={(e) => {
            onContentKeyDown(e, index)
          }}
          onMouseEnter={() => {
            clearTimeout(debounceTimeoutRef.current)
            debounceTimeoutRef.current = 0
          }}
        >
          <NavigationTabPanelComponent
            navHeaderHeight={props.navHeaderHeight}
            hasFeaturedColumn={
              firstSubNav(item)?.type === NavigationItemType.FEATURED || false
            }
            hasDetachedMenu={
              secondSubNav(item)?.type === NavigationItemType.DETACHED || false
            }
            positionLeftOffset={LEFT_OFFSET}
            isVariableWidth={!!props.hasDynamicHeaderDropdown}
            type={item.type}
            animation={animations.get(`${index}`) || 'close'}
            index={index}
          >
            {!props.enableThreeTierNav && showContent && (
              <NavigationContent
                hasWaterfallMenu={props.hasWaterfallMenu || false}
                dynamicWidth={!!props.hasDynamicHeaderDropdown}
                item={item}
                brandsData={props.brandsData}
                routes={props.routes}
                i18nText={{
                  viewAllBrands: props.i18nText.viewAllBrandsText,
                  navAriaLabel: props.i18nText.navAriaLabel,
                }}
                enableThreeTierNav={props.enableThreeTierNav || false}
                enableAlternateNavGrid={props.enableAlternateNavGrid}
                enableMasonryGridMenu={props.enableMasonryGridMenu}
                navSubLevelPromotionList={props.navSubLevelPromotionList}
                navLevelThreePromotionList={props.navLevelThreePromotionList}
                onClick={(e?: string) => {
                  props.navigationOnClickCallback &&
                    props.enableThreeTierNav &&
                    props.navigationOnClickCallback({
                      selectedTopLevelNavigation:
                        topLevel?.[index]?.displayName,
                      selectedSecondLevelNavigation: item?.displayName,
                      selectedThirdLevelNavigation: e || '',
                    })
                }}
                navigationOnClickCallback={props.navigationOnClickCallback}
                navigationTopLevel={props.navigationTopLevel}
              />
            )}
          </NavigationTabPanelComponent>
        </ContentPanelWrapper>
      </NavItemContainer>
    )
  })

  const threeTierNavLinks = (
    topLevel?.[activeClickedIndex]?.subNavigation || []
  ).map((item, index) => {
    const showContent =
      item.type === 'BRANDS' ||
      (item?.subNavigation && item?.subNavigation?.length > 0)

    return (
      <NavItemContainer
        data-cs-override-id={`${topLevel?.[activeClickedIndex]?.displayName}_${item.displayName}`}
        key={`three-tier-nav-item-${index}`}
        enableThreeTierNav={props.enableThreeTierNav}
      >
        <PrefetchableLink
          onMouseEnter={() => onLinkHover(index, !!showContent)}
          onFocus={() => {
            onLinkHover(index, !!showContent)
          }}
          onKeyDown={(e) => {
            onLinkKeyDown(e, index)
          }}
          {...(props.navigationOnClickCallback && {
            onClick: () => {
              props.navigationOnClickCallback?.({
                selectedTopLevelNavigation:
                  topLevel?.[activeClickedIndex]?.displayName,
                selectedSecondLevelNavigation: item?.displayName,
              })
            },
          })}
          ref={null}
          data-tab-index={`${index}`}
          href={item.link?.url}
          data-testid={`three-tier-navigation-link-${index}`}
          isActive={activeIndex === index}
          enableThreeTierNav={props.enableThreeTierNav}
        >
          {item.displayName}
        </PrefetchableLink>
        <ContentPanelWrapper
          isOpen={
            animations.get(`${index}`) === 'open' ||
            animations.get(`${index}`) === 'openInstant'
          }
          positionTopOffset={props.navWrapperHeight}
          type={item.type}
          enableThreeTierNav={props.enableThreeTierNav}
          onKeyDown={(e) => {
            onContentKeyDown(e, index)
          }}
          onMouseEnter={() => {
            clearTimeout(debounceTimeoutRef.current)
            debounceTimeoutRef.current = 0
          }}
        >
          <NavigationTabPanelComponent
            navHeaderHeight={props.navHeaderHeight}
            hasFeaturedColumn={
              firstSubNav(item)?.type === NavigationItemType.FEATURED || false
            }
            hasDetachedMenu={
              secondSubNav(item)?.type === NavigationItemType.DETACHED || false
            }
            positionLeftOffset={LEFT_OFFSET}
            isVariableWidth={!!props.hasDynamicHeaderDropdown}
            type={item.type}
            animation={animations.get(`${index}`) || 'close'}
            index={index}
          >
            {showContent && (
              <NavigationContent
                hasWaterfallMenu={props.hasWaterfallMenu || false}
                dynamicWidth={!!props.hasDynamicHeaderDropdown}
                item={item}
                brandsData={props.brandsData}
                routes={props.routes}
                i18nText={{
                  viewAllBrands: props.i18nText.viewAllBrandsText,
                  navAriaLabel: props.i18nText.navAriaLabel,
                }}
                enableThreeTierNav={props.enableThreeTierNav || false}
                enableAlternateNavGrid={props.enableAlternateNavGrid}
                enableMasonryGridMenu={props.enableMasonryGridMenu}
                navSubLevelPromotionList={props.navSubLevelPromotionList}
                navLevelThreePromotionList={props.navLevelThreePromotionList}
                onClick={(e?: string) => {
                  props.navigationOnClickCallback &&
                    props.enableThreeTierNav &&
                    props.navigationOnClickCallback({
                      selectedTopLevelNavigation:
                        topLevel?.[index]?.displayName,
                      selectedSecondLevelNavigation: item?.displayName,
                      selectedThirdLevelNavigation: e || '',
                    })
                }}
                navigationOnClickCallback={navigationOnClickCallback}
                navigationTopLevel={navigationTopLevel}
              />
            )}
          </NavigationTabPanelComponent>
        </ContentPanelWrapper>
      </NavItemContainer>
    )
  })

  return (
    <React.Fragment>
      <StyledNavigation fullWidthSeparator={props.fullWidthSeparator}>
        <NavigationWrapper
          isOpen={!hasAllClosed(animations)}
          fullWidthSeparator={
            props.fullWidthSeparator && !props.enableThreeTierNav
          }
          truncateNavigationItems={props.truncateNavigationItems}
          onMouseEnter={() => {}}
          onMouseLeave={() => {
            if (!props.enableThreeTierNav) {
              clearTimeout(debounceTimeoutRef.current)
              debounceTimeoutRef.current = 0
              animationActions.initialize(startingArray)
              setActiveIndex(-1)
            }
          }}
          onBlur={(e) => {
            if (
              e.relatedTarget &&
              e.relatedTarget instanceof Node &&
              !e.currentTarget.contains(e.relatedTarget)
            ) {
              clearTimeout(debounceTimeoutRef.current)
              debounceTimeoutRef.current = 0
              animationActions.initialize(startingArray)
              setActiveIndex(-1)
            }
          }}
          data-testid="navigation-wrapper"
          ref={navWrapperRef}
        >
          {newNavLinks}
        </NavigationWrapper>
        {props.enableThreeTierNav && (
          <ReactResizeDetector
            handleWidth
            refreshMode="debounce"
            refreshRate={100}
            onResize={() =>
              props.headerRef?.current &&
              setHeaderWidth(props.headerRef.current?.clientWidth)
            }
          >
            <NavigationWrapper
              isOpen={!hasAllClosed(animations)}
              fullWidthSeparator={props.fullWidthSeparator}
              truncateNavigationItems={props.truncateNavigationItems}
              onMouseEnter={() => {}}
              onMouseLeave={() => {
                clearTimeout(debounceTimeoutRef.current)
                debounceTimeoutRef.current = 0
                animationActions.initialize(startingArray)
                setActiveIndex(-1)
              }}
              onBlur={(e) => {
                if (
                  e.relatedTarget &&
                  e.relatedTarget instanceof Node &&
                  !e.currentTarget.contains(e.relatedTarget)
                ) {
                  clearTimeout(debounceTimeoutRef.current)
                  debounceTimeoutRef.current = 0
                  animationActions.initialize(startingArray)
                  setActiveIndex(-1)
                }
              }}
              data-testid="three-tier-navigation-wrapper"
              ref={threeTierNavWrapperRef}
              enableThreeTierNav={props.enableThreeTierNav}
              headerWidth={headerWidth}
            >
              {threeTierNavLinks}
            </NavigationWrapper>
          </ReactResizeDetector>
        )}
      </StyledNavigation>
      <Overlay
        isShowing={!hasAllClosed(animations)}
        zIndex={HeaderZIndexLevel.DesktopNavOverlay}
      />
    </React.Fragment>
  )
}

export default Navigation
