const yup = require('yup')

export const validate_columbo_event = async (payload): Promise<boolean> => {
  const columboSchema = yup.object().shape({
    nonce: yup.number().required(),
    args: yup.object(),
    attributes: yup.array().required(),
    experiments: yup.array().required(),
    errors: yup.array().required(),
    device: yup.object().shape({
      type: yup.object().shape({
        is_mobile: yup.string(),
        is_tablet: yup.string(),
        is_pc: yup.string(),
      }),
      screen: yup.object().shape({
        width: yup.string(),
        height: yup.string(),
        devicePixelRatio: yup.string(),
      }),
      browser: yup.object().shape({
        family: yup.string(),
        version: yup.string(),
        major_version: yup.string(),
      }),
      ip: yup.object().shape({
        country: yup.string(),
      }),
    }),
    event: yup.object().shape({
      type: yup.string().required(),
      subtype: yup.string().required(),
      viewport_width: yup.number().required(),
      contents: yup.array().required(),
    }),
    property: yup.object().shape({
      site_id: yup.number().required(),
      channel: yup.string().required(),
      subsite: yup.string().required(),
      shipping_country_code: yup.string(),
      is_mobile_version: yup.string(),
      locale: yup.string(),
    }),
    request: yup.object().shape({
      client_timestamp: yup.number().required(),
      url: yup.string().required(),
    }),
    insert_id: yup.string().required(),
    key: yup.string().required(),
    server: yup.object().shape({
      ip: yup.string(),
      hostname: yup.string(),
      elysium_version: yup.string(),
      columbo_version: yup.string(),
    }),
  })
  return await columboSchema.isValid(payload)
}
