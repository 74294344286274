import {
  string as yupString,
  object as yupObject,
  number as yupNumber,
  array as yupArray,
} from 'yup'

export const validate_perf = async (payload): Promise<boolean> => {
  const performanceSchema = yupObject().shape({
    meta: yupObject().shape({
      sessionId: yupString().required(),
      userId: yupString().required(),
      URL: yupString().required(),
      siteDefaultLocale: yupString().required(),
      countryCode: yupString().required(),
      currency: yupString().required(),
      customerLocale: yupString().required(),
      customerLocation: yupString().required(),
      shippingCountry: yupString().required(),
      elysiumVersion: yupString().required(),
      serverIP: yupString().required(),
      siteCode: yupString().required(),
      subsiteCode: yupString().required(),
      siteID: yupString().required(),
      productID: yupString().required(),
      backendLoadTime: yupNumber().required(),
      cacheTime: yupNumber().required(),
      connectionTime: yupNumber().required(),
      dnsTime: yupNumber().required(),
      domInteractiveTime: yupNumber().required(),
      domParsingTime: yupNumber().required(),
      domReadyTime: yupNumber().required(),
      frontendLoadTime: yupNumber().required(),
      navigationTime: yupNumber().required(),
      redirectTime: yupNumber().required(),
    }),
    experiments: yupArray().required(),
    performance: yupObject().shape({
      backendLoadTime: yupNumber().required(),
      cacheTime: yupNumber().required(),
      connectionTime: yupNumber().required(),
      dnsTime: yupNumber().required(),
      domInteractiveTime: yupNumber().required(),
      domParsingTime: yupNumber().required(),
      domReadyTime: yupNumber().required(),
      frontendLoadTime: yupNumber().required(),
      navigationTime: yupNumber().required(),
      redirectTime: yupNumber().required(),
    }),
    products: yupArray().required(),
    key: yupString(),
  })
  const valid = await performanceSchema.isValid(payload)
  return valid
}
