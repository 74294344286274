import * as React from 'react'
export const SvgSmartphone = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || '#333'}
      fillRule="evenodd"
      d="M18 2v20H6V2h12zm-6 17a1 1 0 100 2 1 1 0 000-2zm4-14H8v13h8V5zm-2-2h-4v1h4V3z"
    />
  </svg>
)
export default SvgSmartphone
