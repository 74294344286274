import * as React from 'react'
export const SvgSystemIconPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M11 11V6h2v5h5v2h-5v5h-2v-5H6v-2z"
      fill={props.fill || '#1976D2'}
      fillRule="evenodd"
    />
  </svg>
)
export default SvgSystemIconPlus
