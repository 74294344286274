import { SiteDefinitionInterface } from '@thg-commerce/enterprise-config'

import { SiteConfigInterface } from '../ConfigurationLoader/types'
import { MetaElement } from '../Head/renderer'

export interface PageTypes {
  pageType: string
  origin: string
  path: string
}

export const facebookMetaTags = (
  { origin, path, pageType }: PageTypes,
  siteDefinition: SiteDefinitionInterface,
  siteConfig: SiteConfigInterface,
): MetaElement[] => {
  const { siteName } = siteDefinition
  const { FacebookMetaAdmins, SocialOgTitle, FacebookAppId } = siteConfig

  if (!FacebookAppId) {
    return []
  }

  const tagNames: { [type: string]: { [key: string]: string } } = {
    product: {
      'fb:app_id': FacebookAppId,
      'og:type': 'product',
    },
    list: {
      'fb:app_id': FacebookAppId,
      'og:type': 'article',
      'og:image': `${origin}/c-images/fb-icon.png`,
    },
    '': {
      'fb:app_id': FacebookAppId,
      'og:type': 'article',
      'og:image': `${origin}/c-images/fb-icon.png`,
    },
    default: {
      'fb:app_id': FacebookAppId,
      'og:title': siteName || SocialOgTitle || '',
      'og:site_name': siteName,
      'og:url': `${origin}${path}`,
      'fb:admins': FacebookMetaAdmins || '',
    },
  }

  const tags =
    tagNames[pageType] === undefined || pageType === null
      ? { ...tagNames[''], ...tagNames.default }
      : { ...tagNames[pageType], ...tagNames.default }

  return Object.entries(tags).map(([key, value]) => {
    return {
      type: 'meta',
      props: {
        property: key,
        content: value,
      },
    }
  })
}
