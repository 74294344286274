export type HorizontalScrollWrapperThemeInterface = {
  chevronIcon: {
    right: {
      svgPath: string
    }
    left: {
      svgPath: string
    }
    viewBox: string
    width: string
    height: string
  }
}

export const theme: HorizontalScrollWrapperThemeInterface = {
  chevronIcon: {
    right: {
      svgPath: '',
    },
    left: {
      svgPath: '',
    },
    viewBox: '0 0 24 24',
    width: '24px',
    height: '24px',
  },
}
