import * as React from 'react'
export const SvgClipboard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <g fill="none">
      <path
        stroke={props.fill || '#4d4f53'}
        d="M27 12H13v17h14V12z"
        strokeWidth={2}
      />
      <path
        stroke={props.fill || '#4d4f53'}
        strokeWidth={2}
        d="M16 20l3.031 3.035L23.983 17"
      />
      <path
        fill={props.fill || '#4d4f53'}
        d="M16 10a2 2 0 012-2v2h-2zm0 1h8v2h-8zm8-1a2 2 0 00-2-2v2h2zm-6-2h4v2h-4z"
      />
      <path fill={props.fill || '#4d4f53'} d="M22 10h2v2h-2zm-6 0h2v2h-2z" />
    </g>
  </svg>
)
export default SvgClipboard
