import { redis } from './redis'

const NAMESPACE = process.env.CACHE_NAMESPACE || ''
const CACHE_LOCK_TTL_SECONDS = process.env.REDIS_LOCK_TTL_SECONDS
  ? parseInt(process.env.REDIS_LOCK_TTL_SECONDS, 10)
  : 300
const DEFAULT_BACKGROUND_TTL_SECONDS = process.env
  .DEFAULT_BACKGROUND_TTL_SECONDS
  ? parseInt(process.env.DEFAULT_BACKGROUND_TTL_SECONDS, 10)
  : 86400 // 1 day

export enum FetchMode {
  FOREGROUND,
  ALWAYS_BACKGROUND,
  BACKGROUND_IF_EXPIRED,
}

interface StoreConfig<Args, Type> {
  key: string | ((args: Args) => string)
  ttlSeconds: number
  staleSeconds?: number
  lookup: (args: Args) => Promise<Type> | Type
  forceBackground?: boolean
  fetchMode?: FetchMode
}

interface LookupContext<Args, Type> {
  storeConfig: StoreConfig<Args, Type>
  fetchMode: FetchMode
}

interface CachedObject<Type> {
  object: Type
  expires: number
  stale?: number
}

const generateKey = (key: string) => {
  if (!NAMESPACE) {
    return key
  }
  return `${NAMESPACE}-${key}`
}

const lookup = async <A, T extends any>(
  context: LookupContext<A, T>,
  args: A,
) => {
  return new Promise<T>(async (resolve, reject) => {
    try {
      const lookupPromise = context.storeConfig.lookup(args)
      const object = await Promise.resolve(lookupPromise)

      if (redis && object) {
        const key =
          typeof context.storeConfig.key === 'function'
            ? context.storeConfig.key(args)
            : context.storeConfig.key
        const prefixedKey = generateKey(key)
        const expires =
          new Date().getTime() + context.storeConfig.ttlSeconds * 1000
        const stale = context.storeConfig.staleSeconds
          ? new Date().getTime() + context.storeConfig.staleSeconds * 1000
          : undefined
        const cachedObject: CachedObject<T> = {
          object,
          expires,
          stale,
        }

        const data = JSON.stringify(cachedObject, (_, value) =>
          value instanceof Set ? Array.from(value) : value,
        )
        if (context.fetchMode === FetchMode.FOREGROUND) {
          await redis.set(
            prefixedKey,
            data,
            'EX',
            context.storeConfig.ttlSeconds,
          )
        } else {
          await redis.set(
            prefixedKey,
            data,
            'EX',
            DEFAULT_BACKGROUND_TTL_SECONDS,
          )
        }
      }

      resolve(object)
    } catch (e) {
      reject(e)
    }
  })
}

const lock = async (key: string) => {
  if (!redis) {
    return false
  }
  const lockKey = `${key}_lock`
  const locked = await redis.set(
    lockKey,
    '1',
    'EX',
    CACHE_LOCK_TTL_SECONDS,
    'NX',
  )
  return locked === 'OK'
}

const releaseLock = async (key: string) => {
  if (!redis) {
    return
  }
  const lockKey = `${key}_lock`
  await redis.del(lockKey)
}

export const createStore = <Args, Type extends any>(
  storeConfig: StoreConfig<Args, Type>,
) => {
  const fetchMode =
    storeConfig.fetchMode ||
    (storeConfig.forceBackground
      ? FetchMode.ALWAYS_BACKGROUND
      : FetchMode.FOREGROUND)

  const lookupContext = {
    fetchMode,
    storeConfig,
  }

  return {
    get: async (args: Args): Promise<Type | undefined> => {
      if (!redis) {
        return await lookup(lookupContext, args)
      }
      const key =
        typeof storeConfig.key === 'function'
          ? storeConfig.key(args)
          : storeConfig.key
      const prefixedKey = generateKey(key)
      const data = await redis.get(prefixedKey)
      if (!data) {
        if (
          fetchMode !== FetchMode.BACKGROUND_IF_EXPIRED &&
          !(await lock(prefixedKey))
        ) {
          return undefined
        }

        const lookupPromise = lookup(lookupContext, args)
          .catch((e) => {
            console.warn(
              `Failed to fetch cache data with key: ${prefixedKey} (uncached)`,
              e.message,
              e.stack,
            )
            if (fetchMode === FetchMode.FOREGROUND) {
              throw e
            }
            return undefined
          })
          .finally(() => {
            releaseLock(prefixedKey)
          })

        if (fetchMode === FetchMode.ALWAYS_BACKGROUND) {
          return undefined
        }

        try {
          return await lookupPromise
        } catch (e) {
          return undefined
        }
      }

      const parsedData = JSON.parse(data) as CachedObject<Type>
      if (fetchMode === FetchMode.FOREGROUND) {
        return parsedData.object
      }

      const now = new Date().getTime()
      if (parsedData.expires < now) {
        if (!(await lock(prefixedKey))) {
          if (parsedData.stale && parsedData.stale > now) {
            return parsedData.object
          }

          if (fetchMode === FetchMode.BACKGROUND_IF_EXPIRED) {
            return parsedData.object
          }

          return undefined
        }

        try {
          const lookupPromise = lookup(lookupContext, args)
            .catch((e) => {
              console.warn(
                `Failed to fetch cache data with key: ${prefixedKey} (stale)`,
                e.message,
                e.stack,
              )

              return parsedData.object
            })
            .finally(() => {
              releaseLock(prefixedKey)
            })

          if (parsedData.stale && parsedData.stale > now) {
            return parsedData.object
          }

          if (
            fetchMode === FetchMode.ALWAYS_BACKGROUND ||
            fetchMode === FetchMode.BACKGROUND_IF_EXPIRED
          ) {
            return parsedData.object
          }

          return await lookupPromise
        } catch (e) {
          return parsedData.object
        }
      }
      return parsedData.object
    },
    ttl: async (args: Args): Promise<number | null> => {
      if (!redis) {
        return null
      }
      const key =
        typeof storeConfig.key === 'function'
          ? storeConfig.key(args)
          : storeConfig.key
      const prefixedKey = generateKey(key)

      const ttl = await redis.ttl(prefixedKey)

      if (ttl === -2) {
        console.warn(`Key not found: ${prefixedKey}`)
        return null
      }

      if (ttl === -1) {
        console.warn(`Key has no expiration: ${prefixedKey}`)
        return null
      }

      return ttl
    },
  }
}
