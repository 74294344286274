import * as React from 'react'

import { AccountIcon } from '@thg-commerce/gravity-icons/src'
import { CombinedThemeInterface } from '@thg-commerce/gravity-patterns'
import { mq, styled, withTheme } from '@thg-commerce/gravity-theme'

import { HeaderDropdown } from './HeaderDropdown'
import { DropdownOptions } from './types'

const AccountTriggerWrapper = styled.div`
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: inline-block;
    justify-content: center;
  }
`

export const AccountIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export interface AccountDropdownProps {
  isDesktop: boolean
  theme: CombinedThemeInterface
  headerDropdownI18nText: { closeButtonAriaLabel: string }
  i18nText: {
    accountButtonAriaLabel: string
    accountLabel: string
  }
  routes: {
    accountHome: string
  }
  accountNav: (close?: () => void) => React.ReactElement
  wishlistNotification?: React.FunctionComponent
  newHeaderEnabled?: boolean
}

export const AccountDropdown = withTheme((props: AccountDropdownProps) => {
  return (
    <AccountTriggerWrapper data-testid={'account-trigger-wrapper'}>
      <HeaderDropdown
        i18nAriaLabels={{
          close: props.headerDropdownI18nText.closeButtonAriaLabel,
          trigger: props.i18nText.accountButtonAriaLabel,
        }}
        isMobile={!props.isDesktop}
        dropdownWidth={props.theme.patterns.header.dropdowns.account.width}
        trigger={{
          icon: (
            <AccountIconWrapper>
              {props.theme.patterns.header.dropdowns.account.icon.svgPath !==
              '' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height={
                    props.theme.patterns.header.dropdowns.account[
                      props.isDesktop ? 'icon' : 'mobileIcon'
                    ].height
                  }
                  width={
                    props.theme.patterns.header.dropdowns.account[
                      props.isDesktop ? 'icon' : 'mobileIcon'
                    ].width
                  }
                  viewBox={
                    props.theme.patterns.header.dropdowns.account[
                      props.isDesktop ? 'icon' : 'mobileIcon'
                    ].viewBox
                  }
                >
                  <path
                    fillRule="evenodd"
                    d={
                      props.theme.patterns.header.dropdowns.account.icon.svgPath
                    }
                  ></path>
                </svg>
              ) : props.theme.patterns.header.dropdowns.account
                  .useAlternativeIconSVG !== '' ? (
                <img
                  src={
                    props.theme.patterns.header.dropdowns.account
                      .useAlternativeIconSVG
                  }
                  height={
                    props.theme.patterns.header.dropdowns.account.icon.height
                  }
                  width={
                    props.theme.patterns.header.dropdowns.account.icon.width
                  }
                  loading="lazy"
                  alt=""
                  role="presentation"
                />
              ) : (
                <AccountIcon />
              )}
            </AccountIconWrapper>
          ),
          text: props.i18nText.accountLabel,
          triggerHref: props.routes.accountHome,
        }}
        disableFocusLogic={false}
        dropdownType={DropdownOptions.ACCOUNT}
        dropdownDirection={
          props.newHeaderEnabled
            ? props.theme.patterns.header.accountDropdownDirection
            : undefined
        }
        content={props.accountNav}
        notification={props.wishlistNotification}
      />
    </AccountTriggerWrapper>
  )
})
