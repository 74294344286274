import * as React from 'react'
import Media from 'react-media'

import {
  PaymentMethods,
  PaymentMethodsProps,
} from '@thg-commerce/gravity-patterns'
import { styled, useTheme } from '@thg-commerce/gravity-patterns/theme'
import { isMobileDevice, Orderer } from '@thg-commerce/gravity-system'
import { mq, spacing } from '@thg-commerce/gravity-theme'

import { CopyrightProps } from './Copyright/Copyright'
import { Copyright } from './Copyright'

export interface CopyrightAndPaymentsProps {
  paymentMethods: PaymentMethodsProps
  copyright: CopyrightProps
}

const CopyrightAndPaymentsContainer = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.theme.patterns.footer.copyrightAndPayments.backgroundColor};
  ${(props) =>
    `border-top: ${props.theme.patterns.footer.copyrightAndPayments.separator.width} solid
      ${props.theme.patterns.footer.copyrightAndPayments.separator.color};`}
`

const CopyrightAndPaymentsContent = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.site.siteWidth};
  display: flex;
  flex-direction: column;
  padding: ${spacing(4)} ${spacing(2)} ${spacing(4)} ${spacing(2)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    flex-direction: row;
    padding: ${spacing(4)} ${spacing(4)} ${spacing(4)} ${spacing(4)};
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: ${spacing(4)} ${spacing(4)} ${spacing(4)} ${spacing(4)};
  }
`
const Section = styled.div<{ addMobileSpacing: boolean }>`
  width: 100%;
  padding-top: ${(props) => (props.addMobileSpacing ? spacing(4) : '0')};
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: 50%;
  }
`

export const CopyrightAndPayments = (
  props: CopyrightAndPaymentsProps & {
    userAgent: string
  },
) => {
  const theme = useTheme()

  return (
    <Media
      query={mq(theme.breakpointUtils.map, 'md', true)}
      defaultMatches={!isMobileDevice(props.userAgent)}
    >
      {(isDesktop) => (
        <CopyrightAndPaymentsContainer>
          <CopyrightAndPaymentsContent>
            <Orderer order={isDesktop ? [1, 2] : [2, 1]}>
              <Section
                style={{ marginRight: spacing(2) }}
                addMobileSpacing={!isDesktop}
              >
                <Copyright
                  logoFill={theme.patterns.footer.copyrightAndPayments.logoFill}
                  ariaMomentumText={props.copyright.ariaMomentumText}
                  displayIcon={props.copyright.displayIcon}
                  text={props.copyright.text}
                  hasFooterCopyrightAboveLogo={
                    props.copyright.hasFooterCopyrightAboveLogo
                  }
                  hasFooterCopyrightIcons={
                    props.copyright.hasFooterCopyrightIcons
                  }
                />
              </Section>
              <Section addMobileSpacing={false}>
                <PaymentMethods
                  {...props.paymentMethods}
                  paymentProviders={props.paymentMethods.paymentProviders || []}
                />
              </Section>
            </Orderer>
          </CopyrightAndPaymentsContent>
        </CopyrightAndPaymentsContainer>
      )}
    </Media>
  )
}
