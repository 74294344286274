import * as React from 'react'
export const SvgBlog = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M20.69 2H3.31A1.31 1.31 0 002 3.31v17.38A1.31 1.31 0 003.31 22h17.38A1.31 1.31 0 0022 20.69V3.31A1.31 1.31 0 0020.69 2zM5.48 5.63h13.41V6.8H5.48zm0 3.18h13.37V10H5.44zm0 3.23h13.37v1.17H5.44zm16.19 5.42H2.37v-1.17h19.26z"
      data-name="Layer 13"
    />
  </svg>
)
export default SvgBlog
