import { validate_columbo_event } from './columboEvent'
import { Message } from '../transmit'

import { validate_perf } from './perf'
import { validate_userExT } from './userExT'
import { validate_exisiting_customer_login } from './login'

export const validate_message = async (message: Message): Promise<boolean> => {
  switch (message.type) {
    case 'columbo':
      return validate_columbo_event(message.payload)
    case 'perf':
      return validate_perf(message.payload)
    case 'userExT':
      return validate_userExT(message.payload)
    case 'login.existing_customer':
    case 'login.account_creation':
    case 'login.page_visit':
    case 'checkout_start':
    case 'newsletter_signup':
      return validate_exisiting_customer_login(message.payload)
    default:
      return new Promise<boolean>(() => false)
  }
}
