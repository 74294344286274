import * as React from 'react'
import { RefObject } from 'react'
import loadable from '@loadable/component'

const { v4: uuid } = require('uuid')

const SvgIconDelete = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconDelete'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { Label, LabelText } from '../Label'

import {
  HelperTextContainer,
  InputClearIcon,
  InputClearIconWrapper,
  InputElement,
  InputIconWrapper,
  InputWrapper,
} from './Styles'
import { InputProps } from './types'

const defaultProps = {
  error: false,
  helperText: '',
  ltr: true,
  show: true,
  delete: true,
  type: 'text',
  labelHidden: false,
  required: false,
  autocomplete: 'off',
  confirmable: false,
  svgIconLeftAlign: true,
  onChange: () => {},
  validators: [],
}

/** @component */
export const Input = (props: InputProps) => {
  const [isShowing, setIsShowing] = React.useState(false)
  const [isDirty, setDirty] = React.useState(props.value || false)
  const [inputValue, setValue] = React.useState(
    props.valueOverride || props.value || '',
  )
  const [announceMessage, setAnnounceMessage] = React.useState(' ')

  let inputEl: RefObject<HTMLInputElement> | any = React.useRef<
    HTMLInputElement
  >(null)

  if (props.bindref) {
    inputEl = props.bindref
  }

  const hasIcon = {
    deleteIcon: props.delete,
    showHideIcon:
      props.type && props.type.toLowerCase() === 'password' && props.show,
  }

  React.useEffect(() => {
    setValue(props.valueOverride || props.value || '')
  }, [props.valueOverride, props.value])

  React.useEffect(() => {
    props.setFormValues && props.setFormValues([inputValue])
  }, [inputValue])

  const toggleShowHide = () => {
    setIsShowing(!isShowing)
    if (props.i18nText) {
      if (isShowing && props.i18nText.hideAnnouncementText) {
        setAnnounceMessage(props.i18nText.hideAnnouncementText)
      }
      if (!isShowing && props.i18nText.showAnnouncementText) {
        setAnnounceMessage(props.i18nText.showAnnouncementText)
      }
    }
  }

  const clearInputValue = (e) => {
    if (inputEl.current && inputValue) {
      inputEl.current.focus()
      setValue('')
      setDirty(false)

      if (props.i18nText && props.i18nText.clearAnnouncementText) {
        setAnnounceMessage(props.i18nText.clearAnnouncementText)
      }

      if (props.clearInputValueHandler) {
        props.clearInputValueHandler(e)
      }
    }
  }

  enum InputActionButtonType {
    DELETE = 'delete',
    SHOWHIDE = 'showHide',
  }

  const handleKeyPress = (
    e: React.KeyboardEvent,
    icon: InputActionButtonType,
  ) => {
    e.preventDefault()
    const keyValue = e.charCode || e.keyCode || 0
    if (keyValue === 13) {
      icon === 'delete'
        ? clearInputValue(e)
        : icon === 'showHide'
        ? toggleShowHide()
        : ''
    }
  }

  const id = uuid()

  const elementLabel = (type: 'input' | 'label') =>
    `${props.label
      ?.toLowerCase()
      .replace(/\:|\?|\.|\!|\"|\'/g, '')
      .split(' ')
      .join('-')}-${type}-element-id-${id}`

  const { className, ...renderProps } = props

  const getRemainingCharacters = (displayRemainingCharacters, maxlength) =>
    displayRemainingCharacters ? maxlength : undefined

  return (
    <React.Fragment>
      <Label
        data-testid={elementLabel('label')}
        className={props.disabled ? 'disabled' : ''}
        htmlFor={`${elementLabel('input')}`}
      >
        {props.renderLiveMessage &&
          props.renderLiveMessage('assertive', announceMessage)}
        <LabelText
          required={props.required}
          className={props.labelHidden ? 'hidden' : ''}
          optionalText={(props.i18nText && props.i18nText.optionalLabel) || ''}
          disabled={props.disabled}
          remainingCharacters={getRemainingCharacters(
            props.displayRemainingCharacters,
            props.maxlength,
          )}
          charCount={inputValue.length}
        >
          {props.label}
        </LabelText>
      </Label>
      <InputWrapper {...renderProps} className={className}>
        {props.svgIcon && props.svgIconLeftAlign && (
          <InputIconWrapper data-testid={'search-input-icon-align-left'}>
            {props.svgIcon}
          </InputIconWrapper>
        )}
        <InputElement
          {...props}
          type={!isShowing ? (props.type || '').toLowerCase() : 'text'}
          dir={props.ltr ? 'ltr' : 'rtl'}
          data-testid={`${elementLabel('input')}`}
          id={`${elementLabel('input')}`}
          ref={inputEl}
          min={props.minValue}
          max={props.maxValue}
          maxLength={props.maxlength}
          minLength={props.minlength}
          value={inputValue}
          name={props.label}
          autoComplete={props.autocomplete ? props.autocomplete : ''}
          onChange={(event) => {
            setValue(event.target.value || inputEl.current.value)
            props.onChange?.(event)
            props.renderLiveMessage && setAnnounceMessage(' ')
            setDirty(inputEl.current && inputEl.current.value)
          }}
          tabIndex={
            props._dangerouslySetTabIndex && props._dangerouslySetTabIndex
          }
        />
        <InputClearIconWrapper {...renderProps}>
          {hasIcon.showHideIcon && (
            <InputClearIcon
              type="button"
              aria-label={
                !isShowing
                  ? props.i18nText && props.i18nText.showAriaLabel
                  : props.i18nText && props.i18nText.hideAriaLabel
              }
              onKeyPress={(e: React.KeyboardEvent) =>
                handleKeyPress(e, InputActionButtonType.SHOWHIDE)
              }
              onClick={() => toggleShowHide()}
              tabIndex={0}
              data-testid="input-show-hide"
            >
              {isShowing
                ? props.i18nText &&
                  props.i18nText.hideLabel &&
                  props.i18nText.hideLabel.toUpperCase()
                : props.i18nText &&
                  props.i18nText.showLabel &&
                  props.i18nText.showLabel.toUpperCase()}
            </InputClearIcon>
          )}
          {hasIcon.deleteIcon &&
            props.type &&
            props.type.toLowerCase() !== 'password' &&
            !props.disabled &&
            isDirty && (
              <InputClearIcon
                data-testid={'clear-button'}
                type="button"
                onKeyPress={(e) =>
                  handleKeyPress(e, InputActionButtonType.DELETE)
                }
                onClick={clearInputValue}
                tabIndex={0}
                aria-label={
                  (props.i18nText && props.i18nText.clearAriaLabel) ||
                  'Clear field'
                }
                title={props.i18nText && props.i18nText.clearAriaLabel}
              >
                <SvgIconDelete data-testid="input-delete-icon" />
              </InputClearIcon>
            )}
        </InputClearIconWrapper>
        {props.svgIcon && !props.svgIconLeftAlign && (
          <InputIconWrapper data-testid={'search-input-icon-align-right'}>
            {props.svgIcon}
          </InputIconWrapper>
        )}
      </InputWrapper>
      {props.helperText && props.helperText.length > 0 && (
        <HelperTextContainer
          data-testid="input-helper-text"
          className={`
            ${props.disabled ? 'disabled' : ''}
          `}
        >
          {props.helperText}
        </HelperTextContainer>
      )}
    </React.Fragment>
  )
}

Input.defaultProps = defaultProps

export default Input
