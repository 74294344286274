import * as React from 'react'

import { styled } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing, Text } from '@thg-commerce/gravity-theme'

interface SessionSettingsTriggerI18nText {
  sessionSettingsChangeText: string
  sessionSettingsYourSettingsAriaLabel: string
  sessionSettingsButtonOpenAriaLabel: string
}

interface SessionSettingsTriggerProps {
  i18nText: SessionSettingsTriggerI18nText
  flagPath?: string
  sessionSettingsModal: (
    close?: () => void,
    dontRemoveScrollLock?: boolean,
  ) => React.ReactElement
  sessionSettingsTriggerText: string
  isMobile: boolean
}

const SessionSettingsButton = styled.button`
  ${(props) =>
    Text(
      props.theme.patterns.header.subNavigation.mobile.font.entry || 'small',
      props.theme.patterns.header.subNavigation.mobile.font.style || 'default',
    )};
  text-transform: ${(props) =>
    props.theme.patterns.header.subNavigation.mobile.font.transform || 'none'};
  color: ${(props) =>
    props.theme.patterns.header.subNavigation.mobile.font.textColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: calc(${spacing(1)} - ${spacing(0.25)});
  text-decoration: ${(props) =>
    props.theme.patterns.header.subNavigation.mobile.font.textDecoration ||
    'none'};
  border: 2px solid transparent;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) =>
      Text(
        props.theme.patterns.header.subNavigation.desktop.font.entry || 'small',
        props.theme.patterns.header.subNavigation.desktop.font.style ||
          'default',
      )};
    text-transform: ${(props) =>
      props.theme.patterns.header.subNavigation.desktop.font.transform ||
      'none'};
    text-decoration: ${(props) =>
      props.theme.patterns.header.subNavigation.desktop.font.textDecoration ||
      'none'};

    align-items: center;
    display: flex;
    height: ${spacing(4)};
    padding: 0 ${spacing(2)};
    width: auto;
    color: ${(props) =>
      props.theme.patterns.header.subNavigation.desktop.font.textColor};
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) =>
      props.theme.patterns.header.subNavigation.hoverColor};

    ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
      ${(props) =>
        Text(
          props.theme.patterns.header.subNavigation.desktop.hoverFont.entry ||
            'small',
          props.theme.patterns.header.subNavigation.desktop.hoverFont.style ||
            'default',
        )};
      text-transform: ${(props) =>
        props.theme.patterns.header.subNavigation.desktop.hoverFont.transform ||
        'none'};
      text-decoration: ${(props) =>
        props.theme.patterns.header.subNavigation.desktop.hoverFont
          .textDecoration || 'none'};

      color: ${(props) =>
        props.theme.patterns.header.subNavigation.desktop.hoverFont.textColor};
    }
  }

  &:focus {
    outline: none;
    border: 2px solid
      ${(props) =>
        props.theme.patterns.header.subNavigation.desktop.focus?.borderColor ||
        props.theme.button.low.regular.focus.borderColor};
  }
`

const ChangeText = styled.span`
  ${(props) =>
    Text(
      props.theme.patterns.header.subNavigation.mobile.font.entry,
      props.theme.patterns.header.subNavigation.mobile.font.style,
    )};
  text-decoration: underline;
  color: ${(props) =>
    props.theme.patterns.header.subNavigation.mobile.changeTextColor};
  padding-right: calc(${spacing(2)} + ${spacing(0.25)});

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: none;
  }
`

const FlagContainer = styled.span`
  display: flex;
  align-items: center;
  text-transform: ${(props) =>
    props.theme.patterns.header.subNavigation.mobile.font.transform};
`

const Flag = (props: { flagPath: string }) => {
  return (
    <img
      src={`${props.flagPath}`}
      alt={`flag`}
      style={{
        width: `${spacing(2)}`,
        height: `${spacing(2)}`,
        borderRadius: '50%',
        border: '1px solid white',
        marginRight: `${spacing(1)}`,
      }}
    />
  )
}

export const SessionSettingsTrigger = (props: SessionSettingsTriggerProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const close = () => {
    setIsOpen(false)
  }

  return (
    <React.Fragment>
      <SessionSettingsButton
        data-testid="session-settings-trigger"
        type="button"
        aria-label={`${props.i18nText.sessionSettingsYourSettingsAriaLabel} ${props.sessionSettingsTriggerText} ${props.i18nText.sessionSettingsButtonOpenAriaLabel}`}
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <FlagContainer>
          {props.flagPath && <Flag flagPath={props.flagPath} />}
          {props.sessionSettingsTriggerText}
        </FlagContainer>
        <ChangeText>{props.i18nText.sessionSettingsChangeText}</ChangeText>
      </SessionSettingsButton>
      {isOpen && props.sessionSettingsModal(close, props.isMobile)}
    </React.Fragment>
  )
}

export default SessionSettingsTrigger
