import * as React from 'react'
export const SvgTwitter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={26} height={26} {...props}>
    <path
      fill={props.fill || '#1DA1F2'}
      fillRule="evenodd"
      d="M22.459 6.304a5.28 5.28 0 002.296-2.916 10.38 10.38 0 01-3.318 1.276A5.193 5.193 0 0017.625 3c-2.883 0-5.221 2.362-5.221 5.274 0 .414.045.815.134 1.201-4.34-.222-8.188-2.317-10.766-5.512a5.314 5.314 0 00-.707 2.655c0 1.827.922 3.442 2.324 4.388a5.17 5.17 0 01-2.367-.658v.065c0 2.556 1.8 4.688 4.191 5.172a5.04 5.04 0 01-1.376.185 5.15 5.15 0 01-.983-.093c.665 2.094 2.593 3.62 4.879 3.661a10.421 10.421 0 01-6.487 2.257c-.421 0-.838-.024-1.246-.072a14.694 14.694 0 008.006 2.368c9.608 0 14.86-8.035 14.86-15.006 0-.228-.004-.457-.013-.682a10.605 10.605 0 002.605-2.73c-.936.42-1.943.703-3 .83z"
    />
  </svg>
)
export default SvgTwitter
