import * as React from 'react'
export const SvgSystemIconLargeNoOrders = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox="0 0 80 80" {...props}>
    <path
      d="M47.528 7.975a1.905 1.905 0 012.17-.555l.204.096 23.386 12.666a1.907 1.907 0 01.7 2.7l-.14.19-5.867 7.08 7.94-1.133c2.107-.3 3.046 2.554 1.173 3.563L64.762 39.22v17.017c0 .693-.376 1.33-.982 1.666L40.81 70.63l-.276.106-.249.056-.23.02-.208-.005-.237-.035-.2-.054-.165-.063-.168-.083L16.22 57.904a1.9 1.9 0 01-.968-1.437l-.014-.229V39.221L2.906 32.58c-1.806-.97-.996-3.66.952-3.58l.22.02 7.94 1.135-5.866-7.082a1.905 1.905 0 01.56-2.89L30.098 7.516a1.904 1.904 0 012.374.46L40 17.062l7.528-9.088zm13.424 33.297L53.57 45.25a1.922 1.922 0 01-.634.208l-11.032 1.575v18.64l19.048-10.556V41.272zm-41.905 0v13.844l19.048 10.556v-18.64l-11.031-1.575a1.929 1.929 0 01-.431-.114l-.203-.094-7.383-3.977zM40.01 22.22L21.072 32.712 40 43.202l19.154-10.614-19.145-10.37zM14.14 34.303l13.802 7.43 1.728.248-13.802-7.43-1.728-.248zm51.722 0l-1.728.247-13.802 7.431 1.728-.248 13.802-7.43zm-16.4-22.693l-6.516 7.866 19.973 10.817 6.516-7.866L49.461 11.61zm-18.922 0L10.566 22.427l6.516 7.866 19.973-10.817-6.516-7.866z"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgSystemIconLargeNoOrders
