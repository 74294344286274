import * as React from 'react'
export const SvgCircularLine = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <circle cx={20} cy={20} r={20} fill="#00B900" />
    <path
      fill={props.fill || '#fff'}
      d="M33.303 18.8c0-5.954-5.967-10.796-13.303-10.796-7.335 0-13.303 4.843-13.303 10.795 0 5.337 4.733 9.806 11.126 10.651.433.094 1.022.286 1.172.656.134.336.087.863.043 1.203l-.19 1.139c-.058.336-.268 1.316 1.152.717 1.42-.598 7.661-4.511 10.452-7.723 1.928-2.115 2.851-4.26 2.851-6.643"
    />
    <path
      fill={props.fill || '#00b900'}
      d="M17.297 15.923h-.933a.259.259 0 00-.26.258v5.797c0 .143.117.258.26.258h.933a.259.259 0 00.259-.258V16.18a.259.259 0 00-.259-.258m6.423 0h-.933a.259.259 0 00-.26.258v3.444l-2.656-3.587a.218.218 0 00-.022-.028l-.015-.016a.145.145 0 00-.019-.016l-.006-.005-.014-.01-.008-.004-.014-.008-.008-.004-.015-.006-.008-.003-.015-.005a.09.09 0 01-.01-.003l-.014-.003-.011-.001a.09.09 0 00-.014-.002l-.013-.001H18.723a.26.26 0 00-.26.258v5.797c0 .143.117.258.26.258h.933c.143 0 .259-.115.259-.258v-3.443l2.66 3.592a.263.263 0 00.068.066l.016.01.007.004.013.006.012.005.008.003.018.006h.004a.25.25 0 00.066.01h.933a.258.258 0 00.259-.26v-5.796a.259.259 0 00-.26-.258m-8.671 4.862h-2.536V16.18a.259.259 0 00-.259-.259h-.933a.259.259 0 00-.259.26v5.795c0 .07.028.133.072.18.001 0 .002.003.004.003l.004.004c.046.045.109.072.179.072h3.728a.259.259 0 00.258-.259v-.933a.259.259 0 00-.258-.26m13.825-3.41a.259.259 0 00.259-.259v-.933a.259.259 0 00-.26-.26h-3.727a.257.257 0 00-.18.074l-.003.002a.257.257 0 00-.076.183V21.979a.257.257 0 00.08.186c.046.045.11.072.179.072h3.728a.259.259 0 00.259-.259v-.933a.26.26 0 00-.26-.26h-2.534v-.979h2.535a.259.259 0 00.259-.259v-.933a.259.259 0 00-.26-.26h-2.534v-.979h2.535z"
    />
  </svg>
)
export default SvgCircularLine
