import * as React from 'react'
export const SvgCircularFacebook = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <g
      id="social-icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Artboard" transform="translate(-541.000000, -317.000000)">
        <g id="facebook" transform="translate(541.000000, 317.000000)">
          <circle
            id="Oval-2"
            fill={props.fill || '#D8D8D8'}
            cx="20"
            cy="20"
            r="20"
          ></circle>
          <path
            d="M17.0680736,16.6145435 L17.0680736,14.6052554 C17.0680736,13.7187702 17.0904121,12.3525954 17.7335254,11.5060843 C18.4119099,10.6090177 19.3418964,10 20.9432132,10 C23.5509376,10 24.6490506,10.3715243 24.6490506,10.3715243 L24.132914,13.4342484 C24.132914,13.4342484 23.2711187,13.1861737 22.4669332,13.1861737 C21.6627476,13.1861737 20.9432132,13.4742226 20.9432132,14.2772324 L20.9432132,16.6145435 L24.2399036,16.6145435 L24.0094645,19.6055494 L20.9432132,19.6055494 L20.9432132,30 L17.0680736,30 L17.0680736,19.6055494 L15,19.6055494 L15,16.6145435 L17.0680736,16.6145435 Z"
            id="Facebook"
            fill={props.fill || '#333333'}
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
export default SvgCircularFacebook
