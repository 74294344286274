import * as React from 'react'
export const SvgIconInfo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} {...props}>
    <path
      fill={props.fill || '#0065AD'}
      fillRule="evenodd"
      d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm1 9H9v7h2V9zm-1-5c-.863 0-1.5.65-1.5 1.506C8.5 6.35 9.137 7 10 7s1.5-.65 1.5-1.494C11.5 4.65 10.863 4 10 4z"
    />
  </svg>
)
export default SvgIconInfo
