import { Property } from 'csstype'

import { VerticalAlignment } from '@thg-commerce/gravity-elements'
import { Margin } from '@thg-commerce/gravity-theme/margin'
import { TextStyling } from '@thg-commerce/gravity-theme/typography/typography'

export interface AddedToBasketModalInterface {
  height: string
  separator: {
    bottom: {
      style: Property.BorderStyle
      width: Property.BorderWidth<string>
      color: Property.BorderColor
    }
  }
  title: {
    margin: Margin
  }
  content: {
    productTitleMargin: Margin
    quantityText: TextStyling
    basketInformation: { spacing: Margin }
  }
  padding: string
  verticalAlignment?: VerticalAlignment[]
  autoHeightMobile?: boolean
  displayRrpPerUnit: boolean
  showRrpInline: boolean
  savings: {
    show: boolean
    textStyle?: TextStyling
    label: {
      show: boolean
    }
  }
  savingsPercentage: {
    show: boolean
  }
}

export const theme: AddedToBasketModalInterface = {
  height: '100%',
  separator: {
    bottom: {
      style: 'solid',
      width: '1px',
      color: '#cccccc',
    },
  },
  title: {
    margin: { bottom: 3 },
  },
  content: {
    productTitleMargin: {
      left: -0.25,
      right: -0.25,
      top: -0.25,
      bottom: 1,
    },
    quantityText: {
      entry: 'bodyText',
      style: 'default',
      transform: 'none',
      textDecoration: 'none',
      textColor: '#8c8c8c',
    },
    basketInformation: { spacing: { top: 3, bottom: 3 } },
  },
  padding: '0px 24px 32px',
  displayRrpPerUnit: false,
  showRrpInline: false,
  savings: {
    show: false,
    label: {
      show: false,
    },
  },
  savingsPercentage: {
    show: false,
  },
}
