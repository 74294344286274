import React from 'react'

export const InfoFreeReturn = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.38193 0.34082H13.6181L16 5.10475V17.3408H0.000488281V5.1048L2.38193 0.34082ZM13.3813 4.34082L12.381 2.34082H9.00026V4.34082H13.3813ZM14 6.34082H2.00003V15.3408H14V6.34082ZM2.61772 4.34082L3.61803 2.34082H7.00026V4.34082H2.61772ZM12 9.84082V11.8408H6.83512V13.3435L3.50003 10.8421L6.83512 8.34082V9.84082H12Z"
      fill={props.fill || '#333333'}
    />
  </svg>
)
export default InfoFreeReturn
