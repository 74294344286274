import * as React from 'react'
export const ListIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={15}
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="profile">
      <path
        id="Combined Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 0.5V2.5H5.5V0.5H18.5ZM18.5 6.5V8.5H5.5V6.5H18.5ZM18.5 14.5V12.5H5.5V14.5H18.5Z"
        fill="#333333"
      />
      <rect id="Rectangle 84" x="0.5" width="3" height="3" fill="#333333" />
      <rect
        id="Rectangle 85"
        x="0.5"
        y="6"
        width="3"
        height="3"
        fill={props.fill || '#333333'}
      />
      <rect
        id="Rectangle 86"
        x="0.5"
        y="12"
        width="3"
        height="3"
        fill={props.fill || '#333333'}
      />
    </g>
  </svg>
)
export default ListIcon
