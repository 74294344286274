import { Property } from 'csstype'

import { shade, tint } from './colorUtils'
import { socialBrands, SocialBrandsInterface } from './socialBrands'

export interface ColorEntryInterface {
  lightest: Property.Color
  lighter: Property.Color
  light: Property.Color
  base: Property.Color
  dark: Property.Color
  darker: Property.Color
  darkest: Property.Color
}

export interface NotificationColorEntryInterface {
  light: Property.Color
  base: Property.Color
}

export interface GreysInterface {
  darker: Property.Color
  dark: Property.Color
  grey: Property.Color
  light: Property.Color
  lighter: Property.Color
  white: Property.Color
}

export interface PaletteInterface {
  brand: ColorEntryInterface
  accent: ColorEntryInterface
  tertiary: ColorEntryInterface
  greys: GreysInterface
}
export interface PapInterface {
  border: Property.Color
  title?: Property.Color
  sale?: Property.Color
}

export interface SystemColorsInterface {
  pap: PapInterface
}

export interface PaletteColorsInterface {
  palette: PaletteInterface
  error: NotificationColorEntryInterface
  success: NotificationColorEntryInterface
  info: NotificationColorEntryInterface
  socialBrands: SocialBrandsInterface
  system: SystemColorsInterface
}

const brand: Property.Color = '#605BFF'
const accent: Property.Color = '#FFC107'
const black: Property.Color = '#000000'

const palette: PaletteInterface = {
  brand: {
    lightest: tint(brand, 90),
    lighter: tint(brand, 60),
    light: tint(brand, 20),
    base: brand,
    dark: shade(brand, 20),
    darker: shade(brand, 40),
    darkest: shade(brand, 80),
  },
  accent: {
    lightest: tint(accent, 90),
    lighter: tint(accent, 60),
    light: tint(accent, 20),
    base: accent,
    dark: shade(accent, 20),
    darker: shade(accent, 40),
    darkest: shade(brand, 80),
  },
  tertiary: {
    lightest: '',
    lighter: '',
    light: '',
    base: '',
    dark: '',
    darker: '',
    darkest: '',
  },
  greys: {
    white: tint(black, 100),
    lighter: tint(black, 95),
    light: tint(black, 80),
    grey: tint(black, 55),
    dark: tint(black, 40),
    darker: tint(black, 20),
  },
}

const system: SystemColorsInterface = {
  pap: { border: '#FF0000', sale: '#FFFFFF' },
}

export const colors: PaletteColorsInterface = {
  palette,
  socialBrands,
  system,
  error: {
    light: '#FAE8E6',
    base: '#C42C21',
  },
  success: {
    light: '#E6F2EA',
    base: '#007831',
  },
  info: {
    light: '#e6f0f8',
    base: '#0065ad',
  },
}
