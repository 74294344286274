import * as React from 'react'

interface DropdownOptionProps {
  value: any
}

/** @component */
export const DropdownOption = (
  props: React.HTMLProps<HTMLOptionElement> & DropdownOptionProps,
) => {
  const { children, ...rest } = props
  return (
    // tslint:disable-next-line
    <option {...rest}>{props.children}</option>
  )
}

export default DropdownOption
