import * as React from 'react'

import { useTheme } from '@thg-commerce/gravity-system/theme'
import {
  BreakpointArray,
  mediaQueryRenderer,
  padding,
  styled,
  Text,
  TextEntry,
  TextStyle,
} from '@thg-commerce/gravity-theme'
import { Padding } from '@thg-commerce/gravity-theme/padding'

export interface PictureProps {
  sources:
    | {
        sourceSet: string
        type?: string | null
        media?: string | null
      }[]
    | null
  default: string
  alt: string
  lazy?: boolean
  width?: string
  aspectRatio?: string
  className?: string
  style?: React.CSSProperties
  fetchPriority?: 'high' | 'low' | 'auto'
  tag?: string
  tagStyle?: {
    container: {
      padding: Padding
      width: BreakpointArray<string>
    }
    textStyling: {
      entry: TextEntry
      style: TextStyle
    }
  }
}

const StyledTag = styled.div<{
  tagStyle?: {
    container: {
      padding: Padding
      width: BreakpointArray<string>
    }
    textStyling: {
      entry: TextEntry
      style: TextStyle
    }
  }
}>`
  position: absolute;
  bottom: 0;

  background: ${(props) => props.theme.colors.palette.greys.lighter};
  opacity: 90%;
  ${(props) =>
    props.tagStyle
      ? padding(props.tagStyle.container.padding)
      : padding({ right: 0.5, bottom: 0.5, left: 0.5, top: 0.5 })};
  ${(props) =>
    Text(
      props.tagStyle?.textStyling.entry || 'xsmall',
      props.tagStyle?.textStyling.style || 'alternate',
    )}
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  ${(props) =>
    props.tagStyle?.container?.width &&
    mediaQueryRenderer(
      props.tagStyle.container.width,
      (width) => `width: ${width};`,
    )}
`

export const Picture: React.FunctionComponent<PictureProps> = (props) => {
  return (
    <picture style={{ display: 'block', lineHeight: 0 }}>
      {props.sources?.map((source) => (
        <source
          srcSet={source.sourceSet}
          media={source.media || undefined}
          type={source.type || undefined}
        />
      ))}
      <img
        src={props.default}
        alt={props.alt}
        width={props.width || ''}
        loading={props.lazy ? 'lazy' : 'eager'}
        style={{
          aspectRatio: props.aspectRatio,
          ...(props.style || {}),
        }}
        className={props.className}
        // @ts-ignore
        fetchpriority={props.fetchPriority || 'auto'}
      />
      {props.tag && (
        <StyledTag tagStyle={props.tagStyle}>{props.tag}</StyledTag>
      )}
    </picture>
  )
}
