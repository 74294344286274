import * as React from 'react'

import { withBasketType } from '../../Header'
import { BasketIcon } from '../../Header/BasketIcon'
import { HeaderDropdown } from '../../Header/HeaderDropdown'
import { MiniBasket, MiniBasketProps } from '../../Header/MiniBasket'
import { DropdownOptions } from '../../Header/types'

interface BasketProps {
  withBasket: withBasketType
  defaultLocale: string
  isDesktop: boolean
  route: string
  i18nText: {
    basketLabel: string
    menuButtonAriaLabel: string
    basketButtonAriaLabel: string
  }
  headerDropdownI18nText: { closeButtonAriaLabel: string }
  dropdownWidth: string
}

const BasketWrapper = ({
  basket,
  ...props
}: BasketProps & {
  basket: MiniBasketProps
}) => {
  return (
    <HeaderDropdown
      i18nAriaLabels={{
        close: props.headerDropdownI18nText.closeButtonAriaLabel,
        trigger: props.i18nText.basketButtonAriaLabel,
      }}
      isMobile={!props.isDesktop}
      dropdownWidth={props.dropdownWidth}
      trigger={{
        icon: <BasketIcon totalQuantity={basket?.basketData?.totalQuantity} />,
        text: props.i18nText.basketLabel,
        triggerHref: props.route,
      }}
      disableFocusLogic={false}
      dropdownType={DropdownOptions.BASKET}
      content={(close) => {
        return (
          <MiniBasket
            close={close}
            isMobile={!props.isDesktop}
            locale={props.defaultLocale}
            links={basket.links}
            basketData={basket.basketData}
            i18nText={basket.i18nText}
          />
        )
      }}
    />
  )
}

export const Basket = (props: BasketProps) => {
  const BasketHOC = props.withBasket({
    Component: ({ basket }) => <BasketWrapper basket={basket} {...props} />,
  })

  return <BasketHOC />
}
