import * as React from 'react'

import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button } from '@thg-commerce/gravity-elements'
import { i18n, useSiteConfig } from '@thg-commerce/enterprise-core'

import { Logout } from '../Logout'
import { Routes } from '../Routing/Routes'
import { useCustomer } from '../CustomerContext'

export interface AccountNavI18nText {
  accountNavAriaLabel: string
  accountWelcomeText: string
  accountLogoutText: string
  accountLoginText: string
  accountRegisterText: string
  accountMyAccountText: string
}

const StyledNav = styled.nav`
  width: 100%;
`

const AccountListItem = styled.li`
  display: flex;
  background: ${(props) => props.theme.colors.palette.greys.lighter};
  margin: 0;
`

const LoginLink = styled(Button)`
  flex: 1;
  margin: ${spacing(2)} ${spacing(2)} ${spacing(1)} ${spacing(2)};
`

const StyledLogoLinkText = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.header.dropdowns.account.font.ctaHigh.entry,
      props.theme.patterns.header.dropdowns.account.font.ctaHigh.style,
    )}
  text-transform: ${(props) =>
    props.theme.patterns.header.dropdowns.account.font.ctaHigh.transform};
  text-decoration: ${(props) =>
    props.theme.patterns.header.dropdowns.account.font.ctaHigh.textDecoration};
  color: inherit;
`

const RegisterLink = styled(Button)`
  flex: 1;
  margin: ${spacing(1)} ${spacing(2)} ${spacing(2)} ${spacing(2)};
`

const StyledRegisterLinkText = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.header.dropdowns.account.font.ctaMedium.entry,
      props.theme.patterns.header.dropdowns.account.font.ctaMedium.style,
    )};
  ${(
    props,
  ) => `text-transform: ${props.theme.patterns.header.dropdowns.account.font.ctaMedium.transform};
text-decoration: ${props.theme.patterns.header.dropdowns.account.font.ctaMedium.textDecoration};
color: inherit;`}
`

const WelcomeContainer = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.header.dropdowns.account.font.welcomeText.entry,
      props.theme.patterns.header.dropdowns.account.font.welcomeText.style,
    )}
  display: flex;
  align-items: center;
`

const WelcomeListItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: ${spacing(2)};
  background: ${(props) => props.theme.colors.palette.greys.lighter};
`

const StyledLink = styled(Button)`
  ${(props) =>
    Text(
      props.theme.patterns.header.dropdowns.account.font.dropdownLink.entry,
      'default',
    )};
  ${(props) => `
    text-decoration: ${props.theme.patterns.header.dropdowns.account.font.dropdownLink.textDecoration};
    text-transform: ${props.theme.patterns.header.dropdowns.account.font.dropdownLink.transform};
    color: ${props.theme.patterns.header.dropdowns.account.font.dropdownLink.textColor};
    `}
  display: block;
  flex: 1;

  &:hover,
  &:focus {
    ${(props) =>
      Text(
        props.theme.patterns.header.dropdowns.account.font.dropdownLink.entry,
        'default',
      )}
    color: ${(props) =>
      props.theme.patterns.header.dropdowns.account.font.dropdownLink
        .textColor};
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }

  &:focus {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }

  && {
    width: 100%;
    justify-content: flex-start;
    padding: ${spacing(1.5)};

    &:hover {
      padding: ${spacing(1.5)};
    }
    &:focus {
      width: 100%;
      padding: calc(${spacing(1.5)} - 1px);
    }
    &:active {
      padding: ${spacing(1.5)};
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    font-weight: normal;

    &:hover,
    &:focus {
      font-weight: normal;
    }
  }
`

const LogoutButton = styled(Logout)`
  ${Text('bodyText', 'alternate')}
  color: ${(props) => props.theme.colors.palette.brand.base};
  white-space: nowrap;

  &:hover,
  &:focus {
    ${Text('bodyText', 'alternate')}
    text-decoration: underline;
  }
`

const MOBILE_DROPDOWN_TRIGGER_HEIGHT = 48
const ACCOUNT_CONTAINER_HEADER_HEIGHT = 144

const StyledLinkContainer = styled.div`
  padding: ${spacing(0.5)} 0;
  overflow-y: auto;

  max-height: calc(
    100vh - ${MOBILE_DROPDOWN_TRIGGER_HEIGHT}px -
      ${ACCOUNT_CONTAINER_HEADER_HEIGHT}px
  );

  @supports (height: 100dvh) {
    max-height: calc(
      100dvh - ${MOBILE_DROPDOWN_TRIGGER_HEIGHT}px -
        ${ACCOUNT_CONTAINER_HEADER_HEIGHT}px
    );
  }
`

export const AccountNav = () => {
  const customer = useCustomer()

  const {
    showAccountOrders,
    showAccountReferrals,
    hasMemberPerks,
    enableWishlists,
    enableWishlistsGlobal,
  } = useSiteConfig()

  const accountNavLinks: { text: string; url: string }[] = []

  enableWishlists &&
    enableWishlistsGlobal &&
    accountNavLinks.push({
      text: i18n('header.account.your.wishlist.text'),
      url: Routes.Wishlists,
    })

  showAccountOrders &&
    accountNavLinks.push({
      text: i18n('header.account.your.orders.text'),
      url: Routes.AccountOrderHistory,
    })

  showAccountReferrals &&
    accountNavLinks.push({
      text: i18n('header.account.your.referrals.text'),
      url: Routes.MyReferrals,
    })

  hasMemberPerks &&
    accountNavLinks.push({
      text: i18n('header.account.member.perks.text'),
      url: i18n('general.memberperks.link') || Routes.MemberPerks,
    })

  const i18nText = {
    accountNavAriaLabel: i18n('header.account.nav.arialabel'),
    accountWelcomeText: i18n('header.account.welcome.text'),
    accountLogoutText: i18n('header.account.logout.text'),
    accountLoginText: i18n('header.account.login.text'),
    accountRegisterText: i18n('header.account.register.text'),
    accountMyAccountText: i18n('header.account.my.account.text'),
  }

  const accountNavStyledLinks = React.useMemo(
    () =>
      accountNavLinks.map((link, index) => {
        return (
          <StyledLink renderedAs="a" emphasis="low" href={link.url} key={index}>
            {link.text}
          </StyledLink>
        )
      }),
    [accountNavLinks],
  )

  return (
    <StyledNav aria-label={i18nText.accountNavAriaLabel}>
      <React.Fragment>
        {customer.firstName ? (
          <React.Fragment>
            <ul>
              <WelcomeListItem>
                <WelcomeContainer data-cs-mask>
                  {i18nText.accountWelcomeText}, {customer.firstName}
                </WelcomeContainer>
                <LogoutButton emphasis="low" />
              </WelcomeListItem>
            </ul>
            <StyledLinkContainer>
              <StyledLink
                renderedAs="a"
                emphasis="low"
                href={Routes.AccountHome}
              >
                {i18nText.accountMyAccountText}
              </StyledLink>
              {accountNavStyledLinks}
            </StyledLinkContainer>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ul>
              <AccountListItem>
                <LoginLink
                  renderedAs="a"
                  emphasis="high"
                  href={Routes.AccountHome}
                >
                  <StyledLogoLinkText>
                    {i18nText.accountLoginText}
                  </StyledLogoLinkText>
                </LoginLink>
              </AccountListItem>
              <AccountListItem>
                <RegisterLink
                  renderedAs="a"
                  emphasis="medium"
                  href={Routes.Register}
                >
                  <StyledRegisterLinkText>
                    {i18nText.accountRegisterText}
                  </StyledRegisterLinkText>
                </RegisterLink>
              </AccountListItem>
            </ul>
            <StyledLinkContainer>{accountNavStyledLinks}</StyledLinkContainer>
          </React.Fragment>
        )}
      </React.Fragment>
    </StyledNav>
  )
}

export default AccountNav
