import { transmit as relayTransmit } from '@thg-commerce/deepspace-relay'
import { Message } from '@thg-commerce/deepspace-relay/src/transmit'

import getConfig from 'next/config'

import { loggerProvider } from '../Logger'
import { useSiteDefinition } from '../ConfigurationLoader'
import { useEnterpriseContext } from '../EnterpriseContext'
import { NextConfig } from '../ConfigurationLoader/types'

const { publicRuntimeConfig } = getConfig() as NextConfig
const {
  APP_NAME,
  APP_VERSION,
  DEEPSPACE_URL,
  ENABLE_TRANSMIT_BEACONS,
} = publicRuntimeConfig

export const transmitProvider = ({
  brand,
  subsite,
  originUrl,
}: {
  brand: string
  subsite: string
  originUrl: string
}): ((message: Message) => void) => {
  const logger = loggerProvider({ brand, subsite, originUrl })

  return (message: Message) => {
    if (!DEEPSPACE_URL) {
      logger.error('Failed to send Deepspace request. Invalid/missing URL')
      return
    }
    relayTransmit(DEEPSPACE_URL, message, ENABLE_TRANSMIT_BEACONS, {
      brand,
      subsite,
      appname: APP_NAME,
      appversion: APP_VERSION,
    })
    logger.debug(`Sending "${message.type}" event`, {
      type: 'event',
      event: message,
    })
  }
}

export const useTransmit = () => {
  const { brand, subsite, originUrl } = useSiteDefinition()
  const enterpriseContext = useEnterpriseContext()

  const transmit = transmitProvider({ brand, subsite, originUrl })

  return (message: Message) => {
    message.attributes = {
      route: message.attributes?.route || enterpriseContext.route,
    }
    transmit(message)
  }
}
