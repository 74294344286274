import * as React from 'react'

import { styled, spacing } from '@thg-commerce/gravity-theme'
import { Button } from '@thg-commerce/gravity-elements'

export interface ModalFooterProps {
  onClose: () => void
  buttonText?: {
    confirmButton: string
    cancelButton: string
  }
  leftAlignButtons?: boolean
  footerPadding?: string
  /** Actions for buttons */
  confirmAction?: () => void
  cancelAction?: () => void
}

const ModalFooterContainer = styled.div<{
  leftAlignButtons?: boolean
  footerPadding?: string
}>`
  display: flex;
  justify-content: ${(props) =>
    props.leftAlignButtons ? 'flex-start' : 'flex-end'};
  padding: ${(props) =>
    props.footerPadding ? props.footerPadding : spacing(2)};
  position: relative;
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
`

const StyledCancelButton = styled(Button)`
  white-space: nowrap;
  margin: ${spacing(1)};
`

const StyledConfirmButton = styled(Button)`
  white-space: nowrap;
  padding: auto;
  margin: ${spacing(1)};
`

export const ModalFooter = React.forwardRef(
  (props: ModalFooterProps, ref: any) => {
    const onClickConfirm = () => {
      props.onClose()
      props.confirmAction && props.confirmAction()
    }
    const onClickCancel = () => {
      props.onClose()
      props.cancelAction && props.cancelAction()
    }

    return (
      <ModalFooterContainer
        leftAlignButtons={props.leftAlignButtons}
        footerPadding={props.footerPadding}
        data-testid="modal-footer"
        ref={ref}
      >
        <StyledCancelButton
          data-testid="modal-cancel-button"
          emphasis={'medium'}
          type={'button'}
          onClick={onClickCancel}
        >
          {props.buttonText?.cancelButton}
        </StyledCancelButton>
        <StyledConfirmButton
          data-testid="modal-confirm-button"
          emphasis={'high'}
          type={'button'}
          onClick={onClickConfirm}
        >
          {props.buttonText?.confirmButton}
        </StyledConfirmButton>
      </ModalFooterContainer>
    )
  },
)
