import React from 'react'

// import { useReducedMotion, Variants } from 'framer-motion'
import { StyledTabPanel } from '../styles'

// const variants: Variants = {
//   open: {
//     y: '0%',
//     visibility: 'visible',
//     transition: { ease: 'easeInOut', duration: 0.4 },
//   },
//   openInstant: {
//     y: '0%',
//     visibility: 'visible',
//     transition: { ease: 'easeInOut', duration: 0 },
//   },
//   close: {
//     y: '-100%',
//     transitionEnd: {
//       visibility: 'hidden',
//     },
//     transition: { ease: 'easeInOut', duration: 0.4 },
//   },
//   closeInstant: {
//     y: '-100%',
//     transitionEnd: {
//       visibility: 'hidden',
//     },
//     transition: { ease: 'easeInOut', duration: 0 },
//   },
// }

interface NavigationTabPanelProps {
  positionLeftOffset: number
  isVariableWidth: boolean
  children: any
  type: string
  animation: 'open' | 'close' | 'openInstant' | 'closeInstant'
  hasFeaturedColumn: boolean
  // detached menu are rhs of tab panel with a grey background
  hasDetachedMenu: boolean
  index: number
  navHeaderHeight?: number
}

export const NavigationTabPanel = (props: NavigationTabPanelProps) => {
  const {
    positionLeftOffset,
    isVariableWidth,
    children,
    type,
    // animation,
    // hasFeaturedColumn,
    // hasDetachedMenu,
    index,
  } = props
  // const shouldReduceMotion = useReducedMotion()

  // const transformedAnimation = (() => {
  //   if (shouldReduceMotion) {
  //     if (animation === 'open') return 'openInstant'
  //     if (animation === 'close') return 'closeInstant'
  //     return animation
  //   }
  //   return animation
  // })()

  // const width = (() => {
  //   // @ts-ignore
  //   const navItems = ref?.current?.children
  //   if (navItems) {
  //     if (navItems.length === 0) {
  //       return 'auto'
  //     }
  //     if (navItems[0].children) {
  //       if (hasFeaturedColumn || hasDetachedMenu) {
  //         return 'auto'
  //       }
  //       if (
  //         navItems[0].children.length === 0 ||
  //         navItems[0].children.length >= 6
  //       ) {
  //         return 'auto'
  //       }
  //       // return `calc((100% / 6) * ${navItems[0].children.length})`
  //     }
  //   }
  //   return 'auto'
  // })()

  return (
    <StyledTabPanel
      // initial="close"
      // variants={variants}
      // animate={transformedAnimation}
      offset={positionLeftOffset}
      isVariableWidth={isVariableWidth}
      width={'auto'}
      type={type}
      data-testid={`navigation-tab-panel-${index}`}
      navHeaderHeight={props.navHeaderHeight}
    >
      <div>{children}</div>
    </StyledTabPanel>
  )
}

export default NavigationTabPanel
