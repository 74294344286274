import axios from 'axios'

import getConfig from 'next/config'

import { sentry } from '../utils/sentry'

const makeBlackHoleRequest = async (
  loggingData: {
    brand: string
    subsite: string
    message: string
    serviceName: string
    serviceVersion: string
    level: 'debug' | 'error' | 'info' | 'warn'
    originUrl: string
  },
  metadata?: any,
) => {
  const { publicRuntimeConfig } = getConfig()
  const { DEEPSPACE_URL } = publicRuntimeConfig

  const url = new URL(
    String(DEEPSPACE_URL).startsWith('http')
      ? DEEPSPACE_URL
      : `${loggingData.originUrl.replace(/\/+$/, '')}/${DEEPSPACE_URL.replace(
          /^\/+/,
          '',
        )}/`,
  )
  url.pathname += 'blackhole'
  try {
    await axios.post(url.toString(), {
      ...loggingData,
      metadata: JSON.stringify(metadata),
    })
  } catch (err) {
    console.error(
      `Attempt to log event with message "${
        loggingData.message
      }" failed due to ${err.message}. DS URL: ${url.toString()}`,
    )
  }
}

export const loggerProvider = ({
  brand,
  subsite,
  originUrl,
}: {
  brand: string
  subsite: string
  originUrl: string
}) => {
  const { publicRuntimeConfig } = getConfig()
  const {
    IS_PRODUCTION,
    SENTRY_DSN,
    APP_NAME,
    APP_VERSION,
  } = publicRuntimeConfig

  return {
    error: (message: string, metadata?: { [key: string]: any }) => {
      if (metadata && IS_PRODUCTION) {
        SENTRY_DSN &&
          sentry().captureExceptionComponentLevel(
            new Error(message),
            brand,
            subsite,
          )
      }
      makeBlackHoleRequest(
        {
          brand,
          subsite,
          message,
          originUrl,
          serviceName: APP_NAME,
          serviceVersion: APP_VERSION,
          level: 'error',
        },
        metadata,
      )
      if (!IS_PRODUCTION) {
        console.error(message)
      }
    },
    debug: (message: string, metadata?: { [key: string]: any }) => {
      makeBlackHoleRequest(
        {
          brand,
          subsite,
          message,
          originUrl,
          serviceName: APP_NAME,
          serviceVersion: APP_VERSION,
          level: 'debug',
        },
        metadata,
      )
      if (!IS_PRODUCTION) {
        console.debug(message)
      }
    },
    warn: (message: string, metadata?: { [key: string]: any }) => {
      makeBlackHoleRequest(
        {
          brand,
          subsite,
          message,
          originUrl,
          serviceName: APP_NAME,
          serviceVersion: APP_VERSION,
          level: 'warn',
        },
        metadata,
      )
      if (!IS_PRODUCTION) {
        console.warn(message)
      }
    },
    info: (message: string, metadata?: { [key: string]: any }) => {
      makeBlackHoleRequest(
        {
          brand,
          subsite,
          message,
          originUrl,
          serviceName: APP_NAME,
          serviceVersion: APP_VERSION,
          level: 'info',
        },
        metadata,
      )
      if (!IS_PRODUCTION) {
        console.info(message)
      }
    },
  }
}
