import * as React from 'react'

import { i18n } from '../../i18n'
import { getSafeUrlParametersFromWindow } from '../../urlUtilities'
import { useToastPresenter } from '../ToastBannerProvider/ToastBannerProvider'

export const SessionSettingsToast = () => {
  const i18nText = {
    sessionSettings: i18n('header.sessionsettings.savenotification'),
  }

  const presentToast = useToastPresenter()

  React.useEffect(() => {
    const sessionSettingsChanged =
      typeof window !== 'undefined' &&
      (getSafeUrlParametersFromWindow(window, 'countrySelected')[
        'countrySelected'
      ] === 'Y' ||
        getSafeUrlParametersFromWindow(window, 'settingsSaved')[
          'settingsSaved'
        ] === 'Y')
    if (sessionSettingsChanged) {
      presentToast({ message: i18nText.sessionSettings })
    }
  }, [])

  return null
}
