import * as React from 'react'
export const SvgReferralsReferral = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={50} height={50} viewBox="0 0 31 24" {...props}>
    <defs>
      <path
        d="M18.6445575 21.9938014C16.7421082 23.2612712 14.4572597 24 12 24 5.373 24 0 18.627 0 12S5.373 0 12 0c2.4572597 0 4.7421082.7387288 6.6445575 2.0061986-1.1029568.03852792-2.160486.25566076-3.1445575.6233684C14.4106719 2.22252925 13.2313317 2 12 2 6.477 2 2 6.477 2 12s4.477 10 10 10c1.2313317 0 2.4106719-.2225293 3.5-.629567.9840715.3677076 2.0416007.5848405 3.1445575.6233684z"
        id="a"
      ></path>
      <mask id="c" x="0" y="0" width="18.6445575" height="24" fill="#fff">
        <use></use>
      </mask>
      <path
        d="M19 0C12.373 0 7 5.373 7 12s5.373 12 12 12 12-5.373 12-12S25.627 0 19 0zm0 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S9 17.523 9 12 13.477 2 19 2z"
        id="b"
      ></path>
      <mask id="d" x="0" y="0" width="24" height="24" fill="#fff">
        <use></use>
      </mask>
    </defs>
    <g
      className="referralsNewIconGroupColor"
      fill="#28bdb3"
      fill-rule="evenodd"
    >
      <g
        className="referralsNewIconStrokeColor"
        stroke="#28bdb3"
        stroke-width="2"
      >
        <use mask="url(#c)"></use>
        <use mask="url(#d)" fill="#FBFBFB"></use>
      </g>
      <g fill="#444444">
        <path d="M28 13h-6V7c0-.552-.448-1-1-1s-1 .448-1 1v6h-6c-.552 0-1 .448-1 1s.448 1 1 1h6v6c0 .552.448 1 1 1s1-.448 1-1v-6h6c.552 0 1-.448 1-1s-.448-1-1-1"></path>
        <path d="M28 13h-6V7c0-.552-.448-1-1-1s-1 .448-1 1v6h-6c-.552 0-1 .448-1 1s.448 1 1 1h6v6c0 .552.448 1 1 1s1-.448 1-1v-6h6c.552 0 1-.448 1-1s-.448-1-1-1"></path>
      </g>
    </g>
  </svg>
)
export default SvgReferralsReferral
