import * as React from 'react'

import { LabelText } from '../FormItem/Label'

import {
  LabelWrapper,
  StyledImageSwatchContainer,
  StyledImageSwatchElement,
  StyledLabelText,
  StyledShowMoreButton,
  StyledSpan,
} from './styles'

export interface ImageColourChoice {
  key: string
  colour: string
  title: string
  disabled?: boolean
  href?: string
  image?: string
}

export interface ImageSwatchProps {
  images: ImageColourChoice[]
  selectedImage?: string
  onImageChange?: (value: string) => void
  label: string
  labelHidden?: boolean
  required?: boolean
  ariaLabel?: string
  disabled?: boolean
  hideOptionalText?: boolean
  displayColoursAsTextOnly?: boolean
  i18nText: {
    showButtonText: string
    showMoreButtonText: string
    showLessButtonText: string
  }
  showAllImageSwatches?: boolean
}

const ImageSwatchLabel = (props: {
  label: string
  selectedImage?: string
  required?: boolean
  labelHidden?: boolean
  disabled?: boolean
  hideOptionalText?: boolean
}) => (
  <LabelWrapper
    selectedValue={!!props.selectedImage}
    className={props.disabled ? 'disabled' : ''}
  >
    <LabelText
      required={props.required}
      className={props.labelHidden ? 'hidden' : ''}
      disabled={props.disabled}
      optionalText={props.hideOptionalText ? undefined : props.selectedImage}
    >
      {props.selectedImage ? `${props.label}:` : props.label}
    </LabelText>
    {props.selectedImage && (
      <StyledLabelText
        required={false}
        className={props.labelHidden ? 'hidden' : ''}
        disabled={props.disabled}
        optionalText={''}
        labelIsSelectedValue={true}
      >
        {props.selectedImage || ''}
      </StyledLabelText>
    )}
  </LabelWrapper>
)

export const ImageSwatch = (props: ImageSwatchProps) => {
  const [showAllImages, setShowAllImages] = React.useState<boolean>(false)
  const inStockOptions = props.images.filter((image) => !image.disabled)
  const moreThanOneOptionInStock = inStockOptions.length > 1

  const imageOptionsLength = Object.keys(props.images)?.length
  return (
    <React.Fragment>
      {!props.labelHidden && (
        <ImageSwatchLabel
          label={props.label}
          selectedImage={props.selectedImage}
          required={props.required}
          labelHidden={props.labelHidden}
          disabled={props.disabled}
          hideOptionalText={props.hideOptionalText}
        />
      )}
      <StyledImageSwatchContainer
        data-testid="image-swatch-container"
        imageOptionsLength={imageOptionsLength}
        showAllImages={props.showAllImageSwatches ?? showAllImages}
        hideSwatches={
          !moreThanOneOptionInStock && props.displayColoursAsTextOnly
        }
      >
        {props.images.map((choice: ImageColourChoice) => {
          return (
            <StyledImageSwatchElement
              data-testid={choice.key}
              key={choice.key}
              choice={choice}
              onClick={
                props.onImageChange
                  ? () => {
                      props.onImageChange?.(choice.key)
                    }
                  : undefined
              }
              selected={props.selectedImage === choice.key}
            />
          )
        })}
        {!props.showAllImageSwatches && (
          <StyledShowMoreButton
            imageOptionsLength={imageOptionsLength}
            showAllImages={showAllImages}
            onClick={() => {
              setShowAllImages(!showAllImages)
            }}
          >
            <StyledSpan
              imageOptionsLength={imageOptionsLength}
              showAllImages={showAllImages}
            >
              {props.i18nText.showButtonText}
            </StyledSpan>
            <span>
              {showAllImages
                ? props.i18nText.showLessButtonText
                : props.i18nText.showMoreButtonText}
            </span>
          </StyledShowMoreButton>
        )}
      </StyledImageSwatchContainer>
    </React.Fragment>
  )
}
