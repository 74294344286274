import * as React from 'react'
import dynamic from 'next/dynamic'

import { SessionSettingsModalProps } from '../SessionSettingsModal/SessionSettingsModal'

const SessionSettingsModal = dynamic<SessionSettingsModalProps>(
  import('../SessionSettingsModal/SessionSettingsModal').then(
    (mod) => mod.SessionSettingsModal,
  ),
)

export const SessionSettingsModalFactory = (
  i18nText,
  countryDomainMap,
  availableCurrencies,
) => {
  return (onClose, shouldRemoveScrollLock) => (
    <SessionSettingsModal
      i18nText={i18nText}
      onClose={onClose}
      countryDomainMap={countryDomainMap}
      availableCurrencies={availableCurrencies}
      shouldRemoveScrollLock={!shouldRemoveScrollLock}
    />
  )
}
