import * as React from 'react'

import { ApolloProvider as ApolloRootProvider } from '@apollo/react-hooks'
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'

export const ApolloProvider = (props: {
  client: ApolloClient<NormalizedCacheObject>
  config?: any
  children?: React.ReactNode
}) => {
  const apolloClient: ApolloClient<NormalizedCacheObject> = props.client

  return (
    <ApolloRootProvider client={apolloClient}>
      {props.children}
    </ApolloRootProvider>
  )
}

export default ApolloProvider
