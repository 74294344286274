import * as React from 'react'
export const SvgCircularTwitch = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <circle fill="#ffff" cx={20} cy={20} r={20} />
    <path
      fill={props.fill || '#772ce8'}
      d="M30 9v13.144l-7.442 7.509h-3.72L16.51 32h-2.324v-2.347H10V12.755L10.93 9H30zm-1.86 1.878H12.792V24.49h4.186v2.816l2.791-2.816h5.116l3.257-3.287V10.878zM19.868 14v6H18v-6h1.867zM25 14v6h-1.867v-6H25z"
    />
  </svg>
)
export default SvgCircularTwitch
