import * as React from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { LoadScript } from '@react-google-maps/api'
import gql from 'graphql-tag'

import {
  AutocompletePrediction,
  BasketContext,
  EnterpriseContext,
  i18n,
  useAddToBasket,
  useBasketId,
  useHorizonSessionSettings,
  useLogger,
  useSiteConfig,
} from '@thg-commerce/enterprise-core'
import { InteractionLocation } from '@thg-commerce/enterprise-core/src/Basket'
import { useBasket } from '@thg-commerce/enterprise-core/src/Basket/hooks/useBasketId'
import { pushToEnhancedDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import { ModalRenderer } from '@thg-commerce/enterprise-modal'
import {
  ChangeFulfilmentTypeData,
  ChangeFulfilmentTypeVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Mutation/ChangeFulfilmentType'
import {
  ClickAndCollectStoresData,
  ClickAndCollectStoresVariables,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/ClickAndCollectStores'
import { FulfilmentMethod } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { spacing } from '@thg-commerce/enterprise-theme'

import { ClickAndCollectModal } from '../ClickAndCollectModal'

export enum ErrorTypes {
  POSTCODE_REQUIRED = 'POSTCODE_REQUIRED',
  NO_RESULTS_FOUND = 'NO_RESULTS_FOUND',
}

interface FiveStoreAvailabilityEnhancedEcommerceEvent {
  event: 'fiveStoreInfo'
  ecommerce: {
    detail: {
      products: {}
    }
  }
}

const ZERO_RESULTS_STATUS: string = 'ZERO_RESULTS'

export const CLICK_AND_COLLECT_STORES = gql`
  query ClickAndCollectStores(
    $sku: SKU!
    $longitude: Float!
    $latitude: Float!
    $limit: Int!
  ) {
    clickAndCollectStores(
      sku: $sku
      longitude: $longitude
      latitude: $latitude
      limit: $limit
    ) @client {
      eligibleForFulfilmentMethods
      isCheckStock
      isOrderInStore
      stores {
        id
        fulfilmentMethods
        stock
        ranged
        distance
        displayName
        openingTimes {
          openingTime
          closingTime
          day
        }
      }
    }
  }
`

export const SEARCH_LOCATIONS = gql`
  query SearchLocations($query: String!) {
    searchLocations(query: $query) @client {
      searchLocations {
        displayName
        longitude
        latitude
        postcode
      }
    }
  }
`

export const CHANGE_PRODUCT_FULFILMENT_MUTATION = gql`
  mutation ChangeFulfilmentType(
    $basketId: ID
    $itemId: ID!
    $fulfilmentInput: BasketAddFulfilmentInput!
    $settings: SessionSettings!
  ) {
    changeFulfilmentType(
      basketId: $basketId
      itemId: $itemId
      fulfilmentInput: $fulfilmentInput
      settings: $settings
    ) {
      id
      items {
        fulfilmentMethod
      }
    }
  }
`

export const ClickAndCollectModalPresenter = () => {
  const {
    geocodeApiKeyClickAndCollect,
    allowedCountryForStoreLocator,
  } = useSiteConfig()

  const logger = useLogger()

  const basketContext = React.useContext(BasketContext)
  const basketData = useBasket()
  const { headerHeight } = React.useContext(EnterpriseContext)
  const [visible, setVisible] = React.useState(false)
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = React.useState(false)
  const [error, setError] = React.useState<ErrorTypes | undefined>()
  const [searchLocations, setSearchLocations] = React.useState<
    AutocompletePrediction[]
  >([])

  const [searchPerformed, setSearchPerformed] = React.useState(false)

  const [
    clickAndCollectStoresData,
    setClickAndCollectStoresData,
  ] = React.useState<
    | {
        clickAndCollectStores: ClickAndCollectStoresData
      }
    | undefined
  >(undefined)

  const initialProductImage = React.useRef<string>('')
  const initialProductTitle = React.useRef<string>('')
  const initialIsProductPage = React.useRef<boolean>(false)
  const initialProductQuantity = React.useRef<number>(0)
  const initialProductFulfilmentMethod = React.useRef<
    FulfilmentMethod | undefined
  >()
  const initialProductSku = React.useRef<string>('')
  const initialItemId = React.useRef<string>('')
  const initialExternalIdentifier = React.useRef<string>('')

  const { execute: addToBasket } = useAddToBasket({
    forceAddToBasket: true,
  })

  const findItemSku = basketData.basket?.items.find(
    (item) =>
      item.product.sku.toString() === initialProductSku.current.toString(),
  )

  const [basketId, setBasketId] = useBasketId()
  const sessionSettings = useHorizonSessionSettings()

  const [changeFulfilmentMethod] = useMutation<
    { changeFulfilmentType: ChangeFulfilmentTypeData },
    ChangeFulfilmentTypeVariables
  >(CHANGE_PRODUCT_FULFILMENT_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data && data.changeFulfilmentType) {
        setBasketId(data.changeFulfilmentType.id)
        setVisible(false)
      }
    },
  })

  const modalCloseI18nText = {
    closeAriaLabel: i18n('general.modal.close.button.arialabel'),
    closeLabel: i18n('general.modal.close.button.label'),
  }

  const modalI18nText = {
    title: i18n('clickandcollect.search.title'),
    subtitle: i18n('clickandcollect.search.subtitle', '5'),
    searchResults: {
      clickAndCollectButton: i18n('clickandcollect.selectstore'),
      deliverToStoreButton: i18n('clickandcollect.storedeliver'),
      deliverToStoreAvailability: i18n(
        'clickandcollect.store.availability.stockmessage',
      ),
      inStockText: (storeStock: string) =>
        i18n('clickandcollect.store.availability.instock', storeStock),
      outOfStockText: i18n('clickandcollect.store.availability.outofstock'),
      unavailableStore: i18n('clickandcollect.store.availability.not.ranged'),
      unavailableClickAndCollectProduct: i18n(
        'clickandcollect.productstock.messages.noclickandcollectonproduct',
      ),
      unavailableClickAndCollectStore: i18n(
        'clickandcollect.productstock.messages.noclickandcollectonstore',
      ),
      productNotRanged: i18n('productStock.messages.notranged'),
    },
    locationSearch: {
      searchLabel: i18n('clickandcollect.search.label'),
      buttonText: i18n('clickandcollect.search.cta'),
      stockDefaultMessage: i18n('clickandcollect.messages.outofstock'),
    },
    errorMessages: {
      [ErrorTypes.POSTCODE_REQUIRED]: i18n(
        'clickandcollect.messages.postcoderequired',
      ),
      [ErrorTypes.NO_RESULTS_FOUND]: (postCode: string) =>
        i18n('clickandcollect.messages.noresultsfound', postCode),
    },
  }

  const [
    getClickAndCollectStores,
    { data: clickAndCollectStores, error: clickAndCollectStoresError },
  ] = useLazyQuery<
    { clickAndCollectStores: ClickAndCollectStoresData },
    ClickAndCollectStoresVariables
  >(CLICK_AND_COLLECT_STORES, { fetchPolicy: 'network-only' })

  React.useEffect(() => {
    if (searchPerformed) {
      setClickAndCollectStoresData(clickAndCollectStores)
    }
  }, [searchPerformed, clickAndCollectStores])

  React.useEffect(() => {
    if (error || !searchPerformed) {
      setSearchLocations([])
      setClickAndCollectStoresData(undefined)
    }
  }, [error, searchPerformed])

  React.useEffect(() => {
    if (
      clickAndCollectStoresError ||
      (clickAndCollectStores &&
        clickAndCollectStores.clickAndCollectStores.stores.length === 0)
    ) {
      setError(ErrorTypes.NO_RESULTS_FOUND)
      setClickAndCollectStoresData(undefined)
    }

    if (
      clickAndCollectStores &&
      clickAndCollectStores?.clickAndCollectStores?.stores.length > 0
    ) {
      pushToEnhancedDataLayer<FiveStoreAvailabilityEnhancedEcommerceEvent>({
        event: 'fiveStoreInfo',
        ...Object.assign(
          {},
          ...clickAndCollectStores.clickAndCollectStores.stores.map(
            (store, index) => ({
              [`datav3-stock-availability-reservable-${
                index + 1
              }`]: store.fulfilmentMethods.find(
                (fulfilmentMethod) =>
                  fulfilmentMethod === FulfilmentMethod.CollectInStore,
              )
                ? 'true'
                : 'false',
              [`datav3-stock-availability-status-${index + 1}`]:
                store.fulfilmentMethods.find(
                  (fulfilmentMethod) =>
                    fulfilmentMethod === FulfilmentMethod.CollectInStore,
                ) && store.stock > 0
                  ? 'available'
                  : 'outOfStock',
              [`datav3-stock-availability-store-id-${index + 1}`]: store.id,
              [`datav3-stock-availability-store-name-${
                index + 1
              }`]: store.displayName,
              [`datav3-stock-availability-store-quantity-${
                index + 1
              }`]: store.stock.toString(),
              [`datav3-stock-availability-store-recovery-shown-${
                index + 1
              }`]: store.fulfilmentMethods.find(
                (fulfilmentMethod) =>
                  fulfilmentMethod === FulfilmentMethod.DeliverToStore,
              )
                ? 'true'
                : 'false',
            }),
          ),
        ),
        [`datav3-stock-availability-product-id`]: findItemSku?.product
          .externalIdentifier
          ? findItemSku?.product.externalIdentifier
          : initialExternalIdentifier.current.toString(),
        ['datav3-stock-availability-total-available']: clickAndCollectStores.clickAndCollectStores.stores.filter(
          (store) =>
            store.fulfilmentMethods.find(
              (fulfilmentMethod) =>
                fulfilmentMethod === FulfilmentMethod.CollectInStore,
            ) && store.stock > 0,
        ).length,
        [`datav3-stock-availability-total-stores-shown`]: clickAndCollectStores
          .clickAndCollectStores.stores.length,
      })
    }
  }, [
    clickAndCollectStores,
    clickAndCollectStoresError,
    findItemSku?.product.externalIdentifier,
  ])

  if (!basketContext.presentClickAndCollectModal) {
    return null
  }

  basketContext.presentClickAndCollectModal.current = (
    sku: string,
    largeProductImage: string,
    productTitle: string,
    isProductPage: boolean,
    quantity: number,
    itemId?: string,
    externalIdentifier?: string,
  ) => {
    initialProductTitle.current = productTitle
    initialProductImage.current = largeProductImage
    initialIsProductPage.current = isProductPage || false
    initialProductFulfilmentMethod.current = undefined
    initialProductSku.current = sku
    initialProductQuantity.current = quantity
    if (itemId) {
      initialItemId.current = itemId
    }
    if (externalIdentifier) {
      initialExternalIdentifier.current = externalIdentifier
    }
    setVisible(true)
  }

  if (!visible) {
    return null
  }

  const handleGetSearchLocations: (
    searchValue: string,
  ) => Promise<any> = async (searchValue: string) => {
    if (isGoogleMapsLoaded) {
      const geocoder = new google.maps.Geocoder()

      const options = {
        address: searchValue,
        componentRestrictions: { country: 'uk' },
        region: 'uk',
      }

      const result = await geocoder.geocode(options, (results, status) => {
        if (
          status === ZERO_RESULTS_STATUS ||
          results?.[0].address_components[0].long_name ===
            allowedCountryForStoreLocator
        ) {
          setError(ErrorTypes.NO_RESULTS_FOUND)
          return null
        }
        return
      })

      return result
    }

    return null
  }

  const handleGetClickAndCollectStores = async (locationData: string) => {
    try {
      const location = await handleGetSearchLocations(locationData)
      if (
        !location ||
        !location.results ||
        !location.results[0] ||
        !location.results[0].geometry.location
      ) {
        return setError(ErrorTypes.NO_RESULTS_FOUND)
      }
      setSearchPerformed(true)
      const lat = location.results[0].geometry.location.lat()
      const long = location.results[0].geometry.location.lng()
      getClickAndCollectStores({
        variables: {
          latitude: lat,
          longitude: long,
          sku: initialProductSku.current.toString(),
          limit: 5,
        },
      })
      setClickAndCollectStoresData(clickAndCollectStores)
    } catch (e) {
      logger.error(e)
    }
  }

  const isClickAndCollect =
    clickAndCollectStoresData?.clickAndCollectStores.eligibleForFulfilmentMethods?.includes(
      FulfilmentMethod.CollectInStore,
    ) || false

  const handleAddToBasketButton = (
    fulfilmentType: FulfilmentMethod,
    storeId: string,
  ) => {
    if (initialIsProductPage.current) {
      addToBasket(
        [
          {
            sku: initialProductSku.current.toString(),
            quantity: initialProductQuantity.current,
          },
        ],
        {
          fromRecommendations: false,
          location: InteractionLocation.PRODUCT_LIST,
        },
        fulfilmentType,
        storeId,
      )
      setVisible(false)
    } else {
      changeFulfilmentMethod({
        variables: {
          basketId,
          fulfilmentInput: {
            storeId,
            method: fulfilmentType,
          },
          itemId: initialItemId.current,
          settings: sessionSettings,
        },
      })
    }
  }

  const productStoreId = initialIsProductPage.current
    ? basketData.basket?.items.find(
        (item) =>
          item.product.sku.toString() === initialProductSku.current.toString(),
      )?.store?.id
    : undefined

  return (
    <React.Fragment>
      <LoadScript
        id="google-api"
        googleMapsApiKey={geocodeApiKeyClickAndCollect || ''}
        libraries={['places']}
        loadingElement={<React.Fragment />}
        onLoad={() => setIsGoogleMapsLoaded(true)}
      />
      {isGoogleMapsLoaded && (
        <ModalRenderer
          data-testid="click-and-collect-modal"
          isOpen={visible}
          onClose={() => setVisible(false)}
          gridColSpan={[12, 10, 10, 8]}
          headerOffset={headerHeight}
          closeI18nText={modalCloseI18nText}
          contentPadding={`${spacing(6)} ${spacing(4)} ${spacing(5)} ${spacing(
            4,
          )}`}
          showHeader={false}
          stickyHeader={true}
          renderContent={(close) => (
            <ClickAndCollectModal
              i18nText={modalI18nText}
              productStoreId={productStoreId}
              isGoogleMapsLoaded={isGoogleMapsLoaded}
              setSearchPerformed={setSearchPerformed}
              searchPerformed={searchPerformed}
              product={{
                isClickAndCollect,
                image: initialProductImage.current,
                title: initialProductTitle.current,
                productFulfilmentMethod: initialProductFulfilmentMethod.current,
                sku: initialProductSku.current,
                isCheckStock:
                  clickAndCollectStoresData?.clickAndCollectStores
                    .isCheckStock || false,
                isOrderInStore:
                  clickAndCollectStoresData?.clickAndCollectStores
                    .isOrderInStore || false,
              }}
              onClick={(fulfilmentType, storeId) => {
                handleAddToBasketButton(fulfilmentType, storeId)
                close()
              }}
              isProductPage={initialIsProductPage.current}
              setError={setError}
              error={error}
              getClickAndCollectStoresCallback={handleGetClickAndCollectStores}
              stores={clickAndCollectStoresData?.clickAndCollectStores.stores}
              searchLocations={searchLocations}
              setSearchLocations={setSearchLocations}
            />
          )}
        />
      )}
    </React.Fragment>
  )
}
