import { MotionDiv } from '@thg-commerce/gravity-animations'
import { ChevronRight } from '@thg-commerce/gravity-icons/src'
import { FlyoutDirection } from '@thg-commerce/gravity-patterns/Header/theme'
import { css, styled } from '@thg-commerce/gravity-patterns/theme'
import { spacing, Text, zIndex, ZIndexLevel } from '@thg-commerce/gravity-theme'

import { navigationLinkBorder } from '../NavigationItems/NavigationTextItem'
import { HeaderZIndexLevel } from '../types'

const FlyoutMenuStyle = css`
  position: fixed;
  top: 0;
  bottom: 0;
  width: ${(props) => props.theme.patterns.header.flyout.width};
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  overflow-x: hidden;
`

const FlyoutFooterStyle = css`
  background-color: ${(props) =>
    props.theme.patterns.header.subNavigation.mobile.footerBackgroundColor};
  flex-grow: 1;
  padding-top: ${spacing(1)};
`

const FlyoutHeaderWrapperStyle = css`
  position: sticky;
  top: 0;
  ${zIndex(ZIndexLevel.High)};
`

const FlyoutHeaderStyle = css`
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  box-sizing: border-box;
`

const FlyoutHeaderContentStyle = css`
  display: flex;
  justify-content: space-between;
  padding: ${spacing(1)};
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  border-bottom: ${(props) =>
    props.theme.patterns.header.navigation.borderBottom};
`
export const HeaderButtonCommonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.palette.brand.base};
  border: 2px solid transparent;
  height: 40px;
  min-width: 40px;

  svg,
  path {
    fill: ${(props) =>
      props.theme.patterns.header.navigation.homeIconColor ||
      props.theme.colors.palette.brand.base};
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }
`

const HeaderButtonStyle = css`
  ${Text('bodyText', 'default')}
  ${HeaderButtonCommonStyles}
`

const HeaderButtonTextStyle = css`
  display: block;
  padding-right: ${spacing(1)};

  color: ${(props) =>
    props.theme.patterns?.header.navigation.mobileLinkFont.textColor};
`
export const StyledFlyoutMenu = styled(MotionDiv)<{
  direction: FlyoutDirection
  allowScrollYaxis?: boolean
}>`
  ${(props) => {
    switch (props.direction) {
      case FlyoutDirection.RIGHT:
        return 'right: 0;'
      case FlyoutDirection.LEFT:
        return 'left: 0;'
      default:
        return ''
    }
  }}
  ${FlyoutMenuStyle}
  ${(props) => props.allowScrollYaxis && 'overflow-y: auto;'}
  ${zIndex(HeaderZIndexLevel.Flyout)};
`

export const FlyoutHeaderWrapper = styled.div`
  ${FlyoutHeaderWrapperStyle}
`

export const FlyoutHeader = styled.div`
  ${FlyoutHeaderStyle}
`

export const FlyoutHeaderContent = styled.div<{
  enableThreeTierNav?: boolean
}>`
  ${FlyoutHeaderContentStyle};
  ${(props) =>
    props.enableThreeTierNav &&
    `
    padding:${props.theme.patterns.header.flyout.flyoutHeaderContentPadding}; 
    flex-wrap: wrap;`};
`

export const Space = styled.div`
  display: flex;
  flex-grow: 1;
`

export const FlyoutNav = styled.nav<{ cardBackground: boolean }>`
  position: relative;
  ${(props) =>
    props.cardBackground &&
    `background: ${props.theme.patterns.header.subNavigation.mobile.footerBackgroundColor};`}
  ${(props) => props.cardBackground && `height: 100%;`}
`

export const NavList = styled.ul<{ isImageCardList?: boolean }>`
  list-style: none;
  margin: ${(props) => (props.isImageCardList ? `${spacing(1.5)}` : '0')} 0;
`

export const FlyoutFooter = styled.div`
  ${FlyoutFooterStyle}
`

export const StyledMotionDiv = styled(MotionDiv)<{ maxHeight: boolean }>`
  position: absolute;
  width: 100%;
  height: ${(props) => (props.maxHeight ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
`

export const HeaderButton = styled.button`
  ${HeaderButtonStyle}
`

export const HeaderButtonText = styled.span`
  ${HeaderButtonTextStyle}
`

export const NavigationLabel = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.flyout?.headerLabel.entry,
      props.theme.patterns.header.navigation.flyout?.headerLabel.style,
    )}
  text-transform: ${(props) =>
    props.theme.patterns.header.navigation.flyout?.headerLabel.transform};
  text-decoration: ${(props) =>
    props.theme.patterns.header.navigation.flyout?.headerLabel.textDecoration};
  color: ${(props) =>
    props.theme.patterns.header.navigation.flyout?.headerLabel.textColor};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const HeaderLink = styled.a`
  ${HeaderButtonCommonStyles}
`

export const FlyoutOverlay = styled(MotionDiv)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  ${zIndex(HeaderZIndexLevel.Overlay)};
`

export const StyledLi = styled.li<{
  currentLevel: number
  hidePadding?: boolean
  enableThreeTierNav?: boolean
  thirdLevelLink?: boolean
  hideBottomBorder?: boolean
}>`
  margin: ${(props) =>
    props.enableThreeTierNav && props.currentLevel === 2
      ? props.thirdLevelLink
        ? `${spacing(1)} 0`
        : `${spacing(2)} 0`
      : '0'};

  ${(props) =>
    props.hidePadding
      ? 'padding: 0;'
      : `padding: ${spacing(
          props.theme.patterns.header.navigation.navigationListPadding || 0,
        )} 0`};

  ${(props) => props.currentLevel !== 2 && navigationLinkBorder}
`

export const FlyoutMenuLinkContainer = styled.a<{ thirdLevelLink?: boolean }>`
  display: flex;
  flex-direction: row;
  ${(props) =>
    !props.thirdLevelLink &&
    `background-color: ${props.theme.colors.palette.greys.white};`}
  padding: ${spacing(2)} 0px;

  align-items: center;
  justify-content: space-between;

  ${(props) =>
    Text(
      props.theme.patterns.header.headerSlots.textStyling.inactive.entry,
      props.theme.patterns.header.headerSlots.textStyling.inactive.style,
    )};
  text-transform: ${(props) =>
    props.theme.patterns.header.headerSlots.textStyling.inactive.transform};
  text-decoration: ${(props) =>
    props.theme.patterns.header.headerSlots.textStyling.inactive
      .textDecoration};

  &:hover {
    cursor: pointer;
    ${(props) =>
      Text(
        props.theme.patterns.header.headerSlots.textStyling.active.entry,
        props.theme.patterns.header.headerSlots.textStyling.active.style,
      )};
    text-transform: ${(props) =>
      props.theme.patterns.header.headerSlots.textStyling.active.transform};
    text-decoration: ${(props) =>
      props.theme.patterns.header.headerSlots.textStyling.active
        .textDecoration};
  }
`

export const FlyoutMenuContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 ${spacing(1)};

  svg {
    fill: ${(props) =>
      props.theme.patterns.header.headerSlots.iconColor.inactive};
  }

  &:hover {
    cursor: pointer;

    svg {
      fill: ${(props) =>
        props.theme.patterns.header.headerSlots.iconColor.active};
    }
  }
`

export const FlyoutMenuLinkIconContainer = styled.a`
  padding-left: ${spacing(1)};

  svg {
    fill: ${(props) =>
      props.theme.patterns.header.headerSlots.iconColor.inactive};
  }

  &:hover {
    svg {
      fill: ${(props) =>
        props.theme.patterns.header.headerSlots.iconColor.active};
    }
  }
`

export const FlyoutMenuLink = styled.a`
  display: flex;
  flex-direction: row;
  text-decoration: none;

  color: ${(props) =>
    props.theme.patterns.header.headerSlots.textStyling.inactive.textColor};

  &:hover {
    color: ${(props) =>
      props.theme.patterns.header.headerSlots.textStyling.active.textColor};
  }
`

export const StyledChevronRight = styled(ChevronRight)`
  width: 24px;
  margin-right: ${spacing(2)};
`

export const ResponsiveFlyoutMenuIcon = styled.svg`
  path {
    fill: ${(props) => props.theme.patterns.header.navigation.homeIconColor};
  }
`
