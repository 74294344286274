import * as React from 'react'
export const SvgTwitterOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="26" height="27" viewBox="0 0 26 27" fill="none" {...props}>
    <g clip-path="url(#clip0_215_85564)">
      <path
        d="M15.4743 11.7708L25.1528 0.766846H22.8583L14.4538 10.3214L7.7415 0.766846H0L10.1508 15.2141L0 26.7541H2.2945L11.1692 16.6635L18.2585 26.7541H26L15.4743 11.7708ZM12.3327 15.3413L11.3035 13.9025L3.12 2.45568H6.643L13.247 11.6945L14.2762 13.1333L22.8605 25.1416H19.3375L12.3327 15.3413Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_215_85564">
        <rect
          width="26"
          height="26"
          fill="white"
          transform="translate(0 0.766846)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default SvgTwitterOutline
