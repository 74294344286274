import * as React from 'react'
export const SvgLiveChat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || '#333'}
      fillRule="evenodd"
      d="M12 24a1 1 0 01-1-1v-9a1 1 0 011-1h16a1 1 0 011 1v9a1 1 0 01-1 1h-5.871l-7.127 7L15 24h-3z"
    />
  </svg>
)
export default SvgLiveChat
