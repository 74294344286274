import {
  Button,
  ProductButton,
  ProductImage,
  ProductQuantity,
  ProductReview,
  SafeHtml,
  Swatch,
  SwatchProps,
} from '@thg-commerce/gravity-elements'
import { ButtonProps } from '@thg-commerce/gravity-elements/Button/Button'
import { ProductReviewProps } from '@thg-commerce/gravity-elements/Product/ProductReview/ProductReview'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { Picture, PictureProps } from '@thg-commerce/gravity-system'
import {
  BreakpointArray,
  css,
  dangerous_mqMax,
  Direction,
  margin,
  mediaQueryRenderer,
  mq,
  spacing,
  Text,
  ThemeInterface,
} from '@thg-commerce/gravity-theme'
import {
  BreakpointKey,
  breakpointUtils,
} from '@thg-commerce/gravity-theme/breakpoints'
import { Margin } from '@thg-commerce/gravity-theme/margin'

import {
  MarketedSpecialOfferSummaryContainer,
  MarketedSpecialOfferSummaryContainerProps,
} from '../MarketedSpecialOfferSummary'

import { ProductBlockFocusStyle } from './theme'
import {
  Directions,
  FlexAlignments,
  HorizontalAlignment,
  ProductBlockComponents,
} from './types'

export type ProductBlockFocusProps = {
  focusStyle?: ProductBlockFocusStyle
}

export type FocusStyle<T extends {}> = T & ProductBlockFocusProps

interface ContainerProps {
  directions: Directions
  condensed: boolean
  useAlternateGap?: boolean
  height?: string
}

interface ImageProps {
  maxWidths?: string[]
}

const setImageMaxWidth = (props: ImageProps & { theme: ThemeInterface }) => css`
  cursor: pointer;
  flex-shrink: 0;

  ${props.maxWidths?.map(
    (maxWidth, index) => `
      ${mq(
        props.theme.breakpointUtils.map,
        breakpointUtils.keys[index] as BreakpointKey,
      )} {
        max-width: ${maxWidth};
      }
    `,
  )}
`

const FocusStyle = (props: FocusStyle<{ theme: ThemeInterface }>) =>
  css`
    &:focus,
    &:focus-visible {
      outline: none;
      border-width: ${props.focusStyle?.width || '2px'};
      border-style: ${props.focusStyle?.style || 'solid'};
      border-color: ${props.focusStyle?.color ||
      props.theme.colors.palette.brand.base ||
      'transparent'};
    }
  `

export const StyledImage = styled(ProductImage)<FocusStyle<ImageProps>>`
  grid-area: ${ProductBlockComponents.IMAGE_CONTAINER};
  aspect-ratio: ${(props) =>
    props.theme.patterns.imageGallery.image.aspectRatio
      ? props.theme.patterns.imageGallery.image.aspectRatio
      : 'auto 1/1'};
  ${(props) => setImageMaxWidth(props)};

  ${FocusStyle}
`

export const StyledPicture = styled(Picture)<PictureProps>`
  grid-area: ${ProductBlockComponents.IMAGE_CONTAINER};
  aspect-ratio: ${(props) =>
    props.theme.patterns.imageGallery.image.aspectRatio
      ? props.theme.patterns.imageGallery.image.aspectRatio
      : 'auto 1/1'};

  ${(props) => setImageMaxWidth(props)};
`

// This StyledHoverImage is required to prevent this style from
// conflicting with the StyledImage
export const StyledHoverImage = styled(ProductImage)<ImageProps>`
  aspect-ratio: ${(props) =>
    props.theme.patterns.imageGallery.image.aspectRatio
      ? props.theme.patterns.imageGallery.image.aspectRatio
      : 'auto 1/1'};

  ${(props) => setImageMaxWidth(props)}
`

export const StyledHoverPicture = styled(Picture)<PictureProps>`
  grid-area: ${ProductBlockComponents.IMAGE_CONTAINER};
  aspect-ratio: ${(props) =>
    props.theme.patterns.imageGallery.image.aspectRatio
      ? props.theme.patterns.imageGallery.image.aspectRatio
      : 'auto 1/1'};

  ${(props) => setImageMaxWidth(props)};
`

// In the Styled Components version: 6.3.4, there is a bug which
// incorrectly applies the overwritten styles. To prevent this,
// we have applied the styles to this surrounding div instead
export const StyledHoverImageContainer = styled.div`
  width: 100%;
  display: none;
`

export const StyledImageLink = styled.a<ProductBlockFocusProps>`
  border: ${(props) =>
    props.theme.patterns.productBlock?.productBorder ||
    `2px solid transparent`};

  display: block;

  ${FocusStyle}
`

export const StyledAnchor = styled.div`
  grid-area: ${ProductBlockComponents.IMAGE_CONTAINER};
  border: ${(props) =>
    props.theme.patterns.productBlock?.productBorder ||
    `2px solid transparent`};
  display: block;

  aspect-ratio: ${(props) =>
    props.theme.patterns.imageGallery.image.aspectRatio
      ? props.theme.patterns.imageGallery.image.aspectRatio
      : 'auto 1/1'};

  ${FocusStyle}
`

export const ImageContainer = styled.div`
  grid-area: ${ProductBlockComponents.IMAGE_CONTAINER};
  border: ${(props) =>
    props.theme.patterns.productBlock
      ? props.theme.patterns.productBlock.productBorder
      : `2px solid transparent`};
  display: block;

  aspect-ratio: ${(props) =>
    props.theme.patterns.imageGallery.image.aspectRatio
      ? props.theme.patterns.imageGallery.image.aspectRatio
      : 'auto 1/1'};

  &:hover {
    ${StyledImage} {
      display: none;
    }
    ${StyledPicture} {
      display: none;

      & + div {
        display: none;
      }
    }
    ${StyledHoverImageContainer} {
      display: inline;
    }
  }

  ${FocusStyle}
`

export const attributesBottomMargin = `
  margin-bottom: ${spacing(2)};
`

export const attributesBottomMarginCondensed = `
  margin-bottom: ${spacing(1)};
`

export const StyledButton = styled(Button)<
  FocusStyle<ButtonProps> & { removePadding: boolean }
>`
  text-transform: none;
  text-decoration: none;
  ${(props) => props.removePadding && `padding: 0;`}
  order: ${({ theme }) =>
    theme.patterns.productBlock.components.titleLabel.order};
  &:hover {
    text-decoration: underline;
    ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
      ${(props) => props.removePadding && `padding: 0;`}
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ${(props) => props.removePadding && `padding: 0;`}
  }

  ${FocusStyle}
`
export const Label = styled.div`
  text-decoration: ${({ theme }) =>
    theme.patterns.productBlock.components.label.title.font.textDecoration};
  color: ${({ theme }) =>
    theme.patterns.productBlock.components.label.title.font.textColor};
  order: ${({ theme }) => theme.patterns.productBlock.components.label.order};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  order: ${({ theme }) =>
    theme.patterns.productBlock.components.titleLabel.order};
`

export const Brand = styled.h2<{
  useAlternateStyle: boolean
  alignment?: HorizontalAlignment
}>`
  ${Text('medium1', 'default')}
  word-break: break-word;
  ${(props) => props.alignment && `text-align:${props.alignment};`}

  ${attributesBottomMargin}
`

export const Title = styled.h3<{
  useAlternateStyle: boolean
  alignment?: HorizontalAlignment
  margin?: Margin
}>`
  word-break: break-word;
  ${(props) =>
    Text(
      props.theme.patterns.productBlock.components.titleLabel.title.font.entry,
      props.useAlternateStyle
        ? 'alternate'
        : props.theme.patterns.productBlock.components.titleLabel.title.font
            .style,
    )}
  text-align: ${(props) =>
    props.theme.patterns.productBlock.components.titleLabel.title.alignment
      ? props.theme.patterns.productBlock.components.titleLabel.title.alignment
      : props.alignment && `text-align:${props.alignment};`};

  ${(props) =>
    props.theme.patterns.productBlock.transformedTitle &&
    `text-transform: ${props.theme.patterns.productBlock.components.titleLabel.title.font.transform};`}
  ${(props) => props.margin && margin(props.margin)}
`

export const Description = styled(SafeHtml)`
  ${Text('bodyText', 'default')};
  margin-bottom: ${spacing(1)};
  order: ${({ theme }) =>
    theme.patterns.productBlock.components.description.order};
`

export const IconsAndPriceWrapper = styled.div<{
  withIcons: boolean
}>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 2px solid transparent;
  height: ${({ theme }) =>
    theme.patterns.productBlock.components.iconsPrice.height};
  justify-content: ${({ theme }) =>
    theme.patterns.productBlock.components.iconsPrice.justifyContent};
  margin: ${({ theme }) =>
    margin(theme.patterns.productBlock.components.iconsPrice.margin)};
  order: ${({ theme }) =>
    theme.patterns.productBlock.components.iconsPrice.order};
  ${(props) =>
    props.withIcons &&
    `
      justify-content: space-between;
      width: 100%;
    `}

  ${FocusStyle}
`

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledProductReview = styled(ProductReview)<
  FocusStyle<ProductReviewProps>
>`
  order: ${({ theme }) =>
    theme.patterns.productBlock.components.productReview.order};

  ${FocusStyle}
`

export const PowerReviewCategorySnippetContainer = styled.div<{
  disableHeightPowerReviews?: boolean
}>`
  order: ${({ theme }) =>
    theme.patterns.productBlock.components.powerReviewCategorySnippet.order};
    min-height: 25px;
}
`

export const StyledMarketedSpecialOfferSummaryContainer = styled(
  MarketedSpecialOfferSummaryContainer,
)<FocusStyle<MarketedSpecialOfferSummaryContainerProps>>`
  order: ${({ theme }) =>
    theme.patterns.productBlock.components.marketedSpecialOffer.order};

  ${FocusStyle}
`

export const StyledSwatch = styled(Swatch)<FocusStyle<SwatchProps>>`
  order: ${({ theme }) => theme.patterns.productBlock.components.swatch.order};
  width: max-content;
  max-width: 100%;

  ${FocusStyle}
`

export const StyledProductQuantity = styled(ProductQuantity)`
  ${attributesBottomMargin}
`

export const AttributesContainer = styled.div<{
  justifyContent?: string
  alignItems?: string
  attributesInsetSpacing?: BreakpointArray<number>
  directions: Directions
  gap?: BreakpointArray<number>
  maxWidth?: string
}>`
  display: flex;

  ${(props) =>
    mediaQueryRenderer(
      props.gap || props.theme.patterns.productBlock.attributes.gap,
      (gap) => `gap: ${spacing(gap)}`,
    )}

  flex-direction: column;

  height: 100%;
  ${(props) =>
    props.directions.map(
      (directions, index) => `
        ${mq(
          props.theme.breakpointUtils.map,
          breakpointUtils.keys[index] as BreakpointKey,
        )}
        justify-content: ${
          props.justifyContent || directions === Direction.ROW
            ? props.theme.patterns.productBlock.alignment.row.justifyContent
            : props.theme.patterns.productBlock.alignment.column.justifyContent
        };
        align-items: ${
          props.alignItems || directions === Direction.ROW
            ? props.theme.patterns.productBlock.alignment.row.alignItems
            : props.theme.patterns.productBlock.alignment.column.alignItems
        };
      `,
    )}

  ${({ attributesInsetSpacing }) =>
    attributesInsetSpacing &&
    mediaQueryRenderer(
      attributesInsetSpacing,
      (insetSpacing) => `margin: 0 ${spacing(insetSpacing)}`,
    )}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  }
`

export const ProductOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-area: ${ProductBlockComponents.OPTIONS};
  order: ${({ theme }) => theme.patterns.productBlock.components.options.order};
`

export const ButtonContainer = styled.div<{
  alignItem?: FlexAlignments
  desktopWidth?: string
  isFullWidth?: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing(1)};
  order: ${({ theme }) =>
    theme.patterns.productBlock.components.productButton.order};
  height: ${({ theme }) =>
    theme.patterns.productBlock.components.productButton.height || '100%'};
  align-items: ${(props) =>
    props.alignItem ||
    props.theme.patterns.productBlock.components.productButton.alignItem};

  ${(props) =>
    (props.isFullWidth ||
      props.theme.patterns.productBlock.components.productButton.isFullWidth) &&
    css`
      ${dangerous_mqMax(props.theme.breakpointUtils.map, 'sm')} {
        width: 100%;
      }
    `}

  ${(props) =>
    props.desktopWidth
      ? `width: ${props.desktopWidth};`
      : props.theme.patterns.productBlock.components.productButton
          .desktopWidth &&
        `width: ${props.theme.patterns.productBlock.components.productButton.desktopWidth};`}
`

export const Container = styled.div<ContainerProps>`
  display: flex;
  text-decoration: none;
  height: ${(props) =>
    props.height || props.theme.patterns.productBlock.height}};

  ${(props) =>
    props.directions.map(
      (direction, index) => `
        ${mq(
          props.theme.breakpointUtils.map,
          breakpointUtils.keys[index] as BreakpointKey,
        )} {
          flex-direction: ${direction};
        }
      `,
    )}
  ${(props) => css`
    h3 {
      border: 2px solid transparent;
      margin-left: -2px;
      margin-right: -2px;
      margin-top: -2px;
    }
    &:focus {
      outline: none;
    }
  `}

  ${(props) =>
    mediaQueryRenderer(
      props.useAlternateGap
        ? props.theme.patterns.productBlock.attributes?.alternateGap
        : props.theme.patterns.productBlock.attributes?.gap,
      (value) => `gap: ${spacing(value)};`,
    )}

  ${Title} {
    ${(props) => props.condensed && attributesBottomMarginCondensed}
  }

  ${StyledProductReview} {
    ${(props) => props.condensed && attributesBottomMarginCondensed}
  }

  ${StyledProductQuantity} {
    ${(props) => props.condensed && attributesBottomMarginCondensed}
  }

`
export const StyledProductButton = styled(ProductButton)<{
  buttonStyle?: { isFullWidth?: boolean; desktopWidth?: string }
}>`
  ${(props) =>
    (props.buttonStyle?.isFullWidth ||
      props.theme.patterns.productBlock.components.productButton.isFullWidth) &&
    css`
      ${dangerous_mqMax(props.theme.breakpointUtils.map, 'sm')} {
        &,
        a {
          width: 100%;
        }

        a:active,
        a:disabled,
        a:focus,
        a:hover,
        a:link {
          padding-left: 0;
          padding-right: 0;
        }
      }
    `}

  ${(props) =>
    props.buttonStyle?.desktopWidth
      ? `width: ${props.buttonStyle?.desktopWidth};`
      : props.theme.patterns.productBlock.components.productButton
          .desktopWidth &&
        `width: ${props.theme.patterns.productBlock.components.productButton.desktopWidth};`}
`
