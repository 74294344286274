import { BreakpointArray, TextStyling } from '@thg-commerce/gravity-theme'

export type ChevronIconStyle = {
  viewBox: string
  width: string
  height: string
  right: {
    svgPath: string
  }
  left: {
    svgPath: string
  }
}

export interface BreadCrumbsInterface {
  padding: {
    top: BreakpointArray<number>
    right: BreakpointArray<number>
    bottom: BreakpointArray<number>
    left: BreakpointArray<number>
  }
  chevronIcon: ChevronIconStyle
  list?: {
    overflow: string
    paddingBottom: number
  }
  linkStyling: TextStyling
  activeLinkStyling: TextStyling
  height: string
}

export const theme: BreadCrumbsInterface = {
  padding: {
    top: [0],
    right: [1, 3],
    bottom: [0],
    left: [1, 3],
  },
  chevronIcon: {
    viewBox: '0 0 24 24',
    width: '24px',
    height: '24px',
    right: {
      svgPath: '',
    },
    left: {
      svgPath: '',
    },
  },
  list: {
    overflow: '',
    paddingBottom: 0,
  },
  linkStyling: {
    entry: 'small',
    style: 'default',
    transform: 'none',
    textDecoration: 'underline',
    textColor: '',
  },
  activeLinkStyling: {
    entry: 'small',
    style: 'alternate',
    transform: 'none',
    textDecoration: 'underline',
    textColor: '',
  },
  height: '24px',
}
