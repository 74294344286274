import * as React from 'react'

import { TagStyle } from '@thg-commerce/gravity-elements/Image/Image'
import { PictureProps } from '@thg-commerce/gravity-system'

import {
  FocusStyle,
  ImageContainer,
  StyledHoverImageContainer,
  StyledHoverPicture,
  StyledImageLink,
  StyledPicture,
} from './styles'

export interface ProductBlockPictureProps {
  picture: PictureProps
  hoverPicture?: PictureProps
  url?: string
  onClick?: () => void
  tagStyle?: TagStyle
}

export const ProductBlockPicture = (
  props: FocusStyle<ProductBlockPictureProps>,
) => {
  return (
    <React.Fragment>
      {props.hoverPicture && props.hoverPicture.sources.length > 0 ? (
        <ImageContainer
          as={props.url ? 'a' : undefined}
          href={props.url ? props.url : undefined}
          focusStyle={props.focusStyle}
          onClick={props.onClick}
        >
          <StyledPicture
            style={{ width: '100%' }}
            {...props.picture}
            tagStyle={props.tagStyle}
          />
          <StyledHoverImageContainer>
            <StyledHoverPicture
              {...props.hoverPicture}
              style={{ width: '100%' }}
              lazy={props.hoverPicture?.lazy ?? true}
              tagStyle={props.tagStyle}
            />
          </StyledHoverImageContainer>
        </ImageContainer>
      ) : (
        <StyledImageLink
          role="img"
          aria-label={props.url ? props.picture.alt : undefined}
          href={props.url ? props.url : undefined}
          focusStyle={props.focusStyle}
          onClick={props.onClick}
        >
          <StyledPicture
            style={{ width: '100%' }}
            {...props.picture}
            tagStyle={props.tagStyle}
          />
        </StyledImageLink>
      )}
    </React.Fragment>
  )
}
