import * as React from 'react'
export const SvgEye = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={14} height={8} fill="none" {...props}>
    <path
      d="M7.057 0C3.505 0 .775 3.489.657 3.66c-.178.228-.178.452 0 .68C.775 4.51 3.505 8 7.057 8c3.551 0 6.282-3.489 6.4-3.66.178-.228.178-.452 0-.68C13.339 3.49 10.608 0 7.057 0z"
      fill={props.fill || '#333'}
    />
    <circle cx={7} cy={4} r={3} className="outside-circle" />
    <circle cx={7} cy={4} r={1} className="inside-circle" />
  </svg>
)
export default SvgEye
