export interface ImageSwatchesInterface {
  image: {
    desktop: {
      height: string
      width: string
    }
    mobile: {
      height: string
      width: string
    }
  }
}

export const theme: ImageSwatchesInterface = {
  image: {
    desktop: {
      height: 'unset',
      width: 'unset',
    },
    mobile: {
      height: 'unset',
      width: 'unset',
    },
  },
}
