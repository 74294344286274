import * as React from 'react'
export const SvgPlay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M4 20l16-8L4 4v16zm12.444-8L5.778 17.333V6.667L16.444 12z"
      fill={props.fill || '#333'}
    />
  </svg>
)
export default SvgPlay
