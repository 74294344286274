import * as React from 'react'
export const SvgPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || 'currentColor'}
      d="M11 11L11 6 13 6 13 11 18 11 18 13 13 13 13 18 11 18 11 13 6 13 6 11z"
      transform="translate(-331 -228) translate(140 216) translate(191 12)"
    />
  </svg>
)
export default SvgPlus
