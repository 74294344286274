export const getErrorsList = (errorList, event) => {
  if (
    errorList &&
    Object.prototype.hasOwnProperty.call(errorList, 'length') &&
    errorList.length !== 0
  ) {
    switch (event.type) {
      case 'login':
        return [{ type: 'login_error', label: errorList[0].toLowerCase() }]
      default:
        return []
    }
  }
  if (event.type === 'newsletter_signup') {
    switch (event.subtype) {
      case 'OPT_OUT':
        return [
          {
            type: 'newsletter_signup_error',
            label: 'opt_out',
          },
        ]
      case 'DUPLICATE':
        return [
          {
            type: 'newsletter_signup_error',
            label: 'duplicate',
          },
        ]
      case 'newsletter_sign_up_page':
      case 'email_sign_up_modal':
        return errorList
      default:
        return []
    }
  }
  return []
}
