import { EnterpriseThemeInterface } from '@thg-commerce/enterprise-theme'

import { getUniqueObjectArrayByKey, createFontFaceWithPreload } from '../utils'
import { FontSpec, FontsToLoad } from '../types'

export const getCDNFontsToLoad = (
  theme: EnterpriseThemeInterface,
): FontsToLoad => {
  const uniqueFontFamilies = getUniqueObjectArrayByKey(
    Object.values(theme.typography.urls),
    'family',
  )

  return uniqueFontFamilies.reduce(
    (accumulator: FontsToLoad, fontStyle: FontSpec) => {
      if (fontStyle.kind !== 'cdnfonts#cdn') {
        return accumulator
      }
      const { fontFaces, fontURLsToPreload } = createFontFaceWithPreload(
        fontStyle.family,
        fontStyle.fontUrls,
      )
      accumulator.fontFaces = `${accumulator.fontFaces}${fontFaces}`
      accumulator.fontsToPreload = accumulator.fontsToPreload.concat(
        fontURLsToPreload,
      )
      return accumulator
    },
    { fontFaces: '', fontsToPreload: [] },
  )
}
