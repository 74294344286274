export interface LoyaltyHeroBannerThemeInterface {
  smallImageUrl: string
  largeImageUrl: string
}

export const theme: LoyaltyHeroBannerThemeInterface = {
  smallImageUrl:
    'https://s1.thcdn.com/design-assets/whitelabel/components/heroBanner/loyaltyImageSmall.jpg',
  largeImageUrl:
    'https://s1.thcdn.com/design-assets/whitelabel/components/heroBanner/loyaltyImageLarge.jpg',
}
