import * as React from 'react'
export const SvgCalender = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" {...props}>
    <path
      d="M7.8,14.8h21.3v11.7c0,2-1.6,3.6-3.6,3.6H11.3c-1.9,0-3.5-1.6-3.5-3.5V14.8z M11,9.2h15.2c1.6,0,2.8,1.3,2.8,2.8v1.8H7.8
        v-1.4C7.8,10.7,9.3,9.2,11,9.2z"
    />
    <path d="M14.1,6L14.1,6c0.8,0,1.4,0.6,1.4,1.3v2.3c0,0.7-0.6,1.3-1.3,1.3h0c-0.7,0-1.3-0.6-1.3-1.3V7.3C12.8,6.6,13.4,6,14.1,6z" />
    <path d="M23.6,6L23.6,6c0.8,0,1.4,0.6,1.4,1.3v2.3c0,0.7-0.6,1.3-1.3,1.3h0c-0.7,0-1.3-0.6-1.3-1.3V7.3C22.2,6.6,22.8,6,23.6,6z" />
    <path
      style={{ fill: '#FFFFFF' }}
      fill="#FFFFFF"
      d="M10.2,16.3h3.4v2.6h-3.4L10.2,16.3z M16.7,16.2h3.4v2.6h-3.4V16.2z M23.2,16.2h3.4v2.6h-3.4V16.2z M10.2,21.1
        h3.4v2.6h-3.4L10.2,21.1z M16.7,21h3.4v2.6h-3.4V21z M10.2,25.7h3.4v2.6h-3.4L10.2,25.7z M16.7,25.6h3.4v2.6h-3.4V25.6z M23.2,20.9
        h3.4v2.6h-3.4V20.9z"
    />
    <circle cx="27.5" cy="28.3" r="5.8" />
    <path
      fill="none"
      stroke="#FFFFFF"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="3.2"
      d="M24.6,29.1l1.9,1.7l3.9-3.7"
    />
  </svg>
)
export default SvgCalender
