const xss = require('xss')

export interface OnTagOptions {
  isWhite: boolean
  isClosing: boolean
  position: number
  sourcePosition: number
}

export interface SafeHtmlProps {
  tagsToRemove?: string[]
  removeTagsAndContent?: boolean
  onTag?: (tag: string, html: string, options: OnTagOptions) => string | null
  __dangerouslyAllowedTags?: string[]
  __dangerouslyAllowedAttrs?: {
    [key: string]: string[]
  }
  className?: string
  'data-testid'?: string
  renderedAs?: 'p' | 'h1' | 'h2'
}

export const sanitizeHtml = (html: string, props: SafeHtmlProps) => {
  const allowedTags = [
    ...(props.__dangerouslyAllowedTags || []),
    ...[
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'strike',
      'blockquote',
      'p',
      'a',
      'ul',
      'ol',
      'nl',
      'li',
      'hr',
      'br',
      'div',
      'table',
      'thead',
      'caption',
      'tbody',
      'tr',
      'th',
      'td',
      'strong',
    ],
  ]

  const filteredAllowedTags =
    typeof props.tagsToRemove !== 'undefined'
      ? allowedTags.filter((tag) => {
          return !props.tagsToRemove?.includes(tag)
        })
      : allowedTags

  const reducedAllowedTags = filteredAllowedTags.reduce((acc, tag) => {
    if (tag === 'a') {
      acc[tag] = ['data-testid', 'target', 'href', 'rel']
      return acc
    }

    if (
      props.__dangerouslyAllowedAttrs &&
      Object.keys(props.__dangerouslyAllowedAttrs).includes(tag)
    ) {
      acc[tag] = ['data-testid', ...props.__dangerouslyAllowedAttrs[tag]]
      return acc
    }
    acc[tag] = ['data-testid']
    return acc
  }, {})

  const onTag = (tag: string, html: string, options: OnTagOptions) => {
    let result: string | null = null

    if (props.onTag) {
      result = props.onTag(tag, html, options)
    }

    if (typeof window !== 'undefined') {
      const host = window.location.host

      if (tag === 'a') {
        const isExternalLink = (href: string, host: string) => {
          try {
            const parsedUrl = new URL(href)
            return parsedUrl.host !== '' && parsedUrl.host !== host
          } catch {
            return false
          }
        }

        const match = html.match(/href="(.*)"/)
        if (match && match[1]) {
          if (isExternalLink(match[1], host)) {
            const splitAnchor = html.split('<a ')
            const reformedAnchor = [
              '<a',
              'rel="noopener noreferrer"',
              splitAnchor[1],
            ].join(' ')
            result = reformedAnchor
          }
        }
      }
    }

    return result
  }

  const xssOptions = {
    onTag,
    whiteList: {
      ...reducedAllowedTags,
    },
    stripIgnoreTag: true,
    stripIgnoreTagBody:
      typeof props.removeTagsAndContent === 'undefined'
        ? true
        : props.removeTagsAndContent,
  }

  return xss(html, xssOptions) === '[removed]'
    ? ''
    : xss(html, xssOptions) === '[/removed]'
    ? ''
    : xss(html, xssOptions)
}
