import {
  CategoryType,
  Experiment,
  PageAttribute,
} from '../data_layer/data_layer'
import { MetaSiteObject } from '../perf'

export const getPageAttribute = (
  meta: MetaSiteObject,
  experiments: Experiment[] | {}[],
  siteCode: string,
  isMobile: boolean,
  categoryType: CategoryType,
): PageAttribute => ({
  experiments,
  categoryType,
  currency: meta.currency,
  locale: meta.siteDefaultLocale,
  mobile: isMobile ? 'yes' : 'no',
  pageTemplatePrefix: '',
  site: siteCode,
  subsite: meta.subsiteCode,
})
