import * as React from 'react'
export const SvgCircularEmail = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <path
      d="M40 19.995c0 11.043-8.954 19.995-20 19.995S0 31.038 0 19.995C0 8.952 8.954 0 20 0s20 8.952 20 19.995z"
      fill={props.fill || '#979797'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 12.997v13.997h-.96a1.025 1.025 0 01-.08 0H10V12.997h20zm-2 10.584l-4.147-4.146L28 16.089v7.492zm-1.415 1.413h-13.17l4.26-4.26 2.271 1.853 2.342-1.89 4.297 4.297zm-12.778-9.997l6.146 5.014 6.216-5.014H13.807zM12 16.103v7.478l4.118-4.118L12 16.103z"
      fill={props.fill || '#fff'}
    />
  </svg>
)
export default SvgCircularEmail
