import * as styledComponents from 'styled-components'

import { PatternThemeInterface } from '@thg-commerce/gravity-patterns'
import {
  ThemeInterface as BaseThemeInterface,
  margin,
  spacing,
  padding,
  mq,
  Text,
  ElevationLevel,
  zIndex,
  ZIndexLevel,
  opacity,
  paddingMixin,
} from '@thg-commerce/gravity-theme'
import { ElementThemeInterface } from '@thg-commerce/gravity-elements'
import { WidgetThemeInterface } from '@thg-commerce/enterprise-widgets/theme'
import { ComponentThemeInterface } from '@thg-commerce/gravity-system'

export type EnterpriseThemeInterface = PatternThemeInterface &
  WidgetThemeInterface &
  ElementThemeInterface &
  ComponentThemeInterface &
  BaseThemeInterface

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  /**
   * @deprecated Use the useTheme hook exported from enterprise-core
   **/
  withTheme,
  useTheme,
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<
  EnterpriseThemeInterface
>

export {
  useTheme,
  styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
  margin,
  spacing,
  padding,
  mq,
  Text,
  ElevationLevel,
  zIndex,
  ZIndexLevel,
  opacity,
  paddingMixin,
}
