import * as React from 'react'
export const SvgAccountReturns = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={25} height={25} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.882 4.5h11.236L20.5 9.264V21.5h-16V9.264L6.882 4.5zm11 4l-1.001-2h-3.38v2h4.38zm.618 2h-12v9h12v-9zm-11.382-2l1-2H11.5v2H7.118zM16.5 14v2h-5.165v1.503L8 15l3.335-2.501V14H16.5z"
      fill={props.fill || '#333'}
    />
  </svg>
)
export default SvgAccountReturns
