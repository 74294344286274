import * as React from 'react'
export const SvgRightAligned = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || '#333'}
      d="M21 11h-9v2h9v-2zm0-5H3v2h18V6zm0 10H10v2h11v-2z"
    />
  </svg>
)
export default SvgRightAligned
