import * as React from 'react'

import { CombinedThemeInterface } from '@thg-commerce/gravity-patterns'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing, withTheme } from '@thg-commerce/gravity-theme'

export const LogoPictureContainer = styled.picture`
  height: ${(props) => props.theme.patterns.header.headerLogo.mobile.height};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    height: ${(props) => props.theme.patterns.header.headerLogo.desktop.height};
  }

  svg,
  img {
    height: 100%;
    width: 100%;
    overflow: inherit;

    ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
      width: auto;
    }
  }
`

export const LogoLink = styled.a`
  display: flex;
  width: 100%;
  justify-content: ${(props) =>
    props.theme.patterns.header.headerLogo.mobile.alignment};
  height: ${(props) =>
    `calc(${props.theme.patterns.header.headerLogo.mobile.height} + 4px)`};
  border: 2px solid transparent;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    justify-content: ${(props) =>
      props.theme.patterns.header.headerLogo.desktop.alignment};
    height: ${(props) =>
      `calc(${props.theme.patterns.header.headerLogo.desktop.height} + 4px)`};
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const LogoBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${spacing(1.5)};
  height: ${(props) => props.theme.patterns.header.headerLogo.mobile.height};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    height: ${(props) => props.theme.patterns.header.headerLogo.desktop.height};
    margin: ${(props) =>
      props.theme.patterns.header.headerLogo.desktop.margin
        ? `${props.theme.patterns.header.headerLogo.desktop.margin.top} ${props.theme.patterns.header.headerLogo.desktop.margin.right} ${props.theme.patterns.header.headerLogo.desktop.margin.bottom} ${props.theme.patterns.header.headerLogo.desktop.margin.left}`
        : `${props.theme.patterns.header.headerLogo.desktop.verticalMargin} 0` ||
          '0'};
  }
`

interface LogoProps {
  logoAriaLabel: string
  logoLink: string
  logoOnClick?: () => void
}

export const Logo = withTheme(
  (props: LogoProps & { theme: CombinedThemeInterface }) => {
    const { logoAriaLabel, logoLink } = props
    const { logoUri, logoAlternateUri, logoSVG } = props.theme.logo

    const mobileUseAlternateLogo =
      props.theme.patterns.header.headerLogo.mobile.useAlternateLogo

    const desktopLogo = logoUri || logoAlternateUri
    const mobileLogo =
      logoUri && !mobileUseAlternateLogo ? logoUri : logoAlternateUri

    const logoBreakpoint = mq(props.theme.breakpointUtils.map, 'lg', true)

    return (
      <LogoBox data-testid="logo-box">
        <LogoLink
          aria-label={logoAriaLabel}
          data-testid="logo-link"
          onClick={props.logoOnClick && props.logoOnClick}
          href={logoLink}
        >
          {!logoUri && !logoAlternateUri ? (
            <div
              dangerouslySetInnerHTML={{
                __html: logoSVG || '',
              }}
            />
          ) : (
            <LogoPictureContainer>
              <source media={logoBreakpoint} srcSet={desktopLogo} />
              <img
                style={{
                  aspectRatio:
                    props.theme.patterns.header.headerLogo.mobile.aspectRatio ||
                    '3/1 auto',
                }}
                src={mobileLogo}
                alt="logo"
              />
            </LogoPictureContainer>
          )}
        </LogoLink>
      </LogoBox>
    )
  },
)
