import { Property } from 'csstype'
import { css } from 'styled-components'
import { mq } from '@thg-commerce/gravity-theme'
import {
  TextStyle,
  TextEntry,
  TextTransform as TransformType,
  TextDecoration,
  DeviceSize,
  FontItem,
} from '../'

export interface FontStyle {
  fontFamily: Property.FontFamily
  fontWeight: Property.FontWeight
  style: string
}

export interface TypographyScaleEntry {
  smallDevice: FontItem
  largeDevice: FontItem
  defaultFontStyle: FontStyle
  alternateFontStyle: FontStyle
  textTransform: Property.TextTransform
  textDecoration: Property.TextDecoration<string>
}

export interface FontUrl {
  family: string
  fontUrls: { weight: string; url: string; style: string }[]
  kind: string
}

export interface UrlCollection {
  xlarge2: FontUrl
  xlarge1: FontUrl
  large2: FontUrl
  large1: FontUrl
  medium1: FontUrl
  bodyText: FontUrl
  small: FontUrl
  xsmall: FontUrl
}

export interface TypographyInterface {
  urls: UrlCollection
  smallDeviceScaleFactor: string
  largeDeviceScaleFactor: string
  xlarge2: TypographyScaleEntry
  xlarge1: TypographyScaleEntry
  large2: TypographyScaleEntry
  large1: TypographyScaleEntry
  medium1: TypographyScaleEntry
  bodyText: TypographyScaleEntry
  small: TypographyScaleEntry
  xsmall: TypographyScaleEntry
}

export interface TextStyling {
  entry: TextEntry
  style: TextStyle
  transform: TransformType
  textDecoration: TextDecoration
  textColor: Property.Color
}

export const TextScaled = (
  entry: TextEntry,
  textStyle: TextStyle,
  deviceSize: DeviceSize,
  t: TypographyInterface,
) => {
  const textEntry = t[entry]

  const fontStyle =
    textStyle === 'default'
      ? textEntry.defaultFontStyle
      : textEntry.alternateFontStyle

  const ammendedFontStyle = {
    fontWeight: fontStyle.fontWeight,
    fontStyle: fontStyle.style,
    fontFamily: fontStyle.fontFamily.includes('sans-serif')
      ? `${fontStyle.fontFamily}`
      : `${fontStyle.fontFamily}, sans-serif`,
  }

  return css({
    ...ammendedFontStyle,
    ...textEntry[deviceSize],
  })
}

const TextTransform = (entry: TextEntry, t: TypographyInterface) => {
  const textEntry = t[entry]
  const textTransform = textEntry.textTransform !== 'none' && {
    textTransform: textEntry.textTransform,
  }
  return css({ ...textTransform })
}

// @ts-ignore
export const Text = (
  entry: TextEntry,
  textStyle: TextStyle,
  scaledText: boolean = true,
) => css`
  color: ${(props) => props.theme.colors.palette.greys.darker};
  ${({ theme }) => TextTransform(entry, theme.typography)}
  ${({ theme }) =>
    TextScaled(
      entry,
      textStyle,
      scaledText ? 'smallDevice' : 'largeDevice',
      theme.typography,
    )}
  ${({ theme }) => mq(theme.breakpointUtils.map, 'sm')} {
    ${({ theme }) =>
      scaledText &&
      TextScaled(entry, textStyle, 'largeDevice', theme.typography)}
  }
`

export const typography: TypographyInterface = {
  urls: {
    xlarge2: {
      family: '',
      fontUrls: [{ url: '', weight: '', style: '' }],

      kind: '',
    },
    xlarge1: {
      family: '',
      fontUrls: [{ url: '', weight: '', style: '' }],

      kind: '',
    },
    large2: {
      family: '',
      fontUrls: [{ url: '', weight: '', style: '' }],

      kind: '',
    },
    large1: {
      family: '',
      fontUrls: [{ url: '', weight: '', style: '' }],

      kind: '',
    },
    medium1: {
      family: '',
      fontUrls: [{ url: '', weight: '', style: '' }],

      kind: '',
    },
    bodyText: {
      family: '',
      fontUrls: [{ url: '', weight: '', style: '' }],

      kind: '',
    },
    small: {
      family: '',
      fontUrls: [{ url: '', weight: '', style: '' }],

      kind: '',
    },
    xsmall: {
      family: '',
      fontUrls: [{ url: '', weight: '', style: '' }],

      kind: '',
    },
  },
  largeDeviceScaleFactor: '',
  smallDeviceScaleFactor: '',
  xlarge2: {
    smallDevice: {
      fontSize: '32px',
      lineHeight: '40px',
      letterSpacing: '-1px',
    },
    largeDevice: {
      fontSize: '45px',
      lineHeight: '56px',
      letterSpacing: '-1px',
    },
    defaultFontStyle: {
      fontFamily: 'Avenir-Book, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    alternateFontStyle: {
      fontFamily: 'Avenir-Heavy, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    textTransform: 'none',
    textDecoration: 'none',
  },
  xlarge1: {
    smallDevice: {
      fontSize: '28px',
      lineHeight: '36px',
      letterSpacing: '-0.7px',
    },
    largeDevice: {
      fontSize: '37px',
      lineHeight: '48px',
      letterSpacing: '-0.7px',
    },
    defaultFontStyle: {
      fontFamily: 'Avenir-Book, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    alternateFontStyle: {
      fontFamily: 'Avenir-Heavy, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    textTransform: 'none',
    textDecoration: 'none',
  },
  large2: {
    smallDevice: {
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.5px',
    },
    largeDevice: {
      fontSize: '30px',
      lineHeight: '40px',
      letterSpacing: '-0.5px',
    },
    defaultFontStyle: {
      fontFamily: 'Avenir-Book, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    alternateFontStyle: {
      fontFamily: 'Avenir-Heavy, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    textTransform: 'none',
    textDecoration: 'none',
  },
  large1: {
    smallDevice: {
      fontSize: '21px',
      lineHeight: '28px',
      letterSpacing: '-0.5px',
    },
    largeDevice: {
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.5px',
    },
    defaultFontStyle: {
      fontFamily: 'Avenir-Book, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    alternateFontStyle: {
      fontFamily: 'Avenir-Heavy, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    textTransform: 'none',
    textDecoration: 'none',
  },
  medium1: {
    smallDevice: {
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0px',
    },
    largeDevice: {
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0px',
    },
    defaultFontStyle: {
      fontFamily: 'Avenir-Book, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    alternateFontStyle: {
      fontFamily: 'Avenir-Heavy, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    textTransform: 'none',
    textDecoration: 'none',
  },
  bodyText: {
    smallDevice: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.2px',
    },
    largeDevice: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.2px',
    },
    defaultFontStyle: {
      fontFamily: 'Avenir-Book, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    alternateFontStyle: {
      fontFamily: 'Avenir-Heavy, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    textTransform: 'none',
    textDecoration: 'none',
  },
  small: {
    smallDevice: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.2px',
    },
    largeDevice: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.2px',
    },
    defaultFontStyle: {
      fontFamily: 'Avenir-Book, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    alternateFontStyle: {
      fontFamily: 'Avenir-Heavy, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    textTransform: 'none',
    textDecoration: 'none',
  },
  xsmall: {
    smallDevice: {
      fontSize: '13px',
      lineHeight: '20px',
      letterSpacing: '0.3px',
    },
    largeDevice: {
      fontSize: '13px',
      lineHeight: '20px',
      letterSpacing: '0.3px',
    },
    defaultFontStyle: {
      fontFamily: 'Avenir-Book, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    alternateFontStyle: {
      fontFamily: 'Avenir-Heavy, Lato, sans-serif',
      fontWeight: 'normal',
      style: 'normal',
    },
    textTransform: 'none',
    textDecoration: 'none',
  },
}
