import { Property } from 'csstype'

/**
 *
 * @param color_1 <Property.Color> first color to mix like #FFCC00
 * @param color_2 <Property.Color> first color to mix like #CCFF00
 * @param weight <number> qty from 0 to 100 for the mix. Default is 50
 */
const mix = (
  color_1: Property.Color,
  color_2: Property.Color,
  weight: number = 50,
) => {
  function d2h(d: any) {
    return d.toString(16)
  } // convert a decimal value to hex
  function h2d(h: any) {
    return parseInt(h, 16)
  } // convert a hex value to decimal

  let color = '#'

  for (let i = 1; i <= 6; i += 2) {
    // loop through each of the 3 hex pairs—red, green, and blue
    const v1 = h2d(color_1.substr(i, 2))
    const v2 = h2d(color_2.substr(i, 2))

    // combine the current pairs from each source color, according to the specified weight
    let val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)))

    while (val.length < 2) {
      val = `0${val}`
    } // prepend a '0' if val results in a single digit

    color += val // concatenate val to our new color string
  }

  return color
}

/**
 *
 * @param color <Property.Color> The color you want to tint
 * @param weight <number> qty from 0 to 100 for the tint
 */
const tint = (color: Property.Color, amount: number) => {
  return mix('#FFFFFF', color, Math.abs(amount))
}

/**
 *
 * @param color <Property.Color> The color you want to shade
 * @param weight <number> qty from 0 to 100 for the shade
 */
const shade = (color: Property.Color, amount: number) => {
  return mix('#000000', color, Math.abs(amount))
}

/**
 *
 * @param color <Property.Color> The color you want to give opacity to
 * @param opacity <number> 0 - 1 the amount of opacity
 */
const opacity = (color: Property.Color, opacity: number) => {
  if (opacity < 0 || opacity > 1) return color
  return `${color}${Math.round(+opacity * 255)
    .toString(16)
    .padStart(2, '0')}`
}

const gradient = (
  direction: string,
  stops: { color: Property.Color; stop: number }[],
) => {
  const gradientStops = stops.reduce((stops, value) => {
    return stops.concat(', ', value.color, ' ', value.stop.toString(), '%')
  }, '')

  return `linear-gradient(${direction}${gradientStops})`
}

export { tint, shade, mix, opacity, gradient }
