import * as React from 'react'
export const SvgAmericanExpress = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="60px"
    height="40px"
    viewBox="0 0 60 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>AMEX</title>
    <g id="AMEX" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="payment-logo/card-border" fill="#FFFFFF" stroke="#DDDDDD">
        <rect
          id="Placehodler"
          x="0.5"
          y="0.5"
          width="59"
          height="39"
          rx="4"
        ></rect>
      </g>
      <g id="Group-3" transform="translate(15.000000, 5.000000)">
        <polygon
          id="Fill-1"
          fill="#FFFFFF"
          points="0 0 0 14.9532 0 30 14.9994 30 30 30 30 0"
        ></polygon>
        <path
          d="M0,0 L0,30 L30,30 L30,25.26 L25.86,25.26 L24.33,23.4702 L22.74,25.26 L10.98,25.26 L10.98,15.7302 L7.0998,15.7302 L11.9502,4.7502 L16.6698,4.7502 L17.8098,7.2402 L17.8098,4.7502 L23.67,4.7502 L24.6498,7.3602 L25.6098,4.7502 L30,4.7502 L30,0 L0,0 Z M26.61,6.3 L25.0698,10.3602 L24.66,11.4606 L24.24,10.3602 L22.6902,6.3 L19.3002,6.3 L19.3002,14.3004 L21.3402,14.3004 L21.3402,9.0906 L21.33,8.04 L21.7302,9.0906 L23.6802,14.3004 L25.62,14.3004 L27.5802,9.0906 L27.96,8.0502 L27.96,14.3004 L30,14.3004 L30,6.3 L26.61,6.3 Z M13.05,6.3 L9.51,14.3004 L11.8296,14.3004 L12.45,12.7602 L16.3302,12.7602 L16.95,14.3004 L19.3002,14.3004 L15.78,6.3 L13.05,6.3 Z M13.9896,9.0102 L14.3898,8.0106 L14.79,9.0102 L15.6198,11.0298 L13.1598,11.0298 L13.9896,9.0102 Z M26.7702,15.7404 L24.36,18.33 L21.96,15.7404 L19.3002,15.7404 L12.6198,15.7404 L12.6198,23.7102 L19.3002,23.7102 L19.3002,21.9804 L14.6598,21.9804 L14.6598,20.5902 L19.2102,20.5902 L19.2102,18.8706 L14.6598,18.8706 L14.6598,17.4702 L19.3002,17.4702 L19.3002,15.7404 L23.0298,19.74 L19.3002,23.7102 L21.8802,23.7102 L24.3102,21.1104 L26.73,23.7102 L29.3796,23.7102 L25.6398,19.71 L29.3796,15.7404 L26.7702,15.7404 Z M27.7398,19.7004 L30,22.0002 L30,17.43 L27.7398,19.7004 Z"
          id="Fill-2"
          fill="#006FCF"
        ></path>
      </g>
    </g>
  </svg>
)
export default SvgAmericanExpress
