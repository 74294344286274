export const SUPPORTED_FEATURES = {
  /** Platform features (GraphQL/Horizon) */
  GUEST_CHECKOUT: 'GUEST_CHECKOUT',
  ACCOUNT_LINKING: 'ACCOUNT_LINKING',
  FORM_INFO: 'FORM_INFO',
  SITE_PROPERTIES: 'SITE_PROPERTIES',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  SUBSCRIPTIONS_CANCEL_ONLINE: 'SUBSCRIPTIONS_CANCEL_ONLINE',
  SUBSCRIPTIONS_DELAY: 'SUBSCRIPTIONS_DELAY',
  FRIENDS_REFERRAL_SCHEME: 'FRIENDS_REFERRAL_SCHEME',
  CROSS_SITE_REFERRALS: 'CROSS_SITE_REFERRALS',
  PROFILES: 'PROFILES',
  SURVEYS: 'SURVEYS',
  REWARD_POINTS: 'REWARD_POINTS',
  REWARD_CARDS: 'REWARD_CARDS',
  WISHLIST: 'WISHLIST',
  POP_IN_A_BOX_TRACKER: 'POP_IN_A_BOX_TRACKER',
  ACCOUNT_CREDIT: 'CREDIT',
  HONDA_MARKETING: 'HONDA_MARKETING',
  CONCESSIONS: 'CONCESSIONS',
  /** Config based features */
  INTERNATIONAL_OVERLAY: 'hasInternationalOverlay',
  REGISTRATION_HIDDEN_ON_LOGIN_PAGE: 'disableRegistration',
  HAS_REVERSED_REGISTER: 'hasReversedRegister',
  GUESTCHECKOUT_LAYOUT: 'useGuestCheckoutLayout',
  RESPONSIVE_OPT_IN: 'dynamicNewsletterSubscription',
  ENABLE_COOKIE_MODAL: 'enableCookieMessage',
  ENABLE_GRAVITY_COOKIE_MODAL: 'enableGravityCookieMessage',
  GUEST_CHECKOUT_V3: 'enableOptInModalOnGuestCheckout',
  TRANSACTIONAL_ELEMENTS_HIDDEN: 'hideAccountTransactionalElements',
  SHOW_HELP_CENTRE_ON_ACCOUNT_HOME: 'showHelpCentreOnAccountHome',
  HELP_CENTRE_IMPROVEMENTS_ON_ACCOUNT_HOME:
    'helpCentreImprovementsOnAccountHome',
  ENABLE_ACCOUNT_ORDER_SUMMARY_DELIVERY_RANGE: 'showOrderDeliveryDateRange',
  ENABLE_ACCOUNT_ORDER_SUMMARY_IMAGES: 'showOrderSummaryImages',
  ENABLE_WISHLISTS: 'enableWishlists',
  HAS_REFERRALS: 'hasReferrals',
  HAS_MEMBER_PERKS: 'hasMemberPerks',
  ACCOUNT_HOME_SOCIAL_MEDIA_HIDDEN: 'hideSocialMediaOnAccountHome',
  APPLE_BUSINESS_CHAT: 'hasAppleBusinessChat',
  ADDRESS_BOOK: 'hasAddressBook',
  HAS_BEAUTY_PROFILE: 'hasBeautyProfile',
  HAS_MULTI_PROFILES: 'hasMultiProfiles',
  FRICTIONLESS_CHECKOUT: 'useFrictionlessCheckout',
  NEW_ACCOUNT_HOME: 'enableNewAccountHome',
  PREVIEW_QUICKBUY: 'previewQuickbuy',
  LOYALTY: 'hasLoyaltyEnabled',
  LOYALTY_REDEEMABLE_POINTS: 'hasLoyaltyRedeemablePoints',
  NEW_HEADER: 'newHeader',
  DEFAULT_ADDRESSES: 'DEFAULT_ADDRESSES',
  MY_RETURNS: 'returns',
  ORDER_RETURNS: 'ORDER_RETURNS',
}

export const AVAILABLE_FEATURES = {
  GUEST_CHECKOUT: 'GUEST_CHECKOUT',
  FORM_INFO: 'FORM_INFO',
  SITE_PROPERTIES: 'SITE_PROPERTIES',
  WISHLIST: 'WISHLIST',
  ENABLE_WISHLISTS: 'enableWishlists',
  PROFILES: 'PROFILES',
  FRIENDS_REFERRAL_SCHEME: 'FRIENDS_REFERRAL_SCHEME',
  ACCOUNT_LINKING: 'ACCOUNT_LINKING',
  ACCOUNT_CREDIT: 'CREDIT',
  INTERNATIONAL_OVERLAY: 'hasInternationalOverlay',
  RESPONSIVE_OPT_IN: 'dynamicNewsletterSubscription',
  ENABLE_COOKIE_MODAL: 'enableCookieMessage',
  HAS_MEMBER_PERKS: 'hasMemberPerks',
  HAS_REFERRALS: 'hasReferrals',
  // ENABLE_GRAVITY_COOKIE_MODAL: 'enableGravityCookieMessage', disabled until we A/B test the new cookie banner
  GUEST_CHECKOUT_V3: 'enableOptInModalOnGuestCheckout',
  ENABLE_ACCOUNT_ORDER_SUMMARY_IMAGES: 'showOrderSummaryImages',
  ENABLE_ACCOUNT_ORDER_SUMMARY_DELIVERY_RANGE: 'showOrderDeliveryDateRange',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  APPLE_BUSINESS_CHAT: 'hasAppleBusinessChat',
  ADDRESS_BOOK: 'hasAddressBook',
  HAS_MULTI_PROFILES: 'hasMultiProfiles',
  GUESTCHECKOUT_LAYOUT: 'useGuestCheckoutLayout',
  FRICTIONLESS_CHECKOUT: 'useFrictionlessCheckout',
  PREVIEW_QUICKBUY: 'previewQuickbuy',
  LOYALTY: 'hasLoyaltyEnabled',
  LOYALTY_REDEEMABLE_POINTS: 'hasLoyaltyRedeemablePoints',
}
