import * as React from 'react'
export const SvgHome = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fillRule="evenodd"
      d="M19 12v9h-5v-5a2 2 0 10-4 0v5H5v-9H2L12 2l10 10h-3z"
    />
  </svg>
)
export default SvgHome
