import * as React from 'react'
import Head from 'next/head'

import { useSiteConfig } from '../ConfigurationLoader'

const tagNames = {
  AlexaVerificationCode: {
    name: 'alexaVerifyID',
  },
  GoogleWebmasterVerificationCode: {
    name: 'google-site-verification',
    id: 'GoogleWebmasterVerificationCode',
  },

  YouTubeVerificationCode: {
    name: 'google-site-verification',
    id: 'YouTubeVerificationCode',
  },
  NaverVerificationCode: {
    name: 'naver-site-verification',
  },
  BingVerificationCode: {
    name: 'msvalidate.01',
    id: 'msvalidate-01',
  },
  ShenmaVerificationCode: {
    name: 'shenma-site-verification',
  },
  PinterestVerificationCode: {
    name: 'p:domain_verify',
    id: 'p-domain-verify',
  },
  BaiduVerificationId: {
    name: 'baidu-site-verification',
  },
  YandexVerificationId: {
    name: 'yandex-verification',
  },
}

export const VerificationCodeLoader = () => {
  const verificationCodes = useSiteConfig()
  const metaTags = React.useMemo(
    () =>
      Object.keys(verificationCodes).reduce((metaTagsArray, val, index) => {
        if (verificationCodes.hasOwnProperty(val) && tagNames[val]) {
          metaTagsArray[index] = tagNames[val].id ? (
            <meta
              name={tagNames[val].name}
              key={tagNames[val].name}
              id={tagNames[val].id}
              content={verificationCodes[val]}
            />
          ) : (
            <meta
              name={tagNames[val].name}
              key={tagNames[val].name}
              content={verificationCodes[val]}
            />
          )
        }
        return metaTagsArray
      }, [] as React.ReactElement<HTMLMetaElement>[]),
    [verificationCodes],
  )
  return <Head>{metaTags}</Head>
}
