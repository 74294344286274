import { SiteDefinitionInterface } from '@thg-commerce/enterprise-config'

import { SiteConfigInterface } from '../ConfigurationLoader/types'
import { facebookMetaTags } from './FacebookMetaTag'
import { canonicalLinkTag } from './CanonicalLinkTag'
import { alternateLinkTags } from './AlternateLinkTag'
import { renderer } from '../Head'

interface PageTypes {
  pageType: string
  origin: string
  path: string
}

export const MetaTagGenerator = (
  { origin, path, pageType }: PageTypes,
  siteDefinition: SiteDefinitionInterface,
  siteConfig: SiteConfigInterface,
  subsiteDomains: { [domain: string]: string },
  hreflangs: { [subsite: string]: string },
) => {
  const metaTags = [
    ...facebookMetaTags(
      {
        pageType,
        origin,
        path,
      },
      siteDefinition,
      siteConfig,
    ),
  ]

  const linkTags = [
    ...alternateLinkTags(
      {
        origin,
        path,
      },
      subsiteDomains,
      hreflangs,
    ),
  ]

  const canonicalTag = canonicalLinkTag({
    origin,
    path,
    pageType,
    selfReferencing: siteConfig.enableSelfReferencingCanonicalTag || false,
  })

  if (pageType === 'product' || pageType === 'reviews') {
    return renderer([...metaTags, canonicalTag])
  }

  if (pageType === 'list' || pageType === 'home') {
    return renderer([...metaTags, ...linkTags, canonicalTag])
  }

  return renderer([...metaTags, ...linkTags])
}
