import { mq, spacing, Text } from '@thg-commerce/gravity-theme'
import { styled, css } from '@thg-commerce/gravity-elements/theme'
import { Image } from '../Image'
import { visuallyHiddenMixin } from '../VisuallyHidden'
import { ImageSwatchElement } from './ImageSwatchElement'
import { Label, LabelText } from '../FormItem/Label'

export interface StyledImageSwatchProps {
  imageOptionsLength: number
  showAllImages: boolean
  hideSwatches?: boolean
}

export const StyledSpan = styled.span<StyledImageSwatchProps>`
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    &&::after {
      ${(props) =>
        !props.showAllImages && `content: " ${props.imageOptionsLength - 6} "`}
    }
  }

  ::after {
    ${(props) =>
      !props.showAllImages && `content: " ${props.imageOptionsLength - 4} "`}
  }
`
export const StyledLabelText = styled(LabelText)<{
  required?: boolean
}>`
  ${(props) => !props.required && `padding-left: ${spacing(1)};`}
`

export const StyledImageSwatchElement = styled(ImageSwatchElement)``

const ButtonStyle = css`
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  justify-content: center;
  align-content: center;
`

export const StyledShowMoreButton = styled.button<StyledImageSwatchProps>`
  ${Text('small', 'default')}
  border: 1px solid ${(props) => props.theme.colors.palette.greys.darker};
  max-width: 55px;
  max-height: 76px;
  min-height: 70px;
  height: ${(props) => props.theme.elements.imageSwatches.image.mobile.height};
  width: ${(props) => props.theme.elements.imageSwatches.image.mobile.width};
  cursor: pointer;
  color: ${(props) => props.theme.colors.palette.greys.darker};
  outline-offset: 30px;
  transition: 0.1s all ease;

  &:focus {
    outline: 2px solid ${(props) => props.theme.colors.palette.brand.base};
    outline-offset: 3px;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-bottom: ${spacing(1.125)};
    max-width: 65px;
    max-height: 95px;
    height: ${(props) =>
      props.theme.elements.imageSwatches.image.desktop.height};
    width: ${(props) => props.theme.elements.imageSwatches.image.desktop.width};
    &:hover {
      cursor: pointer;
      outline: 2px solid ${(props) => props.theme.colors.palette.brand.base};
      outline-offset: 3px;
    }
    ${(props) =>
      props.imageOptionsLength < 7 ? `display: none` : `${ButtonStyle}`};
  }

  ${(props) =>
    props.imageOptionsLength < 5 ? `display: none` : `${ButtonStyle}`};
`

export const StyledImageSwatchContainer = styled.div<StyledImageSwatchProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${StyledImageSwatchElement}:nth-of-type(n + 5),
  button:nth-of-type(n + 5):not(:last-child) {
    display: none;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${StyledImageSwatchElement}:nth-of-type(n + 5),
    button:nth-of-type(n + 5):not(:last-child) {
      display: block;
    }

    ${StyledImageSwatchElement}:nth-of-type(n + 7),
    button:nth-of-type(n + 7):not(:last-child) {
      display: none;
    }
  }
  ${(props) =>
    props.showAllImages &&
    `${StyledImageSwatchElement}:nth-of-type(n), 
    button:nth-of-type(n + 7):not(:last-child) {
      display: block;
    }`}

  ${(props) =>
    props.hideSwatches &&
    `
      display: none;
    `}
`
export const StyledImageLabel = styled.label<{
  selected: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${spacing(1.125)} ${spacing(1.125)} 0;
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  outline-offset: 30px;

  div > img {
    object-fit: cover;
  }

  &:hover {
    cursor: pointer;
    outline: 2px solid
      ${(props) => !props.selected && props.theme.colors.palette.brand.base};
    outline-offset: 3px;
  }

  ${(props) =>
    props.selected &&
    `
     outline: 2px solid ${props.theme.colors.palette.brand.base};
     outline-offset: 3px;
     `}
`

export const HiddenButton = styled.button<{ selected: boolean }>`
  ${visuallyHiddenMixin}

  &:focus + ${StyledImageLabel} {
    outline: 2px solid ${(props) => props.theme.colors.palette.brand.base};
    outline-offset: 3px;
  }
`

export const StyledImage = styled(Image)`
  max-width: 55px;
  max-height: 76px;
  min-height: 70px;
  height: ${(props) => props.theme.elements.imageSwatches.image.mobile.height};
  width: ${(props) => props.theme.elements.imageSwatches.image.mobile.width};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    max-width: 65px;
    max-height: 95px;
    height: ${(props) =>
      props.theme.elements.imageSwatches.image.desktop.height};
    width: ${(props) => props.theme.elements.imageSwatches.image.desktop.width};
  }
`

export const LabelWrapper = styled(Label)<{ selectedValue: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.selectedValue ? 'row' : 'column')};
`
