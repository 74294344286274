import { Property } from 'csstype'
import { Margin } from '@thg-commerce/gravity-theme/margin'
import { Padding } from '@thg-commerce/gravity-theme/padding'
import { TextStyling } from '@thg-commerce/gravity-theme/typography/typography'
import { theme as BaseTheme } from '@thg-commerce/gravity-theme'

export interface StoreSearchInterface {
  title: {
    font: TextStyling
    margin: Margin
  }
  subtitle: {
    font: TextStyling
    margin: Margin
  }
  searchInput: {
    margin: Margin
    width: Property.Width
    borderRadius: Property.BorderRadius
  }
  backgroundColor: Property.Color
  padding: Padding
  storeMap: {
    defaultZoom: number
    selectedPinZoom: number
    defaultPinColor: {
      fillColor: Property.Color
      textColor: Property.Color
    }
    hoverPinColor: {
      fillColor: Property.Color
      textColor: Property.Color
    }
    selectedPinColor: {
      fillColor: Property.Color
      textColor: Property.Color
    }
  }
}

export const theme: StoreSearchInterface = {
  title: {
    font: {
      entry: 'large1',
      style: 'default',
      transform: 'none',
      textDecoration: 'none',
      textColor: '#333333',
    },
    margin: {
      top: 0,
      bottom: 2,
      left: 0,
      right: 0,
    },
  },
  subtitle: {
    font: {
      entry: 'bodyText',
      style: 'default',
      transform: 'none',
      textDecoration: 'none',
      textColor: '#333333',
    },
    margin: {
      top: 0,
      bottom: 3,
      left: 0,
      right: 0,
    },
  },
  searchInput: {
    margin: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 2,
    },
    width: '340px',
    borderRadius: 0,
  },
  backgroundColor: '#F7F7F7',
  padding: {
    top: 4,
    bottom: 4,
    left: 4,
    right: 4,
  },
  storeMap: {
    defaultZoom: 7,
    selectedPinZoom: 10,
    defaultPinColor: {
      fillColor: BaseTheme.colors.palette.greys.darker,
      textColor: BaseTheme.colors.palette.greys.white,
    },
    hoverPinColor: {
      fillColor: BaseTheme.colors.palette.greys.white,
      textColor: BaseTheme.colors.palette.greys.darker,
    },
    selectedPinColor: {
      fillColor: BaseTheme.colors.palette.greys.white,
      textColor: BaseTheme.colors.palette.greys.darker,
    },
  },
}
