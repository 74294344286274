import * as React from 'react'
import { useEffect, useState } from 'react'
import MessageArea from './MessageArea'

interface AnnouncerProps {
  assertiveMessage: string
  politeMessage: string
}

export const Announcer = (props: AnnouncerProps) => {
  const [assertiveMessage1, setAssertiveMessage1] = useState('')
  const [assertiveMessage2, setAssertiveMessage2] = useState('')
  const [politeMessage1, setPoliteMessage1] = useState('')
  const [politeMessage2, setPoliteMessage2] = useState('')

  const [useAlternativeAssertive, setUseAlternativeAssertive] = useState(false)
  const [useAlternativePolite, setUseAlternativePolite] = useState(false)

  useEffect(() => {
    const { assertiveMessage } = props

    if (assertiveMessage && assertiveMessage !== '') {
      setAssertiveMessage1(useAlternativeAssertive ? '' : assertiveMessage)
      setAssertiveMessage2(useAlternativeAssertive ? assertiveMessage : '')
      setUseAlternativeAssertive(!useAlternativeAssertive)
    }
  }, [props.assertiveMessage])

  useEffect(() => {
    const { politeMessage } = props

    if (politeMessage && politeMessage !== '') {
      setPoliteMessage1(useAlternativePolite ? '' : politeMessage)
      setPoliteMessage2(useAlternativePolite ? politeMessage : '')
      setUseAlternativePolite(!useAlternativePolite)
    }
  }, [props.politeMessage])

  return (
    <>
      <MessageArea
        aria-live="assertive"
        message={assertiveMessage1}
        data-testid="assertiveMessage1"
      />
      <MessageArea
        aria-live="assertive"
        message={assertiveMessage2}
        data-testid="assertiveMessage2"
      />
      <MessageArea
        aria-live="polite"
        message={politeMessage1}
        data-testid="politeMessage1"
      />
      <MessageArea
        aria-live="polite"
        message={politeMessage2}
        data-testid="politeMessage2"
      />
    </>
  )
}
