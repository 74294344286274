import * as React from 'react'
import { vsprintf } from 'sprintf-js'

import { ChevronRight, SvgIcon } from '@thg-commerce/gravity-icons/src'
import {
  FlexDirection,
  HeaderThemeInterface,
  ImageThumbnailAlignment,
} from '@thg-commerce/gravity-patterns/Header/theme'
import { css, styled } from '@thg-commerce/gravity-patterns/theme'
import { margin, mq, padding, spacing, Text } from '@thg-commerce/gravity-theme'

import { NavigationItem } from '../../NavigationTree'

interface SubNavArgs {
  subNav: any
  colIndex: number
  topLevelName: string
  focusHandler: Function
  totalCards: number
  mobile: boolean
  dynamicWidths: boolean
  homeCard: boolean
  i18nHomeText?: string
  mobileImageWidth: string
  mobileImageHeight: string
  hasChildren?: boolean
  threeTierNav?: boolean
  onClick?: (index: number) => void
  headerTheme?: HeaderThemeInterface
  currentLevel?: number
}

export const SubNavigationLinkWrapper = styled.li<{
  totalCards: number
  mobile: boolean
  dynamicWidths: boolean
  threeTierNav?: boolean
}>`
  width: ${(props) =>
    props.mobile
      ? `100%`
      : !props.dynamicWidths
      ? `calc(100% / 6)`
      : `calc(100% / ${props.totalCards})`};

  ${(props) =>
    props.mobile
      ? `margin: -${spacing(1)} 0; padding: ${spacing(2)} ${spacing(2)};`
      : props.theme.patterns.header.navigation.imageThumbnail.flexDirection !==
          FlexDirection.COLUMN && `padding: ${spacing(2)} ${spacing(1)};`}

  ${(props) =>
    props.threeTierNav &&
    css`
      width: 100%;
      padding: 0 ${spacing(1.75)};
      margin: 0px;
      border: 2px solid transparent;
      &:active {
        border-color: ${props.theme.colors.palette.brand.base};
      }
    `}

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) =>
      props.theme.patterns.header.navigation.imageThumbnail.flexDirection !==
        FlexDirection.COLUMN && `padding: ${spacing(1)};`}
    ${(props) =>
      margin(
        props.theme.patterns.header.navigation.imageThumbnail.listItemMargin,
      )}
    border: 2px solid transparent;
  }

  &:focus-within {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
  /* DO NOT GROUP WITH ABOVE BREAKS POLYFILL FOR MSOFT BROWSERS */
  &.focus-within {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

const styledCardCommonStyles = css`
  display: flex;
  align-items: center;
  ${(props) =>
    props.theme.patterns.header.navigation.imageThumbnail.alignment ===
    ImageThumbnailAlignment.RIGHT
      ? 'flex-direction:row-reverse; justify-content:flex-end;'
      : 'flex-direction:row; justify-content:flex-start;'}
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  outline: none;
  text-decoration: none;
  width: 100%;
`

export const StyledCard = styled.a<{ isImageCard?: boolean }>`
  ${styledCardCommonStyles}
  &:hover {
    text-decoration: ${(props) =>
      props.theme.patterns.header.navigation.imageThumbnail.hover.font
        .textDecoration};
    background-color: ${(props) =>
      props.theme.patterns.header.navigation.imageThumbnail.hover
        .backgroundColor};
  }
`

export const StyledCardWithChildren = styled.div`
  ${styledCardCommonStyles}
  border: none;
  width: 100%;
`
export const StyledLinkText = styled.div<{ currentLevel?: number }>`
  ${(props) =>
    padding(props.theme.patterns.header.navigation.imageThumbnail.textPadding)}
  height: fit-content;
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ${(props) =>
      padding(
        props.theme.patterns.header.navigation.imageThumbnail.lg.textPadding,
      )}
  }
  ${(props) =>
    props.currentLevel === 1 &&
    css`
      ${Text(
        props.theme.patterns.header.navigation.imageThumbnail.secondTier.font
          ?.entry,
        props.theme.patterns.header.navigation.imageThumbnail.secondTier.font
          ?.style,
      )}
      text-transform: ${props.theme.patterns.header.navigation.imageThumbnail
        .secondTier.font?.transform}
    `}
  ${(props) =>
    props.currentLevel === 2 &&
    css`
      ${Text(
        props.theme.patterns.header.navigation.imageThumbnail.thirdTier.font
          ?.entry,
        props.theme.patterns.header.navigation.imageThumbnail.thirdTier.font
          ?.style,
      )}
      text-transform: ${props.theme.patterns.header.navigation.imageThumbnail
        .thirdTier.font?.transform}
    `}
    ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) =>
      Text(
        props.theme.patterns.header.navigation.imageThumbnail.thirdTier.font
          ?.entry,
        props.theme.patterns.header.navigation.imageThumbnail.thirdTier.font
          ?.style,
      )}
  }
`

export const StyledImg = styled.img<{
  mobileImageWidth?: string
  mobileImageHeight?: string
  currentLevel?: number
}>`
  width: ${(props) => props.mobileImageWidth || '56px'};
  height: ${(props) => props.mobileImageHeight || '56px'};
  border-radius: ${(props) =>
    props.theme.patterns.header.navigation.imageThumbnail.radius};
  ${(props) =>
    padding(
      props.currentLevel === 1
        ? props.theme.patterns.header.navigation.imageThumbnail.secondTier
            .imagePadding
        : props.theme.patterns.header.navigation.imageThumbnail.thirdTier
            .imagePadding,
    )}
  ${(props) =>
    margin(props.theme.patterns.header.navigation.imageThumbnail.imageMargin)}
  box-sizing: content-box;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: ${(props) =>
      props.theme.patterns.header.navigation.imageThumbnail.lg.width};
    height: ${(props) =>
      props.theme.patterns.header.navigation.imageThumbnail.lg.height};
    ${(props) =>
      padding(
        props.theme.patterns.header.navigation.imageThumbnail.lg.imagePadding,
      )}
    ${(props) =>
      margin(
        props.theme.patterns.header.navigation.imageThumbnail.lg.imageMargin,
      )}
  }
`

const CardWithChildren = (props: { subNavArgs: SubNavArgs }) => {
  const { subNavArgs } = props
  return (
    <StyledCardWithChildren>
      {subNavArgs.subNav.image && (
        <StyledImg
          src={subNavArgs.subNav.image.url}
          mobileImageWidth={subNavArgs.mobileImageWidth}
          mobileImageHeight={subNavArgs.mobileImageHeight}
          loading="lazy"
          alt={subNavArgs.subNav.image.alt || ''}
          currentLevel={subNavArgs.currentLevel}
        />
      )}
      <div
        style={{
          alignSelf: 'center',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <StyledLinkText currentLevel={subNavArgs.currentLevel}>
          {!subNavArgs.homeCard
            ? subNavArgs.subNav.displayName
            : subNavArgs.i18nHomeText &&
              vsprintf(subNavArgs.i18nHomeText, [
                subNavArgs.subNav.displayName,
              ])}
        </StyledLinkText>
        <div style={{ alignSelf: 'center' }}>
          {subNavArgs.headerTheme?.flyout.chevronRightIcon.svgPath ? (
            <div
              style={{
                height: subNavArgs.headerTheme?.flyout.chevronRightIcon.height,
                width: subNavArgs.headerTheme?.flyout.chevronRightIcon.width,
              }}
            >
              <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                viewBox={
                  subNavArgs.headerTheme?.flyout.chevronRightIcon.viewBox
                }
                width={subNavArgs.headerTheme?.flyout.chevronRightIcon.width}
                height={subNavArgs.headerTheme?.flyout.chevronRightIcon.height}
              >
                <path
                  d={subNavArgs.headerTheme?.flyout.chevronRightIcon.svgPath}
                  fillRule="evenodd"
                />
              </SvgIcon>
            </div>
          ) : (
            <ChevronRight />
          )}
        </div>
      </div>
    </StyledCardWithChildren>
  )
}

const CardWithoutChildren = (props: { subNavArgs: SubNavArgs }) => {
  const { subNavArgs } = props
  return (
    <StyledCard
      href={subNavArgs.subNav.link?.url}
      data-nav-name={`${subNavArgs.topLevelName}`}
      data-nav-index={`${
        subNavArgs.mobile ? subNavArgs.colIndex + 1 : subNavArgs.colIndex
      },0`}
      onFocus={() => {
        subNavArgs.focusHandler(subNavArgs.colIndex, 0)
      }}
    >
      {subNavArgs.subNav.image && (
        <StyledImg
          src={subNavArgs.subNav.image.url}
          mobileImageWidth={subNavArgs.mobileImageWidth}
          mobileImageHeight={subNavArgs.mobileImageHeight}
          loading="lazy"
          alt={subNavArgs.subNav.image.alt || ''}
        />
      )}
      <StyledLinkText currentLevel={subNavArgs.currentLevel}>
        {!subNavArgs.homeCard
          ? subNavArgs.subNav.displayName
          : subNavArgs.i18nHomeText &&
            vsprintf(subNavArgs.i18nHomeText, [subNavArgs.subNav.displayName])}
      </StyledLinkText>
    </StyledCard>
  )
}

export const SubNavCard = (subNavArgs: SubNavArgs) => {
  return (
    <SubNavigationLinkWrapper
      dynamicWidths={subNavArgs.dynamicWidths}
      key={`thumbnail-sub-nav-${
        !subNavArgs.mobile ? subNavArgs.colIndex : subNavArgs.colIndex + 1
      }`}
      totalCards={subNavArgs.totalCards}
      mobile={subNavArgs.mobile}
      threeTierNav={subNavArgs.threeTierNav}
      onClick={() => {
        subNavArgs.onClick && subNavArgs.onClick(subNavArgs.colIndex)
      }}
    >
      {subNavArgs.threeTierNav && subNavArgs.hasChildren ? (
        <CardWithChildren subNavArgs={subNavArgs} />
      ) : (
        <CardWithoutChildren subNavArgs={subNavArgs} />
      )}
    </SubNavigationLinkWrapper>
  )
}

const NavContent = styled.ul<{ mobile?: boolean }>`
  list-style: none;
  display: flex;
  flex-direction: ${(props) =>
    props.theme.patterns.header.navigation.imageThumbnail.flexDirection};
  flex-wrap: wrap;
  background: ${(props) =>
    props.theme.patterns.header.navigation.imageThumbnail.backgroundColor};
  margin-top: ${spacing(1.5)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    margin-top: ${spacing(1)};
  }
`

interface ImageThumbnailContentProps {
  item: NavigationItem
  mobile: boolean
  i18nHomeText?: string
  dynamicWidths: boolean
  mobileImageWidth: string
  mobileImageHeight: string
  threeTierNav?: boolean
  onClick?: (index: number) => void
  headerTheme?: HeaderThemeInterface
  currentLevel?: number
}

export const ImageThumbnailContent = ({
  item,
  mobile,
  i18nHomeText,
  dynamicWidths,
  mobileImageWidth,
  mobileImageHeight,
  threeTierNav,
  onClick,
  headerTheme,
  currentLevel,
}: ImageThumbnailContentProps) => {
  const [activeNavIndex, setActiveNavIndex] = React.useState([-1, -1])

  const onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault()

        const leftElement = document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] - 1
          },${activeNavIndex[1]}"]`,
        ) as HTMLElement
        if (leftElement) {
          leftElement.focus()
        } else if (
          document.querySelector(
            `[data-nav-name="${item.displayName}"][data-nav-index="${
              activeNavIndex[0] - 1
            },0"]`,
          ) as HTMLElement
        ) {
          let closestEl
          let index = activeNavIndex[1] - 1
          while (!closestEl) {
            closestEl = document.querySelector(
              `[data-nav-name="${item.displayName}"][data-nav-index="${
                activeNavIndex[0] - 1
              },${index}"]`,
            ) as HTMLElement
            index -= 1
          }
          closestEl.focus()
        }
        break
      case 'ArrowRight':
        event.preventDefault()

        const rightElement = document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] + 1
          },${activeNavIndex[1]}"]`,
        ) as HTMLElement
        if (rightElement) {
          rightElement.focus()
        } else if (
          document.querySelector(
            `[data-nav-name="${item.displayName}"][data-nav-index="${
              activeNavIndex[0] + 1
            },0"]`,
          ) as HTMLElement
        ) {
          let closestEl
          let index = activeNavIndex[1] - 1
          while (!closestEl) {
            closestEl = document.querySelector(
              `[data-nav-name="${item.displayName}"][data-nav-index="${
                activeNavIndex[0] + 1
              },${index}"]`,
            ) as HTMLElement
            index -= 1
          }
          closestEl.focus()
        }
        break
    }
  }
  const focusHandler = (colIndex, rowIndex) => {
    setActiveNavIndex([colIndex, rowIndex])
  }

  const navCards = item.subNavigation?.map((subNav, index) =>
    SubNavCard({
      subNav,
      dynamicWidths,
      focusHandler,
      mobile,
      i18nHomeText,
      mobileImageWidth,
      mobileImageHeight,
      threeTierNav,
      onClick,
      headerTheme,
      currentLevel,
      colIndex: index,
      topLevelName: item.displayName,
      totalCards: item.subNavigation?.length || 0,
      homeCard: false,
      hasChildren: Boolean(item.subNavigation),
    }),
  )
  return (
    <NavContent mobile={mobile} onKeyDown={!mobile ? onKeyDown : () => {}}>
      {mobile &&
        !headerTheme?.navigation.imageThumbnail?.hideHomeText &&
        SubNavCard({
          i18nHomeText,
          dynamicWidths,
          mobileImageWidth,
          mobileImageHeight,
          threeTierNav,
          onClick,
          headerTheme,
          currentLevel,
          subNav: item,
          colIndex: 0,
          topLevelName: item.displayName,
          focusHandler: () => {},
          totalCards: 0,
          mobile: true,
          homeCard: true,
          hasChildren: Boolean(item.subNavigation),
        })}
      {navCards}
    </NavContent>
  )
}
