export const getExperimentsList = (experimentsObject?: {
  [key: string]: string
}) => {
  if (typeof experimentsObject !== 'undefined') {
    return Object.keys(experimentsObject).reduce(
      (accumulator: {}[], currentKey) => {
        return accumulator.concat([
          { name: currentKey, value: experimentsObject[currentKey] },
        ])
      },
      [],
    )
  }
  return []
}
