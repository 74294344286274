import * as React from 'react'

import { useQuery } from '@apollo/react-hooks'

import {
  Feature,
  SessionSettings,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

import { EnterpriseContext } from '../EnterpriseContext'
import { SiteConfigInterface } from './types'
import { FEATURES_QUERY } from '../Layouts/AccountLayout/graphql'

export const useConfig = () => {
  const EnterpriseCtx = React.useContext(EnterpriseContext)

  if (!EnterpriseCtx.config) {
    throw Error(
      'Unable to load config from EnterpriseContext. Make sure ConfigurationLoader useConfig is called from a react component which is a child of EnterpiseProvider',
    )
  }

  return EnterpriseCtx.config
}

export const useHorizonFeature = (feature: Feature) => {
  const EnterpriseCtx = React.useContext(EnterpriseContext)

  if (!EnterpriseCtx.horizonFeatures) {
    throw Error(
      'Unable to load config from EnterpriseContext. Make sure ConfigurationLoader useConfig is called from a react component which is a child of EnterpiseProvider',
    )
  }

  return EnterpriseCtx.horizonFeatures?.includes(feature)
}

export const getSessionSettings = () => {
  return useConfig().sessionSettings
}

export const getShippingDestinations = () => {
  return useConfig().shippingDestinations
}

export const useSiteDefinition = () => {
  return useConfig().siteDefinition
}

export const useRequestConfig = () => {
  const EnterpriseCtx = React.useContext(EnterpriseContext)

  if (!EnterpriseCtx.config) {
    throw Error(
      'Unable to load config from EnterpriseContext. Make sure ConfigurationLoader useConfig is called from a react component which is a child of EnterpiseProvider',
    )
  }

  return EnterpriseCtx.requestConfig
}

export const useSiteConfig = (): SiteConfigInterface => {
  const config = useConfig()
  const { siteConfig } = config
  const subsiteDomains = config['subsiteDomains'] || {}
  const countryDomainMap = config['countryDomainMap'] || {}
  const subsites = config['subsites']
  const hreflangs = config['hreflangs']
  const translatedCountryDomain = config['translatedCountryDomain'] || {}

  return {
    ...siteConfig,
    subsiteDomains,
    countryDomainMap,
    subsites,
    hreflangs,
    translatedCountryDomain,
  }
}

export const useFeatureFlags = (features: string[]) => {
  const { featureFlags } = useRequestConfig()

  const { data, loading } = useQuery<{
    features: { enabled: string[] }
  }>(FEATURES_QUERY, {
    variables: { identifiers: features },
    context: {
      cacheKey: 'Features',
      cacheTTL: 3600,
    },
  })

  return (
    (!loading &&
      features.every((feature) => data?.features?.enabled.includes(feature))) ||
    features.every((feature) => featureFlags.includes(feature))
  )
}

export const useSessionSettings = (): SessionSettings => {
  const { sessionSettings } = useRequestConfig()
  const { defaultSessionSettings } = useSiteDefinition()

  return {
    currency:
      sessionSettings?.currency || defaultSessionSettings.currency || 'GBP',
    shippingDestination:
      sessionSettings?.shippingDestination ||
      defaultSessionSettings.shippingDestination ||
      'GB',
  }
}
