import * as React from 'react'
export const ShoppingBag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13.5 12V10.5C13.5 8.01562 15.4688 6 18 6C20.4844 6 22.5 8.01562 22.5 10.5V12H26.25C27.4688 12 28.5 13.0312 28.5 14.25V25.5C28.5 27.9844 26.4844 30 24 30H12C9.46875 30 7.5 27.9844 7.5 25.5V14.25C7.5 13.0312 8.48438 12 9.75 12H13.5ZM15 12H21V10.5C21 8.85938 19.6406 7.5 18 7.5C16.3125 7.5 15 8.85938 15 10.5V12ZM9.75 13.5C9.32812 13.5 9 13.875 9 14.25V25.5C9 27.1875 10.3125 28.5 12 28.5H24C25.6406 28.5 27 27.1875 27 25.5V14.25C27 13.875 26.625 13.5 26.25 13.5H22.5V17.25C22.5 17.6719 22.125 18 21.75 18C21.3281 18 21 17.6719 21 17.25V13.5H15V17.25C15 17.6719 14.625 18 14.25 18C13.8281 18 13.5 17.6719 13.5 17.25V13.5H9.75Z" />
    <rect x="13" y="13.5" width="10" height="5" />
    <path d="M22.0625 20.25C22.0625 20.6016 21.7695 20.8945 21.4375 20.8945H18.625V23.707C18.625 24.0391 18.332 24.3125 18 24.3125C17.6484 24.3125 17.375 24.0391 17.375 23.707V20.8945H14.5625C14.2109 20.8945 13.9375 20.6016 13.9375 20.25C13.9375 19.918 14.2109 19.6445 14.5625 19.6445H17.375V16.832C17.375 16.4805 17.6484 16.1875 18 16.1875C18.332 16.1875 18.625 16.4805 18.625 16.832V19.6445H21.4375C21.7695 19.625 22.0625 19.918 22.0625 20.25Z" />
  </svg>
)
export default ShoppingBag
