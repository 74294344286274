import * as React from 'react'
export const SvgHelpDesk = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M11.746 17.86c1.232 0 2.062.825 2.062 2.083 0 1.232-.83 2.057-2.062 2.057-1.233 0-2.076-.825-2.076-2.057 0-1.258.843-2.084 2.076-2.084zM12.08 2C15.616 2 18 4.124 18 7.06c0 2.085-1.045 3.56-2.813 4.629-1.727 1.028-2.223 1.745-2.223 3.112v.812h-2.772l-.013-1.028c-.067-1.949.75-3.167 2.584-4.276 1.62-.988 2.197-1.76 2.197-3.126 0-1.489-1.192-2.571-3.027-2.571-1.848 0-3.04 1.096-3.16 2.828H6C6.12 4.327 8.196 2 12.08 2z"
      fill={props.fill || '#333'}
      fillRule="nonzero"
    />
  </svg>
)
export default SvgHelpDesk
