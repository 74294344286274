import * as React from 'react'
export const SvgAccountMessagesApp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={64} height={64} {...props}>
    <path
      fill={props.fill || '#333'}
      fillRule="evenodd"
      d="M32 6c15.464 0 28 10.745 28 24S47.464 54 32 54c-1.611 0-3.19-.117-4.727-.34l.227-.16c-2.333 1.667-4.808 2.833-7.423 3.5-2.616.667-4.141 1-4.577 1a72.92 72.92 0 001.876-3c.567-.96 1-2.255 1.3-3.887C9.934 47.052 4 39.12 4 30 4 16.745 16.536 6 32 6zm0 2.5C17.917 8.5 6.5 18.126 6.5 30c0 8.73 6.17 16.244 15.036 19.612-.109 1.079-.336 1.962-.683 2.649-.38.753-.794 1.523-1.244 2.311.35-.042 1.545-.43 3.585-1.16a18.709 18.709 0 004.356-2.238c1.445.214 2.932.326 4.45.326 14.083 0 25.5-9.626 25.5-21.5S46.083 8.5 32 8.5z"
    />
  </svg>
)
export default SvgAccountMessagesApp
