import * as React from 'react'
export const SvgFullscreen = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path d="M15.2727 0V2.18182H21.8182V8.72727H24V2.18182V0H21.8182H15.2727Z" />
    <path d="M2.18182 0H0V2.18182V8.72727H2.18182V2.18182H8.72727V0H2.18182Z" />
    <path d="M2.18182 15.2727H0V21.8182V24V24H2.18182V24H8.72727V21.8182H2.18182V15.2727Z" />
    <path d="M24 15.2727H21.8182V21.8182H15.2727V24H21.8182H24V21.8182V15.2727Z" />
  </svg>
)
export default SvgFullscreen
