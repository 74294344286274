import * as React from 'react'
export const SvgOneItemPerRowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.25 0.25C16.6172 0.25 17.75 1.38281 17.75 2.75V15.25C17.75 16.6562 16.6172 17.75 15.25 17.75H2.75C1.34375 17.75 0.25 16.6562 0.25 15.25V2.75C0.25 1.38281 1.34375 0.25 2.75 0.25H15.25ZM15.25 1.5H2.75C2.04688 1.5 1.5 2.08594 1.5 2.75V15.25C1.5 15.9531 2.04688 16.5 2.75 16.5H15.25C15.9141 16.5 16.5 15.9531 16.5 15.25V2.75C16.5 2.08594 15.9141 1.5 15.25 1.5Z"
      fill="#383838"
    />
  </svg>
)
export default SvgOneItemPerRowIcon
