import { spacing, styled, mq, Text } from '@thg-commerce/gravity-theme'
import { Button, PlatformMessage } from '@thg-commerce/gravity-elements'
import { zIndex, ZIndexLevel } from '@thg-commerce/enterprise-theme'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: ${spacing(1)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    flex-direction: row;
    width: 100%;
    max-width: 500px;
  }
`

export const StyledPlatformMessage = styled(PlatformMessage)`
  margin-top: ${spacing(1)};
`

export const InputContainer = styled.div`
  position: relative;
  flex: 1;
`

export const DropdownContainer = styled.ul<{ dropdownActive: boolean }>`
  position: ${(props) => (props.dropdownActive ? `absolute` : `fixed`)};
  width: 320px;
  ${zIndex(ZIndexLevel.High)}
`

export const DropdownItem = styled.li`
  cursor: pointer;
  width: 100%;
  padding: ${spacing(1)} ${spacing(2)};
  margin: 0;
  border: 1px solid ${(props) => props.theme.colors.palette.greys.lighter};
  background-color: ${(props) => props.theme.colors.palette.greys.white};

  &:not(first-child) {
    border-top: none;
  }

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }

  &:focus-within {
    border-color: ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const FindStoresButton = styled(Button)`
  width: 200px;
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: initial;
  }
`

export const DefaultStockMessage = styled.p`
  ${Text('bodyText', 'alternate')};
  color: ${(props) => props.theme.colors.error.base};
  margin-top: ${spacing(2)}};
`
