import * as React from 'react'
export const SvgTelephoneOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M13.2448 17.2842L16.9661 13.565L21.3192 17.9171L19.9585 19.2754C18.4452 20.7888 16.3244 21.2123 13.9738 20.9076C11.528 20.5907 9.09141 19.7139 6.62894 17.3712C4.28625 14.9087 3.40947 12.4721 3.09251 10.0263C2.78789 7.67575 3.21134 5.55498 4.72471 4.04161L6.08572 2.68115L10.4353 7.03793L6.71597 10.7554C7.26818 12.0366 8.08018 13.3818 9.34926 14.6509C10.6184 15.92 11.9635 16.732 13.2448 17.2842Z"
      stroke="#333333"
      stroke-width="2"
    />
  </svg>
)
export default SvgTelephoneOutline
