import * as React from 'react'

import { Extensions } from '@thg-commerce/enterprise-network/types'
import { SubscribableRef } from '@thg-commerce/enterprise-utils/src/SubscribableRef'

import { loggerProvider } from '../Logger/Logger'
import { EnterpriseContextInterface } from './types'

// Placeholder for extensions ref, so we don't need an optional type.
const extensionsRef: SubscribableRef<Extensions | undefined> = {
  value: [
    () => {
      return undefined
    },
    () => {},
  ],
  subscribe: () => {},
  unsubscribe: () => {},
}

export const EnterpriseContext = React.createContext<
  EnterpriseContextInterface
>({
  extensionsRef,
  logger: loggerProvider({ brand: '', subsite: '', originUrl: '' }),
  headerHeight: 0,
  setHeaderHeight: (height: number) => height,
  history: [],
  updateHistory: (history: []) => history,
  currentLocation: '',
  metricNonce: '0',
  showCookieModal: false,
  setShowCookieModal: (show: boolean) => show,
  shippingDestination: { code: '', name: '' },
  currency: '',
  subsite: '',
  brand: '',
  requestConfig: {
    customerLocation: 'unknown',
    userAgent: '',
    previewId: '',
    showKeys: false,
    featureFlags: [],
    concessionCode: '',
  },
  appConfig: {
    isCached: false,
    productDescriptionKeys: { items: [], details: [], download: [], video: [] },
  },
  horizonFeatures: [],
})
