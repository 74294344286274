import * as React from 'react'

import {
  ToastBanner,
  ToastBannerType,
  AriaMessageType,
} from '@thg-commerce/gravity-elements'

import { Toast } from '../types'
import { ToastBannerContext } from '../ToastBannerProvider'
import { MessageArea } from '../../Announcer'

const TOAST_TIMEOUT = 7000 // toast closes after 7s

const renderAnnouncer = (ariaLive: AriaMessageType, message: string) => (
  <MessageArea aria-live={ariaLive} message={message} />
)

export const ToastBannerRenderer = (props: { 'data-testid'?: string }) => {
  const toastCtx = React.useContext(ToastBannerContext)
  const [toasts, setToasts] = React.useState<Toast[]>([])

  if (toastCtx.showToast) {
    toastCtx.showToast.current = (toast: Toast) => {
      setToasts((toasts) => [...toasts, toast])
    }
  }

  React.useEffect(() => {
    if (toasts.length > 0) {
      const toastTimeout = setTimeout(() => {
        setToasts((toasts) => toasts.slice(1))
      }, TOAST_TIMEOUT)
      return () => clearTimeout(toastTimeout)
    }
    return
  }, [toasts])

  if (toasts.length === 0) {
    return null
  }

  return (
    <ToastBanner
      data-testid={props['data-testid']}
      text={toasts[0].message}
      renderAnnouncer={renderAnnouncer}
      bannerType={toasts[0].bannerType || ToastBannerType.SUCCESS}
    />
  )
}
