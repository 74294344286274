import * as React from 'react'

import { useTheme } from '@thg-commerce/gravity-elements/theme'
import {
  BreakpointArray,
  styled,
  TextEntry,
  TextStyle,
} from '@thg-commerce/gravity-theme'
import { Padding } from '@thg-commerce/gravity-theme/padding'

import { FetchPriority, Image, ResponsiveDimensions } from '../../Image'
import { TagStyle } from '../../Image/Image'

export interface ProductImageUrls {
  thumbnail?: string
  largeProduct?: string
  zoom?: string
  tag?: string
}
export interface ProductImageThemeInterface {
  aspectRatio: string
  imageTagStyle: {
    container: {
      padding: Padding
      width: BreakpointArray<string>
    }
    textStyling: {
      entry: TextEntry
      style: TextStyle
    }
  }
}

export interface ProductImageProps {
  urls: ProductImageUrls
  alt: string
  onClick?: () => void
  isAmp?: boolean
  lazy?: boolean
  className?: string
  width?: ResponsiveDimensions
  height?: ResponsiveDimensions
  aspectRatio?: string
  fetchpriority?: FetchPriority
  tagStyle?: TagStyle
  testId?: string
}

const StyledImage = styled(Image)`
  ${({ isAmp }) =>
    isAmp &&
    `
      width: 100%;
      height: auto;

      amp-img {
        min-width: 100%;
        max-width: 100%;
      }
  `}
`

export const ProductImage = (props: ProductImageProps) => {
  const theme = useTheme()
  const isAmp = props.isAmp || false
  const imageSources = props.urls?.largeProduct
    ? [
        theme.elements.productImage.aspectRatio === '1/1'
          ? {
              url: props.urls.largeProduct,
              width: 300,
              imageSize: 300,
            }
          : {
              url: props.urls.largeProduct
                .replace('/300', '/401')
                .replace('/300', '/456'),
              width: 401,
              imageSize: 401,
            },
      ]
    : []

  props.urls?.thumbnail &&
    imageSources.push({
      url: props.urls.thumbnail,
      width: 70,
      imageSize: 70,
    })

  props.urls?.zoom &&
    imageSources.push({
      url: props.urls.zoom,
      width: 960,
      imageSize: 960,
    })

  const widthHeight = isAmp
    ? {
        width: 300,
        height: 300,
      }
    : {
        width: props.width ?? '100%',
        height: props.height ?? 'auto',
      }
  return (
    <StyledImage
      testId={props.testId}
      src={imageSources}
      alt={props.alt}
      onClick={props.onClick}
      isAmp={isAmp}
      ampLayout="responsive"
      lazy={props.lazy ?? true}
      className={props.className}
      {...widthHeight}
      displayStyle="square"
      aspectRatio={theme.elements.productImage.aspectRatio}
      fetchPriority={props.fetchpriority}
      tag={props.urls?.tag}
      tagStyle={props.tagStyle}
    />
  )
}
