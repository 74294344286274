import * as React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { Customer } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Customer'
import {
  Feature,
  MainCustomerQueryVariables,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

import { useEnterpriseContext, useLoginCheck } from '../EnterpriseContext'
import { Customer as CUSTOMER_QUERY } from '../graphql/Customer.graphql'

import { CustomerContext } from './CustomerContext'

export const CustomerProvider: React.FunctionComponent<{
  children: React.ReactNode
}> = (props) => {
  const isLoggedIn = useLoginCheck()
  const { horizonFeatures } = useEnterpriseContext()

  const { data } = useQuery<
    {
      customer: Customer
    },
    MainCustomerQueryVariables
  >(CUSTOMER_QUERY, {
    variables: {
      enableWishlists: horizonFeatures?.includes(Feature.Wishlist) || false,
      enableLoyaltyAccountDashboard:
        horizonFeatures?.includes(Feature.Loyalty) || false,
    },
    ssr: false,
    skip: !isLoggedIn,
  })

  return (
    <CustomerContext.Provider value={data?.customer || {}}>
      {props.children}
    </CustomerContext.Provider>
  )
}
