import xss from 'xss'

import { allowedSiteCodes } from './allowedSitecodes'

const allowedParams = ['brand']

const isEmpty = (obj) => {
  return Object.keys(obj).length === 0
}

const isAllowedValue = (value, param) => {
  switch (param) {
    case 'brand':
      const siteCodes = allowedSiteCodes.data.brands.map(
        (brand) => brand.siteCode,
      )
      return siteCodes.some((siteCode) => siteCode === value)
    default:
      return {}
  }
}

const constructParamFromQuery = (query, parameter) => {
  if (query === null || isEmpty(query) || typeof query === 'undefined') {
    return {}
  }
  if (allowedParams.some((allowedParam) => allowedParam === parameter)) {
    if (Array.isArray(query[parameter]) && (query[parameter][0] as string)) {
      return {
        [parameter]: query[parameter][0].toLowerCase(),
      }
    }
    if (query[parameter] as string) {
      return {
        [parameter]: query[parameter].toLowerCase(),
      }
    }
    return {}
  }
  return parameter.reduce((parameterObject, currentParam) => {
    if (
      isAllowedValue(query[currentParam], currentParam) &&
      (query[currentParam] as string)
    ) {
      parameterObject[currentParam] = query[currentParam] as string
    }
    return parameterObject
  }, {})
}

export const sanitizeParameters = <ParamsType = {}>(
  parameterKeyValueObject: ParamsType,
): Partial<ParamsType> => {
  return Object.keys(parameterKeyValueObject).reduce(
    (correctParams, currentParamKey) => {
      const cleanValue = sanitizeParameter(
        parameterKeyValueObject[currentParamKey],
      )

      correctParams[currentParamKey] = cleanValue
      return correctParams
    },
    {},
  )
}

const MAX_DECODE_ATTEMPT = 10

const decodeStringInput = (encodedInput: string, attempt: number = 0) => {
  let decodedInput = encodedInput

  try {
    decodedInput = decodeURIComponent(encodedInput)
  } catch (e) {
    const message = (e as Error).message
    console.warn(
      `Decoding string failed. string: ${encodedInput}, Reason: ${message}`,
    )

    return decodedInput
  }

  if (decodedInput === encodedInput) {
    return decodedInput
  }

  if (attempt >= MAX_DECODE_ATTEMPT) {
    console.warn(
      `[decodeStringInput] MAX_DECODE_ATTEMPT exceeded for encodedInput: ${encodedInput} & decodedInput: ${decodedInput} `,
    )
    return decodedInput
  }

  return decodeStringInput(decodedInput, attempt + 1)
}

const sanitiseStringInput = (stringInput: string) => {
  const decodedString = decodeStringInput(stringInput)

  return xss(decodedString, {
    whiteList: {}, // if empty, filter out all HTML tags
    stripIgnoreTag: true, // filter out all HTML tags not on the whilelist
    stripIgnoreTagBody: true, // filter all script content not on the whitelist
  })
}

export const sanitizeParameter = (parameterValue: string): string | null => {
  if (!parameterValue) {
    return null
  }

  const decodedParameterValue = decodeStringInput(parameterValue)

  const sanitised = sanitiseStringInput(decodedParameterValue)

  return sanitised
}

export const getSafeUrParameterFromReqQuery = (query, parameter) => {
  const correctlyTypedParam = constructParamFromQuery(query, parameter)
  const safeUrlParam = sanitizeParameters(correctlyTypedParam)
  return safeUrlParam[parameter]
}
