import * as React from 'react'
export const SvgMinus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M6 11h12v2H6z"
      fill={props.fill || 'currentColor'}
      fillRule="nonzero"
    />
  </svg>
)
export default SvgMinus
