import * as React from 'react'

export const SvgFats = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="68"
    height="68"
    viewBox="0 0 50 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.4975 0.0169742C16.71 0.604474 9.87254 7.94197 9.24754 15.6045C9.10785 16.765 9.00972 17.8933 8.91346 19.0001C8.78577 20.4682 8.66135 21.8987 8.44754 23.317C8.07254 25.8045 7.41004 28.242 5.94755 30.7545C5.68318 31.2161 5.41426 31.6793 5.14416 32.1445C2.24703 37.135 -0.786844 42.3609 0.185046 48.3295C3.53505 65.767 27.6975 71.542 40.735 61.5295C46.7725 57.6045 51.26 50.2545 49.535 42.817C48.8573 39.7327 47.1849 36.5949 45.5359 33.5009C44.2041 31.0023 42.8876 28.5322 42.1225 26.142C41.1734 23.0837 40.9039 19.9637 40.6321 16.8167C40.5173 15.4874 40.4021 14.1533 40.235 12.817C39.0725 5.52947 31.835 -0.358026 24.4975 0.0169742ZM25.96 2.34197C31.6475 2.50447 37.4975 7.62947 37.9725 13.442C38.0404 14.1691 38.1036 14.9076 38.1675 15.653C38.3155 17.3807 38.4667 19.1455 38.685 20.892C39.01 23.3795 39.485 25.8295 40.3225 28.067C41.0414 30.0264 42.1355 32.0425 43.2553 34.1059C45.5432 38.3217 47.9384 42.7352 47.46 47.267C46.11 59.992 29.785 66.542 18.8475 63.4295C10.085 61.392 0.835046 53.617 2.51005 43.717C3.13186 40.4325 4.82644 37.4836 6.51079 34.5525C7.46749 32.8877 8.42089 31.2286 9.17254 29.517C10.6267 26.0675 10.9315 22.7668 11.2472 19.3478C11.3983 17.7118 11.5519 16.0488 11.835 14.3295C12.6725 7.89197 19.4475 1.84197 25.96 2.34197ZM24.0975 25.0045C17.335 26.817 13.96 35.242 13.1225 41.5045C12.585 48.892 20.235 54.592 27.135 53.217C32.7975 52.2795 37.5475 46.6295 36.7225 40.817C35.66 34.7295 31.7475 24.7545 24.0975 25.0045ZM25.36 27.3295C31.0725 28.4795 33.985 36.817 34.4725 41.8545C35.0225 47.5295 28.685 52.1045 23.3975 50.9545C9.96004 49.6795 16.16 27.7545 25.36 27.3295ZM42.3225 46.5045C41.7322 47.4019 41.2537 48.2993 40.7898 49.1693C39.5543 51.4862 38.4227 53.6084 35.56 55.017C35.0929 55.3652 34.695 55.5865 34.3703 55.7671C34.2171 55.8522 34.0802 55.9284 33.96 56.0045C33.5975 56.242 33.385 56.4795 33.36 57.017C34.5725 62.242 48.41 46.3295 42.3225 46.5045ZM30.585 58.317C29.3475 58.6545 29.61 60.517 30.885 60.517C32.5475 60.6045 32.285 57.867 30.585 58.317Z"
      fill="#333333"
    />
    <path
      d="M24.4975 0.0169742C16.71 0.604474 9.87254 7.94197 9.24754 15.6045C9.10785 16.765 9.00972 17.8933 8.91346 19.0001C8.78577 20.4682 8.66135 21.8987 8.44754 23.317C8.07254 25.8045 7.41004 28.242 5.94755 30.7545C5.68318 31.2161 5.41426 31.6793 5.14416 32.1445C2.24703 37.135 -0.786844 42.3609 0.185046 48.3295C3.53505 65.767 27.6975 71.542 40.735 61.5295C46.7725 57.6045 51.26 50.2545 49.535 42.817C48.8573 39.7327 47.1849 36.5949 45.5359 33.5009C44.2041 31.0023 42.8876 28.5322 42.1225 26.142C41.1734 23.0837 40.9039 19.9637 40.6321 16.8167C40.5173 15.4874 40.4021 14.1533 40.235 12.817C39.0725 5.52947 31.835 -0.358026 24.4975 0.0169742ZM25.96 2.34197C31.6475 2.50447 37.4975 7.62947 37.9725 13.442C38.0404 14.1691 38.1036 14.9076 38.1675 15.653C38.3155 17.3807 38.4667 19.1455 38.685 20.892C39.01 23.3795 39.485 25.8295 40.3225 28.067C41.0414 30.0264 42.1355 32.0425 43.2553 34.1059C45.5432 38.3217 47.9384 42.7352 47.46 47.267C46.11 59.992 29.785 66.542 18.8475 63.4295C10.085 61.392 0.835046 53.617 2.51005 43.717C3.13186 40.4325 4.82644 37.4836 6.51079 34.5525C7.46749 32.8877 8.42089 31.2286 9.17254 29.517C10.6267 26.0675 10.9315 22.7668 11.2472 19.3478C11.3983 17.7118 11.5519 16.0488 11.835 14.3295C12.6725 7.89197 19.4475 1.84197 25.96 2.34197ZM24.0975 25.0045C17.335 26.817 13.96 35.242 13.1225 41.5045C12.585 48.892 20.235 54.592 27.135 53.217C32.7975 52.2795 37.5475 46.6295 36.7225 40.817C35.66 34.7295 31.7475 24.7545 24.0975 25.0045ZM25.36 27.3295C31.0725 28.4795 33.985 36.817 34.4725 41.8545C35.0225 47.5295 28.685 52.1045 23.3975 50.9545C9.96004 49.6795 16.16 27.7545 25.36 27.3295ZM42.3225 46.5045C41.7322 47.4019 41.2537 48.2993 40.7898 49.1693C39.5543 51.4862 38.4227 53.6084 35.56 55.017C35.0929 55.3652 34.695 55.5865 34.3703 55.7671C34.2171 55.8522 34.0802 55.9284 33.96 56.0045C33.5975 56.242 33.385 56.4795 33.36 57.017C34.5725 62.242 48.41 46.3295 42.3225 46.5045ZM30.585 58.317C29.3475 58.6545 29.61 60.517 30.885 60.517C32.5475 60.6045 32.285 57.867 30.585 58.317Z"
      stroke="#333333"
    />
  </svg>
)

export default SvgFats
