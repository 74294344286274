import * as React from 'react'
export const MapIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12ZM5 12C5 11.305 5.10128 10.6337 5.28988 10H8.02737C7.89491 10.6522 7.81417 11.3261 7.81417 12C7.81417 12.6739 7.89491 13.3478 8.02737 14H5.28988C5.10128 13.3663 5 12.695 5 12ZM9.81417 12C9.81417 11.3261 9.89491 10.6522 10.0274 10H13.9726C14.1051 10.6522 14.1858 11.3261 14.1858 12C14.1858 12.6739 14.1051 13.3478 13.9726 14H10.0274C9.89491 13.3478 9.81417 12.6739 9.81417 12ZM13.4024 16H10.5976C11.0716 17.3091 11.6502 18.3884 12 18.9879C12.3498 18.3884 12.9284 17.3091 13.4024 16ZM14.1921 18.6499C14.5395 18.0228 15.0073 17.0913 15.4024 16H17.7453C16.8875 17.2298 15.6449 18.1713 14.1921 18.6499ZM15.9726 14C16.1051 13.3478 16.1858 12.6739 16.1858 12C16.1858 11.3261 16.1051 10.6522 15.9726 10H18.7101C18.8987 10.6337 19 11.305 19 12C19 12.695 18.8987 13.3663 18.7101 14H15.9726ZM15.4024 8H17.7453C16.8875 6.77021 15.6449 5.82874 14.1921 5.3501C14.5395 5.97715 15.0073 6.90872 15.4024 8ZM12 5.01213C11.6502 5.61159 11.0716 6.69088 10.5976 8H13.4024C12.9284 6.69087 12.3498 5.61159 12 5.01213ZM6.25469 16H8.59757C8.99272 17.0913 9.46052 18.0228 9.8079 18.6499C8.35506 18.1713 7.1125 17.2298 6.25469 16ZM8.59757 8C8.99272 6.90872 9.46052 5.97715 9.8079 5.3501C8.35506 5.82874 7.1125 6.77021 6.25469 8H8.59757Z"
      fill={props.fill || '#333333'}
    />
  </svg>
)
export default MapIcon
