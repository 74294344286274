import * as React from 'react'
export const SvgFacebook = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <g fill="none">
      <path
        fill="#4267B2"
        d="M22.675 0H1.333C.597 0 0 .597 0 1.333v21.35C.01 23.413.603 24 1.333 24h11.5v-9.282h-3.13v-3.631h3.117V8.42c0-3.1 1.893-4.788 4.667-4.788a25.96 25.96 0 012.795.141V7h-1.915c-1.5 0-1.797.715-1.797 1.763v2.324h3.597l-.467 3.631h-3.13V24h6.105A1.333 1.333 0 0024 22.675V1.333C24 .6 23.408.005 22.675 0z"
      />
      <path
        fill="#FFF"
        d="M16.57 24v-9.282h3.13l.467-3.631H16.57V8.772c0-1.049.292-1.764 1.797-1.764h1.906V3.767a25.96 25.96 0 00-2.795-.142c-2.765 0-4.658 1.688-4.658 4.788v2.667H9.703v3.638h3.117V24h3.75z"
      />
    </g>
  </svg>
)
export default SvgFacebook
