import * as React from 'react'
export const SvgCircularSnapchat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <circle fill="#fff" cx={20} cy={20} r={20} />
    <path
      fill={props.fill || '#fffc00'}
      stroke="#000"
      d="M14.343 12.562c-.55 1.248-.333 3.486-.245 5.05-.594.334-1.356-.251-1.788-.251-.45 0-.986.3-1.07.745-.06.322.082.79 1.101 1.198.394.158 1.332.344 1.55.862.304.729-1.568 4.092-4.509 4.582a.46.46 0 00-.381.482c.051.906 2.055 1.261 2.943 1.4.09.125.164.651.28 1.052.053.179.188.394.534.394.452 0 1.203-.353 2.51-.134 1.281.216 2.486 2.058 4.799 2.058 2.15 0 3.432-1.85 4.666-2.058.714-.12 1.327-.082 2.013.054.472.094.895.146 1.03-.325.118-.406.19-.921.28-1.043.88-.139 2.892-.493 2.943-1.399a.462.462 0 00-.381-.482c-2.892-.483-4.821-3.836-4.509-4.582.217-.517 1.148-.701 1.55-.862.746-.298 1.12-.665 1.111-1.09-.01-.544-.655-.868-1.13-.868-.483 0-1.177.58-1.739.266.088-1.578.305-3.805-.244-5.053C24.617 10.195 22.302 9 19.987 9c-2.298 0-4.595 1.178-5.644 3.562z"
    />
  </svg>
)
export default SvgCircularSnapchat
