import * as React from 'react'

import { useTheme } from '@thg-commerce/gravity-patterns/theme'

import {
  BasketButton,
  ProductsContainer,
  QuantityContainer,
  StyledBasketEmptyText,
  StyledBasketViewContainer,
  StyledBasketViewText,
  StyledButtonText,
  StyledLoyaltyPoints,
  StyledProductBlock,
  StyledProductContainer,
  StyledShoppingButton,
  TotalPriceText,
} from './styles'

export interface MiniBasketProps {
  close?: () => void
  isMobile?: boolean
  locale?: string
  links: {
    viewBasket: string
  }
  basketData?: {
    items?: BasketItem[]
    totalPrice?: string
    totalQuantity?: number
    earnableLoyaltyPoints?: number | null
  }
  i18nText: {
    basketSummarySingular: string
    basketSummary: string
    basketView: string
    basketEmpty: string
    continueShopping: string
    itemListAriaLabel: string
    quantityText: string
    freePriceText: string
    loyaltyText: string
    rrpText: string
  }
}

interface BasketItem {
  name: string
  quantity: number
  // @TODO: REBUILD-6063 update price to use the ProductPriceProps interface so will match the schema for other components
  price: { price: string }
  imageUrl: string
  productUrl: string
  freeGift: boolean
  rrpPrice: {
    amount: string
    displayValue: string
  }
}

const getBasketItemPrice = (
  basketItem: BasketItem,
  displayRrpPerUnit: boolean,
  showRrpInline: boolean,
  freeText: string,
) => ({
  freeText,
  price: {
    defaultPrice: basketItem.price.price,
  },
  ...(displayRrpPerUnit &&
    basketItem.price.price !== basketItem.rrpPrice.displayValue && {
      showRrpInline,
      rrp: {
        defaultPrice: basketItem.rrpPrice.displayValue,
      },
    }),
})

export const MiniBasket = (props: MiniBasketProps) => {
  const theme = useTheme()
  if (
    props.basketData === undefined ||
    (props.basketData.items || []).length === 0
  ) {
    return (
      <React.Fragment>
        <StyledBasketEmptyText>
          {props.i18nText.basketEmpty}
        </StyledBasketEmptyText>
        {props.isMobile && (
          <StyledShoppingButton
            empty={true}
            emphasis="medium"
            onClick={() => props.close && props.close()}
          >
            <StyledButtonText>
              {props.i18nText.continueShopping}
            </StyledButtonText>
          </StyledShoppingButton>
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <StyledBasketViewContainer>
        <StyledBasketViewText>
          <QuantityContainer>
            {props.basketData.totalQuantity}{' '}
            {props.basketData.totalQuantity === 1
              ? props.i18nText.basketSummarySingular
              : props.i18nText.basketSummary}
          </QuantityContainer>
          <TotalPriceText>{props.basketData.totalPrice}</TotalPriceText>
        </StyledBasketViewText>
        {props.basketData.earnableLoyaltyPoints && (
          <StyledLoyaltyPoints pointsText={props.i18nText.loyaltyText} />
        )}
        <BasketButton
          emphasis={'high'}
          renderedAs={'a'}
          href={props.links.viewBasket}
        >
          <StyledButtonText>{props.i18nText.basketView}</StyledButtonText>
        </BasketButton>
      </StyledBasketViewContainer>
      <ProductsContainer isMobile={props.isMobile || false}>
        <ul aria-label={props.i18nText.itemListAriaLabel}>
          {props.basketData.items &&
            props.basketData.items.map((basketItem, index) => {
              return (
                <StyledProductContainer
                  data-quantity={basketItem.quantity}
                  data-testid="minibasket-product-container"
                  key={index}
                  isFreeGift={basketItem.freeGift}
                >
                  <StyledProductBlock
                    title={{
                      value: basketItem.name,
                      useAlternateStyle: false,
                    }}
                    url={basketItem.productUrl}
                    price={getBasketItemPrice(
                      basketItem,
                      theme.patterns.miniBasket?.displayRrpPerUnit,
                      theme.patterns.miniBasket?.showRrpInline,
                      basketItem.freeGift ? props.i18nText.freePriceText : '',
                    )}
                    image={{
                      urls: {
                        largeProduct: basketItem.imageUrl,
                      },
                      maxWidths: ['70px', '70px'],
                      width: 70,
                      altText: '',
                    }}
                    quantity={{
                      text: props.i18nText.quantityText,
                      quantity: basketItem.quantity,
                    }}
                    directions={['row']}
                    condensed
                  />
                </StyledProductContainer>
              )
            })}
        </ul>
        {props.isMobile && (
          <StyledShoppingButton
            emphasis="medium"
            onClick={() => props.close && props.close()}
          >
            <StyledButtonText>
              {props.i18nText.continueShopping}
            </StyledButtonText>
          </StyledShoppingButton>
        )}
      </ProductsContainer>
    </React.Fragment>
  )
}
