export type BreakpointKey = 'xs' | 'sm' | 'md' | 'lg'

export type BreakpointType<T> = {
  xs: T
  sm: T
  md: T
  lg: T
}

export interface BreakpointsMapInterface {
  xs: number
  sm: number
  md: number
  lg: number
}

const breakpointMap: BreakpointsMapInterface = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
}

export interface BreakpointsInterface {
  keys: string[]
  map: BreakpointsMapInterface
}

export const breakpointUtils: BreakpointsInterface = {
  keys: Object.keys(breakpointMap),
  map: breakpointMap,
}

export const mq = (
  map: BreakpointsMapInterface,
  key: BreakpointKey,
  excludeMedia?: boolean,
) =>
  excludeMedia
    ? `(min-width: ${map[key]}px)`
    : `@media (min-width: ${map[key]}px)`

export const dangerous_mqMax = (
  map: BreakpointsMapInterface,
  key: BreakpointKey,
  excludeMedia?: boolean,
) =>
  excludeMedia
    ? `(max-width: ${map[key] - 1}px)`
    : `@media (max-width: ${map[key] - 1}px)`

export type BreakpointArray<ValueType> =
  | [ValueType]
  | [ValueType, ValueType]
  | [ValueType, ValueType, ValueType]
  | [ValueType, ValueType, ValueType, ValueType]

export const mediaQueryRenderer = <ValueType>(
  value: BreakpointArray<ValueType>,
  renderBody: (
    value: ValueType,
    breakpoint: BreakpointKey,
    index: number,
  ) => any,
) => {
  return Object.keys(breakpointMap).map((breakpoint, index) => {
    if (typeof value[index] === 'undefined') {
      return ''
    }

    return `${mq(breakpointMap, breakpoint as BreakpointKey)} {
      ${renderBody(value[index]!, breakpoint as BreakpointKey, index)}
    }`
  })
}
