import { Property } from 'csstype'

export interface PackagingDesignBlockThemeInterface {
  container: {
    border: {
      active: {
        color: Property.Color
      }
      inactive: {
        color: Property.Color
      }
    }
  }
  button: {
    checkMarkColor: Property.Color
    border: {
      color: Property.Color
    }
  }
}

export const theme: PackagingDesignBlockThemeInterface = {
  container: {
    border: {
      active: {
        color: '#333333',
      },
      inactive: {
        color: '#CCCCCC',
      },
    },
  },
  button: {
    checkMarkColor: '#333333',
    border: {
      color: '#333333',
    },
  },
}
