import getConfig from 'next/config'

import { LinkElement } from '../Head/renderer'

export const alternateLinkTags = (
  {
    path,
    origin,
  }: {
    path: string
    origin: string
  },
  subsiteDomains: { [domain: string]: string },
  hreflangs: { [subsite: string]: string },
): LinkElement[] => {
  const originUrl = new URL(origin)
  const { publicRuntimeConfig } = getConfig()

  if (!publicRuntimeConfig.RENDER_ALT_SUBSITES) {
    return []
  }

  return Object.keys(hreflangs).map((subsite) => {
    const domain = subsiteDomains[subsite]
    const hreflangTag = hreflangs[subsite]

    return {
      type: 'link',
      props: {
        rel: 'alternate',
        hrefLang: hreflangTag,
        href: `${originUrl.protocol}//${domain}${path}`,
        key: `link_alternate_${hreflangTag}`,
      },
    }
  })
}
