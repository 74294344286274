import * as React from 'react'

import { AnimatePresence, AnimatePresenceProps } from 'framer-motion'

import { isProxyAvailable } from '../utils'

export const CustomAnimatePresence = (
  props: AnimatePresenceProps & { children: React.ReactNode },
) => {
  return isProxyAvailable ? (
    <AnimatePresence {...props} />
  ) : (
    <React.Fragment>{props.children}</React.Fragment>
  )
}
