import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from 'apollo-boost'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'
import ApolloLinkTimeout from 'apollo-link-timeout'
import fetch, { RequestInfo, RequestInit } from 'node-fetch'

import { getHistogram } from '@thg-commerce/enterprise-metrics/src/prometheus/histogram'
import {
  httpAgent,
  httpsAgent,
} from '@thg-commerce/enterprise-network/src/httpsAgent'

import { cacheRedirects } from '../src/ApolloProvider/cacheRedirects'
import { dataIdFromObject } from '../src/ApolloProvider/dataIdFromObject'
import contentIntrospectionQueryResultData from '../src/generated/Content/fragmentTypes.json'
import introspectionQueryResultData from '../src/generated/fragmentTypes.json'
import { Logger } from '../types'
import {
  MetricProperties,
  NetworkClient,
  SupportedEndpoints,
} from '../types/client'

export const mergedIntrospectionQueryResultData = {
  __schema: {
    types: [
      ...introspectionQueryResultData.__schema.types,
      ...contentIntrospectionQueryResultData.__schema.types,
    ],
  },
}

interface Chumewe {
  user: string
  session: string
}

interface OpaqueToken {
  value: string
  cookieName: string
}

const getRetryLink = ({ timeout }) => {
  const retryIf = (error, _operation) => {
    if (error) {
      if (linkConfig.retry.accepted500Codes.includes(error.statusCode)) {
        return true
      }
      const is400Code = error.statusCode <= 499 && error.statusCode >= 400
      const is500Code = error.statusCode <= 599 && error.statusCode >= 500
      if (is400Code || is500Code) return false
    }
    return true
  }

  const linkConfig = {
    timeout: parseInt(timeout, 10),
    retry: {
      delay: {
        // Milliseconds before attempting a first retry
        initial: 1000,
        // Max milliseconds a link should wait for any retry
        max: 2000,
        // Are delays between retry attempts randomized
        jitter: true,
      },
      attempts: {
        retryIf,
        max: 3,
      },
      accepted500Codes: [503, 504],
    },
  }

  const retryLink = new RetryLink({
    delay: linkConfig.retry.delay,
    attempts: linkConfig.retry.attempts,
  })

  return retryLink
}

interface ApolloConstructorOptions {
  initialState: any
  uris: SupportedEndpoints
  metrics?: MetricProperties
  setExtensions: (extensions) => void
  logger: Logger
  timeout: string
  enableRetries?: boolean
  modifiers?: {
    ignoreRateLimit?: boolean
    ip?: string
    chumewe?: Chumewe
    auth?: string
    overrides?: boolean
    opaqueToken?: OpaqueToken
    headers?: { [key: string]: string }
  }
  originReq?: {
    ip?: string
    userAgent?: string
  }
  horizonClient?: string
}

export default function initApollo(
  options: ApolloConstructorOptions,
): ApolloClient<NormalizedCacheObject> {
  const isBrowser = (process as any).browser

  async function timedFetch(url: RequestInfo, init?: RequestInit) {
    const parsedBody =
      typeof init?.body === 'string' ? JSON.parse(init?.body) : 'unknown'
    const startTime = new Date().getTime()
    const result = await fetch(url, init)
    const endTime = new Date().getTime() - startTime

    if (isBrowser) {
      return result
    }

    try {
      const histogram = getHistogram({
        name: 'enterprise_monitor_api_timings',
        labels: ['appname', 'brand', 'subsite', 'endpoint'],
        help: 'This metric stores the duration of API calls',
      })
      histogram
        .labels(
          options.metrics?.appname ?? '',
          options.metrics?.brand ?? '',
          options.metrics?.subsite ?? '',
          `horizon-fetch-${parsedBody.operationName}`,
        )
        .observe(endTime)
    } catch (error) {
      console.warn(`failed to raise metric for page query: ${error}`)
    }

    return result
  }

  const httpLink = (
    uri: string,
    ignoreRateLimit?: boolean,
    _?: string,
    chumewe?: Chumewe,
    basicAuth?: string,
    opaqueToken?: OpaqueToken,
    additionalHeaders?: { [name: string]: string } = {},
  ) => {
    const headers: { [name: string]: string | boolean | number } = {
      'Accept-Encoding': 'gzip, deflate, br',
    }
    const cookies: { [name: string]: string } = {}

    const auth = basicAuth ? { Authorization: `Basic ${basicAuth}` } : {}

    if (ignoreRateLimit) {
      headers['X-Ignore-Rate-Limiting'] = true
    }

    if (opaqueToken) {
      cookies[opaqueToken.cookieName] = opaqueToken.value
    }

    const extraOptions =
      uri?.indexOf('http://') === 0
        ? {
            fetchOptions: {
              agent: httpAgent,
            },
          }
        : {
            credentials: 'include',
            fetchOptions: {
              agent: httpsAgent,
              withCredentials: true,
            },
          }

    const originReqHeaders = options.originReq && {
      'X-Forwarded-For': options.originReq.ip,
      'User-Agent': options.originReq.userAgent,
    }

    if (Object.keys(cookies).length > 0) {
      headers.Cookie = Object.entries(cookies)
        .reduce<string[]>((accumulator, [name, value]) => {
          accumulator.push(`${name}=${value}`)
          return accumulator
        }, [])
        .join('; ')
    }

    return new HttpLink({
      uri,
      fetch: timedFetch,
      headers: process.env.CHUMEWE_HEADERS
        ? {
            ...additionalHeaders,
            ...headers,
            ...originReqHeaders,
            ...auth,
            'X-Chumewe-User': chumewe?.user,
            'X-Chumewe-Session': chumewe?.session,
          }
        : {
            ...additionalHeaders,
            ...headers,
            ...originReqHeaders,
            ...auth,
          },
      ...extraOptions,
      useGETForQueries: false,
    })
  }

  const metricsLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: new Date().getTime() })

    return forward(operation).map((response) => {
      const { start: requestStartTime } = operation.getContext()

      const requestEndTime = new Date().getTime()

      if (!isBrowser) {
        try {
          const histogram = getHistogram({
            name: 'enterprise_monitor_api_timings',
            labels: ['appname', 'brand', 'subsite', 'endpoint'],
            help: 'This metric stores the duration of API calls',
          })
          histogram
            .labels(
              options.metrics?.appname ?? '',
              options.metrics?.brand ?? '',
              options.metrics?.subsite ?? '',
              `apollo-link-${operation.operationName}`,
            )
            .observe(requestEndTime - requestStartTime)
        } catch (error) {
          console.warn(`failed to raise metric for page query: ${error}`)
        }
      }
      return response
    })
  })

  const extensionLink = (setExtensions) =>
    new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        response.extensions && setExtensions(response.extensions)
        return response
      })
    })

  const errorLink = onError(
    ({ graphQLErrors, networkError, response, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          options.logger.warn(
            // @ts-ignore
            `GQL Error: ${message}. Error occured in operation ${
              operation.operationName
            }${
              response &&
              (response as any).extensions &&
              ` with rayID ${(response as any).extensions.ray}`
            } on client ${operation.getContext().clientName || 'default'}`,
            {
              type: 'graphql_request_error',
              payload: {
                response,
              },
            },
          )
        })
      }
      if (networkError) {
        options.logger.error(
          `Network Error: ${networkError.message}. Error occured in operation ${operation.operationName}`,
          {
            type: 'graphql_network_error',
            payload: { response },
          },
        )
      }
    },
  )

  const timeoutLink = new ApolloLinkTimeout(
    parseInt(options.timeout ?? '15000', 10),
  )

  const link = (
    uris: SupportedEndpoints,
    setExtensions: (extensions) => void,
    ignoreRateLimit: boolean,
    ip,
    chumewe?: Chumewe,
    auth?: string,
    opaqueToken?: OpaqueToken,
  ) => {
    const apolloLinks: (ApolloLink | ApolloLinkTimeout)[] = []
    if (options.enableRetries ?? true) {
      const retryLink = getRetryLink({ timeout: options.timeout ?? '15000' })
      apolloLinks.push(retryLink)
    }
    return ApolloLink.from([
      // The order of this array affects its behaviour
      ...apolloLinks,
      errorLink,
      timeoutLink,
      extensionLink(setExtensions),
      metricsLink,
      ApolloLink.split(
        (operation) =>
          operation.getContext().clientName === NetworkClient.Content,
        httpLink(uris?.content || '', ignoreRateLimit, ip),
        httpLink(
          uris.default,
          ignoreRateLimit,
          ip,
          chumewe,
          auth,
          opaqueToken,
          {
            'X-Horizon-Client':
              options.horizonClient ??
              `Enterprise (${isBrowser ? 'Browser' : 'Server'})`,
            ...options.modifiers.headers,
          },
        ),
      ),
    ])
  }

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: mergedIntrospectionQueryResultData,
  })

  const createApolloClient = (
    initialState: NormalizedCacheObject,
    uris: SupportedEndpoints,
    setExtensions: (extensions) => void,
    modifiers: {
      ignoreRateLimit?: boolean
      ip?: string
      chumewe?: Chumewe
      auth?: string
      opaqueToken?: OpaqueToken
    },
  ) =>
    new ApolloClient({
      connectToDevTools: true,
      ssrMode: !isBrowser,
      link: link(
        uris,
        setExtensions,
        modifiers?.ignoreRateLimit || false,
        modifiers?.ip,
        modifiers?.chumewe,
        modifiers?.auth,
        modifiers.opaqueToken,
      ),
      cache: new InMemoryCache({
        fragmentMatcher,
        dataIdFromObject,
        cacheRedirects,
      }).restore(initialState),
      defaultOptions:
        !isBrowser && process.env.DISABLE_APOLLO_SSR
          ? {
              watchQuery: {
                fetchPolicy: 'no-cache',
              },
              query: {
                fetchPolicy: 'no-cache',
              },
            }
          : undefined,
    })

  return createApolloClient(
    options.initialState ?? {},
    options.uris,
    options.setExtensions,
    { ...options.modifiers },
  )
}
