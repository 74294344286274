import * as React from 'react'
import dynamic from 'next/dynamic'

import { styled } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing } from '@thg-commerce/gravity-theme'

import { ConnectWithUsProps } from './ConnectWithUs/ConnectWithUs'
import { NewsletterSignUpProps } from './NewsletterSignUp/NewsletterSignUp'
import { NewsletterSignUp } from './NewsletterSignUp'

const ConnectWithUs = dynamic(() =>
  import('./ConnectWithUs').then((mod) => mod.ConnectWithUs),
)

export interface CustomerEngagementProps {
  connectWithUs: ConnectWithUsProps
  newsletterSignUp: NewsletterSignUpProps
}

const CustomerEngagementContainer = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.theme.patterns.footer.customerEngagement.backgroundColor};
`

const CustomerEngagementContent = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.site.siteWidth};
  display: flex;
  flex-direction: column;
  padding: ${spacing(4)} ${spacing(2)} ${spacing(4)} ${spacing(2)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    flex-direction: row;
    padding: ${(props) =>
      props.theme.patterns.footer.customerEngagement.padding || spacing(4)};
  }
`

const Section = styled.div<{ addMobileSpacing: boolean }>`
  width: 100%;
  padding-top: ${(props) => (props.addMobileSpacing ? spacing(5) : '0')};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding-top: 0;
    width: 50%;
  }
`

export const CustomerEngagement = (props: CustomerEngagementProps) => {
  return (
    <CustomerEngagementContainer>
      <CustomerEngagementContent>
        <Section addMobileSpacing={false}>
          <NewsletterSignUp
            text={props.newsletterSignUp.text}
            buttonText={props.newsletterSignUp.buttonText}
            signUpLink={props.newsletterSignUp.signUpLink}
          />
        </Section>
        <Section addMobileSpacing={true}>
          <ConnectWithUs
            text={props.connectWithUs.text}
            socialAccounts={props.connectWithUs.socialAccounts || []}
            actionComponent={props.connectWithUs.actionComponent}
          />
        </Section>
      </CustomerEngagementContent>
    </CustomerEngagementContainer>
  )
}
