import * as React from 'react'
export const SvgContentWhatsapp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <path
      fill={props.fill || '#333'}
      d="M31 19.717c0 5.918-4.835 10.716-10.8 10.716-1.894 0-3.673-.484-5.22-1.333L9 31l1.95-5.75a10.6 10.6 0 01-1.55-5.533C9.4 13.798 14.235 9 20.2 9 26.166 9 31 13.798 31 19.717zm-10.8-9.01c-5.007 0-9.08 4.042-9.08 9.01 0 1.971.642 3.797 1.73 5.282l-1.135 3.346 3.49-1.109a9.064 9.064 0 004.995 1.49c5.007 0 9.08-4.04 9.08-9.009 0-4.968-4.073-9.01-9.08-9.01zm5.454 11.478c-.067-.11-.243-.175-.508-.307-.265-.131-1.567-.767-1.809-.854-.242-.088-.42-.132-.596.131s-.683.855-.838 1.03c-.154.176-.309.198-.574.066-.264-.131-1.117-.409-2.128-1.304-.787-.696-1.319-1.555-1.473-1.818-.154-.263-.016-.405.116-.536.12-.118.265-.307.397-.46.133-.154.177-.263.265-.438.088-.176.044-.329-.022-.46-.066-.132-.596-1.425-.817-1.95-.22-.526-.44-.439-.595-.439-.154 0-.331-.022-.508-.022a.978.978 0 00-.706.329c-.242.263-.926.898-.926 2.19 0 1.293.948 2.542 1.08 2.717.133.175 1.832 2.914 4.523 3.966 2.692 1.051 2.692.7 3.177.657.485-.044 1.566-.636 1.788-1.249.22-.614.22-1.14.154-1.25z"
    />
  </svg>
)
export default SvgContentWhatsapp
