export interface LogoInterface {
  logoSVG?: string // deprecated: please use logoUri or logoAlternateUri
  logoUri?: string
  logoAlternateUri?: string
  logoHeight?: string // deprecated: please use value from pattern
  logoVerticalMargin?: string // deprecated: please use value from pattern
}

export const logo: LogoInterface = {
  logoSVG: `<svg xmlns="http://www.w3.org/2000/svg" width="79" height="24" viewBox="0 0 79 24">
  <path fill="#333" d="M7.81597009e-14,0.168 L20.1426061,0.168 L20.1426061,5.2416 L13.1044242,5.2416 L13.1044242,23.7216 L6.97115152,23.7216 L6.97115152,5.2416 L7.81597009e-14,5.2416 L7.81597009e-14,0.168 Z M27.4106061,0.168 L33.5438788,0.168 L33.5438788,9.7776 L43.0286667,9.7776 L43.0286667,0.168 L49.1619394,0.168 L49.1619394,23.7216 L43.0286667,23.7216 L43.0286667,14.6832 L33.5438788,14.6832 L33.5438788,23.7216 L27.4106061,23.7216 L27.4106061,0.168 Z M72.8619394,17.64 L72.8619394,11.6928 L78.1573333,11.6928 L78.1573333,20.9664 C75.643697,22.7136 71.6553939,23.9904 68.3709091,23.9904 C61.1986667,23.9904 55.8027273,18.816 55.8027273,11.9616 C55.8027273,5.1072 61.3327273,2.84217094e-14 68.7730909,2.84217094e-14 C72.2921818,2.84217094e-14 76.0123636,1.344 78.4254545,3.4944 L74.9398788,7.7952 C73.2641212,6.216 70.8845455,5.208 68.7395758,5.208 C64.9523636,5.208 62.1035758,8.1312 62.1035758,11.9616 C62.1035758,15.8256 64.9858788,18.7488 68.8066061,18.7488 C69.9796364,18.7488 71.454303,18.3456 72.8619394,17.64 Z"/>
  </svg>
  `,
  logoUri: '',
  logoAlternateUri: '',
  logoHeight: '18px',
  logoVerticalMargin: '24px',
}
