import * as React from 'react'
export const SvgCircularMessenger = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <circle cx={20} cy={20} r={20} fill="#CCC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.156 9C13.872 9 9 13.604 9 19.822c0 3.252 1.333 6.063 3.504 8.004a.891.891 0 01.3.636l.06 1.984a.892.892 0 001.253.79l2.214-.978a.89.89 0 01.596-.044c1.017.28 2.1.43 3.23.43 6.284 0 11.155-4.604 11.155-10.822C31.313 13.604 26.441 9 20.157 9z"
      fill="url(#circular-messenger_svg__paint0_radial_2074_10361)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.457 22.986l3.277-5.199a1.673 1.673 0 012.42-.447l2.606 1.955a.67.67 0 00.807-.002l3.52-2.671c.47-.357 1.083.205.769.704l-3.278 5.2a1.673 1.673 0 01-2.42.446l-2.606-1.955a.67.67 0 00-.806.002l-3.52 2.672c-.47.357-1.084-.206-.769-.705z"
      fill={props.fill || '#fff'}
    />
    <defs>
      <radialGradient
        id="circular-messenger_svg__paint0_radial_2074_10361"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.295 31.193) scale(24.3116)"
      >
        <stop stopColor="#09F" />
        <stop offset={0.61} stopColor="#A033FF" />
        <stop offset={0.935} stopColor="#FF5280" />
        <stop offset={1} stopColor="#FF7061" />
      </radialGradient>
    </defs>
  </svg>
)
export default SvgCircularMessenger
