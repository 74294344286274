import * as React from 'react'
import getConfig from 'next/config'

import {
  EnterpriseProvider,
  loggerProvider,
  useSiteConfig,
} from '@thg-commerce/enterprise-core'
import {
  getShippingDestination,
  loadConfiguration,
  loadRequestProps,
  loadTheme,
} from '@thg-commerce/enterprise-core/src/ConfigurationLoader/ConfigurationLoader'
import { NextConfig } from '@thg-commerce/enterprise-config'
import { EnterpriseRequest } from '@thg-commerce/enterprise-core/src/Server/types'
import initApollo from '@thg-commerce/enterprise-network/lib/initApollo'
import { graphqlUrls } from '@thg-commerce/enterprise-network/utils/urls'
import { Resolvers } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers'
import { loadSiteProperties } from '@thg-commerce/enterprise-core/src/i18n/siteProperties'
import { createApolloWithRequest } from '@thg-commerce/enterprise-core/src/Factory/apollo'
import { ApolloProvider } from '@thg-commerce/enterprise-network'
import { OverrideClient } from '@thg-commerce/enterprise-network/src/ApolloProvider/utils'
import {
  Configuration,
  RequestConfig,
  SiteProperties,
} from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import { EnterpriseThemeInterface } from '@thg-commerce/enterprise-theme'
import { ShippingDestination } from '@thg-commerce/enterprise-config/types'
import { Feature } from '@thg-commerce/enterprise-network/src/generated/graphql'

import { ESIComponent } from './types'

interface WrapperProps<ComponentProps> {
  shippingDestination?: ShippingDestination
  chumewe?: { user: string; session: string }
  horizonFeatures?: Feature[]
  allowNetworkOverrides?: boolean
  allowNetworkSitePropertyOverrides?: boolean
  siteProperties?: SiteProperties
  requestConfig?: RequestConfig
  config?: Configuration
  theme?: EnterpriseThemeInterface
  componentProps?: ComponentProps
  ip?: string
}

export const withESIWrapper = <Props, InitialProps>(
  Component: ESIComponent<Props, InitialProps>,
  configKey: string = '',
  envVar: string = '',
) => {
  const nextConfig = getConfig() as NextConfig

  const Wrapper: ESIComponent<
    Props & WrapperProps<Props>,
    WrapperProps<InitialProps>
  > = (props) => {
    const logger = React.useMemo(
      () =>
        props.config
          ? loggerProvider({
              brand: props.config.publicRuntimeConfig.siteDefinition.brand,
              subsite: props.config.publicRuntimeConfig.siteDefinition.subsite,
              originUrl:
                props.config.publicRuntimeConfig.siteDefinition.originUrl,
            })
          : undefined,
      [
        props.config?.publicRuntimeConfig.siteDefinition.brand,
        props.config?.publicRuntimeConfig.siteDefinition.subsite,
      ],
    )

    const apolloClient = React.useMemo(() => {
      if (!logger || !props.config) {
        return undefined
      }
      const apollo = initApollo({
        logger,
        initialState: {},
        uris: graphqlUrls(props.config?.publicRuntimeConfig),
        // Excluding extensions because all events/queries/mutations that need them should be client side
        setExtensions: () => {},
        timeout: props.config?.serverRuntimeConfig.APOLLO_TIMEOUT,
        enableRetries: props.config?.serverRuntimeConfig.APOLLO_ENABLE_RETRIES,
        modifiers: {
          ignoreRateLimit:
            props.config.publicRuntimeConfig.IGNORE_RATE_LIMITING,
          ip: props.ip,
          chumewe: props.chumewe,
          overrides:
            props.allowNetworkOverrides &&
            OverrideClient(props.config.publicRuntimeConfig),
        },
        horizonClient: 'Enterprise (Terra)',
      })
      apollo.addResolvers(
        Resolvers(
          apollo,
          props.config.publicRuntimeConfig,
          props.allowNetworkOverrides,
          props.allowNetworkSitePropertyOverrides,
        ),
      )
      return apollo
    }, [props.config?.publicRuntimeConfig])

    if (typeof window !== 'undefined') {
      return (
        <Component
          {...(props.componentProps ||
            (props as JSX.IntrinsicAttributes & Props))}
        />
      )
    }

    if (
      !props.config ||
      !props.requestConfig ||
      !logger ||
      !props.siteProperties ||
      !apolloClient ||
      !props.theme ||
      !props.shippingDestination
    ) {
      const siteConfig = useSiteConfig()
      const esiEnabledEnv = nextConfig.publicRuntimeConfig[envVar] === true
      const esiEnabledSiteConfig = siteConfig[configKey] === true
      const checkESIEnabled = configKey && envVar

      const enableESI = checkESIEnabled
        ? esiEnabledSiteConfig && esiEnabledEnv
        : true

      if (!enableESI) {
        return <Component {...(props as JSX.IntrinsicAttributes & Props)} />
      }

      return null
    }

    const { publicRuntimeConfig, ...appConfig } = props.config

    return (
      <ApolloProvider client={apolloClient}>
        <EnterpriseProvider
          logger={logger}
          previewId={props.requestConfig.previewId}
          theme={props.theme}
          showKeys={props.requestConfig.showKeys}
          brand={publicRuntimeConfig.siteDefinition.brand}
          subsite={publicRuntimeConfig.siteDefinition.subsite}
          currentLocation={props.requestConfig.customerLocation}
          metricNonce=""
          config={props.config}
          requestConfig={props.requestConfig}
          serviceContextProviders={[]}
          shippingDestination={props.shippingDestination}
          appConfig={appConfig}
          horizonFeatures={props.horizonFeatures}
          siteProperties={props.siteProperties}
        >
          <Component
            {...(props.componentProps ||
              ({} as JSX.IntrinsicAttributes & Props))}
          />
        </EnterpriseProvider>
      </ApolloProvider>
    )
  }

  Wrapper.getInitialProps = async (context) => {
    if (typeof window !== 'undefined') {
      return {
        componentProps: context.props,
      }
    }

    const request = context.req as EnterpriseRequest
    const config = await loadConfiguration(nextConfig, request)

    const allowOverrides =
      request.headers['x-enterprise-allow-overrides'] === '1'
    const allowSitePropertyOverrides =
      context.req.headers['x-enterprise-allow-site-property-overrides'] === '1'

    const apolloClient = createApolloWithRequest(request, config, true)
    apolloClient.addResolvers(
      Resolvers(
        apolloClient,
        config.publicRuntimeConfig,
        allowOverrides,
        allowSitePropertyOverrides,
      ),
    )

    const theme = await loadTheme(config)
    const requestProps = await loadRequestProps(nextConfig, request)
    const siteProperties = await loadSiteProperties(config)

    let componentProps: InitialProps | undefined
    if (Component.getInitialProps) {
      componentProps = await Component.getInitialProps({
        apolloClient,
        config,
        req: context.req,
        res: context.res,
        props: context.props,
        esi: true,
      })
    }

    const shippingDestination = getShippingDestination(
      config.publicRuntimeConfig.siteDefinition.subsite,
      config.publicRuntimeConfig.shippingDestinations,
      config.publicRuntimeConfig.siteDefinition.defaultLocale.split('_')[1],
      request.config.customerLocation,
    )

    return {
      componentProps,
      shippingDestination,
      serverProps: {
        config,
        theme,
        siteProperties,
        requestConfig: request.config,
      },
      ip: request.enterpriseIp,
      chumewe: requestProps.chumewe,
      horizonFeatures: request.horizonFeatures || [],
      allowNetworkOverrides: allowOverrides,
      allowNetworkSitePropertyOverrides: allowSitePropertyOverrides,
    }
  }

  return Wrapper
}
