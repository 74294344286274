import * as React from 'react'
export const SvgReviewStar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" viewBox="0 0 14 14" {...props}>
    <path
      d="M7 10.938L2.628 14l1.224-5.25L0 5.25l5.055-.49L7 0l1.945 4.76L14 5.25l-3.852 3.5L11.372 14 7 10.937z"
      fill={props.fill || '#D77F04'}
      stroke={props.stroke || '#D77F04'}
    />
  </svg>
)
export default SvgReviewStar
