import * as React from 'react'
export const SvgWhatsappIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 64 64" {...props}>
    <g fillRule="evenodd">
      <path d="M33.5 4.228C18.33 4.228 6 16.635 6 31.913c0 5.552 1.634 10.741 4.516 15.193L7.44 57.438c-.134.452-.006.942.332 1.269.339.327.83.435 1.273.282l10.046-3.49a27.235 27.235 0 0014.409 4.1c15.175 0 27.5-12.408 27.5-27.686C61 16.636 48.675 4.228 33.5 4.228zm0 2.517c13.825 0 25 11.25 25 25.168s-11.175 25.169-25 25.169c-5.012 0-9.673-1.48-13.57-4.026a1.243 1.243 0 00-1.088-.133l-8.307 2.886 2.544-8.547a1.266 1.266 0 00-.165-1.07C10.09 42.021 8.5 37.162 8.5 31.913c0-13.917 11.182-25.168 25-25.168z" />
      <path
        fillRule="nonzero"
        d="M23.415 16c1.105 0 2.155.53 2.838 1.426l.091.125 3.246 4.646c.549.788.759 1.74.592 2.682a3.554 3.554 0 01-1.344 2.213l-.136.1-1.545 1.078c1.212 1.808 2.904 3.825 4.826 5.748 1.81 1.81 3.703 3.415 5.426 4.609l.322.219 1.078-1.546a3.586 3.586 0 014.852-.982l.142.094 4.646 3.246c1.337.935 1.889 2.62 1.342 4.098a6.65 6.65 0 01-.443.96l-.13.216-.451.645a4.878 4.878 0 01-.424.48c-.783.783-1.773 1.339-2.944 1.652a8.979 8.979 0 01-2.08.287L43.04 48l-.243-.003c-4.88-.105-11.082-3.354-16.26-8.532-6.268-6.269-9.58-13.85-8.243-18.863.313-1.171.868-2.162 1.651-2.944.095-.095.193-.188.296-.276l.156-.128.642-.452a6.433 6.433 0 011.208-.593A3.363 3.363 0 0123.415 16zm.138 2lNaN NaNsNaN NaN NaN NaNcNaN NaN NaN NaN NaN NaNlNaN NaN NaN NaN NaN NaNcNaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaNlNaN NaN NaN NaN NaN NaNcNaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaNlNaN NaN NaN NaN NaN NaNcNaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaNlNaN NaN NaN NaNcNaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaN NaNlNaN NaN NaN NaN NaN NaNcNaN NaN NaN NaN NaN NaNlNaN NaNz"
      />
    </g>
  </svg>
)
export default SvgWhatsappIcon
