import React from 'react'

export const InfoDeliveryIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23 18.3112L21.8455 18.3005C21.395 20.0192 19.8314 21.2871 17.9717 21.2871C16.1057 21.2871 14.5379 20.0107 14.0933 18.2833H9.83652C9.39198 20.0107 7.82412 21.2871 5.95819 21.2871C4.09226 21.2871 2.52441 20.0107 2.07986 18.2833H1V4.28711H15.9694V6.28904L19.9739 6.26802L22.9773 11.2744L23 18.3112ZM18.9728 8.27057H14.9683V12.2757H20.975V11.2744L18.9728 8.27057ZM7.96044 17.282C7.96044 18.388 7.064 19.2846 5.95819 19.2846C4.85239 19.2846 3.95595 18.388 3.95595 17.282C3.95595 16.176 4.85239 15.2795 5.95819 15.2795C7.064 15.2795 7.96044 16.176 7.96044 17.282ZM17.9717 19.2846C19.0775 19.2846 19.9739 18.388 19.9739 17.282C19.9739 16.176 19.0775 15.2795 17.9717 15.2795C16.8659 15.2795 15.9694 16.176 15.9694 17.282C15.9694 18.388 16.8659 19.2846 17.9717 19.2846Z"
      fill={props.fill || '#333333'}
    />
  </svg>
)
export default InfoDeliveryIcon
