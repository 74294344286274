import * as React from 'react'
import { vsprintf } from 'sprintf-js'

import {
  I18nProvider as GenericI18nProvider,
  I18nStringMap,
  useI18n as useCoreI18n,
} from '@thg-commerce/enterprise-translations'

import { useLogger } from '../Logger'

export const I18nProvider = (props: {
  value: I18nStringMap
  showKeys?: boolean
  children?: React.ReactNode
}) => <GenericI18nProvider {...props} />

export const i18n = (key: string, variables?: string[] | string) => {
  const logger = useLogger()
  const i18n = useCoreI18n(logger)
  return i18n(key, variables)
}

export const useI18n = () => {
  const logger = useLogger()
  const i18n = useCoreI18n(logger)
  return i18n
}

export const useFormattableI18nProperty = (
  key: string,
): ((variables: string | string[]) => string) => {
  const logger = useLogger()
  const i18n = useCoreI18n(logger)
  const value = i18n(key)
  return (variables: string[] | string): string => {
    return vsprintf(value, variables as string[])
  }
}
