import * as React from 'react'
export const SvgRefineIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={18} height={14} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.268 1a2 2 0 013.464 0H18v2H6.732a2 2 0 01-3.464 0H0V1h3.268zm9 5a2 2 0 013.464 0H18v2h-2.268a2 2 0 01-3.464 0H0V6h12.268zm-2.536 5a2 2 0 00-3.464 0H0v2h6.268a2 2 0 003.464 0H18v-2H9.732z"
    />
  </svg>
)
export default SvgRefineIcon
