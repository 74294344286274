import { getMetaObject } from './meta'
import { MetricsSiteDefinition, CoreWebVitals, PerformanceData } from '../'

export const getFirstLoadPerfData = (
  siteDefinition: MetricsSiteDefinition,
  customerLocation: string,
  subsite: string,
  metricNonce: string,
  coreWebVitals: CoreWebVitals,
): PerformanceData => ({
  meta: getMetaObject(siteDefinition, customerLocation, subsite, metricNonce),
  performance: coreWebVitals,
  key: 'pageLoad',
})
