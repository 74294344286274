import * as React from 'react'

import {
  FulfilmentMethod,
  StoreOpeningTime,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  ButtonWrapper,
  CTAPlaceholder,
  Day,
  Distance,
  Hours,
  IconBox,
  IconError,
  IconInfo,
  InfoMessageText,
  InfoMessageWrapper,
  InfoWrapper,
  MessagesWrapper,
  Name,
  NameWrapper,
  Separator,
  StoreWrapper,
  StyledButton,
  StyledIconSuccess,
  TimesWrapper,
} from './styles'

export interface SearchResultsProps {
  stores: {
    id: string
    displayName: string
    distance: Number
    fulfilmentMethods: FulfilmentMethod[]
    openingTimes: [StoreOpeningTime, StoreOpeningTime, StoreOpeningTime]
    stock: number
    ranged: boolean
  }[]
  i18nText: {
    clickAndCollectButton: string
    deliverToStoreButton: string
    deliverToStoreAvailability: string
    inStockText: (_: string) => string
    outOfStockText: string
    unavailableStore: string
    unavailableClickAndCollectProduct: string
    unavailableClickAndCollectStore: string
    productNotRanged: string
  }
  onClick: (fulfilmentType: FulfilmentMethod, storeId: string) => void
  productIsCheckStock: boolean
  productIsOrderInStore: boolean
  productIsClickAndCollect: boolean
  isProductPage?: boolean
  productFulfilmentMethod?: FulfilmentMethod
  productStoreId?: string
}

export const InfoMessage = (
  iconType: 'danger' | 'info' | 'success',
  text: string,
) => (
  <InfoMessageWrapper data-testid={`${iconType}`}>
    <IconBox>
      {iconType === 'danger' && <IconError />}
      {iconType === 'info' && <IconInfo width={24} height={24} />}
      {iconType === 'success' && <StyledIconSuccess width={24} height={24} />}
    </IconBox>
    <InfoMessageText>{text}</InfoMessageText>
  </InfoMessageWrapper>
)

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function formatAMPM(dateString: string) {
  const [hours, minutes, _] = dateString.split(':')
  let hoursNumber = Number(hours)
  const ampm = hoursNumber >= 12 ? 'pm' : 'am'
  hoursNumber = hoursNumber % 12
  hoursNumber = hoursNumber ? hoursNumber : 12
  return `${hoursNumber}:${minutes}${ampm}`
}

export const SearchResults = ({
  stores,
  i18nText,
  onClick,
  productIsCheckStock,
  productIsOrderInStore,
  productIsClickAndCollect,
  isProductPage,
  productFulfilmentMethod,
  productStoreId,
}: SearchResultsProps) => {
  return (
    <div>
      {stores.map((store, index) => {
        const storeOutOfStock = store.stock === 0
        const storeHighStock = store.stock >= 6
        const storeLowStock = store.stock < 6 && !storeOutOfStock
        const isStoreDeliverToStore = store.fulfilmentMethods.find(
          (fulfilmentMethod) =>
            fulfilmentMethod === FulfilmentMethod.DeliverToStore,
        )
        const isStoreClickAndCollect = store.fulfilmentMethods.find(
          (fulfilmentMethod) =>
            fulfilmentMethod === FulfilmentMethod.CollectInStore,
        )
        const buttonText =
          isStoreClickAndCollect && !storeOutOfStock
            ? i18nText.clickAndCollectButton
            : isStoreDeliverToStore && storeOutOfStock
            ? i18nText.deliverToStoreButton
            : undefined
        const messageCTAPlaceholder =
          !isStoreDeliverToStore && !isStoreClickAndCollect
            ? [
                !storeOutOfStock && productIsCheckStock
                  ? i18nText.unavailableClickAndCollectProduct
                  : [
                      productIsClickAndCollect && !storeOutOfStock
                        ? i18nText.unavailableClickAndCollectStore
                        : [
                            !store.ranged && productIsCheckStock
                              ? i18nText.productNotRanged
                              : undefined,
                          ],
                    ],
              ]
            : undefined

        const isButtonDisabled =
          isProductPage &&
          productFulfilmentMethod &&
          !(
            (isStoreClickAndCollect &&
              productFulfilmentMethod === FulfilmentMethod.CollectInStore) ||
            (!isStoreClickAndCollect &&
              isStoreDeliverToStore &&
              productFulfilmentMethod === FulfilmentMethod.DeliverToStore)
          )

        return (
          <div key={`store-${index}`}>
            <Separator topMargin={index === 0 ? 4 : 2} bottomMargin={2} />
            <StoreWrapper>
              <InfoWrapper>
                <MessagesWrapper>
                  <NameWrapper>
                    <Name>{store.displayName}</Name>
                    <Distance>({store.distance.toFixed(1)} miles)</Distance>
                  </NameWrapper>
                  {storeHighStock &&
                    InfoMessage(
                      'success',
                      i18nText.inStockText(String(store.stock)),
                    )}
                  {storeLowStock &&
                    InfoMessage(
                      'info',
                      i18nText.inStockText(String(store.stock)),
                    )}
                  {storeOutOfStock &&
                    store.ranged &&
                    InfoMessage('danger', i18nText.outOfStockText)}
                  {storeOutOfStock &&
                    !store.ranged &&
                    InfoMessage('danger', i18nText.unavailableStore)}
                  {isStoreDeliverToStore &&
                    storeOutOfStock &&
                    InfoMessage('success', i18nText.deliverToStoreAvailability)}
                </MessagesWrapper>
                <TimesWrapper>
                  {store.openingTimes.map((time, index) => (
                    <div key={`store-opening-time-${index}`}>
                      <Day>
                        {index === 0
                          ? `Today`
                          : capitalizeFirstLetter(time.day.toLowerCase())}
                        :
                      </Day>
                      <Hours>
                        {formatAMPM(time.openingTime)} -{' '}
                        {formatAMPM(time.closingTime)}
                      </Hours>
                    </div>
                  ))}
                </TimesWrapper>
                <ButtonWrapper
                  hasMarginTop={!!(messageCTAPlaceholder || buttonText)}
                >
                  {messageCTAPlaceholder ? (
                    <CTAPlaceholder>{messageCTAPlaceholder}</CTAPlaceholder>
                  ) : buttonText ? (
                    <StyledButton
                      data-testid="click-and-collect-button"
                      data-storeid={store.id}
                      onClick={() =>
                        buttonText === i18nText.deliverToStoreButton
                          ? onClick(FulfilmentMethod.DeliverToStore, store.id)
                          : onClick(FulfilmentMethod.CollectInStore, store.id)
                      }
                      disabled={
                        isButtonDisabled ||
                        !!(productStoreId && productStoreId !== store.id)
                      }
                    >
                      {buttonText}
                    </StyledButton>
                  ) : undefined}
                </ButtonWrapper>
              </InfoWrapper>
            </StoreWrapper>
          </div>
        )
      })}
    </div>
  )
}
