import { Button } from '@thg-commerce/gravity-elements'
import { styled, spacing, mq, Text } from '@thg-commerce/gravity-theme'
import loadable from '@loadable/component'

const IconSuccess = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconSuccess'),
)

export const IconError = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconError'),
)
export const IconInfo = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconInfo'),
)

export const Separator = styled.hr<{
  topMargin: number
  bottomMargin: number
  isLast?: boolean
}>`
  ${(props) => props.isLast === true && `display: none;`}
  border: 0;
  border-top: 1px solid ${(props) => props.theme.colors.palette.greys.light};
  margin: ${(props) =>
    `${spacing(props.topMargin)} 0 ${spacing(props.bottomMargin)} 0`};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    display: inherit;
  }
`

export const StyledIconSuccess = styled(IconSuccess)`
  path {
    fill: ${(props) => props.theme.colors.success.base};
  }
`

export const StoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    flex-direction: row;
  }
}
`

export const NameWrapper = styled.div`
  margin-bottom: ${spacing(2)};
`

export const TimesWrapper = styled.span`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  flex-grow: 1;
  ${Text('bodyText', 'default')};
  margin-top: ${spacing(2)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-top: 0;
  }
`

export const Day = styled.span`
  ${Text('bodyText', 'alternate')}
  display: inline-block;
  width: 90px;
`

export const Hours = styled.span`
  ${Text('small', 'default')}
  margin-left: ${spacing(2)};
`

export const StyledButton = styled(Button)`
  ${Text('bodyText', 'alternate')};
  width: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-top: 0;
  }
`

export const ButtonWrapper = styled.div<{
  hasMarginTop: boolean
}>`
  width: 100%;
  ${(props) => props.hasMarginTop && `margin-top: ${spacing(3)};`}
  text-align: center;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: max-content;
    margin-top: 0;
  }
`

export const CTAPlaceholder = styled.span`
  ${Text('bodyText', 'alternate')};
  width: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-top: 0;
  }
`

export const MessagesWrapper = styled.div`
  max-width: 400px;
  flex-grow: 2;

  & > :not(:first-child) {
    margin-top: ${spacing(2)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: 250px;
  }
`

export const IconBox = styled.div`
  margin-top: 2px;
`

export const InfoMessageWrapper = styled.div`
  display: flex;
`

export const InfoMessageText = styled.div`
  margin-inline: ${spacing(1)};
  ${Text('bodyText', 'default')};
`

export const Name = styled.span`
  ${Text('bodyText', 'alternate')};
`

export const Distance = styled.span`
  margin-left: ${spacing(1)};
  ${Text('bodyText', 'default')};
`
