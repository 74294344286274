import * as React from 'react'
import { vsprintf } from 'sprintf-js'

import { styled } from '@thg-commerce/gravity-patterns/theme'
import {
  mediaQueryRenderer,
  mq,
  padding,
  spacing,
  Text,
} from '@thg-commerce/gravity-theme'

import { NavigationItem } from '../../NavigationTree'

const SubNavigationLinkWrapper = styled.li<{
  dynamicWidths: boolean
  totalCards: number
  mobile: boolean
  currentLevel?: number
}>`
  list-style-type: none;
  width: ${(props) =>
    props.mobile
      ? `100%`
      : !props.dynamicWidths
      ? `calc(100% / 6)`
      : `calc(100% / ${props.totalCards})`};
  margin: 0;

  ${(props) =>
    props.currentLevel !== 0 &&
    `
      margin: ${spacing(1.5)} 0;
      &:last-of-type {
        margin-bottom: ${spacing(1.75)};
      }
    `}
`

export const StyledCard = styled.a<{ currentLevel?: number }>`
  position: relative;
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.dropdownHeaderFont.entry,
      props.theme.patterns.header.navigation.dropdownHeaderFont.style,
    )}
  color: ${(props) =>
    props.theme.patterns.header.navigation.dropdownHeaderFont.textColor};
  display: flex;
  flex-direction: column;
  outline: none;
  text-decoration: none;
  border: 2px solid transparent;
  width: 100%;
  justify-content: center;
  padding: ${(props) =>
    `${props.currentLevel === 0 ? spacing(0.25) : 0} ${spacing(1.75)}`};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    padding: 0;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus-within {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
  /* DO NOT GROUP WITH ABOVE BREAKS POLYFILL FOR MSOFT BROWSERS */
  &.focus-within {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const StyledLinkText = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.imageCard.text.font.entry,
      props.theme.patterns.header.navigation.imageCard.text.font.style,
    )};
  ${(props) =>
    padding(props.theme.patterns.header.navigation.imageCard.text.padding)};
  color: ${(props) =>
    props.theme.patterns.header.navigation.imageCard.text.font.textColor};
  background: ${(props) =>
    props.theme.patterns.header.navigation.imageCard.text.background};
  position: ${(props) =>
    props.theme.patterns.header.navigation.imageCard.text.position};
  ${(props) =>
    mediaQueryRenderer(
      props.theme.patterns.header.navigation.imageCard.text.bottom,
      (value) => `bottom: ${value};`,
    )}
`

export const StyledImg = styled.img<{
  width?: string
  height?: string
  currentLevel?: number
}>`
  width: ${(props) => props.width || '100%'};
  ${(props) => props.height && `height: ${props.height}`};
  object-fit: ${(props) =>
    props.theme.patterns.header.navigation.imageCard.image.objectFit};
  object-position: ${(props) => (props.currentLevel === 2 ? 'top' : 'center')};
  ${(props) =>
    mediaQueryRenderer(
      props.theme.patterns.header.navigation.imageCard.image.aspectRatio,
      (value) => `aspect-ratio: ${value};`,
    )}

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    object-position: 50% 50%;
  }
`

interface ImageCardProps {
  subNav: any
  colIndex?: number
  topLevelName: string
  focusHandler?: Function
  dynamicWidths: boolean
  totalCards: number
  mobile: boolean
  homeCard: boolean
  i18nHomeText?: string
  currentLevel?: number
  height?: string
  onClick?: () => void
}

export const ImageCard = (subNavArgs: ImageCardProps) => {
  return (
    <SubNavigationLinkWrapper
      key={`image-card-sub-nav-${
        subNavArgs.colIndex &&
        (!subNavArgs.mobile ? subNavArgs.colIndex : subNavArgs.colIndex + 1)
      }`}
      dynamicWidths={subNavArgs.dynamicWidths}
      totalCards={subNavArgs.totalCards}
      mobile={subNavArgs.mobile}
      currentLevel={subNavArgs.currentLevel}
    >
      <StyledCard
        href={subNavArgs.subNav.link?.url}
        data-nav-name={`${subNavArgs.topLevelName}`}
        data-nav-index={`${
          subNavArgs.colIndex &&
          (!subNavArgs.mobile ? subNavArgs.colIndex : subNavArgs.colIndex + 1)
        },${0}`}
        onFocus={() => {
          subNavArgs.focusHandler?.(subNavArgs.colIndex, 0)
        }}
        onClick={subNavArgs.onClick}
      >
        {subNavArgs.subNav.image && (
          <StyledImg
            src={subNavArgs.subNav.image.url}
            loading="lazy"
            alt=""
            currentLevel={subNavArgs.currentLevel}
            height={subNavArgs.height}
          />
        )}
        <StyledLinkText>
          {!subNavArgs.homeCard
            ? subNavArgs.subNav.displayName
            : subNavArgs.i18nHomeText &&
              vsprintf(subNavArgs.i18nHomeText, [
                subNavArgs.subNav.displayName,
              ])}
        </StyledLinkText>
      </StyledCard>
    </SubNavigationLinkWrapper>
  )
}

const NavContent = styled.ul<{ mobile?: boolean }>`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: ${spacing(2)} ${spacing(2)};
  background: ${(props) => props.theme.colors.palette.greys.lighter};
  ${(props) => props.mobile && `margin: -${spacing(1)} 0;`}
  ${(props) => props.mobile && `flex-direction: column;`}
`

interface ImageCardNavigationContentProps {
  item: NavigationItem
  dynamicWidths: boolean
  mobile: boolean
  i18nHomeText?: string
  navItems?: any
  onClick?: () => void
}

export const ImageCardNavigationContent = ({
  item,
  dynamicWidths,
  mobile,
  i18nHomeText,
  navItems,
  onClick,
}: ImageCardNavigationContentProps) => {
  const [activeNavIndex, setActiveNavIndex] = React.useState([-1, -1])

  const onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault()

        const leftElement = document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] - 1
          },${activeNavIndex[1]}"]`,
        ) as HTMLElement
        if (leftElement) {
          leftElement.focus()
        } else if (
          document.querySelector(
            `[data-nav-name="${item.displayName}"][data-nav-index="${
              activeNavIndex[0] - 1
            },0"]`,
          ) as HTMLElement
        ) {
          let closestEl
          let index = activeNavIndex[1] - 1
          while (!closestEl) {
            closestEl = document.querySelector(
              `[data-nav-name="${item.displayName}"][data-nav-index="${
                activeNavIndex[0] - 1
              },${index}"]`,
            ) as HTMLElement
            index -= 1
          }
          closestEl.focus()
        }
        break
      case 'ArrowRight':
        event.preventDefault()

        const rightElement = document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] + 1
          },${activeNavIndex[1]}"]`,
        ) as HTMLElement
        if (rightElement) {
          rightElement.focus()
        } else if (
          document.querySelector(
            `[data-nav-name="${item.displayName}"][data-nav-index="${
              activeNavIndex[0] + 1
            },0"]`,
          ) as HTMLElement
        ) {
          let closestEl
          let index = activeNavIndex[1] - 1
          while (!closestEl) {
            closestEl = document.querySelector(
              `[data-nav-name="${item.displayName}"][data-nav-index="${
                activeNavIndex[0] + 1
              },${index}"]`,
            ) as HTMLElement
            index -= 1
          }
          closestEl.focus()
        }
        break
    }
  }
  const focusHandler = (colIndex: number, rowIndex: number) => {
    setActiveNavIndex([colIndex, rowIndex])
  }

  const navCards = item.subNavigation?.map((subNav, index) =>
    ImageCard({
      subNav,
      focusHandler,
      dynamicWidths,
      mobile,
      i18nHomeText,
      onClick,
      colIndex: index,
      topLevelName: item.displayName,
      totalCards: item.subNavigation?.length || 0,
      homeCard: false,
    }),
  )

  if (navItems) {
    return <ul>{navItems}</ul>
  }

  return (
    <NavContent mobile={mobile} onKeyDown={!mobile ? onKeyDown : () => {}}>
      {mobile &&
        ImageCard({
          dynamicWidths,
          i18nHomeText,
          onClick,
          subNav: item,
          colIndex: 0,
          topLevelName: item.displayName,
          focusHandler: () => {},
          totalCards: 0,
          mobile: true,
          homeCard: true,
        })}
      {navCards}
    </NavContent>
  )
}
