import { css, spacing, styled, Text } from '@thg-commerce/gravity-theme'

const WATERFALL_MENU_HEIGHT = '537px'
const SCROLLABLE_COLUMN_HEIGHT = '472px'

export const DetachedFeatureWrapper = styled.div<{
  showDetachedList?: boolean | undefined
}>`
  width: 50%;
  display: ${(props) => (props.showDetachedList ? 'none' : 'flex')};
  padding: ${spacing(4)} ${spacing(2)};
  background-color: ${(props) =>
    props.showDetachedList
      ? props.theme.colors.palette.greys.white
      : props.theme.colors.palette.greys.lighter};
`

export const DetachedList = styled.ul`
  width: 50%;
  display: flex;
  flex-direction: column;
`

export const DetachedLink = styled.a`
  ${Text('small', 'default')}
  padding: ${spacing(1)} calc(${spacing(2)} - 3px);
  border: solid 1px transparent;
  width: 100%;
  text-decoration: none;
  outline: none;

  &:hover {
    color: ${(props) => props.theme.colors.palette.brand.base};
    text-decoration: underline;
  }
`

export const DetachedListItem = styled.li`
  border: solid 1px transparent;

  &:focus-within {
    border: solid 1px ${(props) => props.theme.colors.palette.greys.darker};
  }
`

export const WaterfallMenuWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: ${spacing(4)} ${spacing(2)};
  height: ${WATERFALL_MENU_HEIGHT};
`

export const HeadingWrapper = styled.li`
  ${Text('bodyText', 'alternate')}
  padding: calc(${spacing(2)} - 3px) 0 ${spacing(3)} 0;
  margin: 0 calc(${spacing(2)} - 3px);
  border-top: solid 2px ${(props) => props.theme.colors.palette.greys.light};
  border-right: solid 2px transparent;
  border-bottom: solid 2px transparent;
  border-left: solid 2px transparent;
  list-style: none;

  &:focus-within {
    border: solid 2px ${(props) => props.theme.colors.palette.greys.darker};
  }
`

export const linkStyling = css`
  display: flex;
  align-items: center;
  padding: ${spacing(1)} ${spacing(2)};
  height: ${spacing(5)};
  width: 100%;
  margin: 0;
  height: ${spacing(5)};
`

export const LinkWrapper = styled.li<{ isActive?: boolean }>`
  ${linkStyling}
  background-color: ${(props) =>
    props.isActive && props.theme.colors.palette.greys.lighter};

  &:hover {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};

    svg {
      display: block;
      fill: ${(props) => props.theme.colors.palette.greys.dark};
    }
  }

  &:focus-within {
    outline: none;
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};

    svg {
      display: block;
      fill: ${(props) => props.theme.colors.palette.greys.dark};
    }
  }

  svg {
    fill: ${(props) => props.isActive && props.theme.colors.palette.greys.dark};
    display: ${(props) => !props.isActive && `none`};
  }
`

export const scrollableColumnStyles = css`
  height: ${SCROLLABLE_COLUMN_HEIGHT};
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${(props) => props.theme.colors.palette.greys.white};
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }
`

export const StyledLevelTwoList = styled.ul`
  width: 50%;
  ${scrollableColumnStyles}
`

export const StyledLevelThreeList = styled.ul<{ showing: boolean }>`
  ${(props) => !props.showing && `display: none;`}
  width: 50%;
  ${scrollableColumnStyles}
`

export const StyledLink = styled.a`
  text-decoration: none;
  outline: none;
  font-weight: bold;
  border: solid 1px transparent;

  &:hover {
    color: ${(props) => props.theme.colors.palette.brand.base};
    text-decoration: underline;
  }
`
