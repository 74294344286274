import * as React from 'react'
export const SvgContentFacebook = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <path
      fill={props.fill || '#333'}
      d="M17.186 16.238V14.04c0-.97.025-2.465.728-3.39.743-.985 1.76-1.65 3.513-1.65 2.853 0 4.055.407 4.055.407l-.565 3.351s-.943-.271-1.823-.271-1.667.315-1.667 1.193v2.558h3.607l-.252 3.273h-3.355v11.374h-4.24V19.511h-2.264v-3.273h2.263z"
    />
  </svg>
)
export default SvgContentFacebook
