import { isUri } from 'valid-url'
import {
  getSafeUrlParametersFromUrlString,
  getSafeUrlParametersFromReq,
} from './urlUtilities'

const sessionSettingsParameters = [
  'shippingcountry',
  'settingsSaved',
  'switchcurrency',
  'countrySelected',
]

export const removeEncodedSessionSettingsParameters = (url: string) => {
  const { returnTo } = getSafeUrlParametersFromUrlString(url, 'returnTo') as {
    returnTo: string
  }

  const returnToUrl = removeSessionSettingParamsFromReturnTo(returnTo)

  const urlObj = new URL(url)

  const urlParams = new URLSearchParams(urlObj.search)
  urlParams.set('returnTo', returnToUrl.toString())

  urlObj.search = urlParams.toString()

  return urlObj.toString()
}

export const redirectToRemoveEncodedParams = (url: string) => {
  const { returnTo } = getSafeUrlParametersFromUrlString(
    url.toString(),
    'returnTo',
  ) as {
    returnTo: string
  }

  if (returnTo && isUri(decodeURIComponent(returnTo))) {
    const returnToUrl = new URL(decodeURIComponent(returnTo))

    const returnToSearchParams = new URLSearchParams(returnToUrl.search)

    if (
      sessionSettingsParameters.some((param) => returnToSearchParams.has(param))
    ) {
      window.location.href = removeEncodedSessionSettingsParameters(url)
    }
  }
}

export const hasSessionSettingParameters = (req) => {
  const params = getSafeUrlParametersFromReq(req, '*')
  return sessionSettingsParameters.some((param) =>
    Object.keys(params).includes(param),
  )
}

export const removeSessionSettingParamsFromReturnTo = (returnTo: string) => {
  const returnToUrl = new URL(decodeURIComponent(returnTo))

  const returnToSearchParams = new URLSearchParams(returnToUrl.search)

  sessionSettingsParameters.forEach((param) => {
    returnToSearchParams.delete(param)
  })

  returnToUrl.search = returnToSearchParams.toString()
  return returnToUrl
}
