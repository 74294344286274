import { BreakpointKey, mq, styled } from '@thg-commerce/gravity-theme'

export enum Visibility {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

export interface BreakpointVisibility {
  xs: Visibility
  sm: Visibility
  md: Visibility
  lg: Visibility
}

export const VisibilityHelper = styled.div<{
  visibilityStatus: BreakpointVisibility
}>`
  ${(props) =>
    Object.entries(props.visibilityStatus).map(([breakpoint, visible]) => {
      return `
        ${mq(props.theme.breakpointUtils.map, breakpoint as BreakpointKey)} {
          display: ${visible === Visibility.HIDE ? 'none' : 'block'};
        }
      `
    })}
`
