import Keyv from 'keyv'

const storageFactory = (namespace: string) => {
  const storage = new Keyv({
    namespace,
    serialize: JSON.stringify,
    deserialize: JSON.parse,
  })
  return storage
}

export const configurationStorage = Object.freeze(
  storageFactory('configuration'),
)

export const themeStorage = Object.freeze(storageFactory('theme'))
