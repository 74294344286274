import * as React from 'react'
export const SvgFacetRemove = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#E6E6E6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m5.138 4.195-.943.943L7.057 8l-2.84 2.84.942.944L8 8.942l2.862 2.862.943-.943L8.943 8l2.862-2.862-.943-.943L8 7.057 5.138 4.195Z"
      fill="#333"
    />
  </svg>
)
export default SvgFacetRemove
