import * as React from 'react'

export const SvgAccountSubscriptionsOutline = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg viewBox="0 0 24 24" {...props} aria-hidden="true" focusable="false">
    <path d="M5.578 6.42l1.51 1.312A6.504 6.504 0 0012 18.499v-1.491l3.335 2.501L12 22.011v-1.512a8.504 8.504 0 01-6.639-13.82l.217-.26zM12 2v1.501a8.504 8.504 0 016.639 13.82l-.217.26-1.51-1.313A6.504 6.504 0 0012 5.501v1.502L8.665 4.5 12 2z" />
  </svg>
)
export default SvgAccountSubscriptionsOutline
