import * as React from 'react'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { useAmp } from 'next/amp'

import { createStore, FetchMode } from '@thg-commerce/enterprise-cache'
import { Footer as FooterComponent } from '@thg-commerce/enterprise-components/Footer'
import { FooterTopLevelNavigationItem } from '@thg-commerce/enterprise-components/Footer/FooterNavigation/FooterNavigationColumn/FooterNavigationColumn'
import { ESIComponent, withESIWrapper } from '@thg-commerce/enterprise-esi'
import { ESIRequestContext } from '@thg-commerce/enterprise-esi/src/types'
import { CopyrightLogo } from '@thg-commerce/gravity-patterns/Footer/theme'

import {
  i18n,
  NextLink,
  Routes,
  useSiteConfig,
  useSiteDefinition,
  useTheme,
} from '../../index'
import pkg from '../../package.json'
import { withCacheConfiguration } from '../cache'

import { Footer as FOOTER_QUERY } from './Footer.graphql'

export interface FooterProps {
  navigation: {
    navigation: {
      topLevel: FooterTopLevelNavigationItem[]
    }
  }
  paymentProviders: string[]
  countryDomainList: [string, string][]
  socialAccounts: {
    url: string
    socialNetwork: string
  }[]
}

export interface FooterInitialProps {
  userAgent: string
  Accreditations: React.FunctionComponent
  flagPath: string
  cookieSettingsHandler: () => void
}

const Footer: ESIComponent<
  FooterProps & FooterInitialProps,
  FooterInitialProps
> = (props) => {
  const theme = useTheme()
  const { brand, siteName, domain } = useSiteDefinition()
  const year = new Date().getFullYear().toString()
  const {
    showFooterSubsiteSelector,
    showOptanonFooterLink,
    hideCopyrightLogo,
    hasFooterCopyrightAboveLogo,
    hasFooterCopyrightIcons,
    useWidgetForAccreditationIcons,
    useIngenuityLogo,
  } = useSiteConfig()

  const copyrightText = i18n('thgcopyright.copyrighttext')

  const footerI18nText = {
    customerEngagement: {
      connectWithUs: {
        text: i18n('footer.social.icons.title.text'),
      },
      newsletterSignUp: {
        text: i18n('account.emailsignup.instruction'),
        buttonText: i18n('footer.newsletter.signup.submit.button.text'),
      },
    },
    copyrightAndPayments: {
      paymentMethods: {
        text: i18n('footer.links.title.pay.securely.with'),
      },
      copyright: {
        text: copyrightText && `${year} ${copyrightText}`,
        ariaMomentumText: i18n('thgcopyright.momentumtext'),
      },
    },
    navigation: {
      countrySelector: {
        selectorLabel: i18n('footer.countryselector.label'),
      },
    },
    footerNotes: {
      footerNotesTermsPolicyText: i18n(
        'footer.footernotes.financial.terms.policy.text',
      ),
    },
  }

  const paymentMethodsI18nText = {
    afterPay: i18n('paymentmethods.afterpay.svg.title'),
    alipay: i18n('paymentmethods.alipay.svg.title'),
    amazonpay: i18n('paymentmethods.amazonpay.svg.title'),
    alipayPlus: i18n('paymentmethods.alipay.plus.svg.title'),
    atome: i18n('paymentmethods.atome.svg.title'),
    americanExpress: i18n('paymentmethods.american.express.svg.title'),
    applePay: i18n('paymentmethods.apple.pay.svg.title'),
    arvato: i18n('paymentmethods.arvato.svg.title'),
    bancontact: i18n('paymentmethods.bancontact.svg.title'),
    clearpay: i18n('paymentmethods.clearpay.svg.title'),
    dinersClub: i18n('paymentmethods.diners.club.svg.title'),
    discover: i18n('paymentmethods.discover.svg.title'),
    elo: i18n('paymentmethods.elo.svg.title'),
    giftCard: i18n('paymentmethods.gift.card.svg.title'),
    googlePay: i18n('paymentmethods.google.pay.svg.title'),
    hiper: i18n('paymentmethods.hiper.svg.title'),
    ideal: i18n('paymentmethods.ideal.svg.title'),
    interac: i18n('paymentmethods.interac.svg.title'),
    jcb: i18n('paymentmethods.jcb.svg.title'),
    kcp: i18n('paymentmethods.kcp.svg.title'),
    klarna: i18n('paymentmethods.klarna.svg.title'),
    laybuy: i18n('paymentmethods.laybuy.svg.title'),
    maestro: i18n('paymentmethods.maestro.svg.title'),
    mastercard: i18n('paymentmethods.mastercard.svg.title'),
    molpay: i18n('paymentmethods.molpay.svg.title'),
    multibanco: i18n('paymentmethods.multibanco.svg.title'),
    one4All: i18n('paymentmethods.one4all.svg.title'),
    openpay: i18n('paymentmethods.openpay.svg.title'),
    paypal: i18n('paymentmethods.paypal.svg.title'),
    paytm: i18n('paymentmethods.paytm.svg.title'),
    primeiropay: i18n('paymentmethods.primeiropay.svg.title'),
    rupay: i18n('paymentmethods.rupay.svg.title'),
    sepa: i18n('paymentmethods.sepa.svg.title'),
    solo: i18n('paymentmethods.solo.svg.title'),
    splitIt: i18n('paymentmethods.splitit.svg.title'),
    spotii: i18n('paymentmethods.spotii.svg.title'),
    tenpay: i18n('paymentmethods.tenpay.svg.title'),
    trustPay: i18n('paymentmethods.trust.pay.svg.title'),
    unionpay: i18n('paymentmethods.unionpay.svg.title'),
    visaDebit: i18n('paymentmethods.visa.debit.svg.title'),
    visaCredit: i18n('paymentmethods.visa.credit.svg.title'),
    visaElectron: i18n('paymentmethods.visa.electron.svg.title'),
    webMoney: i18n('paymentmethods.web.money.svg.title'),
    wechatPay: i18n('paymentmethods.wechat.pay.svg.title'),
    yandex: i18n('paymentmethods.yandex.svg.title'),
    zippay: i18n('paymentmethods.zippay.svg.title'),
    humm: i18n('paymentmethods.humm.svg.title'),
    quadpay: i18n('paymentmethods.quadpay.svg.title'),
    sezzle: i18n('paymentmethods.sezzle.svg.title'),
    newpay: i18n('paymentmethods.newpay.svg.title'),
    upi: i18n('paymentmethods.upi.svg.title'),
    frasersPlus: i18n('paymentmethods.frasersplus.svg.title'),
  }

  const footerRouteLinks = {
    customerEngagement: {
      newsletterSignUp: {
        signUpLink: Routes.NewsletterSignUp,
      },
    },
  }

  const iconDisplayed = () => {
    if (theme.patterns.footer.copyrightAndPayments.copyrightLogo) {
      return theme.patterns.footer.copyrightAndPayments.copyrightLogo
    }
    if (useIngenuityLogo) {
      return CopyrightLogo.ingenuity
    }
    return CopyrightLogo.thg
  }

  return (
    <React.Fragment>
      <FooterComponent
        useWidgetForAccreditationIcons={useWidgetForAccreditationIcons || false}
        userAgent={props.userAgent}
        customerEngagement={{
          connectWithUs: {
            text: footerI18nText.customerEngagement.connectWithUs.text,
            socialAccounts: props?.socialAccounts,
            actionComponent: NextLink,
          },
          newsletterSignUp: {
            text: footerI18nText.customerEngagement.newsletterSignUp.text,
            buttonText:
              footerI18nText.customerEngagement.newsletterSignUp.buttonText,
            signUpLink:
              footerRouteLinks.customerEngagement.newsletterSignUp.signUpLink,
          },
        }}
        navigation={{
          userAgent: props.userAgent,
          currentBrand: brand,
          topLevel: props.navigation?.navigation.topLevel,
          showSubsiteSelector: showFooterSubsiteSelector,
          showCookieSettings: showOptanonFooterLink,
          cookieSettingsHandler: props.cookieSettingsHandler,
          countrySelector: {
            domain,
            countryDomainList: props.countryDomainList,
            flagPath: props.flagPath,
            i18nLabel: footerI18nText.navigation.countrySelector.selectorLabel,
            brand: getBrandFromSiteName(siteName),
          },
          actionComponent: NextLink,
          cookieConsentButtonText: i18n('stickyoptanoncookiebuttonlink'),
        }}
        copyrightAndPayments={{
          paymentMethods: {
            paymentMethodsI18nText,
            text: footerI18nText.copyrightAndPayments.paymentMethods.text,
            paymentProviders: props?.paymentProviders,
          },
          copyright: {
            hasFooterCopyrightAboveLogo,
            hasFooterCopyrightIcons,
            text: footerI18nText.copyrightAndPayments.copyright.text,
            ariaMomentumText:
              footerI18nText.copyrightAndPayments.copyright.ariaMomentumText,
            displayIcon: !hideCopyrightLogo ? iconDisplayed() : undefined,
          },
        }}
        footerNotes={{
          footerNotesTermsPolicyText:
            footerI18nText.footerNotes.footerNotesTermsPolicyText,
        }}
        Accreditations={props.Accreditations}
      />
    </React.Fragment>
  )
}

const getFooterData = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
) => {
  const { data } = await apolloClient.query({
    query: FOOTER_QUERY,
    variables: {
      code: '',
      concessionEnabled: false,
    },
  })
  return data
}

const store = createStore<
  {
    brand: string
    subsite: string
    apolloClient: ApolloClient<NormalizedCacheObject>
  },
  {
    paymentMethods: any
    footer: any
    socialAccounts: any
  }
>({
  key: (args) =>
    `graphql:core:${pkg.version}:${args.brand}:${args.subsite}:Footer`,
  ttlSeconds: 300,
  fetchMode: FetchMode.BACKGROUND_IF_EXPIRED,
  lookup: async (args) => getFooterData(args.apolloClient),
})

Footer.getInitialProps = withCacheConfiguration<
  FooterInitialProps,
  ESIRequestContext<FooterInitialProps & FooterProps>
>(async (context) => {
  const { siteDefinition } = context.config.publicRuntimeConfig

  const data = context.esi
    ? await getFooterData(context.apolloClient)
    : await store.get({
        brand: siteDefinition.brand,
        subsite: siteDefinition.subsite,
        apolloClient: context.apolloClient,
      })

  return {
    ...context.props,
    paymentProviders: data?.paymentMethods,
    navigation: data?.footer,
    socialAccounts: data?.socialAccounts,
    countryDomainMap: data?.countryDomainMap,
    cache: {
      ttl: 600,
      grace: 3600,
    },
  }
})

const getBrandFromSiteName = (siteName: string) => {
  return siteName?.split(' ')[0]
}

const FooterESI = withESIWrapper(Footer, 'enableLayoutESI', 'ENABLE_LAYOUT_ESI')

export { Footer }

export default FooterESI
