import * as React from 'react'
export const SvgDelivery = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M15.97 4v2.002l3.806-.02.176-.001h.022l2.482 4.137.342.57.18.3.022 7.036-1.155-.01a4.006 4.006 0 01-7.751-.017H9.836a4.006 4.006 0 01-7.756 0H1V4h14.97zM5.957 14.992a2.002 2.002 0 100 4.005 2.002 2.002 0 000-4.005zm12.014 0a2.002 2.002 0 100 4.005 2.002 2.002 0 000-4.005zm1-7.009h-4.004v4.006h6.007v-1.002l-2.002-3.004z"
      fill={props.fill || '#333'}
      fillRule="nonzero"
    />
  </svg>
)
export default SvgDelivery
