import * as React from 'react'
export const SvgTelephone = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M9.35 14.65a12.334 12.334 0 003.895 2.634l3.177-3.177c.3-.3.788-.3 1.088 0l3.265 3.264c.3.3.3.788 0 1.088l-.816.816c-1.514 1.514-3.635 1.937-5.985 1.632-2.446-.316-4.883-1.193-7.345-3.536-2.343-2.462-3.22-4.899-3.536-7.345-.305-2.35.118-4.471 1.632-5.985l.816-.816c.3-.3.787-.3 1.088 0L9.893 6.49c.3.3.3.787 0 1.088l-3.177 3.177a12.334 12.334 0 002.633 3.896z"
      fill={props.fill || '#333'}
      fillRule="nonzero"
    />
  </svg>
)
export default SvgTelephone
