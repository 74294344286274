import * as React from 'react'
export const SvgQuadpay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 56.8 21" width={57} height={21} {...props}>
    <path
      fill={props.fill || '#AA8FFF'}
      d="M19.356 6.375l1.775 14.555h17.358L36.713 6.375H19.356z"
    />
    <path
      fill={props.fill || '#1A0826'}
      d="M24.308.821c1.107 1.048 1.258 2.698.339 3.685-.92.985-2.563.935-3.67-.113-1.107-1.051-1.26-2.7-.339-3.686.92-.985 2.561-.935 3.67.114z"
    />
    <path
      fill={props.fill || '#1A0826'}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.68 11.615c-.399-3.273-2.95-5.25-6.416-5.24H38.723l1.774 14.555h5.195l-.354-2.91h5.498c4.32 0 6.302-2.717 5.844-6.403v-.002zm-6.41 2.325l-5.434.005-.427-3.49 5.461.004c1.285.017 1.941.742 2.05 1.743.069.639-.223 1.738-1.65 1.738z"
    />
    <path
      fill={props.fill || '#1A0826'}
      d="M1.278 16.862l.496 4.068h17.342l-.571-4.655h-8.082l-.075-.58 7.45-5.24-.497-4.08H0l.565 4.66h8.1l.073.582-7.46 5.245z"
    />
  </svg>
)
export default SvgQuadpay
