import * as React from 'react'
import { styled } from '@thg-commerce/enterprise-theme'

interface MessageAreaProps {
  message: string
  'aria-live': 'polite' | 'assertive'
  'data-testid'?: string
}

const Message = styled.div`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`

export const MessageArea = (props: MessageAreaProps) => {
  const { message, 'aria-live': ariaLive, 'data-testid': dataTestId } = props

  return (
    <Message
      role="log"
      aria-live={ariaLive}
      aria-relevant="additions"
      aria-atomic="true"
      data-testid={dataTestId}
    >
      {message}
    </Message>
  )
}

export default MessageArea
