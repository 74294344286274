import * as React from 'react'
export const SvgClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill={props.fill || '#333'}
      d="M18.004 4.586L19.418 6l-6.002 6.002L19.414 18 18 19.414l-5.998-5.998L6 19.418l-1.414-1.414 6.002-6.002L4.586 6 6 4.586l6.002 6.002 6.002-6.002z"
    />
  </svg>
)

export default SvgClose
