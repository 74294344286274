import * as React from 'react'

interface WishlistNotificationContextInterface {
  showWishlistNotification: React.MutableRefObject<(() => void) | undefined>
}

export const WishlistNotificationContext = React.createContext<
  WishlistNotificationContextInterface
>({
  showWishlistNotification: { current: () => {} },
})

export const WishlistNotificationProvider = (props: {
  children?: React.ReactNode
}) => {
  const showWishlistNotification = React.useRef<() => void>()

  const value = {
    showWishlistNotification,
  }

  return (
    <WishlistNotificationContext.Provider value={value}>
      {props.children}
    </WishlistNotificationContext.Provider>
  )
}

export const useWishlistNotificationContext = () => {
  return React.useContext(WishlistNotificationContext)
}
