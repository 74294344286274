import * as React from 'react'
export const SvgAccountCredit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={65} height={64} {...props}>
    <path
      fill={props.fill || '#333'}
      fillRule="evenodd"
      d="M51.166 10.698c.062.326.093.656.093.987v1.783h1.391c3.073 0 5.564 2.417 5.564 5.399v7.419h1.429v17.143l-1.429-.001v6.031c0 2.981-2.49 5.398-5.564 5.398H10.921c-3.073 0-5.564-2.417-5.564-5.398V17.956c0-2.601 1.912-4.832 4.547-5.307L44.68 6.378c3.02-.545 5.925 1.39 6.487 4.32zm1.418 5.588H10.987a2.82 2.82 0 00-1.76.61l-.163.14-.162.166-.176.215-.128.19-.086.154a2.586 2.586 0 00-.186.455l-.054.208-.02.1-.02.147c-.012.1-.018.2-.018.302v30.34c0 1.484 1.242 2.687 2.773 2.687h41.597c1.532 0 2.773-1.203 2.773-2.687v-5.885H38.214a2.857 2.857 0 01-2.857-2.857V29.143a2.857 2.857 0 012.857-2.857h17.143v-7.313c0-1.484-1.241-2.687-2.773-2.687zm4.202 12.857H39.643c-.79 0-1.429.64-1.429 1.428v8.572c0 .789.64 1.428 1.429 1.428h17.143V29.143zm-11.19-20c-.141 0-.283.012-.423.035l-25.53 4.25h28.571v-1.626c0-1.469-1.172-2.66-2.617-2.66z"
    />
  </svg>
)
export default SvgAccountCredit
