import * as React from 'react'
export const SvgContactUs = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm1 9h-2v7h2v-7zm-1-5c-.863 0-1.5.65-1.5 1.506C10.5 8.35 11.137 9 12 9s1.5-.65 1.5-1.494C13.5 6.65 12.863 6 12 6z"
      fill={props.fill || '#333'}
      fillRule="nonzero"
    />
  </svg>
)
export default SvgContactUs
