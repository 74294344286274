export enum CookieGroup {
  REQUIRED = 1,
  PERFORMANCE = 2,
  FUNCTIONAL = 3,
  TARGETING = 4,
  PERSONALISATION = 5,
}

export const COOKIE_GROUP_BASKET = CookieGroup.REQUIRED
export const COOKIE_GROUP_CURRENCY = CookieGroup.REQUIRED
export const COOKIE_GROUP_LOCALE = CookieGroup.REQUIRED
export const COOKIE_GROUP_SHIPPING_COUNTRY = CookieGroup.REQUIRED
export const COOKIE_GROUP_CHOSEN_SUBSITE = CookieGroup.REQUIRED
export const COOKIE_GROUP_PRESERVED_REFERER = CookieGroup.REQUIRED
export const COOKIE_GROUP_CHUMEWE_SESS = CookieGroup.PERFORMANCE
export const COOKIE_GROUP_CHUMEWE_USER = CookieGroup.PERFORMANCE
export const COOKIE_GROUP_CONCESSION = CookieGroup.REQUIRED
export const COOKIE_GROUP_AFFILIATE = CookieGroup.PERFORMANCE
export const COOKIE_GROUP_ADVERTISING = CookieGroup.TARGETING
export const COOKIE_GROUP_PERSISTENT_NAVIGATION = CookieGroup.PERSONALISATION
export const COOKIE_GROUP_POWER_REVIEWS = CookieGroup.PERFORMANCE

export interface Cookie {
  name: string
  value: string
  group: CookieGroup
  domain: string
  expires?: Date
  secure?: boolean
  path?: string
  removeSubdomain?: boolean
  encode?: ((val: string) => string) | undefined
}
