import * as React from 'react'
export const LoyaltyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 1C12.3447 4.27581 13.4459 6.84262 15.3038 8.70046C17.1616 10.5583 19.727 11.6581 23 12C19.7713 12.277 17.2059 13.3708 15.3038 15.2814C13.4017 17.1921 12.3169 19.7649 12.0493 23C11.5861 19.5516 10.502 16.9788 8.797 15.2814C7.09203 13.5841 4.49303 12.4903 1 12C4.40256 11.5957 7.00156 10.4959 8.797 8.70046C10.5924 6.90502 11.6601 4.3382 12 1Z"
      fill={props.fill || '#333333'}
    />
  </svg>
)
export default LoyaltyIcon
