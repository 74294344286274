import * as React from 'react'
export const SvgAccountMessage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={65} height={64} {...props}>
    <g fill="#333" fillRule="evenodd">
      <path d="M34.643 7.714c.476.381.714.857.714 1.429 0 .571-.238 1.047-.714 1.428H12.5c-1.808 0-4.155 1.798-4.286 3.572V52c0 1.807 1.798 4.155 3.572 4.286h37.857c1.751 0 4.07-1.858 4.285-3.572.005-.036.005-.513 0-1.428V29.857c.381-.476.858-.714 1.429-.714.571 0 1.048.238 1.429.714v22.429a6.857 6.857 0 01-6.858 6.857H12.214a6.857 6.857 0 01-6.857-6.857V14.571a6.857 6.857 0 016.857-6.857h22.429z" />
      <path
        fillRule="nonzero"
        d="M57.5 7.714c-2.358-2.919-5.967-3.713-8.959-1.447-.05.038-.097.079-.142.122L24.834 29.26c-.202.195-.348.44-.425.71l-3.275 11.407a1.608 1.608 0 001.987 1.99l11.253-3.216a1.61 1.61 0 00.709-.423L56.786 17c2.841-2.43 3.035-6.355.714-9.286zm-2.72 7.24a1.804 1.804 0 00-.129.118L32.67 37.609l-8.742 2.962 2.858-9.285L49.643 9.143c1.428-1.429 3.04-1.342 4.14-.718a5.42 5.42 0 011.576 1.382 3.718 3.718 0 01-.58 5.147z"
      />
    </g>
  </svg>
)
export default SvgAccountMessage
