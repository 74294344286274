import * as React from 'react'
export const SvgGoogle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <defs>
      <path
        id="google_svg__a"
        d="M11.52 2.94c0 3.653-1.31 6.73-3.579 8.82H4.064V8.748a5.522 5.522 0 002.395-3.621H0V.485h11.301a13.7 13.7 0 01.219 2.455z"
      />
      <path
        id="google_svg__c"
        d="M12 10.667c-4.69 0-8.75-2.69-10.724-6.611V.946h4.01C6.228 3.784 8.874 5.894 12 5.894c1.615 0 2.99-.425 4.064-1.145l3.877 3.012C17.956 9.592 15.24 10.667 12 10.667z"
      />
      <path
        id="google_svg__e"
        d="M5.285 8.947l-4.009 3.109A12.007 12.007 0 010 6.666c0-1.935.464-3.769 1.276-5.389h4.01v3.11a7.213 7.213 0 00-.377 2.28c0 .79.136 1.56.376 2.28z"
      />
      <path
        id="google_svg__g"
        d="M12 4.773c-3.125 0-5.77 2.111-6.715 4.947L1.276 6.61A11.997 11.997 0 0112 0c3.235 0 5.95 1.19 8.03 3.125l-3.443 3.442C15.344 5.379 13.76 4.773 12 4.773z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(12 9.333)">
        <mask id="google_svg__b" fill="#fff">
          <use xlinkHref="#google_svg__a" />
        </mask>
        <path
          fill="#318AF5"
          d="M-.027 11.893h11.68V.373H-.027z"
          mask="url(#google_svg__b)"
        />
      </g>
      <g transform="translate(0 13.333)">
        <mask id="google_svg__d" fill="#fff">
          <use xlinkHref="#google_svg__c" />
        </mask>
        <path
          fill="#3DA751"
          d="M1.253 10.773h18.72V.853H1.253z"
          mask="url(#google_svg__d)"
        />
      </g>
      <g transform="translate(0 5.333)">
        <mask id="google_svg__f" fill="#fff">
          <use xlinkHref="#google_svg__e" />
        </mask>
        <path
          fill="#FDB900"
          d="M-.027 12.213h5.44V1.173h-5.44z"
          mask="url(#google_svg__f)"
        />
      </g>
      <mask id="google_svg__h" fill="#fff">
        <use xlinkHref="#google_svg__g" />
      </mask>
      <path
        fill="#EA3E36"
        d="M1.253 9.867h18.88v-9.92H1.253z"
        mask="url(#google_svg__h)"
      />
    </g>
  </svg>
)
export default SvgGoogle
