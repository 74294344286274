import * as React from 'react'
import loadable from '@loadable/component'
import dynamic from 'next/dynamic'
import { v4 as uuid } from 'uuid'

import { Font } from '@thg-commerce/gravity-patterns'
import { styled, useTheme } from '@thg-commerce/gravity-system/theme'
import {
  css,
  padding,
  SpacingBox,
  Text,
  TextEntry,
} from '@thg-commerce/gravity-theme'

import { AccordionThemeInterface } from './theme'

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const ChevronDown = dynamic<React.SVGProps<SVGSVGElement>>(() =>
  import('@thg-commerce/gravity-icons/src/components/ChevronDown').then(
    (mod) => mod.SvgChevronDown,
  ),
)

export enum AccordionBorderPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  UNDER = 'under',
  FULL = 'full',
  NONE = 'none',
}

interface AccordionProps {
  title: React.ReactNode
  subtitleElement?: React.ReactNode
  id: string
  headerTextColor?: string
  textEntry?: TextEntry
  textTransform?: string
  footerTitlePadding?: boolean
  isDark?: boolean
  isOpen?: boolean
  /**  Will allow you to change styling based on focus state, currently forced to brand until artemis implementation is complete */
  focusColour?: 'brand' | 'grey'
  borderPlacement?: AccordionBorderPlacement
  onClick?: (id: string) => void
  onOpenAccordion?: (id: string) => void
  children: React.ReactChildren | React.ReactNode | React.ReactElement | Element
  theme?: AccordionThemeInterface
  focusRef?: React.RefObject<HTMLDivElement>
}

export const Title = styled.label<{
  fontStyle?: Font
  padding?: SpacingBox
  color?: string
  borderPlacement?: AccordionBorderPlacement
  expandedAccordion: boolean
}>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  ${(props) => props.padding && padding(props.padding)};
  ${(props) =>
    Text(
      props.fontStyle?.entry || 'bodyText',
      props.fontStyle?.style || 'alternate',
    )};
  ${(props) =>
    (props.borderPlacement === AccordionBorderPlacement.BOTTOM ||
      props.borderPlacement === AccordionBorderPlacement.UNDER) &&
    `border-bottom: 1px solid ${props.theme.colors.palette.greys.light};`}

  ${(props) =>
    props.borderPlacement === AccordionBorderPlacement.TOP &&
    `border-top: 1px solid ${props.theme.colors.palette.greys.light};
    `}

  ${(props) => props.color && `color: ${props.color};`}

  ${(props) =>
    props.expandedAccordion &&
    props.borderPlacement === AccordionBorderPlacement.UNDER &&
    'border: none;'};
`

const Content = styled.div<{
  padding: SpacingBox
  borderPlacement?: AccordionBorderPlacement
  expandedAccordion: boolean
}>`
  display: none;
  max-height: 0;
  visibility: hidden;
  ${(props) => padding(props.padding)};

  ${(props) =>
    props.expandedAccordion &&
    css`
      display: block;
      max-height: 100%;
      visibility: initial;
      ${props.borderPlacement === AccordionBorderPlacement.UNDER &&
      `border-bottom: 1px solid ${props.theme.colors.palette.greys.dark};`}
    `}
`

const ChevronContainer = styled.div<{
  expandedAccordion: boolean
}>`
  width: 24px;
  height: 24px;
  ${(props) => props.expandedAccordion && 'transform: rotate(180deg);'}
`

const StyledButton = styled.button`
  width: 100%;

  &:focus {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const Accordion: React.FunctionComponent<AccordionProps> = (props) => {
  const [expanded, setExpanded] = React.useState(props.isOpen || false)
  const id = uuid()
  const buttonId = `${id}-button`
  const defaultTheme = useTheme()
  const borderPlacement =
    props.borderPlacement || AccordionBorderPlacement.BOTTOM

  const handleOnClick = () => {
    setExpanded((prevState) => !prevState)
    props.onOpenAccordion &&
      typeof props.onOpenAccordion === 'function' &&
      props.onOpenAccordion(buttonId)
  }
  return (
    <div id={`${props.id}-${id}`} ref={props.focusRef}>
      <StyledButton
        id={buttonId}
        onClick={handleOnClick}
        aria-expanded={expanded}
      >
        <Title
          htmlFor={buttonId}
          color={props.headerTextColor}
          expandedAccordion={expanded}
          borderPlacement={borderPlacement}
          padding={
            props.theme?.title.padding ||
            defaultTheme.components.accordion.title.padding
          }
        >
          {props.title}
          <ChevronContainer expandedAccordion={expanded}>
            {props.theme?.chevronIcon?.svgPath ||
            defaultTheme.components.accordion.chevronIcon?.svgPath ? (
              <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                viewBox={
                  props.theme?.chevronIcon?.viewBox ||
                  defaultTheme.components.accordion.chevronIcon?.viewBox
                }
                width={
                  props.theme?.chevronIcon?.width ||
                  defaultTheme.components.accordion.chevronIcon?.width
                }
                height={
                  props.theme?.chevronIcon?.height ||
                  defaultTheme.components.accordion.chevronIcon?.height
                }
              >
                <path
                  d={
                    props.theme?.chevronIcon?.svgPath ||
                    defaultTheme.components.accordion.chevronIcon?.svgPath
                  }
                  fillRule="evenodd"
                />
              </SvgIcon>
            ) : (
              <ChevronDown aria-hidden="true" focusable="false" />
            )}
          </ChevronContainer>
        </Title>
        {props.subtitleElement}
      </StyledButton>
      <Content
        tabIndex={0}
        expandedAccordion={expanded}
        borderPlacement={borderPlacement}
        padding={
          props.theme?.content.padding ||
          defaultTheme.components.accordion.content.padding
        }
      >
        {props.children}
      </Content>
    </div>
  )
}
