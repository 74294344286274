import * as React from 'react'

import {
  AutocompletePrediction,
  initAutocomplete,
} from '@thg-commerce/enterprise-core'
import {
  FulfilmentMethod,
  StoreOpeningTime,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Input } from '@thg-commerce/gravity-elements'

import { ErrorTypes } from '../../ClickAndCollectModalPresenter/ClickAndCollectModalPresenter'

import { SearchSuggestions } from './SearchSuggestions'
import {
  DefaultStockMessage,
  FindStoresButton,
  InputContainer,
  InputWrapper,
  StyledPlatformMessage,
} from './styles'

export interface SearchProps {
  i18nText: {
    searchLabel: string
    buttonText: string
    stockDefaultMessage: string
    errorMessages: {
      POSTCODE_REQUIRED: string
      NO_RESULTS_FOUND: (_: string) => string
    }
  }
  getClickAndCollectStores: (locationData: string) => void
  setError: React.Dispatch<React.SetStateAction<ErrorTypes | undefined>>
  error: ErrorTypes | undefined
  setSearchLocations: React.Dispatch<
    React.SetStateAction<AutocompletePrediction[]>
  >
  setSearchPerformed: React.Dispatch<React.SetStateAction<boolean>>
  searchPerformed: boolean
  searchLocations?: AutocompletePrediction[]
  currentProductSku: string
  isGoogleMapsLoaded: boolean
  stores?: {
    id: string
    displayName: string
    distance: Number
    fulfilmentMethods: FulfilmentMethod[]
    openingTimes: [StoreOpeningTime, StoreOpeningTime, StoreOpeningTime]
    stock: number
    ranged: boolean
  }[]
}

export const Search = ({
  i18nText,
  getClickAndCollectStores,
  setError,
  setSearchLocations,
  searchLocations,
  error,
  isGoogleMapsLoaded,
  stores,
  setSearchPerformed,
  searchPerformed,
}: SearchProps) => {
  const searchInput = React.useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = React.useState<string>('')

  const [lastInputSearched, setLastInputSearched] = React.useState<string>('')

  React.useEffect(() => {
    const handleUpdateState = (newPredictions: AutocompletePrediction[]) => {
      setSearchLocations(newPredictions)
    }

    initAutocomplete(inputValue, handleUpdateState)
  }, [inputValue, setSearchLocations])

  const handleFindStores = React.useCallback(
    (
      locationData: AutocompletePrediction | string,
      suggestionSearch = false,
    ) => {
      if (typeof locationData === 'string') {
        getClickAndCollectStores(locationData)
      } else {
        getClickAndCollectStores(locationData.description)
        if (suggestionSearch) {
          setInputValue(locationData.description)
          setLastInputSearched(locationData.description)
        }
      }

      setSearchLocations([])
    },
    [getClickAndCollectStores, setSearchLocations],
  )

  const handleFindFirstLocationStores = () => {
    if (
      inputValue !== '' ||
      (searchLocations && searchLocations.length !== 0)
    ) {
      const firstLocation = inputValue || searchLocations?.[0]
      if (firstLocation) {
        handleFindStores(firstLocation, false)
      }
    }
  }

  const availabilitySelectedLocation = sessionStorage.getItem(
    'availabilitySelectedLocation',
  )
    ? JSON.parse(sessionStorage.getItem('availabilitySelectedLocation') || '')
    : null

  React.useEffect(() => {
    if (availabilitySelectedLocation) {
      setInputValue(
        typeof availabilitySelectedLocation === 'object'
          ? availabilitySelectedLocation.displayName
          : availabilitySelectedLocation,
      )
      setLastInputSearched(
        typeof availabilitySelectedLocation === 'object'
          ? availabilitySelectedLocation.displayName
          : availabilitySelectedLocation,
      )
      setSearchPerformed(true)
      handleFindStores(availabilitySelectedLocation, false)
    }
  }, [availabilitySelectedLocation, handleFindStores, setSearchPerformed])

  React.useEffect(() => {
    if (inputValue !== '' && searchPerformed) {
      sessionStorage.setItem(
        'availabilitySelectedLocation',
        JSON.stringify(inputValue),
      )
    } else {
      sessionStorage.removeItem('availabilitySelectedLocation')
    }
  }, [inputValue, searchPerformed])

  return (
    <React.Fragment>
      <InputWrapper>
        <InputContainer>
          <Input
            label={i18nText.searchLabel}
            labelHidden={true}
            bindref={searchInput}
            data-testid="click-and-collect-search-input"
            onChange={(event) => {
              setInputValue(event.target.value)
              setError(undefined)
              if (event.target.value === '') {
                setSearchPerformed(false)
              }

              event.target?.value && isGoogleMapsLoaded
                ? initAutocomplete(event.target.value, setSearchLocations)
                : setSearchLocations([])
            }}
            onKeyPress={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter') {
                event.stopPropagation()
                if (lastInputSearched !== inputValue) {
                  handleFindFirstLocationStores()
                }
              }
            }}
            valueOverride={inputValue}
            clearInputValueHandler={() => {
              setInputValue('')
              setSearchPerformed(false)
              setSearchLocations([])
              setError(undefined)
            }}
          />
          {inputValue !== '' &&
            searchLocations &&
            searchLocations.length !== 0 && (
              <SearchSuggestions
                searchLocations={searchLocations}
                handleFindStores={handleFindStores}
                stores={stores}
              />
            )}
        </InputContainer>
        <FindStoresButton
          data-testid="click-and-collect-search-btn"
          onClick={() => {
            if (inputValue === '') {
              setError(ErrorTypes.POSTCODE_REQUIRED)
            }
            setLastInputSearched(inputValue)

            if (lastInputSearched !== inputValue) {
              handleFindFirstLocationStores()
            }
          }}
        >
          {i18nText.buttonText}
        </FindStoresButton>
      </InputWrapper>
      <DefaultStockMessage>{i18nText.stockDefaultMessage}</DefaultStockMessage>
      <React.Fragment>
        {error === ErrorTypes.POSTCODE_REQUIRED && (
          <StyledPlatformMessage
            type="error"
            text={i18nText.errorMessages[error]}
          />
        )}
        {inputValue !== '' && error === ErrorTypes.NO_RESULTS_FOUND && (
          <StyledPlatformMessage
            type="error"
            text={i18nText.errorMessages[error](inputValue)}
          />
        )}
      </React.Fragment>
    </React.Fragment>
  )
}
