import * as React from 'react'

// src: https://github.com/willmcpo/body-scroll-lock/blob/master/src/bodyScrollLock.js
export const isIOSSafari =
  (typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.platform &&
    (/iP(ad|hone|od)/.test(window.navigator.platform) ||
      (window.navigator.platform === 'MacIntel' &&
        window.navigator.maxTouchPoints > 1))) ||
  false

export const useScrollLock = () => {
  const lockedScrollPosition = React.useRef(0)

  return (lock: boolean, $document: Document, $window: Window) => {
    if (typeof $document !== 'undefined') {
      const htmlElement = $document.body as HTMLElement

      if (lock) {
        lockedScrollPosition.current = $window.scrollY
      }

      htmlElement.style.overflow = lock ? 'hidden' : ''

      const lockActive = htmlElement.style.position === 'fixed'

      if (isIOSSafari) {
        if (lock && !lockActive) {
          htmlElement.style.top = `-${lockedScrollPosition.current}px`
          htmlElement.style.position = 'fixed'
          htmlElement.style.width = '100%'
          htmlElement.style.height = '100%'
        } else if (!lock && lockActive) {
          htmlElement.style.top = '0'
          htmlElement.style.position = ''
          htmlElement.style.width = ''
          htmlElement.style.height = ''
          $window.scrollTo(0, lockedScrollPosition.current)
        }
      }
    }
  }
}
