interface PredictionSubstring {
  length: number
  offset: number
}

export interface AutocompletePrediction {
  description: string
  matched_substrings: PredictionSubstring[]
  place_id: string
  structured_formatting: {
    main_text: string
    main_text_matched_substrings: PredictionSubstring[]
    secondary_text: string
  }
  terms: {
    offset: number
    value: string
  }[]
  types: string[]
  distance_meters?: number
}

export const initAutocomplete = (input: string, updateState: any) => {
  if (typeof window !== 'undefined' && window.google?.maps?.places) {
    if (typeof window['googleAutocompleteSessionToken'] === 'undefined') {
      window[
        'googleAutocompleteSessionToken'
      ] = new window.google.maps.places.AutocompleteSessionToken()
    }
    const autocompleteService = new window.google.maps.places.AutocompleteService()
    const displaySuggestions = function (
      predictions: AutocompletePrediction[] | null,
      status,
    ) {
      // @TODO: [CSBOM-396] Pass window object as a parameter from where Autocomplete is used to avoid assuming it already exists
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK ||
        status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
      ) {
        if (predictions === null) {
          return updateState([])
        }
        updateState(predictions)
      }

      return
    }
    autocompleteService.getPlacePredictions(
      {
        input,
        sessionToken: window['googleAutocompleteSessionToken'],
        componentRestrictions: { country: 'uk' },
      },
      displaySuggestions,
    )
  }
}
