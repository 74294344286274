import * as React from 'react'
export const SvgTenPay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={40} {...props}>
    <path
      fill={props.fill || '#006AB3'}
      d="M31.042 19.552c-2.198.096-4.245 1.97-4.585 4.182-.334 2.216 1.166 3.932 3.36 3.835 2.192-.098 4.243-1.972 4.579-4.186.341-2.211-1.163-3.928-3.354-3.83zm.566 5.422l.006.008c-.995-.36-2.516-.133-3.085.135l-.013.006c.569-1.083.64-2.582.48-3.113.522.212 1.962.3 3.065-.146l.023-.003c-.567 1.083-.638 2.587-.476 3.113z"
    />
    <path
      fill={props.fill || '#EF9612'}
      d="M13.866 20.834c-.029.056-1.715 3.908-2.274 5.181-.114.255-.358.577-.851.599-.014.091-.235 1.364-.256 1.481.145 0 2.206.046 2.831-1.563.726-1.621 2.482-5.579 2.538-5.698h-1.988m-7.961-2.84c-.528 0-1.628.332-1.826 1.337-.339 2.07-1.02 6.19-1.035 6.29H4.87l1.09-6.225h3.558s-1.08 6.14-1.101 6.27h1.866l1.254-7.673H5.905m19.003-3.02c-.04.054-.988 1.436-1.401 2.037-.476.743-1.54.838-1.54.838-.02.108-.273 1.753-.298 1.899.925-.046 1.37-.327 1.37-.327l-1.618 8.837h2.577c.02-.121 1.947-10.804 1.947-10.804l1.66-2.48h-2.697m13.64 0H36.12l-.25 1.406h-7.932l-.98 1.73h.29v-.002h8.3l-.542 2.608c.489.813.703 1.835.529 2.963-.402 2.62-2.698 4.862-5.265 5.24a.604.604 0 01-.251.1s5.877.415 6.814-4.523c.9-4.74 1.106-6.156 1.147-6.388h.743c.021-.13.273-1.52.301-1.667h-.74s.234-1.317.263-1.468"
    />
    <path
      fill={props.fill || '#EF9612'}
      d="M7.698 25.23c.154-1.214.778-4.773.8-4.901h-1.93c-.017.096-.502 3.743-.726 4.49-.261.864-.626 1.358-1.48 1.801-.411.159-.966.285-1.544.342-.048.358-.2 1.247-.216 1.363 2.316-.05 3.796-1.462 3.938-1.552.915 1.308 3.13 1.552 3.245 1.552.012-.085.2-1.258.217-1.374 0 0-2.499-.236-2.304-1.72m11.11-7.238H16.68c-.012.08-.157.933-.157.933H12.73l-.898 1.336h4.476s2.456 1.906-2.64 7.848a.1.1 0 00-.006.031h2.124c.918 0 1.415-.312 1.524-.959l1.129-6.92h.892c.013-.09.194-1.216.212-1.336h-.887l.153-.933m37.898.133H45.941l-.749 1.19h8.4l-1.407.956h-1.593s-.221-.425-.25-.493h-3.034c.043.106.244.539.244.539h-1.26c-.462 0-1.137.479-1.258 1.127-.33 1.83-.952 5.243-.973 5.341h2.176l.317-1.73h2.153s-.29 1.603-.315 1.73h2.34c.015-.093.314-1.73.314-1.73h1.877l.297-1.141h-1.9l.128-.677h1.92l.327-1.19h-2.034l.125-.678h2.227v-.004s1.807 1.338-1.759 5.468h1.657c.81.03 1.251-.23 1.356-.793l1.034-5.674h-1.167s1.344-1.135 1.376-1.16c.006-.032.177-.963.197-1.081zm-7.785 5.742h-2.153l.114-.63h2.152l-.113.63zm.334-1.82h-2.154l.11-.63h2.157l-.113.63z"
    />
    <path
      fill={props.fill || '#EF9612'}
      d="M43.242 26.981a.802.802 0 01-.255-.59 1.25 1.25 0 01.022-.254l1.222-6.59h-2.898c-.018.088-.196 1.074-.217 1.193h.74l-1.164 6.256c-.017.158-.086.234-.178.286-.076.04-.489.04-.489.04-.014.09-.183 1.029-.206 1.144h1.328c.29 0 .559-.097.813-.307.254-.21.376-.053.553.038.322.149.748.242 1.257.27l11.67.034c.016-.088.159-1.016.178-1.13h-10.95c-.654-.018-1.165-.15-1.426-.39"
    />
    <path
      fill={props.fill || '#8A9094'}
      d="M3.414 16.722l.214-1.667h-.67l.044-.34h1.793l-.044.34h-.668l-.214 1.667h-.455M4.83 16.722l.258-2.007h1.671l-.044.34H5.499l-.057.444h1.133l-.044.338H5.399l-.071.546h1.261l-.044.339H4.83M6.936 16.722l.259-2.007h.442l.751 1.339.172-1.339h.423l-.258 2.007h-.458l-.74-1.309-.168 1.309h-.423M9.205 16.722l.258-2.007h.731c.277 0 .457.01.538.03.125.03.226.096.3.197.074.1.1.23.08.39a.657.657 0 01-.333.51.799.799 0 01-.248.094c-.111.019-.27.028-.475.028H9.76l-.098.758h-.456zm.67-1.667l-.072.57h.249c.18 0 .3-.011.362-.033a.345.345 0 00.156-.099.278.278 0 00.072-.155.22.22 0 00-.049-.181.284.284 0 00-.172-.089 2.427 2.427 0 00-.326-.013h-.22zm3.232 1.667h-.496l-.138-.456h-.9l-.245.456h-.484l1.138-2.007h.481l.644 2.007zm-.737-.795l-.214-.744-.4.744h.614zM13.65 16.722l.109-.845-.675-1.162h.532l.429.794.622-.794h.525l-.979 1.165-.108.842h-.455M14.806 16.995l.085-.659h.739l-.084.659h-.74M17.224 15.983l.424.125a1.04 1.04 0 01-.4.488c-.17.105-.376.16-.62.16-.299 0-.533-.091-.703-.273-.17-.183-.233-.431-.193-.747.043-.334.174-.593.393-.778.215-.185.483-.277.8-.277.274 0 .489.072.643.218a.658.658 0 01.183.37l-.463.095a.35.35 0 00-.136-.246.459.459 0 00-.297-.09.61.61 0 00-.426.159c-.119.107-.193.28-.224.52-.033.253-.004.433.084.54a.465.465 0 00.38.162c.12 0 .228-.035.324-.103a.678.678 0 00.23-.323m.772-.252c.026-.205.084-.376.17-.515a1.139 1.139 0 01.559-.456c.148-.052.313-.079.497-.079.332 0 .587.092.762.275.175.183.243.438.2.765-.04.324-.174.578-.394.76-.222.184-.498.274-.83.274-.334 0-.59-.09-.765-.272-.172-.183-.24-.434-.199-.752zm.471-.014c-.029.227.008.4.11.516a.538.538 0 00.426.176c.182 0 .34-.058.47-.174.131-.117.211-.29.241-.523.03-.23-.005-.402-.103-.515-.098-.113-.241-.17-.43-.17a.688.688 0 00-.475.173c-.13.113-.21.287-.239.517zM20.405 16.722l.258-2.007h.681l.232 1.369.582-1.369h.683l-.258 2.007h-.424l.203-1.58-.65 1.58h-.439l-.242-1.58-.204 1.58h-.422"
    />
    <path
      fill={props.fill || '#006AB3'}
      d="M13.571 13.828s9.466-3.141 18.102-2.47c7.463.581 9.889 4.531 9.998 6.36h3.047s-.541-6.637-11.066-7.55c-9.855-.855-20.08 3.66-20.08 3.66"
    />
  </svg>
)
export default SvgTenPay
