import * as React from 'react'
import withHydrationOnDemand from 'react-hydration-on-demand'
import Media from 'react-media'
import loadable from '@loadable/component'
import ResizeObserver from 'resize-observer-polyfill'

import { FlyoutPresenter } from '@thg-commerce/enterprise-components/NewHeader/components'
import { RenderAnnouncerType } from '@thg-commerce/gravity-elements'
import { AccountIcon } from '@thg-commerce/gravity-icons/src'
import { CombinedThemeInterface } from '@thg-commerce/gravity-patterns'
import { FlyoutDirection } from '@thg-commerce/gravity-patterns/Header/theme'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'
import { isMobileDevice } from '@thg-commerce/gravity-system'
import { mq } from '@thg-commerce/gravity-theme'

import { flyoutIcons, FlyoutMenuI18nText } from './FlyoutMenu/FlyoutMenu'
import { NavigationEventData } from './Navigation/Navigation'
import { BrandsProps } from './Navigation/NavigationContent/Brands/Brands'
import { Submenu, SubmenuI18nText } from './Submenu/Submenu'
import { AccountDropdown, AccountIconWrapper } from './AccountDropdown'
import { BasketIcon } from './BasketIcon'
import { HeaderDropdown } from './HeaderDropdown'
import { TrendingTerms } from './HeaderSearchForm'
import { HeaderSlots } from './HeaderSlots'
import { SearchI18nText } from './index'
import { MiniBasket, MiniBasketProps } from './MiniBasket'
import { Navigation } from './Navigation'
import { NavigationTree } from './NavigationTree'
import {
  BasketTriggerWrapper,
  HeaderContainer,
  LinkContainer,
  LinkTriggerWrapper,
  LogoBox,
  LogoContainer,
  LogoLink,
  LogoSVGContainer,
  OpenMobileSearchWrapper,
  StoreHeaderLink,
  StoreHeaderLinkWrapper,
  StyledHeader,
  StyledHeaderSearchWrapper,
  SubmenuAccountIcon,
  SubmenuWrapper,
  TriggersWrapper,
} from './styles'
import { DropdownOptions, HeaderSlot, PromotionalProducts } from './types'

const HeaderSearchComponent = loadable(
  () => import('./HeaderSearch').then((mod) => mod.HeaderSearch),
  {
    ssr: true,
  },
)

const HeaderSearch = withHydrationOnDemand({
  on: [['idle']],
  onBefore: HeaderSearchComponent.load,
})(HeaderSearchComponent)

export interface HeaderI18nText {
  searchPlaceholder: string
  logoAriaLabel: string
  accountLabel: string
  basketLabel: string
  searchButtonAriaLabel: string
  menuButtonAriaLabel: string
  closeButtonAriaLabel: string
  clearAriaLabel: string
  basketButtonAriaLabel: string
  accountButtonAriaLabel: string
  headerAriaLabel: string
  wishlistAriaLabel: string
  slotText?: { text: string; url: string }[]
}

interface HeaderRoutes {
  accountHome: string
  basket: string
  listExtension: string
  wishlist: string
}

export type withBasketType = ({
  Component,
}: {
  Component: React.ComponentType<{ basket: MiniBasketProps }>
}) => () => React.ReactElement<{ basket: MiniBasketProps }>

export interface HeaderProps {
  headerPromotionUrl?: string
  headerPromotionUrlTwo?: string
  defaultLocale: string
  i18nText: HeaderI18nText
  routes: HeaderRoutes
  userAgent: string
  isMobile: boolean
  isMobileApp: boolean
  headerRef: React.MutableRefObject<HTMLElement | null>
  headerHeight: number
  submenu: {
    showSessionSettings?: boolean
    sessionSettingsModal: (
      close?: () => void,
      dontRemoveScrollLock?: boolean,
    ) => React.ReactElement
    i18nText: SubmenuI18nText
    sessionSettingsTriggerText: string
    showLinkOne?: boolean
    showLinkTwo?: boolean
    showLinkThree?: boolean
    showLinkFour?: boolean
    flagPath?: string
    submenuSlots: HeaderSlot[]
  }
  useAlternateHeaderGrid: boolean
  headerSlots: HeaderSlot[]
  showResponsiveSubMenuStoreLocator?: boolean
  enableStoreLocatorIconOnMobileHeader?: boolean
  setHeight: (height: number) => void
  navigationTree: NavigationTree
  logoOnClick?: () => void
  logoLink: string
  brands: BrandsProps
  flyoutI18nText: FlyoutMenuI18nText
  headerDropdownI18nText: { closeButtonAriaLabel: string }
  accountNav: (close?: () => void) => React.ReactElement
  withBasket: withBasketType
  wishlistNotification?: React.FunctionComponent
  headerSearch: {
    // TODO: provide type for InstantSearchInjector
    InstantSearchInjector: any /*(props: {
      children: (props: InstantSearchProps) => React.ReactNode
    }) => JSX.Element*/
    searchI18nText: SearchI18nText
    onSubmit: (searchText: string) => void
    autocompleteLink: string
    renderAnnouncer: RenderAnnouncerType
    currency: string
    shippingDestination: string
    onFocus?: (event: React.FocusEvent) => void
    showExposedSearch: boolean
    vipPriceEnabled?: boolean
    showPowerReview: boolean
    promotionalProducts?: PromotionalProducts[] | null
    trendingTerms?: TrendingTerms
    enableTrendingSearch?: boolean
  }
  hasDynamicHeaderDropdown: boolean | undefined
  hasWaterfallMenu: boolean | undefined
  responsiveFlyoutMenuIcons: {
    store?: boolean
    wishlist?: boolean
  }
  sticky: boolean
  enableThreeTierNav?: boolean
  selectTopLevelNavigationItem?: (path: string) => void
  selectedTopLevelNavigationItemIndex?: number
  primaryNavImages?: string[]
  vipPriceEnabled?: boolean
  navigationOnClickCallback?: (data: NavigationEventData) => void
  enableRecentSearches?: boolean
  recentlySearchedTitle?: string
  recentlySearchedText?: string
  clearRecentSearch?: string
  enablePromotionalSearch?: boolean
  enablePersistentSearch?: boolean
  hideThreeTierTab?: boolean
  enableAlternateNavGrid?: boolean
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  hideFocusOnLevelOneNav?: boolean
  enableMasonryGridMenu?: boolean
}

export enum FlyoutIconTypes {
  STORE = 'STORE',
  WISHLIST = 'WISHLIST',
}

export const HeaderLink = (props: {
  text?: string
  ariaLabel?: string
  icon?: React.ReactElement
  url?: string
}) => {
  return (
    <LinkTriggerWrapper>
      <LinkContainer
        data-testid="header-link-container"
        href={props.url}
        aria-label={props.ariaLabel}
      >
        {props.icon && props.icon}
        {props.text}
      </LinkContainer>
    </LinkTriggerWrapper>
  )
}

const BasketDropdown = ({
  isDesktop,
  basket,
  ...props
}: HeaderProps & {
  isDesktop: boolean
  basket: MiniBasketProps
  theme: CombinedThemeInterface
}) => {
  return (
    <BasketTriggerWrapper>
      <HeaderDropdown
        i18nAriaLabels={{
          close: props.headerDropdownI18nText.closeButtonAriaLabel,
          trigger: props.i18nText.basketButtonAriaLabel,
        }}
        isMobile={!isDesktop}
        dropdownWidth={props.theme.patterns.header.dropdowns.basket.width}
        trigger={{
          icon: (
            <BasketIcon totalQuantity={basket?.basketData?.totalQuantity} />
          ),
          text: props.i18nText.basketLabel,
          triggerHref: props.routes.basket,
        }}
        disableFocusLogic={false}
        dropdownType={DropdownOptions.BASKET}
        content={(close) => (
          <MiniBasket
            close={close}
            isMobile={!isDesktop}
            locale={props.defaultLocale}
            links={basket.links}
            basketData={basket.basketData}
            i18nText={basket.i18nText}
          />
        )}
      />
    </BasketTriggerWrapper>
  )
}

export const Header = (props: HeaderProps) => {
  const theme = useTheme()
  const { headerRef, setHeight, ...otherProps } = props

  React.useEffect(() => {
    let resizeObserver: ResizeObserver | undefined
    if (headerRef?.current) {
      resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        const { height } = entries[0]?.contentRect
        setHeight(height)
      })
      resizeObserver.observe(headerRef.current)
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [headerRef, setHeight])

  const headerTheme = theme.patterns.header
  const storeIcon = flyoutIcons(
    headerTheme.flyout.storeIcon,
    otherProps.i18nText.slotText?.[1]?.text,
    otherProps.i18nText.slotText?.[1]?.url,
  )
  const accountDropdownProps = {
    trigger: {
      icon: (
        <AccountIconWrapper>
          {headerTheme.dropdowns.account.icon.svgPath !== '' ? (
            <SubmenuAccountIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 47.43 47.43"
            >
              <path d={headerTheme.dropdowns.account.icon.svgPath}></path>
            </SubmenuAccountIcon>
          ) : (
            <AccountIcon />
          )}
        </AccountIconWrapper>
      ),
      text: otherProps.i18nText.accountLabel,
      triggerHref: otherProps.routes.accountHome,
    },
    dropdownWidth: headerTheme.dropdowns.account.width,
    content: otherProps.accountNav,
    i18nAriaLabels: {
      close: otherProps.headerDropdownI18nText.closeButtonAriaLabel,
      trigger: otherProps.i18nText.accountButtonAriaLabel,
    },
    disableFocusLogic: false,
    notification: otherProps.wishlistNotification,
    dropdownType: DropdownOptions.ACCOUNT,
  }

  const exposedSearch = (
    <OpenMobileSearchWrapper data-test-id="exposed-search">
      <HeaderSearch
        currency={otherProps.headerSearch.currency}
        shippingDestination={otherProps.headerSearch.shippingDestination}
        isMobile={
          otherProps.headerSearch.showExposedSearch
            ? false
            : otherProps.isMobile
        }
        showExposedSearch={otherProps.headerSearch.showExposedSearch}
        showPowerReview={otherProps.headerSearch.showPowerReview}
        headerSearchI18nText={{
          placeholder: otherProps.i18nText.searchPlaceholder,
          searchButtonAriaLabel: otherProps.i18nText.searchButtonAriaLabel,
          clearAriaLabel: otherProps.i18nText.clearAriaLabel,
        }}
        InstantSearchInjector={otherProps.headerSearch.InstantSearchInjector}
        searchI18nText={otherProps.headerSearch.searchI18nText}
        onSubmit={otherProps.headerSearch.onSubmit}
        autocompleteLink={otherProps.headerSearch.autocompleteLink}
        renderAnnouncer={otherProps.headerSearch.renderAnnouncer}
        triggerAriaLabels={{
          close: otherProps.headerDropdownI18nText.closeButtonAriaLabel,
          search: otherProps.i18nText.searchButtonAriaLabel,
        }}
        mobileIcon={headerTheme.search.mobileIcon}
        onFocus={otherProps.headerSearch.onFocus}
        mobileSearch={!otherProps.isMobile && headerTheme.mobileSearch}
        slimHeader={false}
        vipPriceEnabled={props?.headerSearch.vipPriceEnabled ?? null}
        enableRecentSearches={otherProps.enableRecentSearches}
        recentlySearchedTitle={otherProps.recentlySearchedTitle}
        recentlySearchedText={otherProps.recentlySearchedText}
        clearRecentSearch={otherProps.clearRecentSearch}
        promotionalProducts={otherProps.headerSearch?.promotionalProducts}
        enablePromotionalSearch={otherProps.enablePromotionalSearch}
        trendingTerms={otherProps.headerSearch.trendingTerms}
        enableTrendingSearch={otherProps.headerSearch.enableTrendingSearch}
        enablePersistentSearch={otherProps.enablePersistentSearch}
      />
    </OpenMobileSearchWrapper>
  )

  return (
    <Media
      query={mq(theme.breakpointUtils.map, 'lg', true)}
      defaultMatches={!isMobileDevice(otherProps.userAgent)}
    >
      {(isDesktop) => {
        const BasketHOC = otherProps.withBasket({
          Component: ({ basket }) => (
            <BasketDropdown
              basket={basket}
              isDesktop={isDesktop}
              theme={theme}
              {...props}
            />
          ),
        })

        const AccountDropdownHOC = (
          <AccountDropdown {...props} isDesktop={isDesktop} />
        )

        return (
          <React.Fragment>
            <StyledHeader
              ref={headerRef}
              sticky={otherProps.sticky}
              aria-label={otherProps.i18nText.headerAriaLabel}
            >
              <SubmenuWrapper data-testid="submenu-wrapper">
                <Submenu
                  isDesktop={isDesktop}
                  {...otherProps.submenu}
                  accountDropdownProps={{
                    isMobile: !isDesktop,
                    ...accountDropdownProps,
                  }}
                />
              </SubmenuWrapper>
              <HeaderContainer>
                <FlyoutPresenter
                  data-testid="flyout"
                  isDesktop={isDesktop}
                  headerPromotionUrl={otherProps.headerPromotionUrl}
                  headerPromotionUrlTwo={otherProps.headerPromotionUrlTwo}
                  i18nText={otherProps.i18nText}
                  headerDropdownI18nText={otherProps.headerDropdownI18nText}
                  navigationTree={otherProps.navigationTree}
                  flyoutI18nText={otherProps.flyoutI18nText}
                  routes={otherProps.routes}
                  accountNav={otherProps.accountNav}
                  submenu={otherProps.submenu}
                  headerSlots={otherProps.headerSlots}
                  responsiveFlyoutMenuIcons={
                    otherProps.responsiveFlyoutMenuIcons
                  }
                  navSubLevelPromotionList={otherProps.navSubLevelPromotionList}
                  navLevelThreePromotionList={
                    otherProps.navLevelThreePromotionList
                  }
                  flyoutIcon={headerTheme.flyout.icon.svgPath}
                  burgerIconColor={headerTheme.navigation.burgerIconColor}
                  flyoutMenuIcon={headerTheme.flyout.icon}
                  flyoutDirection={FlyoutDirection.LEFT}
                  accountDropdownProps={{
                    isMobile: !isDesktop,
                    ...accountDropdownProps,
                  }}
                  enableThreeTierNav={otherProps.enableThreeTierNav}
                  selectTopLevelNavigationItem={
                    otherProps.selectTopLevelNavigationItem
                  }
                  homeButtonOnClick={otherProps.logoOnClick}
                  primaryNavImages={otherProps.primaryNavImages}
                  selectedTopLevelNavigationItemIndex={
                    otherProps.selectedTopLevelNavigationItemIndex
                  }
                  navigationOnClickCallback={
                    otherProps.navigationOnClickCallback
                  }
                  hideThreeTierTab={props.hideThreeTierTab}
                  hideFocusOnLevelOneNav={props.hideFocusOnLevelOneNav}
                />
                <LogoContainer
                  mobileOrder={headerTheme.headerLogo.mobile.order}
                  desktopOrder={headerTheme.headerLogo.desktop.order}
                >
                  <LogoBox data-testid="logo-box">
                    <LogoLink
                      tabIndex={isDesktop ? 0 : 4}
                      aria-label={otherProps.i18nText.logoAriaLabel}
                      data-testid="logo-link"
                      href={otherProps.logoLink}
                    >
                      {theme.logo.logoUri && (
                        <LogoSVGContainer
                          width="100%"
                          height="100%"
                          type="default"
                        >
                          <image
                            href={theme.logo.logoUri}
                            width="100%"
                            height="100%"
                          />
                        </LogoSVGContainer>
                      )}
                      {theme.logo.logoAlternateUri && (
                        <LogoSVGContainer
                          width="100%"
                          height="100%"
                          type="alternate"
                        >
                          <image
                            href={theme.logo.logoAlternateUri}
                            width="100%"
                            height="100%"
                          />
                        </LogoSVGContainer>
                      )}
                      {!theme.logo.logoUri && !theme.logo.logoAlternateUri && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: theme.logo.logoSVG || '',
                          }}
                        />
                      )}
                    </LogoLink>
                  </LogoBox>
                </LogoContainer>
                {otherProps.enableStoreLocatorIconOnMobileHeader && (
                  <StoreHeaderLinkWrapper
                    mobileOrder={headerTheme.search.order.mobile}
                  >
                    <StoreHeaderLink
                      aria-label={
                        storeIcon[FlyoutIconTypes.STORE].i18nText.ariaLabel
                      }
                      href={storeIcon[FlyoutIconTypes.STORE].i18nText.url}
                    >
                      {storeIcon[FlyoutIconTypes.STORE].svgPathElement}
                    </StoreHeaderLink>
                  </StoreHeaderLinkWrapper>
                )}
                <StyledHeaderSearchWrapper
                  enableStoreIconOnMobile={
                    otherProps.enableStoreLocatorIconOnMobileHeader
                  }
                  mobileOrder={headerTheme.search.order.mobile}
                  desktopOrder={headerTheme.search.order.desktop}
                  alignment={headerTheme.search.alignment}
                  desktopFlex={headerTheme.search.flex}
                >
                  <HeaderSearch
                    enablePersistentSearch={otherProps.enablePersistentSearch}
                    currency={otherProps.headerSearch.currency}
                    shippingDestination={
                      otherProps.headerSearch.shippingDestination
                    }
                    isMobile={!isDesktop}
                    headerSearchI18nText={{
                      placeholder: otherProps.i18nText.searchPlaceholder,
                      searchButtonAriaLabel:
                        otherProps.i18nText.searchButtonAriaLabel,
                      clearAriaLabel: otherProps.i18nText.clearAriaLabel,
                    }}
                    showExposedSearch={
                      otherProps.headerSearch.showExposedSearch
                    }
                    showPowerReview={otherProps.headerSearch.showPowerReview}
                    InstantSearchInjector={
                      otherProps.headerSearch.InstantSearchInjector
                    }
                    searchI18nText={otherProps.headerSearch.searchI18nText}
                    onSubmit={otherProps.headerSearch.onSubmit}
                    autocompleteLink={otherProps.headerSearch.autocompleteLink}
                    renderAnnouncer={otherProps.headerSearch.renderAnnouncer}
                    triggerAriaLabels={{
                      close:
                        otherProps.headerDropdownI18nText.closeButtonAriaLabel,
                      search: otherProps.i18nText.searchButtonAriaLabel,
                    }}
                    mobileIcon={headerTheme.search.mobileIcon}
                    onFocus={otherProps.headerSearch.onFocus}
                    mobileSearch={false}
                    slimHeader={false}
                    vipPriceEnabled={
                      props?.headerSearch.vipPriceEnabled ?? null
                    }
                    enableRecentSearches={otherProps.enableRecentSearches}
                    recentlySearchedTitle={otherProps.recentlySearchedTitle}
                    recentlySearchedText={otherProps.recentlySearchedText}
                    clearRecentSearch={otherProps.clearRecentSearch}
                    promotionalProducts={
                      otherProps.headerSearch?.promotionalProducts
                    }
                    enablePromotionalSearch={otherProps.enablePromotionalSearch}
                    trendingTerms={otherProps.headerSearch.trendingTerms}
                    enableTrendingSearch={
                      otherProps.headerSearch.enableTrendingSearch
                    }
                  />
                </StyledHeaderSearchWrapper>
                <TriggersWrapper
                  mobileOrder={headerTheme.dropdowns.order.mobile}
                  desktopOrder={headerTheme.dropdowns.order.desktop}
                  isMobile={!isDesktop}
                >
                  <HeaderSlots
                    headerType="header"
                    i18nText={otherProps.i18nText.slotText}
                    slotConfig={otherProps.headerSlots}
                    link={<HeaderLink />}
                    accountDropdown={AccountDropdownHOC}
                    basketDropdown={<BasketHOC />}
                  />
                </TriggersWrapper>
              </HeaderContainer>
              <Navigation
                headerPromotionUrl={otherProps.headerPromotionUrl}
                headerPromotionUrlTwo={otherProps.headerPromotionUrlTwo}
                brandsData={otherProps.brands.brandsData}
                routes={{
                  viewAllBrandsLink: otherProps.brands.viewAllBrandsLink,
                  listExtension: otherProps.routes.listExtension,
                }}
                navHeaderHeight={otherProps.headerHeight}
                navigationTree={otherProps.navigationTree}
                hasDynamicHeaderDropdown={otherProps.hasDynamicHeaderDropdown}
                hasWaterfallMenu={otherProps.hasWaterfallMenu}
                i18nText={{
                  categoryHomeText:
                    otherProps.flyoutI18nText.linkWithChildrenLabel,
                  viewAllBrandsText: otherProps.brands.i18nText.viewAllBrands,
                  navAriaLabel: otherProps.brands.i18nText.navAriaLabel,
                }}
                navWrapperHeight={headerTheme.navigation.height}
                navigationOnClickCallback={otherProps.navigationOnClickCallback}
                enableAlternateNavGrid={otherProps.enableAlternateNavGrid}
                enableMasonryGridMenu={otherProps.enableMasonryGridMenu}
                navSubLevelPromotionList={otherProps.navSubLevelPromotionList}
                navLevelThreePromotionList={
                  otherProps.navLevelThreePromotionList
                }
              />
            </StyledHeader>
            {otherProps.headerSearch.showExposedSearch && exposedSearch}
          </React.Fragment>
        )
      }}
    </Media>
  )
}

export default Header
