import * as React from 'react'
export const SvgCircularWhatsapp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={20} cy={20} r={20} fill="#25D366" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M32 19.204c0 6.188-5.055 11.204-11.291 11.204-1.98 0-3.84-.506-5.458-1.394L9 31l2.038-6.011a11.086 11.086 0 01-1.62-5.785C9.418 13.016 14.473 8 20.709 8 26.946 8 32 13.016 32 19.204zm-11.291-9.42c-5.235 0-9.493 4.226-9.493 9.42a9.32 9.32 0 001.808 5.522l-1.186 3.499 3.649-1.16a9.487 9.487 0 005.222 1.558c5.234 0 9.493-4.225 9.493-9.419 0-5.194-4.258-9.42-9.493-9.42zm5.702 12c-.07-.114-.254-.183-.53-.32-.278-.138-1.639-.802-1.892-.893-.254-.092-.439-.138-.623.137s-.715.893-.877 1.076c-.161.184-.322.207-.6.07-.276-.138-1.167-.428-2.225-1.363-.822-.728-1.378-1.627-1.54-1.902-.16-.275-.016-.423.122-.56.125-.123.277-.32.415-.48.139-.161.185-.276.277-.46.093-.182.046-.343-.023-.48-.07-.138-.623-1.489-.854-2.039-.23-.55-.46-.458-.622-.458-.162 0-.346-.023-.53-.023-.185 0-.485.069-.74.344-.253.275-.967.939-.967 2.29 0 1.352.99 2.657 1.13 2.84.138.183 1.914 3.046 4.727 4.146 2.814 1.1 2.814.732 3.322.686.507-.045 1.637-.664 1.868-1.305.23-.642.23-1.191.162-1.306z"
      />
    </g>
  </svg>
)
export default SvgCircularWhatsapp
