import { getRequestObject } from './request'
import { eventDataTypes, getEventObject } from './event'
import { getPropertyObject } from './property'

type columboEventDataArgs = {
  argumentsObj: object
  eventData: eventDataTypes
  requestArgs: {
    client_timestamp: number
    url: string
  }
  attributes?: string[]
  propertyArgs: {
    subsite: string
    siteId: string
    channel: string
  }
  nonce: string
}

export const getColumboEventData = (metricData: columboEventDataArgs) => {
  const { v4: uuid } = require('uuid')
  const columboData = {
    insert_id: uuid(),
    nonce: metricData.nonce,
    args: metricData.argumentsObj,
    attributes: metricData.attributes ?? [],
    experiments: [],
    errors: [],
    device: {}, // Added in deepspace
    event: getEventObject(metricData.eventData),
    property: getPropertyObject(metricData.propertyArgs),
    request: getRequestObject(metricData.requestArgs),
    key: 'columboEvent',
  }
  return columboData
}
