import * as React from 'react'

import { EnterpriseNextAppContext } from '../App/types'
import { Configuration } from '../ConfigurationLoader/types'
import { loggerProvider } from './Logger'
import { Logger } from './types'

export interface WithLoggerComponentProps {
  logger: Logger
}

export interface WithLoggerInitialProps {
  config: Configuration
}

export const withLogger = <ComponentProps, InitialProps>(
  Component: React.ComponentType<WithLoggerComponentProps & ComponentProps> & {
    getInitialProps?: (ctx: EnterpriseNextAppContext) => Promise<InitialProps>
  },
): React.FunctionComponent<
  WithLoggerComponentProps &
    WithLoggerInitialProps &
    ComponentProps &
    InitialProps
> & {
  getInitialProps: (
    ctx: EnterpriseNextAppContext,
  ) => Promise<WithLoggerInitialProps & InitialProps>
} => {
  let logger: Logger | null = null

  const WithLogger = (
    props: WithLoggerComponentProps & WithLoggerInitialProps & ComponentProps,
  ) => {
    if (!logger || !(process as any).browser) {
      const {
        brand,
        subsite,
        originUrl,
      } = props.config.publicRuntimeConfig.siteDefinition
      logger = loggerProvider({ brand, subsite, originUrl })
    }

    return <Component {...props} logger={logger} />
  }

  WithLogger.getInitialProps = async (ctx: EnterpriseNextAppContext) => {
    const { config } = ctx.ctx

    if (config) {
      const {
        brand,
        subsite,
        originUrl,
      } = config.publicRuntimeConfig.siteDefinition
      const logger = loggerProvider({
        brand,
        subsite,
        originUrl,
      })
      ctx.ctx.logger = logger
    }

    let componentProps = {} as InitialProps
    if (Component.getInitialProps) {
      componentProps = await Component.getInitialProps(ctx)
    }

    return {
      ...componentProps,
      config,
    }
  }

  return WithLogger
}
