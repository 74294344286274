import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'
import { ClientBehaviours } from '../../utils'

import { captchaConfiguration } from '../../../graphql/Query/Account/CaptchaConfiguration.graphql'

export const mockResponse = {
  captchaConfiguration: [
    {
      type: 'V2_VISIBLE',
      siteKey: '6Lct1QYUFIB-ZaUrFV1YI5fZs0dOL3F',
      __typename: 'CaptchaConfiguration',
    },
    {
      type: 'V2_INVISIBLE',
      siteKey: '6Lf4fiMUGRkNt_wJnf79ra2LSdFBlTL',
      __typename: 'CaptchaConfiguration',
    },
  ],
}

export const CaptchaConfiguration = (
  client: ApolloClient<NormalizedCacheObject>,
  { behaviours }: { behaviours: ClientBehaviours },
) => async (_, args) => {
  if (behaviours.override) {
    return mockResponse
  }

  const { data } = await client.query({
    variables: args,
    query: captchaConfiguration,
  })

  const dataWithTypeName =
    data &&
    data['captchaConfiguration'] &&
    data['captchaConfiguration'].map((entry) => ({
      ...entry,
      __typename: 'CaptchaConfiguration',
    }))

  return dataWithTypeName
}
