import * as React from 'react'
import {
  styled,
  theme,
  spacing,
  mq,
  elevation,
} from '@thg-commerce/gravity-theme'
import { GridItem } from '../GridItem'
import { GridProps } from '../../'

interface MediaQueryPadding {
  breakpoint
  paddingVertical?
  paddingHorizontal?
}

const warningMessage = (incorrectProp) => {
  console.warn(
    `You may have used ${incorrectProp} incorrectly. ${incorrectProp} if used to represent a set of custom breakpoints, it should be the same length as the breakpoints you have defined, otherwise it should match the default breakpoints defined within @thg-commerce/gravity-theme/grid. Please review our docs for Grid - http://thg.design`,
  )
}

const createPaddingMediaQuery = (mediaQueryArgs: MediaQueryPadding) => {
  return `@media (min-width: ${
    theme.breakpointUtils.map[mediaQueryArgs.breakpoint]
  }px) {
      padding: ${spacing(mediaQueryArgs.paddingVertical || '0')} ${spacing(
    mediaQueryArgs.paddingHorizontal || '0',
  )};
    }`
}

const GridPaddingStyling = (props) => {
  let styles = ``
  const breakpointArray = props.breakpoints
    ? props.breakpoints
    : props.theme.grid.breakpoints

  const paddingVerticalArray =
    typeof props.paddingVertical === 'number'
      ? Array.apply(null, Array(breakpointArray.length)).map(() => {
          return props.paddingVertical
        })
      : props.paddingVertical

  paddingVerticalArray &&
    paddingVerticalArray.length !== breakpointArray.length &&
    warningMessage('paddingVertical')

  const paddingHorizontalArray =
    typeof props.paddingHorizontal === 'number'
      ? Array.apply(null, Array(breakpointArray.length)).map(() => {
          return props.paddingHorizontal
        })
      : props.paddingHorizontal

  paddingHorizontalArray &&
    paddingHorizontalArray.length !== breakpointArray.length &&
    warningMessage('paddingHorizontal')

  for (let index = 0; index < breakpointArray.length; index += 1) {
    const argumentsObject: MediaQueryPadding = {
      breakpoint: breakpointArray[index],
      paddingVertical: paddingVerticalArray ? paddingVerticalArray[index] : 0,
      paddingHorizontal: paddingHorizontalArray
        ? paddingHorizontalArray[index]
        : 0,
    }
    styles = `${styles} ${createPaddingMediaQuery(argumentsObject)}`
  }
  return styles
}

const GridContainer = styled(GridItem)<GridProps>`
  box-sizing: content;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: ${(props) =>
    `1fr `.repeat(props.columns || props.theme.grid.columnCount)};
  grid-template-columns: repeat(
    ${(props) => props.columns || props.theme.grid.columnCount},
    [col] 1fr
  );
  -ms-grid-rows: ${(props) =>
    `1fr `.repeat(props.rows || props.theme.grid.rowCount)};
  grid-template-rows: repeat(
    ${(props) => props.rows || props.theme.grid.rowCount},
    [row] 1fr
  );

  ${(props) => GridPaddingStyling(props)}

  grid-gap: 
    ${(props) =>
    elevation.elevationActive
      ? `${props.theme.grid.columnGap}`
      : `calc(${props.theme.grid.columnGap} / 2)`}
    ${(props) => props.theme.grid.rowGap};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    grid-gap: calc(${(props) => props.theme.grid.columnGap} / 2)
      calc(${(props) => props.theme.grid.rowGap} * 2);
  }
`

export const Grid = (props: GridProps) => {
  return (
    <GridContainer
      {...(props.forwardedRef && { gridItemRef: props.forwardedRef })}
      {...props}
    >
      {props.children}
    </GridContainer>
  )
}

export default Grid
