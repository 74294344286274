import * as React from 'react'
export const SvgAddressBook = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 21V3H5v18h15zM7 5h11v14H7V5z"
      fill={props.fill || '#333333'}
    />
    <path
      fill={props.fill || '#333333'}
      d="M3 6h4v1.5H3zM3 9.334h4v1.5H3zM3 12.666h4v1.5H3zM3 16.5h4V18H3zM15.65 12.237V16.5H13.4v-2.368c0-.524-.403-.948-.9-.948s-.9.424-.9.948V16.5H9.35v-4.263H8L12.5 7.5l4.5 4.737h-1.35z"
    />
  </svg>
)
export default SvgAddressBook
