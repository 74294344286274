import * as React from 'react'
export const SvgClickAndCollect = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="-10 -10 60 60" {...props}>
    <path
      fill={props.fill || '#4d4f53'}
      d="M0.39,7.0002 C0.175,7.0002 0,7.1742 0,7.3902 L0,7.4002 L0,12.9102 C0,13.1252 0.175,13.3002 0.39,13.3002 L2.46,13.3002 C2.677,13.3052 2.85,13.4832 2.85,13.7002 L2.85,30.5692 C2.85,30.7852 3.025,30.9602 3.24,30.9602 L34.77,30.9602 C34.991,30.9602 35.17,30.7802 35.17,30.5602 L35.17,13.7202 C35.164,13.5042 35.334,13.3262 35.55,13.3202 L35.56,13.3202 L37.56,13.3202 C37.77,13.3252 37.945,13.1602 37.951,12.9502 L37.951,12.9302 L37.951,7.4102 C37.951,7.1942 37.776,7.0202 37.56,7.0202 L0.39,7.0002 Z M17.22,27.5902 C17.22,27.8112 17.041,27.9902 16.82,27.9902 L9.63,27.9902 C9.409,27.9902 9.23,27.8112 9.23,27.5902 L9.23,27.5802 L9.23,16.4002 C9.235,16.1812 9.411,16.0052 9.63,16.0002 L16.82,16.0002 C17.039,16.0052 17.215,16.1812 17.22,16.4002 L17.22,27.5902 Z M28.71,27.5902 C28.728,27.7872 28.586,27.9642 28.39,27.9902 L21.04,27.9902 C20.839,27.9642 20.697,27.7812 20.72,27.5802 L20.72,16.4002 C20.702,16.2022 20.843,16.0262 21.04,16.0002 L28.39,16.0002 C28.584,16.0302 28.723,16.2042 28.71,16.4002 L28.71,27.5902 Z"
    />
  </svg>
)
export default SvgClickAndCollect
