import * as React from 'react'
export const SvgReviewStarSelected = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={14} height={14} fill="none" {...props}>
    <path
      d="M7 10.938L2.628 14l1.224-5.25L0 5.25l5.055-.49L7 0l1.945 4.76L14 5.25l-3.852 3.5L11.372 14 7 10.937z"
      fill={props.fill || '#fff'}
    />
  </svg>
)
export default SvgReviewStarSelected
