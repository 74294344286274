import * as Sentry from '@sentry/node'
import {
  Dedupe as DedupeIntegration,
  CaptureConsole as CaptureConsoleIntegration,
} from '@sentry/integrations'

import getConfig from 'next/config'

const Cookie = require('js-cookie')

const setTagFromPublicRuntime = (
  scope: any,
  brand: string,
  subsite: string,
) => {
  scope.setTag('appName', getConfig().publicRuntimeConfig['APP_NAME'])
  scope.setTag('appVersion', getConfig().publicRuntimeConfig['APP_VERSION'])
  scope.setTag('brand', brand)
  scope.setTag('subsite', subsite)
}

const captureAndFlush = async (err) => {
  Sentry.captureException(err)
  // flush is needed after calling captureException to send server side errors to Sentry,
  // otherwise the serverless function will exit before it's sent
  await Sentry.flush(2000)
}

export const sentry = () => {
  const sentryOptions = {
    release: `${getConfig().publicRuntimeConfig['APP_NAME']}@${
      getConfig().publicRuntimeConfig['APP_VERSION']
    }`,
    dsn: getConfig().publicRuntimeConfig['SENTRY_DSN'],
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      // Email link Microsoft Outlook crawler compatibility error
      // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
      'Network Error:',
    ],
    integrations: [
      new DedupeIntegration(),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    sampleRate: 0.8,
  }

  Sentry.init(sentryOptions)

  return {
    Sentry,
    captureExceptionComponentLevel: (
      err: Error,
      brand: string,
      subsite: string,
    ) => {
      Sentry.configureScope((scope) => {
        if (err.message) {
          scope.setFingerprint([err.message])
        }

        if (typeof window !== 'undefined') {
          setTagFromPublicRuntime(scope, brand, subsite)
          const sessionId = Cookie.get('chumewe_user')
          if (sessionId) {
            scope.setUser({ id: sessionId })
          }
          scope.addEventProcessor((event) => {
            event.environment =
              window.location.hostname.indexOf('pl.thehutgroup') === -1
                ? 'production'
                : 'prelive'
            return event
          })
        }
      })

      return typeof window !== 'undefined'
        ? Sentry.captureException(err)
        : captureAndFlush(err)
    },
    captureExceptionPageLevel: (
      err: Error,
      ctx: any,
      brand: string,
      subsite: string,
    ) => {
      Sentry.configureScope((scope) => {
        if (err.message) {
          scope.setFingerprint([err.message])
        }

        if (ctx) {
          const { req, errorInfo } = ctx

          if (typeof window !== 'undefined') {
            setTagFromPublicRuntime(scope, brand, subsite)
            const sessionId = Cookie.get('chumewe_user')
            if (sessionId) {
              scope.setUser({ id: sessionId })
            }
            scope.addEventProcessor((event) => {
              event.environment =
                window.location.hostname.indexOf('pl.thehutgroup') === -1
                  ? 'production'
                  : 'prelive'
              return event
            })
          } else {
            if (req) {
              req.cookies.chumewe_user &&
                scope.setUser({
                  id: req.cookies.chumewe_user,
                })
              scope.addEventProcessor((event) => {
                event.environment =
                  req.url.indexOf('pl.thehutgroup') === -1
                    ? 'production'
                    : 'prelive'
                return event
              })
            }
            setTagFromPublicRuntime(scope, brand, subsite)
          }
          if (errorInfo) {
            Object.keys(errorInfo).forEach((key) =>
              scope.setExtra(key, errorInfo[key]),
            )
          }
        }
      })

      return typeof window !== 'undefined'
        ? Sentry.captureException(err)
        : captureAndFlush(err)
    },
  }
}
export default sentry
