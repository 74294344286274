import * as React from 'react'

export const SvgCookie = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={42} height={42} {...props}>
    <g transform="translate(5 5)">
      <path d="M16 0l.49.008c.818.027 1.639.12 2.47.277a1.881 1.881 0 0 1 .427 3.565.942.942 0 0 0-.563.858c-.001.405.274.765.676.887a1.884 1.884 0 0 1 1.32 2.088l-.067.323c-.032.17-.047.322-.047.463a2.835 2.835 0 0 0 2.823 2.825c.142 0 .294-.015.466-.047l.324-.066a1.882 1.882 0 0 1 2.085 1.316c.123.405.484.68.892.68.367 0 .7-.221.854-.563a1.881 1.881 0 1 1 3.566.426c.187.997.284 1.98.284 2.96-.002 8.827-7.173 15.998-16 16C7.173 31.998.002 24.827 0 16 .002 7.173 7.173.002 16 0zm-.295 1.885C8.054 2.045 1.885 8.312 1.882 16 1.884 23.787 8.213 30.116 16 30.118c7.787-.002 14.116-6.331 14.118-14.118 0-.716-.06-1.438-.176-2.17l-.076-.441-.082.165a2.818 2.818 0 0 1-2.487 1.505 2.815 2.815 0 0 1-2.638-1.85l-.056-.167-.28.058c-.274.05-.535.076-.795.076a4.719 4.719 0 0 1-4.704-4.705c0-.26.025-.52.076-.794l.059-.28-.17-.056a2.812 2.812 0 0 1-1.848-2.634 2.819 2.819 0 0 1 1.506-2.49l.165-.082-.441-.077a14.097 14.097 0 0 0-1.74-.169L16 1.882l-.296.003z" />
      <circle cx="12.133" cy="12.133" r="2.133" />
      <circle cx="10.133" cy="21.133" r="2.133" />
      <circle cx="20.133" cy="22.133" r="2.133" />
    </g>
  </svg>
)

export default SvgCookie
