import * as React from 'react'
export const SvgAccountHelpCentre = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={64} height={64} {...props}>
    <path
      fill={props.fill || '#333'}
      fillRule="evenodd"
      d="M53.423 10.286a5.72 5.72 0 015.72 5.72v31.417a5.72 5.72 0 01-5.72 5.72h-9.209v8.572l-13.571-8.572H10.577a5.72 5.72 0 01-5.72-5.72V16.006a5.72 5.72 0 015.72-5.72h42.846zm.003 2.857H10.574a2.86 2.86 0 00-2.86 2.86v31.423a2.86 2.86 0 002.86 2.86H32l9.444 6.429v-6.429h11.982a2.86 2.86 0 002.86-2.86V16.003a2.86 2.86 0 00-2.86-2.86zM32.257 38.857c.514 0 .957.186 1.329.558.371.371.557.814.557 1.328 0 .514-.186.957-.557 1.329a1.814 1.814 0 01-1.329.557c-.514 0-.957-.186-1.329-.557a1.814 1.814 0 01-.557-1.329c0-.514.186-.957.557-1.328a1.814 1.814 0 011.329-.558zm.314-17.142c.8 0 1.548.119 2.243.357.695.238 1.3.58 1.814 1.028.515.448.92 1 1.215 1.657.295.658.443 1.405.443 2.243 0 .724-.105 1.357-.315 1.9a5.757 5.757 0 01-.885 1.529L35.37 32.2l-.285.257-.176.167a11.42 11.42 0 00-.553.562c-.181.2-.324.41-.429.629a2.497 2.497 0 00-.214.728c-.038.267-.057.6-.057 1V36.8h-2.743v-1.743l.003-.25c.01-.404.048-.75.111-1.035.077-.343.19-.653.343-.929.153-.276.357-.543.615-.8.257-.257.576-.567.957-.928.304-.286.61-.577.914-.872.305-.295.581-.6.829-.914.247-.314.452-.648.614-1 .162-.353.243-.748.243-1.186 0-.438-.081-.843-.243-1.214a3.074 3.074 0 00-.643-.957 2.884 2.884 0 00-.957-.629 3.093 3.093 0 00-1.186-.228c-.933 0-1.69.295-2.271.885-.581.59-.929 1.362-1.043 2.315l-2.914-.286.051-.307c.284-1.511.958-2.71 2.02-3.593 1.133-.943 2.538-1.414 4.214-1.414z"
    />
  </svg>
)
export default SvgAccountHelpCentre
