import { styled, Text, spacing } from '@thg-commerce/gravity-theme'
import { Flex } from '@thg-commerce/gravity-system'

export const ErrorContainer = styled(Flex)`
  ${(props) =>
    Text(props.theme.formgroup.error.scale, props.theme.formgroup.error.style)}
  border-radius: ${spacing(0.5)};
  padding: ${spacing(1)};
  color: ${(props) => props.theme.colors.error.base};
  border: solid 1px ${(props) => props.theme.colors.error.light};
  background-color: ${(props) => props.theme.colors.error.light};
  margin-top: ${spacing(0.5)};
  width: 100%;
`

export const ErrorDescription = styled.span`
  padding: 0 ${spacing(1)};
  align-self: center;
  flex: 1;
`
