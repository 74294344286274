import * as React from 'react'
export const SvgAccountPaymentCards = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg width={64} height={64} {...props}>
    <path
      fill={props.fill || '#333'}
      fillRule="evenodd"
      d="M53.423 10.571a5.72 5.72 0 015.72 5.72V47.71a5.72 5.72 0 01-5.72 5.72H10.577a5.72 5.72 0 01-5.72-5.72V16.29a5.72 5.72 0 015.72-5.72h42.846zm2.863 18.572H7.714V47.71a2.86 2.86 0 002.86 2.86h42.852a2.86 2.86 0 002.86-2.86V29.143zm-2.86-15.714H10.574a2.86 2.86 0 00-2.86 2.86v7.14h48.572v-7.14a2.86 2.86 0 00-2.86-2.86z"
    />
  </svg>
)
export default SvgAccountPaymentCards
