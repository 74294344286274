import * as React from 'react'
export const SvgCircularWhitePinterest = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg width={40} height={40} {...props}>
    <g
      id="social-icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Artboard"
        transform="translate(-605.000000, -397.000000)"
        fill="#FFFFFF"
      >
        <g id="pinterest" transform="translate(605.000000, 397.000000)">
          <circle
            id="Oval-2-Copy"
            opacity="0.402739025"
            cx="20"
            cy="20"
            r="20"
          ></circle>
          <path
            d="M9.63124 0.0936379C5.08603 0.613055 0.55684 4.37746 0.369904 9.75479C0.252402 13.0381 1.16305 15.5012 4.21544 16.1928C5.54001 13.8008 3.78816 13.2732 3.51577 11.5427C2.39682 4.45127 11.5059 -0.384773 16.2728 4.56609C19.5709 7.99424 17.3998 18.5411 12.0801 17.4449C6.98476 16.3979 14.5744 8.00245 10.5072 6.35398C7.20107 5.01443 5.44388 10.4519 7.01147 13.1529C6.09281 17.7976 4.11396 22.1743 4.91512 28C7.51352 26.07 8.38945 22.3739 9.10782 18.5193C10.4137 19.3312 11.1107 20.1759 12.7771 20.3072C18.9219 20.7938 22.3536 14.0277 21.515 7.78648C20.7699 2.25332 15.3755 -0.562468 9.63124 0.0936379Z"
            id="pinterest"
            transform="translate(9, 7)"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgCircularWhitePinterest
