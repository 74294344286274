import * as React from 'react'
export const SvgAccountViewMessage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={64} height={64} {...props}>
    <path
      fill={props.fill || '#333'}
      fillRule="evenodd"
      d="M53.423 10.571a5.72 5.72 0 015.72 5.72V47.71a5.72 5.72 0 01-5.72 5.72H10.577a5.72 5.72 0 01-5.72-5.72V16.29a5.72 5.72 0 015.72-5.72h42.846zm2.863 7.143L34.474 33.111a4.29 4.29 0 01-4.948 0L7.714 17.714v29.997a2.86 2.86 0 002.86 2.86h42.852a2.86 2.86 0 002.86-2.86V17.714zm0-4.285H9.143c-.79 0-1.429.64-1.429 1.428l21.812 15.397a4.29 4.29 0 004.948 0l21.812-15.397V13.43z"
    />
  </svg>
)
export default SvgAccountViewMessage
