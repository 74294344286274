type propArgTypes = {
  subsite: string
  siteId: string
  channel: string
}
export const getPropertyObject = (propertyArgs: propArgTypes) => {
  return {
    site_id: parseInt(propertyArgs.siteId, 10),
    channel: propertyArgs.channel, // channel in rocinante
    subsite: propertyArgs.subsite,
  }
}
