import initApollo from '@thg-commerce/enterprise-network/lib/initApollo'
import { OverrideClient } from '@thg-commerce/enterprise-network/src/ApolloProvider/utils'
import { metricProperties } from '@thg-commerce/enterprise-network/utils/metrics'
import { graphqlUrls } from '@thg-commerce/enterprise-network/utils/urls'

import { Configuration } from '../ConfigurationLoader/types'
import { loggerProvider } from '../Logger/Logger'
import { EnterpriseRequest } from '../Server/types'

export const createApolloWithRequest = (
  req: EnterpriseRequest,
  config: Configuration,
  includeOpaqueToken?: boolean,
) => {
  const { publicRuntimeConfig, serverRuntimeConfig } = config
  const { brand, subsite, originUrl } = publicRuntimeConfig.siteDefinition
  const logger = loggerProvider({ brand, subsite, originUrl })

  const allowOverrides = req.headers['x-enterprise-allow-overrides'] === '1'

  const opaqueCookieKey = `Opaque_${brand}_${subsite}`
  const opaqueToken = includeOpaqueToken
    ? { value: req.cookies[opaqueCookieKey], cookieName: opaqueCookieKey }
    : undefined

  const uris = graphqlUrls(publicRuntimeConfig, serverRuntimeConfig)

  let headers = {}

  if (
    typeof window === 'undefined' &&
    serverRuntimeConfig.siteDefinition.graphqlApi
  ) {
    uris.default = serverRuntimeConfig.siteDefinition.graphqlApi.url
    headers = serverRuntimeConfig.siteDefinition.graphqlApi.headers
  }

  const apollo = initApollo({
    logger,
    uris,
    initialState: {},
    metrics: metricProperties(publicRuntimeConfig),
    setExtensions: () => {},
    timeout: serverRuntimeConfig.APOLLO_TIMEOUT,
    enableRetries: serverRuntimeConfig.APOLLO_ENABLE_RETRIES,
    modifiers: {
      opaqueToken,
      headers,
      ignoreRateLimit: publicRuntimeConfig.IGNORE_RATE_LIMITING,
      ip: req.enterpriseIp,
      chumewe: {
        user: req.cookies.chumewe_user,
        session: req.cookies.chumewe_sess,
      },
      auth: req['auth'],
      overrides: allowOverrides && OverrideClient(publicRuntimeConfig),
    },
    originReq: {
      ip: req.enterpriseIp,
      userAgent: req.headers['user-agent'],
    },
  })

  return apollo
}
