import * as React from 'react'
export const SvgReferralsAccount = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g stroke="none" stroke-width="1">
      <g transform="translate(-92.000000, -1934.000000)">
        <g transform="translate(92.000000, 1887.000000)">
          <g transform="translate(0.000000, 47.000000)">
            <g>
              <path d="M12,0 C8.6862915,0 6,2.6862915 6,6 C6,9.3137085 8.6862915,12 12,12 C15.3137085,12 18,9.3137085 18,6 C18,2.6862915 15.3137085,0 12,0 Z M12,15 C2.85,15 0,21 0,21 L0,24 L24,24 L24,21 C24,21 21.15,15 12,15 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default SvgReferralsAccount
