import * as React from 'react'
import styled from 'styled-components'

import { spacing, Text } from '@thg-commerce/gravity-theme'

import { visuallyHiddenMixin } from '../../VisuallyHidden'

const RequiredLabel = styled.span`
  ${(props) =>
    Text(
      props.theme.formgroup.requiredLabel.scale,
      props.theme.formgroup.requiredLabel.style,
    )}
  position: absolute;
  ${(props) =>
    props.theme.elements?.label?.inputLabel?.textColor &&
    `color : ${props.theme.elements.label.inputLabel.textColor}`}
`
const LabelContent = styled.span<{
  selectedValue: boolean
  required?: boolean
}>`
  ${(props) => props.required && `padding-left: ${spacing(1)};`}
  ${(props) =>
    props.selectedValue
      ? Text(
          props.theme.formgroup.optionalLabel.scale,
          props.theme.formgroup.optionalLabel.style,
        )
      : Text(
          props.theme.formgroup.label.scale,
          props.theme.formgroup.label.style,
        )}
  ${(props) =>
    props.theme.elements?.label?.inputLabel?.textColor &&
    `color : ${props.theme.elements.label.inputLabel.textColor}`}
`
const LabelTextWrapper = styled.span<{
  remainingCharacters?: number
}>`
  ${(props) => props.remainingCharacters && 'justify-content: space-between;'}
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: ${spacing(0.5)};
  color: ${(props) => props.theme.colors.palette.greys.dark};

  &.hidden {
    ${visuallyHiddenMixin}
  }

  &.disabled > span {
    color: ${(props) => props.theme.colors.palette.greys.light};
    cursor: not-allowed;
  }
`

const OptionalLabel = styled.span`
  ${(props) =>
    Text(
      props.theme.formgroup.optionalLabel.scale,
      props.theme.formgroup.optionalLabel.style,
    )}
  margin-left: ${spacing(1)};
`

export interface LabelTextProps {
  children: string
  optionalText?: string
  className?: string
  disabled?: boolean
  required?: boolean
  labelIsSelectedValue?: boolean
  remainingCharacters?: number
  charCount?: number
}

export const LabelText = (props: LabelTextProps) => {
  return (
    <React.Fragment>
      {props.children && (
        <LabelTextWrapper
          data-testid="label-text"
          className={`
            ${props.className}  
            ${props.disabled ? 'disabled' : ''}
          `}
          remainingCharacters={props.remainingCharacters}
        >
          {props.required && (
            <RequiredLabel aria-hidden="true">*</RequiredLabel>
          )}
          <LabelContent
            selectedValue={!!props.labelIsSelectedValue}
            required={props?.required}
          >
            {props.children}
          </LabelContent>
          {!props.required && props.optionalText && (
            <OptionalLabel>({props.optionalText})</OptionalLabel>
          )}
          {props.remainingCharacters && (
            <span>
              {props.charCount}/{props.remainingCharacters}
            </span>
          )}
        </LabelTextWrapper>
      )}
    </React.Fragment>
  )
}

export default LabelText
