import * as React from 'react'

export const SvgTwoItemsPerRowIconSelected = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.75 2.125V5.875C7.75 6.92969 6.89062 7.75 5.875 7.75H2.125C1.07031 7.75 0.25 6.92969 0.25 5.875V2.125C0.25 1.10938 1.07031 0.25 2.125 0.25H5.875C6.89062 0.25 7.75 1.10938 7.75 2.125ZM7.75 12.125V15.875C7.75 16.9297 6.89062 17.75 5.875 17.75H2.125C1.07031 17.75 0.25 16.9297 0.25 15.875V12.125C0.25 11.1094 1.07031 10.25 2.125 10.25H5.875C6.89062 10.25 7.75 11.1094 7.75 12.125ZM10.25 2.125C10.25 1.10938 11.0703 0.25 12.125 0.25H15.875C16.8906 0.25 17.75 1.10938 17.75 2.125V5.875C17.75 6.92969 16.8906 7.75 15.875 7.75H12.125C11.0703 7.75 10.25 6.92969 10.25 5.875V2.125ZM17.75 12.125V15.875C17.75 16.9297 16.8906 17.75 15.875 17.75H12.125C11.0703 17.75 10.25 16.9297 10.25 15.875V12.125C10.25 11.1094 11.0703 10.25 12.125 10.25H15.875C16.8906 10.25 17.75 11.1094 17.75 12.125Z"
      fill="#383838"
    />
  </svg>
)
export default SvgTwoItemsPerRowIconSelected
