import * as React from 'react'

import { spacing, styled } from '@thg-commerce/gravity-theme'

import { AToZ } from './AToZ'
import { BrandsPanel } from './BrandsPanel'

export interface BrandsProps {
  brandsData: { brands: Brand[] }
  i18nText: { viewAllBrands: string; navAriaLabel: string }
  viewAllBrandsLink: string
  listExtension?: string
}

export interface Brand {
  name: string
  page: {
    path: string
  }
}

interface BrandGroup {
  group: string
  brands: Brand[]
}

const BrandsContainer = styled.div`
  padding: ${spacing(2)};
`

export const Brands = (props: BrandsProps) => {
  const orderedBrands =
    props.brandsData.brands &&
    props.brandsData.brands.slice().sort((a, b) => {
      return a['name'].toLowerCase() > b['name'].toLowerCase() ? 1 : -1
    })

  const groups =
    orderedBrands &&
    orderedBrands.map((brand) => {
      return brand.name[0] <= '9' && brand.name[0] >= '0'
        ? '0-9'
        : (brand.name[0] as string)
    })

  const lowerCaseGroups = groups?.map((group) => group.toLowerCase())

  const uniqueGroups = lowerCaseGroups && Array.from(new Set(lowerCaseGroups))

  if (uniqueGroups && uniqueGroups[0] === '0-9') {
    const first = uniqueGroups?.splice(0, 1)
    uniqueGroups.push(first[0])
  }

  const filterBrandsByGroup = (group: string) => {
    if (group <= '9' && group >= '0') {
      return (
        orderedBrands &&
        orderedBrands.filter(
          (brand) => brand.name[0] <= '9' && brand.name[0] >= '0',
        )
      )
    }
    return (
      orderedBrands &&
      orderedBrands.filter((brand) => brand.name[0].toLowerCase() === group)
    )
  }

  const brandGroups: BrandGroup[] = uniqueGroups.map((group) => {
    return {
      group,
      brands: filterBrandsByGroup(group),
    }
  })

  const [group, setGroup] = React.useState<string>(
    uniqueGroups && uniqueGroups[0],
  )

  const selectBrands = (group: string) => {
    setGroup(group)
  }

  const focusCurrentGroup = () => {
    const currentGroup = document.querySelector(
      `[data-group-value="${group}"]`,
    ) as HTMLElement
    if (currentGroup) {
      currentGroup.focus()
    }
  }

  return (
    <BrandsContainer>
      {props.brandsData && (
        <React.Fragment>
          <AToZ
            viewAllBrandsLink={props.viewAllBrandsLink}
            availableGroups={uniqueGroups}
            i18nText={{
              viewAllBrands: props.i18nText.viewAllBrands,
              navAriaLabel: props.i18nText.navAriaLabel,
            }}
            selectBrands={selectBrands}
            selectedGroup={group}
          />
          {brandGroups.map((brandsGroup, index) => {
            const isFocused = brandsGroup.group === group
            return (
              <BrandsPanel
                key={index}
                brands={brandsGroup.brands}
                panelIndex={index}
                isFocused={isFocused}
                focusCurrentGroup={focusCurrentGroup}
                listExtension={props.listExtension}
              />
            )
          })}
        </React.Fragment>
      )}
    </BrandsContainer>
  )
}
