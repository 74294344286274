import { Property } from 'csstype'

import { Font } from '@thg-commerce/gravity-patterns'
import { SpacingBox } from '@thg-commerce/gravity-theme'

export type ChevronIconStylingType = {
  svgPath?: string
  height?: string
  width?: string
  viewBox?: string
  fillColor?: Property.Color
}

export interface AccordionThemeInterface {
  chevronIcon?: ChevronIconStylingType
  title: {
    fontStyle: Font
    padding: SpacingBox
  }
  content: {
    padding: SpacingBox
  }
  hideTitle?: boolean
}

export const theme: AccordionThemeInterface = {
  chevronIcon: {
    svgPath: '',
    width: '24px',
    height: '24px',
    viewBox: '0 0 24 24',
    fillColor: '',
  },
  title: {
    fontStyle: {
      entry: 'bodyText',
      style: 'alternate',
      textDecoration: 'none',
      transform: 'none',
    },
    padding: {
      top: 1,
      bottom: 1,
      right: 1,
      left: 1,
    },
  },
  content: {
    padding: {
      top: 0,
      right: 1,
      bottom: 0,
      left: 1,
    },
  },
}
