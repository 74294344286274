import * as React from 'react'

export const WeChatPay = () => {
  return (
    <svg
      role="img"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="60"
      height="40"
    >
      <defs>
        <path id="a1" d="M0 .105h13.074v11.21H0z" />
        <path id="c1" d="M0 11.32h51.772V.11H0z" />
      </defs>
      <title>WeChat Pay</title>
      <g fill="none" fillRule="evenodd" transform="translate(-300 -370)">
        <g transform="translate(304 385.5)">
          <g transform="translate(0 .006)">
            <mask id="b1" fill="#fff">
              <use xlinkHref="#a1" />
            </mask>
            <path
              fill="#22AB39"
              d="M4.755 7.195a.444.444 0 0 1-.576-.17l-.029-.061-1.19-2.538a.214.214 0 0 1-.02-.09c0-.116.097-.21.217-.21.05 0 .094.015.13.041l1.405.972a.664.664 0 0 0 .58.064l6.605-2.856C10.693.99 8.743.105 6.537.105 2.927.105 0 2.475 0 5.399 0 6.994.88 8.429 2.259 9.4a.42.42 0 0 1 .16.48l-.295 1.068c-.013.05-.035.102-.035.155 0 .116.098.211.218.211a.251.251 0 0 0 .126-.04l1.431-.802a.7.7 0 0 1 .347-.098c.067 0 .131.01.192.028a7.93 7.93 0 0 0 2.134.29c3.61 0 6.537-2.37 6.537-5.293 0-.886-.27-1.72-.744-2.453L4.803 7.169l-.048.026z"
              mask="url(#b1)"
            />
          </g>
          <mask id="d1" fill="#fff">
            <use xlinkHref="#c1" />
          </mask>
          <path
            fill="#232222"
            d="M28.812 4.312h4.904v-.437h-4.904zm0 1.358h4.904v-.46h-4.904zm.599 3.06h3.707V7.005h-3.707V8.73zm-.474.427h4.655V6.578h-4.655v2.579zm-3.047-3.8l.261.437c.276-.397.567-.863.842-1.352v4.983h.474V3.491c.278-.582.524-1.21.725-1.845l-.439-.246c-.433 1.482-1.067 2.83-1.863 3.957m17.817 0l.262.437c.276-.397.567-.863.84-1.352v4.983h.475V3.491c.278-.582.524-1.21.725-1.845L45.57 1.4c-.432 1.482-1.067 2.83-1.863 3.957M31.656 2.336l.01-.006-.004-.011c-.035-.08-.127-.267-.224-.465-.09-.184-.184-.375-.229-.472l-.006-.014-.437.245.006.012c.157.286.291.548.411.801a.343.343 0 0 1 .032.08h-2.883v.462h5.9v-.461H31.35l.306-.171zm-10.079-.179h-.44v1.502h-.83V1.523h-.44V3.66h-.865V2.157h-.44v1.93h3.015zM18.7 5.364h2.671v-.437H18.7zm3.258 2.25l-.212-.357-.009-.014-.674.562v-1.61h-2.26v.648c.064.888-.164 1.523-.678 1.888l-.01.007.244.412.009.014.012-.01c.64-.513.942-1.234.897-2.145v-.387h1.346v.954c-.017.332-.034.434-.152.593l-.005.007.27.454.013-.01c.237-.184.64-.52 1.2-.999l.009-.007zm-3.605-5.949l-.41-.23c-.424.84-.956 1.563-1.553 2.107l.265.447a8.723 8.723 0 0 0 1.698-2.324m-.045 2.192l-.425-.237-.006.014a8.912 8.912 0 0 1-1.6 2.586l-.007.007.244.41.01.017.012-.015c.26-.294.514-.605.756-.924v3.71h.44V5.011c.198-.344.39-.729.571-1.143l.005-.01z"
            mask="url(#d1)"
          />
          <path
            fill="#232222"
            d="M23.882 3.418c-.09 1.393-.328 2.51-.705 3.322-.29-.725-.505-1.712-.64-2.934.044-.128.1-.258.166-.388h1.18zm-1.03-.427c.169-.496.363-1.074.428-1.568h-.459c-.285 1.3-.721 2.428-1.282 3.319l.277.467.204-.396c.077-.13.139-.24.186-.328.137 1.152.377 2.079.714 2.756-.323.543-.82 1.106-1.477 1.676l-.009.007.254.426.009.015.012-.012c.696-.633 1.178-1.188 1.433-1.649.29.502.781 1.11 1.352 1.674l.013.013.26-.439.006-.009-.008-.007c-.612-.568-1.098-1.158-1.368-1.66.499-.885.81-2.183.925-3.858h.481v-.427h-1.952zm18.394 1.892s-.483 1.344-2.138 2.491c-.788-.583-1.6-1.44-2.122-2.49h4.26zM39.4 1.355h-.48V2.51h-3.714v.456h3.714v1.517h-2.408V4.9s.37.81 1.118 1.69c.3.352.656.721 1.11 1.084-.844.505-1.9.984-3.365 1.26l.277.467s1.738-.36 3.466-1.448c.233.144 1.3.973 3.297 1.436h.006l.008.003.194-.36s-.515-.131-1.296-.424a9.55 9.55 0 0 1-1.872-.938 6.81 6.81 0 0 0 2.351-2.968l-.39-.219H39.4V2.966h3.747V2.51H39.4V1.355zM50.498 3.41V1.434h-.476V3.41h-3.911v.46h3.911v4.282c0 .465-.296.683-.855.683l-.275-.001v.46l.414-.001c.465 0 .72-.097.918-.29.193-.188.285-.475.274-.852V3.87h1.274v-.46h-1.274z"
            mask="url(#d1)"
          />
          <path
            fill="#232222"
            d="M47.14 5.314c.289.548.612 1.217.966 1.996l.47-.267c-.251-.499-.589-1.16-1.006-1.973l-.43.244z"
            mask="url(#d1)"
          />
        </g>
      </g>
    </svg>
  )
}

export default WeChatPay
