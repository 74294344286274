import * as React from 'react'

import {
  ImageSwatch,
  RadioGroup,
  SquaredRadioInput,
  Swatch,
} from '@thg-commerce/gravity-elements'
import { IconPosition } from '@thg-commerce/gravity-elements/CustomDropdown/CustomDropdown'
import { LabelText } from '@thg-commerce/gravity-elements/FormItem/Label'
import { ColourChoice } from '@thg-commerce/gravity-elements/Swatch'
import { SwatchShape } from '@thg-commerce/gravity-elements/Swatch/types'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'
import { spacing } from '@thg-commerce/gravity-theme'

import { SkeletonProductOption, StyledDropdown } from './styles'

export interface Choice {
  image?: string
  key: string
  colour?: string | null
  title: string
  disabled?: boolean
  inStock?: boolean
  notifyWhenInStockEnabled?: boolean | null
  useDisabledStyling?: boolean
  icon?: { fill: string; path: string; iconPosition?: IconPosition }
  customLabel?: boolean
}

export interface Option {
  type: 'RADIO' | 'DROPDOWN' | 'IMAGE' | 'SWATCH'
  label: string
  attribute?: boolean | null
  key: string
  selectedChoice?: string
  customLabel?: boolean | null
  placeholder?: string
  choices: Choice[]
}

export interface SelectedOptions {
  [key: string]: string
}
export interface ProductOptionsProps {
  order?: number[]
  onOptionChange: (key: string, value: string) => void
  onQuantityChange?: (quantity: number) => void
  onDropdownClick?: () => void
  onColourSwatchClick?: () => void
  options: Option[]
  selectedOptions?: SelectedOptions
  hideProductOptionsSwatch?: boolean
  loading?: boolean
  forceDropDowns?: boolean
  hideDropDownsLabel?: boolean
  isWishlistOption?: boolean
  required?: boolean
  swatchShape?: SwatchShape
  notifyWhenInStockInDropdown?: boolean
  swatch?: {
    show: boolean
  }
  i18nText: {
    swatch: {
      unavailableText: string
      closeButtonText: string
      showLessButtonText: string
      showMoreButtonText: string
    }
    imageSwatch: {
      showButtonText: string
      showMoreButtonText: string
      showLessButtonText: string
    }
    dropdown?: {
      unavailableText: string
      customLabelText?: string
    }
    wishlist?: {
      unavailableText: string
    }
  }
  labelCustomComponent?: React.ReactNode
  dropdownWidth?: string
  maxDropdownHeight?: string
  showOnlyTwoRows?: boolean
  displayColoursAsTextOnly?: boolean
  customErrorMessage?: {
    displayError?: boolean
    errorMessage?: string
  }
  showOutOfStockAsDisabled?: boolean
  showAllImageSwatches?: boolean
  setPersonalisationImages?: (
    personalisationImages: ChoiceSelectedVariantMap | VariantOptions,
  ) => void
}

enum OptionType {
  Size = 'Size',
}

const OptionElement = (
  props: {
    option: Option
    index: number
    required?: boolean
    labelCustomComponent?: React.ReactNode
    dropdownWidth?: string
    maxDropdownHeight?: string
    customErrorMessage?: {
      displayError?: boolean
      errorMessage?: string
    }
  } & ProductOptionsProps,
) => {
  const theme = useTheme()

  const choicesWithColour = props.option.choices.filter(
    (choice: Choice) => choice.colour,
  ) as ColourChoice[]

  const dropdownOptions = props.option.choices.map((choice) => {
    const outOfStock = choice.inStock === false
    let customLabel: string | null = null
    if (
      (choice.notifyWhenInStockEnabled &&
        props.i18nText.dropdown?.customLabelText &&
        outOfStock) ||
      (choice.customLabel && props.i18nText.dropdown?.customLabelText)
    ) {
      customLabel = props.i18nText.dropdown.customLabelText
    } else if (
      (choice.disabled || outOfStock) &&
      props.i18nText.dropdown?.unavailableText
    ) {
      customLabel = props.i18nText.dropdown.unavailableText
    }

    const displayCustomLabel =
      props.isWishlistOption ||
      outOfStock ||
      (props.showOutOfStockAsDisabled && choice.disabled)

    const useDisabledStyling =
      ((!props.isWishlistOption && outOfStock) ||
        (props.showOutOfStockAsDisabled && choice.disabled)) &&
      !choice.notifyWhenInStockEnabled

    return {
      ...choice,
      useDisabledStyling,
      customLabel: displayCustomLabel ? customLabel : null,
      value: choice.key,
      displayText: choice.title,
      icon:
        (props.notifyWhenInStockInDropdown &&
          choice.notifyWhenInStockEnabled &&
          !choice.inStock) ||
        (choice.customLabel && choice.disabled)
          ? theme.patterns.productBlock.components.options.icon
          : choice.icon,
    }
  })

  const displayingColorText = props.option.choices.filter(
    (choice: Choice) =>
      props.selectedOptions?.[props.option.key] === choice.key,
  )

  switch (props.option.type) {
    case 'DROPDOWN':
      return (
        <React.Fragment>
          <StyledDropdown
            iconOverride={theme.icons.chevronDown}
            label={props.option.label}
            removeLabelMargin={true}
            labelCustomComponent={
              props.option.attribute ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  {props.labelCustomComponent}
                </div>
              ) : null
            }
            labelHidden={props.hideDropDownsLabel || false}
            required={props.required ?? true}
            aria-label={props.option.label}
            placeholder={props.option.placeholder}
            options={dropdownOptions}
            onChange={(event) => {
              props.onOptionChange(props.option.key, event.value || '')
              props.onDropdownClick?.()
            }}
            selectedIconPosition={
              props.notifyWhenInStockInDropdown
                ? IconPosition.LEFT
                : IconPosition.RIGHT
            }
            hasMarginBottom={choicesWithColour.length > 0 || props.index >= 0}
            selected={props.selectedOptions?.[props.option.key]}
            maxDropdownHeight={props.maxDropdownHeight || '146px'}
            width={props.dropdownWidth}
            {...(props.option.label === OptionType.Size && {
              customErrorMessage: props.customErrorMessage,
            })}
          />
          {choicesWithColour.length > 0 &&
          !props.forceDropDowns &&
          (props.swatch?.show ?? true) ? (
            <Swatch
              shape={props.swatchShape ?? SwatchShape.SQUARE}
              colours={choicesWithColour}
              selectedColour={props.selectedOptions?.[props.option.key]}
              onColourChange={(value: string) => {
                props.onOptionChange(props.option.key, value)
                props.onColourSwatchClick?.()
              }}
              i18nText={props.i18nText.swatch}
              showOnlyTwoRows={props.showOnlyTwoRows}
              displayColoursAsTextOnly={props.displayColoursAsTextOnly}
            />
          ) : null}
        </React.Fragment>
      )
    case 'IMAGE':
      return (
        <ImageSwatch
          label={props.option.label}
          labelHidden={props.hideDropDownsLabel || false}
          required={false}
          ariaLabel={props.option.label}
          images={choicesWithColour}
          selectedImage={displayingColorText[0].title}
          onImageChange={(value: string) => {
            props.onOptionChange(props.option.key, value)
            props.onColourSwatchClick?.()
          }}
          i18nText={props.i18nText.imageSwatch}
          hideOptionalText={true}
          displayColoursAsTextOnly={props.displayColoursAsTextOnly}
          showAllImageSwatches={props.showAllImageSwatches}
        />
      )
    case 'SWATCH':
      return (
        <React.Fragment>
          <LabelText required={true}>{props.option.label}</LabelText>
          <Swatch
            shape={props.swatchShape ?? SwatchShape.SQUARE}
            colours={choicesWithColour}
            selectedColour={props.selectedOptions?.[props.option.key]}
            onColourChange={(value: string) => {
              props.onOptionChange(props.option.key, value)
              props.onColourSwatchClick?.()
            }}
            i18nText={props.i18nText.swatch}
            showOnlyTwoRows={props.showOnlyTwoRows}
            displayColoursAsTextOnly={props.displayColoursAsTextOnly}
          />
        </React.Fragment>
      )
    default:
      return (
        <RadioGroup
          required={props.required ?? true}
          label={props.option.label}
          selectedValue={props.selectedOptions?.[props.option.key]}
          labelHidden={false}
          // @TODO REBUILD-6279 improve how this is sent and split size guide container
          labelCustomComponent={
            props.option.attribute ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                {props.labelCustomComponent}
              </div>
            ) : null
          }
          columns={2}
          getState={(value: string) =>
            props.onOptionChange(props.option.key, value)
          }
          horizontal={true}
          defaultValue={props.selectedOptions?.[props.option.key]}
        >
          {props.option.choices.map((choice: Choice, index: number) => (
            <SquaredRadioInput
              name={props.option.key}
              title={choice.title}
              value={choice.key}
              key={index}
              disabled={choice.disabled}
              borderWidth="2px"
            />
          ))}
        </RadioGroup>
      )
  }
}

// @deprecated TODO REBUILD-8913 ProductOptions should not be used. Instead use SimpleProductOptions that queries aurora edge for product options.
export const ProductOptions = (props: ProductOptionsProps) => {
  const hasOptionOrder =
    props.order && props.order.length === props.options.length

  const options = React.useMemo(
    () =>
      props.options.map((option: Option, index: number) => {
        if (option.choices.length === 0) return
        return (
          <div
            style={{
              width: '100%',
              gap: spacing(1),
              display: 'flex',
              flexDirection: 'column',
              order: hasOptionOrder ? props.order?.[index] : index,
            }}
            key={index}
          >
            <OptionElement
              option={
                props.forceDropDowns ? { ...option, type: 'DROPDOWN' } : option
              }
              {...props}
              index={index}
            />
          </div>
        )
      }),
    [props, hasOptionOrder],
  )

  if (props.loading) {
    return <SkeletonProductOption />
  }

  if (!options.length) return null

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {options}
    </div>
  )
}
