import { MetricProperties } from '../types/client'

export const metricProperties = (publicRuntimeConfig: {
  APP_NAME: string
  siteDefinition: {
    subsite: string
    brand: string
  }
}): MetricProperties => ({
  appname: publicRuntimeConfig.APP_NAME,
  brand: publicRuntimeConfig.siteDefinition.brand,
  subsite: publicRuntimeConfig.siteDefinition.subsite,
})
