import * as React from 'react'
export const SvgLiveChatOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M21 2.999v12h-6.879L7.027 21v-6H3V3h18zm-2 2H5v8h4.007v4.014l4.015-4.014H19v-8z"
      fill={props.fill || '#333'}
    />
  </svg>
)
export default SvgLiveChatOutline
