import * as React from 'react'

import { styled } from '@thg-commerce/gravity-theme'

import { NavigationItem, NavigationItemType } from '../../../NavigationTree'

import { DetachedFeatureMenu } from './DetachedFeatureMenu'
import { WaterfallMenu } from './WaterfallMenu'

const WaterfallDropdownWrapper = styled.div`
  display: flex;
`

const getNavigationItemsByType = (
  navigationItems: NavigationItem[],
  type: NavigationItemType,
) => {
  return navigationItems?.filter(
    (navigationItem) => navigationItem.type === type,
  )
}

export const WaterfallMenuDropdown = (props: {
  navigationItem: NavigationItem
  showDetachedList?: boolean | undefined
}) => {
  if (!props.navigationItem.subNavigation || !props.navigationItem.link) {
    return null
  }
  {
    const waterfallNavigationItems = getNavigationItemsByType(
      props.navigationItem.subNavigation,
      NavigationItemType.TEXT,
    )
    const detachedNavigationItems = getNavigationItemsByType(
      props.navigationItem.subNavigation,
      NavigationItemType.DETACHED,
    )
    return (
      <WaterfallDropdownWrapper>
        <WaterfallMenu
          levelOneLink={{
            displayName: props.navigationItem.displayName,
            link: props.navigationItem.link,
          }}
          navigationItems={waterfallNavigationItems}
        />
        <DetachedFeatureMenu
          showDetachedList={props.showDetachedList}
          navigationItems={detachedNavigationItems}
        />
      </WaterfallDropdownWrapper>
    )
  }
}
