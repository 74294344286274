import * as React from 'react'
export const SvgGooglePay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={44} height={18} {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.7314 13.5603V8.47289H23.4054C24.5012 8.47289 25.426 8.11236 26.18 7.40132L26.3609 7.22106C27.7382 5.7489 27.6477 3.45555 26.18 2.09355C25.4461 1.3725 24.4409 0.981925 23.4054 1.00195H19.1129V13.5603H20.7314ZM20.7314 6.93063V2.54421H23.4456C24.0287 2.54421 24.5816 2.76453 24.9938 3.16512C25.8684 4.00635 25.8885 5.4084 25.044 6.27968C24.6319 6.71031 24.0488 6.95066 23.4456 6.93063H20.7314Z"
      fill="#3C4043"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.9106 5.63877C33.217 5.00785 32.272 4.68738 31.0757 4.68738C29.5376 4.68738 28.3816 5.2482 27.6176 6.35983L29.0451 7.25113C29.5678 6.49002 30.2815 6.10946 31.1863 6.10946C31.7593 6.10946 32.3122 6.31977 32.7445 6.70032C33.1667 7.06085 33.408 7.58161 33.408 8.13242V8.50296C32.7847 8.16246 32.0006 7.9822 31.0355 7.9822C29.9096 7.9822 29.0048 8.24258 28.3313 8.77336C27.6578 9.30414 27.316 10.0052 27.316 10.8965C27.2959 11.7077 27.6477 12.4788 28.271 12.9995C28.9043 13.5604 29.7085 13.8408 30.6535 13.8408C31.7694 13.8408 32.654 13.3501 33.3275 12.3686H33.3979V13.5604H34.946V8.26261C34.946 7.15098 34.6042 6.26969 33.9106 5.63877ZM29.5175 11.9781C29.1858 11.7377 28.9847 11.3471 28.9847 10.9265C28.9847 10.4558 29.2059 10.0653 29.6382 9.7548C30.0805 9.44434 30.6334 9.28411 31.2868 9.28411C32.1916 9.27409 32.8953 9.47439 33.3979 9.87497C33.3979 10.556 33.1265 11.1468 32.5937 11.6476C32.1112 12.1283 31.4577 12.3987 30.7741 12.3987C30.3218 12.4087 29.8794 12.2585 29.5175 11.9781Z"
      fill="#3C4043"
    />
    <path
      d="M43.8327 4.96777L38.4243 17.3359H36.7555L38.7661 13.0095L35.2175 4.96777H36.9767L39.5402 11.1368H39.5703L42.0735 4.96777H43.8327Z"
      fill="#3C4043"
    />
    <path
      d="M14.187 7.3712C14.187 6.88048 14.1468 6.38976 14.0664 5.90906H7.24054V8.68312H11.1511C10.9902 9.57442 10.4675 10.3756 9.70347 10.8763V12.679H12.0357C13.4029 11.4271 14.187 9.57442 14.187 7.3712Z"
      fill="#4285F4"
    />
    <path
      d="M7.24053 14.4215C9.19077 14.4215 10.8394 13.7806 12.0357 12.679L9.70346 10.8764C9.05003 11.317 8.21565 11.5674 7.24053 11.5674C5.3506 11.5674 3.75221 10.2955 3.1792 8.59302H0.776581V10.4557C2.00302 12.8893 4.50617 14.4215 7.24053 14.4215Z"
      fill="#34A853"
    />
    <path
      d="M3.1792 8.59295C2.87761 7.70165 2.87761 6.73023 3.1792 5.82891V3.9762H0.776578C-0.258859 6.00917 -0.258859 8.41269 0.776578 10.4457L3.1792 8.59295Z"
      fill="#FBBC04"
    />
    <path
      d="M7.24053 2.85461C8.27597 2.83458 9.27119 3.22516 10.0151 3.9362L12.086 1.87318C10.7691 0.651389 9.03998 -0.0195935 7.24053 0.000435848C4.50617 0.000435848 2.00302 1.54269 0.776581 3.97626L3.1792 5.83898C3.75221 4.12648 5.3506 2.85461 7.24053 2.85461Z"
      fill="#EA4335"
    />
  </svg>
)
export default SvgGooglePay
