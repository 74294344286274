import Redis from 'ioredis'

const createRedisClient = () => {
  if (
    !process.env.REDIS_URI ||
    !process.env.REDIS_PORT ||
    !process.env.REDIS_PASSWORD
  ) {
    return
  }

  return process.env.ENABLE_REDIS_CLUSTER === 'true'
    ? new Redis.Cluster(
        [
          {
            host: process.env.REDIS_URI,
            port: parseInt(process.env.REDIS_PORT || '0', 10),
          },
        ],
        {
          redisOptions: {
            password: process.env.REDIS_PASSWORD,
          },
        },
      )
    : new Redis({
        password: process.env.REDIS_PASSWORD,
        host: process.env.REDIS_URI,
        port: Number(process.env.REDIS_PORT),
      })
}

export const redis = createRedisClient()
