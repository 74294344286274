import { PriceTheme } from '@thg-commerce/gravity-elements/Product/types'
import {
  BreakpointArray,
  BreakpointType,
  Direction,
} from '@thg-commerce/gravity-theme'
import { TextStyling } from '@thg-commerce/gravity-theme/typography/typography'

export enum QuickBuyComponent {
  TITLE = 'title',
  OPTIONS = 'options',
  PRICING = 'pricing',
  ADDTOBAG = 'addToBag',
  AVAILABILITY = 'availability',
  VIEWPRODUCT = 'viewProduct',
}

export interface QuickBuyModalThemeInterface {
  pricing: {
    flexDirection: BreakpointArray<string>
    productPrice?: PriceTheme
    savings: {
      show: boolean
      textStyle?: TextStyling
      label?: {
        show: boolean
      }
    }
    savingsPercentage?: {
      show: boolean
    }
    alignItems: BreakpointArray<string>
    discountMessage?: {
      textStyle?: TextStyling
    }
  }
  priceDirection?: BreakpointArray<Direction>
  quantityLabel: {
    display: string
  }
  product?: {
    image?: {
      display?: BreakpointArray<string>
      width?: BreakpointArray<string>
      height?: BreakpointArray<string>
    }
  }
  productOptions?: {
    order: number[]
    dropdown?: {
      width: string
    }
  }
  swatch: {
    showOnlyTwoRows: boolean
    displayColoursAsTextOnly: boolean
  }
  animation: boolean
  enableStickyFooterButtons: boolean
  enabledComponents: BreakpointType<QuickBuyComponent[]>
}

export const theme: QuickBuyModalThemeInterface = {
  pricing: {
    flexDirection: ['row'],
    savings: {
      show: true,
    },
    alignItems: ['flex-start'],
  },
  product: {
    image: {
      display: ['block'],
      width: ['100%', '100%', '190px', '191px'],
      height: ['', '', '190px', '191px'],
    },
  },
  priceDirection: [Direction.ROW],
  quantityLabel: {
    display: 'none',
  },
  swatch: {
    showOnlyTwoRows: false,
    displayColoursAsTextOnly: false,
  },
  animation: false,
  enableStickyFooterButtons: false,
  enabledComponents: {
    xs: [
      QuickBuyComponent.TITLE,
      QuickBuyComponent.OPTIONS,
      QuickBuyComponent.PRICING,
      QuickBuyComponent.ADDTOBAG,
      QuickBuyComponent.AVAILABILITY,
      QuickBuyComponent.VIEWPRODUCT,
    ],
    sm: [
      QuickBuyComponent.TITLE,
      QuickBuyComponent.OPTIONS,
      QuickBuyComponent.PRICING,
      QuickBuyComponent.ADDTOBAG,
      QuickBuyComponent.AVAILABILITY,
      QuickBuyComponent.VIEWPRODUCT,
    ],
    md: [
      QuickBuyComponent.TITLE,
      QuickBuyComponent.OPTIONS,
      QuickBuyComponent.PRICING,
      QuickBuyComponent.ADDTOBAG,
      QuickBuyComponent.AVAILABILITY,
      QuickBuyComponent.VIEWPRODUCT,
    ],
    lg: [
      QuickBuyComponent.TITLE,
      QuickBuyComponent.OPTIONS,
      QuickBuyComponent.PRICING,
      QuickBuyComponent.ADDTOBAG,
      QuickBuyComponent.AVAILABILITY,
      QuickBuyComponent.VIEWPRODUCT,
    ],
  },
}
