import * as React from 'react'

import {
  ExpandButton,
  SwatchContainer,
  SwatchesLeftNumber,
  SwatchesLeftText,
} from './styles'
import { SwatchElement } from './SwatchElement'
import { SwatchShape, SwatchSize } from './types'

export interface ColourChoice {
  key: string
  colour: string
  title: string
  disabled?: boolean
  href?: string
}

export interface SwatchProps {
  shape: SwatchShape
  size?: SwatchSize
  colours: ColourChoice[]
  selectedColour?: string
  borderStyle?: {
    color?: string
  }
  onColourChange?: (value: string) => void
  i18nText: {
    unavailableText: string
    closeButtonText: string
    showLessButtonText?: string
    showMoreButtonText?: string
    moreText?: string
  }
  className?: string
  showOnlyTwoRows?: boolean
  displayColoursAsTextOnly?: boolean
  paddingBottom?: number
  persistOnOneRow?: boolean
}

const NUM_OF_SWATCHES_IN_MOBILE_VIEW: number = 12
export const Swatch = (props: SwatchProps) => {
  const indexOfSelectedColour = props.colours.findIndex(
    (colour) => colour.title === props.selectedColour,
  )
  const [hideColours, setHideColours] = React.useState<boolean | undefined>(
    props.showOnlyTwoRows &&
      indexOfSelectedColour < NUM_OF_SWATCHES_IN_MOBILE_VIEW,
  )

  const overflowOfColours =
    props.colours.length > NUM_OF_SWATCHES_IN_MOBILE_VIEW
  const inStockOptions = props.colours.filter((colour) => !colour.disabled)
  const moreThanOneOptionInStock = inStockOptions.length > 1

  return (
    <React.Fragment>
      <SwatchContainer
        className={props.className}
        hideColours={hideColours}
        data-testid={'swatch-container'}
        showOnlyTwoRows={props.showOnlyTwoRows}
        hideSwatches={
          props.displayColoursAsTextOnly && !moreThanOneOptionInStock
        }
        paddingBottom={props.paddingBottom}
        persistOnOneRow={props.persistOnOneRow}
      >
        {props.colours.map((choice: ColourChoice) => {
          return (
            <SwatchElement
              key={choice.key}
              choice={choice}
              onClick={
                props.onColourChange
                  ? () => {
                      props.onColourChange?.(choice.key)
                    }
                  : undefined
              }
              selected={props.selectedColour === choice.key}
              shape={props.shape}
              size={props.size}
              i18nText={props.i18nText}
              borderStyle={props.borderStyle}
            />
          )
        })}
        {props.persistOnOneRow && (
          <SwatchesLeftText>
            <SwatchesLeftNumber
              text={props.i18nText.moreText}
              colours={props.colours.length}
            />
          </SwatchesLeftText>
        )}
      </SwatchContainer>

      {props.showOnlyTwoRows &&
      overflowOfColours &&
      !props.displayColoursAsTextOnly &&
      moreThanOneOptionInStock ? (
        <ExpandButton
          onClick={() => {
            if (indexOfSelectedColour < NUM_OF_SWATCHES_IN_MOBILE_VIEW) {
              setHideColours(!hideColours)
            }
          }}
        >
          {hideColours
            ? props.i18nText.showMoreButtonText
            : props.i18nText.showLessButtonText}
        </ExpandButton>
      ) : null}
    </React.Fragment>
  )
}
