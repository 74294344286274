import * as React from 'react'
export const SvgCentreAligned = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || '#333'}
      d="M8 11h9v2H8v-2zM3 6h18v2H3V6zm4 10h11v2H7v-2z"
    />
  </svg>
)
export default SvgCentreAligned
