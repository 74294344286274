import * as React from 'react'

import gql from 'graphql-tag'

import { I18nProvider } from './I18nProvider'

export const SITE_PROPERTIES_QUERY = gql`
  query SiteProperties(
    $propertyBucket: String
    $previewId: String
    $keys: [String!]
  ) {
    siteProperties(propertyBucket: $propertyBucket, previewId: $previewId)
      @client {
      properties(keys: $keys) {
        key
        value
        location
      }
    }
  }
`

export const I18nComponent = (props: {
  showKeys?: boolean
  children?: React.ReactNode
  includeSupportedKeys?: boolean
  siteProperties: { [key: string]: { value: string; location: string } }
}) => {
  return (
    <I18nProvider showKeys={props.showKeys} value={props.siteProperties}>
      {props.children}
    </I18nProvider>
  )
}
