import * as React from 'react'

import { Button } from '@thg-commerce/gravity-elements'
import { spacing, styled, Text } from '@thg-commerce/gravity-theme'

interface AToZProps {
  availableGroups?: string[]
  i18nText: { viewAllBrands: string; navAriaLabel: string }
  selectBrands: (group: string) => void
  viewAllBrandsLink: string
  selectedGroup: string
}

const StyledCTA = styled(Button)`
  ${Text('bodyText', 'alternate')};
  height: ${spacing(5)};
  padding: ${spacing(1)} ${spacing(2)};

  &:focus-within {
    padding: ${spacing(1)} ${spacing(2)};
  }
  &:hover {
    padding: ${spacing(1)} ${spacing(2)};
  }
`

const StyledGroupTab = styled.button<{ isSelected: boolean }>`
  display: inline-flex;
  margin: 0;
  border: 2px solid transparent;
  align-items: center;
  width: ${spacing(5)};
  justify-content: center;

  ${(props) =>
    props.isSelected &&
    `
    outline: none;
    background-color: ${props.theme.colors.palette.greys.lighter};
    border: 2px solid ${props.theme.colors.palette.brand.base};`}

  &:focus {
    outline: none;
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
    cursor: pointer;
  }
`

const CTAContainer = styled.div`
  display: inline;
  margin: 0;
  margin-left: auto;
`

const StyledGroup = styled.div`
  ${Text('bodyText', 'alternate')};
`

const StyledGroupTabList = styled.div`
  margin: 0;
  display: flex;
  height: 100%;
  padding: 0;
  border-top: 1px solid ${(props) => props.theme.colors.palette.greys.grey};
  border-bottom: 1px solid ${(props) => props.theme.colors.palette.greys.grey};
`

export const AToZ = (props: AToZProps) => {
  const [currentNavIndex, setCurrentNavIndex] = React.useState<number>(0)
  const [focusedWithin, setFocusedWithin] = React.useState<boolean>(false)

  const onKeyDown = (event: React.KeyboardEvent, index: number) => {
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault()
        const leftElement = document.querySelector(
          `[data-group-index="${currentNavIndex - 1}"]`,
        ) as HTMLElement
        leftElement?.focus()
        break
      case 'ArrowRight':
        event.preventDefault()
        const rightElement = document.querySelector(
          `[data-group-index="${currentNavIndex + 1}"]`,
        ) as HTMLElement
        rightElement && rightElement.focus()
        break
      case 'ArrowDown':
        event.preventDefault()
        const firstBrandName = document.querySelector(
          `[data-brand-index="${index} 0"]`,
        ) as HTMLElement
        firstBrandName?.focus()
        setFocusedWithin(true)
        break
      case 'Escape':
        setFocusedWithin(false)
        break
    }
  }

  const focusHandler = (group: string, index: number) => {
    setFocusedWithin(false)
    setCurrentNavIndex(index)
    props.selectBrands(group)
  }

  return (
    <StyledGroupTabList
      role="tablist"
      aria-label={props.i18nText.navAriaLabel}
      data-testid="group-list"
    >
      {props.availableGroups?.map((group, index) => {
        const isSelected = props.selectedGroup === group && focusedWithin
        return (
          <StyledGroupTab
            role="tab"
            key={index}
            data-group-index={index}
            data-group-value={group}
            onKeyDown={(e) => onKeyDown(e, index)}
            onFocus={() => focusHandler(group, index)}
            onBlur={() => setFocusedWithin(false)}
            isSelected={isSelected}
          >
            <StyledGroup
              data-testid={`group-${group}`}
              onMouseEnter={() => props.selectBrands(group)}
            >
              {group.toUpperCase()}
            </StyledGroup>
          </StyledGroupTab>
        )
      })}
      <CTAContainer>
        <StyledCTA
          href={props.viewAllBrandsLink}
          emphasis="low"
          renderedAs={'a'}
        >
          {props.i18nText.viewAllBrands}
        </StyledCTA>
      </CTAContainer>
    </StyledGroupTabList>
  )
}
