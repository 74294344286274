import { Request, Response } from 'express'
import * as Cookies from 'js-cookie'

import { Cookie, CookieGroup } from './types'

export const OPTANON_CONSENT_COOKIE = 'OptanonConsent'

export const prepareDomain = (domain: string, removeSubdomain: boolean) => {
  if (removeSubdomain) {
    return `.${domain.replace(/^([a-zA-Z]*-*[a-zA-Z]*)\./, '')}`
  }
  if (domain.indexOf('www.') === 0) {
    return `.${domain.replace('www.', '')}`
  }

  return `.${domain}`
}

export const isEveryCookieEnabled = (optanonCookie: string): boolean => {
  if (
    !optanonCookie ||
    typeof optanonCookie !== 'string' ||
    optanonCookie === ''
  ) {
    return false
  }

  for (const groupKey in CookieGroup) {
    const groupId = CookieGroup[groupKey]

    if (!isNaN(Number(groupId))) {
      const optanonGroupsRegEx = new RegExp(
        `.*?groups=([C0-9_:,])*${groupId}:1(,|&|$)`,
      )

      if (!optanonGroupsRegEx.test(decodeURIComponent(optanonCookie))) {
        return false
      }
    }
  }
  return true
}

const isCookieAllowed = (
  cookie: Cookie,
  optanonCookie: string,
  cookieNoticeShown: string,
): boolean => {
  if (cookie.group === CookieGroup.REQUIRED) {
    return true
  }

  if (
    !optanonCookie ||
    typeof optanonCookie !== 'string' ||
    optanonCookie === ''
  ) {
    return !!cookieNoticeShown
  }

  const optanonGroupsRegEx = new RegExp(
    `.*?groups=([C0-9_:,])*${cookie.group}:1(,|&|$)`,
  )

  return optanonGroupsRegEx.test(decodeURIComponent(optanonCookie))
}

export const getCookieOptions = (cookie: Cookie) => {
  return {
    domain: prepareDomain(cookie.domain, cookie.removeSubdomain || false),
    expires: cookie.expires,
    secure: cookie.secure || true,
    path: cookie.path,
    encode: cookie.encode,
  }
}

export const setCookie = (
  cookie: Cookie,
  res?: Response,
  req?: Request,
  setCookieInRequest?: boolean,
  isOneTrustAvailable?: boolean,
) => {
  const { optanonConsentCookie, cookieNoticeShownCookie } =
    req && res
      ? {
          optanonConsentCookie: req.cookies[OPTANON_CONSENT_COOKIE],
          cookieNoticeShownCookie: req.cookies['cookieNoticeShown'],
        }
      : {
          optanonConsentCookie: Cookies.get(OPTANON_CONSENT_COOKIE),
          cookieNoticeShownCookie: Cookies.get('cookieNoticeShown'),
        }

  if (
    !isOneTrustAvailable ||
    isCookieAllowed(cookie, optanonConsentCookie, cookieNoticeShownCookie)
  ) {
    if (req && res) {
      res.cookie(cookie.name, cookie.value, getCookieOptions(cookie))

      if (setCookieInRequest) req.cookies[cookie.name] = cookie.value
    } else {
      Cookies.set(cookie.name, cookie.value, getCookieOptions(cookie))
    }
  }
}

export const clearCookie = (name: string, domain: string, res?: Response) => {
  if (res) {
    const cookieOptions = {
      domain: prepareDomain(domain, false),
      path: '/',
      secure: true,
    }
    res.cookie(name, '', cookieOptions)
    res.clearCookie(name, cookieOptions)
  } else {
    Cookies.remove(name)
  }
}
