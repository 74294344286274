import * as React from 'react'

import {
  EnterpriseContext,
  i18n,
  useRequestConfig,
  useSiteDefinition,
  useTransmit,
} from '@thg-commerce/enterprise-core'
import { getColumboEventData } from '@thg-commerce/enterprise-metrics'
import { spacing, styled } from '@thg-commerce/enterprise-theme'

const StyledLink = styled.a<{ isMobileApp: boolean }>`
  position: ${(props) => (props.isMobileApp ? 'absolute' : 'relative')};
  ${(props) =>
    !props.isMobileApp &&
    `height: 0; transition: all 0s linear 0.3s;
  display: flex;`}
  clip: rect(0, 0, 0, 0);
  background-color: ${(props) => props.theme.colors.palette.greys.white};

  :focus {
    ${(props) =>
      props.isMobileApp &&
      `position: relative;
    display: flex;`}
    ${(props) => !props.isMobileApp && 'transition: none;'}
   
    z-index: 1;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    outline: none;
    padding: ${spacing(1)};
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const SkipLink = () => {
  const transmit = useTransmit()
  const EnterpriseCtx = React.useContext(EnterpriseContext)
  const requestConfig = useRequestConfig()
  const { defaultLocale, subsite, siteId, channel } = useSiteDefinition()
  const propertyArgs = {
    defaultLocale,
    subsite,
    siteId,
    channel,
    customerLocation: EnterpriseCtx.currentLocation,
  }

  const isMobileApp = requestConfig.isMobileApp || false

  return (
    <StyledLink
      isMobileApp={isMobileApp}
      tabIndex={0}
      href="#main-content"
      data-testid="skiplink"
      onClick={() =>
        transmit({
          type: 'columbo',
          payload: getColumboEventData({
            propertyArgs,
            argumentsObj: {
              '0': 'Accessibility',
              '1': 'Clicked',
              '2': 'SkipLink',
            },
            requestArgs: {
              client_timestamp: Math.round(Date.now() / 1000),
              url: window.location.href,
            },
            eventData: {
              type: 'clicked',
              subtype: 'accessibility_skip_link',
              contents: [],
            },
            nonce: EnterpriseCtx.metricNonce,
          }),
        })
      }
      onFocus={() =>
        transmit({
          type: 'columbo',
          payload: getColumboEventData({
            propertyArgs,
            argumentsObj: {
              '0': 'Accessibility',
              '1': 'Focused',
              '2': 'SkipLink',
            },
            requestArgs: {
              client_timestamp: Math.round(Date.now() / 1000),
              url: window.location.href,
            },
            eventData: {
              type: 'Focused',
              subtype: 'accessibility_skip_link',
              contents: [],
            },
            nonce: EnterpriseCtx.metricNonce,
          }),
        })
      }
    >
      {i18n('header.skip.link.text') || 'Skip to main content'}
    </StyledLink>
  )
}
