import * as React from 'react'
export const SvgSuccess = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || '#333'}
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm5.293-14.707l-7.29 7.293-3.296-3.293-1.414 1.414 4.71 4.707 8.704-8.707-1.414-1.414z"
    />
  </svg>
)
export default SvgSuccess
