import * as React from 'react'
export const SvgCircularSms = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" {...props}>
    <circle cx={20} cy={20} r={20} fill="#8C8C8C" />
    <path
      d="M12.587 30l5.758-4.43h7.92c2.61 0 4.735-2.037 4.735-4.538v-4.495C31 14.036 28.876 12 26.265 12h-12.53C11.125 12 9 14.036 9 16.537v4.495c0 2.105 1.505 3.903 3.587 4.4V30zm13.678-16.71c1.853 0 3.36 1.457 3.36 3.247v4.495c0 1.79-1.507 3.248-3.36 3.248h-8.411l-3.892 2.994v-2.95l-.607-.067c-1.699-.188-2.98-1.574-2.98-3.225v-4.495c0-1.79 1.507-3.247 3.36-3.247h12.53z"
      fill={props.fill || '#fff'}
    />
    <ellipse cx={20.196} cy={18.75} rx={1.599} ry={1.5} fill="#fff" />
    <ellipse cx={15.398} cy={18.75} rx={1.599} ry={1.5} fill="#fff" />
    <ellipse cx={24.994} cy={18.75} rx={1.599} ry={1.5} fill="#fff" />
  </svg>
)
export default SvgCircularSms
