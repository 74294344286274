import * as React from 'react'
export const SvgPlayArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="white" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.465 11.723a1 1 0 010 1.79L5.447 20.52A1 1 0 014 19.628V5.609a1 1 0 011.447-.895l14.018 7.01z"
      fill={props.fill || '#333'}
    />
  </svg>
)
export default SvgPlayArrow
