import { getSafeUrlParametersFromUrlString } from '@thg-commerce/enterprise-core'
import { QubitMode } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import {
  CampaignVariantGroup,
  QubitPreviewType,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'

const QUBIT_PREVIEW_PARAM = 'qb_opts=preview'

enum QubitPageCategory {
  Home = 'home',
  Category = 'category',
  Search = 'search',
  Product = 'product',
  Basket = 'basket',
  Checkout = 'checkout',
  Confirmation = 'confirmation',
  Help = 'help',
  Contact = 'contact',
  Registration = 'registration',
  Content = 'content',
  Account = 'account',
  Other = 'other',
}

const QubitPageCategoryRewrite: { [key: string]: QubitPageCategory } = {
  'account-home': QubitPageCategory.Account,
  register: QubitPageCategory.Registration,
  login: QubitPageCategory.Other,
}

export const convertToQubitPreviewType = (queryParamsMap: {
  [key: string]: string
}): QubitPreviewType => {
  return {
    placementId: queryParamsMap['qb_placement_id'],
    mode: queryParamsMap['qb_mode']
      ? (queryParamsMap['qb_mode'] as QubitMode)
      : QubitMode.PREVIEW,
    experienceId: queryParamsMap['qb_experience_id'],
    campaignId: queryParamsMap['qb_campaign_id'],
    group: queryParamsMap['qb_group'] as CampaignVariantGroup,
  }
}

export const parseQubitPreviewParams = (url: string): QubitPreviewType => {
  if (!url.includes(QUBIT_PREVIEW_PARAM)) {
    return {}
  }

  const urlString = url.replace('#', '?')

  const queryParamsMap = getSafeUrlParametersFromUrlString(urlString, [
    'qb_opts',
    'qb_placement_id',
    'qb_mode',
    'qb_experience_id',
    'qb_campaign_id',
    'qb_group',
  ])

  return convertToQubitPreviewType(queryParamsMap)
}

export const getQubitPageCategory = (key: string): QubitPageCategory => {
  const pageCategory = key as QubitPageCategory
  return Object.values(QubitPageCategory).includes(pageCategory)
    ? pageCategory
    : QubitPageCategoryRewrite[key] || QubitPageCategory.Other
}
