import { StyleSpec } from './types'

export const createFontFaceWithPreload = (
  font: string,
  cdnUrls: StyleSpec[],
) => {
  const removeItalicIfPresent = (weight: string) => {
    if (weight.includes('italic')) {
      if (weight.indexOf('italic') === 0) {
        return '400'
      }

      const newWeight = weight.slice(0, weight.indexOf('italic'))
      return newWeight
    }
    return weight
  }

  const createFontFaceString = (
    font: string,
    fontUrl: string,
    fontWeight: string,
    fontStyle: string,
  ) => {
    return `
      @font-face {
        font-family: ${font};
        src: url(${fontUrl || ''});
        font-weight: ${removeItalicIfPresent(fontWeight)};
        font-style: ${fontStyle};
        font-display: fallback;
      }
      `
  }

  const fontFaceList: string[] = []
  const fontURLsToPreload: { url: string; fontType: string }[] = []
  cdnUrls.forEach(({ url, weight, style }) => {
    const fontFaceString = createFontFaceString(font, url, weight, style)
    if (!fontFaceList.includes(fontFaceString) && url) {
      const fontExtension = url.split('.').pop() || ''
      fontURLsToPreload.push({
        url,
        fontType: fontExtension,
      })
      fontFaceList.push(fontFaceString)
    }
  })
  return { fontURLsToPreload, fontFaces: fontFaceList.join('\n') }
}

export function getUniqueObjectArrayByKey<T, Y extends keyof T>(
  arr: T[],
  key: Y,
) {
  return arr
    .map((e) => e[key])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => (typeof e === 'number' ? arr[e] : ''))
    .map((e) => (typeof e === 'number' ? arr[e] : ''))
}
