import { LinkElement } from '../Head/renderer'

const ALLOWED_SELF_REFERENCING_PARAMS = {
  list: ['pageNumber', 'facetFilters'],
}

export const canonicalLinkTag = ({
  path,
  origin,
  pageType,
  selfReferencing,
}: {
  path: string
  origin: string
  pageType: string
  selfReferencing: boolean
}): LinkElement => {
  const originUrl = new URL(origin)
  const baseUrl = path.split('?')[0]

  if (selfReferencing) {
    const url = new URL(baseUrl, originUrl)

    if (!ALLOWED_SELF_REFERENCING_PARAMS[pageType]) {
      url.search = ''
    } else {
      url.searchParams.forEach((_, key) => {
        if (!ALLOWED_SELF_REFERENCING_PARAMS[pageType].includes(key)) {
          url.searchParams.delete(key)
        }
      })
    }

    return {
      type: 'link',
      props: {
        rel: 'canonical',
        href: url.toString(),
      },
    }
  }

  return {
    type: 'link',
    props: {
      rel: 'canonical',
      href: `${originUrl.origin}${baseUrl}`,
    },
  }
}
