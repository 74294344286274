import * as React from 'react'
export const SvgCircularPinterest = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#e60023" cx={20} cy={20} r={20} />
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M14.883 30.89c-.179-1.929-.169-3.314.03-4.157.255-1.086 1.652-6.921 1.652-6.921s-.421-.834-.421-2.068c0-1.935 1.135-3.38 2.55-3.38 1.203 0 1.783.891 1.783 1.96 0 1.195-.77 2.98-1.168 4.637-.331 1.386.705 2.517 2.087 2.517 2.505 0 4.432-2.61 4.432-6.378 0-3.335-2.426-5.665-5.889-5.665-4.009 0-6.362 2.971-6.362 6.043 0 1.197.466 2.48 1.048 3.178a.416.416 0 01.098.4c-.107.44-.345 1.385-.391 1.579-.063.254-.204.309-.472.185C12.1 22.011 11 19.47 11 17.427 11 13.033 14.23 9 20.31 9 25.2 9 29 12.442 29 17.042c0 4.8-3.062 8.662-7.313 8.662-1.43 0-2.77-.734-3.23-1.6l-.879 3.31c-.221.843-1.063 2.371-2.526 4.586l-.17-1.11z"
      />
    </g>
  </svg>
)
export default SvgCircularPinterest
