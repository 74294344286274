import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Property } from 'csstype'

import { withTheme } from '@thg-commerce/gravity-theme'
import { SvgIcon } from '@thg-commerce/gravity-icons/src/components/SvgIcon'

import { CombinedThemeInterface } from '../../../'

export interface StarProps {
  fillValue: number
  height?: string
  width?: string
  colorFills?: {
    fill: Property.Color
    background: Property.Color
  }
}

export const Star = withTheme(
  (props: StarProps & { theme: CombinedThemeInterface }) => {
    const uuid = uuidv4()

    const defs = (
      <defs>
        <linearGradient id={`star-gradient-${uuid}`}>
          <stop
            offset={props.fillValue}
            stopColor={
              props.colorFills?.fill || props.theme.colors.palette.brand.base
            }
          />
          <stop
            offset={props.fillValue}
            stopColor={
              props.colorFills?.background ||
              props.theme.colors.palette.greys.light
            }
          />
        </linearGradient>
      </defs>
    )

    return props.theme.elements.productReview.star.icon.svgPath ? (
      <SvgIcon
        height={props.theme.elements.productReview.star.icon.height || '17px'}
        width={props.theme.elements.productReview.star.icon.width || '18px'}
        viewBox={
          props.theme.elements.productReview.star.icon.viewBox || '0 0 18 17'
        }
      >
        {defs}
        <path
          d={props.theme.elements.productReview.star.icon.svgPath}
          fill={`url(#${`star-gradient-${uuid}`})`}
        />
      </SvgIcon>
    ) : (
      <SvgIcon
        height={props.height || '16px'}
        width={props.width || '16px'}
        viewBox="0 0 51 48"
      >
        {defs}
        <path
          d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
          fill={`url(#${`star-gradient-${uuid}`})`}
        />
      </SvgIcon>
    )
  },
)
