import * as React from 'react'
export const SvgPlayDefaultCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={42} height={42} fill="none" {...props}>
    <mask id="play-default-circle_svg__a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 42c11.598 0 21-9.402 21-21S32.598 0 21 0 0 9.402 0 21s9.402 21 21 21zm-5-28.955a1 1 0 011.521-.854l12.893 7.87a1 1 0 01.008 1.701L17.53 29.804A1 1 0 0116 28.956V13.045z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 42c11.598 0 21-9.402 21-21S32.598 0 21 0 0 9.402 0 21s9.402 21 21 21zm-5-28.955a1 1 0 011.521-.854l12.893 7.87a1 1 0 01.008 1.701L17.53 29.804A1 1 0 0116 28.956V13.045z"
      fill={props.fill || '#fff'}
    />
    <path
      d="M17.521 12.191l-.521.854.521-.854zm12.893 7.87l-.521.853.52-.854zm.008 1.701l.53.849-.53-.849zM17.53 29.804l.53.849-.53-.849zM41 21c0 11.046-8.954 20-20 20v2c12.15 0 22-9.85 22-22h-2zM21 1c11.046 0 20 8.954 20 20h2C43 8.85 33.15-1 21-1v2zM1 21C1 9.954 9.954 1 21 1v-2C8.85-1-1 8.85-1 21h2zm20 20C9.954 41 1 32.046 1 21h-2c0 12.15 9.85 22 22 22v-2zm-2.958-29.662c-1.333-.814-3.042.146-3.042 1.707h2l1.042-1.707zm12.893 7.868l-12.893-7.868L17 13.045l12.893 7.869 1.042-1.708zm.016 3.405a2 2 0 00-.016-3.405l-1.042 1.708 1.058 1.697zm-12.893 8.042l12.893-8.042-1.058-1.697L17 28.956l1.058 1.697zM15 28.956c0 1.57 1.726 2.528 3.058 1.697L17 28.956h-2zm0-15.911v15.91h2v-15.91h-2z"
      fill={props.fill || '#fff'}
      mask="url(#play-default-circle_svg__a)"
    />
  </svg>
)
export default SvgPlayDefaultCircle
