import * as React from 'react'

interface CustomerContext {
  firstName?: string
  email?: string
  wishlist?: {
    itemCount: number
    skus: number[]
  }
  emailMarketingPreference?: boolean
  smsMarketingPreference?: boolean
  loyalty?: {
    pointsToNextTier?: number | null
  }
}

export const CustomerContext = React.createContext<CustomerContext>({})
