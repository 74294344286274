import * as React from 'react'

import { styled } from '@thg-commerce/gravity-patterns/theme'

import {
  CopyrightAndPayments,
  CopyrightAndPaymentsProps,
} from './CopyrightAndPayments'
import {
  CustomerEngagement,
  CustomerEngagementProps,
} from './CustomerEngagement'
import { FooterNavigation, FooterNavigationProps } from './FooterNavigation'
import { FooterNotes, FooterNotesProps } from './FooterNotes'

export interface FooterProps {
  userAgent: string
  useWidgetForAccreditationIcons: boolean
  Accreditations: React.FunctionComponent
  customerEngagement: CustomerEngagementProps
  navigation: FooterNavigationProps
  copyrightAndPayments: CopyrightAndPaymentsProps
  footerNotes: FooterNotesProps
}

const StyledFooter = styled.footer.attrs(() => ({ role: 'contentinfo' }))<{
  topBorder: boolean
}>`
  ${(props) =>
    props.topBorder &&
    `border-top: ${props.theme.patterns.footer.customerEngagement.topSeparator.width} solid ${props.theme.patterns.footer.customerEngagement.topSeparator.color};`}
  width: 100%;
`

export const Footer = (props: FooterProps) => {
  const {
    Accreditations,
    useWidgetForAccreditationIcons: enableAccreditations,
  } = props

  return (
    <StyledFooter topBorder={!enableAccreditations}>
      {enableAccreditations && <Accreditations />}
      <CustomerEngagement
        connectWithUs={props.customerEngagement.connectWithUs}
        newsletterSignUp={props.customerEngagement.newsletterSignUp}
      />
      <FooterNavigation
        userAgent={props.userAgent}
        topLevel={props.navigation.topLevel}
        currentBrand={props.navigation.currentBrand}
        showSubsiteSelector={props.navigation.showSubsiteSelector}
        showCookieSettings={props.navigation.showCookieSettings}
        cookieSettingsHandler={props.navigation.cookieSettingsHandler}
        countrySelector={props.navigation.countrySelector}
        actionComponent={props.navigation.actionComponent}
        cookieConsentButtonText={props.navigation.cookieConsentButtonText}
      />
      <CopyrightAndPayments
        userAgent={props.userAgent}
        paymentMethods={props.copyrightAndPayments.paymentMethods}
        copyright={props.copyrightAndPayments.copyright}
      />
      <FooterNotes
        footerNotesTermsPolicyText={
          props.footerNotes.footerNotesTermsPolicyText
        }
      />
    </StyledFooter>
  )
}
