// ES5 import for IE11
import format from 'number-format.js/lib/format.es5.js'

export const currencyMap = {
  AED: { code: 'AED', symbol: 'AED' },
  AFN: { code: 'AFN', symbol: 'Afs' },
  ALL: { code: 'ALL', symbol: 'L' },
  AMD: { code: 'AMD', symbol: 'AMD' },
  AOA: { code: 'AOA', symbol: 'Kz' },
  ARS: { code: 'ARS', symbol: 'AR$' },
  AUD: { code: 'AUD', symbol: 'A$' },
  AZN: { code: 'AZN', symbol: 'AZN' },
  BAM: { code: 'BAM', symbol: 'KM' },
  BDT: { code: 'BDT', symbol: '৳' },
  BGN: { code: 'BGN', symbol: 'лв' },
  BHD: { code: 'BHD', symbol: 'BD' },
  BND: { code: 'BND', symbol: 'B$' },
  BOB: { code: 'BOB', symbol: '$b' },
  BRL: { code: 'BRL', symbol: 'R$' },
  BYR: { code: 'BYR', symbol: 'p.' },
  CAD: { code: 'CAD', symbol: 'CA$' },
  CHF: { code: 'CHF', symbol: 'CHF' },
  CLP: { code: 'CLP', symbol: 'CL$' },
  CNY: { code: 'CNY', symbol: '¥' },
  COP: { code: 'COP', symbol: 'CO$' },
  CRC: { code: 'CRC', symbol: '₡' },
  CUC: { code: 'CUC', symbol: 'CUC$' },
  CZK: { code: 'CZK', symbol: 'Kč' },
  DKK: { code: 'DKK', symbol: 'kr' },
  DOP: { code: 'DOP', symbol: 'RD$' },
  DZD: { code: 'DZD', symbol: 'DA' },
  EGP: { code: 'EGP', symbol: 'E£' },
  EUR: { code: 'EUR', symbol: '€' },
  FJD: { code: 'FJD', symbol: 'FJ$' },
  GBP: { code: 'GBP', symbol: '£' },
  HKD: { code: 'HKD', symbol: 'HK$' },
  HRK: { code: 'HRK', symbol: 'kn' },
  HUF: { code: 'HUF', symbol: 'Ft' },
  IDR: { code: 'IDR', symbol: 'Rp' },
  ILS: { code: 'ILS', symbol: '₪' },
  INR: { code: 'INR', symbol: '₹' },
  IQD: { code: 'IQD', symbol: 'IQD' },
  IRR: { code: 'IRR', symbol: 'IRR' },
  ISK: { code: 'ISK', symbol: 'kr' },
  JMD: { code: 'JMD', symbol: 'J$' },
  JPY: { code: 'JPY', symbol: '¥' },
  KES: { code: 'KES', symbol: 'KSh' },
  KRW: { code: 'KRW', symbol: '₩' },
  KWD: { code: 'KWD', symbol: 'K.D.' },
  KZT: { code: 'KZT', symbol: '₸' },
  LBP: { code: 'LBP', symbol: 'LBP' },
  LKR: { code: 'LKR', symbol: 'Rs' },
  MAD: { code: 'MAD', symbol: 'MAD' },
  MKD: { code: 'MKD', symbol: 'ден' },
  MOP: { code: 'MOP', symbol: 'MOP$' },
  MXN: { code: 'MXN', symbol: 'MXN', prefix: '$' },
  MYR: { code: 'MYR', symbol: 'RM' },
  NGN: { code: 'NGN', symbol: '₦' },
  PEN: { code: 'PEN', symbol: 'S/' },
  PHP: { code: 'PHP', symbol: '₱' },
  PKR: { code: 'PKR', symbol: '₨' },
  PLN: { code: 'PLN', symbol: 'zł' },
  PYG: { code: 'PYG', symbol: '₲' },
  QAR: { code: 'QAR', symbol: 'QR' },
  RON: { code: 'RON', symbol: 'RON' },
  RSD: { code: 'RSD', symbol: 'din' },
  RUB: { code: 'RUB', symbol: 'руб' },
  SAR: { code: 'SAR', symbol: 'SR' },
  SEK: { code: 'SEK', symbol: 'kr' },
  SGD: { code: 'SGD', symbol: 'S$' },
  THB: { code: 'THB', symbol: '฿' },
  TND: { code: 'TND', symbol: 'DT' },
  TRY: { code: 'TRY', symbol: 'TRY' },
  TWD: { code: 'TWD', symbol: 'NT$' },
  UAH: { code: 'UAH', symbol: '₴' },
  USD: { code: 'USD', symbol: '$' },
  UYU: { code: 'UYU', symbol: '$U' },
  UZS: { code: 'UZS', symbol: 'лв' },
  VEF: { code: 'VEF', symbol: 'Bs.' },
  VND: { code: 'VND', symbol: '₫' },
  WST: { code: 'WST', symbol: 'WS$' },
  XCD: { code: 'XCD', symbol: 'EC$' },
  ZAR: { code: 'ZAR', symbol: 'R' },
  NOK: { code: 'NOK', symbol: 'kr' },
  NZD: { code: 'NZD', symbol: 'NZ$' },
  OMR: { code: 'OMR', symbol: 'OMR' },
}

export const formatPrice = (
  isoCode: string,
  price: number,
  locale: string,
): string => {
  const formatEuro = (price: number, locale: string): string => {
    switch (locale) {
      case 'de_DE':
      case 'fr_FR':
      case 'es_ES':
      case 'fi_FI':
        return format(`0,00 ${currencyMap.EUR.symbol}`, price)
      case 'en_IE':
      case 'en_GB':
      case 'zh_CN':
        return prefixSymbol(format(`0.00`, price), currencyMap.EUR.symbol)
      case 'nl_NL':
      case 'nl_BE':
        return prefixSymbol(format(`0,00`, price), currencyMap.EUR.symbol)
      default:
        return format(`#,##0.00${currencyMap.EUR.symbol}`, price)
    }
  }

  const prefixSymbol = (
    formattedPrice: string,
    symbol: string,
    withSpace?: boolean,
  ) => {
    if (formattedPrice.split('').indexOf('-') !== -1) {
      return withSpace
        ? `-${symbol} ${formattedPrice.replace('-', '')}`
        : `-${symbol}${formattedPrice.replace('-', '')}`
    }
    return withSpace
      ? `${symbol} ${formattedPrice}`
      : `${symbol}${formattedPrice}`
  }

  switch (isoCode) {
    case currencyMap.AED.code:
      return format(`#,##0.00 ${currencyMap.AED.symbol}`, price)
    case currencyMap.AFN.code:
      return prefixSymbol(format(`0`, price), currencyMap.AFN.symbol)
    case currencyMap.ALL.code:
      return prefixSymbol(format(`0`, price), currencyMap.ALL.symbol)
    case currencyMap.AMD.code:
      return prefixSymbol(format(`#,##0.`, price), currencyMap.AMD.symbol, true)
    case currencyMap.AOA.code:
      return prefixSymbol(format(`0`, price), currencyMap.AOA.symbol)
    case currencyMap.ARS.code:
      return prefixSymbol(format(`# ##0,00`, price), currencyMap.ARS.symbol)
    case currencyMap.AUD.code:
      return prefixSymbol(format(`0.00`, price), currencyMap.AUD.symbol)
    case currencyMap.AZN.code:
      return format(`0,00 ${currencyMap.AZN.symbol}`, price)
    case currencyMap.BAM.code:
      return format(`#,##0.00${currencyMap.BAM.symbol}`, price)
    case currencyMap.BDT.code:
      return prefixSymbol(format(`#,##0.`, price), currencyMap.BDT.symbol)
    case currencyMap.BGN.code:
      return format(`0,00 ${currencyMap.BGN.symbol}`, price)
    case currencyMap.BHD.code:
      return prefixSymbol(format(`#,##0.000`, price), currencyMap.BHD.symbol)
    case currencyMap.BND.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.BND.symbol)
    case currencyMap.BOB.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.BOB.symbol)
    case currencyMap.BRL.code:
      return prefixSymbol(format(`0,00`, price), currencyMap.BRL.symbol, true)
    case currencyMap.BYR.code:
      return prefixSymbol(format(`0.`, price), currencyMap.BYR.symbol)
    case currencyMap.CAD.code:
      return prefixSymbol(format(`0.00`, price), currencyMap.CAD.symbol)
    case currencyMap.CHF.code:
      return prefixSymbol(format(`0.00`, price), currencyMap.CHF.symbol, true)
    case currencyMap.CLP.code:
      return prefixSymbol(format(`# ##0.`, price), currencyMap.CLP.symbol)
    case currencyMap.CNY.code:
      return prefixSymbol(format(`0.00`, price), currencyMap.CNY.symbol)
    case currencyMap.COP.code:
      return prefixSymbol(format(`# ##0.`, price), currencyMap.COP.symbol)
    case currencyMap.CRC.code:
      return prefixSymbol(format(`# ##0,00`, price), currencyMap.CRC.symbol)
    case currencyMap.CUC.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.CUC.symbol)
    case currencyMap.CZK.code:
      return format(`0.00 ${currencyMap.CZK.symbol}`, price)
    case currencyMap.DKK.code:
      return format(`0. ${currencyMap.DKK.symbol}`, price)
    case currencyMap.DOP.code:
      return prefixSymbol(format(`#,##0.`, price), currencyMap.DOP.symbol)
    case currencyMap.DZD.code:
      return format(`0.${currencyMap.DZD.symbol}`, price)
    case currencyMap.EGP.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.EGP.symbol)
    case currencyMap.EUR.code:
      return formatEuro(price, locale)
    case currencyMap.FJD.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.FJD.symbol)
    case currencyMap.GBP.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.GBP.symbol)
    case currencyMap.HKD.code:
      return prefixSymbol(format(`0.00`, price), currencyMap.HKD.symbol)
    case currencyMap.HRK.code:
      return format(`0,00 ${currencyMap.HRK.symbol}`, price)
    case currencyMap.HUF.code:
      return format(`0 000. ${currencyMap.HUF.symbol}`, price)
    case currencyMap.IDR.code:
      return prefixSymbol(
        format(`#.##0,00`, price),
        currencyMap.IDR.symbol,
        true,
      )
    case currencyMap.ILS.code:
      return format(`0,00 ${currencyMap.ILS.symbol}`, price)
    case currencyMap.INR.code:
      return prefixSymbol(format(`0.`, price), currencyMap.INR.symbol)
    case currencyMap.IQD.code:
      return format(`#,##0. ${currencyMap.IQD.symbol}`, price)
    case currencyMap.IRR.code:
      return format(`#,##0. ${currencyMap.IRR.symbol}`, price)
    case currencyMap.ISK.code:
      return prefixSymbol(format(`# ##0.`, price), currencyMap.ISK.symbol)
    case currencyMap.JMD.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.JMD.symbol)
    case currencyMap.JPY.code:
      return prefixSymbol(format(`#,##0.`, price), currencyMap.JPY.symbol)
    case currencyMap.KES.code:
      return prefixSymbol(format(`#,##0.`, price), currencyMap.KES.symbol)
    case currencyMap.KRW.code:
      return prefixSymbol(format(`#,##0.`, price), currencyMap.KRW.symbol)
    case currencyMap.KWD.code:
      return format(`#,##0.000${currencyMap.KWD.symbol}`, price)
    case currencyMap.KZT.code:
      return prefixSymbol(format(`###0.`, price), currencyMap.KZT.symbol)
    case currencyMap.LBP.code:
      return format(`# ##0. ${currencyMap.LBP.symbol}`, price)
    case currencyMap.LKR.code:
      return prefixSymbol(format(`#,##0.`, price), currencyMap.LKR.symbol)
    case currencyMap.MAD.code:
      return format(`###0.00 ${currencyMap.MAD.symbol}`, price)
    case currencyMap.MKD.code:
      return prefixSymbol(format(`#,##0.`, price), currencyMap.MKD.symbol)
    case currencyMap.MOP.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.MOP.symbol)
    case currencyMap.MXN.code:
      return prefixSymbol(
        format(`###0.00 ${currencyMap.MXN.symbol}`, price),
        currencyMap.MXN.prefix,
        true,
      )
    case currencyMap.MYR.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.MYR.symbol)
    case currencyMap.NGN.code:
      return prefixSymbol(format(`###0.`, price), currencyMap.NGN.symbol)
    case currencyMap.NOK.code:
      return prefixSymbol(format(`0,00`, price), currencyMap.NOK.symbol)
    case currencyMap.NZD.code:
      return prefixSymbol(format(`0.00`, price), currencyMap.NZD.symbol)
    case currencyMap.OMR.code:
      return format(`#,##0.000 ${currencyMap.OMR.symbol}`, price)
    case currencyMap.PEN.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.PEN.symbol)
    case currencyMap.PHP.code:
      return prefixSymbol(format(`#,##0.`, price), currencyMap.PHP.symbol)
    case currencyMap.PKR.code:
      return prefixSymbol(format(`#,##0.`, price), currencyMap.PKR.symbol)
    case currencyMap.PLN.code:
      return format(`0.00 ${currencyMap.PLN.symbol}`, price)
    case currencyMap.PYG.code:
      return prefixSymbol(format(`0`, price), currencyMap.PYG.symbol)
    case currencyMap.QAR.code:
      return format(`0.00${currencyMap.QAR.symbol}`, price)
    case currencyMap.RON.code:
      return format(`0.00${currencyMap.RON.symbol}`, price)
    case currencyMap.RSD.code:
      return format(`0${currencyMap.RSD.symbol}`, price)
    case currencyMap.RUB.code:
      return format(`# ##0. ${currencyMap.RUB.symbol}`, price)
    case currencyMap.SAR.code:
      return format(`#,##0.00${currencyMap.SAR.symbol}`, price)
    case currencyMap.SEK.code:
      return format(`0 ${currencyMap.SEK.symbol}`, price)
    case currencyMap.SGD.code:
      return prefixSymbol(format(`0.00`, price), currencyMap.SGD.symbol)
    case currencyMap.THB.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.THB.symbol)
    case currencyMap.TND.code:
      return format(`0.00${currencyMap.TND.symbol}`, price)
    case currencyMap.TRY.code:
      return prefixSymbol(
        format(`#,##0.000`, price),
        currencyMap.TRY.symbol,
        true,
      )
    case currencyMap.TWD.code:
      return prefixSymbol(format(`0`, price), currencyMap.TWD.symbol)
    case currencyMap.UAH.code:
      return prefixSymbol(
        format(`# ##0,00`, price),
        currencyMap.UAH.symbol,
        true,
      )
    case currencyMap.USD.code:
      return prefixSymbol(format(`0.00`, price), currencyMap.USD.symbol)
    case currencyMap.UYU.code:
      return prefixSymbol(format(`# ##0.`, price), currencyMap.UYU.symbol)
    case currencyMap.UZS.code:
      return prefixSymbol(format(`0`, price), currencyMap.UZS.symbol)
    case currencyMap.VEF.code:
      return prefixSymbol(format(`# ##0,00`, price), currencyMap.VEF.symbol)
    case currencyMap.VND.code:
      return prefixSymbol(format(`# ##0.`, price), currencyMap.VND.symbol)
    case currencyMap.WST.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.WST.symbol)
    case currencyMap.XCD.code:
      return prefixSymbol(format(`#,##0.00`, price), currencyMap.XCD.symbol)
    case currencyMap.ZAR.code:
      return prefixSymbol(format(`0.00`, price), currencyMap.ZAR.symbol)
    default:
      try {
        const formatter = new Intl.NumberFormat(locale.replace('_', '-'), {
          currency: isoCode,
          style: 'currency',
        })
        return formatter.format(price)
      } catch (e) {
        return format(`0.00`, price)
      }
  }
}
