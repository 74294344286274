import * as React from 'react'

import {
  SITE_PROPERTIES_QUERY,
  I18nComponent as GenericI18nComponent,
} from '@thg-commerce/enterprise-translations'

import { SiteProperties } from '../ConfigurationLoader/types'

const I18nComponent = (props: {
  showKeys?: boolean
  children?: React.ReactNode
  siteProperties: SiteProperties
}) => {
  return <GenericI18nComponent {...props} />
}

export { I18nComponent, SITE_PROPERTIES_QUERY }
