export enum PageType {
  PRODUCT = 'product',
  LANDING = 'landing',
  REVIEWS = 'reviews',
  ADD_REVIEW = 'addReview',
  BASKET = 'basket',
  SEARCH = 'search',
  HOME = 'home',
}

const CACHE_CONFIG = (process.env.PAGE_CACHE_TTL_CONFIG
  ? JSON.parse(process.env.PAGE_CACHE_TTL_CONFIG)
  : {}) as { [key in PageType]?: { ttl: number; grace: number } }

export const pageTTLConfig = (pageType: PageType) => {
  return CACHE_CONFIG[pageType]
}
