import * as React from 'react'
export const SvgFavouritesOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M16.446 3C19.594 3 22 5.586 22 8.59c0 3.1-2.19 5.609-5.503 8.714-.436.41-1.082.957-1.748 1.504l-.364.298c-.91.74-1.797 1.436-2.183 1.737l-.127.099L12 21l-.075-.058-.127-.1c-.386-.3-1.272-.995-2.182-1.736l-.365-.298a38.924 38.924 0 01-1.748-1.504C4.19 14.199 2 11.69 2 8.59 2 5.586 4.406 3 7.554 3 9.29 3 10.987 3.907 12 5.234 13.012 3.907 14.71 3 16.446 3zM7.554 5H7.54C5.586 5.008 4 6.616 4 8.59c0 2.26 1.712 4.294 4.87 7.255.728.683 2.116 1.806 3.13 2.61 1.013-.804 2.401-1.927 3.13-2.61C18.287 12.884 20 10.85 20 8.59 20 6.61 18.405 5 16.446 5 13.656 5 12 8.083 12 8.083S10.362 5 7.554 5z"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgFavouritesOutline
