import * as React from 'react'
export const SvgAlipay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={54} height={19} {...props}>
    <g fill="none">
      <path
        fill="#0AE"
        d="M19.309 12.84V3.208c0-1.776-1.42-3.208-3.17-3.208H3.67C1.93 0 .5 1.436.5 3.208V15.64c0 1.776 1.419 3.208 3.17 3.208h12.47c1.735 0 3.162-1.429 3.169-3.195-.166-.068-6.629-2.757-7.374-3.258-.307.364-2.507 3.332-6.312 3.332-1.493 0-3.748-1.145-3.748-3.2 0-3.006 2.94-3.566 3.686-3.566 2.563 0 5.682 1.19 5.682 1.19.755-1.321 1.074-2.633 1.074-2.633H5v-.691h3.752V5.443H4.19v-.745h4.54l.022-1.75c0-.284.08-.443.42-.443h1.768l-.022 2.193h4.521v.745H10.94v1.384h3.68s-.808 2.946-1.624 3.942c0 0 5.546 1.936 6.313 2.071zm-8.389-1.185s-2.355-1.444-4.725-1.444c-1.433 0-3.006.511-3.006 2.202 0 1.43 1.816 2.05 3.006 2.05 2.74 0 4.725-2.808 4.725-2.808z"
      />
      <path
        fill="#3F3A39"
        d="M22.51 2.882h3.79V1.69s.006-.112.065-.187c.114-.143.232-.126.232-.126h1.791v1.498h3.866v.745h-3.882v1.208h3.314s-.337 1.483-1.321 2.628c-1.01 1.173-1.81 1.75-1.81 1.75s.55.336 1.496.814c.88.446 2.317.955 2.317.955v.86s-1.377-.168-3.314-1.069c-1.063-.493-1.69-.823-1.69-.823s-.633.452-1.998 1c-2.18.875-2.932.882-2.932.882v-.882s1.063-.3 2.13-.784c.993-.45 1.745-.952 1.745-.952s-.997-.697-1.565-1.32c-.8-.877-1.196-1.615-1.196-1.615h1.69s.506.673 1.009 1.184c.496.505 1.117.94 1.117.94s.447-.189 1.257-1.25c.813-1.066.887-1.58.887-1.58h-6.445V4.82h3.25V3.63h-3.809l.007-.747zm12.034-1.37l1.87-.01-1.252 2.828h.629v7.37l-1.69.012-.007-5.574-1.495-.001 1.945-4.625zm1.935 1.81h4.246V1.577h1.753v1.745h.687v.742h-.69v6.896s-.01.26-.191.438c-.187.186-.44.194-.44.194h-2.307v-.822h.944s.127.01.181-.06c.054-.07.066-.178.066-.178V4.08h-4.246l-.003-.757zm.565 1.762h1.558s.119 0 .313.492c.12.307.934 3.006.934 3.006l-1.678-.004-1.127-3.494zm7.325-2.707h3.624v-1h2.063v1h3.183s.184-.006.312.128.13.311.13.311v1.385H51.68V3.14h-5.371v1.057h-1.94v-1.82zm0 2.452h9.31v.74h-3.747v1.384h3.751v.748H49.93v3.015h1.874l-.498-1.57h1.688s.1.001.186.128c.087.127.183.438.183.438l.5 1.749h-9.494v-.75h3.683V7.71h-3.677v-.76h3.677V5.576h-3.686l.003-.747zM23.54 17.464l1.557-4.314.62.001 1.632 4.323-.869-.007-.258-.875h-1.557l-.311.875-.814-.003zm1.306-1.36l.574-1.761.056-.005.559 1.762-1.19.003zm19.315 1.36l1.557-4.314.62.001 1.632 4.323-.869-.007-.258-.875h-1.557l-.311.875-.814-.003zm1.306-1.36l.575-1.761.055-.005.559 1.762-1.19.003zM29.844 13.16v4.315h2.627v-.621h-1.813V13.16h-.814zm5.437-.007v4.315l.812.003v-4.32l-.812.002zm3.936-.003v4.32h.814v-1.63h1.12s1.135-.12 1.19-1.375c-.055-1.245-1.19-1.315-1.19-1.315h-1.934zm.758.625v1.446h1.058s.506-.124.506-.753-.502-.695-.502-.695l-1.062.002zm9.748-.621h.81l.876 1.816.816-1.816.866-.002-1.251 2.503v1.816h-.875l.014-1.815-1.256-2.502z"
      />
    </g>
  </svg>
)
export default SvgAlipay
