import * as React from 'react'
export const SvgSocialLinkOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="#333">
      <path d="M13.669 9.928l-.139-.141-.145-.13a3.604 3.604 0 00-4.912.18l-3.77 3.743a3.827 3.827 0 00-.093 5.31 3.606 3.606 0 005.196.09l1.885-1.87c.412-.41.424-1.08.027-1.503a1.038 1.038 0 00-1.493-.026L8.34 17.45a1.531 1.531 0 01-2.211-.038 1.664 1.664 0 01.04-2.304l3.77-3.743a1.53 1.53 0 012.108-.062l.103.101c.404.43 1.074.441 1.493.026.412-.41.424-1.08.026-1.503z" />
      <path d="M9.903 13.914l.139.14.145.131a3.604 3.604 0 004.912-.18l3.77-3.743a3.827 3.827 0 00.093-5.31 3.606 3.606 0 00-5.196-.09l-1.885 1.87a1.082 1.082 0 00-.026 1.503c.403.43 1.074.441 1.492.026l1.885-1.87a1.531 1.531 0 012.211.037c.607.647.59 1.68-.04 2.305l-3.77 3.742a1.53 1.53 0 01-2.107.063l-.104-.101a1.038 1.038 0 00-1.493-.026 1.082 1.082 0 00-.026 1.503z" />
    </g>
  </svg>
)
export default SvgSocialLinkOutline
