import { spacing, styled, Text } from '@thg-commerce/gravity-theme'
import { Skeleton, CustomDropdown } from '@thg-commerce/gravity-elements'

export const StyledDropdown = styled(CustomDropdown)`
  width: 100%;
`

export const SkeletonProductOption = styled(Skeleton)`
  width: 100%;
  height: 100%;
  min-height: 100px;
`

export const QuantitySelectorContainer = styled.div`
  margin-bottom: ${spacing(5)};
`

export const QuantityText = styled.p`
  ${Text('bodyText', 'alternate')};
  margin-bottom: ${spacing(1)};
`
