import * as React from 'react'
export const SvgAccountAddressBook = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={65} height={64} {...props}>
    <g fill="#333" fillRule="evenodd">
      <path d="M52.535 3.429a5.68 5.68 0 015.68 5.68v45.783a5.68 5.68 0 01-5.68 5.68h-38.64a5.68 5.68 0 01-5.68-5.68l-.001-10.035h-.008a1.42 1.42 0 01-1.42-1.42v-.017c0-.784.636-1.42 1.42-1.42h.008v-8.572l-.008.001a1.42 1.42 0 01-1.42-1.42v-.017c0-.784.636-1.42 1.42-1.42l.008-.001V22h-.008a1.42 1.42 0 01-1.42-1.42v-.017c0-.784.636-1.42 1.42-1.42h.008V9.109a5.682 5.682 0 015.681-5.68h38.64zm-.018 2.857H13.912a2.84 2.84 0 00-2.84 2.84l-.001 10.017h1.438c.784 0 1.42.636 1.42 1.42v.017a1.42 1.42 0 01-1.42 1.42h-1.438v8.571h1.438c.784 0 1.42.637 1.42 1.42v.018a1.42 1.42 0 01-1.42 1.42l-1.438-.001V42h1.438c.784 0 1.42.636 1.42 1.42v.018a1.42 1.42 0 01-1.42 1.42l-1.438-.001v10.018a2.84 2.84 0 002.84 2.84h38.606a2.84 2.84 0 002.84-2.84V9.126a2.84 2.84 0 00-2.84-2.84z" />
      <path
        fillRule="nonzero"
        d="M33.929 30.572a4.286 4.286 0 100-8.572 4.286 4.286 0 000 8.572zm0 2.857a7.143 7.143 0 110-14.286 7.143 7.143 0 010 14.286zm-10 12.857h-2.857c0-5.523 5.756-10 12.857-10 7.1 0 12.857 4.477 12.857 10h-2.857c0-3.765-4.342-7.143-10-7.143s-10 3.378-10 7.143z"
      />
    </g>
  </svg>
)
export default SvgAccountAddressBook
