export interface MetricProperties {
  appname: string
  brand: string
  subsite: string
}

export interface SupportedEndpoints {
  default: string
  content?: string
}

export enum NetworkClient {
  Default = 'default',
  Content = 'content',
}
