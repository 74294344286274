import * as React from 'react'
export const SvgLayBuy = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={61} height={41} {...props}>
    <g fill="none">
      <path
        fill="#000"
        d="M25.793 22.59h-3.347v-5.382a.223.223 0 00-.446 0v5.57c.003.122.1.22.222.222h3.573a.205.205 0 100-.41h-.002zm4.529-5.379a.325.325 0 00-.309-.211h-.017a.323.323 0 00-.309.211l-2.652 5.469a.295.295 0 00-.035.135c.006.107.1.189.213.185a.234.234 0 00.222-.16l.727-1.528h3.659l.727 1.516c.034.094.125.16.23.168a.223.223 0 00.155-.057.202.202 0 00.067-.145.274.274 0 00-.036-.135l-2.642-5.448zm-1.966 3.705l1.645-3.418 1.632 3.418h-3.277zM37.784 17a.232.232 0 00-.19.127l-2.094 3.1-2.081-3.098a.23.23 0 00-.197-.127.228.228 0 00-.222.22.287.287 0 00.04.136l2.243 3.27v2.165c.007.117.1.207.213.207.113 0 .206-.09.214-.207v-2.175l2.224-3.236a.33.33 0 00.066-.17.225.225 0 00-.216-.212zm5.112 2.86c.42-.24.772-.617.772-1.291v-.017a1.392 1.392 0 00-.403-1.02c-.336-.343-.864-.532-1.536-.532h-2.083a.635.635 0 00-.458.192.662.662 0 00-.188.469v4.68a.662.662 0 00.188.467.635.635 0 00.458.192H41.8c1.327 0 2.2-.548 2.2-1.645v-.018c-.004-.808-.423-1.211-1.104-1.477zm-2.636-1.705h1.276c.546 0 .848.222.848.617v.016c0 .447-.36.635-.932.635H40.26v-1.268zm2.451 3.034c0 .445-.344.65-.915.65H40.26V20.52h1.495c.662 0 .956.249.956.652v.017zM49.363 17a.62.62 0 00-.447.187.646.646 0 00-.183.457v2.76c0 .928-.467 1.405-1.235 1.405-.769 0-1.235-.494-1.235-1.447v-2.714a.638.638 0 00-.632-.62.638.638 0 00-.631.62v2.751c0 1.723.943 2.601 2.483 2.601S50 22.128 50 20.357v-2.713a.646.646 0 00-.185-.459.62.62 0 00-.452-.185zm7.055 0c-.248 0-.426.152-.573.391l-1.34 2.078-1.319-2.064c-.147-.235-.318-.395-.59-.395a.558.558 0 00-.417.176.659.659 0 00-.179.446c.001.168.053.33.147.463l1.752 2.583v1.674c0 .358.268.648.597.648.33 0 .597-.29.597-.648v-1.7l1.752-2.566a.85.85 0 00.154-.454.629.629 0 00-.16-.456.53.53 0 00-.421-.175z"
      />
      <path
        fill="#786DFF"
        d="M11.835 15.078l-1.353 1.335c-.094.093-.23 0-.23 0l-1.734-1.74a1.449 1.449 0 00-2.023 0 1.402 1.402 0 000 1.997l2.874 2.837c.56.55 1.464.55 2.024 0l2.405-2.374.42-.426a.744.744 0 011.046 0 .698.698 0 01-.006 1.008l-.434.432-4.369 4.313a.181.181 0 01-.262 0L5.44 17.77a1.45 1.45 0 00-2.024 0 1.404 1.404 0 000 1.997l5.925 5.848-.031-.027c.56.55 1.463.55 2.023 0l5.516-5.444.058-.058a3.511 3.511 0 00.975-3.442 3.565 3.565 0 00-2.56-2.528 3.62 3.62 0 00-3.488.962z"
      />
    </g>
  </svg>
)
export default SvgLayBuy
