import gql from 'graphql-tag'

export const FEATURES_QUERY = gql`
  query Features($identifiers: [String!]!) {
    features(input: { identifiers: $identifiers }) @client(always: true) {
      enabled
    }
  }
`

export const MAIN_PROFILES_QUERY = gql`
  query mainProfile {
    mainProfile @client {
      identifier
    }
  }
`
