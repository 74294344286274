import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'

import { Cart } from './types'

export const getCartObject = (basket: BasketData, currency: string): Cart => {
  return {
    id: null,
    items: basket.items.map((item) => ({
      quantity: item.quantity,
      product_group: {
        id: Number(item.product.product?.sku),
        selected_variant: {
          price: {
            currency,
            value: Number(item.standardPricePerUnit.amount),
          },
          sku: Number(item.product.sku),
        },
        total_variants: Number(item.product.product?.variants.length),
      },
    })),
    total_price: {
      currency,
      value: Number(basket.standardPrice.amount),
    },
    total_size: basket.totalQuantity,
  }
}
