import * as React from 'react'
import dynamic from 'next/dynamic'

import SvgIcon from '@thg-commerce/gravity-icons/src/components/SvgIcon'
import { spacing } from '@thg-commerce/gravity-theme'

import { IconStyling } from '../Header/theme'

import { Container, LoyaltyText } from './styles'

const LoyaltyIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/LoyaltyIcon'),
  { ssr: true, loading: () => <div style={{ height: 24, width: 24 }} /> },
)

export interface LoyaltyPointsProps {
  className?: string
  pointsText: string
  iconTheme?: IconStyling
}

export const LoyaltyPoints = ({
  className,
  pointsText,
  iconTheme,
}: LoyaltyPointsProps) => {
  return (
    <Container className={className}>
      {iconTheme ? (
        <SvgIcon
          xmlns="http://www.w3.org/2000/svg"
          viewBox={iconTheme.viewBox}
          width={iconTheme.width}
          height={iconTheme.height}
          fill={iconTheme.color}
          style={{ marginRight: spacing(1) }}
        >
          <path d={iconTheme.svgPath} />
        </SvgIcon>
      ) : (
        <LoyaltyIcon style={{ marginRight: spacing(1) }} />
      )}
      <LoyaltyText data-testid="loyalty-points">{pointsText}</LoyaltyText>
    </Container>
  )
}
