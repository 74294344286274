type propArgTypes = {
  defaultLocale: string
  customerLocation: string | null
  subsite: string
  siteId: string
  channel: string
}
export const getPropertyObject = (propertyArgs: propArgTypes) => {
  return {
    site_id: parseInt(propertyArgs.siteId, 10),
    channel: propertyArgs.channel, // channel in rocinante
    locale: propertyArgs.defaultLocale,
    subsite: propertyArgs.subsite,
    country_code: propertyArgs.defaultLocale?.split('_')[1]?.toLowerCase(),
    customer_location: propertyArgs.customerLocation, // MaxMind Lookup
  }
}
