import * as React from 'react'
export const SvgPopTrackerSelected = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Pop-Tracker-Selected_svg__pop-tracker-selected-svg"
    data-name="Pop tracker selected svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path d="M15.92 11.35h-2.46a.65.65 0 01-.54-.29.66.66 0 01-.07-.61 6.3 6.3 0 00.45-2.35v-.38a.3.3 0 00-.3-.3h-.2a.29.29 0 00-.29.22l-.13.45A7.45 7.45 0 0111 10.71a1.65 1.65 0 01-1 .61.58.58 0 010 .19v4.54l.92.46a.66.66 0 00.3.07h4a.34.34 0 00.29-.17l.37-.65a5.34 5.34 0 00.71-2.65V12a.66.66 0 00-.67-.65z" />
    <path d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm5.23 11.11a6 6 0 01-.79 3l-.37.65a1 1 0 01-.86.49h-4a1.31 1.31 0 01-.59-.13l-.7-.35a.81.81 0 01-.74.48H7.59a.82.82 0 01-.82-.82v-4.9a.82.82 0 01.82-.82h2.12a1 1 0 00.8-.39 6.74 6.74 0 001.24-2.39l.13-.45a1 1 0 01.92-.69h.2a1 1 0 011 1v.31a6.94 6.94 0 01-.5 2.59h2.46A1.31 1.31 0 0117.23 12z" />
    <path d="M9.22 11.35H7.59a.17.17 0 00-.17.16v4.9a.17.17 0 00.17.17h1.63a.16.16 0 00.16-.17v-4.9a.16.16 0 00-.16-.16z" />
  </svg>
)
export default SvgPopTrackerSelected
