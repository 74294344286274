export const cacheControl = (cache: { ttl?: number; grace?: number }) => {
  if (typeof cache.ttl === 'number' && cache.ttl === 0) {
    return 'no-cache'
  }
  {
    const cacheControl: {
      ['max-age']?: number
      ['stale-while-revalidate']?: number
      ['stale-if-error']?: number
    } = {}

    if (cache.ttl) {
      cacheControl['max-age'] = cache.ttl
    }

    if (typeof cache.grace === 'number') {
      cacheControl['stale-while-revalidate'] = cache.grace
      cacheControl['stale-if-error'] = 86400
    }

    if (Object.keys(cacheControl).length > 0) {
      const formattedCacheControlHeader = Object.entries(cacheControl)
        .map(([key, value]) => {
          return `${key}=${value}`
        })
        .join(',')

      return formattedCacheControlHeader
    }

    return null
  }
}
