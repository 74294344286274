import * as React from 'react'

let supportsPrefetchValue: boolean | undefined
function supportsPrefetch() {
  if (typeof supportsPrefetchValue === 'undefined') {
    supportsPrefetchValue = document
      .createElement('link')
      .relList?.supports?.('prefetch')
  }
  return supportsPrefetchValue
}

interface PrefetchContextValue {
  prefetch: (url: string) => void
}

const PrefetchContext = React.createContext<PrefetchContextValue>({
  prefetch: (url) => {
    const currentDomain = window.location.origin
    const urlDomain = new URL(url).origin
    if (urlDomain !== currentDomain) return

    if (prefetchCache.has(url)) return

    if (supportsPrefetch()) {
      const linkTag = document.createElement('link')
      linkTag.rel = 'prefetch'
      linkTag.href = url
      linkTag.as = 'document'
      linkTag.setAttribute('data-prefetch', 'true')

      document.head.appendChild(linkTag)
      prefetchCache.add(url)
    } else {
      fetch(url, {
        headers: {
          Purpose: 'prefetch',
        },
      })
        .then(() => {
          prefetchCache.add(url)
        })
        .catch(() => {
          // Suppressing fetch errors
        })
    }
    prefetchCache.add(url)
  },
})

const prefetchCache = new Set<string>()

export const PrefetchContextProvider: React.FunctionComponent<React.PropsWithChildren<
  PrefetchContextValue
>> = (props) => {
  return (
    <PrefetchContext.Provider value={props}>
      {props.children}
    </PrefetchContext.Provider>
  )
}

export const usePrefetch = (options: { url: string; rootMargin?: string }) => {
  const ref = React.useRef<HTMLAnchorElement>()
  const context = React.useContext(PrefetchContext)
  const prefetched = React.useRef(false)

  React.useEffect(() => {
    if (!ref.current || prefetched.current) {
      return
    }

    const currentRef = ref.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !prefetched.current) {
          context.prefetch(options.url)
          prefetched.current = true
          observer.unobserve(currentRef)
        }
      },
      {
        rootMargin: options.rootMargin ?? '0px',
      },
    )

    observer.observe(currentRef)

    return () => {
      if (observer && currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [prefetched, context, options.url, options.rootMargin])

  return ref
}

type ExtractProps<
  TComponentOrTProps
> = TComponentOrTProps extends React.ComponentType<infer TProps>
  ? TProps
  : TComponentOrTProps

export const withPrefetch = <
  Key extends keyof ExtractProps<ComponentType>,
  ComponentType extends React.ComponentType
>(
  hrefPropKey: Key,
  Component: ComponentType,
) => {
  return (props: React.PropsWithChildren<ExtractProps<ComponentType>>) => {
    const hrefProp = props[hrefPropKey]
    const url = typeof hrefProp === 'string' ? hrefProp : ''
    const prefetchRef = usePrefetch({ url })

    return React.createElement(
      Component,
      { ...props, ref: prefetchRef },
      props.children,
    )
  }
}
