import * as React from 'react'
export const SvgZippay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 60 41" width={60} height={40} {...props}>
    <path
      fill="#AA8FFF"
      d="M23.375 15.337l1.351 11.082h13.216L36.59 15.337H23.375h0z"
      stroke="null"
    />
    <path
      fill="#1A0826"
      d="M27.145 11.109c.843.798.958 2.054.258 2.805-.7.75-1.951.712-2.794-.086-.843-.8-.959-2.055-.258-2.806.7-.75 1.95-.712 2.794.087h0z"
      stroke="null"
    />
    <path
      fill="#1A0826"
      fillRule="evenodd"
      d="M51.792 19.327c-.304-2.492-2.247-3.998-4.885-3.99H38.12l1.351 11.082h3.955l-.27-2.216h4.186c3.29 0 4.798-2.068 4.45-4.875h0zm-4.88 1.77l-4.138.004-.325-2.658 4.158.004c.978.013 1.478.565 1.56 1.327.053.486-.169 1.323-1.255 1.323z"
      clipRule="evenodd"
      stroke="null"
    />
    <path
      fill="#1A0826"
      d="M9.611 23.322l.378 3.097h13.203l-.435-3.544h-6.153l-.057-.442 5.672-3.99-.378-3.106H8.638l.43 3.548h6.167l.056.443-5.68 3.994h0z"
      stroke="null"
    />
  </svg>
)
export default SvgZippay
