import * as React from 'react'

import { styled, mq, BreakpointKey } from '@thg-commerce/gravity-theme'

const HeaderContainer = styled.div<{
  defineWidth?: number | string | number[] | string[]
  sticky?: boolean
  shouldShow?: boolean | boolean[]
}>`
  position: ${({ sticky }) => (sticky ? 'sticky' : 'relative')};
  display: flex;
  justify-content: flex-end;
  ${({ sticky }) => sticky && 'top: 0;'}
  background-color: ${(props) => props.theme.colors.palette.greys.white};

  ${(props) =>
    Array.isArray(props.shouldShow)
      ? props.shouldShow
          .map(
            (show, index) => `${mq(
              props.theme.breakpointUtils.map,
              props.theme.breakpointUtils.keys[index] as BreakpointKey,
            )} {
              display: ${show ? 'flex' : 'none'};
            }`,
          )
          .join('')
      : `display: ${props.shouldShow ? 'flex' : 'none'};`}
`

interface ModalHeaderProps {
  closeButton: React.ReactNode
  sticky?: boolean
  'data-testid'?: string
  shouldShow?: boolean | boolean[]
}

export const ModalHeader = React.forwardRef(
  (props: ModalHeaderProps, ref: any) => {
    return (
      <HeaderContainer
        data-testid={props['data-testid'] || 'modal-header'}
        sticky={props.sticky}
        shouldShow={props.shouldShow !== undefined ? props.shouldShow : true}
        ref={ref}
      >
        {props.closeButton}
      </HeaderContainer>
    )
  },
)
