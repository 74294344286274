import * as React from 'react'
export const SvgWishlist = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Wishlist_svg__wishlist-svg"
    data-name="wishlist-svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path d="M11.91 6.73c-1.55-1.68-4-3.45-5.71-3.21C2 4.1 1.11 8.1 3.22 12.68c1.56 3.38 7 7.82 8.69 7.82s6.8-4.16 8.47-7.83c2-4.43.93-8.45-2.91-9.07-1.75-.28-4 1.4-5.56 3.13z" />
  </svg>
)
export default SvgWishlist
