import * as React from 'react'
export const SvgAccountPreferences = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={64} height={64} {...props}>
    <g fill="#333">
      <path d="M58.149 35.646l-4.22-2.813v-1.666l4.22-2.812c.885-.6 1.224-1.72.833-2.709l-3.412-8.23c-.416-.964-1.458-1.537-2.474-1.328l-5 .99-1.173-1.173.99-5c.208-1.016-.365-2.058-1.328-2.449l-8.23-3.438a2.257 2.257 0 00-2.709.833l-2.813 4.22h-1.666L28.354 5.85a2.257 2.257 0 00-2.709-.833l-8.23 3.412c-.964.416-1.537 1.458-1.328 2.474l.99 5-1.173 1.172-5-.99c-1.016-.208-2.058.365-2.448 1.329l-3.439 8.231c-.391.99-.052 2.11.833 2.709l4.22 2.812v1.667l-4.22 2.814a2.257 2.257 0 00-.833 2.709l3.412 8.23c.416.963 1.458 1.536 2.474 1.328l5-.99 1.172 1.172-.99 5c-.208 1.017.365 2.059 1.329 2.45l8.23 3.437c.99.39 2.11.052 2.709-.833l2.812-4.22h1.667l2.813 4.22c.443.65 1.12.99 1.85.99.286 0 .572-.053.859-.157l8.23-3.412c.963-.416 1.536-1.458 1.328-2.474l-.99-5 1.172-1.173 5 .99c1.017.208 2.059-.365 2.45-1.328l3.437-8.23c.39-.99.052-2.11-.833-2.709zm-5.077 9.486l-6-1.164-3.104 3.104 1.164 6-7.578 3.155-3.414-5.095h-4.397l-3.414 5.095-7.578-3.155 1.164-6-3.103-3.104-6 1.164-3.156-7.578 5.095-3.414v-4.397l-5.094-3.414 3.155-7.578 6 1.164 3.104-3.104-1.164-6 7.577-3.154 3.414 5.095h4.397l3.414-5.095 7.578 3.155-1.164 6 3.104 3.104 6-1.164 3.155 7.578-5.095 3.414v4.397l5.095 3.414-3.155 7.578z" />
      <path d="M32 20.571c-6.299 0-11.429 5.13-11.429 11.429 0 6.298 5.13 11.428 11.429 11.428 6.298 0 11.428-5.13 11.428-11.428 0-6.299-5.13-11.429-11.428-11.429zm0 20c-4.727 0-8.572-3.845-8.572-8.571 0-4.727 3.845-8.572 8.572-8.572 4.726 0 8.571 3.845 8.571 8.572 0 4.726-3.845 8.571-8.571 8.571z" />
    </g>
  </svg>
)
export default SvgAccountPreferences
