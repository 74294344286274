export enum NavigationItemType {
  TEXT = 'TEXT',
  TEXT_CARD = 'TEXT_CARD',
  THUMBNAIL_CARD = 'THUMBNAIL_CARD',
  IMAGE_CARD = 'IMAGE_CARD',
  BRANDS = 'BRANDS',
  FEATURED = 'FEATURED',
  DETACHED = 'DETACHED',
  WATERFALL = 'WATERFALL',
}

export interface NavigationLink {
  text: string
  url: string
  openExternally?: boolean
  noFollow?: boolean
  noIndex?: boolean
}

export interface NavigationImage {
  url: string
  alt?: string
}

export interface NavigationItem {
  displayName: string
  type: NavigationItemType
  link?: NavigationLink
  image?: NavigationImage
  subNavigation?: NavigationItem[]
  id?: string
  iconName?: string
}

export interface NavigationTree {
  navigation: {
    topLevel: NavigationItem[] & { subNavigation?: NavigationItem[] }
  }
}
