import * as React from 'react'
export const SvgContentEmail = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <path
      fill={props.fill || '#333'}
      d="M29.04 27H10V13h20v14h-.96zm-2.455-2l-4.297-4.297-2.342 1.89-2.27-1.854L13.414 25h13.17zM28 23.587v-7.495l-4.147 3.347L28 23.587zm-16 0l4.118-4.12L12 16.108v7.48zM13.807 15l6.146 5.016L26.17 15H13.807z"
    />
  </svg>
)
export default SvgContentEmail
