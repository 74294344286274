import { Property } from 'csstype'

import { margin, mq, spacing, styled, Text } from '@thg-commerce/gravity-theme'
import { Image, SafeHtml } from '@thg-commerce/gravity-elements'
import { Margin } from '@thg-commerce/gravity-theme/margin'

export const Description = styled(SafeHtml)<{
  textColor?: Property.Color
}>`
  ${Text('bodyText', 'default')}
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(3)};
  color: ${(props) => props.theme.colors.palette.greys.dark};

  p {
    color: ${(props) => props.textColor || 'inherit'};
  }
`

export const StyledDiv = styled.div<{
  direction?: string
  display?: string
  alignment?: string
}>`
  display: ${(props) => props.display || 'block'};
  flex-direction: ${(props) => props.direction || 'start'};
  align-items: ${(props) => props.alignment || 'unset'};
`

export const StyledImage = styled(Image)`
  margin-bottom: ${spacing(3)};
`

export const Container = styled.div<{
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  onlyDisplayOfferBadge?: boolean
  reduceBottomMargin?: boolean
  margin?: Margin
}>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.onlyDisplayOfferBadge &&
    `
      margin-bottom: ${props.reduceBottomMargin ? '0' : spacing(2)};
    `}

  ${(props) => props.margin && margin(props.margin)};
  ${(props) =>
    props.direction === 'row' &&
    `
      ${mq(props.theme.breakpointUtils.map, 'sm')} {
        flex-direction: row;
      }
    `}
`

export const FreeGiftTitle = styled.div`
  ${Text('bodyText', 'alternate')}
  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(1)};
`

export const FreeGiftPrices = styled.div`
  ${Text('bodyText', 'alternate')}
  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(3)};
`
