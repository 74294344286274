import * as React from 'react'

export const SvgIconInfoOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill={props.fill || '#000000'}
      clipRule="evenodd"
      d="M21.125 12C21.125 7.02944 17.0956 3 12.125 3C7.15444 3 3.125 7.02944 3.125 12C3.125 16.9706 7.15444 21 12.125 21C17.0956 21 21.125 16.9706 21.125 12ZM5.125 12C5.125 8.13401 8.25901 5 12.125 5C15.991 5 19.125 8.13401 19.125 12C19.125 15.866 15.991 19 12.125 19C8.25901 19 5.125 15.866 5.125 12ZM13.625 8.50319C13.625 9.33162 12.9534 10.0032 12.125 10.0032C11.2966 10.0032 10.625 9.33162 10.625 8.50319C10.625 7.67476 11.2966 7.00319 12.125 7.00319C12.9534 7.00319 13.625 7.67476 13.625 8.50319ZM11.129 16.9992H13.129V11.4992H11.129V16.9992Z"
    />
  </svg>
)
export default SvgIconInfoOutline
