import * as React from 'react'
export const SvgAccountSocialLinks = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={64} height={64} {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(6 7)">
      <path
        stroke="#000"
        strokeLinecap="square"
        strokeWidth={2}
        d="M19.862 31.177l12.002 6.525"
      />
      <circle cx={10.592} cy={26.07} r={10.44} stroke="#333" strokeWidth={2} />
      <path
        fill="#333"
        fillRule="nonzero"
        d="M10.59 25.331c1.013 0 1.833-.776 1.833-1.733 0-.957-.82-1.733-1.833-1.733s-1.833.776-1.833 1.733c0 .957.82 1.733 1.833 1.733zm0 1.155c-1.688 0-3.056-1.293-3.056-2.888s1.368-2.888 3.056-2.888 3.056 1.293 3.056 2.888-1.368 2.888-3.056 2.888zM6.312 31.43H5.09c0-2.233 2.462-4.043 5.5-4.043s5.5 1.81 5.5 4.043h-1.222c0-1.523-1.858-2.888-4.278-2.888-2.42 0-4.278 1.365-4.278 2.888z"
      />
      <circle cx={41.44} cy={10.44} r={10.44} stroke="#333" strokeWidth={2} />
      <circle cx={42.304} cy={39.984} r={10.44} stroke="#333" strokeWidth={2} />
      <path
        fill="#333"
        fillRule="nonzero"
        d="M41.89 12.945c.237-.13.506-.305.77-.509.682-.523 1.23-1.145 1.524-1.804.586-1.306.128-2.377-.816-2.532-.447-.073-.932.213-1.46.926l-.292.394-.295-.392c-.525-.703-1.014-.99-1.47-.926-1.038.145-1.427 1.163-.808 2.528.271.598.818 1.212 1.508 1.757.271.212.548.402.788.543.102.06.192.107.26.137.032.014.055.022.015.022-.023 0 .118-.059.277-.144zm2.122-6.417c1.86.304 2.726 2.334 1.747 4.52-.808 1.805-3.276 3.702-4.153 3.702-.823 0-3.397-2.031-4.153-3.7-1.022-2.256-.264-4.24 1.757-4.524.844-.118 1.643.263 2.393 1.093.757-.847 1.561-1.23 2.41-1.091zm-1.156 33.994h1.507l.225-1.75h-1.732v-1.115c0-.506.14-.851.865-.851h.919v-1.56a12.358 12.358 0 00-1.346-.07c-1.333 0-2.248.814-2.248 2.308v1.284h-1.498v1.75h1.501v4.474h1.807v-4.47z"
      />
      <path
        stroke="#333"
        strokeLinecap="square"
        strokeWidth={2}
        d="M20.773 21.7l11.09-6.878"
      />
    </g>
  </svg>
)
export default SvgAccountSocialLinks
