import * as React from 'react'
export const SvgLoyalty = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      fill={props?.fill || '#333'}
      d="M12 0c.376 3.574 1.577 6.374 3.604 8.4 2.027 2.027 4.825 3.227 8.396 3.6-3.522.302-6.32 1.495-8.396 3.58-2.075 2.084-3.258 4.89-3.55 8.42-.506-3.762-1.688-6.569-3.548-8.42C6.646 13.728 3.811 12.535 0 12c3.712-.441 6.547-1.64 8.506-3.6C10.464 6.442 11.629 3.642 12 0z"
    />
  </svg>
)
export default SvgLoyalty
