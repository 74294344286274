import * as React from 'react'

import loadable from '@loadable/component'

import { styled, css, Text, spacing } from '@thg-commerce/gravity-theme'
import { BorderValueTypes } from '@thg-commerce/gravity-theme/elements/formgroup'
const ChevronDown = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronDown'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

export const borderInfo = (type: BorderValueTypes) => (props) =>
  type !== 'focus'
    ? css`
        border-width: ${props.error
          ? '2px;'
          : props.theme.formgroup.general.border[type].width};
        border-color: ${props.error
          ? props.theme.colors.error.base
          : props.theme.formgroup.general.border[type].color};
        border-radius: ${props.theme.formgroup.general.border[type].radius};
        border-style: ${props.theme.formgroup.general.border[type].style};
        ${props.error ? `margin-left: -1px; margin-right: -1px;` : ''}
      `
    : css`
        border-color: ${props.theme.formgroup.general.border[type].color};
        border-width: ${props.theme.formgroup.general.border[type].width};
        border-radius: ${props.theme.formgroup.general.border[type].radius};
        border-style: ${props.theme.formgroup.general.border[type].style};
      `

interface StyledDropdownProps {
  labelHidden?: boolean
  styleOverride?: {
    textColor?: string
    borderColor?: string
    textSize?: string
  }
}

export const StyledChevronDown = styled(ChevronDown)<{
  fill: string | undefined
}>`
  margin: auto;
  &.disabled {
    path {
      fill: ${(props) => props.theme.colors.palette.greys.light};
      cursor: not-allowed;
    }
  }
  path {
    fill: ${(props) => props.fill};
  }
`

export const StyledDropdown = styled.select<StyledDropdownProps>`
  width: 100%;
  height: 48px;
  outline: none;
  background: ${(props) => props.theme.colors.palette.greys.white};
  border-radius: ${spacing(0.5)};
  appearance: none;
  border-style: none;
  padding: ${(props) => props.theme.formgroup.general.padding};
  ${(props) =>
    props.styleOverride?.borderColor
      ? `
      border-width: ${props.theme.formgroup.general.border.default.width};
      border-color: ${props.styleOverride.borderColor};
      border-radius: ${props.theme.formgroup.general.border.default.radius};
      border-style: ${props.theme.formgroup.general.border.default.style};
      `
      : borderInfo('default')};
  margin-top: ${(props) => (props.labelHidden ? `` : spacing(0.5))};
  ${(props) =>
    props.styleOverride && props.styleOverride.textSize
      ? Text('small', 'default')
      : Text('bodyText', 'default')};

  ${(props) =>
    props.styleOverride?.textColor
      ? `color: ${props.styleOverride.textColor}`
      : ``};

  option {
    background-color: ${(props) => props.theme.colors.palette.greys.white};
    color: ${(props) => props.theme.colors.palette.greys.darker};
  }

  &:active {
    ${borderInfo('active')}
  }

  &:disabled {
    ${borderInfo('disabled')}
    cursor: not-allowed;
  }

  &:hover {
    ${(props) =>
      props.styleOverride?.borderColor
        ? `border-color: none`
        : borderInfo('hover')}
  }

  &:focus,
  &:focus-within {
    ${(props) =>
      props.styleOverride?.borderColor
        ? `
      border-width: ${props.theme.formgroup.general.border.focus.width};
      border-color: ${props.styleOverride.borderColor};
      border-radius: ${props.theme.formgroup.general.border.focus.radius};
      border-style: ${props.theme.formgroup.general.border.focus.style};
      `
        : borderInfo('focus')};
    margin-left: -1px;
    margin-right: -1px;
  }
  /* DO NOT GROUP WITH ABOVE BREAKS POLYFILL FOR MSOFT BROWSERS */
  &.focus-within {
    ${(props) =>
      props.styleOverride?.borderColor
        ? `
      border-width: ${props.theme.formgroup.general.border.focus.width};
      border-color: ${props.styleOverride.borderColor};
      border-radius: ${props.theme.formgroup.general.border.focus.radius};
      border-style: ${props.theme.formgroup.general.border.focus.style};
      `
        : borderInfo('focus')};
    margin-left: -1px;
    margin-right: -1px;
  }
`

export const HelperTextContainer = styled.div`
  ${(props) =>
    Text(props.theme.formgroup.error.scale, props.theme.formgroup.error.style)}
  margin-top: ${spacing(1)};
  margin-left: ${spacing(1)};
  color: ${(props) => props.theme.colors.palette.greys.dark};
`
export const StyledDropdownContainer = styled.div`
  position: relative;
`
export const StyledChevronDownContainer = styled.div`
  pointer-events: none;
  position: absolute;
  right: ${spacing(1)};
  top: 0;
  bottom: 0;
  display: flex;
`
