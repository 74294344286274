import * as React from 'react'
export const SvgDiscover = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="60" height="40" viewBox="0 0 60 40" fill="none" {...props}>
    <rect
      x="0.5"
      y="0.5"
      width="59"
      height="39"
      rx="3.5"
      fill="url(#paint0_linear_721_2096)"
      stroke="#DDDDDD"
    />
    <path
      d="M2 4C2 2.89543 2.89543 2 4 2H39C48.9411 2 57 10.0589 57 20V20C57 29.9411 48.9411 38 39 38H4C2.89543 38 2 37.1046 2 36V4Z"
      fill="white"
    />
    <path
      d="M6.31118 16.2994H4V24.3652H6.29247C7.51824 24.3652 8.3978 24.0751 9.16507 23.4388C10.0821 22.6809 10.6248 21.53 10.6248 20.3417C10.6341 17.9556 8.85629 16.2994 6.31118 16.2994ZM8.14516 22.3534C7.64924 22.8025 7.01296 22.999 5.99305 22.999H5.57198V17.6655H5.99305C7.01296 17.6655 7.63053 17.8433 8.14516 18.3205C8.68787 18.8071 9.01536 19.5557 9.01536 20.3323C9.01536 21.0996 8.68787 21.8668 8.14516 22.3534Z"
      fill="#231F20"
    />
    <path
      d="M12.9359 16.2994H11.3639V24.3652H12.9359V16.2994Z"
      fill="#231F20"
    />
    <path
      d="M16.7815 19.3872C15.8364 19.041 15.5651 18.8071 15.5651 18.3673C15.5651 17.862 16.061 17.4691 16.7347 17.4691C17.2026 17.4691 17.5956 17.6655 1.0073 18.124L18.8307 17.048C18.157 16.4585 17.3429 16.1497 16.4634 16.1497C15.0411 16.1497 13.9463 17.1416 13.9463 18.4609C13.9463 19.5744 14.4516 20.1452 15.93 20.6691C16.5476 20.8844 16.8564 21.0341 17.0154 21.1276C17.3336 21.3335 17.4833 21.6236 17.4833 21.9604C17.4833 22.6154 16.9593 23.102 16.2575 23.102C15.509 23.102 14.9007 22.7277 14.5452 22.0259L13.5253 23.0178C14.2458 24.0845 15.116 24.5523 16.3137 24.5523C17.9511 24.5523 19.0927 23.4669 19.0927 21.9043C19.102 20.6224 18.5687 20.0422 16.7815 19.3872Z"
      fill="#231F20"
    />
    <path
      d="M19.598 20.3323C19.598 22.6996 21.4601 24.543 23.8555 24.543C24.5292 24.543 25.1093 24.412 25.8298 24.0751V22.2224C25.2029 22.8493 24.6415 23.102 23.9303 23.102C22.349 23.102 21.2261 21.9511 21.2261 20.3229C21.2261 18.779 22.3864 17.5533 23.8648 17.5533C24.6134 17.5533 25.1842 17.8246 25.8392 18.4609V16.6082C25.1467 16.262 24.5853 16.1123 23.9022 16.1123C21.5069 16.1123 19.598 17.993 19.598 20.3323Z"
      fill="#231F20"
    />
    <path
      d="M38.2933 21.7171L36.1412 16.2994H34.4288L37.8535 24.571H38.6956L42.1764 16.2994H40.4735L38.2933 21.7171Z"
      fill="#231F20"
    />
    <path
      d="M42.8877 24.3558H47.351V22.999H44.4597V20.8189H47.2481V19.4527H44.4597V17.6655H47.351V16.2994H42.8877V24.3558Z"
      fill="#231F20"
    />
    <path
      d="M53.5828 18.6761C53.5828 17.1696 52.5442 16.2994 50.7289 16.2994H48.399V24.3652H49.971V21.1276H50.1769L52.3571 24.3652H54.294L51.7582 20.9686C52.9278 20.7253 53.5828 19.9112 53.5828 18.6761ZM50.4202 20.0048H49.9617V17.5626H50.4482C51.4307 17.5626 51.9641 17.9743 51.9641 18.7603C51.9641 19.5744 51.4307 20.0048 50.4202 20.0048Z"
      fill="#231F20"
    />
    <path
      d="M54.5839 16.5989C54.5839 16.4585 54.4904 16.3836 54.3126 16.3836H54.088V17.0948H54.2564V16.8234L54.4529 17.0948H54.6588L54.4249 16.8047C54.5278 16.7673 54.5839 16.7018 54.5839 16.5989ZM54.2845 16.6924H54.2564V16.5053H54.2845C54.3687 16.5053 54.4155 16.5334 54.4155 16.5989C54.4155 16.6644 54.3687 16.6924 54.2845 16.6924Z"
      fill="#231F20"
    />
    <path
      d="M54.3501 16.1123C54.0039 16.1123 53.7325 16.3836 53.7325 16.7299C53.7325 17.0761 54.0133 17.3474 54.3501 17.3474C54.687 17.3474 54.9583 17.0667 54.9583 16.7299C54.9583 16.393 54.687 16.1123 54.3501 16.1123ZM54.3501 17.2445C54.0788 17.2445 53.8542 17.0199 53.8542 16.7392C53.8542 16.4585 54.0694 16.2339 54.3501 16.2339C54.6215 16.2339 54.8367 16.4679 54.8367 16.7392C54.8367 17.0106 54.6121 17.2445 54.3501 17.2445Z"
      fill="#231F20"
    />
    <path
      d="M30.6767 24.571C33.0436 24.571 34.9623 22.6523 34.9623 20.2855C34.9623 17.9187 33.0436 16 30.6767 16C28.3099 16 26.3912 17.9187 26.3912 20.2855C26.3912 22.6523 28.3099 24.571 30.6767 24.571Z"
      fill="url(#paint1_radial_721_2096)"
    />
    <path
      d="M30.6767 24.571C33.0436 24.571 34.9623 22.6523 34.9623 20.2855C34.9623 17.9187 33.0436 16 30.6767 16C28.3099 16 26.3912 17.9187 26.3912 20.2855C26.3912 22.6523 28.3099 24.571 30.6767 24.571Z"
      fill="url(#paint2_radial_721_2096)"
    />
    <path
      d="M30.6767 24.571C33.0436 24.571 34.9623 22.6523 34.9623 20.2855C34.9623 17.9187 33.0436 16 30.6767 16C28.3099 16 26.3912 17.9187 26.3912 20.2855C26.3912 22.6523 28.3099 24.571 30.6767 24.571Z"
      fill="url(#paint3_radial_721_2096)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_721_2096"
        x1="26"
        y1="-3.5"
        x2="60"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E15115" />
        <stop offset="1" stopColor="#F9A020" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_721_2096"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(31.0904 20.7856) scale(19.2216)"
      >
        <stop offset="0.1385" stopColor="#F37B20" />
        <stop offset="0.1693" stopColor="#F27123" />
        <stop offset="0.2056" stopColor="#D95227" />
        <stop offset="0.2193" stopColor="#C24525" />
        <stop offset="0.2386" stopColor="#A53423" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_721_2096"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(32.2632 22.3661) scale(17.411)"
      >
        <stop offset="0.0435203" stopColor="#F89F1F" />
        <stop offset="0.0789941" stopColor="#F89F1F" />
        <stop offset="0.1266" stopColor="#F79420" stopOpacity="0.8563" />
        <stop offset="0.2096" stopColor="#F47821" stopOpacity="0.6057" />
        <stop offset="0.3173" stopColor="#F04924" stopOpacity="0.2806" />
        <stop offset="0.4102" stopColor="#EC1C26" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_721_2096"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(32.2632 22.3661) scale(17.411)"
      >
        <stop offset="0.0435203" stopColor="#F89F1F" />
        <stop offset="0.0789941" stopColor="#F89F1F" />
        <stop offset="0.3504" stopColor="#F89F1F" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
)
export default SvgDiscover
