import * as React from 'react'
export const SvgTick = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={14} height={14} {...props}>
    <path
      fill={props.fill || 'none'}
      strokeWidth={props.strokeWidth || 'none'}
      d="M1 8l4 4L13 .998"
    />
  </svg>
)
export default SvgTick
