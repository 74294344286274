import * as React from 'react'
import getConfig from 'next/config'

import {
  EnterpriseNextAppContext,
  EnterpriseNextPage,
} from '@thg-commerce/enterprise-core'
import { Resolvers } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers'
import { Feature } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Extensions } from '@thg-commerce/enterprise-network/types'
import { EnterpriseThemeInterface } from '@thg-commerce/enterprise-theme'
import { sanitizeCookie } from '@thg-commerce/enterprise-utils'
import { isMobileDevice } from '@thg-commerce/gravity-system'

import { PageType } from '../App/types'
import { createApolloWithRequest } from '../Factory/apollo'
import { Footer } from '../Footer'
import { HeaderGroup } from '../HeaderGroup/HeaderGroup'
import { loadSiteProperties } from '../i18n/siteProperties'
import {
  EnterpriseRequest as Request,
  EnterpriseResponse as Response,
} from '../Server/types'

import {
  getShippingDestination,
  loadConfiguration,
  loadTheme,
  setupInternationalOverlay,
  validShippingDestination,
} from './ConfigurationLoader'
import {
  Configuration,
  InternationalOverlay,
  NextConfig,
  PublicConfiguration,
  RequestConfig,
  ShippingDestination,
  SiteProperties,
} from './types'

interface WithConfigurationProps {
  config?: Omit<Configuration, 'serverRuntimeConfig'>
  requestConfig: RequestConfig
  isAmp: boolean
  concessionCode?: string
  internationalOverlay?: InternationalOverlay
  shippingDestination?: ShippingDestination
  siteProperties: SiteProperties
  pageLayoutData: any
  isMobile: boolean
  theme?: EnterpriseThemeInterface
  horizonFeatures?: Feature[]
  browserUrl?: string
  pageType?: PageType
  extensions: Extensions | Partial<Extensions>
}

export interface WithConfigurationInitialProps {
  config?: Omit<Configuration, 'serverRuntimeConfig'>
  requestConfig?: RequestConfig
  internationalOverlay?: InternationalOverlay
  concessionCode?: string
  siteProperties: SiteProperties
  isMobile: boolean
  theme?: EnterpriseThemeInterface
  pageLayoutData: any
  horizonFeatures?: Feature[]
  browserUrl?: string
}

export interface WithConfigurationComponentProps {
  config?: Omit<Configuration, 'serverRuntimeConfig'>
  requestConfig: RequestConfig
  internationalOverlay?: InternationalOverlay
}

const updateExperiment = (extensions?: Extensions | Partial<Extensions>) => {
  if (!process.browser) {
    return extensions
  }

  if (!extensions) {
    return {}
  }
  const experimentsCookieValue = sanitizeCookie('enterprise_experiments')
  if (!experimentsCookieValue) {
    return extensions
  }

  const modifiedExtensions = { ...extensions }
  modifiedExtensions.experiments = experimentsCookieValue
    .split(',')
    .reduce((accumulator, experiment) => {
      const [experimentName, experimentBucket] = experiment.split(':')
      accumulator[experimentName] = experimentBucket
      return accumulator
    }, {})
  return modifiedExtensions
}

export const withConfiguration = <ComponentProps, InitialProps>(
  Component: React.ComponentType<
    WithConfigurationComponentProps & ComponentProps
  > & {
    getInitialProps?: (ctx: EnterpriseNextAppContext) => Promise<InitialProps>
  },
): React.FunctionComponent<WithConfigurationProps & ComponentProps> & {
  getInitialProps: (
    ctx: EnterpriseNextAppContext,
  ) => Promise<WithConfigurationInitialProps & InitialProps>
} => {
  let config: PublicConfiguration | null = null
  let requestConfig: RequestConfig | null = null
  let internationalOverlay: InternationalOverlay | undefined
  let siteProperties: SiteProperties | undefined
  let pageLayoutData: any | undefined
  let theme: EnterpriseThemeInterface | undefined
  let horizonFeatures: Feature[] | undefined
  let browserUrl: string | undefined
  let pageType: PageType | undefined

  const WithConfiguration = (
    props: WithConfigurationProps & ComponentProps,
  ) => {
    const { isAmp, isMobile } = props

    const isBrowser = process.browser
    if (!config || !isBrowser) {
      config = props.config || null
    }
    if (!requestConfig || !isBrowser) {
      requestConfig = props.requestConfig
    }
    if (!internationalOverlay || !isBrowser) {
      internationalOverlay = props.internationalOverlay
    }
    if (!siteProperties || !isBrowser) {
      siteProperties = props.siteProperties
    }
    if (!pageLayoutData || !isBrowser) {
      pageLayoutData = props.pageLayoutData
    }
    if (!theme || !isBrowser) {
      theme = props.theme
    }
    if (!horizonFeatures || !isBrowser) {
      horizonFeatures = props.horizonFeatures
    }
    if (!browserUrl || !isBrowser) {
      browserUrl = props.browserUrl
    }
    if (!pageType || !isBrowser) {
      pageType = props.pageType
    }

    return (
      <Component
        {...(props as ComponentProps)}
        extensions={updateExperiment(props.extensions)}
        config={config}
        isAmp={isAmp}
        requestConfig={requestConfig}
        internationalOverlay={internationalOverlay}
        siteProperties={siteProperties}
        pageLayoutData={pageLayoutData}
        theme={theme}
        horizonFeatures={horizonFeatures}
        isMobile={isMobile}
        browserUrl={browserUrl}
        pageType={pageType}
      />
    )
  }

  WithConfiguration.getInitialProps = async (
    ctx: EnterpriseNextAppContext,
  ): Promise<WithConfigurationInitialProps & InitialProps> => {
    const { req, res } = ctx.ctx as { req: Request; res: Response }

    const nextConfig = getConfig() as NextConfig

    const PageComponent = ctx.Component as EnterpriseNextPage

    let concessionCode: string | undefined
    let config: Configuration | undefined
    let siteProperties: SiteProperties = {}
    let pageLayoutData: any | undefined
    let pageType: PageType | undefined
    let theme: EnterpriseThemeInterface | undefined
    let componentProps = {} as InitialProps

    if (req) {
      config = await loadConfiguration(nextConfig, req)

      ctx.ctx.config = config

      const allowOverrides = req.headers['x-enterprise-allow-overrides'] === '1'
      const allowSitePropertyOverrides =
        req.headers['x-enterprise-allow-site-property-overrides'] === '1'

      const apollo = createApolloWithRequest(req, config, false)
      apollo.addResolvers(
        Resolvers(
          apollo,
          config.publicRuntimeConfig,
          allowOverrides,
          allowSitePropertyOverrides,
          req.config.enableVary,
          config.contentKeys?.productBlock,
        ),
      )
      horizonFeatures = req.horizonFeatures || []

      siteProperties =
        (await loadSiteProperties(config, req.config.previewId)) || {}

      const enableLayoutESI =
        config.publicRuntimeConfig.siteConfig.enableLayoutESI &&
        config.publicRuntimeConfig.ENABLE_LAYOUT_ESI

      if (!enableLayoutESI) {
        try {
          const [header, footer] = await Promise.all([
            HeaderGroup.getInitialProps({
              req,
              res,
              config,
              apolloClient: apollo,
              esi: false,
              // REBUILD-7710 improve type so this doesn't have to be any
              props: {} as any,
            }),
            Footer.getInitialProps({
              req,
              res,
              config,
              apolloClient: apollo,
              esi: false,
              // REBUILD-7710 improve type so this doesn't have to be any
              props: {} as any,
            }),
          ])
          pageLayoutData = {
            header,
            footer,
          }
        } catch (e) {
          console.warn(
            `Failed to load navigation data with error ${e.message}`,
            e.stack,
          )
        }
      }

      const horizonSupportsConcessions = horizonFeatures?.includes(
        Feature.Concessions,
      )

      const concessionHeader = req.concessionConfig?.headerCode

      if (
        (horizonSupportsConcessions &&
          (PageComponent.getConcessionCode ||
            PageComponent.supportsConcessions)) ||
        concessionHeader
      ) {
        concessionCode =
          concessionHeader && typeof concessionHeader === 'string'
            ? concessionHeader
            : PageComponent.getConcessionCode
            ? await PageComponent.getConcessionCode(ctx.ctx, apollo)
            : req.cookies['concession_V6']

        if (res.concessionCode) {
          res.concessionCode = concessionCode
        }
      }

      if (req.config) {
        req.config.concessionCode = concessionCode
      }

      theme = await loadTheme(config, req.config?.previewId, concessionCode)
      ctx.ctx.theme = theme
    }

    const requestConfig: RequestConfig = req
      ? req.config
      : {
          customerLocation: 'unknown',
          userAgent: '',
          previewId: '',
          showKeys: false,
          featureFlags: [],
          enableVary: false,
        }

    let internationalOverlay: InternationalOverlay | undefined = undefined
    let shippingDestination: ShippingDestination | undefined = undefined

    if (config) {
      const {
        brand,
        subsite,
        defaultLocale,
      } = config.publicRuntimeConfig.siteDefinition
      const { siteConfig, shippingDestinations } = config.publicRuntimeConfig
      internationalOverlay = setupInternationalOverlay(
        brand,
        subsite,
        siteConfig,
        config,
        requestConfig.customerLocation,
      )

      shippingDestination =
        validShippingDestination(
          shippingDestinations,
          req.config.sessionSettings?.shippingDestination ||
            defaultLocale.split('_')[1],
          defaultLocale,
        ) ||
        getShippingDestination(
          subsite,
          shippingDestinations,
          defaultLocale.split('_')[1],
          requestConfig.customerLocation,
        )
    }

    if (Component.getInitialProps) {
      componentProps = await Component.getInitialProps(ctx)
    }

    if (PageComponent.pageType) {
      pageType =
        typeof PageComponent.pageType === 'function'
          ? PageComponent.pageType(req?.browserUrl, componentProps['pageProps'])
          : PageComponent.pageType
    }

    const isAmp: boolean = ctx.ctx.req?.url?.includes('amp=1') ? true : false
    const isMobile: boolean = isMobileDevice(requestConfig.userAgent)

    let publicConfig: Omit<Configuration, 'serverRuntimeConfig'> | undefined

    if (config) {
      const { serverRuntimeConfig: _, ...remainingPublicConfig } = config
      publicConfig = remainingPublicConfig
    }

    return {
      ...componentProps,
      pageType,
      internationalOverlay,
      requestConfig,
      isAmp,
      shippingDestination,
      concessionCode,
      siteProperties,
      pageLayoutData,
      theme,
      horizonFeatures,
      isMobile,
      config: publicConfig,
      browserUrl: req?.browserUrl,
    }
  }

  return WithConfiguration
}
