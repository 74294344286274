import * as Cookies from 'js-cookie'

import { currencyMap } from '@thg-commerce/enterprise-pricing/src'

export enum SessionSettingsCookie {
  Shipping = 'shippingCountry',
  Region = 'chosenSubsite',
  Currency = 'currency',
}

/**
 * TODO: [REBUILD-7495] Remove Deprecated Code in Session Settings
 * @deprecated Session cookies should no longer be read from the browser
 */
export const getSessionSettingsCookie = (
  type: SessionSettingsCookie,
  subsite: string,
): string | undefined => {
  const cookie = Cookies.get(`${subsite}_${type}_V6`)
  if (type === SessionSettingsCookie.Currency && !currencyMap[cookie]) {
    return undefined
  }
  return cookie
}

/**
 * TODO: [REBUILD-7495] Remove Deprecated Code in Session Settings
 * @deprecated Session cookies should no longer be written from the browser
 */
export const setSessionSettingsCookie = (
  type: SessionSettingsCookie,
  subsite: string,
  value: string,
  domain: string,
) => {
  Cookies.remove(`${subsite}_${type}_V6`)
  Cookies.set(`${subsite}_${type}_V6`, value, { domain })
}
