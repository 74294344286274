import * as React from 'react'
export const SvgUseMyLocation = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M13 2.05c2.295.2312 4.4396 1.24842 6.0706 2.87944C20.7016 6.56045 21.7188 8.70501 21.95 11H24v2h-2.05c-.2312 2.295-1.2484 4.4396-2.8794 6.0706-1.631 1.631-3.7756 2.6482-6.0706 2.8794V24h-2v-2.05c-2.29499-.2312-4.43955-1.2484-6.07056-2.8794C3.29842 17.4396 2.2812 15.295 2.05 13H0v-2h2.05c.2312-2.29499 1.24842-4.43955 2.87944-6.07056C6.56045 3.29842 8.70501 2.2812 11 2.05V0h2v2.05zM12 16c-1.0609 0-2.07828-.4214-2.82843-1.1716C8.42143 14.0783 8 13.0609 8 12c0-1.0609.42143-2.07828 1.17157-2.82843C9.92172 8.42143 10.9391 8 12 8s2.0783.42143 2.8284 1.17157C15.5786 9.92172 16 10.9391 16 12s-.4214 2.0783-1.1716 2.8284C14.0783 15.5786 13.0609 16 12 16zm0 4c2.1217 0 4.1566-.8429 5.6569-2.3431C19.1571 16.1566 20 14.1217 20 12c0-2.12173-.8429-4.15656-2.3431-5.65685C16.1566 4.84285 14.1217 4 12 4c-2.12173 0-4.15656.84285-5.65685 2.34315C4.84285 7.84344 4 9.87827 4 12c0 2.1217.84285 4.1566 2.34315 5.6569C7.84344 19.1571 9.87827 20 12 20z"
      fill={props.fill || '#333333'}
      fillRule="nonzero"
    />
  </svg>
)
export default SvgUseMyLocation
