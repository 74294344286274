import * as React from 'react'
export const SvgMemberPerks = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <g fill="#333" fillRule="evenodd">
      <path
        d="M15 8.5a3.563 3.563 0 110 7.125A3.563 3.563 0 0115 8.5zm0 2a1.563 1.563 0 100 3.125 1.563 1.563 0 000-3.125zM15 16.498c3.729 0 6.858 2.285 6.995 5.294l.005.206h-2c0-1.86-2.186-3.5-5-3.5-2.731 0-4.87 1.545-4.994 3.337l-.006.163H8c0-3.11 3.186-5.5 7-5.5z"
        fillRule="nonzero"
      />
      <path d="M7.745 3.51L8 3l.255.51a5 5 0 002.236 2.235L11 6l-.51.255A5 5 0 008.256 8.49L8 9l-.255-.51A5 5 0 005.51 6.256L5 6l.51-.255A5 5 0 007.744 3.51zM5.114 9.772L5.5 9l.386.772a3 3 0 001.342 1.342L8 11.5l-.772.386a3 3 0 00-1.342 1.342L5.5 14l-.386-.772a3 3 0 00-1.342-1.342L3 11.5l.772-.386a3 3 0 001.342-1.342z" />
    </g>
  </svg>
)
export default SvgMemberPerks
