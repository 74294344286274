import * as React from 'react'
import { useArray } from 'react-hanger'

export interface ModalObject {
  stateToggle: React.Dispatch<React.SetStateAction<boolean>>
  id: string
  onClose: () => void
}

export const ModalContext = React.createContext({
  showModal: (_modalObject: ModalObject) => {},
  closeModal: (_id: string) => {},
})

export const ModalProvider = (props: { children: React.ReactNode }) => {
  const modals = useArray<{
    id: string
    toggle: React.Dispatch<React.SetStateAction<boolean>>
  }>([])
  const [openModal, setOpenModal] = React.useState<string | undefined>()

  React.useEffect(() => {
    if (!openModal && modals.value.length > 0) {
      const firstModal = modals.value[0]
      firstModal.toggle(true)
      setOpenModal(firstModal.id)
    }
  }, [modals])

  const showModal = (modalObject: ModalObject) => {
    if (modalObject.stateToggle) {
      const dupes = modals.value.filter((n) => n.id === modalObject.id)
      if (dupes.length === 0) {
        modals.push({
          id: modalObject.id,
          toggle: modalObject.stateToggle,
        })
      }
    }
  }

  const closeModal = (id: string) => {
    const modal = modals.value.find((n) => n.id === id)
    modal && modal.toggle(false)
    setTimeout(() => {
      setOpenModal(undefined)
      modals.removeById(id)
    }, 500)
  }

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {props.children}
    </ModalContext.Provider>
  )
}
