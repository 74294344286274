import * as React from 'react'
export const SvgCopyright = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={204} height={25} {...props}>
    <path
      fill={props.fill || '#4d4f53'}
      d="M7.736 4.974H0V.414h21.063v4.56H13.33v19.612H7.736V4.974zM50.641.414v24.172h-5.594v-9.91H34.066v9.91h-5.595V.414h5.595v9.531h10.981V.414h5.594zm25.033 11.707h5.109v9.807c-1.311.99-2.83 1.75-4.557 2.277a17.707 17.707 0 01-5.214.795c-2.51 0-4.766-.535-6.768-1.605-2.004-1.07-3.574-2.555-4.715-4.455-1.139-1.898-1.709-4.045-1.709-6.44 0-2.393.57-4.541 1.709-6.44 1.141-1.899 2.723-3.384 4.75-4.454C66.305.534 68.584 0 71.117 0c2.117 0 4.039.357 5.766 1.071 1.729.714 3.178 1.75 4.352 3.107l-3.592 3.315c-1.727-1.818-3.811-2.728-6.25-2.728-1.543 0-2.912.323-4.107.966a6.947 6.947 0 00-2.799 2.728c-.668 1.174-1.002 2.521-1.002 4.04 0 1.497.334 2.831 1.002 4.004a7.076 7.076 0 002.779 2.746c1.188.656 2.539.986 4.057.986 1.613 0 3.063-.346 4.352-1.037v-7.077h-.001z"
    />
  </svg>
)
export default SvgCopyright
