import * as React from 'react'

import { styled } from '@thg-commerce/gravity-patterns/theme'
import { spacing, Text } from '@thg-commerce/gravity-theme'

import { Brand } from '../Brands'

interface BrandPanelProps {
  isFocused: boolean
  brands: Brand[]
  panelIndex: number
  focusCurrentGroup: () => void
  listExtension?: string
}

const StyledBrandLink = styled.a`
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.font.active.entry || 'bodyText',
      props.theme.patterns.header.navigation.font.active.style || 'default',
    )};
  outline: none;
  text-decoration: none;
  padding: ${spacing(1)};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:active {
    ${(props) =>
      Text(
        props.theme.patterns.header.navigation.font.active.entry || 'bodyText',
        props.theme.patterns.header.navigation.font.active.style || 'alternate',
      )};
  }

  &:nth-child(n) {
    flex-basis: 20%;
  }
`

const BrandsListContainer = styled.div<{ isFocused: boolean }>`
  margin: ${spacing(4)};
  ${(props) => (props.isFocused ? 'display: flex;' : 'display: none;')}
  flex-direction: row;
  flex-wrap: wrap;
`

export const BrandsPanel = (props: BrandPanelProps) => {
  const [currentBrandIndex, setCurrentBrandIndex] = React.useState<number>(0)

  const onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault()
        if (currentBrandIndex === 0) {
          props.focusCurrentGroup()
        }
        const aboveElement = document.querySelector(
          `[data-brand-index="${props.panelIndex} ${currentBrandIndex - 5}"]`,
        ) as HTMLElement
        aboveElement && aboveElement.focus()
        break
      case 'ArrowDown':
        event.preventDefault()
        const belowElement = document.querySelector(
          `[data-brand-index="${props.panelIndex} ${currentBrandIndex + 5}"]`,
        ) as HTMLElement
        belowElement && belowElement.focus()
        break
      case 'ArrowRight':
        event.preventDefault()
        const rightElement = document.querySelector(
          `[data-brand-index="${props.panelIndex} ${currentBrandIndex + 1}"]`,
        ) as HTMLElement
        rightElement && rightElement.focus()

        break
      case 'ArrowLeft':
        event.preventDefault()
        const leftElement = document.querySelector(
          `[data-brand-index="${props.panelIndex} ${currentBrandIndex - 1}"]`,
        ) as HTMLElement
        leftElement && leftElement.focus()
        break
    }
  }

  const handleFocus = (index: number) => {
    setCurrentBrandIndex(index)
  }

  return (
    <BrandsListContainer
      role="tabpanel"
      data-testid="brands-list"
      isFocused={props.isFocused}
    >
      {props.brands?.map((brand, index) => {
        return (
          <StyledBrandLink
            key={index}
            href={
              props.listExtension
                ? `${brand.page?.path}${props.listExtension}`
                : brand.page?.path
            }
            data-testid={`brand-${brand.name}`}
            data-brand-index={`${props.panelIndex} ${index}`}
            onKeyDown={onKeyDown}
            onFocus={() => handleFocus(index)}
          >
            {brand.name[0].toUpperCase() + brand.name.slice(1)}
          </StyledBrandLink>
        )
      })}
    </BrandsListContainer>
  )
}
