import * as React from 'react'
export const SvgBlackYouTube = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <defs>
      <filter color-interpolation-filters="auto" id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.200000 0 0 0 0 0.200000 0 0 0 0 0.200000 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Icon-Youtube"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Icon-Youtube" filter="url(#filter-1)">
        <g
          id="Youtube"
          transform="translate(0.000000, 3.692308)"
          fill="#333333"
        >
          <path d="M4,0 L20,0 C22.209139,1.11220812e-15 24,1.790861 24,4 L24,12.6153846 C24,14.8245236 22.209139,16.6153846 20,16.6153846 L4,16.6153846 C1.790861,16.6153846 8.07507083e-16,14.8245236 0,12.6153846 L0,4 C-7.1463071e-16,1.790861 1.790861,-3.82769592e-17 4,0 Z M16.9107692,8.15899962 L9.23076923,12.6211444 L9.23076923,3.69685487 L16.9107692,8.15899962"></path>
        </g>
      </g>
    </g>
  </svg>
)
export default SvgBlackYouTube
