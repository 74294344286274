import * as React from 'react'

export const SwatchDisableCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 779 779"
    enableBackground="new 0 0 779 779"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.fill || '#CCC'}
      d="M708,389.5C708,565.4,565.4,708,389.5,708c-68.9,0-132.8-21.9-184.9-59.1l444.3-444.3
	C686.1,256.7,708,320.6,708,389.5z M130,574.2L574.2,130c-52.1-37.1-115.8-59-184.7-59C213.6,71,71,213.6,71,389.5
	C71,458.4,92.8,522.1,130,574.2z M761,389.5C761,594.7,594.7,761,389.5,761S18,594.7,18,389.5S184.3,18,389.5,18
	S761,184.3,761,389.5z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.fill || '#8C8C8C'}
      d="M726,389.5C726,575.3,575.3,726,389.5,726c-79.8,0-153.1-27.8-210.7-74.1l473.1-473.1
	C698.2,236.4,726,309.7,726,389.5z M127.3,600.4l473.1-473.1C542.7,80.8,469.3,53,389.5,53C203.7,53,53,203.7,53,389.5
	C53,469.3,80.8,542.7,127.3,600.4z M779,389.5C779,604.6,604.6,779,389.5,779S0,604.6,0,389.5S174.4,0,389.5,0S779,174.4,779,389.5z
	"
    />
  </svg>
)
export default SwatchDisableCircle
