import * as React from 'react'
import { v4 as uuid } from 'uuid'

import { ImageColourChoice } from '../ImageSwatch'
import { HiddenButton, StyledImage, StyledImageLabel } from '../styles'

export interface ImageSwatchElementProps {
  choice: ImageColourChoice
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  selected: boolean
  id?: string
  className?: string
}

export const ImageSwatchElement = (props: ImageSwatchElementProps) => {
  const buttonId = uuid()

  return (
    <React.Fragment>
      <HiddenButton
        id={buttonId}
        type={props.onClick && 'button'}
        aria-label={props.choice.title}
        onClick={props.onClick}
        selected={props.selected}
        data-testid={`${props.choice.title.replace(/\s+/g, '')}-swatch${
          props.selected ? '-selected' : ''
        }`}
      />
      <StyledImageLabel
        htmlFor={buttonId}
        selected={props.selected}
        className={props.className}
        data-testid={`${props.choice.title.replace(/\s+/g, '')}-swatch-label`}
      >
        <StyledImage
          data-testid="image-swatch-image"
          src={[{ url: props.choice.image || '' }]}
          alt={props.choice.title}
          isAmp={false}
          width="100%"
        />
      </StyledImageLabel>
    </React.Fragment>
  )
}
