import * as React from 'react'
export const SvgAccountMissingProduct = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 102" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#F2F2F2" d="M0 0h102v102H0z" />
      <g fill="#000" fillRule="nonzero">
        <path d="M62.91 35H39.09A4.094 4.094 0 0035 39.09v23.82A4.094 4.094 0 0039.09 67h23.82A4.094 4.094 0 0067 62.91V39.09A4.094 4.094 0 0062.91 35zm2.205 27.91a2.207 2.207 0 01-2.204 2.205H39.089a2.207 2.207 0 01-2.204-2.204v-3.438l6.203-5.278a.61.61 0 01.785-.005l3.886 3.227a.943.943 0 001.27-.06l9.232-9.246a.604.604 0 01.463-.179.604.604 0 01.443.225l5.948 7.325v7.429zm0-10.418l-4.485-5.523a2.499 2.499 0 00-1.81-.92 2.501 2.501 0 00-1.892.73l-8.626 8.639-3.224-2.678a2.49 2.49 0 00-3.211.02l-4.982 4.238V39.09c0-1.215.989-2.204 2.204-2.204h23.822c1.215 0 2.204.989 2.204 2.204v13.403z" />
        <path d="M44.951 39.293a4.493 4.493 0 00-4.488 4.487 4.493 4.493 0 004.488 4.488 4.493 4.493 0 004.488-4.488 4.493 4.493 0 00-4.488-4.487zm0 7.114a2.63 2.63 0 01-2.626-2.627 2.63 2.63 0 012.626-2.626 2.63 2.63 0 012.627 2.626 2.63 2.63 0 01-2.627 2.627z" />
      </g>
    </g>
  </svg>
)
export default SvgAccountMissingProduct
