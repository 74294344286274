import * as React from 'react'

export const SvgTwoItemsPerRowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.25 2.125V5.875C7.25 6.64679 6.62129 7.25 5.875 7.25H2.125C1.34645 7.25 0.75 6.65354 0.75 5.875V2.125C0.75 1.37871 1.35321 0.75 2.125 0.75H5.875C6.61448 0.75 7.25 1.38552 7.25 2.125ZM7.25 12.125V15.875C7.25 16.6468 6.62129 17.25 5.875 17.25H2.125C1.34645 17.25 0.75 16.6535 0.75 15.875V12.125C0.75 11.3787 1.35321 10.75 2.125 10.75H5.875C6.61448 10.75 7.25 11.3855 7.25 12.125ZM10.75 2.125C10.75 1.37871 11.3532 0.75 12.125 0.75H15.875C16.6145 0.75 17.25 1.38552 17.25 2.125V5.875C17.25 6.64679 16.6213 7.25 15.875 7.25H12.125C11.3465 7.25 10.75 6.65354 10.75 5.875V2.125ZM17.25 12.125V15.875C17.25 16.6468 16.6213 17.25 15.875 17.25H12.125C11.3465 17.25 10.75 16.6535 10.75 15.875V12.125C10.75 11.3787 11.3532 10.75 12.125 10.75H15.875C16.6145 10.75 17.25 11.3855 17.25 12.125Z"
      fill="white"
      stroke="#383838"
    />
  </svg>
)
export default SvgTwoItemsPerRowIcon
