import * as React from 'react'

import { mq, spacing, styled } from '@thg-commerce/enterprise-theme'

import { Image } from '../Image/Image'
import { DrawFocus } from '../mixin/focus'

export enum BrandSize {
  SMALL = 'small',
  LARGE = 'large',
}

export interface BrandProps {
  brandLabel: string
  imageUrl: string
  brandSize?: BrandSize
  brandUrl?: string
}

export const BrandLogoImage = styled(Image)<{
  brandSize?: BrandSize
}>`
  background-color: transparent;
  display: block;
  max-height: ${(props) =>
    props.brandSize === BrandSize.LARGE ? '56px' : '16px'};
  max-width: ${(props) =>
    props.brandSize === BrandSize.LARGE ? '128px' : '64px'};
`

export const BrandLogoLink = styled.a`
  display: inline-block;
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(2)};
  ${DrawFocus}

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-top: 0;
`

export const BrandLogo = (props: BrandProps) => (
  <React.Fragment>
    {props.brandUrl ? (
      <BrandLogoLink tabIndex={0} href={props.brandUrl}>
        <BrandLogoImage
          src={[{ url: props.imageUrl }]}
          brandSize={props.brandSize}
          imageStyle="contain"
          width="300"
          height="50"
          alt={props.brandLabel}
          isAmp={false}
        />
      </BrandLogoLink>
    ) : (
      <BrandLogoImage
        src={[{ url: props.imageUrl }]}
        brandSize={props.brandSize}
        imageStyle="contain"
        width="300"
        height="50"
        alt={props.brandLabel}
        isAmp={false}
      />
    )}
  </React.Fragment>
)
