import * as React from 'react'

interface CustomIconProps {
  path: string
  fillColor: string
  height?: string
  width?: string
  viewBox?: string
  rect?: {
    fill: string
    stroke: string
    width: string
    height: string
    x: string
    y: string
    rx: string
  }
}

export const CustomIcon = (props: CustomIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={props.viewBox || '0 0 24 24'}
      height={props.height || '24'}
      width={props.width || '24'}
    >
      {props.rect ? (
        <rect
          x={props.rect.x}
          y={props.rect.y}
          width={props.rect.width}
          height={props.rect.height}
          rx={props.rect.rx}
          fill={props.rect.fill}
          stroke={props.rect.stroke}
        />
      ) : null}

      <path d={props.path} fill={props.fillColor} />
    </svg>
  )
}
