import * as React from 'react'
export const SvgReferralsCredit = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      id="referralCreditIcon-path-1"
      d="M 12 0 C 5.4 0 0 5.4 0 12 C 0 18.6 5.4 24 12 24 C 18.6 24 24 18.6 24 12 C 24 5.4 18.6 0 12 0 Z M 12 2 C 17.5 2 22 6.5 22 12 C 22 17.5 17.5 22 12 22 C 6.5 22 2 17.5 2 12 C 2 6.5 6.5 2 12 2 Z M 12 6.1 L 10.4 10.3 L 6 10.6 L 9.4 13.5 L 8.3 17.9 L 12 15.5 L 15.7 17.9 L 14.6 13.5 L 18 10.6 L 13.6 10.3 L 12 6.1 Z"
    ></path>
  </svg>
)
export default SvgReferralsCredit
