import * as React from 'react'
export const SvgKcp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={40} {...props}>
    <defs>
      <linearGradient id="kcp_svg__a" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" />
        <stop offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none">
      <path
        fill="#2D6A79"
        d="M23.438 15.095l-5.301 4.314 3.324 5.723c-1.146 0-2.068.03-2.984-.021-.211-.012-.476-.261-.596-.47-.761-1.316-1.488-2.651-2.319-4.159-.305 1.442-.6 2.68-.817 3.935-.107.603-.28.721-.967.736-.686.016-1.13-.015-1.778-.015.533-2.77.966-5.383 1.567-7.961.211-.91.095-1.486-.715-1.997h3.738l-.821 4.017c1.001-.88 1.529-1.405 2.368-2.146.55-.487 1.352-1.536 1.932-1.74.58-.202.864-.171 1.291-.202.598-.047 1.212-.014 2.078-.014z"
      />
      <path
        fill="#2D6A79"
        d="M31.714 15.47c0 .194-.278.828-.499 1.098-.187.23-.602.36-.921.37-.297.009-.594-.22-.902-.308-3.294-.948-5.873 1.2-5.508 4.582.175 1.62 1.28 2.568 2.898 2.375.721-.086 1.417-.375 2.128-.557.265-.068.533-.161.85-.161.215 0 .094.203.075.306-.255 1.398-.394 1.503-1.776 1.697-.98.137-1.986.21-2.97.152-2.798-.165-4.281-2.236-3.679-5.028.639-2.959 3.274-5.026 6.652-5.103 1.134-.026 2.136.07 3.459.191.129.129.193.258.193.387zM38.73 15.086c2.08.014 2.513 1.915 1.794 4.25-.5 1.624-2.24 2.022-3.852 2.091-1.896.084-1.894-.123-2.194 1.748-.044.282-.125.694-.193.966-.193.773-.408.994-1.079.932-.644-.005-1.206-.008-1.685-.008.245-1.326.458-2.559.702-3.786.282-1.429.603-2.85.885-4.278.178-.9.16-.902-.773-1.915.404 0 1.225.026 1.757 0 1.534.02 3.106-.01 4.638 0zm-.525 2.484c-.084-.957-.878-.978-1.581-.945-.325.013-.864.181-.92.386-.238.876-.327 1.794-.52 2.953.813-.172 1.469-.237 2.073-.452.897-.32 1.017-1.146.948-1.942z"
      />
      <path
        fill="url(#kcp_svg__a)"
        d="M16.427 7.131c1.29-1.111 2.392-2.07 3.504-3.015 1.246-1.061 2.485-2.126 3.757-3.162C25.563-.563 27.457-.24 29.05 1.7c2.01 2.45 4.11 4.832 6.152 7.248 1.037 1.231 2.08 2.466 1.526 4.293-.37 1.225-1.457 1.739-2.319 2.472-2.598 2.187-5.218 4.344-7.833 6.517-1.972 1.639-3.918 3.309-5.92 4.911-1.37 1.098-3.19 1.009-4.367-.288-1.677-1.85-3.239-3.806-4.886-5.76 1.664-.523 2.383-.293 3.31.893a56.676 56.676 0 002.319 2.826c1.08 1.21 1.803 1.227 3.042.193 2.037-1.713 4.075-3.426 6.113-5.137 2.513-2.126 5.043-4.252 7.538-6.396 1.067-.914 1.09-1.536.193-2.603-2.172-2.61-4.343-5.222-6.513-7.835-.752-.905-1.753-.976-2.706-.194-1.419 1.16-2.9 2.24-4.252 3.48-1.123 1.049-2.43.807-4.02.811z"
        transform="translate(12 6)"
      />
    </g>
  </svg>
)
export default SvgKcp
