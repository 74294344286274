import { IdGetter } from 'apollo-cache-inmemory'
import { defaultDataIdFromObject } from 'apollo-boost'

import { LocalizedString, WishlistItem } from '../generated/graphql'

export const dataIdFromObject: IdGetter = (object) => {
  switch (object.__typename) {
    case 'LocalizedString':
      return `LocalizedString:${(object as LocalizedString).key}`
    case 'WishlistItem':
      const wishlistItem = object as WishlistItem
      if (wishlistItem.product) {
        return `WishlistItem:${
          wishlistItem.selectedVariant?.sku || wishlistItem.product.sku
        }`
      }
    default:
      return defaultDataIdFromObject(object)
  }
}
