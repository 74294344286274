import * as React from 'react'
export const SvgStoreLocator = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    viewBox="-20 -10 60 60"
    {...props}
  >
    <path
      fill={props.fill || '#4d4f53'}
      d="M13.2497037,18.9071 C10.1227037,18.9071 7.58970374,16.3721 7.58970374,13.2481 C7.58970374,10.1231 10.1227037,7.5881 13.2497037,7.5881 C16.3737037,7.5881 18.9087037,10.1231 18.9087037,13.2481 C18.9087037,16.3741 16.3737037,18.9071 13.2497037,18.9071 M26.2587037,10.6561 C24.8607037,2.9691 19.4927037,0.7331 15.4427037,0.1471 C13.4067037,-0.1469 11.3687037,0.0731 10.6557037,0.1781 C6.73570374,0.7791 1.62070374,3.0611 0.239703741,10.6581 C-2.04829626,23.2351 12.7847037,37.5101 13.2547037,37.9441 C13.7157037,37.5041 28.5447037,23.2311 26.2587037,10.6561"
    />
  </svg>
)
export default SvgStoreLocator
