import * as React from 'react'
export const SvgMolPay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={40} {...props}>
    <path
      fill={props.fill || '#7E287D'}
      d="M43.415 21.315c-.285.284-.407.61-.407.976 0 .407.122.733.407 1.018.285.244.61.407.977.407.407 0 .732-.163.977-.407.285-.285.407-.61.407-1.018 0-.366-.122-.692-.407-.976-.245-.285-.57-.408-.977-.408-.366 0-.692.123-.977.408zm2.117 2.116c-.285.326-.692.488-1.14.488-.448 0-.814-.162-1.14-.488-.326-.285-.488-.692-.488-1.14 0-.447.162-.814.488-1.14.326-.325.692-.488 1.14-.488.448 0 .855.163 1.14.489.325.325.488.692.488 1.14 0 .447-.163.854-.488 1.139zm-.815-1.75a.873.873 0 00-.407-.082h-.244v.652h.285c.122 0 .204 0 .285-.041.122-.04.203-.122.203-.285 0-.122-.04-.203-.122-.244zm-.366-.285c.244 0 .407.04.488.081.204.082.285.204.285.448 0 .163-.04.285-.162.326-.082.04-.163.081-.285.122.162 0 .244.081.325.162.082.082.082.163.082.285v.245c.04.04.04.081.04.081v.04h-.284v-.244c0-.203-.082-.325-.204-.407-.04-.04-.163-.04-.326-.04h-.244v.692h-.326v-1.791h.611z"
    />
    <path
      fill={props.fill || '#818285'}
      d="M29.221 16.864c-.387-1.96-.258-3.884-.14-5.789.073-1.219 2.351-2.894 3.585-2.894h6.06c4.678.023 4.215-.572 4.224 4.273v3.12h7.007c-1.196 1.448-2.048 2.989-4.225 2.676-1.18-.17-2.407-.023-3.618-.033-1.465-.01-1.79-.343-1.798-1.838a570.039 570.039 0 010-4.974c0-.596-.08-1.042-.845-1.126-.287-.03-.532-.455-.68-.595 0 1.677.035 3.437-.023 5.194-.015.436-.28.894-.518 1.288-.977 1.628-2.368 2.22-4.307 2.077-2.428-.181-4.885-.036-7.323-.047-1.382 0-1.758-.4-1.764-1.788v-3.346c0-1.932-.7-2.513-2.713-2.171 0 1.872.027 3.764-.02 5.653-.011.443-.152 1.019-.454 1.283-.303.264-.986.4-1.298.226a1.786 1.786 0 01-.81-1.243c-.096-1.653-.042-3.314-.043-4.971v-1.033h-2.714v1.098c0 1.659.018 3.316-.023 4.973-.022.841-.561 1.333-1.312 1.3-.782-.032-1.266-.47-1.266-1.246-.03-2.501-.03-5.003 0-7.505.009-.77.474-1.243 1.266-1.247 3.166-.014 6.331-.014 9.496 0 .23 0 .553.04.67.181.811 1.022 1.851 1.934 1.88 3.383.022 1.085.013 2.17 0 3.256 0 .57.113.96.784 1.11.33.091.598.482.922.755zm7.03-6.422a1.07 1.07 0 00-.248-.1c-.81-.008-1.628-.017-2.43-.02-.754 0-1.826.641-1.877 1.347-.105 1.447-.03 2.907-.03 4.416h2.738c.673 0 1.81-.894 1.833-1.562.047-1.37.014-2.744.014-4.08z"
    />
    <path
      fill={props.fill || '#7E287D'}
      d="M15.961 25.622v4.137h-1.726v-9.971c.697 0 1.406-.011 2.115 0 1.592.029 3.197-.036 4.774.134 1.824.199 2.878 1.367 2.826 2.827-.053 1.46-1.288 2.652-3.041 2.775-1.32.092-2.647.04-3.971.054-.3.006-.586.026-.977.044zm.058-4.573v3.256c1.552 0 3.052.083 4.537-.029 1.042-.078 1.576-.783 1.561-1.659-.014-.836-.564-1.489-1.572-1.548-1.49-.085-2.988-.02-4.526-.02zm16.909 8.117c-.146.233-.242.595-.452.68-.843.346-1.76-.036-2.312-.904-1.326.893-2.77 1.282-4.365.946-1.18-.25-1.922-.983-1.97-1.93-.055-1.04.57-1.87 1.821-2.135 1.144-.243 2.321-.328 3.486-.45.52-.057.98-.156 1.039-.773.058-.617-.315-.917-.856-1.102-1.416-.481-2.582-.101-3.497.95-.262.3-.92.255-1.485.387.036-1.17.543-1.81 1.393-2.124a6.592 6.592 0 014.85.027c.812.32 1.216.92 1.196 1.822-.027 1.143.025 2.29-.017 3.437-.025.724.181 1.118 1.019.954l.15.215zm-2.85-2.998c-1.313.226-2.564.391-3.784.686-.305.074-.66.543-.723.875-.051.295.199.824.463.957 1.143.58 2.272.299 3.345-.285.902-.48.814-1.292.7-2.226v-.007zm2.53-3.618c1.744-.117 1.744-.117 2.352 1.328l1.96 4.597 1.006-2.328 1.035-2.401c.583-1.364.583-1.364 2.157-1.165.002.114-.013.229-.046.338-1.359 2.781-2.722 5.56-4.088 8.338-.543 1.086-1.384 1.574-2.612 1.549-.805-.017-1.13-.26-.856-1.183.991.211 1.914.063 2.26-1.086.124-.369.124-.769 0-1.138-.905-2-1.86-3.98-2.795-5.959-.118-.266-.223-.539-.373-.89z"
    />
    <path
      fill={props.fill || '#FFF'}
      d="M36.251 10.442c0 1.337.04 2.714-.016 4.081-.027.668-1.16 1.556-1.833 1.562h-2.738c0-1.51-.075-2.969.03-4.416.05-.706 1.124-1.348 1.876-1.346.81 0 1.628.009 2.432.02.087.022.17.056.25.1zM16.02 21.05c1.538 0 3.035-.065 4.523.023 1.009.06 1.557.711 1.572 1.549.016.875-.518 1.58-1.562 1.659-1.485.11-2.985.029-4.537.029l.004-3.26zm14.06 5.126c.114.932.199 1.746-.693 2.233-1.073.584-2.202.865-3.345.286-.264-.134-.514-.662-.463-.957.056-.335.41-.802.724-.876 1.212-.295 2.463-.46 3.777-.686z"
    />
  </svg>
)
export default SvgMolPay
