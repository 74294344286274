import * as React from 'react'
export const SvgStoreInfo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <g fill="none" fill-rule="evenodd" transform="translate(0 .263)">
      <path
        fill="#1D8634"
        d="M0.0030958868,11.6041694 C0.132773306,5.9428791 4.47019266,0.0373307123 11.8261927,-1.08357767e-13 C19.0738056,-0.0366047715 23.8587088,5.9192662 23.6775475,11.9312662 C23.6581927,18.5370726 18.3456765,23.8495888 11.6938056,23.7357823 C4.83290234,23.618492 -0.142065404,17.9339759 0.0030958868,11.6041694"
      />
      <path
        fill="#FFFFFF"
        d="M12.9926662 14.2522858C12.9933381 15.4038996 13.010136 16.556306 12.9886347 17.7075236 12.9664615 18.9229398 12.3832396 19.7123461 11.5359552 19.736916 10.7007653 19.7606933 10.0480002 18.9839682 10.0288506 17.7673631 9.99088745 15.3991442 9.9888717 13.0281513 10.0318742 10.6603287 10.0540474 9.45006417 10.7118519 8.70821247 11.5678711 8.73833038 12.3704732 8.76686314 12.9577266 9.54239935 12.9856111 10.6987686 13.0138315 11.8824818 12.9916583 13.0677801 12.9926662 14.2522858M11.5208826 7.73744684C10.6503336 7.7413397 10.0038179 7.12122807 9.99999911 6.27817493 9.99621181 5.45752261 10.6824865 4.744252 11.4828523 4.7374962 12.2873668 4.7307404 12.9809019 5.41841006 12.9995713 6.24155136 13.0189322 7.08638234 12.3789853 7.73351719 11.5208826 7.73744684"
      />
    </g>
  </svg>
)
export default SvgStoreInfo
