import * as React from 'react'
export const SvgCopyToClipboard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={19} height={22} fill="none" {...props}>
    <path
      d="M14 0H2a2.006 2.006 0 00-2 2v14h2V2h12V0zm3 4H6a2.006 2.006 0 00-2 2v14a2.006 2.006 0 002 2h11a2.006 2.006 0 002-2V6a2.006 2.006 0 00-2-2zm0 16H6V6h11v14z"
      fill={props.fill || '#fff'}
    />
  </svg>
)
export default SvgCopyToClipboard
