import * as React from 'react'
import ReactDOM from 'react-dom'
import { HTMLMotionProps } from 'framer-motion'

import {
  CustomAnimatePresence,
  MotionDiv,
} from '@thg-commerce/gravity-animations'
import {
  opacity,
  styled,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'

export const defaultZIndex = ZIndexLevel.Low

const StyledOverlay = styled(
  ({ zIndex, ...props }: { zIndex?: ZIndexLevel } & HTMLMotionProps<'div'>) => (
    <MotionDiv {...props} />
  ),
)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${opacity('#000000', 0.5)};
  ${(props) => zIndex(props.zIndex || defaultZIndex)}
`

export const Overlay = (props: {
  isShowing: boolean
  zIndex?: ZIndexLevel
}) => {
  const overlayVariants = {
    showing: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  return (
    (typeof document !== 'undefined' &&
      document.body &&
      ReactDOM.createPortal(
        <CustomAnimatePresence>
          {props.isShowing && (
            <StyledOverlay
              variants={overlayVariants}
              initial="hidden"
              animate="showing"
              exit="hidden"
              transition={{ ease: 'easeInOut', duration: 0.3 }}
              zIndex={props.zIndex}
            />
          )}
        </CustomAnimatePresence>,
        document.body,
      )) ||
    null
  )
}
