import * as React from 'react'

import { AutocompletePrediction } from '@thg-commerce/enterprise-core'

import { DropdownContainer, DropdownItem } from './styles'
import {
  FulfilmentMethod,
  StoreOpeningTime,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

export interface SearchSuggestionsProps {
  searchLocations: AutocompletePrediction[]
  handleFindStores: (Location, suggestionSearch) => void
  stores?: {
    id: string
    displayName: string
    distance: Number
    fulfilmentMethods: FulfilmentMethod[]
    openingTimes: [StoreOpeningTime, StoreOpeningTime, StoreOpeningTime]
    stock: number
    ranged: boolean
  }[]
}

export const SearchSuggestions = ({
  searchLocations,
  handleFindStores,
  stores,
}: SearchSuggestionsProps) => {
  return (
    <DropdownContainer
      data-testid="search-suggestions"
      dropdownActive={(stores && stores.length > 0) || false}
    >
      {searchLocations.map((location) => {
        return (
          <DropdownItem
            onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
              event.stopPropagation()
              event.preventDefault()
              handleFindStores(location, true)
            }}
          >
            {`${location.description}`}
          </DropdownItem>
        )
      })}
    </DropdownContainer>
  )
}
