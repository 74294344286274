import * as React from 'react'

import { Toast } from '../types'
import { ToastBannerRenderer } from '../ToastBannerRenderer'

export const ToastBannerContext = React.createContext<{
  showToast?: React.MutableRefObject<(toast: Toast) => void>
}>({})

export const ToastBannerProvider = (props: {
  children?: React.ReactNode
  rendererTestId?: string
}) => {
  const showToast = React.useRef((_toast: Toast) => {})

  return (
    <ToastBannerContext.Provider value={{ showToast }}>
      {props.children}
      <ToastBannerRenderer data-testid={props.rendererTestId} />
    </ToastBannerContext.Provider>
  )
}

export const useToastPresenter = () => {
  const { showToast } = React.useContext(ToastBannerContext)

  return (toast: Toast) => showToast?.current?.(toast)
}
