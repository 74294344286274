import * as React from 'react'
export const SvgAmazonPay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 60 40"
    style={{
      background: 'new 0 0 60 40',
      width: '60px',
      height: '40px',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {
        '\n    .st0{fill:#FBD610;}\n\t.st1{clip-path:url(#SVGID_00000004514705612420380160000017596978512362455995_);}\n\t.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#242F3E;}\n\t.st3{clip-path:url(#SVGID_00000119090502759336734520000002078399111760860337_);}\n  '
      }
    </style>
    <path
      className="st0"
      d="M1.75,0h56.5C59.22,0,60,0.78,60,1.75v36.5c0,0.97-0.78,1.75-1.75,1.75H1.75C0.78,40,0,39.22,0,38.25V1.75  C0,0.78,0.78,0,1.75,0z"
    />
    <g>
      <defs>
        <rect id="SVGID_1_" x={3.8} y={16.28} width={52.4} height={10.07} />
      </defs>
      <clipPath id="SVGID_00000154383902985224188620000011380813318208809122_">
        <use
          xlinkHref="#SVGID_1_"
          style={{
            overflow: 'visible',
          }}
        />
      </clipPath>
      <g
        style={{
          clipPath:
            'url(#SVGID_00000154383902985224188620000011380813318208809122_)',
        }}
      >
        <path
          className="st2"
          d="M7.91,22.09c-0.07,0.06-0.17,0.07-0.24,0.02c-0.34-0.28-0.4-0.41-0.59-0.68c-0.56,0.57-0.96,0.74-1.69,0.74    c-0.87,0-1.54-0.53-1.54-1.6c0-0.83,0.45-1.4,1.1-1.67c0.56-0.24,1.34-0.29,1.93-0.36v-0.13c0-0.24,0.02-0.53-0.12-0.74    c-0.12-0.19-0.37-0.27-0.58-0.27c-0.39,0-0.74,0.2-0.83,0.61c-0.02,0.09-0.09,0.18-0.18,0.19l-1-0.11    c-0.08-0.02-0.18-0.09-0.15-0.21c0.23-1.2,1.32-1.57,2.3-1.57c0.5,0,1.15,0.13,1.55,0.51c0.5,0.47,0.45,1.09,0.45,1.76v1.6    c0,0.48,0.2,0.69,0.39,0.95c0.07,0.09,0.08,0.2,0,0.27C8.49,21.59,8.12,21.91,7.91,22.09L7.91,22.09z M6.87,19.6    c0,0.4,0.01,0.73-0.19,1.09c-0.16,0.29-0.42,0.47-0.71,0.47c-0.39,0-0.62-0.3-0.62-0.74c0-0.87,0.79-1.03,1.53-1.03V19.6z"
        />
        <path
          className="st2"
          d="M10.72,22.05H9.7c-0.1-0.01-0.18-0.08-0.18-0.17l0-5.26c0-0.11,0.09-0.19,0.2-0.19h0.96    c0.1,0,0.18,0.08,0.19,0.18v0.69h0.02c0.25-0.66,0.72-0.97,1.35-0.97c0.64,0,1.05,0.31,1.33,0.97c0.25-0.66,0.82-0.97,1.42-0.97    c0.43,0,0.9,0.18,1.19,0.58c0.33,0.44,0.26,1.08,0.26,1.65l0,3.32c0,0.11-0.09,0.19-0.2,0.19h-1.03c-0.1-0.01-0.18-0.09-0.18-0.19    v-2.79c0-0.22,0.02-0.77-0.03-0.98c-0.08-0.35-0.31-0.45-0.6-0.45c-0.25,0-0.51,0.17-0.61,0.43c-0.11,0.27-0.1,0.71-0.1,1.01v2.79    c0,0.11-0.09,0.19-0.2,0.19h-1.03c-0.1-0.01-0.18-0.09-0.18-0.19l0-2.79c0-0.59,0.1-1.45-0.63-1.45c-0.74,0-0.71,0.84-0.71,1.45    v2.79C10.92,21.97,10.84,22.05,10.72,22.05z"
        />
        <path
          className="st2"
          d="M22.18,21.14c-0.19-0.26-0.39-0.47-0.39-0.95v-1.6c0-0.68,0.05-1.3-0.45-1.76c-0.39-0.38-1.05-0.51-1.55-0.51    c-0.98,0-2.07,0.36-2.3,1.57c-0.02,0.13,0.07,0.19,0.15,0.21l1,0.11c0.09,0,0.16-0.1,0.18-0.19c0.09-0.42,0.43-0.61,0.83-0.61    c0.21,0,0.45,0.08,0.58,0.27c0.14,0.21,0.13,0.5,0.13,0.74v0.13c-0.6,0.07-1.38,0.11-1.93,0.35c-0.64,0.28-1.1,0.84-1.1,1.68    c0,1.06,0.67,1.6,1.54,1.6c0.73,0,1.13-0.17,1.69-0.74c0.19,0.27,0.25,0.4,0.59,0.68c0.08,0.04,0.17,0.04,0.24-0.02l0,0    c0.21-0.18,0.58-0.51,0.79-0.68C22.26,21.34,22.25,21.23,22.18,21.14z M20.16,20.68c-0.16,0.29-0.42,0.47-0.71,0.47    c-0.39,0-0.63-0.3-0.63-0.74c0-0.87,0.78-1.03,1.53-1.03v0.22C20.35,19.99,20.36,20.32,20.16,20.68z"
        />
        <path
          className="st2"
          d="M23.05,17.35V16.6c0-0.11,0.09-0.19,0.19-0.19h3.38c0.11,0,0.19,0.08,0.19,0.19v0.64    c0,0.11-0.09,0.25-0.25,0.47l-1.75,2.49c0.65-0.02,1.34,0.08,1.93,0.41c0.13,0.07,0.17,0.19,0.18,0.29v0.8    c0,0.11-0.12,0.24-0.25,0.17c-1.04-0.54-2.42-0.6-3.57,0.01c-0.12,0.06-0.24-0.06-0.24-0.17v-0.76c0-0.12,0-0.33,0.13-0.52    l2.03-2.9h-1.76C23.13,17.54,23.05,17.47,23.05,17.35z"
        />
        <path
          className="st2"
          d="M29.73,17.39c-0.76,0-0.81,1.03-0.81,1.67c0,0.64-0.01,2.01,0.8,2.01c0.8,0,0.83-1.11,0.83-1.78    c0-0.44-0.02-0.97-0.15-1.39C30.28,17.54,30.05,17.39,29.73,17.39z M29.72,16.32c1.53,0,2.35,1.31,2.35,2.97    c0,1.6-0.91,2.88-2.35,2.88c-1.5,0-2.31-1.31-2.31-2.93C27.41,17.59,28.23,16.32,29.72,16.32z"
        />
        <path
          className="st2"
          d="M34.06,22.04h-1.02c-0.1-0.01-0.18-0.09-0.18-0.19l0-5.26c0.01-0.1,0.09-0.17,0.2-0.17H34    c0.09,0,0.16,0.07,0.18,0.15v0.8h0.02c0.29-0.72,0.69-1.06,1.4-1.06c0.46,0,0.91,0.17,1.2,0.62c0.27,0.42,0.27,1.13,0.27,1.64    v3.31c-0.01,0.09-0.1,0.17-0.2,0.17h-1.03c-0.1-0.01-0.17-0.08-0.18-0.17v-2.86c0-0.58,0.07-1.42-0.64-1.42    c-0.25,0-0.48,0.17-0.59,0.42c-0.14,0.32-0.16,0.64-0.16,1v2.83C34.25,21.96,34.17,22.04,34.06,22.04z"
        />
        <path
          className="st2"
          d="M42.42,17.22c-0.51,0-0.99,0.17-1.44,0.49v3c0.46,0.34,0.93,0.52,1.42,0.52c0.99,0,1.49-0.67,1.49-2    C43.88,17.89,43.4,17.22,42.42,17.22z M40.9,17.08c0.27-0.24,0.56-0.42,0.87-0.54c0.31-0.12,0.63-0.18,0.95-0.18    c0.34,0,0.66,0.06,0.94,0.19c0.28,0.13,0.52,0.31,0.71,0.56c0.19,0.24,0.34,0.54,0.45,0.89c0.1,0.35,0.16,0.74,0.16,1.18    c0,0.44-0.06,0.85-0.17,1.2c-0.11,0.36-0.27,0.66-0.47,0.91c-0.2,0.25-0.45,0.45-0.73,0.59c-0.29,0.14-0.61,0.21-0.96,0.21    c-0.65,0-1.2-0.21-1.67-0.63v2.51c0,0.14-0.07,0.21-0.22,0.21h-0.64c-0.14,0-0.22-0.07-0.22-0.21v-7.23    c0-0.14,0.07-0.21,0.22-0.21h0.47c0.14,0,0.23,0.07,0.25,0.21L40.9,17.08z"
        />
        <path
          className="st2"
          d="M47.66,21.31c0.24,0,0.48-0.05,0.74-0.14c0.26-0.09,0.51-0.23,0.74-0.41v-1.1c-0.18-0.04-0.37-0.08-0.57-0.1    s-0.39-0.04-0.58-0.04c-0.81,0-1.22,0.31-1.22,0.93c0,0.27,0.08,0.48,0.23,0.63C47.16,21.23,47.38,21.31,47.66,21.31z M46.4,17.46    c-0.02,0.01-0.05,0.02-0.08,0.03c-0.03,0.01-0.06,0.02-0.09,0.02c-0.09,0-0.14-0.06-0.14-0.19v-0.29c0-0.1,0.01-0.17,0.04-0.21    c0.03-0.04,0.09-0.08,0.17-0.11c0.23-0.1,0.51-0.18,0.85-0.24c0.34-0.06,0.68-0.09,1.01-0.09c0.68,0,1.19,0.14,1.51,0.42    c0.32,0.28,0.48,0.71,0.48,1.3v3.67c0,0.14-0.07,0.21-0.22,0.21h-0.46c-0.14,0-0.22-0.07-0.24-0.2l-0.05-0.35    c-0.26,0.22-0.54,0.39-0.86,0.52c-0.31,0.12-0.62,0.18-0.93,0.18c-0.52,0-0.93-0.15-1.23-0.44c-0.3-0.29-0.45-0.68-0.45-1.17    c0-0.52,0.19-0.94,0.56-1.25c0.37-0.31,0.88-0.47,1.51-0.47c0.4,0,0.86,0.06,1.36,0.18v-0.71c0-0.39-0.09-0.66-0.26-0.82    c-0.17-0.16-0.47-0.24-0.91-0.24C47.48,17.21,46.95,17.3,46.4,17.46z"
        />
        <path
          className="st2"
          d="M53.67,22.92c-0.19,0.49-0.42,0.85-0.67,1.06c-0.25,0.21-0.59,0.32-1,0.32c-0.23,0-0.43-0.03-0.61-0.08    c-0.08-0.02-0.14-0.06-0.17-0.1c-0.04-0.05-0.05-0.12-0.05-0.22v-0.28c0-0.14,0.05-0.2,0.16-0.2c0.04,0,0.09,0.01,0.16,0.02    c0.07,0.01,0.17,0.02,0.29,0.02c0.27,0,0.48-0.06,0.64-0.19c0.15-0.13,0.29-0.37,0.41-0.71l0.2-0.55l-2.08-5.11    c-0.05-0.12-0.08-0.21-0.08-0.26c0-0.09,0.05-0.13,0.15-0.13h0.65c0.11,0,0.19,0.02,0.24,0.05c0.04,0.04,0.09,0.12,0.13,0.24    l1.51,4.17l1.45-4.17c0.04-0.12,0.09-0.2,0.13-0.24c0.04-0.04,0.12-0.05,0.24-0.05h0.6c0.1,0,0.15,0.04,0.15,0.13    c0,0.05-0.03,0.14-0.08,0.26L53.67,22.92z"
        />
        <path
          className="st2"
          d="M24.44,24.12c-1.93,1.42-4.73,2.17-7.14,2.17c-3.38,0-6.42-1.24-8.72-3.31c-0.18-0.16-0.02-0.38,0.2-0.26    c2.48,1.44,5.55,2.31,8.72,2.31c2.14,0,4.49-0.44,6.65-1.36C24.48,23.53,24.76,23.88,24.44,24.12z M25.24,23.2    c0.25,0.32-0.27,1.61-0.51,2.19c-0.07,0.18,0.08,0.25,0.24,0.11c1.04-0.86,1.3-2.67,1.09-2.93c-0.21-0.26-2.02-0.48-3.12,0.29    c-0.17,0.12-0.14,0.28,0.05,0.26C23.61,23.05,25,22.89,25.24,23.2z"
        />
      </g>
    </g>
  </svg>
)
export default SvgAmazonPay
