import * as React from 'react'
export const SvgBatSelected = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="Bat-Selected_svg__bat-selected-svg"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path d="M17.24 18.33a9.3 9.3 0 00-5-1.36 9.24 9.24 0 00-5 1.36A4.29 4.29 0 005 21.63c0 .18.22.32.48.32H19c.26 0 .47-.14.47-.32a4.33 4.33 0 00-2.23-3.3zM7 11.5a.49.49 0 00.06.23 5.21 5.21 0 004.33 3.86l.65.63.81-.62a5.41 5.41 0 001.07-.27l.15-.05a5.22 5.22 0 003.28-5 5.16 5.16 0 00-.11-1L17.32 2l-2.68 3.8a4.55 4.55 0 00-2.52-.8 4.43 4.43 0 00-2.51.75L7 2v7.4a5.26 5.26 0 000 2.14zm6.26.83a11.1 11.1 0 012.06-.77.36.36 0 01.37.25 3.87 3.87 0 01-1.16 1.57 1.11 1.11 0 01-.26-.07c-.25-.09-.81-.47-1.05-.59s-.07-.35.02-.39zm-4.38-.77a11.7 11.7 0 012.06.77c.09 0 .29.28 0 .39s-.8.5-1.06.59a.71.71 0 01-.22.06 3.9 3.9 0 01-1.16-1.61.31.31 0 01.36-.2z" />
  </svg>
)
export default SvgBatSelected
