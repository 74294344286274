export type ReadMoreThemeInterface = {
  chevronIcon: {
    svgPath: string
    viewBox: string
    width: string
    height: string
  }
}

export const theme: ReadMoreThemeInterface = {
  chevronIcon: {
    svgPath: '',
    viewBox: '0 0 24 24',
    width: '24px',
    height: '24px',
  },
}
