import * as React from 'react'
export const SvgInstagramOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} {...props}>
    <path
      d="M9 1.621c2.402 0 2.688.008 3.637.055.879.039 1.355.183 1.672.308.418.164.718.36 1.035.672.312.317.508.617.672 1.035.125.317.27.793.308 1.672.047.95.055 1.235.055 3.637s-.008 2.688-.055 3.637c-.039.879-.183 1.355-.308 1.672-.164.418-.36.718-.672 1.035a2.857 2.857 0 01-1.035.672c-.317.125-.793.27-1.672.308-.95.047-1.235.055-3.637.055s-2.688-.008-3.637-.055c-.879-.039-1.355-.183-1.672-.308a2.857 2.857 0 01-1.035-.672 2.857 2.857 0 01-.672-1.035c-.125-.317-.27-.793-.308-1.672-.047-.95-.055-1.235-.055-3.637s.008-2.688.055-3.637c.039-.879.183-1.355.308-1.672.164-.418.36-.718.672-1.035a2.857 2.857 0 011.035-.672c.317-.125.793-.27 1.672-.308.95-.047 1.235-.055 3.637-.055M9 0C6.555 0 6.25.012 5.29.055 4.331.098 3.675.25 3.104.473c-.593.23-1.093.539-1.593 1.039-.5.5-.809 1-1.04 1.593C.25 3.675.099 4.332.056 5.29.012 6.25 0 6.555 0 9s.012 2.75.055 3.71c.043.958.195 1.614.418 2.185.23.593.539 1.093 1.039 1.593.5.5 1 .809 1.593 1.04.57.222 1.227.374 2.184.417C6.25 17.988 6.555 18 9 18s2.75-.012 3.71-.055c.958-.043 1.614-.195 2.185-.418a4.405 4.405 0 001.593-1.039c.5-.5.809-1 1.04-1.593.222-.57.374-1.227.417-2.184C17.988 11.75 18 11.445 18 9s-.012-2.75-.055-3.71c-.043-.958-.195-1.614-.418-2.185a4.405 4.405 0 00-1.039-1.593c-.5-.5-1-.809-1.593-1.04-.57-.222-1.227-.374-2.184-.417C11.75.012 11.445 0 9 0zm0 4.379A4.623 4.623 0 004.379 9c0 2.55 2.07 4.621 4.621 4.621 2.55 0 4.621-2.07 4.621-4.621 0-2.55-2.07-4.621-4.621-4.621zM9 12a3 3 0 11.002-6.002A3 3 0 019 12zm5.883-7.805c0 .598-.48 1.082-1.078 1.082a1.082 1.082 0 01-1.082-1.082c0-.597.484-1.078 1.082-1.078.597 0 1.078.48 1.078 1.078zm0 0"
      fill={props.fill || '#0a0a08'}
    />
  </svg>
)
export default SvgInstagramOutline
