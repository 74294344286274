import * as React from 'react'
export const SvgAccountLiveChat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={65} height={64} {...props}>
    <path
      fill={props.fill || '#333'}
      fillRule="evenodd"
      d="M53.923 6.286a5.72 5.72 0 015.72 5.72v31.417a5.72 5.72 0 01-5.72 5.72h-9.209v8.571l-13.571-8.571H11.077a5.72 5.72 0 01-5.72-5.72V12.006a5.72 5.72 0 015.72-5.72h42.846zm.003 2.857H11.074a2.86 2.86 0 00-2.86 2.86v31.423a2.86 2.86 0 002.86 2.86H32.5l9.444 6.428v-6.428h11.982a2.86 2.86 0 002.86-2.86V12.003a2.86 2.86 0 00-2.86-2.86zM32.5 33.429v2.857H13.929v-2.857H32.5zm21.429-7.143v2.857h-40v-2.857h40zm0-7.143V22h-40v-2.857h40z"
    />
  </svg>
)
export default SvgAccountLiveChat
