import * as React from 'react'
export const SvgAlipayPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={59} height={39} {...props}>
    <g clip-path="url(#clip0_1912_10064)">
      <path
        d="M14.8633 15.2686V24.1802H16.7918V15.2686H14.8633ZM6 24.1802H7.78233L8.27051 22.4953H11.3351L11.7987 24.1802H14.0811L11.4813 15.5737H8.8934L6 24.1802ZM8.67386 21.0918L9.82102 17.1242H9.86977L10.9563 21.0914H8.67386V21.0918ZM24.3851 24.265C26.106 24.265 27.6566 22.9958 27.6566 20.7368C27.6566 18.7126 26.5336 17.5022 25.1416 17.5022C24.4339 17.5022 23.8843 17.7709 23.2614 18.2836V17.6362H21.3332V26.2557H23.2614V24.1188C23.6275 24.2185 24.0056 24.2676 24.3851 24.2654V24.265ZM32.2348 24.1798H34.1637L33.9315 22.5437V19.8092C33.9315 18.3208 33.0527 17.5022 31.5018 17.5022C30.5135 17.5022 29.8296 17.6734 28.5846 18.0514L28.9261 19.5528C30.0618 19.0401 30.5622 18.8206 31.0869 18.8206C31.722 18.8206 32.0022 19.2723 32.0022 19.9685V20.0168C29.7931 20.4336 29.1092 20.6635 28.682 21.0885C28.365 21.4062 28.2303 21.858 28.2303 22.3833C28.2303 23.6403 29.2067 24.3115 30.1224 24.3115C30.8063 24.3115 31.3552 24.0555 32.1001 23.494L32.2348 24.1776V24.1798ZM18.1101 17.6366V24.1802H20.0391V17.6366H18.1101ZM40.8041 17.6492L40.8167 17.6366H38.998L37.8512 21.6165H37.7916L36.4707 17.6366H34.31L36.9098 24.2047L35.8233 26.2066V26.2557H37.5207L40.8041 17.6492ZM24.4097 19.0044C25.1297 19.0044 25.7031 19.5774 25.7031 20.7986C25.7031 22.3614 24.8614 23.0568 23.9211 23.0568C23.6974 23.0594 23.4747 23.0263 23.2614 22.959V19.4193C23.6647 19.1383 23.9944 19.0025 24.4093 19.0025V19.0044H24.4097ZM30.0979 22.1906C30.0979 22.0072 30.1351 21.8237 30.2813 21.6771C30.5135 21.4457 30.9648 21.2749 32.0026 21.0308V22.5928C31.4899 22.8741 31.197 22.9835 30.8551 22.9835C30.3907 22.9835 30.0979 22.6784 30.0979 22.1906Z"
        fill="#3E3A39"
      />
      <path
        d="M17.9665 16.062C17.9665 16.6967 18.4302 17.1243 19.0776 17.1243C19.7247 17.1243 20.1883 16.6967 20.1883 16.062C20.1853 15.4394 19.7217 15 19.0746 15C18.4272 15 17.9665 15.4391 17.9665 16.062ZM53.9851 21.8316H49.6052C49.0263 21.8316 48.4712 21.6017 48.0618 21.1923C47.6525 20.783 47.4225 20.2278 47.4225 19.6489H52.9306C53.069 19.6488 53.206 19.676 53.3339 19.7289C53.4618 19.7817 53.578 19.8593 53.676 19.957C53.7739 20.0548 53.8516 20.1709 53.9046 20.2988C53.9577 20.4266 53.985 20.5636 53.9851 20.702V21.8316Z"
        fill="#1677FF"
      />
      <path
        d="M43.0433 19.6487H46.3609V21.8314H44.0986C43.8191 21.8314 43.5511 21.7204 43.3535 21.5228C43.1558 21.3252 43.0448 21.0571 43.0448 20.7776V19.6465L43.0433 19.6487Z"
        fill="#00B6FF"
      />
      <path
        d="M49.6045 16.3232V21.8313C49.3178 21.8314 49.0338 21.7752 48.7689 21.6656C48.504 21.556 48.2632 21.3954 48.0604 21.1927C47.8575 20.9901 47.6966 20.7495 47.5868 20.4847C47.477 20.2199 47.4204 19.936 47.4203 19.6493V15.2705H48.5515C48.8307 15.2707 49.0984 15.3817 49.2958 15.5791C49.4933 15.7766 49.6043 16.0443 49.6045 16.3235V16.3232Z"
        fill="#1677FF"
      />
      <path
        d="M49.6052 22.9017V26.21H48.4741C48.1946 26.21 47.9265 26.099 47.7289 25.9014C47.5313 25.7038 47.4203 25.4357 47.4203 25.1563V22.9014H49.6052V22.9017Z"
        fill="#00B6FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_1912_10064">
        <rect
          width="48"
          height="11.5349"
          fill="white"
          transform="translate(6 15)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default SvgAlipayPlus
