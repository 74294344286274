import * as React from 'react'
export const SvgEmailOutline = ({
  fill,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M22 5v14H2V5h20zm-7.713 7.702l-2.34 1.89-2.272-1.854L5.414 17h13.171l-4.298-4.298zM20 8.092l-4.148 3.347L20 15.586V8.092zM4 8.107v7.478l4.118-4.118L4 8.107zM18.167 7H5.807l6.146 5.016L18.167 7z"
      fill={fill}
    />
  </svg>
)
export default SvgEmailOutline
