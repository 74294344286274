import * as React from 'react'
export const SvgPopTracker = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g data-name="pop-tracker-svg">
      <path d="M8.71 17.73H7.08a.81.81 0 01-.81-.82V12a.82.82 0 01.81-.82h1.63a.82.82 0 01.82.82v4.9a.82.82 0 01-.82.83zm-1.63-5.88a.16.16 0 00-.16.16v4.9a.16.16 0 00.16.17h1.63a.17.17 0 00.17-.17V12a.17.17 0 00-.17-.16z" />
      <path d="M14.71 17.73h-4a1.29 1.29 0 01-.58-.13L9.06 17a.31.31 0 01-.15-.43.32.32 0 01.44-.15l1.1.55a.59.59 0 00.29.07h4a.34.34 0 00.29-.17l.37-.65a5.33 5.33 0 00.7-2.65V12.5a.65.65 0 00-.65-.65H13a.67.67 0 01-.54-.29.66.66 0 01-.06-.61 6.3 6.3 0 00.4-2.35v-.38a.3.3 0 00-.3-.3h-.21a.31.31 0 00-.29.22l-.12.45a7.62 7.62 0 01-1.36 2.62 1.69 1.69 0 01-1.32.64h-1a.33.33 0 110-.66h1a1 1 0 00.8-.39 6.74 6.74 0 001.24-2.39l.13-.41a1 1 0 01.92-.69h.21a1 1 0 01.95 1v.29a6.93 6.93 0 01-.45 2.59h2.46a1.31 1.31 0 011.31 1.31v1.11a6 6 0 01-.8 3l-.37.65a1 1 0 01-.89.47z" />
      <circle cx={11.5} cy={12.5} r={10} />
    </g>
  </svg>
)
export default SvgPopTracker
