import * as React from 'react'
export const SvgLeftAligned = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || '#333'}
      d="M3 11h9v2H3v-2zm0-5h18v2H3V6zm0 10h11v2H3v-2z"
    />
  </svg>
)
export default SvgLeftAligned
