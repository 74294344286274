import { styled } from '@thg-commerce/gravity-patterns/theme'
import {
  css,
  ElevationLevel,
  spacing,
  Text,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'

import { Label } from '../FormItem/Label'

import { IconPosition } from './CustomDropdown'

const CONTAINER_WIDTH = '295px'
const CONTAINER_HEIGHT = '48px'

export const Dropdown = styled.div<{
  width?: string
  hasMarginBottom?: boolean
  hasMessageError?: boolean
}>`
  border-radius: ${(props) =>
    props.theme.formgroup.general.border.default.radius};
  height: ${CONTAINER_HEIGHT};
  width: ${(props) => props.width || CONTAINER_WIDTH};
  appearance: none;
  ${(props) =>
    props.hasMessageError
      ? `margin-bottom: ${spacing(8.5)};`
      : props.hasMarginBottom && `margin-bottom: ${spacing(2)};`}
`

export const DropdownListItem = styled.li<{
  selected: boolean
  focused: boolean
  darkStyling?: boolean
  iconPosition?: IconPosition
  useDisabledStyling?: boolean
}>`
  ${(props) =>
    props.selected
      ? Text('bodyText', 'alternate')
      : Text('bodyText', 'default')};
  padding: ${spacing(1.5)};
  display: flex;
  margin: 0;
  align-items: center;
  cursor: pointer;
  background: inherit;
  ${(props) =>
    props.iconPosition === IconPosition.LEFT
      ? `flex-direction: row-reverse;
      justify-content: space-between;`
      : `flex-direction: row; 
       justify-content: flex-end;`}
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) =>
    props.darkStyling && props.theme.colors.palette.greys.white};

  ${(props) =>
    props.focused &&
    (props.darkStyling
      ? css`
          background-color: ${(props) =>
            props.theme.colors.palette.greys.darker};
        `
      : css`
          background-color: ${(props) =>
            props.theme.colors.palette.brand.lightest};
        `)}

  ${(props) =>
    props.useDisabledStyling &&
    `color: ${props.theme.colors.palette.greys.light}; pointer-events: none;`}

  &:hover {
    background-color: ${(props) =>
      props.darkStyling
        ? props.theme.colors.palette.greys.darker
        : props.theme.colors.palette.brand.lightest};
  }
  &:active {
    background-color: ${(props) =>
      props.darkStyling
        ? props.theme.colors.palette.greys.dark
        : props.theme.colors.palette.brand.lighter};
  }
`

export const OptionsContainer = styled.div<{
  width?: string
  darkStyling?: boolean
  fixPosition?: boolean
  countrySelectorStyling?: boolean
  ignoreCustomWidthOnDropdown?: boolean
  useDefaultWidth?: boolean
  dropdownWidth?: string
  maxDropdownHeight?: string
  stickyPosition?: boolean
  removePadding?: boolean
  zIndex?: ZIndexLevel
}>`
  ${(props) =>
    Text((props.countrySelectorStyling && 'small') || 'bodyText', 'default')}
  ${ElevationLevel(2)};
  border-radius: ${(props) =>
    props.theme.formgroup.general.border.default.radius};
  overflow-y: auto;
  padding: ${spacing(1)} 0;
  z-index: ${(props) => (props.zIndex ? props.zIndex : ZIndexLevel.Base5)};
  background-color: ${(props) =>
    props.darkStyling
      ? props.theme.colors.palette.accent.darkest
      : props.theme.colors.palette.greys.white};

  ${(props) =>
    props.stickyPosition
      ? `position: absolute;`
      : `position: ${props.fixPosition ? 'fixed' : 'relative'}`};

  margin-top: ${spacing(0.5)};
  width: ${(props) =>
    (!props.useDefaultWidth && (props.dropdownWidth || props.width)) ||
    CONTAINER_WIDTH};
  border: none;
  outline: none;

  ${(props) =>
    props.maxDropdownHeight &&
    `
    max-height: ${props.maxDropdownHeight};
  `}
`

export const TickContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${spacing(1)};
  height: 16px;
  width: 16px;
`

export const DropdownButton = styled.button<{
  dropdownOpen: boolean
  darkStyling?: boolean
  countrySelectorStyling?: boolean
  buttonActive?: boolean
  width?: string
  borderColor?: string
  hasErrorBorder?: boolean
}>`
  ${(props) =>
    Text(
      (props.countrySelectorStyling && 'small') ||
        props.theme.elements.dropdown.labelText.style.entry,
      props.theme.elements.dropdown.labelText.style.style,
    )}
  padding: calc(${spacing(1.5)} - 1px);
  display: flex;
  width: ${(props) => props.width || '100%'};
  height: 100%;
  background-color: ${(props) =>
    props.darkStyling
      ? props.theme.colors.palette.accent.darkest
      : props.theme.colors.palette.greys.white};

  border: 1px solid
    ${(props) =>
      props.hasErrorBorder
        ? props.theme.colors.error.base
        : props.borderColor
        ? props.borderColor
        : props.countrySelectorStyling
        ? props.theme.patterns.footer.navigation.countrySelectorColor
        : props.theme.colors.palette.greys.dark};
  align-items: center;
  position: relative;
  border-radius: ${(props) =>
    props.theme.formgroup.general.border.default.radius};
  color: ${(props) =>
    (props.darkStyling && props.theme.colors.palette.greys.white) ||
    (props.countrySelectorStyling &&
      props.theme.patterns.footer.navigation.countrySelectorColor)};

  &:disabled {
    cursor: not-allowed;
    border: 1px solid ${(props) => props.theme.colors.palette.greys.light};
    color: ${(props) => props.theme.colors.palette.greys.light};
  }

  &:focus {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
    padding: calc(${spacing(1.5)} - 2px);
    outline: none;
  }

  ${(props) =>
    props.buttonActive &&
    `border: 2px solid ${props.theme.colors.palette.greys.darker};
    ${ElevationLevel(3)}`};

  span {
    min-width: fit-content;
  }
  svg {
    path {
      fill: ${(props) =>
        (props.darkStyling && props.theme.colors.palette.greys.white) ||
        (props.countrySelectorStyling &&
          props.theme.patterns.footer.navigation.countrySelectorColor)};
    }
    ${(props) => props.dropdownOpen && `transform: rotate(180deg);`}
  }
`

export const StyledLabel = styled(Label)<{
  darkStyling?: boolean
  removeLabelMargin?: boolean
}>`
  ${(props) => Text('bodyText', props.darkStyling ? 'default' : 'alternate')}
  margin: ${(props) =>
    props.removeLabelMargin
      ? '0'
      : props.darkStyling
      ? `0 0 ${spacing(1)} 0`
      : `${spacing(2)} 0`};
  color: ${(props) =>
    props.darkStyling
      ? props.theme.colors.palette.greys.white
      : props.theme.colors.palette.accent.darker};
`

export const DropdownIcon = styled.div`
  margin-right: ${spacing(0.5)};
  display: flex;
`

export const DropdownElementContainer = styled.div<{
  iconPosition?: IconPosition
}>`
  width: 100%;
  display: flex;
  ${(props) =>
    props.iconPosition === IconPosition.LEFT
      ? 'flex-direction: reverse;'
      : 'flex-direction: row-reverse;'}
  align-items: center;
  justify-content: space-between;
`

export const Item = styled.div<{
  customFontStyleOverride?: boolean
  itemFontStyle?: string
}>`
  display: flex;
  flex-grow: 1;
  ${(props) =>
    props?.customFontStyleOverride && `font-family: ${props?.itemFontStyle}`}
`
