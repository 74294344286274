import * as React from 'react'

import { Button } from '@thg-commerce/gravity-elements'
import { styled, useTheme } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing, Text } from '@thg-commerce/gravity-theme'

export interface NewsletterSignUpProps {
  text: string
  buttonText: string
  signUpLink: string
}

const StyledNewsletterSignup = styled.div`
  margin-right: ${spacing(0)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-right: ${spacing(2)};
  }
`

const TagLine = styled.p`
  ${(props) =>
    Text(
      props.theme.patterns.footer.customerEngagement.headerText.entry,
      props.theme.patterns.footer.customerEngagement.headerText.style,
    )};
  ${(props) => `
  text-transform: ${props.theme.patterns.footer.customerEngagement.headerText.transform};
  text-decoration: ${props.theme.patterns.footer.customerEngagement.headerText.textDecoration};
  color: ${props.theme.patterns.footer.customerEngagement.headerText.textColor};`}
  margin-bottom: ${spacing(2)};
`

const StyledButtonText = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.footer.customerEngagement.cta.entry,
      props.theme.patterns.footer.customerEngagement.cta.style,
    )}
  text-transform: ${(props) =>
    props.theme.patterns.footer.customerEngagement.cta.transform};
  text-decoration: ${(props) =>
    props.theme.patterns.footer.customerEngagement.cta.textDecoration};
  color: inherit;
`

export const NewsletterSignUp = (props: NewsletterSignUpProps) => {
  const theme = useTheme()

  return (
    <StyledNewsletterSignup>
      <TagLine data-testid="signup-tagline">{props.text}</TagLine>
      <Button
        renderedAs="a"
        href={props.signUpLink}
        emphasis={theme.patterns.footer.customerEngagement.ctaEmphasis}
        data-testid="signup-button"
        aria-label={props.text}
      >
        <StyledButtonText>{props.buttonText}</StyledButtonText>
      </Button>
    </StyledNewsletterSignup>
  )
}
