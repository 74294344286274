import { Property } from 'csstype'

export interface GridInterface {
  columnGap: Property.Width<string>
  rowGap: Property.Width<string>
  columnCount: number
  rowCount: number
  maxWidth: number
  breakpoints: string[]
}

export const grid: GridInterface = {
  columnGap: '32px',
  rowGap: '16px',
  columnCount: 12,
  rowCount: 1,
  maxWidth: 1400,
  breakpoints: ['xs', 'sm', 'md', 'lg'],
}
