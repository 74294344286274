import * as React from 'react'

export const SVGStorefront = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5815 1.89667L21.719 6.63083C21.9899 7.73583 21.7407 8.87333 21.0474 9.76167C20.9892 9.83432 20.9165 9.90211 20.8455 9.96829L20.8454 9.96832C20.8106 10.0008 20.7761 10.0329 20.744 10.065V17.5833C20.744 18.775 19.769 19.75 18.5774 19.75H3.41069C2.21903 19.75 1.24403 18.775 1.24403 17.5833V10.065C1.20503 10.0217 1.16258 9.98182 1.12012 9.94196L1.12011 9.94195L1.12009 9.94194C1.05639 9.88214 0.992693 9.82233 0.940692 9.75083C0.247359 8.8625 0.00902571 7.73583 0.269026 6.63083L1.40653 1.89667C1.63403 0.9325 2.48986 0.25 3.46486 0.25H18.5124C19.4982 0.25 20.3432 0.921667 20.5815 1.89667ZM19.6174 7.14L18.4799 2.40583L16.389 2.41667L17.0174 7.68167C17.0932 8.38583 17.6674 8.91667 18.3282 8.91667C18.8699 8.91667 19.1949 8.6025 19.3465 8.4075C19.6282 8.05 19.7257 7.595 19.6174 7.14ZM12.0774 2.41667H14.2007L14.7857 7.31333C14.8399 7.73583 14.7099 8.15833 14.4282 8.4725C14.1899 8.75417 13.8432 8.91667 13.399 8.91667C12.6732 8.91667 12.0774 8.2775 12.0774 7.4975V2.41667ZM7.78736 2.41667L7.19153 7.31333C7.14819 7.73583 7.27819 8.15833 7.54903 8.4725C7.80903 8.75417 8.14486 8.91667 8.51319 8.91667C9.31486 8.91667 9.91069 8.2775 9.91069 7.4975V2.41667H7.78736ZM2.37058 7.14L3.46475 2.41667H5.59891L4.97058 7.68167C4.88391 8.38583 4.32058 8.91667 3.65975 8.91667C3.12891 8.91667 2.79308 8.6025 2.65225 8.4075C2.35975 8.06083 2.26225 7.595 2.37058 7.14ZM3.41069 11.0508V17.5833H18.5882V11.0508C18.5578 11.0546 18.5287 11.0598 18.5 11.0648C18.447 11.0742 18.3953 11.0833 18.339 11.0833C17.3965 11.0833 16.5407 10.6933 15.9124 10.0542C15.2624 10.7042 14.3957 11.0833 13.4857 11.0833C12.5107 11.0833 11.644 10.6933 11.0049 10.0758C10.3765 10.6933 9.53153 11.0833 8.58903 11.0833C7.60319 11.0833 6.73653 10.7042 6.08653 10.0542C5.45819 10.6933 4.60236 11.0833 3.65986 11.0833C3.6036 11.0833 3.55191 11.0742 3.49886 11.0648C3.47018 11.0598 3.4411 11.0546 3.41069 11.0508Z"
      fill={props.fill || '#333333'}
    />
  </svg>
)

export default SVGStorefront
