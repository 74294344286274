import * as React from 'react'
export const SvgGreyCircularTikTok = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <circle fill={props.fill || '#D8D8D8'} cx={20} cy={20} r={20} />
    <path
      fill={props.fill || '#333333'}
      fillRule="evenodd"
      d="M24.2089 7c0 .33361.044.69289.0996 1.0208H21.055v15.7654c0 .7271-.0761 1.3743-.2284 1.9418-1.3237 2.84-5.0721 2.4693-6.1088.5189 1.5433.941 4.0794.6901 5.119-1.5426.1522-.5674.2284-1.2147.2284-1.9418V7h4.1437zm2.211 4.7675c.8376.653 1.9943 1.132 3.5874 1.2261v.9068c-1.763-.3194-2.8787-1.1748-3.5874-2.1329zM11.5434 29.0812c-5.30635-4.4824-1.87714-14.4651 6.2815-13.2077v.9239c-6.8877-.3108-9.92156 7.6902-6.2815 12.2838z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || '#333333'}
      fillRule="evenodd"
      d="M25.1988 8.0208v.01996c0 .36498.1112 5.64294 5.7983 5.97084 0 4.9101.0029 0 .0029 4.0775-.4275.0256-3.7455-.2082-5.8071-2.0017l-.0059 7.9383c.0498 3.5956-2.003 7.1199-5.851 7.8299-1.0777.1996-2.0499.2224-3.6781-.1255-9.38572-2.7344-6.26692-16.29 3.1568-14.8386 0 4.3769.003 0 .003 4.3769-3.8949-.5589-5.1951 2.5948-4.1614 4.8531.943 2.0558 4.8173 2.5006 6.1703-.3992.1523-.5675.2284-1.2147.2284-1.9418V8.0208h4.1438z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || '#333333'}
      fillRule="evenodd"
      d="M25.1988 8.0208v.01996c0 .21385.038 2.12144 1.2211 3.72674-1.4232-1.1092-1.9328-2.71165-2.1114-3.7467h.8903zm4.8085 5.8767c.3104.0542.6413.0941.9898.1141 0 4.9101.0029 0 .0029 4.0775-.4275.0256-3.7455-.2082-5.8071-2.0017l-.0059 7.9383c.0498 3.5956-2.003 7.1199-5.851 7.8299-1.0777.1996-2.0499.2224-3.6781-.1255-1.8332-.5332-3.1891-1.4827-4.1145-2.6517.8258.6985 1.8625 1.2631 3.1246 1.6309 1.6283.3451 2.5976.3223 3.6753.1255 3.8479-.7071 5.9037-4.2343 5.8539-7.827l.0059-7.9412c2.0645 1.7935 5.3795 2.0273 5.81 2.0017 0-3.7867-.0058.1768-.0058-3.1708zm-12.1824 2.8999c.3221.0142.6531.0456.9898.0969 0 4.3769.003 0 .003 4.3769-3.8949-.5588-5.1951 2.5948-4.1614 4.8531.0176.0399.041.0827.0615.1226-.4627-.2823-.8346-.6701-1.0513-1.1434-1.0337-2.2583.2665-5.4119 4.1613-4.8531-.0029-4.0119-.0029-.6757-.0029-3.453z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgGreyCircularTikTok
