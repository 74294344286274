import { getMetaObject } from './meta'
import { PerformanceData } from '../'

export const getSpaLoadPerfData = (
  siteDefinition,
  customerLocation,
  subsite,
  metricNonce,
): PerformanceData => {
  const perfData = {
    meta: getMetaObject(siteDefinition, customerLocation, subsite, metricNonce),
    key: 'pageLoad',
  }

  return perfData
}
