import * as React from 'react'
export const SvgVisa = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={40} {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#1A1F71">
        <path d="M45.77 13.651c-.835 0-1.438.255-1.804 1.082l-5.16 11.616h3.686l.717-1.946h4.403l.41 1.946h3.276L48.431 13.65H45.77zm-9.057-.204c1.35 0 2.43.276 3.12.533l-.472 2.752-.311-.147a6.477 6.477 0 00-2.607-.487c-1.362 0-1.994.565-1.994 1.093-.007.595.737.986 1.955 1.574 2.009.907 2.936 2.005 2.924 3.45-.028 2.636-2.403 4.338-6.064 4.338-1.561-.014-3.065-.322-3.878-.676l.488-2.844.449.202c1.144.475 1.884.667 3.277.667 1.001 0 2.075-.39 2.084-1.24.006-.555-.45-.953-1.806-1.576-1.32-.606-3.069-1.625-3.05-3.449.021-2.468 2.444-4.19 5.885-4.19zm-7.123.204L27.44 26.35h-3.48l2.15-12.698h3.481zm-4.812 0l-5.53 12.698h-3.686l-3.077-11.06c2.21 1.101 4.184 3.297 4.818 5.325l.306 1.741 3.482-8.704h3.687zm21.258 3.451l.236 1.094.805 3.668h-2.89c.288-.733 1.387-3.55 1.387-3.55-.02.033.285-.735.462-1.212z" />
        <path d="M8.702 13.651v.206c4.368 1.05 7.401 3.706 8.601 6.757l-1.262-5.878c-.21-.805-.85-1.056-1.606-1.085H8.702" />
      </g>
    </g>
  </svg>
)
export default SvgVisa
