import * as React from 'react'
export const SvgGravityCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} {...props}>
    <g fill="none">
      <circle fill="#FFF" cx={24} cy={24} r={24} />
      <path
        d="M31.073 14.567c-.899-.263-1.815.274-2.047 1.2-.284 1.132.85 3.734 1.003 4.873.066.49.054.954.12.844.375-.622 2.035-3.631 2.177-4.921.128-1.163-.355-1.733-1.253-1.996"
        fill="#FFF"
      />
      <path
        d="M24.768 23.232a2.5 2.5 0 11-3.536 3.536 2.5 2.5 0 013.536-3.536m6.853-6.853a3 3 0 11-4.242 4.242 3 3 0 014.242-4.242m5-5a3 3 0 11-4.242 4.242 3 3 0 014.242-4.242m-8.345-4.776a3 3 0 11-1.553 5.794 3 3 0 011.553-5.794m-9.923-.017a2.5 2.5 0 111.294 4.829 2.5 2.5 0 01-1.294-4.83M11.94 12.94a1.5 1.5 0 112.122 2.122 1.5 1.5 0 01-2.122-2.122m-5.387 7.673a1.5 1.5 0 112.897.776 1.5 1.5 0 01-2.897-.776m.982 9.147a1 1 0 111.933-.517 1 1 0 01-1.933.517m4.259 6.448a1 1 0 111.415-1.414 1 1 0 01-1.415 1.414m6.448 4.259a1 1 0 11.518-1.932 1 1 0 01-.518 1.932m9.147.983a1.5 1.5 0 11-.776-2.898 1.5 1.5 0 01.776 2.898m7.673-5.388a1.5 1.5 0 11-2.122-2.121 1.5 1.5 0 012.122 2.12m6.353-6.413a2.499 2.499 0 11-4.827-1.293 2.499 2.499 0 014.827 1.293m-.017-9.923a3 3 0 11-5.794 1.552 3 3 0 015.794-1.552m-21.044-5.138a2.5 2.5 0 111.294 4.829 2.5 2.5 0 01-1.294-4.83m-5.802 7.027a1.5 1.5 0 112.898.777 1.5 1.5 0 01-2.898-.777m1.388 10.449a1.5 1.5 0 112.12-2.122 1.5 1.5 0 01-2.12 2.122m10.45 1.387a1.5 1.5 0 11-.777-2.897 1.5 1.5 0 01.776 2.897m7.026-5.801a2.499 2.499 0 11-4.827-1.293 2.499 2.499 0 014.827 1.293"
        fill="#000"
      />
    </g>
  </svg>
)
export default SvgGravityCircle
