import * as React from 'react'
export const SvgCircularFacebook = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <g fill="none">
      <circle fill="#4267B2" cx={20} cy={20} r={20} />
      <path
        d="M17.068 16.615v-2.01c0-.886.022-2.252.666-3.099.678-.897 1.608-1.506 3.21-1.506 2.607 0 3.705.372 3.705.372l-.516 3.062s-.862-.248-1.666-.248c-.804 0-1.524.288-1.524 1.091v2.338h3.297l-.23 2.99h-3.067V30h-3.875V19.606H15v-2.991h2.068z"
        fill="#FFF"
      />
    </g>
  </svg>
)
export default SvgCircularFacebook
