import fs from 'fs'
import path from 'path'

import { SiteConfigMap } from './types'

const CONFIGURATION_PATH = process.env.CONFIGURATION_PATH

export const loadConfigFile = <T>(config: {
  brand: string
  subsite: string
  fileName: string
}): T | undefined => {
  if (!CONFIGURATION_PATH) {
    return undefined
  }

  const fullPath = path.posix.join(
    CONFIGURATION_PATH,
    config.brand,
    config.subsite,
    `${config.fileName}.json`,
  )

  if (!fs.existsSync(fullPath)) {
    return undefined
  }

  const data = JSON.parse(fs.readFileSync(fullPath, 'utf8')) as T

  return data
}

export const loadSiteConfigMap = (): SiteConfigMap => {
  if (!CONFIGURATION_PATH) {
    return {}
  }

  const filePath = path.posix.join(CONFIGURATION_PATH, 'siteConfigMap.json')

  if (!fs.existsSync(filePath)) {
    console.warn(`warn [ CONFIGURATION ] ${filePath} may not exist`)

    return {}
  }

  try {
    return JSON.parse(fs.readFileSync(filePath, 'utf8'))
  } catch (error) {
    console.warn(
      `warn [ CONFIGURATION ] ${filePath} may not exist and failed to load with error: ${error}`,
    )

    return {}
  }
}

export const loadConfigFileForHost = <T>(
  host: string,
  fileName: string,
): T | undefined => {
  const siteConfig = siteCodeForHost(host)

  if (!siteConfig) {
    console.warn(`Failed to load site config for ${host}`)
    return undefined
  }

  return loadConfigFile<T>({ ...siteConfig, fileName })
}

export const siteCodeForHost = (
  host: string,
): { brand: string; subsite: string } | null => {
  const siteConfigMap = loadSiteConfigMap()
  return siteConfigMap[host] || null
}
