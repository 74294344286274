import { Property } from 'csstype'
import { colors } from '../colors'
import { TextEntry, TextStyle } from '../'

interface MessageTextInterface {
  entry: TextEntry
  style: TextStyle
}

export interface CookieModalInterface {
  backgroundColor: Property.Color
  borderWidth: Property.BorderWidth<string>
  borderColor: Property.BorderColor
  borderStyle: Property.BorderStyle
  svgFillColor: Property.Color
  messageText: MessageTextInterface
}

export const cookiemodal: CookieModalInterface = {
  backgroundColor: colors.palette.greys.white,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: colors.palette.greys.grey,
  svgFillColor: colors.palette.greys.darker,
  messageText: {
    entry: 'bodyText',
    style: 'default',
  },
}
