import * as React from 'react'

import { SubscribableRef } from '@thg-commerce/enterprise-utils'

import { BasketContextInterface } from './types'

const basketIdRef: SubscribableRef<string | undefined> = {
  value: [
    () => {
      return undefined
    },
    () => {},
  ],
  subscribe: () => {},
  unsubscribe: () => {},
}

export const BasketContext = React.createContext<BasketContextInterface>({
  basketIdRef,
})
