import * as React from 'react'
export const SvgPaypal = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={54} height={16} {...props}>
    <g fill="none">
      <path
        fill="#0093C7"
        d="M36.764 4.439C36.54 5.99 35.412 5.99 34.322 5.99h-.62l.435-2.897a.348.348 0 01.338-.304h.285c.742 0 1.443 0 1.804.445.216.266.282.66.2 1.205zM36.29.39h-4.112a.58.58 0 00-.564.507L29.95 11.98c-.033.219.128.416.339.416h2.11c.196 0 .364-.15.394-.354l.472-3.142a.58.58 0 01.564-.507h1.3c2.71 0 4.272-1.378 4.68-4.108.185-1.194.008-2.133-.524-2.79C38.701.773 37.666.391 36.29.391z"
      />
      <path
        fill="#213170"
        d="M7.439 4.439C7.214 5.99 6.087 5.99 4.997 5.99h-.62l.434-2.897a.35.35 0 01.339-.304h.284c.742 0 1.443 0 1.805.445.216.266.282.66.2 1.205zM6.964.39H2.853a.582.582 0 00-.565.507L.626 11.98c-.033.219.128.416.338.416h1.963a.58.58 0 00.565-.506L3.94 8.9a.582.582 0 01.565-.507h1.3c2.709 0 4.272-1.378 4.68-4.108.184-1.194.008-2.133-.524-2.79C9.376.773 8.34.391 6.964.391zm9.546 8.027c-.19 1.183-1.084 1.976-2.223 1.976-.57 0-1.028-.193-1.322-.558-.29-.363-.4-.879-.308-1.454.177-1.172 1.084-1.991 2.206-1.991.56 0 1.013.195 1.313.564.301.371.42.891.334 1.463zm2.743-4.028h-1.968a.348.348 0 00-.339.304l-.087.579-.137-.21c-.427-.65-1.377-.868-2.326-.868-2.175 0-4.033 1.733-4.394 4.163-.189 1.212.078 2.37.732 3.18.601.742 1.459 1.052 2.48 1.052 1.753 0 2.726-1.184 2.726-1.184l-.088.575c-.033.219.128.416.338.416h1.773a.58.58 0 00.564-.506l1.065-7.085c.033-.218-.128-.416-.339-.416z"
      />
      <path
        fill="#0093C7"
        d="M45.835 8.417c-.19 1.183-1.083 1.976-2.222 1.976-.571 0-1.028-.193-1.322-.558-.292-.363-.401-.879-.309-1.454.177-1.172 1.085-1.991 2.207-1.991.559 0 1.013.195 1.312.564.302.371.42.891.334 1.463zm2.744-4.028h-1.97a.349.349 0 00-.338.304l-.086.579-.138-.21c-.426-.65-1.377-.868-2.325-.868-2.175 0-4.034 1.733-4.395 4.163-.188 1.212.079 2.37.733 3.18.6.742 1.458 1.052 2.48 1.052 1.753 0 2.725-1.184 2.725-1.184l-.088.575c-.033.219.128.416.339.416h1.773a.58.58 0 00.564-.506l1.064-7.085c.033-.218-.128-.416-.338-.416z"
      />
      <path
        fill="#213170"
        d="M29.74 4.389h-1.98a.564.564 0 00-.472.263l-2.73 4.226-1.157-4.06a.575.575 0 00-.547-.429h-1.945c-.235 0-.4.243-.325.476l2.18 6.724-2.05 3.04c-.161.238.001.568.28.568h1.976a.563.563 0 00.47-.258l6.581-9.984c.157-.24-.005-.566-.282-.566"
      />
      <path
        fill="#0093C7"
        d="M50.9.695L49.211 11.98c-.033.219.128.416.338.416h1.697c.282 0 .52-.215.565-.506L53.475.808c.033-.22-.127-.417-.338-.417h-1.9a.35.35 0 00-.338.304"
      />
    </g>
  </svg>
)
export default SvgPaypal
