import * as React from 'react'
export const SvgBat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g data-name="Layer 10">
      <path d="M16.66 19a8.9 8.9 0 00-4.83-1.32A8.83 8.83 0 007 19a4.09 4.09 0 00-2.1 3.15c0 .17.2.31.45.31h13c.25 0 .46-.14.46-.31A4.2 4.2 0 0016.66 19zM6.77 11.65a.49.49 0 00.06.22A5 5 0 0011 15.6l.63.6.78-.6a5 5 0 001-.25h.14a5 5 0 003.17-4.78 5.88 5.88 0 00-.11-.95l.09-7.11-2.55 3.61a4.4 4.4 0 00-2.43-.71 4.24 4.24 0 00-2.41.72L6.78 2.46v7.12a5 5 0 00-.11 1 5.91 5.91 0 00.1 1.07z" />
      <path d="M8.3 11.34a.34.34 0 01.33-.19 10.41 10.41 0 012 .75c.08 0 .27.26 0 .38s-.77.47-1 .56l-.21.06a3.83 3.83 0 01-1.12-1.56zM13.83 12.84c-.25-.09-.78-.45-1-.56s0-.35 0-.38a10.41 10.41 0 012-.75.35.35 0 01.35.25 3.7 3.7 0 01-1.11 1.51z" />
    </g>
  </svg>
)
export default SvgBat
