import { Property } from 'csstype'
import { colors } from '../colors'
import { TextEntry, TextStyle } from '../'

export type BorderValueTypes =
  | 'default'
  | 'active'
  | 'disabled'
  | 'focus'
  | 'hover'

export type BorderInterface = {
  [key in BorderValueTypes]: BorderValuesInterface
}

export interface BorderValuesInterface {
  width: Property.BorderWidth<string>
  style: Property.BorderStyle
  color: Property.BorderColor
  radius?: Property.BorderRadius<string>
}

export type FontTypes = 'input' | 'icon' | 'error' | 'helper'

export type FontInterface = {
  scale: TextEntry
  style: TextStyle
  autofillColor?: Property.Color
}

export interface FormGroupInterface {
  general: GeneralInterface
  error: FontInterface
  helper: FontInterface
  input: FontInterface
  icon: FontInterface
  label: FontInterface
  requiredLabel: FontInterface
  optionalLabel: FontInterface
}

export interface GeneralInterface {
  border: BorderInterface
  padding: Property.Padding<string>
  background: Property.Color
}

const border: BorderInterface = {
  default: {
    width: '1px',
    style: 'solid',
    color: colors.palette.greys.grey,
    radius: '4px',
  },
  active: {
    width: '1px',
    style: 'solid',
    color: colors.palette.greys.darker,
  },
  disabled: {
    width: '1px',
    style: 'solid',
    color: colors.palette.greys.light,
  },
  focus: {
    width: '2px',
    style: 'solid',
    color: colors.palette.brand.base,
  },
  hover: {
    width: '1px',
    style: 'solid',
    color: colors.palette.greys.darker,
  },
}

const general: GeneralInterface = {
  border,
  padding: '0px 8px',
  background: colors.palette.greys.white,
}

const error: FontInterface = {
  scale: 'small',
  style: 'alternate',
}

const helper: FontInterface = {
  scale: 'small',
  style: 'default',
}

const input: FontInterface = {
  scale: 'bodyText',
  style: 'default',
  autofillColor: colors.palette.greys.darker,
}

const icon: FontInterface = {
  scale: 'xsmall',
  style: 'alternate',
}

const label: FontInterface = {
  scale: 'bodyText',
  style: 'alternate',
}

const requiredLabel: FontInterface = {
  scale: 'xsmall',
  style: 'default',
}

const optionalLabel: FontInterface = {
  scale: 'bodyText',
  style: 'default',
}

export const formgroup: FormGroupInterface = {
  general,
  error,
  helper,
  input,
  icon,
  label,
  requiredLabel,
  optionalLabel,
}
