import { styled, Text } from '@thg-commerce/gravity-theme'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const LoyaltyText = styled.span`
  ${Text('bodyText', 'alternate')};
`
