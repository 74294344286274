import { Order, Orientation } from '@thg-commerce/gravity-patterns/Header/types'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import {
  mq,
  padding,
  spacing,
  Text,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'

import { HeaderButtonCommonStyles } from './FlyoutMenu/styles'
import { HeaderZIndexLevel } from './types'

export const StyledHeader = styled.header<{ sticky: boolean }>`
  ${zIndex(HeaderZIndexLevel.Header)};
  position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
  top: 0;
  background: ${(props) => props.theme.patterns.header.mobileBackgroundColor};
  height: ${(props) =>
    props.theme.patterns.header.headerLogo.mobile.headerHeight};
  width: 100%;
  border-bottom: ${(props) =>
    props.theme.patterns.header.navigation.borderBottom};
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    height: auto;
    border: none;
    top: ${(props) =>
      props.sticky ? props.theme.patterns.header.sticky?.top || '-32px' : '0'};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    background: ${(props) => props.theme.patterns.header.backgroundColor};
  }
`

export const HeaderContainer = styled.div`
  ${zIndex(HeaderZIndexLevel.Header)};
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    max-width: ${(props) => props.theme.site.siteWidth};
    margin: 0 auto;
    height: ${(props) =>
      `calc(${props.theme.patterns.header.headerLogo.desktop.verticalMargin} * 2 +
      ${props.theme.patterns.header.headerLogo.desktop.height})`};
  }
`

export const OpenMobileSearchWrapper = styled.div`
  width: 100%;
  ${zIndex(ZIndexLevel.Base5)};
  max-width: ${(props) =>
    props.theme.patterns.header.navigation.mobileSearchWrapper.MaxWidth};
  margin: ${(props) =>
      props.theme.patterns.header.navigation.mobileSearchWrapper.margin}
    || ${spacing(1)} auto;
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: none;
  }
`

export const StoreHeaderLink = styled.a`
  ${HeaderButtonCommonStyles}
`
export const StoreHeaderLinkWrapper = styled.div<{
  mobileOrder: string
}>`
  order: ${(props) => (props.mobileOrder ? props.mobileOrder : Order.three)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: none;
  }
`
export const LogoContainer = styled.div<{
  mobileOrder: string
  desktopOrder: string
}>`
  order: ${(props) => (props.mobileOrder ? props.mobileOrder : Order.two)};
  width: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    order: ${(props) => (props.desktopOrder ? props.desktopOrder : Order.one)};
    margin-right: auto;
    ${(props) =>
      props.theme.patterns.header.headerLogo?.desktop?.container?.margin
        ?.left &&
      `margin-left: ${props.theme.patterns.header.headerLogo.desktop.container?.margin?.left};`}
    justify-content: center;
    flex: ${(props) =>
      props.theme.patterns.header.headerLogo.desktop.container?.flex || `20%`};

    ${(props) =>
      props.theme.patterns.header.headerLogo.desktop.container?.flex
        ? ``
        : props.desktopOrder === Order.three
        ? `padding-left: ${spacing(4)};`
        : `padding: 0 ${spacing(4)};`}
  }
`

export const LogoLink = styled.a`
  width: ${(props) => props.theme.patterns.header.headerLogo.mobile.width};
  display: flex;
  justify-content: ${(props) =>
    props.theme.patterns.header.headerLogo.mobile.alignment};
  height: ${(props) =>
    `calc(${props.theme.patterns.header.headerLogo.mobile.height} + 4px)`};
  border: 2px solid transparent;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    justify-content: ${(props) =>
      props.theme.patterns.header.headerLogo.desktop.alignment};
    height: ${(props) =>
      `calc(${props.theme.patterns.header.headerLogo.desktop.height} + 4px)`};
    width: ${(props) => props.theme.patterns.header.headerLogo.desktop.width};
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: ${(props) =>
      props.theme.patterns.header.headerLogo.mobile?.focus?.borderColor ??
      (props.theme.patterns.header.headerLogo.desktop.borderColor ||
        props.theme.colors.palette.brand.base)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    &:focus {
      border-color: ${(props) =>
        props.theme.patterns.header.headerLogo.desktop.borderColor ||
        props.theme.colors.palette.brand.base};
    }
`

export const LogoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.theme.patterns.header.headerLogo.mobile.height};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    justify-content: ${(props) =>
      props.theme.patterns.header.headerLogo.desktop.alignment || 'flex-start'};
    height: ${(props) => props.theme.patterns.header.headerLogo.desktop.height};
    margin: ${(props) =>
        props.theme.patterns.header.headerLogo.desktop.verticalMargin || '0'}
      0;
  }
`

export const StyledHeaderSearchWrapper = styled.div<{
  mobileOrder: string
  desktopOrder: string
  enableStoreIconOnMobile?: boolean
  alignment: string
  desktopFlex: string
}>`
  order: ${(props) => (props.mobileOrder ? props.mobileOrder : Order.three)};
  width: 44px;
  ${(props) => (props.enableStoreIconOnMobile ? 'display: none' : '')};
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    margin: 0 ${spacing(2)};
    margin-left: ${(props) => props.theme.patterns.header.search.margin?.left};
    order: ${(props) => (props.desktopOrder ? props.desktopOrder : Order.two)};
    flex: ${(props) => props.desktopFlex};
    display: ${(props) => (props.enableStoreIconOnMobile ? 'block' : 'flex')};
    ${(props) => props.alignment && `justify-content: ${props.alignment};`}
  }
`

export const BasketTriggerWrapper = styled.div`
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: inline-block;
    justify-content: center;
  }
`

export const LinkTriggerWrapper = styled.div`
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const TriggersWrapper = styled.div<{
  mobileOrder: string
  desktopOrder: string
  isMobile?: boolean
}>`
  order: ${(props) => (props.mobileOrder ? props.mobileOrder : Order.four)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    order: ${(props) =>
      props.desktopOrder ? props.desktopOrder : Order.three};
    flex: ${(props) => props.theme.patterns.header.dropdowns.flex.width};
    align-items: center;
    justify-content: flex-end;
    ${(props) =>
      padding(props.theme.patterns.header.dropdowns.container.padding)}
  }
`

export const SubmenuWrapper = styled.div`
  position: relative;

  display: none;
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: block;
  }
`

export const LinkContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) =>
    props.theme.patterns.header.headerSlots.orientation ===
    Orientation.HORIZONTAL
      ? 'row'
      : 'column'};
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${(props) =>
      props.theme.patterns.header.headerSlots.textStyling.active.textColor};

    svg {
      fill: ${(props) =>
        props.theme.patterns.header.headerSlots.iconColor.active};
    }
  }

  svg {
    fill: ${(props) =>
      props.theme.patterns.header.headerSlots.iconColor.inactive};
    margin-right: ${(props) =>
      props.theme.patterns.header.headerSlots.orientation ===
      Orientation.HORIZONTAL
        ? spacing(1)
        : '0'};
  }

  ${(props) =>
    Text(
      props.theme.patterns.header.headerSlots.textStyling.inactive.entry,
      props.theme.patterns.header.headerSlots.textStyling.inactive.style,
    )}

  text-transform: ${(props) =>
    props.theme.patterns.header.headerSlots.textStyling.inactive.transform};
  text-decoration: ${(props) =>
    props.theme.patterns.header.headerSlots.textStyling.inactive
      .textDecoration};
  color: ${(props) =>
    props.theme.patterns.header.headerSlots.textStyling.inactive.textColor};

  &:hover {
    ${(props) =>
      Text(
        props.theme.patterns.header.headerSlots.textStyling.active.entry,
        props.theme.patterns.header.headerSlots.textStyling.active.style,
      )}

    cursor: pointer;
    text-transform: ${(props) =>
      props.theme.patterns.header.headerSlots.textStyling.active.transform};
    text-decoration: ${(props) =>
      props.theme.patterns.header.headerSlots.textStyling.active
        .textDecoration};
    color: ${(props) =>
      props.theme.patterns.header.headerSlots.textStyling.active.textColor};
  }

  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};

    svg {
      fill: ${(props) => props.theme.colors.palette.greys.darker};
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    min-width: initial;
    padding: ${spacing(1.25)} ${spacing(2)};
  }
`

export const SubmenuAccountIcon = styled.svg`
  height: ${(props) =>
    props.theme.patterns.header.subNavigation.dropdowns.account.icon.height};
  width: ${(props) =>
    props.theme.patterns.header.subNavigation.dropdowns.account.icon.width};
`

export const LogoSVGContainer = styled.svg<{ type: 'alternate' | 'default' }>`
  ${(props) => {
    const mobileUseAlternateLogo =
      props.theme.patterns.header.headerLogo.mobile.useAlternateLogo

    switch (props.type) {
      case 'alternate':
        return `display: ${
          props.theme.logo.logoAlternateUri &&
          (mobileUseAlternateLogo || !props.theme.logo.logoUri)
            ? 'block'
            : 'none'
        };`
      case 'default':
      default:
        return `display: ${
          props.theme.logo.logoUri && !mobileUseAlternateLogo ? 'block' : 'none'
        };`
    }
  }}

  svg,
  image {
    height: 100%;
    width: 100%;
    overflow: inherit;

    ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
      ${(props) =>
        `width: ${
          props.theme.patterns.header.headerLogo.desktop.svg?.image?.width ||
          `auto`
        };`}
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) => {
      const desktopUseAlternateLogo =
        props.theme.patterns.header.headerLogo.desktop.useAlternateLogo

      switch (props.type) {
        case 'alternate':
          return `display: ${
            props.theme.logo.logoAlternateUri &&
            (desktopUseAlternateLogo || !props.theme.logo.logoUri)
              ? 'block'
              : 'none'
          };`
        case 'default':
        default:
          return `display: ${
            props.theme.logo.logoUri && !desktopUseAlternateLogo
              ? 'block'
              : 'none'
          };`
      }
    }}
  }
`
