import { AppInitialProps } from 'next/app'

import {
  withConfiguration,
  WithConfigurationInitialProps,
} from '@thg-commerce/enterprise-core/src/ConfigurationLoader/withConfiguration'
import {
  withLogger,
  WithLoggerInitialProps,
} from '@thg-commerce/enterprise-core/src/Logger/withLogger'
import {
  EnterpriseApp,
  EnterpriseAppProps,
} from '@thg-commerce/enterprise-core/src/App/App'
import {
  withApollo,
  WithApolloInitialProps,
} from '@thg-commerce/enterprise-network/lib/withApollo'
import {
  BasketProvider,
  CustomerProvider,
  EnterpriseNextAppContext,
} from '@thg-commerce/enterprise-core'

/**
 * The HOC components are rendered in reverse - top to bottom.
 * The ordering is important, so that the correct props are passed between HOC's.
 */
const WithApollo = withApollo<EnterpriseAppProps, AppInitialProps>(
  EnterpriseApp,
)
const WithLogger = withLogger<
  EnterpriseAppProps & WithApolloInitialProps,
  AppInitialProps & WithApolloInitialProps
>(WithApollo)
const WithConfig = withConfiguration<
  EnterpriseAppProps & WithApolloInitialProps,
  AppInitialProps & WithApolloInitialProps & WithLoggerInitialProps
>(WithLogger)

const App = (
  props: WithConfigurationInitialProps &
    EnterpriseAppProps &
    WithApolloInitialProps,
) => {
  const serviceContextProviders = [
    ...(props.serviceContextProviders || []),
    CustomerProvider,
    BasketProvider,
  ]
  return (
    <WithConfig {...props} serviceContextProviders={serviceContextProviders} />
  )
}

App.getInitialProps = (ctx: EnterpriseNextAppContext) => {
  return WithConfig.getInitialProps(ctx)
}

export default App
