import { ProductContentItem } from '@thg-commerce/enterprise-network/src/generated/graphql'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

interface TrackingEvent {
  type: 'elysiumEvent' | 'customEvent' | 'marketing_preference'
  eventData: {
    eventAction: string
    eventCategory: string
    eventLabel?: string
    eventLabelValue?: string
    eventPage?: string
  }
}

export interface DataLayerGA4 {
  event: string
  event_name: string
  ecommerce?: {
    currencyCode: string
    items: {
      item_name?: string
      item_id?: string | number
      price?: string | number
      item_brand?: string | { name: string }
      item_category?: string
      quantity?: number
      item_list_name?: string
      index?: number
      item_variant?: string
    }[]
  }
  navigation_type?: string
  navigation_element?: string
  menu_division?: string
  menu_sub_division?: string
  menu_department?: string
  coupon_code?: string
  col_type?: string
  col_number?: number
}

export interface LoginGA4 extends Pick<DataLayerGA4, 'event'> {
  method: string
  visitorLoginState: string
  user: {
    user_id: string
  }
}

export interface PromotionGA4 extends Pick<DataLayerGA4, 'event'> {
  ecommerce: {
    promotion_name: string
    promotion_id: string
  }
}

export interface EnhancedTrackingEvent {
  event: string
}

export interface SortByDataLayerGA4 extends Omit<DataLayerGA4, 'event_name'> {
  sort_method: string
}

export const pushToDataLayer = (event: TrackingEvent) => {
  if (!window) {
    return
  }
  if (!window.dataLayer) {
    window.dataLayer = []
  }

  window.dataLayer.push({
    event: event.type,
    eventData: {
      ...event.eventData,
      eventPage: window.location.pathname,
    },
  })
}

export const pushToEnhancedDataLayer = <T extends EnhancedTrackingEvent>(
  event: T,
) => {
  if (!window) {
    return
  }

  if (!window.dataLayer) {
    window.dataLayer = []
  }
  window.dataLayer.push(event)
}

export interface ColourDataLayerGA4 extends Omit<DataLayerGA4, 'event_name'> {
  colour: string
  item_id: string
  item_name: string
}
export interface ColumnTypeDataLayerGA4
  extends Omit<DataLayerGA4, 'event_name'> {
  col_type: string
  col_number: number
}

export interface NavigationDataGA4 extends Omit<DataLayerGA4, 'event_name'> {
  category?: string
  sub_category?: string
  department?: string
}

export interface VisitorSignUpDataLayerGA4
  extends Omit<DataLayerGA4, 'event_name'> {
  method: string
  visitorLoginState: string
  user: {
    user_id: string
  }
}

export interface MultipleEvents extends DataLayerGA4 {
  eventLabel?: string
  eventPage?: string
  promotion_name?: string
  creative_slot?: string
  promotion_id?: number | null
  item: {
    item_id: string
    item_external_id: string | null
    item_name: string
    item_brand: string
    item_currency: string
    index: string | null
    item_page: string | null
    price: string | null
    item_promotion: string
  }
}

export const pushToEventGA4 = <T = DataLayerGA4>(dataLayer: T) => {
  if (!window) {
    return
  }

  if (!window.dataLayer) {
    window.dataLayer = []
    window.dataLayer.push(dataLayer)
  } else {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(dataLayer)
  }
}

export const pushProductViewData = (
  productName,
  externalSku,
  productPrice,
  productBrand,
  inStockWarehouse,
  homeDeliveryAvailable,
  clickAndCollect,
  checkStock,
  noCheckStock,
  collectionType,
  category,
  range,
  variantExternalSku,
  rootCategories,
) => {
  const isInStockWarehouse = inStockWarehouse === 'true' ? 'in' : 'out'

  let hasHomeDeliveryAvailable = 'Not available for delivery'
  let isCheckStock = 'No check stock'
  let fulfilment = 'Not available'

  if (inStockWarehouse && homeDeliveryAvailable) {
    hasHomeDeliveryAvailable = 'In Stock'
  } else if (homeDeliveryAvailable === 'true' && inStockWarehouse === 'false') {
    hasHomeDeliveryAvailable = 'Out of stock'
  }

  if (clickAndCollect || checkStock) {
    isCheckStock = 'Can check stock'
  }

  if (clickAndCollect) {
    fulfilment = 'Available to order'
  } else if (checkStock) {
    fulfilment = ' Check stock'
  } else if (noCheckStock) {
    fulfilment = 'No check stock'
  }

  const product = {
    category,
    range,
    name: productName,
    item_id: externalSku,
    price: productPrice,
    brand: productBrand,
    dimension48: isInStockWarehouse,
    'Home Delivery': hasHomeDeliveryAvailable,
    'In-Store': fulfilment,
    dimension46: `${homeDeliveryAvailable}`,
    dimension45: collectionType,
    item_variant: variantExternalSku,
  }

  if (rootCategories) {
    product['root_category'] = rootCategories
  }

  if (inStockWarehouse && homeDeliveryAvailable) {
    product['Home deliverable'] = isCheckStock
  } else {
    product['Home deliverable'] = isCheckStock
  }

  return product
}

export const pushFulfilmentData = (
  inStockWarehouse,
  homeDeliveryAvailable,
  clickAndCollectAvailable,
  fulfilmentType,
) => {
  const isInStockWarehouse = inStockWarehouse ? 'in' : 'out'

  const isHomeDeliveryAvailable = String(homeDeliveryAvailable)

  const collectionType = clickAndCollectAvailable
    ? 'click_and_collect'
    : 'order_in_store'

  return {
    dimension3: fulfilmentType,
    dimension45: collectionType,
    dimension46: isHomeDeliveryAvailable,
    dimension48: isInStockWarehouse,
  }
}

export const productEventsCategory = (content, keys) => {
  if (!content) {
    return
  }

  const filteredContent = content.filter(
    (item) => item && keys.includes(item.key),
  )
  if (filteredContent?.length > 0) {
    return {
      root_category: filteredContent.reduce((acc, item) => {
        let processedValue

        if (item.value.stringListValue) {
          processedValue = item.value.stringListValue.reduce(
            (valueAcc, val, index) => {
              return {
                ...valueAcc,
                [`${item.key.replace('mat_', '')} ${index + 1}`]: val,
              }
            },
            {},
          )
        } else {
          processedValue = item.value.stringValue || ''
        }

        acc[item.key] = processedValue
        return acc
      }, {}),
    }
  }
  return
}
