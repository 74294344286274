import axios from 'axios'
import Cookies from 'js-cookie'

import { httpsAgent } from '@thg-commerce/enterprise-network/src/httpsAgent'

import { validate_message } from './validation'

interface Payload {
  [key: string]: any
}

export interface Message {
  type: string
  payload: Payload
  attributes?: {
    route?: string
    appname?: string
    appversion?: string
    brand?: string
    subsite?: string
  }
}

type Headers = {
  Cookie?: string
  'user-agent'?: string
  'x-customer-ip'?: string
}

let attempt = 1
const attemptMax = 5

export const transmit = (
  target_uri: string,
  message: Message,
  enableTransmitBeacons: boolean,
  options?: {
    appname: string
    appversion: string
    brand: string
    subsite: string
  },
  headers?: Headers,
) => {
  if (!validate_message(message)) {
    console.error(
      `🚨 %c Message does not match required format, expected message of type ${message.type}`,
      `background: #ED2939; color: white; padding: 2px 0px;`,
    )
    return
  }

  if (
    typeof window !== 'undefined' &&
    window.location.search.indexOf('debug') !== -1
  ) {
    console.info(
      `📡 %c Transmitting "${message.type}" event with payload:`,
      `background: #000000; color: white; padding: 4px 2px;`,
      message.payload,
    )
  }

  const body = { ...message }
  post(target_uri, body, enableTransmitBeacons, options, headers)
}

const transmitXHREvent = (
  target_uri: string,
  body: Message,
  isNodeEnv: boolean,
  headers?: Headers,
) => {
  axios
    .post(target_uri, body, {
      headers: {
        ...(isNodeEnv && { ...headers }),
      },
      withCredentials: true,
      ...(isNodeEnv &&
        target_uri.startsWith('https') && {
          httpsAgent,
        }),
    })
    .then((res) => {
      if (res.status[0] < 200 || res.status[0] > 299) {
        attempt < attemptMax && post(target_uri, body, false)
        attempt += 1
      }
      if (
        typeof window !== 'undefined' &&
        window.location.search.indexOf('debug') !== -1
      ) {
        console.info(
          `📡 %c Transmission of "${body.type}" event was successful with payload:`,
          `background: #000000; color: white; padding: 4px 2px;`,
          body.payload,
        )
      }
    })
    .catch((e) => {
      console.error(
        `🚨 %c ${e.message}`,
        `background: #ED2939; color: white; padding: 2px 0px;`,
      )
    })
}

const post = (
  target_uri: string,
  body: Message,
  enableTransmitBeacons: boolean,
  options?: {
    appname: string
    appversion: string
    brand: string
    subsite: string
  },
  headers?: Headers,
) => {
  const { v4: uuid } = require('uuid')
  const date = new Date()
  date.setHours(date.getHours() + 3)

  const isNodeEnv = typeof window === 'undefined'

  if (!Cookies.get('chumewe_sess') && !isNodeEnv) {
    Cookies.set('chumewe_sess', uuid(), {
      expires: date,
      secure: true,
      path: '/',
    })
  }

  if (!body.attributes) {
    body.attributes = {}
  }

  body.attributes!.appname = options?.appname
  body.attributes!.appversion = options?.appversion
  body.attributes!.brand = options?.brand
  body.attributes!.subsite = options?.subsite

  if (
    enableTransmitBeacons &&
    typeof navigator === 'object' &&
    typeof navigator.sendBeacon === 'function'
  ) {
    try {
      const beaconHeaders = {
        type: 'application/json',
      }
      const blob = new Blob([JSON.stringify(body)], beaconHeaders)
      navigator.sendBeacon(target_uri, blob)
    } catch (e) {
      console.warn(`Failed to send transmit beacon with error: ${e.message}`)
      transmitXHREvent(target_uri, body, isNodeEnv, headers)
    }
  } else {
    transmitXHREvent(target_uri, body, isNodeEnv, headers)
  }
}
