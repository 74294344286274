import * as React from 'react'
export const SvgAccountDetails = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={65} height={64} {...props}>
    <g fill="#333" fillRule="evenodd">
      <path d="M32.5 39.143c14.202 0 25.715 8.954 25.715 20l-2.858.001C55.357 49.675 45.124 42 32.5 42 19.877 42 9.643 49.675 9.643 59.143H6.786c0-11.046 11.513-20 25.714-20z" />
      <path
        fillRule="nonzero"
        d="M33.215 33.429c6.706 0 12.142-5.437 12.142-12.143S39.921 9.143 33.216 9.143c-6.707 0-12.143 5.437-12.143 12.143s5.436 12.143 12.143 12.143zm0 2.857c-8.285 0-15-6.716-15-15 0-8.284 6.715-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15z"
      />
    </g>
  </svg>
)
export default SvgAccountDetails
