import * as React from 'react'
export const SvgArvato = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={40} viewBox="0 0 60 41" {...props}>
    <g fill="none">
      <rect
        width={59}
        height={39}
        x={0.5}
        fill="#FFF"
        stroke="#DDD"
        rx={4}
        transform="translate(0 1)"
      />
      <path
        fill="#78A22F"
        d="M8.61 15.058a3.231 3.231 0 00-1.211 0C5.942 15.33 5 16.428 5 17.846v4.508c-.008.14.02.28.08.407a.486.486 0 00.423.239c.102 0 .201-.03.285-.084.178-.114.257-.293.257-.583v-1.85h3.908v1.87a.786.786 0 00.1.407.48.48 0 00.424.24.512.512 0 00.285-.084c.206-.132.237-.355.237-.583v-4.49c0-1.417-.94-2.51-2.39-2.785zM6.053 19.55v-1.694c0-.726.27-1.237.857-1.604a1.997 1.997 0 011.095-.315 2.3 2.3 0 01.394.035c.25.045.487.14.695.281.579.363.86.89.86 1.603v1.694h-3.9zm15.43 1.357a.486.486 0 00-.25.07c-.165.082-.267.246-.31.5l-.047.26-.009.072c-.157.7-.678 1.14-1.362 1.14-.084 0-.168-.005-.251-.017-.827-.134-1.212-.715-1.212-1.827v-.14h1.574c.194 0 .692 0 .692-.52a.469.469 0 00-.41-.485 2.692 2.692 0 00-.325-.013h-1.531v-2.235a.987.987 0 00-.08-.449.482.482 0 00-.432-.263.501.501 0 00-.29.092c-.21.146-.24.395-.24.644v3.244c0 1.818.637 2.735 2.06 2.974.165.03.333.045.501.046 1.107.017 2.081-.753 2.355-1.861.022-.086.038-.174.048-.263.058-.332.047-.52-.04-.689a.49.49 0 00-.44-.28zM25.521 17c-.303 0-.604.039-.897.115-.937.219-1.74.84-2.204 1.707a3.565 3.565 0 00-.229 2.83c.444 1.404 1.746 2.348 3.24 2.348a3.536 3.536 0 002.006-.608c.231-.154.441-.337.625-.547.186-.224.294-.403.274-.612a.495.495 0 00-.587-.442h-.016a.784.784 0 00-.336.192l-.01.01-.087.112-.15.145a2.315 2.315 0 01-1.157.665c-.18.042-.364.063-.549.064-.979.003-1.855-.626-2.19-1.573a4.047 4.047 0 01-.093-.327h5.096l.086-.012.173-.011a.478.478 0 00.382-.202c.15-.222.135-.58-.054-1.274-.415-1.534-1.777-2.591-3.323-2.58zm-2.387 3.114c.02-.123.049-.245.086-.364.298-1.03 1.22-1.735 2.265-1.728.235 0 .47.033.696.1a2.419 2.419 0 011.639 1.771c.02.074.038.149.052.225l-4.738-.004z"
      />
      <path
        fill="#78A22F"
        d="M33.873 18.61l-.022-.058C33.464 17.58 32.584 17 31.487 17h-.028a2.828 2.828 0 00-.533.046c-.878.177-1.926.759-1.926 2.51v2.815a.73.73 0 00.104.396c.085.144.25.234.43.233a.544.544 0 00.29-.082c.202-.123.217-.32.217-.567v-2.794c0-.951.346-1.439 1.124-1.583.104-.02.21-.032.317-.032.405-.003.79.159 1.05.442.135.139.234.303.291.48.112.491.173.642.242.736l.009.01.01.011a.596.596 0 00.65.102c.324-.16.29-.551.218-.882a1.957 1.957 0 00-.08-.23z"
      />
      <path
        fill="#685546"
        d="M38.985 15h-.037C37.185 15.023 36 16.168 36 17.852v4.503a.76.76 0 00.107.407c.09.147.263.238.45.238a.57.57 0 00.304-.084c.23-.139.262-.372.262-.582v-2.085h1.784c1.619 0 2.731-.756 3.028-2.075.185-.771-.03-1.576-.584-2.189-.58-.633-1.451-.995-2.366-.985zm1.874 2.651c-.02.98-.83 1.656-1.968 1.656h-1.764v-1.549c0-.68.117-1.006.48-1.34a1.922 1.922 0 011.348-.487H39c1.104.02 1.87.728 1.859 1.72zm8.005 1.76C48.458 17.993 47.1 17 45.564 17c-.36 0-.718.053-1.064.157a3.453 3.453 0 00-2.422 2.611c-.383 1.882.67 3.645 2.452 4.103.322.085.654.128.987.129.98 0 1.867-.392 2.416-1.027v.133c0 .211 0 .327.062.451.088.164.26.266.446.264a.527.527 0 00.3-.093c.229-.154.259-.412.259-.645v-2.795l-.011-.116a3.258 3.258 0 00-.125-.761zm-2.783 3.491a2.701 2.701 0 01-.601.07c-1.103 0-2.005-.704-2.306-1.8a2.113 2.113 0 01-.093-.509 2.438 2.438 0 011.686-2.51c.252-.08.514-.12.778-.12 1.31 0 2.337 1.018 2.388 2.37.053 1.229-.691 2.233-1.852 2.5zm7.817-5.662a.487.487 0 00-.426-.24.528.528 0 00-.289.084c-.21.133-.228.366-.228.588v2.891c0 1.006-.546 1.632-1.426 1.632-.086 0-.172-.006-.257-.018-.797-.103-1.234-.64-1.234-1.52v-3.006a.83.83 0 00-.07-.41.487.487 0 00-.429-.241.521.521 0 00-.29.085c-.22.139-.249.375-.249.587v2.996c0 1.18.567 2.016 1.597 2.352.275.092.565.139.857.14a2.425 2.425 0 001.496-.49.695.695 0 01-.015.103v.016c-.096.652-.45 1.044-1.079 1.199-.133.036-.27.055-.409.055a1.27 1.27 0 01-.537-.113 1.22 1.22 0 01-.482-.36l-.158-.194-.096-.105a1.188 1.188 0 00-.145-.148l-.03-.029-.024-.014a.503.503 0 00-.246-.063.507.507 0 00-.504.392c-.038.213.056.399.235.654.138.18.3.34.482.48.426.303.946.464 1.48.457.243-.002.485-.033.72-.092C53.34 24.616 54 23.705 54 22.343V17.65a.805.805 0 00-.102-.41z"
      />
      <path
        fill="#78A22F"
        d="M16.872 18.61l-.022-.058C16.463 17.58 15.584 17 14.488 17h-.028a2.795 2.795 0 00-.533.046c-.879.177-1.927.759-1.927 2.51v2.815a.74.74 0 00.104.396c.085.145.25.234.43.233a.538.538 0 00.29-.082c.212-.13.23-.349.23-.567l.01-.666v-1.213h1.659c.5 0 .621-.233.635-.429a.395.395 0 00-.12-.324c-.108-.105-.277-.156-.515-.156h-1.68v-.006c0-.951.345-1.439 1.123-1.583.104-.02.21-.032.317-.032.405-.003.79.16 1.05.442.134.139.233.303.29.48.112.492.173.642.241.735l.009.012.01.011a.557.557 0 00.398.156.6.6 0 00.252-.054c.326-.161.29-.552.219-.883a2.18 2.18 0 00-.08-.23z"
      />
      <path
        fill="#685546"
        d="M55.708 16.706a1 1 0 11-1.416-1.412 1 1 0 011.416 1.412zm-1.314-1.315a.83.83 0 00-.25.608.856.856 0 101.647-.325.863.863 0 00-.79-.533.826.826 0 00-.607.25zm.588.057a.845.845 0 01.298.038c.111.045.18.157.17.277a.24.24 0 01-.108.217.413.413 0 01-.158.053.271.271 0 01.188.107c.036.05.057.108.059.17v.162a.208.208 0 00.01.057l.005.013h-.18a.04.04 0 000-.012.107.107 0 000-.011v-.122a.268.268 0 00-.105-.252.486.486 0 00-.215-.034h-.152v.432h-.193v-1.095h.38zm.208.172a.494.494 0 00-.23-.041h-.166v.397h.175a.502.502 0 00.184-.025.194.194 0 00.04-.331h-.003z"
      />
    </g>
  </svg>
)
export default SvgArvato
