import * as React from 'react'

import { BasketIcon as Basket } from '@thg-commerce/gravity-icons/src'
import { CombinedThemeInterface } from '@thg-commerce/gravity-patterns'
import { styled, withTheme } from '@thg-commerce/gravity-patterns/theme'
import { css, mq, Text } from '@thg-commerce/gravity-theme'

export interface BasketIconProps {
  totalQuantity?: number
  altText?: string
  theme: CombinedThemeInterface
}

const IconSvg = styled.svg`
  width: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.mobileIcon.width};
  height: ${(props) =>
    props.theme.patterns.header.dropdowns.basket.mobileIcon.height};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: ${(props) =>
      props.theme.patterns.header.dropdowns.basket.icon.width};
    height: ${(props) =>
      props.theme.patterns.header.dropdowns.basket.icon.height};
  }
`

const NumberCircle = styled.div`
  ${(props) =>
    Text(
      props.theme.patterns.header.dropdowns.basket.quantityStyling.mobileText
        .entry,
      props.theme.patterns.header.dropdowns.basket.quantityStyling.mobileText
        .style,
    )};

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 100%;

  ${(props) => {
    const quantityStyling =
      props.theme.patterns.header.dropdowns.basket.quantityStyling

    return css`
      height: ${quantityStyling.mobileHeight};
      background-color: ${quantityStyling.backgroundColor};
      color: ${quantityStyling.text.textColor};
      text-decoration: ${quantityStyling.text.textDecoration};
      text-transform: ${quantityStyling.text.transform};
      min-width: ${quantityStyling.mobileWidth};
      top: ${quantityStyling.mobilePosition.top};
      left: ${quantityStyling.mobilePosition.left};
      right: ${quantityStyling.mobilePosition.right};
      color: ${quantityStyling.text.textColor};
      height: ${quantityStyling.mobileHeight};
      width: ${quantityStyling.mobileWidth};
    `
  }}

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) =>
      Text(
        props.theme.patterns.header.dropdowns.basket.quantityStyling.text.entry,
        props.theme.patterns.header.dropdowns.basket.quantityStyling.text.style,
      )}

    ${(props) => {
      const quantityStyling =
        props.theme.patterns.header.dropdowns.basket.quantityStyling

      return css`
        top: ${quantityStyling.position.top};
        right: ${quantityStyling.position.right};
        left: ${quantityStyling.position.left};
        color: ${quantityStyling.text.textColor};
        height: ${quantityStyling.height};
        width: ${quantityStyling.width};
      `
    }}

    &:hover {
      ${(props) =>
        Text(
          props.theme.patterns.header.dropdowns.basket.quantityStyling.hoverText
            .entry,
          props.theme.patterns.header.dropdowns.basket.quantityStyling.hoverText
            .style,
        )};

      ${(props) => {
        const quantityStyling =
          props.theme.patterns.header.dropdowns.basket.quantityStyling

        return css`
          background-color: ${quantityStyling.hoverBackgroundColor};
          color: ${quantityStyling.hoverText.textColor};
          text-decoration: ${quantityStyling.hoverText.textDecoration};
          text-transform: ${quantityStyling.hoverText.transform};
        `
      }}
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  position: relative;
`

export const BasketIcon = withTheme((props: BasketIconProps) => {
  const totalBasketQuantity =
    props.totalQuantity && props.totalQuantity ? props.totalQuantity : 0

  return (
    <IconContainer>
      {props.theme.patterns.header.dropdowns.basket.icon.svgPath !== '' ? (
        <IconSvg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox={props.theme.patterns.header.dropdowns.basket.icon.viewBox}
        >
          <path
            d={props.theme.patterns.header.dropdowns.basket.icon.svgPath}
            fill-rule="evenodd"
          ></path>
        </IconSvg>
      ) : (
        <Basket />
      )}
      <NumberCircle>{totalBasketQuantity}</NumberCircle>
    </IconContainer>
  )
})
