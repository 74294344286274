import * as React from 'react'
import { vsprintf } from 'sprintf-js'

import { styled } from '@thg-commerce/gravity-patterns/theme'
import { spacing, Text } from '@thg-commerce/gravity-theme'

const SubNavigationLinkWrapper = styled.li<{
  dynamicWidths: boolean
  totalCards: number
  mobile: boolean
}>`
  width: ${(props) =>
    props.mobile
      ? `100%`
      : !props.dynamicWidths
      ? `calc(100% / 6)`
      : props.totalCards >= 6
      ? `calc(100% / 6)`
      : `calc(100% / ${props.totalCards})`};
  ${(props) =>
    props.mobile
      ? `margin: ${spacing(1)} 0`
      : `margin: 0; padding: ${spacing(2)} ${spacing(1)}`}
`

const StyledCard = styled.a`
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.dropdownHeaderFont.entry,
      props.theme.patterns.header.navigation.dropdownHeaderFont.style,
    )};
  color: ${(props) =>
    props.theme.patterns.header.navigation.dropdownHeaderFont.textColor};
  display: flex;
  flex-direction: column;
  outline: none;
  text-decoration: none;
  border: 2px solid transparent;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }

  &:focus-within {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
  /* DO NOT GROUP WITH ABOVE BREAKS POLYFILL FOR MSOFT BROWSERS */
  &.focus-within {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

const StyledLinkText = styled.div`
  padding: calc(${spacing(2)} - 2px);
  background: ${(props) => props.theme.colors.palette.greys.white};
`

const SubNavCard = (subNavArgs: {
  subNav: any
  colIndex: number
  topLevelName: string
  focusHandler: Function
  dynamicWidths: boolean
  totalCards: number
  mobile: boolean
  homeCard: boolean
  i18nHomeText?: string
}) => {
  return (
    <SubNavigationLinkWrapper
      key={`text-card-sub-nav-${
        !subNavArgs.mobile ? subNavArgs.colIndex : subNavArgs.colIndex + 1
      }`}
      dynamicWidths={subNavArgs.dynamicWidths}
      totalCards={subNavArgs.totalCards}
      mobile={subNavArgs.mobile}
    >
      <StyledCard
        href={subNavArgs.subNav.link?.url}
        data-nav-name={`${subNavArgs.topLevelName}`}
        data-nav-index={`${
          !subNavArgs.mobile ? subNavArgs.colIndex : subNavArgs.colIndex + 1
        },${0}`}
        onFocus={() => {
          subNavArgs.focusHandler(subNavArgs.colIndex, 0)
        }}
      >
        <StyledLinkText>
          {!subNavArgs.homeCard
            ? subNavArgs.subNav.displayName
            : subNavArgs.i18nHomeText &&
              vsprintf(subNavArgs.i18nHomeText, [
                subNavArgs.subNav.displayName,
              ])}
        </StyledLinkText>
      </StyledCard>
    </SubNavigationLinkWrapper>
  )
}

const NavContent = styled.ul<{ mobile?: boolean }>`
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${spacing(2)} ${spacing(2)};
  background: ${(props) => props.theme.colors.palette.greys.lighter};
  ${(props) => (props.mobile ? `margin: -${spacing(1)} 0` : ``)};
`

export const CardNavigationContent = ({
  item,
  dynamicWidths,
  mobile,
  i18nHomeText,
}) => {
  const [activeNavIndex, setActiveNavIndex] = React.useState([-1, -1])

  const onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault()

        const leftElement = document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] - 1
          },${activeNavIndex[1]}"]`,
        ) as HTMLElement
        if (leftElement) {
          leftElement.focus()
        } else if (
          document.querySelector(
            `[data-nav-name="${item.displayName}"][data-nav-index="${
              activeNavIndex[0] - 1
            },0"]`,
          ) as HTMLElement
        ) {
          let closestEl
          let index = activeNavIndex[1] - 1
          while (!closestEl) {
            closestEl = document.querySelector(
              `[data-nav-name="${item.displayName}"][data-nav-index="${
                activeNavIndex[0] - 1
              },${index}"]`,
            ) as HTMLElement
            index -= 1
          }
          closestEl.focus()
        }
        break
      case 'ArrowRight':
        event.preventDefault()

        const rightElement = document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] + 1
          },${activeNavIndex[1]}"]`,
        ) as HTMLElement
        if (rightElement) {
          rightElement.focus()
        } else if (
          document.querySelector(
            `[data-nav-name="${item.displayName}"][data-nav-index="${
              activeNavIndex[0] + 1
            },0"]`,
          ) as HTMLElement
        ) {
          let closestEl
          let index = activeNavIndex[1] - 1
          while (!closestEl) {
            closestEl = document.querySelector(
              `[data-nav-name="${item.displayName}"][data-nav-index="${
                activeNavIndex[0] + 1
              },${index}"]`,
            ) as HTMLElement
            index -= 1
          }
          closestEl.focus()
        }
        break
    }
  }
  const focusHandler = (colIndex, rowIndex) => {
    setActiveNavIndex([colIndex, rowIndex])
  }

  const navCards = item.subNavigation.map((subNav, index) =>
    SubNavCard({
      subNav,
      focusHandler,
      dynamicWidths,
      mobile,
      i18nHomeText,
      colIndex: index,
      topLevelName: item.displayName,
      totalCards: item.subNavigation.length,
      homeCard: false,
    }),
  )

  return (
    <NavContent mobile={mobile} onKeyDown={!mobile ? onKeyDown : () => {}}>
      {mobile &&
        SubNavCard({
          dynamicWidths,
          i18nHomeText,
          subNav: item,
          colIndex: 0,
          topLevelName: item.displayName,
          focusHandler: () => {},
          totalCards: 0,
          mobile: true,
          homeCard: true,
        })}
      {navCards}
    </NavContent>
  )
}
