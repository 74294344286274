import * as React from 'react'
export const SvgTime = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <g strokeWidth={2} stroke="#333" fill="none" fillRule="evenodd">
      <circle cx={20} cy={20} r={9} />
      <path d="M20 14.167v6.505a.1.1 0 00.145.089L25 18.333" />
    </g>
  </svg>
)
export default SvgTime
