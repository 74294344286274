import { Property } from 'csstype'

interface ButtonStyleInterface {
  borderColor: Property.Color
  backgroundColor: Property.Color
  color: Property.Color
}

export interface StepperThemeInterface {
  item: {
    active: ButtonStyleInterface
    inactive: ButtonStyleInterface
  }
  divider: {
    active: {
      color: Property.Color
    }
    inactive: {
      color: Property.Color
    }
  }
}

export const theme: StepperThemeInterface = {
  item: {
    active: {
      borderColor: '#CCCCCC',
      backgroundColor: '#000000',
      color: '#CCCCCC',
    },
    inactive: {
      borderColor: '#000000',
      backgroundColor: '#FFFFFF',
      color: '#FFFFFF',
    },
  },
  divider: {
    active: {
      color: '#000000',
    },
    inactive: {
      color: '#CCCCCC',
    },
  },
}
