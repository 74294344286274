import * as React from 'react'
import { styled, css } from '@thg-commerce/gravity-theme'

interface VisuallyHiddenProps {
  text: string
}

export const visuallyHiddenMixin = css`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
`

const StyledSpan = styled.span`
  ${visuallyHiddenMixin}
`

export const VisuallyHidden = ({ text }: VisuallyHiddenProps) => (
  <StyledSpan>{text}</StyledSpan>
)
