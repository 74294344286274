import * as React from 'react'

import { v4 as uuid } from 'uuid'

import { styled } from '@thg-commerce/gravity-theme'

const PlaceholderElement = styled.div`
  display: block;
  height: 100px;
  position: relative;
  overflow: hidden;
  background-color: #eff1f3;
  border-radius: 4px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      to right,
      #eff1f3 4%,
      #e2e2e2 25%,
      #eff1f3 36%
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`

export const Skeleton = ({ className }: { className?: string }) => {
  return <PlaceholderElement className={className} />
}

const ANIMATION_KEY_TIMES = '0; 0.25; 1'
const ANIMATION_SPEED = '2s'

const SkeletonSvg = styled.svg`
  width: 100%;
`

export const NewSkeleton = (props: {
  children: any
  className?: string
  style?: React.CSSProperties
}) => {
  const id = uuid()
  const clipId = `${id}-clip`
  const gradientId = `${id}-gradient`

  return (
    <SkeletonSvg className={props.className} style={props.style}>
      <rect
        role="presentation"
        x="0"
        y="0"
        width="100%"
        height="100%"
        clipPath={`url(#${clipId})`}
        style={{ fill: `url(#${gradientId})` }}
      />
      <defs>
        <clipPath id={clipId}>{props.children}</clipPath>

        <linearGradient id={gradientId}>
          <stop offset="0%" stopColor="#eff1f3" stopOpacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes={ANIMATION_KEY_TIMES}
              dur={ANIMATION_SPEED}
              repeatCount="indefinite"
            />
          </stop>

          <stop offset="50%" stopColor="#e2e2e2" stopOpacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes={ANIMATION_KEY_TIMES}
              dur={ANIMATION_SPEED}
              repeatCount="indefinite"
            />
          </stop>

          <stop offset="100%" stopColor="#eff1f3" stopOpacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes={ANIMATION_KEY_TIMES}
              dur={ANIMATION_SPEED}
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </SkeletonSvg>
  )
}
