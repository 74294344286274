import * as React from 'react'

import { css, styled, useTheme } from '@thg-commerce/gravity-patterns/theme'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'
import { margin, padding, spacing, Text } from '@thg-commerce/gravity-theme'

import { NavigationItem, NavigationItemType } from '../../NavigationTree/index'
import { NavigationEventData } from '../Navigation'

import { ImageCard } from './ImageCardNavigationContent'
import {
  StyledCard,
  StyledImg,
  StyledLinkText,
  SubNavigationLinkWrapper,
} from './ImageThumbnailContent'

const NavigationList = styled.ul`
  list-style-type: none;
  display: ${(props) => props.theme.patterns.header.navigation.subMenu.display};
  flex-direction: ${(props) =>
    props.theme.patterns.header.navigation.subMenu.flexDirection};
  flex-wrap: ${(props) =>
    props.theme.patterns.header.navigation.subMenu.flexWrap};
  column-gap: ${spacing(2)};

  ${(props) =>
    props.theme.patterns.header.navigation.subMenu?.gridAutoFlow &&
    `grid-auto-flow: ${props.theme.patterns.header.navigation.subMenu.gridAutoFlow};`}

  ${(props) =>
    props.theme.patterns.header.navigation.subMenu?.gridNumberOfRows &&
    `grid-template-rows: repeat(${props.theme.patterns.header.navigation.subMenu.gridNumberOfRows}, 1fr);`}

    ${(props) =>
    props.theme.patterns.header.navigation.subMenu?.column?.list.height &&
    `height: ${props.theme.patterns.header.navigation.subMenu?.column.list.height};`}
`

const headerHoverStyles = css`
  ${(props) =>
    props.theme.patterns.header.navigation.dropdownHeaderHover
      .hoverBackgroundColor === '#FFFFFF' && 'text-decoration: underline;'}
  outline: none;
  color: ${(props) =>
    props.theme.patterns.header.navigation.dropdownHeaderHover.hoverLinkFont
      .textColor};
`

const StyledHeaderLink = styled.a<{ promotion?: boolean }>`
  background: transparent;
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.dropdownHeaderFont.entry,
      props.theme.patterns.header.navigation.dropdownHeaderFont.style,
    )};
  outline: none;
  text-decoration: ${(props) =>
    props.theme.patterns.header.navigation.dropdownHeaderFont.textDecoration};
  color: ${(props) =>
    props.promotion
      ? props.theme.patterns.header.navigation.subMenu.promotion?.textColor ||
        props.theme.colors.system.pap.sale
      : props.theme.patterns.header.navigation.dropdownHeaderFont.textColor};
  text-transform: ${(props) =>
    props.theme.patterns.header.navigation.dropdownHeaderFont.transform};

  &:hover {
    ${headerHoverStyles}
  }
`

const StyledHeader = styled.div<{ addBorderBottom: boolean }>`
  background: transparent;
  border: 2px solid transparent;
  ${(props) =>
    margin(
      props.theme.patterns.header.navigation.subMenu?.heading?.margin ?? {
        top: 1,
        left: -1,
        right: -1,
        bottom: 1,
      },
    )};

  ${(props) =>
    padding(
      props.theme.patterns.header.navigation.subMenu?.heading?.padding ?? {
        top: 1,
        right: 0.75,
        bottom: 1,
        left: 0.75,
      },
    )};

  ${(props) =>
    (props.theme.patterns.header.navigation.subMenu.border?.show ??
      props.addBorderBottom) &&
    `
    border-top: none;
    border-bottom: 1px solid ${props.theme.colors.palette.greys.light};
    `}

  &:focus-within {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }

  &:hover {
    ${(props) =>
      `background: ${props.theme.patterns.header.navigation.dropdownHeaderHover.hoverBackgroundColor};`}
  }

  &:hover ${StyledHeaderLink} {
    ${headerHoverStyles}
  }

  /* DO NOT GROUP WITH ABOVE BREAKS POLYFILL FOR MSOFT BROWSERS */
  &.focus-within {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
  &:hover {
    ${(props) =>
      `background: ${props.theme.patterns.header.navigation.dropdownHeaderHover.hoverBackgroundColor};`}
  }

  &:hover ${StyledHeaderLink} {
    ${headerHoverStyles}
  }
`

const StyledNavigationHeading = styled.p`
  background: transparent;
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.dropdownHeaderFont.entry,
      props.theme.patterns.header.navigation.dropdownHeaderFont.style,
    )};
  outline: none;
  text-decoration: ${(props) =>
    props.theme.patterns.header.navigation.dropdownHeaderFont.textDecoration};
  color: ${(props) =>
    props.theme.patterns.header.navigation.dropdownHeaderFont.textColor};
  text-transform: ${(props) =>
    props.theme.patterns.header.navigation.dropdownHeaderFont.transform};
`

const LinkWrapper = styled.li`
  display: flex;
  align-items: center;
  gap: ${spacing(1)};
  ${(props) =>
    margin(
      props.theme.patterns.header.navigation.link.wrapper?.margin ?? {
        top: 0,
        left: -1,
        right: -1,
        bottom: 0,
      },
    )};

  border: 2px solid transparent;

  &:focus-within {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }

  /* DO NOT GROUP WITH ABOVE BREAKS POLYFILL FOR MSOFT BROWSERS */
  &.focus-within {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

const SubNavigationLinkPromotion = (props) =>
  props.promotion &&
  css`
    color: ${props.theme.patterns.header.navigation.subMenu.promotion
      ?.textColor || props.theme.colors.system.pap.sale};
  `

const SubNavigationLink = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.linkFont.entry,
      props.theme.patterns.header.navigation.linkFont.style,
    )};
  color: ${(props) =>
    props.theme.patterns.header.navigation.linkFont.textColor};
  outline: none;
  text-decoration: ${(props) =>
    props.theme.patterns.header.navigation.linkFont.textDecoration};
  text-transform: ${(props) =>
    props.theme.patterns.header.navigation.linkFont.transform};
  ${(props) =>
    props.theme.patterns.header.navigation.link.subNavigation?.padding
      ? padding(
          props.theme.patterns.header.navigation.link.subNavigation?.padding,
        )
      : `padding: calc(${spacing(0.5)} - 2px) calc(${spacing(1)} - 2px);`};

  &:hover {
    ${(props) =>
      props.theme.patterns.header.navigation.linkHover.hoverBackgroundColor ===
        '#FFFFFF' && 'text-decoration: underline;'}
    ${(props) =>
      Text(
        props.theme.patterns.header.navigation.linkHover.hoverLinkFont.entry,
        props.theme.patterns.header.navigation.linkHover.hoverLinkFont.style,
      )};
    color: ${(props) =>
      props.theme.patterns.header.navigation.linkHover.hoverLinkFont.textColor};
    text-decoration: ${(props) =>
      props.theme.patterns.header.navigation.linkHover.hoverLinkFont
        .textDecoration};
    text-transform: ${(props) =>
      props.theme.patterns.header.navigation.linkHover.hoverLinkFont.transform};

    ${(props) =>
      props.theme.patterns.header.navigation.linkHover.hoverBackgroundColor !==
        '#FFFFFF' &&
      `background: ${props.theme.patterns.header.navigation.linkHover.hoverBackgroundColor};`}
    color: ${(props) =>
      props.theme.patterns.header.navigation.linkHover.hoverLinkFont.textColor};
  }

  &:active {
    ${(props) =>
      Text(
        props.theme.patterns.header.navigation.linkFont.entry,
        props.theme.patterns.header.navigation.linkFont.style,
      )};
  }

  ${SubNavigationLinkPromotion}
`

const SubNavigationColumnWrapper = styled.div<{
  columnWidth: string
  gridSpan?: number
}>`
  ${(props) =>
    props.theme.patterns.header.navigation.subMenu?.column?.height &&
    `height: ${props.theme.patterns.header.navigation.subMenu?.column.height};`}
  flex-basis: calc(${(props) => props.columnWidth});
  grid-row-end: span ${(props) => props.gridSpan || 1};
`

const SubNavColumnInnerContainer = styled.div<{
  addBorderTop: boolean
  isImageCard?: boolean
}>`
  ${(props) =>
    (props.theme.patterns.header.navigation.subMenu.border?.show ??
      props.addBorderTop) &&
    `border-top: 1px solid ${props.theme.colors.palette.greys.light};`}
  ${(props) =>
    props.isImageCard
      ? `margin: ${spacing(2)} 0 0 ${spacing(1.5)}`
      : margin(
          props.theme.patterns.header.navigation.subMenu.columnMargin ?? {
            top: `${spacing(1)}`,
            left: `${spacing(2)}`,
            right: `${spacing(2)}`,
            bottom: `${spacing(2)}`,
          },
        )};
`

const SubNavColumnFeaturedInnerContainer = styled.div`
  padding-top: ${spacing(2)};
`

const ColumnsFlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: calc(5 * (100% / 6));
`

const PrefetchableHeaderLink = withPrefetch('href', StyledHeaderLink)

const SubnavigationLinks = (props: {
  subNavsSubNavs: NavigationItem[]
  colIndex: number
  topLevelName: string
  focusHandler: (colIndex: number, rowIndex: number) => void
  parentType: NavigationItemType
  onClick?: (value: string) => void
  navLevelThreePromotionList?: string[]
  navigationOnClickCallback?: (data: NavigationEventData) => void
  navigationTopLevel?: string
  subNavTopLevel?: string
  enableThreeTierNav?: boolean
}) => {
  const {
    subNavsSubNavs,
    colIndex,
    topLevelName,
    focusHandler,
    parentType,
    onClick,
    navLevelThreePromotionList,
    navigationOnClickCallback,
    navigationTopLevel,
    subNavTopLevel,
    enableThreeTierNav,
  } = props

  return subNavsSubNavs.map((el, index) => {
    if (el.link) {
      return (
        <LinkWrapper
          key={`subnavigation-link-${index}`}
          data-testid="subnavigation-link"
        >
          <SubNavigationLink
            href={el.link.url}
            data-nav-name={`${topLevelName}`}
            data-nav-index={`${colIndex},${index + 1}`}
            onFocus={() => {
              focusHandler(colIndex, index + 1)
            }}
            promotion={(
              navLevelThreePromotionList?.map((i) => i.toLowerCase()) ?? []
            ).includes(`${topLevelName}/${el.displayName}`.toLowerCase())}
            onClick={() => {
              if (onClick) {
                onClick(el.displayName)
              }
              navigationOnClickCallback &&
                !enableThreeTierNav &&
                navigationOnClickCallback({
                  selectedTopLevelNavigation: navigationTopLevel,
                  selectedSecondLevelNavigation: subNavTopLevel || '',
                  selectedThirdLevelNavigation: el.displayName || '',
                })
            }}
          >
            {el.displayName}
          </SubNavigationLink>
        </LinkWrapper>
      )
    }
    return null
  })
}

const getNumberOfGridColumns = (
  subNav: NavigationItem,
  gridNumberOfRows?: number,
) => {
  const numberOfGridColumns =
    subNav?.subNavigation?.length &&
    gridNumberOfRows &&
    subNav?.subNavigation?.length > gridNumberOfRows
      ? Math.ceil(subNav?.subNavigation?.length / gridNumberOfRows)
      : 1
  return numberOfGridColumns
}

const SubNavigationColumn = (props: {
  subNav: NavigationItem
  colIndex: number
  topLevelName: string
  focusHandler: (arg1: number, arg2: number) => void
  featuredColumn: boolean
  enableThreeTierNav: boolean
  dynamicWidthSize?: string
  onClick?: (value?: string) => void
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  navigationOnClickCallback?: (data: NavigationEventData) => void
  navigationTopLevel?: string
}) => {
  const {
    subNav,
    colIndex,
    topLevelName,
    focusHandler,
    featuredColumn,
    enableThreeTierNav,
    dynamicWidthSize,
    onClick,
    navSubLevelPromotionList,
    navLevelThreePromotionList,
    navigationOnClickCallback,
    navigationTopLevel,
  } = props

  const theme = useTheme()
  const gridSpan = Math.max(subNav?.subNavigation?.length || 1)

  if (subNav.type !== NavigationItemType.FEATURED) {
    const width = `${
      featuredColumn ? '(100% / 5)' : dynamicWidthSize || '(100% / 6)'
    } * ${getNumberOfGridColumns(
      subNav,
      theme.patterns.header.navigation.subMenu.gridNumberOfRows,
    )}`

    return (
      <React.Fragment>
        {subNav.type === NavigationItemType.IMAGE_CARD ? (
          subNav.subNavigation?.map((item) => {
            return (
              <SubNavigationColumnWrapper
                key={`sub-nav-column-${colIndex}`}
                data-testid="sub-nav-column"
                columnWidth={width}
              >
                <SubNavColumnInnerContainer
                  addBorderTop={!enableThreeTierNav}
                  isImageCard={subNav.type === NavigationItemType.IMAGE_CARD}
                >
                  {ImageCard({
                    focusHandler,
                    colIndex,
                    subNav: item,
                    dynamicWidths: true,
                    mobile: false,
                    i18nHomeText: '',
                    topLevelName: subNav.displayName,
                    totalCards: 1,
                    homeCard: false,
                  })}
                </SubNavColumnInnerContainer>
              </SubNavigationColumnWrapper>
            )
          })
        ) : (
          <SubNavigationColumnWrapper
            key={`sub-nav-column-${colIndex}`}
            data-testid="sub-nav-column"
            columnWidth={width}
            gridSpan={gridSpan}
          >
            <SubNavColumnInnerContainer addBorderTop={!enableThreeTierNav}>
              <StyledHeader addBorderBottom={enableThreeTierNav}>
                {subNav.link?.url ? (
                  <PrefetchableHeaderLink
                    href={subNav.link?.url}
                    data-nav-name={`${topLevelName}`}
                    data-nav-index={`${colIndex},${0}`}
                    onFocus={() => {
                      focusHandler(colIndex, 0)
                    }}
                    onClick={() => {
                      if (onClick) {
                        onClick(subNav.displayName)
                      }
                      navigationOnClickCallback &&
                        !enableThreeTierNav &&
                        navigationOnClickCallback({
                          selectedTopLevelNavigation: navigationTopLevel,
                          selectedSecondLevelNavigation: subNav?.displayName,
                        })
                    }}
                    promotion={(navSubLevelPromotionList ?? []).includes(
                      subNav.link?.url,
                    )}
                  >
                    {subNav.displayName}
                  </PrefetchableHeaderLink>
                ) : (
                  <StyledNavigationHeading
                    data-nav-name={`${topLevelName}`}
                    data-nav-index={`${colIndex},${0}`}
                  >
                    {subNav.displayName}
                  </StyledNavigationHeading>
                )}
              </StyledHeader>
              {subNav.subNavigation && (
                <NavigationList
                  aria-labelledby={subNav.id}
                  data-testid={`nav-list-subnav-${subNav.displayName}`}
                >
                  <SubnavigationLinks
                    subNavsSubNavs={subNav.subNavigation}
                    colIndex={colIndex}
                    topLevelName={topLevelName}
                    focusHandler={focusHandler}
                    parentType={subNav.type}
                    onClick={onClick}
                    navLevelThreePromotionList={navLevelThreePromotionList}
                    navigationOnClickCallback={navigationOnClickCallback}
                    navigationTopLevel={navigationTopLevel}
                    subNavTopLevel={subNav.displayName}
                    enableThreeTierNav={enableThreeTierNav}
                  />
                </NavigationList>
              )}
            </SubNavColumnInnerContainer>
          </SubNavigationColumnWrapper>
        )}
      </React.Fragment>
    )
  }
  return null
}
const navPadding = spacing(2)

const NavWrapper = styled.div<{ overflowHidden?: boolean }>`
  width: 100%;
  ${(props) =>
    props.overflowHidden &&
    props.theme.patterns.header.navigation.subMenu?.column?.height &&
    `
      height: calc(
        (${navPadding} * 2) +
          ${props.theme.patterns.header.navigation.subMenu.column.height}
      );
      overflow: hidden;
    `}
`

const NavContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${navPadding};
  width: 100%;
`

const NavContentGrid = styled.div`
  display: block;

  ${(props) =>
    padding(
      props.theme.patterns.header.navigation.subMenu.padding ?? {
        top: 2,
        right: 2,
        bottom: 2,
        left: 2,
      },
    )};

  width: 100%;
  column-count: ${(props) =>
    props.theme.patterns.header.navigation.subMenu.column?.count || 5};
  > div {
    display: grid;
    grid-template-rows: 1fr auto;
    break-inside: avoid;
  }
`

const MasonryContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) =>
      props.theme.patterns.header.navigation.subMenu.column?.count || 5},
    minmax(0, 1fr)
  );
  ${(props) =>
    padding(
      props.theme.patterns.header.navigation.subMenu.padding ?? {
        top: 2,
        right: 2,
        bottom: 2,
        left: 2,
      },
    )};
`

interface TextNavContentProps {
  item: NavigationItem
  dynamicWidth: boolean
  enableThreeTierNav: boolean
  onClick?: (value?: string) => void
  enableAlternateNavGrid?: boolean
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  navigationOnClickCallback?: (data: NavigationEventData) => void
  navigationTopLevel?: string
  enableMasonryGridMenu?: boolean
}

export const TextNavigationContent = ({
  item,
  dynamicWidth,
  enableThreeTierNav,
  onClick,
  enableAlternateNavGrid,
  navSubLevelPromotionList,
  navLevelThreePromotionList,
  navigationOnClickCallback,
  navigationTopLevel,
  enableMasonryGridMenu,
}: TextNavContentProps) => {
  const [activeNavIndex, setActiveNavIndex] = React.useState([-1, -1])
  const arrowLeft = (event) => {
    event.preventDefault()
    const leftElement = (document.querySelector(
      `[data-nav-name="${item.displayName}"][data-nav-index="${
        activeNavIndex[0] - 1
      },${activeNavIndex[1]}"]`,
    ) ||
      document.querySelector(
        `[data-nav-name="${item.displayName}"][data-nav-index="${
          activeNavIndex[0] - 1
        },${activeNavIndex[1] + 1}"]`,
      )) as HTMLElement
    if (leftElement) {
      leftElement.focus()
    } else if (
      document.querySelector(
        `[data-nav-name="${item.displayName}"][data-nav-index="${
          activeNavIndex[0] - 1
        },0"]`,
      ) as HTMLElement
    ) {
      let closestEl
      let index = activeNavIndex[1] - 1
      while (!closestEl) {
        closestEl = document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] - 1
          },${index}"]`,
        ) as HTMLElement
        index -= 1
      }
      closestEl.focus()
    }
  }

  const arrowUp = (event) => {
    event.preventDefault()
    const aboveElement = document.querySelector(
      `[data-nav-name="${item.displayName}"][data-nav-index="${
        activeNavIndex[0]
      },${activeNavIndex[1] - 1}"]`,
    ) as HTMLElement
    if (aboveElement) {
      aboveElement.focus()
    } else if (
      (document.querySelector(
        `[data-nav-name="${item.displayName}"][data-nav-index="${
          activeNavIndex[0] - 1
        },0"]`,
      ) ||
        document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] - 1
          },1"]`,
        )) as HTMLElement
    ) {
      let closestEl = (document.querySelector(
        `[data-nav-name="${item.displayName}"][data-nav-index="${
          activeNavIndex[0] - 1
        },0"]`,
      ) ||
        document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] - 1
          },1"]`,
        )) as HTMLElement
      for (let startingIndex = 0; startingIndex <= 1; startingIndex += 1) {
        let index = startingIndex
        while (closestEl) {
          const candidateClosestEl = document.querySelector(
            `[data-nav-name="${item.displayName}"][data-nav-index="${
              activeNavIndex[0] - 1
            },${index}"]`,
          ) as HTMLElement

          if (candidateClosestEl) {
            closestEl = candidateClosestEl
            index += 1
          } else {
            break
          }
        }
      }
      closestEl.focus()
    }
  }

  const arrowRight = (event) => {
    event.preventDefault()
    const rightElement = document.querySelector(
      `[data-nav-name="${item.displayName}"][data-nav-index="${
        activeNavIndex[0] + 1
      },${activeNavIndex[1]}"]`,
    ) as HTMLElement
    if (rightElement) {
      rightElement.focus()
    } else if (
      document.querySelector(
        `[data-nav-name="${item.displayName}"][data-nav-index="${
          activeNavIndex[0] + 1
        },0"]`,
      ) as HTMLElement
    ) {
      let closestEl
      let index = activeNavIndex[1] - 1
      while (!closestEl) {
        closestEl = document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] + 1
          },${index}"]`,
        ) as HTMLElement
        index -= 1
      }
      closestEl.focus()
    }
  }

  const arrowDown = (event) => {
    event.preventDefault()
    const belowElement = document.querySelector(
      `[data-nav-name="${item.displayName}"][data-nav-index="${
        activeNavIndex[0]
      },${activeNavIndex[1] + 1}"]`,
    ) as HTMLElement
    belowElement
      ? belowElement.focus()
      : (document.querySelector(
          `[data-nav-name="${item.displayName}"][data-nav-index="${
            activeNavIndex[0] + 1
          },0"]`,
        ) as HTMLElement)?.focus()
  }

  const onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        arrowLeft(event)
        break
      case 'ArrowUp':
        arrowUp(event)
        break
      case 'ArrowRight':
        arrowRight(event)
        break
      case 'ArrowDown':
        arrowDown(event)
        break
      default:
        break
    }
  }

  const focusHandler = (colIndex: number, rowIndex: number) => {
    setActiveNavIndex([colIndex, rowIndex])
  }

  const firstSubNav = item?.subNavigation?.[0]
  const featuredColumn = item?.subNavigation?.[0].type === 'FEATURED' && (
    <SubNavigationColumnWrapper
      key="sub-nav-column-0"
      data-testid="sub-nav-column"
      columnWidth="100% / 6"
    >
      <SubNavColumnFeaturedInnerContainer>
        {firstSubNav?.subNavigation && (
          <NavigationList
            aria-labelledby={firstSubNav?.id}
            data-testid={`nav-list-first-col-${firstSubNav.displayName}`}
          >
            <SubnavigationLinks
              subNavsSubNavs={firstSubNav?.subNavigation}
              colIndex={0}
              topLevelName={item.displayName}
              focusHandler={focusHandler}
              parentType={firstSubNav?.type}
              onClick={onClick}
              navigationOnClickCallback={navigationOnClickCallback}
              navigationTopLevel={navigationTopLevel}
              subNavTopLevel={firstSubNav.displayName}
              enableThreeTierNav={enableThreeTierNav}
            />
          </NavigationList>
        )}
      </SubNavColumnFeaturedInnerContainer>
    </SubNavigationColumnWrapper>
  )
  const dynamicWidthSize =
    dynamicWidth &&
    item?.subNavigation?.length &&
    item?.subNavigation?.length < 6
      ? `100% / ${item?.subNavigation?.length}`
      : ''
  const navColumns = item?.subNavigation?.map((subNav, index) => {
    return (
      <SubNavigationColumn
        subNav={subNav}
        colIndex={index}
        topLevelName={item.displayName}
        focusHandler={focusHandler}
        featuredColumn={!!featuredColumn}
        enableThreeTierNav={enableThreeTierNav}
        dynamicWidthSize={dynamicWidthSize}
        onClick={onClick}
        navSubLevelPromotionList={navSubLevelPromotionList}
        navLevelThreePromotionList={navLevelThreePromotionList}
        navigationOnClickCallback={navigationOnClickCallback}
        navigationTopLevel={navigationTopLevel}
      />
    )
  })
  if (featuredColumn) {
    return (
      <NavWrapper overflowHidden={enableThreeTierNav}>
        <NavContent onKeyDown={onKeyDown}>
          {featuredColumn}
          <ColumnsFlexWrapper>{navColumns}</ColumnsFlexWrapper>
        </NavContent>
      </NavWrapper>
    )
  }
  return (
    <NavWrapper overflowHidden={enableThreeTierNav}>
      {enableMasonryGridMenu ? (
        <MasonryContentGrid onKeyDown={onKeyDown}>
          {navColumns}
        </MasonryContentGrid>
      ) : enableAlternateNavGrid ? (
        <NavContentGrid onKeyDown={onKeyDown}>{navColumns}</NavContentGrid>
      ) : (
        <NavContent onKeyDown={onKeyDown}>{navColumns}</NavContent>
      )}
    </NavWrapper>
  )
}
