import * as React from 'react'
export const SvgCircularVk = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <circle fill="#fff" cx={20} cy={20} r={20} />
    <path
      fill={props.fill || '#5181b8'}
      d="M32.403 13.083c.181-.624 0-1.083-.86-1.083H28.7c-.723 0-1.057.396-1.238.833 0 0-1.446 3.654-3.495 6.027-.663.687-.965.905-1.326.905-.181 0-.443-.218-.443-.842v-5.84c0-.75-.21-1.083-.812-1.083h-4.47c-.452 0-.724.348-.724.677 0 .71 1.025.875 1.13 2.873v4.34c0 .952-.166 1.125-.527 1.125-.965 0-3.31-3.67-4.701-7.87C11.82 12.33 11.547 12 10.82 12H7.975c-.812 0-.975.396-.975.833 0 .781.964 4.653 4.49 9.774C13.84 26.104 17.152 28 20.166 28c1.808 0 2.031-.421 2.031-1.146V24.21c0-.843.172-1.01.744-1.01.422 0 1.145.218 2.833 1.904C27.702 27.103 28.02 28 29.105 28h2.845c.812 0 1.219-.421.984-1.252-.256-.828-1.177-2.03-2.399-3.454-.663-.812-1.657-1.686-1.959-2.123-.422-.562-.301-.812 0-1.312 0 0 3.466-5.058 3.827-6.776"
    />
  </svg>
)
export default SvgCircularVk
