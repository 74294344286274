import * as React from 'react'

export const SvgContentTwitter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <path
      fill={props.fill || '#333'}
      d="M 21.986 18.391 L 29.751 9.501 L 27.909 9.501 L 21.167 17.221 L 15.782 9.501 L 9.57 9.501 L 17.715 21.176 L 9.57 30.499 L 11.41 30.499 L 18.531 22.345 L 24.219 30.499 L 30.43 30.499 L 21.985 18.391 L 21.986 18.391 Z M 19.463 21.277 L 18.639 20.116 L 12.075 10.864 L 14.899 10.864 L 20.2 18.329 L 21.024 19.493 L 27.909 29.196 L 25.084 29.196 L 19.463 21.278 L 19.463 21.277 Z"
    />
  </svg>
)
export default SvgContentTwitter
