import * as React from 'react'
export const SvgWeibo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <defs>
      <path id="weibo_svg__a" d="M0 .035h23.98V20H0z" />
      <path id="weibo_svg__c" d="M0 .023h20.948V17H0z" />
      <path id="weibo_svg__e" d="M.012.032h8.966v7.952H.012z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 2)">
        <mask id="weibo_svg__b" fill="#fff">
          <use xlinkHref="#weibo_svg__a" />
        </mask>
        <path
          fill="#FEFEFE"
          d="M17.547 3.138c-.149 0-.299-.014-.445.003-.599.069-.946.627-.73 1.162.144.354.454.539.913.508.585-.04 1.09.118 1.49.556.365.402.462.88.421 1.41-.039.509.316.901.79.923.479.021.852-.33.886-.839.015-.216.03-.431.012-.652-.149-1.758-1.574-3.082-3.337-3.07m6.43 3.347c-.04-2.028-.806-3.726-2.388-5.006C20.083.262 18.347-.187 16.427.137c-.5.084-.783.501-.7.995.075.436.515.727.983.652a4.857 4.857 0 011.593-.007c2.743.463 4.486 3.093 3.86 5.821-.12.524.148.974.645 1.08.449.098.892-.213.999-.71.105-.487.187-.977.17-1.482M10.112 19.999c.589.012 1.511-.07 2.426-.228 1.927-.332 3.737-.97 5.344-2.106 1.232-.87 2.222-1.944 2.74-3.393.197-.549.307-1.117.276-1.697-.048-.916-.63-1.482-1.353-1.936-.576-.36-1.208-.59-1.855-.778-.15-.043-.211-.073-.117-.237a2.926 2.926 0 00.36-1.919c-.125-.9-.565-1.407-1.444-1.61-.43-.099-.868-.1-1.304-.05-1.072.126-2.07.49-3.035.956-.145.07-.294.147-.44.023-.158-.134-.094-.298-.036-.458a5.27 5.27 0 00.295-1.379c.087-1.1-.577-1.859-1.68-1.906-1.002-.043-1.914.28-2.787.716C5.096 5.2 3.146 6.947 1.607 9.132.608 10.55-.221 12.03.053 13.907c.138.952.365 1.826.933 2.598.755 1.027 1.767 1.734 2.905 2.27 1.86.877 3.835 1.22 6.22 1.224"
          mask="url(#weibo_svg__b)"
        />
      </g>
      <g transform="translate(0 5)">
        <mask id="weibo_svg__d" fill="#fff">
          <use xlinkHref="#weibo_svg__c" />
        </mask>
        <path
          fill="#E2252B"
          d="M9.255 15.504a9.72 9.72 0 003.056-.448c1.406-.443 2.669-1.126 3.609-2.313 1.213-1.53 1.11-3.396-.254-4.784a5.26 5.26 0 00-1.447-1.048c-2.352-1.149-4.781-1.188-7.242-.446-1.367.411-2.58 1.106-3.498 2.248-1.119 1.39-1.158 3.066-.11 4.427a4.89 4.89 0 001.34 1.182c1.398.856 2.933 1.175 4.546 1.182m.878 1.495c-2.39-.003-4.369-.352-6.234-1.243-1.14-.544-2.154-1.262-2.91-2.304-.57-.784-.797-1.671-.936-2.638C-.221 8.907.61 7.406 1.61 5.967 3.153 3.747 5.107 1.975 7.517.754c.875-.443 1.79-.77 2.794-.727 1.105.048 1.77.817 1.684 1.935a5.425 5.425 0 01-.297 1.4c-.057.163-.122.329.037.465.146.125.296.048.44-.023.968-.473 1.968-.844 3.042-.97.437-.053.876-.05 1.307.05.88.206 1.322.72 1.448 1.634a3.003 3.003 0 01-.361 1.948c-.095.167-.033.197.116.24.65.191 1.283.425 1.86.79.725.461 1.308 1.036 1.356 1.966.031.59-.08 1.166-.276 1.722-.52 1.472-1.513 2.562-2.747 3.445-1.61 1.154-3.424 1.802-5.355 2.139-.917.16-1.842.243-2.432.23"
          mask="url(#weibo_svg__d)"
        />
      </g>
      <g transform="translate(15 2)">
        <mask id="weibo_svg__f" fill="#fff">
          <use xlinkHref="#weibo_svg__e" />
        </mask>
        <path
          fill="#F1970A"
          d="M8.976 5.954c.018.464-.072.914-.186 1.362-.116.455-.596.74-1.082.652-.54-.099-.83-.511-.7-.992.679-2.505-1.212-4.92-4.187-5.344a6.213 6.213 0 00-1.728.005C.585 1.707.108 1.44.028 1.04-.063.586.244.203.786.126 2.87-.172 4.751.24 6.385 1.359 8.1 2.534 8.932 4.093 8.976 5.954"
          mask="url(#weibo_svg__f)"
        />
      </g>
      <path
        fill="#F1970A"
        d="M17.351 5.006c1.923-.012 3.477 1.437 3.64 3.362.02.241.003.476-.013.714-.038.557-.444.94-.967.917-.517-.023-.903-.453-.86-1.01.044-.58-.062-1.104-.46-1.543-.437-.48-.987-.652-1.625-.609-.5.034-.84-.168-.995-.556-.236-.585.142-1.197.795-1.272.16-.018.323-.003.485-.003"
      />
      <path
        fill="#FEFDFD"
        d="M7.78 19.082c1.467 0 2.603-.474 3.418-1.316 1.521-1.57 1.084-3.855-.904-4.725-1.8-.788-4.123-.117-5.181 1.495-.77 1.175-.655 2.6.3 3.538a3.52 3.52 0 002.368 1.008m.792.917c-1.596-.006-3.114-.307-4.497-1.115a4.775 4.775 0 01-1.327-1.116c-1.035-1.283-.997-2.865.11-4.177.908-1.077 2.108-1.733 3.46-2.122 2.434-.7 4.837-.663 7.163.422a5.203 5.203 0 011.432.989c1.35 1.31 1.45 3.07.251 4.514-.93 1.12-2.18 1.765-3.57 2.183-.981.295-1.991.435-3.022.422"
      />
      <path
        fill="#030405"
        d="M9.801 16.306c.333.004.622-.223.626-.493.003-.224-.199-.395-.47-.399-.315-.003-.627.243-.63.5-.004.221.198.388.474.392m-1.807-.234c-.588-.001-1.119.254-1.422.668-.427.585-.214 1.294.465 1.55.675.255 1.533-.008 1.948-.599.396-.562.205-1.233-.428-1.504a1.273 1.273 0 00-.563-.115M8.384 20a3.696 3.696 0 01-2.525-1.107c-1.017-1.03-1.14-2.597-.32-3.887 1.13-1.772 3.606-2.509 5.525-1.643 2.12.955 2.586 3.467.964 5.191-.87.925-2.08 1.446-3.644 1.446"
      />
      <path
        fill="#FDFDFD"
        d="M8.157 16.001a.757.757 0 01.396.099c.446.234.58.813.302 1.299-.293.51-.897.737-1.372.517-.479-.221-.628-.834-.328-1.338A1.158 1.158 0 018.157 16"
      />
      <path
        fill="#FAFBFB"
        d="M9.431 16A.426.426 0 019 15.56c.004-.288.287-.564.574-.56a.428.428 0 01.426.447.568.568 0 01-.569.553"
      />
    </g>
  </svg>
)
export default SvgWeibo
