import * as React from 'react'
import { createContext, useState } from 'react'

import { Announcer } from './Announcer'

interface AnnouncerContextType {
  announceAssertiveMessage: (message: string) => void
  announcePoliteMessage: (message: string) => void
}

export const AnnouncerContext = createContext<AnnouncerContextType>({
  announceAssertiveMessage: () => {},
  announcePoliteMessage: () => {},
})

export const LiveAnnouncerProvider = (props: any) => {
  const [assertiveMessage, setAssertiveMessage] = useState('')
  const [politeMessage, setPoliteMessage] = useState('')

  const announceAssertiveMessage = (message: string) => {
    setAssertiveMessage(message)
  }

  const announcePoliteMessage = (message: string) => {
    setPoliteMessage(message)
  }

  return (
    <AnnouncerContext.Provider
      value={{ announceAssertiveMessage, announcePoliteMessage }}
    >
      <Announcer
        assertiveMessage={assertiveMessage}
        politeMessage={politeMessage}
      />
      {props.children}
    </AnnouncerContext.Provider>
  )
}

export default LiveAnnouncerProvider
