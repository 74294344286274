import * as React from 'react'
export const SvgIconDelete = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || '#8C8C8C'}
      fillRule="evenodd"
      d="M12 10.586L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586zM12 20a8 8 0 110-16 8 8 0 010 16z"
    />
  </svg>
)
export default SvgIconDelete
