import * as React from 'react'
export const SvgCircularTwitter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <title>Twitter</title>
    <circle cx="20" cy="20" r="20" fill="#1DA1F2" />
    <path
      d="M21.9033 18.8921L29.3483 10H27.5833L21.1183 17.7209L15.955 10H10L17.8083 21.6747L10 31H11.765L18.5917 22.8459L24.045 31H30L21.9033 18.8921ZM19.4867 21.7774L18.695 20.6147L12.4 11.3647H15.11L20.19 18.8305L20.9817 19.9931L27.585 29.6969H24.875L19.4867 21.7774Z"
      fill="#ffffff"
    />
  </svg>
)
export default SvgCircularTwitter
