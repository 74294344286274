import * as React from 'react'
import loadable from '@loadable/component'

import { useTheme } from '@thg-commerce/gravity-patterns/theme'

const NavIconPlaceholder = () => {
  return <div style={{ width: 40, height: 40 }} />
}

const Box = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Box'),
  { fallback: <NavIconPlaceholder /> },
)
const ContactUs = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ContactUs'),
  { fallback: <NavIconPlaceholder /> },
)
const FooterCookie = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/FooterCookie'),
  { fallback: <NavIconPlaceholder /> },
)
const Delivery = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Delivery'),
  { fallback: <NavIconPlaceholder /> },
)
const GiftCard = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/PaymentIcons/GiftCard'),
  { fallback: <NavIconPlaceholder /> },
)
const HelpDesk = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/HelpDesk'),
  { fallback: <NavIconPlaceholder /> },
)
const InternationalDelivery = loadable(
  () =>
    import('@thg-commerce/gravity-icons/src/components/InternationalDelivery'),
  { fallback: <NavIconPlaceholder /> },
)
const Returns = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Returns'),
  { fallback: <NavIconPlaceholder /> },
)
const Telephone = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Telephone'),
  { fallback: <NavIconPlaceholder /> },
)
const UseMyLocation = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/UseMyLocation'),
  { fallback: <NavIconPlaceholder /> },
)
const Tick = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Tick'),
  { fallback: <NavIconPlaceholder /> },
)
const MatalanMe = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/MatalanMeIcon'),
  { fallback: <NavIconPlaceholder /> },
)

interface NavIconProps {
  iconName: string
  fillColor?: string
  focusable?: boolean
  ariaHidden?: boolean
}

const Icon = (props: {
  path: string
  fillColor?: string
  height?: string
  width?: string
  viewBox?: string
  focusable?: boolean
  ariaHidden?: boolean
}) => {
  return (
    <React.Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={props.viewBox}
        height={props.height}
        width={props.width}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      >
        <path
          d={props.path}
          fill={props.fillColor}
          fillRule="evenodd"
          clipRule="evenodd"
        ></path>
      </svg>
    </React.Fragment>
  )
}

const getNavIcon = (props: NavIconProps) => {
  const fillColour = props.fillColor || 'grey'
  return {
    cookie: (
      <FooterCookie
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    'contact-us': (
      <ContactUs
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    information: (
      <ContactUs
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    delivery: (
      <Delivery
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    localDelivery: (
      <Delivery
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    'help-desk': (
      <HelpDesk
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    question: (
      <HelpDesk
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    'international-delivery': (
      <InternationalDelivery
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    internationalDelivery: (
      <InternationalDelivery
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    returns: (
      <Returns
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    telephone: (
      <Telephone
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    phone: (
      <Telephone
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    locate: (
      <UseMyLocation
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    tick: (
      <Tick
        stroke={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
        strokeWidth="2px"
      />
    ),
    box: (
      <Box
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    giftCard: (
      <GiftCard
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
    MatalanMe: (
      <MatalanMe
        fill={fillColour}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
      />
    ),
  }
}

export const FooterNavIcon = (props: NavIconProps) => {
  const theme = useTheme()
  const iconNameTheme = theme.patterns.footer.navigation.icons[props.iconName]
  if (iconNameTheme?.svgPath) {
    return (
      <Icon
        path={iconNameTheme.svgPath}
        fillColor={props.fillColor}
        height={iconNameTheme.height}
        width={iconNameTheme.width}
        focusable={props.focusable}
        aria-hidden={props.ariaHidden}
        viewBox={iconNameTheme.viewBox}
      />
    )
  }
  const NavIcon = getNavIcon(props)
  return NavIcon[props.iconName] || null
}
