import * as React from 'react'
export const SvgBlackFacebook = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <title>Icon-Facebook@2x</title>
    <defs>
      <filter color-interpolation-filters="auto" id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.200000 0 0 0 0 0.200000 0 0 0 0 0.200000 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Icon-Facebook"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Icon-Facebook" filter="url(#filter-1)">
        <g
          id="Facebook"
          transform="translate(6.461538, 0.000000)"
          fill="#333333"
        >
          <path d="M2.46877193,7.89614035 L2.46877193,5.49754386 C2.46877193,4.43929825 2.4954386,2.80842105 3.26315789,1.79789474 C4.07298246,0.727017544 5.18315789,0 7.09473684,0 C10.2077193,0 11.5185965,0.443508772 11.5185965,0.443508772 L10.9024561,4.09964912 C10.9024561,4.09964912 9.87368421,3.80350877 8.91368421,3.80350877 C7.95368421,3.80350877 7.09473684,4.14736842 7.09473684,5.10596491 L7.09473684,7.89614035 L11.0301754,7.89614035 L10.7550877,11.4666667 L7.09473684,11.4666667 L7.09473684,23.8750877 L2.46877193,23.8750877 L2.46877193,11.4666667 L0,11.4666667 L0,7.89614035 L2.46877193,7.89614035 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)
export default SvgBlackFacebook
