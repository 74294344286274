import * as React from 'react'
import { FunctionComponent } from 'react'

type OrdererProps = {
  order: number[]
}

export const Orderer: FunctionComponent<OrdererProps> = (props) => {
  React.useEffect(() => {
    process.env.NODE_ENV !== 'production' &&
      props.order.length !== React.Children.count(props.children) &&
      (props.order.length < React.Children.count(props.children)
        ? console.warn(
            'Orderer Warning: Your props.order array length is less than the number of children of Orderer, please make sure they are equal as extra children will not be ordered - likely resulting in unwanted behaviour',
          )
        : console.warn(
            'Orderer Warning: Your props.order array length is greater than the number of children of Orderer, please make sure they are equal as extra order values will be ignored - likely resulting in unwanted behaviour',
          ))
  }, [])

  const reorderChildren = () => {
    const getExtendedOrderArray = (orderArray, extendAmount) => {
      const emptyArray = Array(extendAmount).fill(0)
      const arrayToAppend = emptyArray.map(
        (_, index) => index + orderArray.length,
      )
      return orderArray.concat(arrayToAppend)
    }
    const normalizedOrderArray = props.order.map((elem) =>
      props.order.slice().sort().indexOf(elem),
    )

    const componentOrderArray =
      normalizedOrderArray.length === React.Children.count(props.children)
        ? normalizedOrderArray
        : normalizedOrderArray.length > React.Children.count(props.children)
        ? normalizedOrderArray.slice(0, React.Children.count(props.children))
        : getExtendedOrderArray(
            normalizedOrderArray,
            React.Children.count(props.children) - normalizedOrderArray.length,
          )
    const childrenArray = React.Children.toArray(props.children)
    const reorderedChildArray = componentOrderArray.map((order) => {
      return childrenArray[order] || null
    })
    return reorderedChildArray
  }

  const memoizedOrderedChildren = React.useMemo(reorderChildren, [
    props.order,
    props.children,
  ])

  return memoizedOrderedChildren
}

export default Orderer
