import { SupportedEndpoints } from '../types/client'

export const graphqlUrls = (
  publicRuntimeConfig: {
    GRAPHQL_SERVER_URI?: string
    siteDefinition: {
      GRAPHQL_SERVER_URI: string
    }
  },
  serverRuntimeConfig?: {
    siteDefinition?: {
      CONTENT_GRAPHQL_SERVER_URI?: string
    }
  },
): SupportedEndpoints => ({
  default:
    publicRuntimeConfig.GRAPHQL_SERVER_URI ||
    publicRuntimeConfig.siteDefinition.GRAPHQL_SERVER_URI,
  content:
    serverRuntimeConfig?.siteDefinition?.CONTENT_GRAPHQL_SERVER_URI ||
    'https://tesseractgraphql.io.thehut.local/default/graphql',
})
