import * as React from 'react'
export const SvgBnplOptions = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M11.698 16.501H2.093a1.06 1.06 0 01-.744-.28A1.035 1.035 0 011 15.435V3.077a1.061 1.061 0 01.79-1.04c.084-.017.17-.03.255-.036A.475.475 0 012.1 2h18.518c.172-.006.342.03.497.106.387.2.596.518.6.956.006.711 0 1.422 0 2.134v6.033a.206.206 0 00.06.153 5.806 5.806 0 011.448 2.892c.093.483.122.976.087 1.467a5.876 5.876 0 01-4.633 5.345c-.49.108-.993.148-1.494.12-1.533-.085-2.84-.666-3.915-1.761a5.75 5.75 0 01-1.552-2.892c0-.01-.004-.021-.008-.032-.003-.01-.001-.008-.009-.02zm-.041-2.066c.342-1.838 1.298-3.24 2.908-4.188 1.64-.9 3.333-.992 5.085-.349V7.703c-.008-.004-.012-.008-.016-.008H3.136c-.02 0-.04 0-.06.004 0 0-.003 0-.005.004l-.004.004c-.014.11-.008 6.682.006 6.734l8.584-.006zm10.005.919a4.2 4.2 0 10-8.4 0 4.2 4.2 0 008.4 0zM19.648 5.62V4.067H3.066V5.62h16.582z"
      fill={props.fill || '#333'}
    />
    <path
      d="M6.178 13.392c-.347 0-.693.004-1.04 0a.995.995 0 01-.906-.546 1.031 1.031 0 01.791-1.512c.051-.005.102-.008.153-.008h2.04a1.034 1.034 0 11-.025 2.066H6.178zM16.595 14.015v-1.3a.863.863 0 011.72-.095c.005.047.008.094.008.14v2.146a.19.19 0 00.066.151c.311.307.62.618.93.927a.867.867 0 01-.916 1.428.85.85 0 01-.303-.202c-.412-.41-.824-.822-1.235-1.234a.89.89 0 01-.266-.634c-.005-.232 0-.463 0-.694l-.004-.633z"
      fill={props.fill || '#333'}
    />
  </svg>
)
export default SvgBnplOptions
