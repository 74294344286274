export interface SocialBrandsInterface {
  twitter: string
  facebook: string
  whatsapp: string
  pinterest: string
}

export const socialBrands: SocialBrandsInterface = {
  twitter: '#33333380',
  facebook: '#3B5998',
  whatsapp: '#25D366',
  pinterest: '#BD2026',
}
