import * as React from 'react'
export const SvgMailFromUk = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={76} height={24} aria-label="Mailed from UK" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M.704.75v22.5h74.592V.75H.704zM76 0v24H0V0h76z"
        fill="#DDD"
        fillRule="nonzero"
      />
      <path
        d="M35.367 17.9c-2.804-.567-4.567-1.717-5.292-3.45-.598 1.6-2.378 2.783-5.338 3.55a3.485 3.485 0 00-.331-.7c2.772-.6 4.488-1.65 5.15-3.15h-5.197v-.6h1.512V10.2h3.874V9h.66v1.2h3.78v3.35h1.512v.6h-5.15c.82 1.633 2.52 2.633 5.103 3-.031.067-.063.15-.094.25a7.74 7.74 0 00-.19.5zM31.917 6h.615v1.5h3.118v.6h-3.118v1.15h-.614V8.1h-3.733v1.15h-.661V8.1h-3.118v-.6h3.118V6h.661v1.5h3.733V6zm-2.267 7.55c.063-.6.095-1.517.095-2.75h-3.213v2.75h3.118zm.756-2.75c0 .333-.016.783-.047 1.35 0 .667-.016 1.133-.048 1.4h3.213V10.8h-3.118zm6.709-4.15h10.063v11.3h-.662v-.65H37.73v.65h-.614V6.65zm.614.6v9.45h8.787V7.25H37.73zm1.228 4.05h2.835V9.35H38.72v-.6h6.803v.6h-3.118v1.95h2.835v.6h-2.835v2.75h3.307v.55h-7.181v-.55h3.26V11.9h-2.835v-.6zm4.347 1.6l.425-.4.094.1c.504.433.882.8 1.134 1.1l-.472.45c-.41-.5-.803-.917-1.181-1.25zm5.196 3.9h1.607V9.1h3.78V7.8h-5.103v-.6h5.102V6h.614v1.2h5.15v.6H54.5v1.3h3.78v7.7h1.653v.6H48.5v-.6zm2.268-1.25v1.25h6.85v-1.25h-6.85zm0-5.85v1.35h6.85V9.7h-6.85zm0 3.9v1.35h6.85V13.6h-6.85zm0-1.95V13h6.85v-1.35h-6.85zm12.756-5.6h.614v2.2h2.315v9.15h-.614v-.95h-3.968v.95h-.615V8.25h2.268v-2.2zm8.315 8.2c0 1.333-.52 2.033-1.559 2.1h-1.087a3.85 3.85 0 00-.141-.7h.472c.22.033.394.05.52.05.819.033 1.212-.467 1.18-1.5 0-.967-.519-1.917-1.558-2.85l1.417-3.7h-2.551v10.3h-.614V7.05h3.78v.7l-1.324 3.45c1.008.933 1.497 1.95 1.465 3.05zm-9.968-1.6v3.2h1.653v-3.2h-1.653zm2.267 0v3.2h1.701v-3.2h-1.7zm-2.267-3.8v3.2h1.653v-3.2h-1.653zm3.968 0h-1.7v3.2h1.7v-3.2zm-46.854-.969c.188.403-1.45 1.526-3.657 2.506-.142.061-.3.132-.47.205l-2.112 7.146-.594.262.25-6.414c-1.678.653-3.542 1.327-4.684 1.646l-.337 2.691-.167.077-.223-2.6c-.206.032-.331.03-.354-.023-.024-.052.054-.146.213-.276L5 11.228l.17-.076 2.29 1.523c.998-.632 2.742-1.564 4.351-2.377L6.71 6.265 7.299 6l6.885 3.141c.17-.078.327-.148.467-.211 2.208-.983 4.149-1.45 4.334-1.049z"
        fill="#888"
      />
    </g>
  </svg>
)
export default SvgMailFromUk
