import * as React from 'react'
export const SvgGoogleplus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} {...props}>
    <g fillRule="nonzero" fill="none">
      <circle fill="#DD5044" cx={20} cy={20} r={20} />
      <path
        d="M15.862 12C11.52 12 8 15.582 8 20s3.52 8 7.862 8c2.828 0 4.467-.89 5.89-2.338s1.94-3.637 1.94-5.846c0-.337-.02-.672-.059-1.004h-7.676v2.828h4.509c-.183.706-.494 1.348-.983 1.846-.876.891-1.886 1.44-3.627 1.44-2.673 0-4.84-2.206-4.84-4.926s2.167-4.926 4.84-4.926c1.3 0 2.434.378 3.332 1.202l2.213-2.213-.003-.004c-1.477-1.417-3.365-2.058-5.536-2.058V12zm12.751 4.606v2.306h-2.306v2.254h2.306v2.306h2.254v-2.306h2.306v-2.254h-2.306v-2.306h-2.254zm-8.634.655a.028.028 0 01.003.006v-.003l-.003-.003z"
        fill="#FFF"
      />
    </g>
  </svg>
)
export default SvgGoogleplus
