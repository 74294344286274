import * as React from 'react'
import { Property } from 'csstype'

import { BurgerIcon } from '@thg-commerce/gravity-icons/src'
import {
  FlyoutDirection,
  IconStyling,
  SlotConfiguration,
} from '@thg-commerce/gravity-patterns/Header/theme'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { BreakpointType, mq } from '@thg-commerce/gravity-theme'

import {
  FlyoutMenu,
  FlyoutMenuI18nText,
} from '../../Header/FlyoutMenu/FlyoutMenu'
import { FlyoutIconTypes, HeaderI18nText } from '../../Header/Header'
import { HeaderDropdownProps } from '../../Header/HeaderDropdown/HeaderDropdown'
import { TriggerStyles } from '../../Header/HeaderDropdown/HeaderDropdownTrigger/HeaderDropdownTrigger'
import { NavigationEventData } from '../../Header/Navigation/Navigation'
import { NavigationTree } from '../../Header/NavigationTree'
import { SubmenuI18nText } from '../../Header/Submenu/Submenu'
import { DropdownOptions, HeaderSlot } from '../../Header/types'

const HeaderButton = styled.button<{
  svgFill: string
  dropdownType: DropdownOptions
}>`
  ${TriggerStyles}

  svg {
    fill: ${(props) => props.svgFill};
  }

  &:hover {
    background-color: ${(props) =>
      props.theme.patterns.header.dropdowns.headerButton?.backgroundHoverColor
        ? props.theme.patterns.header.dropdowns.headerButton
            ?.backgroundHoverColor
        : ''};
  }

  &:focus {
    svg {
      fill: ${(props) => props.theme.colors.palette.greys.darker};
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: none;
    visibility: hidden;
  }
`

interface HeaderRoutes {
  accountHome: string
  basket: string
  listExtension: string
}
interface FlyoutMenuProps {
  isDesktop: boolean
  i18nText: HeaderI18nText
  headerDropdownI18nText: { closeButtonAriaLabel: string }
  navigationTree: NavigationTree
  flyoutI18nText: FlyoutMenuI18nText
  routes: HeaderRoutes
  accountNav: (close?: () => void) => React.ReactElement
  wishlistNotification?: React.FunctionComponent
  submenu: {
    showSessionSettings?: boolean
    sessionSettingsModal: (close?: () => void) => React.ReactElement
    i18nText: SubmenuI18nText
    sessionSettingsTriggerText: string
    showLinkOne?: boolean
    showLinkTwo?: boolean
    flagPath?: string
    submenuSlots: HeaderSlot[]
  }
  headerSlots: HeaderSlot[]
  responsiveFlyoutMenuIcons: {
    store?: boolean
    wishlist?: boolean
  }
  flyoutIcon: string
  burgerIconColor: Property.Color
  flyoutMenuIcon: IconStyling
  flyoutDirection: FlyoutDirection
  accountDropdownProps: HeaderDropdownProps
  enableThreeTierNav?: boolean
  headerPromotionUrl?: string
  headerPromotionUrlTwo?: string
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  selectTopLevelNavigationItem?: (path: string) => void
  homeButtonOnClick?: () => void
  primaryNavImages?: string[]
  slots?: BreakpointType<SlotConfiguration[]>[]
  dropdownWidth?: string
  selectedTopLevelNavigationItemIndex?: number
  navigationOnClickCallback?: (data: NavigationEventData) => void
  hideThreeTierTab?: boolean
  hideFocusOnLevelOneNav?: boolean
}

export const FlyoutPresenter = (props: FlyoutMenuProps) => {
  const [visible, setVisible] = React.useState<boolean>(false)

  return (
    <React.Fragment>
      <HeaderButton
        data-testid="flyout-button"
        dropdownType={DropdownOptions.ACCOUNT}
        type="button"
        title={props.i18nText.menuButtonAriaLabel}
        aria-label={props.i18nText.menuButtonAriaLabel}
        svgFill={props.burgerIconColor}
        onClick={() => {
          setVisible(true)
        }}
      >
        {props.flyoutMenuIcon?.svgPath !== '' ? (
          <svg
            width={props.flyoutMenuIcon?.width || 24}
            height={props.flyoutMenuIcon?.height || 24}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={props.flyoutMenuIcon?.viewBox || '0 0 24 24'}
          >
            <path d={props.flyoutMenuIcon?.svgPath}></path>
          </svg>
        ) : (
          <BurgerIcon />
        )}
      </HeaderButton>
      <FlyoutMenu
        isShowing={visible}
        onClose={() => setVisible(false)}
        headerPromotionUrl={props.headerPromotionUrl}
        navigationTree={props.navigationTree}
        i18nText={props.flyoutI18nText}
        navSubLevelPromotionList={props.navSubLevelPromotionList}
        navLevelThreePromotionList={props.navLevelThreePromotionList}
        submenuProps={{
          isDesktop: props.isDesktop,
          accountDropdownProps: {
            ...props.accountDropdownProps,
          },
          ...props.submenu,
        }}
        clickOutsideDeactivates={true}
        sloti18nText={props.i18nText.slotText}
        slotConfig={props.headerSlots}
        responsiveFlyoutIcons={[
          {
            type: FlyoutIconTypes.STORE,
            value: props.responsiveFlyoutMenuIcons?.store,
          },
          {
            type: FlyoutIconTypes.WISHLIST,
            value: props.responsiveFlyoutMenuIcons?.wishlist,
          },
        ]}
        flyoutPosition={props.flyoutDirection}
        enableThreeTierNav={props.enableThreeTierNav}
        selectTopLevelNavigationItem={props.selectTopLevelNavigationItem}
        homeButtonOnClick={props.homeButtonOnClick}
        primaryNavImages={props.primaryNavImages}
        selectedTopLevelNavigationItemIndex={
          props.selectedTopLevelNavigationItemIndex
        }
        navigationOnClickCallback={props.navigationOnClickCallback}
        hasSelectedTopLevelItem={Boolean(
          props.enableThreeTierNav &&
            !props.hideThreeTierTab &&
            props.selectedTopLevelNavigationItemIndex !== undefined &&
            props.selectedTopLevelNavigationItemIndex !== -1,
        )}
        hideThreeTierTab={props.hideThreeTierTab}
        hideFocusOnLevelOneNav={props.hideFocusOnLevelOneNav}
      />
    </React.Fragment>
  )
}
