import * as React from 'react'

export const SvgCircularWhiteTiktok = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg width={40} height={41} viewBox="0 0 40 41" {...props}>
    <circle cx="20" cy="20.9297" r="20" fill="#FFFFFF" opacity="0.402739025" />
    <path
      d="M31 18.84C28.9356 18.8445 26.922 18.2 25.2438 16.9977V25.3797C25.2433 26.9321 24.7689 28.4474 23.884 29.7228C22.9991 30.9983 21.7459 31.9731 20.292 32.5169C18.8381 33.0608 17.2529 33.1477 15.7483 32.7661C14.2436 32.3845 12.8914 31.5525 11.8724 30.3815C10.8534 29.2104 10.2161 27.7561 10.046 26.2131C9.87577 24.6701 10.1807 23.1118 10.92 21.7468C11.6594 20.3818 12.7978 19.2751 14.1831 18.5747C15.5683 17.8743 17.1344 17.6136 18.6719 17.8274V22.0419C17.9689 21.8206 17.2139 21.8271 16.5148 22.0604C15.8157 22.2938 15.2082 22.742 14.779 23.3413C14.3499 23.9405 14.121 24.66 14.1251 25.3971C14.1291 26.1341 14.366 26.8511 14.8017 27.4455C15.2375 28.04 15.8499 28.4815 16.5516 28.7071C17.2532 28.9327 18.0082 28.9309 18.7087 28.7018C19.4092 28.4727 20.0194 28.0281 20.4522 27.4315C20.8851 26.8349 21.1183 26.1168 21.1188 25.3797V9H25.2438C25.2414 9.34891 25.2712 9.69729 25.3328 10.0407C25.4763 10.8063 25.7743 11.5345 26.2087 12.181C26.6431 12.8274 27.2048 13.3785 27.8594 13.8005C28.7911 14.4159 29.8834 14.7436 31 14.7428V18.84Z"
      fill="#FFFFFF"
      id="Tiktok"
    />
  </svg>
)
export default SvgCircularWhiteTiktok
