import * as React from 'react'
export const SvgOpenpay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={41} {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(9.5 10.829)" fillRule="nonzero">
        <rect fill="#FFB81C" width={41.2} height={20.6} rx={4} />
        <path
          d="M23.524 14.43v-1.321h1.292v1.32h-1.292zm-16.29 0v-1.321h1.291v1.32H7.234zm29.148 0v-1.321h1.291v1.32h-1.291zm.008-1.76v-.442a2.447 2.447 0 01-1.751-2.36V7.295h1.28v2.573a1.114 1.114 0 102.226 0V7.295h1.29v2.574a2.447 2.447 0 01-1.752 2.36v.442H36.39zm-12.866 0V9.688a2.596 2.596 0 012.558-2.618 2.564 2.564 0 012.405 1.715 2.656 2.656 0 01-.75 2.9c-.815.71-1.99.822-2.923.278v.706l-1.29.003zm1.28-2.99A1.298 1.298 0 0026.081 11a1.313 1.313 0 001.288-1.306 1.298 1.298 0 00-1.277-1.32h-.007a1.295 1.295 0 00-1.28 1.306zm-17.57 2.99V9.687A2.596 2.596 0 019.79 7.07a2.564 2.564 0 012.407 1.717 2.657 2.657 0 01-.75 2.898c-.814.71-1.99.821-2.923.277v.706l-1.291.003zm1.28-2.99A1.298 1.298 0 009.79 11a1.313 1.313 0 001.29-1.305 1.298 1.298 0 00-1.276-1.32h-.007A1.295 1.295 0 008.514 9.68zm-6.748.012a2.596 2.596 0 012.566-2.624A2.596 2.596 0 016.9 9.691a2.603 2.603 0 01-2.567 2.624 2.596 2.596 0 01-2.566-2.623zm1.283 0a1.295 1.295 0 001.283 1.314 1.312 1.312 0 000-2.624 1.295 1.295 0 00-1.283 1.31zm26.727 1.854a2.662 2.662 0 01-.412-3.149 2.54 2.54 0 012.86-1.24 2.608 2.608 0 011.924 2.496v2.53h-1.291v-.217a2.518 2.518 0 01-3.08-.422l-.001.002zm.9-2.784a1.32 1.32 0 00.916 2.24c.342.004.67-.132.91-.377.244-.247.38-.58.38-.927v-.045c-.02-.7-.589-1.26-1.29-1.27h-.012c-.34 0-.666.136-.904.38zm-15.408 3.549a2.59 2.59 0 01-2.56-2.414 2.613 2.613 0 012.144-2.8 2.568 2.568 0 012.905 1.96c.09.44.084.895-.02 1.333h-3.59l.022.034a1.295 1.295 0 001.752.43l.897.917c-.44.347-.983.536-1.543.537l-.007.003zm-1.152-3.27l-.018.032h2.338l-.015-.033a1.3 1.3 0 00-2.303 0h-.002zm7.642 3.137V9.522a1.115 1.115 0 10-2.229 0v2.656h-1.29V9.522a2.429 2.429 0 012.4-2.453 2.429 2.429 0 012.4 2.453v2.656h-1.28z"
          fill="#333332"
        />
      </g>
    </g>
  </svg>
)
export default SvgOpenpay
