import { Property } from 'csstype'

export enum Visibility {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

export interface BreakpointVisibility {
  xs: Visibility
  sm: Visibility
  md: Visibility
  lg: Visibility
}

export interface BorderBox {
  radius: string
  left: Border
  top: Border
  right: Border
  bottom: Border
}

export interface Border {
  display: boolean
  color: Property.Color
  width: string
}

export enum Order {
  one = '1',
  two = '2',
  three = '3',
  four = '4',
}

export enum Alignment {
  center = 'center',
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
}

export type Separator = {
  color: Property.Color
  width: string
}

export enum Orientation {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}
