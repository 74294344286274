import * as React from 'react'

import { styled, spacing } from '@thg-commerce/gravity-theme'
import { Skeleton } from '@thg-commerce/gravity-elements'

const Image = styled(Skeleton)`
  height: 144px;
  width: 144px;
  margin-bottom: ${spacing(3)};
`
const Badge = styled(Skeleton)`
  height: 24px;
  width: 56px;
  margin-bottom: ${spacing(1)};
`
const FreeGiftTitle = styled(Skeleton)`
  height: 24px;
  width: 407px;
  margin-bottom: ${spacing(1)};
`
const Price = styled(Skeleton)`
  height: 24px;
  width: 102px;
  margin-bottom: ${spacing(3)};
`
const Title = styled(Skeleton)`
  height: 32px;
  width: 407px;
  margin-bottom: ${spacing(1)};
`

const Description = styled(Skeleton)`
  width: 407px;
  height: 24px;
  margin-bottom: ${spacing(1)};
`

const Button = styled(Skeleton)`
  width: 156px;
  height: 46px;
  margin-top: ${spacing(1)};
`
export const MarketedSpecialOfferSkeleton = () => {
  return (
    <React.Fragment>
      <Image />
      <Badge />
      <FreeGiftTitle />
      <Price />
      <Title />
      <Description />
      <Description />
      <Description />
      <Button />
    </React.Fragment>
  )
}
