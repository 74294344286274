import * as React from 'react'
import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import loadable from '@loadable/component'
import { Property } from 'csstype'
import FocusTrap from 'focus-trap-react'
import styled from 'styled-components'

import { useTheme } from '@thg-commerce/gravity-elements/theme'
import { Grid, GridItem, useScrollLock } from '@thg-commerce/gravity-system'
import {
  BreakpointArray,
  BreakpointKey,
  mediaQueryRenderer,
  mq,
  spacing,
  Text,
} from '@thg-commerce/gravity-theme'
import { Margin } from '@thg-commerce/gravity-theme/margin'

const SvgClose = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Close'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { VerticalAlignment } from '../index'

import { ModalFooter } from './ModalFooter'
import { ModalHeader } from './ModalHeader'

const ModalBackground = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  background-color: ${(props) => props.theme.colors.palette.greys.darker}B2;
`

const ModalContentWrapper = styled.div`
  max-height: 100%;
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  display: flex;
  flex-direction: column;
`
const ModalContent = styled.div<{
  showFooter?: boolean
  showHeader?: boolean | boolean[]
  padding?: string
  showDividers?: boolean
  headerOffset?: number
  isAutoHeightMobile?: boolean
  isFullHeightDesktop?: boolean
  hideOverflowX?: boolean
  textAlign?: string
}>`
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: ${(props) => (props.isAutoHeightMobile ? 'auto' : '100%')};
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}

  ${(props) => props.hideOverflowX && 'overflow-x: hidden;'}
  ${(props) => ` background-color: ${props.theme.colors.palette.greys.white};`}
  ${(props) => `padding: ${
    props.padding ||
    `${spacing(props.showHeader ? 0 : 4)} ${spacing(3)} ${spacing(4)} ${spacing(
      3,
    )}`
  };
      `}
  ${(props) =>
    props.showHeader && props.showDividers
      ? 'border-top: solid 1px #ccd0d3;'
      : ''}
  
  ${(props) =>
    props.showFooter && props.showDividers
      ? 'border-bottom: solid 1px #ccd0d3;'
      : ''}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) =>
      props.headerOffset
        ? `max-height: calc(100% - ${props.headerOffset * 2}px)`
        : 'max-height: 100%;'}

    height: ${(props) => (props.isFullHeightDesktop ? '100%' : 'auto')};
  }
`

const StyledGrid = styled(Grid)<{ headerOffset?: number; animation?: boolean }>`
  max-width: ${(props) => props.theme.grid.maxWidth}px;
  margin: 0 auto;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-top: 0;
    ${(props) =>
      props.headerOffset
        ? `height: calc(100% - ${props.headerOffset / 2}px);`
        : 'height: 100%;'}
  }

  ${(props) =>
    props.headerOffset
      ? `
        margin-top: ${props.headerOffset}px;
        height: calc(100% - ${props.headerOffset}px);
      `
      : 'height: 100%;'}

  @keyframes slideInFromBelow {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  ${(props) =>
    mq(props.theme.breakpointUtils.map, 'xs') &&
    props.animation &&
    `animation: 300ms ease 0s 1 slideInFromBelow;`}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    animation: none;
  }
`

const StyledGridItem = styled(GridItem)<{
  verticalAlignment: VerticalAlignment[] | VerticalAlignment
  modalMaxWidth?: string
  height?: Property.Height<string>
  maxWidth?: string
  margin?: Margin
  textAlign?: string
}>`
  height: ${(props) => props.height || '100%'};
  margin-bottom: 0;
  display: flex;
  flex-direction: column;

  ${(props) =>
    typeof props.verticalAlignment === 'string'
      ? `justify-content: ${
          props.verticalAlignment === 'top'
            ? 'flex-start'
            : props.verticalAlignment === 'bottom'
            ? 'flex-end'
            : props.verticalAlignment
        };`
      : mediaQueryRenderer(
          props.verticalAlignment as BreakpointArray<VerticalAlignment>,
          (item) =>
            `justify-content: ${
              item === 'top'
                ? 'flex-start'
                : item === 'bottom'
                ? 'flex-end'
                : item
            };`,
        )}

  max-width: ${(props) =>
    props.modalMaxWidth ? `${props.modalMaxWidth}px` : `${props.maxWidth}`};
  margin-left: ${(props) => props.margin?.left};
  margin-right: ${(props) => props.margin?.right};
  text-align: ${(props) => props.textAlign};
`

const CloseButton = styled.button`
  margin: calc(${spacing(1)} - 2px);
  outline: none;
  border: 2px solid transparent;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:active svg path {
    fill: ${(props) => props.theme.colors.palette.greys.darker};
  }

  &:focus {
    border: 2px solid
      ${(props) =>
        props.theme.elements?.modal?.icon?.close?.focusBorderColor ||
        props.theme.colors.palette.brand.base};
  }

  ${(props) =>
    props.theme.elements?.modal?.icon?.close?.color &&
    `
  svg path {
    fill: ${props.theme.elements.modal.icon.close.color};
  }

  &:active svg path {
    fill: ${props.theme.colors.palette.accent.base};
  }
  `}
`

const AbsoluteCloseButton = styled(CloseButton)<{
  shouldShow?: boolean[]
}>`
  position: absolute;
  top: ${spacing(1)};
  right: 0;
  z-index: 90;

  ${(props) =>
    props.shouldShow
      ? props.shouldShow
          .map(
            (show, index) => `${mq(
              props.theme.breakpointUtils.map,
              props.theme.breakpointUtils.keys[index] as BreakpointKey,
            )} {
              display: ${show ? 'block' : 'none'};
            }`,
          )
          .join('')
      : `display: block;`}
`

const StyledCloseWrapper = styled.div`
  display: flex;
  padding: ${spacing(1)};
  align-items: center;
`

const StyledCloseText = styled.div`
  ${Text('bodyText', 'alternate')}
  padding-right: ${spacing(1)};

  ${(props) =>
    props.theme.elements?.modal?.icon?.close?.color &&
    `
  color: ${props.theme.elements.modal.icon.close.color};
  `}
`

const CloseIcon = ({ closeLabel }: { closeLabel?: string }) => {
  return (
    <StyledCloseWrapper>
      {closeLabel && <StyledCloseText>{closeLabel}</StyledCloseText>}
      <SvgClose />
    </StyledCloseWrapper>
  )
}

interface ModalI18nText {
  closeAriaLabel: string
  closeLabel?: string
}

interface ModalContentProps {
  showDividers?: boolean
}

export enum CloseEventType {
  'CLOSE_BUTTON',
  'UNDERLAY',
}

export interface ModalProps {
  /** The content of the modal */
  children: React.ReactNode
  /** If the model should be open or not */
  open: boolean
  /** "dialogalert" should be used when the users attention is immediately required */
  role?: 'dialog' | 'dialogalert'
  /** Should be used if the content does not contain a title */
  'aria-label'?: string
  /** Should be used to specify the ID of the title */
  'aria-labelledby'?: string
  /** Callback function when the modal is closed */
  onClose: () => void
  /** Callback function when the close modal button is clicked */
  onCloseEventEmitter?: (eventType?: CloseEventType) => void
  /** Used to return keyboard focus to the opening element */
  returnToRef?: React.RefObject<HTMLElement>
  'data-testid'?: string
  i18nText: ModalI18nText
  /** Used to account for header offset when using modals */
  headerOffset?: number
  /** Used to a deactivate the focus trap and allow the click event to do its thing. **/
  clickOutsideDeactivates?: boolean
  /** If false when modal closes it will not remove the scroll lock, default true **/
  shouldRemoveScrollLock?: boolean
  /** Used to display header and footer of modal */
  showHeader?: boolean | boolean[]
  showFooter?: boolean
  /** Sets whether the header is sticky or not - only has meaning when showHeader is true */
  stickyHeader?: boolean
  /** Text for cancel and confirm buttons */
  buttonText?: {
    confirmButton: string
    cancelButton: string
  }
  contentPadding?: string
  /** Actions for buttons */
  confirmAction?: () => void
  cancelAction?: () => void
  leftAlignButtons?: boolean
  removeCloseButton?: boolean
  modalMaxWidth?: string
  footerPadding?: string
  /** Props to configure the modal content section */
  content?: ModalContentProps
  verticalAlignment?: VerticalAlignment[] | VerticalAlignment
  /** Define number of columns the modal should span */
  gridColSpan: number[]
  /** Sets the height to auto if true on mobile otherwise 100% */
  isAutoHeightMobile?: boolean
  isFullHeightDesktop?: boolean
  gridRowStart?: number[]
  gridRowSpan?: number[]
  hideOverflowX?: boolean
  removeFocusTrap?: boolean
  allowOverflow?: boolean
  gridItemHeight?: Property.Height<string>
  animation?: boolean
  maxWidth?: string
  margin?: Margin
  textAlign?: string
}

/** @component */
export const Modal = (props: ModalProps) => {
  const {
    children,
    open,
    role = 'dialog',
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    onClose,
    onCloseEventEmitter,
    returnToRef,
    'data-testid': dataTestId,
    stickyHeader,
    shouldRemoveScrollLock = true,
    gridColSpan,
    gridRowSpan,
    gridRowStart,
    verticalAlignment = ['top', 'top', 'top', 'top'],
    gridItemHeight,
    animation,
  } = props

  const contentRef = React.createRef<HTMLDivElement>()
  const headerRef = React.createRef<HTMLDivElement>()
  const footerRef = React.createRef<HTMLDivElement>()
  const closeButtonRef = React.createRef<HTMLButtonElement>()
  const setScrollLock = useScrollLock()
  const theme = useTheme()
  const documentType = typeof document
  const windowType = typeof window

  const toggleScrollLock = React.useMemo(() => {
    return (valueToToggle: boolean) => {
      shouldRemoveScrollLock &&
        documentType !== 'undefined' &&
        windowType !== 'undefined' &&
        setScrollLock(valueToToggle, document, window)
    }
  }, [windowType, documentType, setScrollLock, shouldRemoveScrollLock])

  const clickAway = (e: React.MouseEvent<HTMLElement>) => {
    if (contentRef.current && contentRef.current.contains(e.target as Node)) {
      return
    }
    if (headerRef.current && headerRef.current.contains(e.target as Node)) {
      return
    }
    if (footerRef.current && footerRef.current.contains(e.target as Node)) {
      return
    }
    toggleScrollLock(false)
    onCloseEventEmitter && onCloseEventEmitter(CloseEventType.UNDERLAY)
    onClose()
  }
  React.useEffect(() => {
    return () => {
      toggleScrollLock(false)
    }
  }, [closeButtonRef, returnToRef, toggleScrollLock])

  const closeWithKeyPress = (event) => {
    if (event.key === 'Escape') {
      toggleScrollLock(false)
      onClose()
    }
    event.stopPropagation()
  }

  useEffect(() => {
    open && closeButtonRef.current && closeButtonRef.current.focus()
    !open && returnToRef && returnToRef.current && returnToRef.current.focus()
    toggleScrollLock(open)
  }, [open, closeButtonRef, returnToRef, toggleScrollLock])

  const renderIcon = () => {
    if (theme.elements.modal.icon.close.svgPath) {
      return (
        <React.Fragment>
          <StyledCloseWrapper>
            {props.i18nText.closeLabel && (
              <StyledCloseText>{props.i18nText.closeLabel}</StyledCloseText>
            )}
            <SvgIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox={theme.elements.modal.icon.viewBox}
              width={theme.elements.modal.icon.width}
              height={theme.elements.modal.icon.height}
            >
              <path
                d={theme.elements.modal.icon.close.svgPath}
                fillRule="evenodd"
              />
            </SvgIcon>
          </StyledCloseWrapper>
        </React.Fragment>
      )
    }
    return <CloseIcon closeLabel={props.i18nText.closeLabel} />
  }

  const renderModalFooter = () => {
    if (props.showFooter) {
      return (
        <ModalFooter
          onClose={props.onClose}
          buttonText={props.buttonText}
          confirmAction={props.confirmAction}
          cancelAction={props.cancelAction}
          leftAlignButtons={props.leftAlignButtons}
          footerPadding={props.footerPadding}
          ref={footerRef}
        />
      )
    }
    return null
  }

  const renderModalBackground = (calculateColStart) => {
    return (
      <ModalBackground
        aria-modal="true"
        tabIndex={-1}
        role={role}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        onClick={clickAway}
        data-testid={dataTestId || 'modal'}
      >
        <StyledGrid
          headerOffset={props.headerOffset}
          rows={12}
          animation={animation}
        >
          <StyledGridItem
            colStart={calculateColStart(gridColSpan)}
            colSpan={gridColSpan}
            rowStart={gridRowStart ?? [1, 2, 2, 2]}
            rowSpan={gridRowSpan ?? [12, 10, 10, 10]}
            verticalAlignment={verticalAlignment}
            modalMaxWidth={props.modalMaxWidth}
            height={gridItemHeight}
            maxWidth={props.maxWidth}
            margin={props.margin}
          >
            <ModalContentWrapper>
              {props.showHeader && (
                <ModalHeader
                  sticky={stickyHeader}
                  ref={headerRef}
                  data-testid={`${dataTestId || 'modal'}-header`}
                  closeButton={
                    <CloseButton
                      aria-label={props.i18nText.closeAriaLabel}
                      ref={closeButtonRef}
                      onKeyDown={closeWithKeyPress}
                      onClick={() => {
                        onCloseEventEmitter &&
                          onCloseEventEmitter(CloseEventType.CLOSE_BUTTON)
                        toggleScrollLock(false)
                        onClose()
                      }}
                      data-testid={`${dataTestId || 'modal'}-close-button`}
                    >
                      {renderIcon()}
                    </CloseButton>
                  }
                  shouldShow={props.showHeader}
                />
              )}
              <ModalContent
                {...props.content}
                hideOverflowX={props.hideOverflowX}
                ref={contentRef}
                onKeyDown={closeWithKeyPress}
                showHeader={props.showHeader}
                showFooter={props.showFooter}
                data-testid={`${dataTestId || 'modal'}-content`}
                padding={props.contentPadding}
                isAutoHeightMobile={props.isAutoHeightMobile}
                isFullHeightDesktop={props?.isFullHeightDesktop}
                textAlign={props.textAlign}
              >
                {((Array.isArray(props.showHeader) &&
                  props.showHeader.some((show) => show === false)) ||
                  (!props.showHeader && !props.removeCloseButton)) && (
                  <AbsoluteCloseButton
                    aria-label={props.i18nText.closeAriaLabel}
                    ref={closeButtonRef}
                    onClick={() => {
                      onCloseEventEmitter &&
                        onCloseEventEmitter(CloseEventType.CLOSE_BUTTON)
                      toggleScrollLock(false)
                      onClose()
                    }}
                    data-testid={`${dataTestId || 'modal'}-close-button`}
                    shouldShow={
                      props.showHeader
                        ? Array.isArray(props.showHeader)
                          ? props.showHeader.map((show) => !show)
                          : [!props.showHeader]
                        : undefined
                    }
                  >
                    <CloseIcon closeLabel={props.i18nText.closeLabel} />
                  </AbsoluteCloseButton>
                )}
                {children}
              </ModalContent>
              {renderModalFooter()}
            </ModalContentWrapper>
          </StyledGridItem>
        </StyledGrid>
      </ModalBackground>
    )
  }

  if (open) {
    const calculateColStart = (colSpan: number[]): number[] => {
      return colSpan.map((span) => Math.ceil((12 - span) / 2) + 1)
    }

    return ReactDOM.createPortal(
      props.removeFocusTrap ? (
        renderModalBackground(calculateColStart)
      ) : (
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: props.clickOutsideDeactivates,
            escapeDeactivates: false,
          }}
        >
          {renderModalBackground(calculateColStart)}
        </FocusTrap>
      ),
      document.body,
    )
  }
  return null
}

export default Modal
