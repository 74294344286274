import * as React from 'react'

import { styled, spacing, mq } from '@thg-commerce/gravity-theme'
import { ProductImage } from '@thg-commerce/gravity-elements'
import { Separator } from '../ClickAndCollectModalSearchResults/styles'

export interface FooterProps {
  image: string
  title: string
}

const FooterWrapper = styled.div`
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-top: ${spacing(2)};
    display: flex;
    justify-content: flex-start;
  }
`

export const Footer = ({ image, title }: FooterProps) => {
  return (
    <div style={{ marginTop: spacing(2) }}>
      <Separator topMargin={4} bottomMargin={3} />
      <FooterWrapper>
        <ProductImage
          alt={title}
          urls={{
            largeProduct: image,
          }}
          width={[73]}
          height={[73]}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ marginLeft: spacing(3) }}>{title}</p>
        </div>
      </FooterWrapper>
    </div>
  )
}
