import * as React from 'react'
export const SvgBlackTikTok = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="tik-tok-social_svg__globalSocialIcon_svg"
    aria-label="TikTok"
    width={40}
    height={40}
    fill="none"
    viewBox="2 10 65 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#000"
      d="M15.166 33.147c3.338 0 6.043-2.39 6.043-6.043V0h7.247c-.406 4.943 4.508 9.718 9.927 9.609v6.815c-4.635 0-8.575-1.96-9.947-3.047v13.727c0 6.043-4.754 12.896-13.27 12.896S2 33.147 2 27.104c0-8.529 9.026-13.476 15.004-12.267v6.942c-.3-.107-1.076-.264-1.737-.264-3.365-.124-6.144 2.605-6.144 5.59a6.043 6.043 0 0 0 6.043 6.042Z"
    />
  </svg>
)
export default SvgBlackTikTok
