import { Property } from 'csstype'

import { SpacingBox } from '@thg-commerce/gravity-theme'

export interface ProductDescriptionAccordionThemeInterface {
  defaultItemsOpen: number
  details: {
    backgroundColor?: Property.Color
    title: {
      padding: SpacingBox
    }
    item: {
      display: string
      padding: SpacingBox
      margin?: SpacingBox
    }
    showProductDetailsAsList: boolean
  }
  container: {
    padding: SpacingBox
  }
  deliveryAndReturnsInfo: {
    margin: SpacingBox
  }
}

export const theme: ProductDescriptionAccordionThemeInterface = {
  defaultItemsOpen: 1,
  details: {
    title: {
      padding: {
        top: 1,
        right: 2,
        bottom: 1,
        left: 2,
      },
    },
    item: {
      display: 'flex',
      padding: {
        top: 1,
        right: 1,
        bottom: 1,
        left: 2,
      },
    },
    showProductDetailsAsList: false,
  },
  container: {
    padding: {
      top: 2,
      right: 1,
      bottom: 3,
      left: 1,
    },
  },
  deliveryAndReturnsInfo: {
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 2,
    },
  },
}
