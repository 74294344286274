export interface ClientBehaviours {
  override: boolean // override client and use client-side only resolver (mocks)
  sitePropertiesOverride: boolean // override site properties and use client-side only properties
  emptyWidgets: boolean
  socialLoginServiceState: (
    type: 'QUERY' | 'MUTATION',
  ) =>
    | 'token'
    | 'moreInfo'
    | 'marketingOnly'
    | 'linkPending'
    | 'invalidToken'
    | null
    | undefined
  socialLoginVerificationLinkRequestStatus: 'success' | 'failed'
  accountCreationMarketingPreferences:
    | 'DOES_NOT_RECEIVE_MARKETING_MATERIAL'
    | 'CUSTOMER_ALREADY_EXISTS'
    | 'RECEIVES_MARKETING_MATERIAL'
  marketingPreferences:
    | 'DOES_NOT_RECEIVE_MARKETING_MATERIAL'
    | 'RECEIVES_MARKETING_MATERIAL'
  contentReturnType: 'POST' | 'CATEGORY' | 'TAG' | 'AUTHOR'
}

interface RuntimeConfig {
  siteDefinition?: { GRAPHQL_SERVER_URI?: string }
  GRAPHQL_CLIENT_OVERRIDE?: boolean
  GRAPHQL_SERVER_URI?: string
  SITE_PROPERTIES_OVERRIDE?: boolean
  FORCE_EMPTY_WIDGET_DATA?: boolean
  SOCIAL_LOGIN_STATE_QUERY?:
    | 'token'
    | 'moreInfo'
    | 'marketingOnly'
    | 'linkPending'
    | 'invalidToken'
  SOCIAL_LOGIN_STATE_MUTATION?:
    | 'token'
    | 'moreInfo'
    | 'marketingOnly'
    | 'linkPending'
    | 'invalidToken'
  SOCIAL_LOGIN_VERIFICATION_LINK_REQUEST?: 'success' | 'failed'
  ACCOUNT_CREATION_MARKETING_PREFERENCES?:
    | 'DOES_NOT_RECEIVE_MARKETING_MATERIAL'
    | 'CUSTOMER_ALREADY_EXISTS'
    | 'RECEIVES_MARKETING_MATERIAL'
  MARKETING_PREFERENCES?:
    | 'DOES_NOT_RECEIVE_MARKETING_MATERIAL'
    | 'RECEIVES_MARKETING_MATERIAL'
  CONTENT_RETURN_TYPE?: 'post' | 'category' | 'tag' | 'author'
  APOLLO_TIMEOUT?: string
  APOLLO_ENABLE_RETRIES?: boolean
}

/**
 * Provides values for how enterprise should handle Apollo Client
 * when used within client-side resovlers
 */
export const clientConfig = (
  config: RuntimeConfig,
  enableOverrides: boolean = false,
  enableSitePropertyOverrides: boolean = false,
  enableVary = false,
  productBlockContentKeys = [''],
): {
  enableVary: boolean
  productBlockContentKeys: string[]
  timeout: number
  enableRetries?: boolean
  behaviours: ClientBehaviours
} => {
  return {
    enableVary,
    productBlockContentKeys,
    timeout: parseInt(
      config.APOLLO_TIMEOUT ? config.APOLLO_TIMEOUT : '15000',
      10,
    ),
    enableRetries: config.APOLLO_ENABLE_RETRIES,
    behaviours: {
      override: enableOverrides && OverrideClient(config),
      sitePropertiesOverride:
        (enableOverrides || enableSitePropertyOverrides) &&
        OverrideSiteProperties(config),
      socialLoginServiceState: (type: 'QUERY' | 'MUTATION') => {
        return AssignSocialLoginServiceState(config, type)
      },
      socialLoginVerificationLinkRequestStatus: AssignSocialLoginVerificationLinkRequestStatus(
        config,
      ),
      accountCreationMarketingPreferences: AssignAccountCreationMarketingPreferences(
        config,
      ),
      marketingPreferences: MarketingPreferences(config),
      emptyWidgets: ReturnEmptyWidgets(config),
      contentReturnType: ContentSlugReturnType(config),
    },
  }
}

const ReturnEmptyWidgets = ({ FORCE_EMPTY_WIDGET_DATA }: RuntimeConfig) => {
  return FORCE_EMPTY_WIDGET_DATA || false
}

export const OverrideClient = ({
  GRAPHQL_CLIENT_OVERRIDE,
  siteDefinition,
}: RuntimeConfig): boolean => {
  if (GRAPHQL_CLIENT_OVERRIDE) {
    return GRAPHQL_CLIENT_OVERRIDE
  }

  if (
    !GRAPHQL_CLIENT_OVERRIDE &&
    process.env.NODE_ENV !== 'production' &&
    (typeof siteDefinition?.GRAPHQL_SERVER_URI === 'undefined' ||
      siteDefinition?.GRAPHQL_SERVER_URI === '')
  ) {
    return true
  }

  return false
}

export const OverrideSiteProperties = ({
  SITE_PROPERTIES_OVERRIDE,
}: RuntimeConfig): boolean => {
  if (SITE_PROPERTIES_OVERRIDE) {
    return SITE_PROPERTIES_OVERRIDE
  }

  return false
}

export const AssignSocialLoginVerificationLinkRequestStatus = ({
  SOCIAL_LOGIN_VERIFICATION_LINK_REQUEST,
}: RuntimeConfig): 'success' | 'failed' => {
  if (SOCIAL_LOGIN_VERIFICATION_LINK_REQUEST) {
    return SOCIAL_LOGIN_VERIFICATION_LINK_REQUEST
  }

  return 'success'
}

export const AssignSocialLoginServiceState = (
  { SOCIAL_LOGIN_STATE_QUERY, SOCIAL_LOGIN_STATE_MUTATION }: RuntimeConfig,
  type: 'QUERY' | 'MUTATION',
) => {
  if (SOCIAL_LOGIN_STATE_QUERY && type === 'QUERY') {
    return SOCIAL_LOGIN_STATE_QUERY
  }

  if (SOCIAL_LOGIN_STATE_MUTATION && type === 'MUTATION') {
    return SOCIAL_LOGIN_STATE_MUTATION
  }

  return null
}

export const AssignAccountCreationMarketingPreferences = ({
  ACCOUNT_CREATION_MARKETING_PREFERENCES,
}: RuntimeConfig) => {
  return ACCOUNT_CREATION_MARKETING_PREFERENCES
    ? ACCOUNT_CREATION_MARKETING_PREFERENCES
    : 'DOES_NOT_RECEIVE_MARKETING_MATERIAL'
}

export const MarketingPreferences = ({
  MARKETING_PREFERENCES,
}: RuntimeConfig) => {
  return MARKETING_PREFERENCES
    ? MARKETING_PREFERENCES
    : 'DOES_NOT_RECEIVE_MARKETING_MATERIAL'
}

export const ContentSlugReturnType = ({
  CONTENT_RETURN_TYPE,
}: RuntimeConfig): 'POST' | 'CATEGORY' | 'TAG' | 'AUTHOR' => {
  switch (CONTENT_RETURN_TYPE) {
    case 'category':
      return 'CATEGORY'
    case 'tag':
      return 'TAG'
    case 'author':
      return 'AUTHOR'
    default:
      return 'POST'
  }
}

const fieldFromAST = (ast) => {
  let query = ``
  if (
    ast.kind === 'Field' &&
    ast.name.kind === 'Name' &&
    ast.name.value.includes('__') === false
  ) {
    const fieldName = ast.name.value
    query += `${fieldName} \n`
    if (
      ast.hasOwnProperty('selectionSet') &&
      typeof ast.selectionSet !== 'undefined'
    ) {
      if (ast.selectionSet.kind === 'SelectionSet') {
        query += `{ \n`
        ast.selectionSet.selections.forEach(
          (selection) => (query += fieldFromAST(selection)),
        )
        query += `} \n`
      }
    }
  }

  if (ast.kind === 'InlineFragment' && ast.typeCondition.kind === 'NamedType') {
    const fieldName = ast.typeCondition.name.value
    query += `... on ${fieldName} \n`
    if (
      ast.hasOwnProperty('selectionSet') &&
      typeof ast.selectionSet !== 'undefined'
    ) {
      if (ast.selectionSet.kind === 'SelectionSet') {
        query += `{ \n`
        ast.selectionSet.selections.forEach(
          (selection) => (query += fieldFromAST(selection)),
        )
        query += `} \n`
      }
    }
  }

  return query
}

import { gql } from 'apollo-boost'

export const queryFromInfo = (info, rootField, fallbackQuery) => {
  if (info.hasOwnProperty('field')) {
    if (info.field.kind === 'Field' && info.field.name.kind === 'Name') {
      const rootFieldName = info.field.name.value
      let query = `{ ${rootField} { ${rootFieldName} { \n`
      if (
        info.field.hasOwnProperty('selectionSet') &&
        info.field.selectionSet.kind === 'SelectionSet'
      ) {
        info.field.selectionSet.selections.forEach(
          (selection) => (query += fieldFromAST(selection)),
        )
      }

      query += `} \n } \n }`

      return gql(query)
    }
  }

  return fallbackQuery
}
