type originInfoType = { urlParams: { [key: string]: string }; referrer: string }

export const getOriginObject = (originInfo: originInfoType) => {
  const origin = {}
  origin['referrer'] = originInfo.referrer || ''
  originInfo.urlParams['gclid'] &&
    (origin['gclid'] = originInfo.urlParams['gclid'])
  originInfo.urlParams['utm_medium'] &&
    (origin['medium'] = originInfo.urlParams['utm_medium'])
  originInfo.urlParams['utm_source'] &&
    (origin['source'] = originInfo.urlParams['utm_source'])
  originInfo.urlParams['utm_campaign'] &&
    (origin['campaign'] = originInfo.urlParams['utm_campaign'])
  originInfo.urlParams['thg_ppc_campaign'] &&
    (origin['thg_ppc_campaign'] = originInfo.urlParams['thg_ppc_campaign'])
  originInfo.urlParams['utm_keyword'] &&
    (origin['keyword'] = originInfo.urlParams['utm_keyword'])
  originInfo.urlParams['affil'] &&
    (origin['channel'] = originInfo.urlParams['affil'])
  originInfo.urlParams['keyword'] &&
    (origin['match_type'] = originInfo.urlParams['keyword'])
  originInfo.urlParams['widget_id'] &&
    (origin['widget_id'] = originInfo.urlParams['widget_id'])
  originInfo.urlParams['sendTime'] &&
    (origin['email_send_time'] = originInfo.urlParams['sendTime'])
  originInfo.urlParams['shoppingpid'] &&
    (origin['gsi'] = {
      offer_ids: originInfo.urlParams['shoppingpid'].split(','),
    })
  return origin
}
