import * as React from 'react'

import { CSSProperties } from 'styled-components'
import { motion, HTMLMotionProps } from 'framer-motion'

import { isProxyAvailable } from '../utils'

export const MotionUnorderedList = React.forwardRef(
  (
    props: HTMLMotionProps<'ul'> & { ieOnlyStyles?: CSSProperties },
    ref: any,
  ) => {
    return (
      <React.Fragment>
        {isProxyAvailable ? (
          <motion.ul ref={ref} {...props} />
        ) : (
          <ul
            ref={ref}
            style={props.ieOnlyStyles ?? (props.style as CSSProperties)}
          >
            {props.children}
          </ul>
        )}
      </React.Fragment>
    )
  },
)
