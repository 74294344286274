import { MetaSiteObject } from '../'

export const getMetaObject = (
  siteDefinition,
  currentLocation,
  subsite,
  metricNonce,
): MetaSiteObject => {
  const location =
    currentLocation !== 'unknown'
      ? currentLocation
      : siteDefinition?.defaultLocale?.split('_')[1] ?? 'unknown'
  return {
    nonce: metricNonce,
    URL: window.location.href,
    siteDefaultLocale: siteDefinition.defaultLocale,
    countryCode: location.toUpperCase(),
    currency: siteDefinition.defaultCurrency,
    customerLocale: `${subsite}_${location}`,
    customerLocation: location,
    shippingCountry: siteDefinition?.defaultLocale?.split('_')[1] ?? 'unknown', // This in ELY1 comes from some code in ExperienceFactory which  is not simlple so using customer location for now
    elysiumVersion: '2.0',
    serverIP: 'x.x.x.x',
    siteCode: siteDefinition.siteCode,
    subsiteCode: subsite,
    siteID: parseInt(siteDefinition.siteId, 10),
    productID: '',
  }
}
