import * as React from 'react'
export const SvgCircularWhiteYouTube = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg width={40} height={40} {...props}>
    <g
      id="social-icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Artboard"
        transform="translate(-605.000000, -397.000000)"
        fill="#FFFFFF"
      >
        <g id="youtube" transform="translate(605.000000, 397.000000)">
          <circle
            id="Oval-2-Copy"
            opacity="0.402739025"
            cx="20"
            cy="20"
            r="20"
          ></circle>
          <path
            fill="#fff"
            fill-rule="evenodd"
            d="M20.708.476a2.864 2.864 0 0 1 2.017 2.022C23.2 4.28 23.2 8 23.2 8s0 3.72-.475 5.502a2.864 2.864 0 0 1-2.017 2.022C18.931 16 11.8 16 11.8 16s-7.127 0-8.908-.476a2.864 2.864 0 0 1-2.017-2.022C.4 11.72.4 8 .4 8s0-3.72.475-5.502A2.864 2.864 0 0 1 2.892.476C4.672 0 11.8 0 11.8 0s7.13 0 8.908.476ZM15.445 8l-5.923 3.43V4.57L15.445 8Z"
            clip-rule="evenodd"
            transform="translate(8, 11)"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default SvgCircularWhiteYouTube
