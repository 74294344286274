import * as React from 'react'
export const SvgIconError = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fill={props.fill || '#c42c21'}
      d="M12 1l12 22H0L12 1zm1 8h-2v7h2V9zm-1 8c-.863 0-1.5.65-1.5 1.494 0 .856.637 1.506 1.5 1.506s1.5-.65 1.5-1.506C13.5 17.65 12.863 17 12 17z"
    />
  </svg>
)
export default SvgIconError
