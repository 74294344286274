import * as React from 'react'
export const SvgMessage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 4.562l-2.314-2.314 1.157-1.157 2.314 2.314-1.157 1.157zm-.771.771L18.663 3.02l-8.389 8.39 2.315 2.313 8.389-8.389zm-12.053 9.74l2.892-.58-2.314-2.313-.578 2.892zM1.09 22.908h18.545V11.455h-2.181v9.272H3.272V6.545h9.273V4.364H1.09v18.545z"
      fill={props.fill || '#333'}
    />
  </svg>
)
export default SvgMessage
