export type Direction = 'row' | 'column'
export type Directions = Direction[]
export type HorizontalAlignment = 'left' | 'center' | 'right'
export type FlexAlignments =
  | 'center'
  | 'start'
  | 'flex-start'
  | 'end'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'

export enum Order {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
}

export enum ProductBlockComponents {
  TITLE_LABEL = 'titleLabel',
  IMAGE_CONTAINER = 'imageContainer',
  PRODUCT_REVIEW = 'productReview',
  POWER_REVIEW_CATEGORY_SNIPPET = 'powerReviewCategorySnippet',
  MARKETED_SPECIAL_OFFER = 'marketedSpecialOffer',
  SWATCH = 'swatch',
  ICONS_PRICE = 'iconsPrice',
  DESCRIPTION = 'description',
  OPTIONS = 'options',
  PRODUCT_BUTTON = 'productButton',
  SPONSORED_LABEL = 'sponsoredLabel',
  SMALL_QUICKBUY_BUTTON = 'smallQuickbuyButton',
  INLINE_QUICKBUY_BUTTON = 'inlineQuickbuyButton',
  WISHLIST_BUTTON = 'wishlistButton',
}
