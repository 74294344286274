import * as React from 'react'

import {
  styled,
  Text,
  spacing,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'
import { RenderAnnouncerType } from '../'

export interface ToastBannerProps {
  text: string
  renderAnnouncer: RenderAnnouncerType
  'data-testid'?: string
  bannerType?: ToastBannerType
}

export enum ToastBannerType {
  SUCCESS,
  DANGER,
}

const Message = styled.p`
  ${Text('bodyText', 'alternate')};
  margin: ${spacing(4)};
  color: ${(props) => props.theme.colors.success.base};
`

const MessageContainer = styled.div<{ bannerType: ToastBannerType }>`
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  background: ${(props) => {
    switch (props.bannerType) {
      case ToastBannerType.DANGER:
        return props.theme.colors.error.light
      case ToastBannerType.SUCCESS:
      default:
        return props.theme.colors.success.light
    }
  }};
  ${zIndex(ZIndexLevel.Higher)};

  ${Message} {
    color: ${(props) => {
      switch (props.bannerType) {
        case ToastBannerType.DANGER:
          return props.theme.colors.error.base
        case ToastBannerType.SUCCESS:
        default:
          return props.theme.colors.success.base
      }
    }};
  }
`

export const ToastBanner = (props: ToastBannerProps) => {
  return (
    <MessageContainer
      data-testid={props['data-testid']}
      bannerType={props.bannerType || ToastBannerType.SUCCESS}
    >
      <Message data-testid="toast-banner-message">{props.text}</Message>
      {props.renderAnnouncer('assertive', props.text)}
    </MessageContainer>
  )
}
