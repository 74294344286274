import * as React from 'react'
export const SvgBell = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4.44045C14 4.7337 13.8783 5.00648 13.6694 5.23404C16.1731 5.95574 18.0043 8.26401 18.0043 11V14.0045L20 19H15C15 20.6569 13.6569 22 12 22C10.4023 22 9.09634 20.7511 9.00509 19.1763L9 19H4L6.00433 14.0045V11C6.00433 8.2665 7.83227 5.95994 10.3325 5.23602C10.1224 5.00806 10 4.73455 10 4.44045C10 3.64491 10.8954 3 12 3C13.1046 3 14 3.64491 14 4.44045ZM11 19C11 19.5523 11.4477 20 12 20C12.5128 20 12.9355 19.614 12.9933 19.1166L13 19H11ZM8.00922 10.8004C8.11325 8.68397 9.86213 7 12.0043 7L12.204 7.0049C14.3204 7.10892 16.0043 8.8578 16.0043 11V14.3892L17.047 17H6.957L8.00433 14.3908V11L8.00922 10.8004Z"
      fill={props.fill || '#FFFFFF'}
    />
  </svg>
)
export default SvgBell
