import * as React from 'react'

export type SubscribableRef<T> = {
  value: [() => T, (value: T) => void] // [ getValue, setValue ]
  subscribe: (subscription: (value: T) => void) => void
  unsubscribe: (subscription: (value: T) => void) => void
}

export const createSubscribableRef = <T>(
  defaultValue: T,
): SubscribableRef<T> => {
  const ref = React.useRef(defaultValue)
  const subscriptions = React.useRef<((value: T) => void)[]>([])

  const getValue = (): T => {
    return ref.current
  }

  const setValue = (value: T): void => {
    ref.current = value
    subscriptions.current.forEach((subscription) => subscription(value))
  }

  const subscribe = (subscription: (value: T) => void) => {
    subscriptions.current.push(subscription)
  }

  const unsubscribe = (subscription: (value: T) => void) => {
    const index = subscriptions.current.indexOf(subscription)
    if (index > -1) {
      subscriptions.current.splice(index, 1)
    }
  }

  return { subscribe, unsubscribe, value: [getValue, setValue] }
}

export const useSubscribableRef = <T>(subscribableRef: SubscribableRef<T>) => {
  const {
    value: [currentValue],
    subscribe,
    unsubscribe,
  } = subscribableRef
  const [value, setValue] = React.useState(currentValue)

  React.useEffect(() => {
    setValue(currentValue)
    const callback = (newValue: T) => setValue(newValue)
    subscribe(callback)
    return () => {
      unsubscribe(callback)
    }
  }, [])

  return value
}
