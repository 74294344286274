import { colors } from '../colors'
import { Property } from 'csstype'
import {
  TextEntry,
  TextStyle,
  TextTransform,
} from '@thg-commerce/gravity-theme'

export interface ShadowInterface {
  shadowX: string
  shadowY: string
  shadowBlur: string
  shadowSpread: string
  shadowColor: string
  shadowOpacity: string
}

export interface StateInterface {
  backgroundColor: Property.BackgroundColor
  color: Property.Color
  borderStyle: Property.BorderStyle
  borderWidth: Property.BorderWidth<string>
  borderColor: Property.BorderColor
  borderType: string
  borderRadius: Property.BorderRadius<string>
  boxShadow: ShadowInterface
  height: Property.Height<string>
  width: Property.Width<string>
  verticalPadding: Property.Padding<string>
  horizontalPadding: Property.Padding<string>
  textDecoration: Property.TextDecoration<string>
  style: TextStyle
}
export interface SizeInterface {
  default: StateInterface
  hover: StateInterface
  active: StateInterface
  focus: StateInterface
  disabled: StateInterface
}
export interface EmphasisInterface {
  common: CommonButtonInterface
  maxi: SizeInterface
  regular: SizeInterface
  micro: SizeInterface
}

export interface TextInterface {
  entry: TextEntry
  transform: TextTransform
}

export interface CommonButtonInterface {
  font: TextInterface
}

export interface ButtonInterface {
  high: EmphasisInterface
  medium: EmphasisInterface
  low: EmphasisInterface
}

export const button: ButtonInterface = {
  high: {
    common: {
      font: {
        entry: 'bodyText',
        transform: 'none',
      },
    },
    maxi: {
      default: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.base,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.base,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '20px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      hover: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.light,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '20px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      active: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '20px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      disabled: {
        style: 'alternate',
        backgroundColor: colors.palette.greys.light,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.greys.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '20px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      focus: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: colors.palette.greys.white,
        borderType: 'outline',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '20px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
    },
    regular: {
      default: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.base,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.base,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '12px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      hover: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.light,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '12px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      active: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '12px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      disabled: {
        style: 'alternate',
        backgroundColor: colors.palette.greys.light,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.greys.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '12px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      focus: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: colors.palette.greys.white,
        borderType: 'outline',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '12px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
    },
    micro: {
      default: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.base,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.base,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '4px',
        horizontalPadding: '16px',
        textDecoration: 'none',
      },
      hover: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.light,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '4px',
        horizontalPadding: '16px',
        textDecoration: 'none',
      },
      active: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '4px',
        horizontalPadding: '16px',
        textDecoration: 'none',
      },
      disabled: {
        style: 'alternate',
        backgroundColor: colors.palette.greys.light,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.greys.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '4px',
        horizontalPadding: '16px',
        textDecoration: 'none',
      },
      focus: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '4px',
        horizontalPadding: '16px',
        textDecoration: 'none',
      },
    },
  },
  medium: {
    common: {
      font: {
        entry: 'bodyText',
        transform: 'none',
      },
    },
    maxi: {
      default: {
        style: 'alternate',
        backgroundColor: colors.palette.greys.white,
        color: colors.palette.brand.base,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.base,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '20px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      hover: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.light,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '20px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      active: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '20px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      disabled: {
        style: 'alternate',
        backgroundColor: colors.palette.greys.white,
        color: colors.palette.greys.light,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.greys.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '20px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      focus: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '20px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
    },
    regular: {
      default: {
        style: 'alternate',
        backgroundColor: colors.palette.greys.white,
        color: colors.palette.brand.base,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.base,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '12px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      hover: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.light,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '12px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      active: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '12px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      disabled: {
        style: 'alternate',
        backgroundColor: colors.palette.greys.white,
        color: colors.palette.greys.light,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.greys.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '12px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
      focus: {
        style: 'alternate',
        backgroundColor: colors.palette.greys.white,
        color: colors.palette.brand.darker,
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '12px',
        horizontalPadding: '40px',
        textDecoration: 'none',
      },
    },
    micro: {
      default: {
        style: 'alternate',
        backgroundColor: colors.palette.greys.white,
        color: colors.palette.brand.base,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.base,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '4px',
        horizontalPadding: '16px',
        textDecoration: 'none',
      },
      hover: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.light,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '4px',
        horizontalPadding: '16px',
        textDecoration: 'none',
      },
      active: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '4px',
        horizontalPadding: '16px',
        textDecoration: 'none',
      },
      disabled: {
        style: 'alternate',
        backgroundColor: colors.palette.greys.white,
        color: colors.palette.greys.light,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.greys.light,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '4px',
        horizontalPadding: '16px',
        textDecoration: 'none',
      },
      focus: {
        style: 'alternate',
        backgroundColor: colors.palette.brand.darker,
        color: colors.palette.greys.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: '',
        verticalPadding: '4px',
        horizontalPadding: '16px',
        textDecoration: 'none',
      },
    },
  },
  low: {
    common: {
      font: {
        entry: 'bodyText',
        transform: 'none',
      },
    },
    maxi: {
      default: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.base,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'underline',
      },
      hover: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.light,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
      active: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.darker,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
      disabled: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.greys.light,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
      focus: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.darker,
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
    },
    regular: {
      default: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.base,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'underline',
      },
      hover: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.light,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
      active: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.darker,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
      disabled: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.greys.light,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
      focus: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.darker,
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
    },
    micro: {
      default: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.base,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'underline',
      },
      hover: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.light,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
      active: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.darker,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
      disabled: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.greys.light,
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: colors.palette.brand.darker,
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
      focus: {
        style: 'alternate',
        backgroundColor: '',
        color: colors.palette.brand.darker,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        borderType: 'border',
        borderRadius: '',
        boxShadow: {
          shadowX: '0px',
          shadowY: '0px',
          shadowBlur: '0px',
          shadowSpread: '0px',
          shadowColor: 'transparent',
          shadowOpacity: '',
        },
        height: '',
        width: 'auto',
        verticalPadding: '4px',
        horizontalPadding: '4px',
        textDecoration: 'none',
      },
    },
  },
}
