export const httpsAgent =
  typeof window === 'undefined'
    ? new (require('https').Agent)({
        rejectUnauthorized: false,
        keepAlive: true,
        maxSockets: process.env.HTTP_MAX_SOCKETS
          ? Number(process.env.HTTP_MAX_SOCKETS)
          : 30,
      })
    : undefined

export const httpAgent =
  typeof window === 'undefined'
    ? new (require('http').Agent)({
        keepAlive: true,
        maxSockets: process.env.HTTP_MAX_SOCKETS
          ? Number(process.env.HTTP_MAX_SOCKETS)
          : 30,
      })
    : undefined
