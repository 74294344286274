import * as React from 'react'
export const SvgCard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 19V5H2v14h20zM4 7h16v1.5H4V7zm16 10v-6.5H4V17h16zM9 14H5v2h4v-2z"
      fill={props.fill || '#333333'}
    />
  </svg>
)
export default SvgCard
