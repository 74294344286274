export interface ProductClearanceInterface {
  fontStyle: {
    fontSize: string
    fontWeight: string
  }
  border: string
  color: string
  padding: string
  backgroundColor: string
}

// TODO to do: hex codes are 6 digits, this can also be pulled from gravity colours- CSBOM-329
export const theme: ProductClearanceInterface = {
  fontStyle: {
    fontSize: '14px',
    fontWeight: '500',
  },
  border: '1px solid #9d0a27',
  color: '#9d0a27',
  padding: '6px 8px',
  backgroundColor: '#fff',
}
