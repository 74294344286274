import * as React from 'react'

import { useSubscribableRef } from '@thg-commerce/enterprise-utils'

import { BasketContext } from '../BasketContext'
import { BasketRef } from '../BasketContext/BasketProvider'

export const useBasketId = (): [
  string | undefined,
  (basketId: string | undefined) => void,
] => {
  const { basketIdRef } = React.useContext(BasketContext)
  const basketId = useSubscribableRef(basketIdRef)
  const [, setBasketId] = basketIdRef.value

  return [basketId, setBasketId]
}

export const useBasket = (): BasketRef => {
  const { basketRef } = React.useContext(BasketContext)
  if (!basketRef) {
    return { loading: false }
  }

  const basketState = useSubscribableRef(basketRef)

  return basketState
}
