import * as styledComponents from 'styled-components'

import { theme as AccordionTheme } from './components/Accordion/theme'
import { theme as PictureTheme } from './components/Picture/theme'
import { CombinedThemeInterface, ComponentThemeInterface } from './'

export const componentTheme: ComponentThemeInterface = {
  components: {
    accordion: AccordionTheme,
    picture: PictureTheme,
  },
}

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
  useTheme,
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<
  CombinedThemeInterface
>

export {
  createGlobalStyle,
  css,
  keyframes,
  styled,
  componentTheme as theme,
  ThemeProvider,
  useTheme,
  withTheme,
}
