import * as React from 'react'
import { Property } from 'csstype'

import { Button, ButtonEmphases } from '@thg-commerce/gravity-elements'
import ShoppingBag from '@thg-commerce/gravity-icons/src/components/ShoppingBag'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { css, mediaQueryRenderer } from '@thg-commerce/gravity-theme'

import { ButtonState } from '../../Button'

export interface ProductButtonProps {
  title: string
  href?: string
  disabled?: boolean
  ariaLabel?: string
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
  dataTestId?: string
  className?: string
  roundButton?: boolean
  isCarouselButton?: boolean
  inlineQuickBuyButton?: boolean
  emphasis?: ButtonEmphases
}

const StyledButton = styled(Button)<{ inlineQuickBuyButton?: boolean }>`
  max-width: 100%;
  cursor: pointer;

  ${(props) =>
    props.inlineQuickBuyButton &&
    css`
      position: ${props.theme.patterns.productBlock.components
        .inlineQuickbuyButton.button.position};
      bottom: ${props.theme.patterns.productBlock.components
        .inlineQuickbuyButton.button.bottom};
      right: ${props.theme.patterns.productBlock.components.inlineQuickbuyButton
        .button.right};

      & > :first-child {
        background-color: ${props.theme.patterns.productBlock.components
          .inlineQuickbuyButton.button.backgroundColor};
        color: ${props.theme.patterns.productBlock.components
          .inlineQuickbuyButton.button.textColor};

        text-transform: uppercase;
        letter-spacing: 0.2px;

        &:hover {
          background-color: ${props.theme.patterns.productBlock.components
            .inlineQuickbuyButton.button.hoverBackgroundColor};
          color: ${props.theme.patterns.productBlock.components
            .inlineQuickbuyButton.button.textColor};
        }
      }
    `}
`

const ButtonContent = styled.div`
  overflow: ${(props) =>
    props.theme.patterns?.productBlock?.components?.productButton?.overflow ||
    'hidden'};
  text-overflow: ellipsis;
  text-align: center;
`

const roundButtonStyleState = (
  borderRadius: string,
  backgroundColor: Property.BackgroundColor,
) => {
  return `
      border: 0px none transparent;
      padding: 0px;
      outline-style: none;
      border-radius: ${borderRadius};
      svg > rect {
        fill: ${backgroundColor};
      }
  `
}

const RoundButton = styled(Button)<{ isCarouselButton?: boolean }>`
  all: revert;
  &&,
  &:hover,
  &:active,
  &:focus,
  &:disabled {
    outline-style: none;
    padding: 0px;
    border: 0px none transparent;
    box-shadow: none;
    border-radius: 50%;
  }

  position: absolute;

  ${(props) =>
    mediaQueryRenderer(
      props.isCarouselButton
        ? props.theme.patterns.productBlock.components.smallQuickbuyButton
            .button.carouselButtonBottom
        : props.theme.patterns.productBlock.components.smallQuickbuyButton
            .button.bottom,
      (bottom) => `bottom: ${bottom};`,
    )}

  ${(props) =>
    mediaQueryRenderer(
      props.theme.patterns.productBlock.components.smallQuickbuyButton.button
        .right,
      (right) => `right: ${right};`,
    )}

  ${(props) =>
    mediaQueryRenderer(
      props.theme.patterns.productBlock.components.smallQuickbuyButton.button
        .size,
      (size) => `
      height: ${size};
      width: ${size};
  `,
    )};

  a,
  button {
    ${(props) =>
      roundButtonStyleState(
        props.theme.patterns.productBlock.components.smallQuickbuyButton.button
          .borderRadius,
        props.theme.button.high.regular[ButtonState.Default].backgroundColor,
      )};
    &:hover {
      ${(props) =>
        roundButtonStyleState(
          props.theme.patterns.productBlock.components.smallQuickbuyButton
            .button.borderRadius,
          props.theme.button.high.regular[ButtonState.Hover].backgroundColor,
        )};
    }

    &:active {
      ${(props) =>
        roundButtonStyleState(
          props.theme.patterns.productBlock.components.smallQuickbuyButton
            .button.borderRadius,
          props.theme.button.high.regular[ButtonState.Active].backgroundColor,
        )};
    }

    &:focus {
      ${(props) =>
        roundButtonStyleState(
          props.theme.patterns.productBlock.components.smallQuickbuyButton
            .button.borderRadius,
          props.theme.button.high.regular[ButtonState.Focus].backgroundColor,
        )};
    }

    &:disabled {
      ${(props) =>
        roundButtonStyleState(
          props.theme.patterns.productBlock.components.smallQuickbuyButton
            .button.borderRadius,
          props.theme.button.high.regular[ButtonState.Disabled].backgroundColor,
        )};
    }
  }
`

const QuickBuyIcon = styled(ShoppingBag)`
  ${(props) =>
    props.theme.patterns.productBlock?.components?.smallQuickbuyButton.icon
      ?.width &&
    mediaQueryRenderer(
      props.theme.patterns.productBlock.components.smallQuickbuyButton.icon
        .width,
      (width) => `width: ${width};`,
    )}

  ${(props) =>
    props.theme.patterns.productBlock?.components?.smallQuickbuyButton?.icon
      ?.height &&
    mediaQueryRenderer(
      props.theme.patterns.productBlock.components.smallQuickbuyButton.icon
        .height,
      (height) => `
        height: ${height};
      `,
    )}
`

export const ProductButton = ({
  title,
  href,
  disabled,
  ariaLabel,
  onClick,
  dataTestId,
  className,
  roundButton,
  isCarouselButton,
  inlineQuickBuyButton,
  emphasis,
}: ProductButtonProps) => {
  if (roundButton) {
    return (
      <RoundButton
        emphasis="high"
        sizing="regular"
        href={href}
        renderedAs={disabled ? undefined : href ? 'a' : 'div'}
        disabled={disabled}
        ariaLabel={ariaLabel}
        onClick={onClick}
        className={className}
        data-testid="round-button"
        isCarouselButton={isCarouselButton}
      >
        <QuickBuyIcon data-testid="quickBuy-icon" />
      </RoundButton>
    )
  }
  return (
    <StyledButton
      emphasis={emphasis || 'high'}
      sizing={inlineQuickBuyButton ? 'micro' : 'regular'}
      href={href}
      // Render as div for onClick function to not be overridden by a tag
      renderedAs={disabled ? undefined : href ? 'a' : 'div'}
      disabled={disabled}
      ariaLabel={ariaLabel}
      onClick={onClick}
      data-testid={dataTestId}
      className={className}
      inlineQuickBuyButton={inlineQuickBuyButton}
    >
      <ButtonContent>{title}</ButtonContent>
    </StyledButton>
  )
}
