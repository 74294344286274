import * as React from 'react'
export const SvgInternationalDelivery = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg width={24} height={24} {...props}>
    <path
      d="M10.993 22L9 21.971l2-7.988-5.545-.001L4 15.98H2l1-3.994L2 7.99h2l1.333 1.997h5.547L9 2h2l4 7.988h3c3 0 4 1.055 4 1.998 0 .942-1 1.997-4 1.997h-3L10.993 22z"
      fill={props.fill || '#333'}
      fillRule="nonzero"
    />
  </svg>
)
export default SvgInternationalDelivery
