export interface AccountHomeHeroBannerThemeInterface {
  smallImageUrl: string
  largeImageUrl: string
}

export const theme: AccountHomeHeroBannerThemeInterface = {
  smallImageUrl:
    'https://s1.thcdn.com/design-assets/whitelabel/components/heroBanner/accountImageSmall.jpg',
  largeImageUrl:
    'https://s1.thcdn.com/design-assets/whitelabel/components/heroBanner/accountImageLarge.jpg',
}
