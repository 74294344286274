import * as React from 'react'
export const SvgIdeal = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={62} height={42} {...props}>
    <g transform="translate(16.017 7.875)" fill="none">
      <path fill="#000" d="M3.344 14.792h4.8v7.996h-4.8z" />
      <ellipse cx={5.786} cy={10.795} fill="#000" rx={2.717} ry={2.726} />
      <path
        fill="#000"
        d="M17.234.043H.013v26.202h17.22v-.005c3.762-.052 6.742-.989 8.857-2.794 2.572-2.196 3.876-5.662 3.876-10.303 0-2.217-.343-4.208-1.02-5.914-.646-1.631-1.603-3.02-2.842-4.124C23.922 1.16 20.86.108 17.234.048V.043zm-.326 1.74c3.338 0 6.12.907 8.044 2.623 2.176 1.94 3.28 4.88 3.28 8.738 0 7.644-3.704 11.36-11.324 11.36H1.747V1.785l15.161-.001z"
      />
      <path
        fill="#CC1B69"
        d="M16.434 22.796h-6.088V3.901h6.088-.246c5.078 0 10.482 2.01 10.482 9.472 0 7.889-5.404 9.423-10.482 9.423h.246z"
      />
      <g fill="#FFF">
        <path d="M9.296 8.779h1.673c.377 0 .664.028.862.087.266.078.493.218.683.418.189.201.334.446.432.737.099.29.149.648.149 1.074 0 .374-.048.697-.14.968a2.013 2.013 0 01-.484.803 1.611 1.611 0 01-.643.363c-.202.064-.472.096-.81.096H9.297V8.779zm.914.77v3.006h.685c.256 0 .441-.015.554-.044a.839.839 0 00.37-.188c.099-.089.178-.235.24-.439.063-.203.093-.48.093-.83 0-.351-.03-.62-.093-.808a1.038 1.038 0 00-.26-.44.868.868 0 00-.424-.213c-.128-.029-.38-.043-.753-.043h-.412z" />
        <path d="M9.172 8.654v4.795h1.846c.348 0 .633-.034.846-.102a1.73 1.73 0 00.692-.392c.223-.217.397-.503.516-.852a3.16 3.16 0 00.145-1.008c0-.438-.052-.813-.154-1.114a2.136 2.136 0 00-.46-.782 1.644 1.644 0 00-.738-.453c-.212-.062-.505-.092-.897-.092H9.172zm1.796.249c.363 0 .642.028.828.082a1.4 1.4 0 01.627.385c.176.186.312.419.406.69.093.277.14.625.14 1.035 0 .36-.044.671-.131.928-.107.31-.26.564-.455.754a1.51 1.51 0 01-.594.334c-.189.06-.448.09-.771.09H9.42V8.903h1.548z" />
        <path d="M10.086 9.425v3.254h.81c.268 0 .46-.016.584-.047a.976.976 0 00.423-.218c.116-.105.206-.266.276-.494.066-.217.098-.5.098-.867s-.033-.644-.1-.847a1.159 1.159 0 00-.293-.49.992.992 0 00-.483-.245c-.137-.031-.392-.047-.779-.047h-.536zm.536.249c.452 0 .644.022.726.04a.762.762 0 01.367.183c.096.09.172.221.227.388.057.174.086.433.086.77 0 .336-.029.603-.087.794-.055.18-.124.308-.204.381a.72.72 0 01-.318.16c-.103.027-.28.04-.524.04h-.56V9.675h.287zm3.506 3.651V8.78h3.354v.77h-2.44v1.007h2.27v.772h-2.27v1.227h2.527v.77h-3.44z" />
        <path d="M14.004 8.654v4.795h3.69V12.43h-2.528v-.979h2.27v-1.019h-2.27v-.758h2.44v-1.02h-3.602zm3.354.249v.522h-2.44v1.256h2.27v.521h-2.27v1.477h2.527v.522h-3.193V8.903h3.106zm5.328 4.422h-.996l-.395-1.032h-1.81l-.376 1.032h-.97L19.9 8.78h.974l1.812 4.546zm-1.684-1.802l-.625-1.685-.612 1.685h1.237z" />
        <path d="M19.815 8.654l-1.857 4.795h1.238l.375-1.031h1.64c.05.135.395 1.031.395 1.031h1.263l-1.91-4.795h-1.144zm.975.249l1.713 4.298h-.727l-.395-1.032h-1.983l-.374 1.032h-.704l1.665-4.298h.805z" />
        <path d="M19.588 11.647h1.592l-.804-2.168-.788 2.168zm.79-1.449l.445 1.2h-.881l.437-1.2zm3.072 3.127V8.816h.914v3.738h2.277v.771h-3.19z" />
        <path d="M23.327 8.692v4.757h3.438V12.43h-2.277V8.692h-1.161zm.914.248v3.739h2.276v.522h-2.943V8.94h.667z" />
      </g>
    </g>
  </svg>
)
export default SvgIdeal
