import * as React from 'react'
import loadable from '@loadable/component'

const SvgIconError = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/IconError'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { ErrorContainer, ErrorDescription } from './Styles'

export interface ErrorObjectInterface {
  /** If a boolean, shows the error state without a message. If a string is passed, shows it as a message with the error state. */
  id: string
  error?: string | boolean
  className?: string
  announce?: boolean
}
/** @component */
export const ErrorMessage = (props: ErrorObjectInterface) => {
  const [errorHtml, setErrorHtml] = React.useState({ __html: ' ' })

  React.useEffect(() => {
    if (typeof props.error === 'string') {
      setErrorHtml({ __html: props.error })
    } else if (typeof props.error === 'boolean') {
      setErrorHtml({ __html: ' ' })
    }
  }, [props.error])

  const id = props.id
    ?.toLowerCase()
    .replace(/\:|\?|\.|\!|\"|\'/g, '')
    .split(' ')
    .join('-')

  return (
    <ErrorContainer
      className={props.className}
      data-testid="input-error-container"
    >
      <SvgIconError data-testid="input-error-icon" />
      <ErrorDescription
        id={id}
        data-testid={`input-error-description-${id}`}
        aria-live={props.announce ? 'polite' : 'off'}
        dangerouslySetInnerHTML={errorHtml}
      />
    </ErrorContainer>
  )
}

export default ErrorMessage
