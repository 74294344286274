import { TextEntry, TextStyle } from '@thg-commerce/gravity-theme'

export interface TextInterface {
  entry: TextEntry
  style: TextStyle
}

export interface SeparatorInterface {
  font: TextInterface
}

export const separator: SeparatorInterface = {
  font: {
    entry: 'bodyText',
    style: 'default',
  },
}
