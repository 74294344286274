import * as React from 'react'
export const SvgHiper = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={40} {...props}>
    <g fill="#fff">
      <path
        d="M60 35c0 2.75-2.25 5-5 5H5c-2.75 0-5-2.25-5-5V5c0-2.75 2.25-5 5-5h50c2.75 0 5 2.25 5 5z"
        fill="#ff7900"
      />
      <path
        d="M55 0H5C2.25 0 0 2.25 0 5v30c0 2.75 2.25 5 5 5h50c2.75 0 5-2.25 5-5V5c0-2.75-2.25-5-5-5zm0 1c2.206 0 4 1.794 4 4v30c0 2.206-1.794 4-4 4H5c-2.206 0-4-1.794-4-4V5c0-2.206 1.794-4 4-4z"
        opacity={0.25}
      />
      <path d="M13.893 19.729c0-1.531.003-3.062-.004-4.593-.001-.19.045-.258.246-.254a43.58 43.58 0 001.734.001c.187-.004.241.054.239.239-.008 1.07.001 2.14-.008 3.211-.002.203.05.269.261.267a161.93 161.93 0 013.117-.001c.187.002.237-.054.236-.238-.008-1.078 0-2.156-.008-3.233-.001-.179.038-.247.231-.244.586.011 1.171.014 1.757-.001.225-.006.228.102.228.27-.004 2.679-.003 5.359-.003 8.038 0 .406-.007.812.004 1.218.004.157-.052.201-.202.2a72.323 72.323 0 00-1.804 0c-.162.002-.212-.049-.211-.213.007-1.203-.001-2.406.008-3.609.002-.206-.056-.265-.264-.263a167.84 167.84 0 01-3.093.001c-.193-.001-.257.057-.255.253.008 1.195-.001 2.39.008 3.585.002.201-.061.249-.251.246a47.73 47.73 0 00-1.734-.001c-.183.003-.234-.054-.233-.239.005-1.546.002-3.093.001-4.64zM28.801 21.691c0 .445.003.82-.002 1.195-.002.132.033.203.183.203.358.002.718.031 1.076.023.583-.011 1.048-.249 1.302-.794a2.715 2.715 0 00.054-2.245c-.274-.675-.806-.973-1.456-.876-.613.091-1.053.585-1.136 1.278-.049.427-.004.857-.021 1.216zm-.226-3.09c.565-.785 1.322-1.101 2.202-1.136 1.164-.046 2.175.616 2.672 1.707.41.898.449 1.837.311 2.794-.099.686-.344 1.311-.829 1.82-.59.619-1.347.875-2.175.961-.514.054-1.03.025-1.545.03-.448.004-.407-.046-.408.397-.001.688-.005 1.375.003 2.063.002.162-.045.217-.211.214a50.116 50.116 0 00-1.734.001c-.173.003-.227-.041-.225-.22.01-.696.005-1.39.004-2.086 0-.371 0-.367-.365-.39a3.706 3.706 0 01-1.565-.428c-.908-.489-1.355-1.292-1.537-2.276-.08-.434-.078-.87-.078-1.307 0-.977.003-1.953-.003-2.93-.001-.158.037-.219.208-.216.593.01 1.188.011 1.781 0 .179-.003.225.053.224.226-.007 1.141-.016 2.282.002 3.422.009.554.14 1.083.562 1.491.155.15.344.243.548.301.144.041.229.021.228-.172-.008-1.608.014-3.217-.072-4.824l-.001-.047c.002-.433-.03-.358.361-.362.438-.004.875.007 1.313-.004.184-.005.243.058.246.242.003.223.047.446.083.729z" />
      <path
        d="M24.199 16.752c-.719.036-1.177-.482-1.169-1.104.009-.648.48-1.098 1.165-1.099.718-.001 1.185.443 1.184 1.125-.002.642-.479 1.078-1.18 1.078z"
        fill="#ffe701"
      />
      <path d="M37.717 20.3c.367 0 .733-.002 1.1.001.109.001.181-.017.17-.152-.077-1.006-.812-1.452-1.64-1.187-.56.18-.795.662-.909 1.208-.029.139.066.131.156.131l1.123-.001zm.981 1.503c-.679 0-1.359.003-2.038-.002-.167-.001-.243.018-.178.224.201.633.688.895 1.278 1.02.814.171 1.613.035 2.401-.168.15-.039.198-.009.224.128.071.375.141.749.222 1.121.035.165-.05.222-.183.266a7.29 7.29 0 01-2.461.357c-.754-.014-1.469-.162-2.117-.563-.79-.488-1.235-1.212-1.402-2.11-.179-.967-.108-1.911.352-2.798.599-1.157 1.562-1.759 2.861-1.831.658-.036 1.291.051 1.868.391.656.387 1.063.973 1.285 1.687.215.694.27 1.403.159 2.125-.026.166-.125.151-.233.151-.68.003-1.359.002-2.038.002zM41.792 21.597c0-.633.005-1.265-.002-1.898a76.698 76.698 0 00-.042-1.918c-.006-.162.046-.196.195-.194.492.008.984.011 1.475-.002.169-.005.218.063.223.209.008.281.018.561.03.842.002.053-.008.125.056.14.072.016.075-.061.098-.107.292-.612.755-1.022 1.419-1.189.246-.061.497-.035.746-.031.131.003.117.103.117.186.002.562-.004 1.125.004 1.686.002.161-.053.207-.206.178a1.831 1.831 0 00-1.122.113c-.61.274-.796.794-.798 1.411-.004 1.108-.007 2.218.004 3.326.002.213-.065.262-.265.258a44.257 44.257 0 00-1.709-.001c-.174.003-.226-.048-.225-.225.005-.926.002-1.855.002-2.784z" />
    </g>
  </svg>
)
export default SvgHiper
