import * as React from 'react'

export const SwatchDisableSquare = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={44} height={44} viewBox="0 0 779 779">
    <g>
      <path
        fill={props.fill || '#CCC'}
        fillRule="evenodd"
        d="M62.19,759,80,776.82,97.83,759H761V95.83l14.34-14.34L761,67.15V16H709.85L698.06,4.21,686.27,16H18V684.27L2.73,699.54,18,714.81V759Zm88.64-53H708V148.83ZM633.27,69,71,631.27V69Z"
        transform="translate(0 0)"
      />
      <path
        fill={props.fill || '#8C8C8C'}
        fillRule="evenodd"
        d="M675.92,53H53V675.92ZM103.19,726H726V103.19ZM0,0H779V779H0V0Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
)
export default SwatchDisableSquare
