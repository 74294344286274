import React from 'react'

import { styled } from '@thg-commerce/gravity-patterns/theme'
import { KeyboardKeys, Text } from '@thg-commerce/gravity-theme'

import { linkStyling } from '../../styles'
import { WaterfallLevel } from '../WaterfallMenu'

export const WaterfallLinkWrapper = styled.li<{
  isActive?: boolean
  level: WaterfallLevel
}>`
  ${linkStyling}
  &:hover {
    ${(props) =>
      props.level === WaterfallLevel.two &&
      `background-color: ${props.theme.patterns.header.navigation.waterfall.hoverBackgroundColor};`}

    ${(props) =>
      props.level === WaterfallLevel.three &&
      `color: ${props.theme.patterns.header.navigation.waterfall.hoverLink.textColor};
        background-color: #FFFFFF`}
      

    svg {
      display: block;
      fill: ${(props) => props.theme.colors.palette.greys.dark};
    }
  }

  &:focus-within {
    outline: none;

    svg {
      display: block;
      fill: ${(props) => props.theme.colors.palette.greys.dark};
    }
  }

  svg {
    fill: ${(props) => props.isActive && props.theme.colors.palette.greys.dark};
    display: ${(props) => !props.isActive && `none`};
  }
`

export const StyledWaterfallLink = styled.a<{ level: WaterfallLevel }>`
  ${(props) =>
    Text(
      props.theme.patterns.header.navigation.waterfall.link.entry,
      props.theme.patterns.header.navigation.waterfall.link.style,
    )}
  color: ${(props) =>
    props.theme.patterns.header.navigation.waterfall.link.textColor};
  outline: none;
  text-decoration: ${(props) =>
    props.theme.patterns.header.navigation.waterfall.link.textDecoration};
  text-transform: ${(props) =>
    props.theme.patterns.header.navigation.waterfall.link.transform};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:hover {
    ${(props) =>
      Text(
        props.theme.patterns.header.navigation.waterfall.hoverLink.entry,
        props.theme.patterns.header.navigation.waterfall.hoverLink.style,
      )}
    color: ${(props) =>
      props.level === WaterfallLevel.three
        ? props.theme.patterns.header.navigation.waterfall.hoverLink.textColor
        : props.theme.patterns.header.navigation.waterfall.link.textColor};
    text-decoration: ${(props) =>
      props.level === WaterfallLevel.three &&
      props.theme.patterns.header.navigation.waterfall.hoverLink
        .textDecoration};
    text-transform: ${(props) =>
      props.theme.patterns.header.navigation.waterfall.hoverLink.transform};
  }
`

interface WaterfallLink {
  displayText: string
  index: number
  href?: string
  children?: JSX.Element
  level: WaterfallLevel
  selectionHandler?: () => void
  deselectionHandler?: () => void
  displayIcon?: boolean
  levelThreeShowing?: number | null
  'data-testid'?: string
  handleLevelThreeKeyPress?: (event: React.KeyboardEvent<HTMLLIElement>) => void
}

const handleLinkKeyPress = (
  linkKeyboardEvent: React.KeyboardEvent<HTMLLIElement>,
  selectionHandler: () => void,
  deselectionHandler: () => void,
  levelThreeDisplayed: boolean,
  levelThreeShowing?: number | null,
) => {
  const nextElement = document.querySelector(
    `[data-level-three-index="${levelThreeShowing}"]`,
  ) as HTMLElement

  switch (linkKeyboardEvent.key) {
    case KeyboardKeys.ArrowRight:
      if (levelThreeDisplayed) {
        nextElement && nextElement.focus()
      }
      selectionHandler()
      break
    case KeyboardKeys.Tab:
      if (levelThreeDisplayed) {
        linkKeyboardEvent.preventDefault()
        nextElement && nextElement.focus()
      }
      break
    case KeyboardKeys.Enter:
    case KeyboardKeys.Spacebar:
      linkKeyboardEvent.preventDefault()
      levelThreeDisplayed ? deselectionHandler() : selectionHandler()
      break
    default:
      break
  }
}

export const WaterfallLink = (props: WaterfallLink) => {
  return (
    <WaterfallLinkWrapper
      level={props.level}
      isActive={props.displayIcon}
      onMouseEnter={props.selectionHandler}
      onFocus={props.deselectionHandler}
      onKeyDown={(linkKeyboardEvent) => {
        props.handleLevelThreeKeyPress &&
          props.handleLevelThreeKeyPress(linkKeyboardEvent)
        props.selectionHandler &&
          props.deselectionHandler &&
          handleLinkKeyPress(
            linkKeyboardEvent,
            props.selectionHandler,
            props.deselectionHandler,
            !!props.levelThreeShowing || props.levelThreeShowing === 0,
            props.levelThreeShowing,
          )
      }}
      data-testid={props['data-testid']}
    >
      <StyledWaterfallLink
        data-link-index={props.index}
        href={props.href}
        level={props.level}
      >
        {props.displayText}
        {props.children}
      </StyledWaterfallLink>
    </WaterfallLinkWrapper>
  )
}
