import { Property } from 'csstype'

export interface SimpleHeaderThemeInterface {
  backgroundColor: Property.Color
  type: 'primary' | 'alternate'
  simpleLogo: { height: string; verticalMargin: string }
}

export const theme: SimpleHeaderThemeInterface = {
  backgroundColor: '#ffffff',
  type: 'primary',
  simpleLogo: { height: '18px', verticalMargin: '24px' },
}
