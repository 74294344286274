import * as React from 'react'

import { styled, Text } from '@thg-commerce/gravity-theme'
import { TextStyling } from '@thg-commerce/gravity-theme/typography/typography'

export interface ProductQuantityProps {
  text: string
  quantity: number
  className?: string
  customStyling?: TextStyling
}

const StyledQuantity = styled.div<{
  customStyling?: TextStyling
}>`
  ${(props) =>
    Text(
      props.customStyling?.entry || 'bodyText',
      props.customStyling?.style || 'default',
    )};
  color: ${(props) =>
    props.customStyling?.textColor || props.theme.colors.palette.greys.grey};
`

export const ProductQuantity = (props: ProductQuantityProps) => {
  return (
    <StyledQuantity
      data-testid="product-quantity"
      className={props.className}
      customStyling={props.customStyling}
    >
      {props.text} {props.quantity}
    </StyledQuantity>
  )
}
