import * as React from 'react'

import {
  useSiteConfig,
  EnterpriseContext,
  useLogger,
  useSiteDefinition,
  getSafeUrlParametersFromWindow,
  useSessionSettings,
} from '@thg-commerce/enterprise-core'
import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'

import { BaseEventType, getEventObject } from './event'
import { getRequestObject } from './request'
import { getPropertyObject } from './property'
import { getDeviceObject } from './device'
import { getOriginObject } from './origin'
import { getExperimentsList } from './experiments'
import { getErrorsList } from './errors'
import { getRayIds } from './ray_ids'
import { getCartObject } from './cart'

export type { CartItem, Product, Cart } from './types'

export interface BackendMetricArguments<EventType> {
  eventData: EventType
  requestData: {
    start_timestamp: number
    url: string
    duration_ms: number
  }
  errors?: { type: string; label: string }[]
  rays?: String[]
  experiments?: { [key: string]: string }
  basket?: BasketData
  customerData?: {
    receive_newsletter?: boolean | null
  }
}

export const useBackendEventNormaliser = () => {
  const siteConfig = useSiteConfig()
  const logger = useLogger()
  const {
    requestConfig,
    metricNonce,
    customerReceiveNewsletter,
    customerLoginState,
    extensionsRef,
    history,
  } = React.useContext(EnterpriseContext)

  const {
    value: [getExtensions],
  } = extensionsRef

  const { defaultLocale, subsite, siteId, channel } = useSiteDefinition()
  const sessionSettings = useSessionSettings()

  const normaliseBackendEvent = <EventType extends BaseEventType>(
    argObject: BackendMetricArguments<EventType>,
  ) => {
    const { v4: uuid } = require('uuid')
    const urlParams = getSafeUrlParametersFromWindow(window, '*')
    const referrer =
      history.current && history.current.length >= 2
        ? history.current[history.current.length - 2]
        : document.referrer

    const extensions = getExtensions()
    const backendEvent = {
      cart: argObject.basket
        ? getCartObject(argObject.basket, sessionSettings.currency)
        : null,
      insert_id: uuid(),
      nonce: metricNonce,
      event: getEventObject(argObject.eventData),
      request: getRequestObject(argObject.requestData),
      property: getPropertyObject({
        defaultLocale,
        subsite,
        siteId,
        channel,
        customerLocation:
          requestConfig.customerLocation === 'unknown'
            ? null
            : requestConfig.customerLocation,
      }),
      device: getDeviceObject(siteConfig, requestConfig.customerLocation),
      origin: getOriginObject({ urlParams, referrer }),
      experiments: getExperimentsList(
        argObject.experiments || extensions?.experiments,
      ),
      ray_ids: getRayIds(argObject.rays ?? []),
      customer: {
        login_state: customerLoginState,
        receive_newsletter:
          argObject.customerData?.receive_newsletter !== undefined
            ? argObject.customerData?.receive_newsletter
            : customerReceiveNewsletter,
      },
    }

    if (typeof backendEvent.request.duration_ms !== 'number') {
      logger.debug(
        'Invalid device object - duration_ms should be of type number.',
        { url: backendEvent.request.url },
      )
    }

    if (argObject.eventData.type === 'page_visit') {
      return backendEvent
    }
    backendEvent['errors'] = getErrorsList(
      argObject.errors,
      argObject.eventData,
    )
    return backendEvent
  }

  return normaliseBackendEvent
}
