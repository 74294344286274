export const ElevationLevel = (level: number) => {
  switch (level) {
    case 1:
      return `box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.1), 0px 2px 4px 0px rgba(0,0,0,0.2)`
    case 2:
      return `box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.1), 0px 4px 6px 0px rgba(0,0,0,0.2)`
    case 3:
      return `box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%)`
    default:
      return ``
  }
}

interface ElevationInterface {
  elevationActive: boolean
}

export const elevation: ElevationInterface = {
  elevationActive: false,
}
