import * as React from 'react'
export const SvgReferralsCode = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={28} height={18} viewBox="0 0 28 18" {...props}>
    <defs>
      <polygon
        id="path-1"
        points="5.5 0 4.01041667 3.87291667 0 4.1559375 3.07942708 6.8371875 2.10546875 10.8739583 5.5 8.65447917 8.89453125 10.8739583 7.92057292 6.8371875 11 4.1559375 6.98958333 3.87291667"
      ></polygon>
      <mask
        id="mask-2"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="11"
        height="10.8739583"
        fill="white"
      >
        <use xlinkHref="#path-1"></use>
      </mask>
    </defs>
    <g stroke="none" stroke-width="1">
      <g transform="translate(-353.000000, -1980.000000)">
        <g transform="translate(353.000000, 1980.000000)">
          <g>
            <path d="M0,0 L0,1 L0,6 L0,7 L1,7 C2.1901961,7 3,7.809804 3,9 C3,10.190196 2.1901961,11 1,11 L0,11 L0,12 L0,17 L0,18 L1,18 L27,18 L28,18 L28,17 L28,12 L28,11 L27,11 C25.809804,11 25,10.190196 25,9 C25,7.809804 25.809804,7 27,7 L28,7 L28,6 L28,1 L28,0 L27,0 L1,0 L0,0 Z M1,1.41666667 L27,1.41666667 L27,4.86979167 C25.1419003,5.35829158 23.75,6.98815792 23.75,9 C23.75,11.0118421 25.1419003,12.6417084 27,13.1302083 L27,16.5833333 L1,16.5833333 L1,13.1302083 C2.85809997,12.6417084 4.25,11.0118421 4.25,9 C4.25,6.98815792 2.85809997,5.35829158 1,4.86979167 L1,1.41666667 Z"></path>
            <g transform="translate(8.500000, 3.500000)" stroke-width="2">
              <use id="Shape" mask="url(#mask-2)" xlinkHref="#path-1"></use>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default SvgReferralsCode
