import * as React from 'react'

import { SimpleHeader } from '@thg-commerce/gravity-patterns'
import { styled, zIndex, ZIndexLevel } from '@thg-commerce/enterprise-theme'

import { i18n, useSiteDefinition, EnterpriseContext } from '../../..'
import { SkipLink } from '../../SkipLink'

const CookieModalContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 0;
  margin: 0px;
  width: 100%;
  ${zIndex(ZIndexLevel.Highest)}
`

const StyledHeader = styled.header`
  position: sticky;
  z-index: 1;
  top: -16px;
`

export const SimpleHeaderLayout = ({
  children,
  hideBranding,
}: {
  children: React.ReactNode
  hideBranding?: boolean
}) => {
  const context = React.useContext(EnterpriseContext)
  const { siteName } = useSiteDefinition()
  const i18nText = {
    logoAriaLabel: i18n('general.header.logo.arialabel', siteName),
  }

  const setHeaderHeightContext = (height: number) => {
    context.setHeaderHeight(height)
  }

  return (
    <React.Fragment>
      <StyledHeader>
        <CookieModalContainer
          id="cookie-modal-container"
          ref={context.cookieModalContainerRef}
        />
        <SkipLink />
        <SimpleHeader
          i18nText={{ logoAriaLabel: i18nText.logoAriaLabel }}
          setHeight={setHeaderHeightContext}
          hideBranding={hideBranding}
        />
      </StyledHeader>
      <main id="main-content">{children}</main>
    </React.Fragment>
  )
}
