import * as React from 'react'
export const SvgAccount = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="account_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <style>{'.account_svg__cls-1{fill:#383838}'}</style>
    </defs>
    <path
      className="account_svg__cls-1"
      d="M12.23 12.72a9.5 9.5 0 019.5 9.19.62.62 0 01-.62.62H3.36a.63.63 0 01-.63-.62 9.52 9.52 0 019.5-9.19zM18 16.28a8.31 8.31 0 00-11.5 0 8 8 0 00-2.44 5h16.42a7.87 7.87 0 00-2.48-5zM12.23 2.53a4.69 4.69 0 11-4.69 4.69 4.69 4.69 0 014.69-4.69zm0 1.25a3.44 3.44 0 103.44 3.44 3.48 3.48 0 00-3.44-3.44z"
    />
  </svg>
)
export default SvgAccount
