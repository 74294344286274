import * as React from 'react'
export const SvgPaytm = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={40} {...props}>
    <g fill="none">
      <path
        fill="#54C1F0"
        d="M51.58 17.649c-.398-1.1-1.48-1.891-2.75-1.891h-.027c-.825 0-1.57.333-2.1.868a2.949 2.949 0 00-2.1-.868h-.026a2.95 2.95 0 00-1.9.684v-.216a.424.424 0 00-.427-.386h-1.949a.424.424 0 00-.43.418v10.263c0 .232.192.418.43.418h1.95a.425.425 0 00.424-.36l-.001-7.368c0-.025 0-.05.003-.073.032-.33.28-.6.672-.633h.36c.164.014.302.07.411.154.17.131.264.332.264.552l.008 7.331c0 .232.193.42.43.42h1.95c.23 0 .416-.178.427-.4v-7.36a.684.684 0 01.317-.59c.1-.063.22-.105.358-.117h.359c.422.035.677.346.676.707l.007 7.32c0 .232.194.42.431.42h1.949c.237 0 .43-.188.43-.42V18.65c0-.537-.062-.766-.147-1m-13.193-1.897H37.27v-1.757a.387.387 0 00-.467-.373c-1.236.329-.988 1.988-3.244 2.13h-.219a.465.465 0 00-.094.01h-.002l.002.001a.42.42 0 00-.337.406v1.89c0 .23.193.418.432.418h1.176l-.002 8.013c0 .229.19.414.426.414h1.927a.42.42 0 00.425-.414l.001-8.013h1.092c.237 0 .43-.188.43-.418v-1.89a.425.425 0 00-.43-.417"
      />
      <path
        fill="#233266"
        d="M31.374 15.84h-1.949a.424.424 0 00-.43.418v3.907a.45.45 0 01-.456.436h-.816a.45.45 0 01-.457-.443l-.008-3.9a.425.425 0 00-.43-.418h-1.95a.425.425 0 00-.43.418v4.282c0 1.627 1.196 2.787 2.875 2.787 0 0 1.26 0 1.298.007.227.025.404.21.404.437a.445.445 0 01-.398.437l-.033.006-2.85.01a.425.425 0 00-.43.418v1.888c0 .232.192.418.43.418h3.186c1.68 0 2.875-1.159 2.875-2.786v-7.904a.425.425 0 00-.43-.418M12.51 19.326v1.167a.45.45 0 01-.456.443l-1.236.001V18.6h1.236a.45.45 0 01.457.443v.283zm.172-3.463h-4.26a.416.416 0 00-.422.41v10.269c0 .227.176.413.394.418h1.986c.237 0 .43-.186.43-.418l.008-2.872h1.864c1.56 0 2.648-1.05 2.648-2.568v-2.666c0-1.52-1.088-2.573-2.648-2.573zm7.915 7.98v.295a.423.423 0 01-.026.13.455.455 0 01-.433.286h-.812c-.253 0-.459-.187-.459-.416v-1.615l.001-.003c.001-.228.205-.413.458-.413h.812c.254 0 .459.185.459.416v1.32zm-.31-7.89h-2.704c-.24 0-.433.177-.433.393v.735l.001.014v1.023c0 .229.205.415.458.415h2.575a.44.44 0 01.387.4v.244a.424.424 0 01-.376.388h-1.276c-1.695 0-2.904 1.093-2.904 2.627v2.197c0 1.526 1.039 2.611 2.723 2.611h3.534c.634 0 1.148-.466 1.148-1.04v-7.17c0-1.738-.924-2.836-3.133-2.836z"
      />
    </g>
  </svg>
)
export default SvgPaytm
