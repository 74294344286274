import {
  string as yupString,
  object as yupObject,
  number as yupNumber,
  boolean as yupBoolean,
  array as yupArray,
} from 'yup'

export const validate_exisiting_customer_login = async (
  payload,
): Promise<boolean> => {
  const exisitingCustomerSchema = yupObject().shape({
    insert_id: yupString(),
    event: yupObject().shape({
      type: yupString(),
      subtype: yupString(),
    }),
    request: yupObject().shape({
      start_timestamp: yupNumber(),
      url: yupString(),
      duration_ms: yupNumber(),
    }),
    property: yupObject().shape({
      site_id: yupNumber(),
      channel: yupString(),
      locale: yupString(),
      subsite: yupString(),
      country_code: yupString(),
    }),
    device: yupObject().shape({
      ip: yupString(),
      cookie: yupString(),
      ga_cookie: yupString(),
      user_cookie: yupString(),
      sess_cookie: yupString(),
      reengagement_cookie: yupString(),
      cookie_notice_shown: yupBoolean(),
    }),
    origin: yupObject().shape({
      source: yupString(),
      medium: yupString(),
      campaign: yupString(),
      channel: yupString(),
      referrer: yupString(),
      widget_id: yupString(),
    }),
    experiments: yupArray(),
    errors: yupArray(),
    ray_ids: yupArray(),
  })
  const valid = await exisitingCustomerSchema.isValid(payload)
  return valid
}
