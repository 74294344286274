import {
  BreakpointArray,
  TextEntry,
  TextStyle,
} from '@thg-commerce/gravity-theme'
import { Padding } from '@thg-commerce/gravity-theme/padding'

export interface PictureThemeInterface {
  pictureTagStyle: {
    container: {
      padding: Padding
      width: BreakpointArray<string>
    }
    textStyling: {
      entry: TextEntry
      style: TextStyle
    }
  }
}

export const theme: PictureThemeInterface = {
  pictureTagStyle: {
    container: {
      padding: {
        top: '2px',
        right: '4px',
        bottom: '2px',
        left: '4px',
      },
      width: ['100%'],
    },
    textStyling: {
      entry: 'xsmall',
      style: 'alternate',
    },
  },
}
