import * as styledComponents from 'styled-components'

import { theme as BreadCrumbsTheme } from './Breadcrumbs/theme'
import { theme as ImageSwatchesTheme } from './ImageSwatch/theme'
import { theme as ProductClearanceTheme } from './Product/ProductClearanceFlag/theme'
import { theme as ProductPriceTheme } from './ProductBlock/ProductPrice/theme'
import { theme as QuantitySelectorThemeInterface } from './QuantitySelector/theme'
import { CombinedThemeInterface, ElementThemeInterface } from './'

const elementTheme: ElementThemeInterface = {
  elements: {
    quantitySelector: QuantitySelectorThemeInterface,
    productImage: {
      aspectRatio: '1/1',
      imageTagStyle: {
        container: {
          padding: {
            top: '2px',
            right: '4px',
            bottom: '2px',
            left: '4px',
          },
          width: ['100%'],
        },
        textStyling: {
          entry: 'xsmall',
          style: 'alternate',
        },
      },
    },
    productReview: {
      star: {
        icon: {
          svgPath: '',
          width: '',
          height: '',
          viewBox: '',
        },
      },
    },
    modal: {
      icon: {
        close: {
          svgPath: '',
        },
        width: '0 0 24 24',
        height: '24px',
        viewBox: '24px',
      },
    },
    productPrice: ProductPriceTheme,
    pagination: {
      chevronIcon: {
        right: {
          svgPath: '',
        },
        left: {
          svgPath: '',
        },
        down: {
          svgPath: '',
        },
        viewBox: '0 0 24 24',
        width: '24px',
        height: '24px',
      },
    },
    squaredRadioInput: {
      height: '38px',
      minWidth: '69px',
      aspectRatio: 'auto',
    },
    breadCrumbs: BreadCrumbsTheme,
    productClearance: ProductClearanceTheme,
    imageSwatches: ImageSwatchesTheme,
    dropdown: {
      labelText: {
        style: {
          entry: 'bodyText',
          style: 'default',
        },
      },
    },
  },
}

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
  useTheme,
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<
  CombinedThemeInterface
>

export {
  createGlobalStyle,
  css,
  keyframes,
  styled,
  elementTheme as theme,
  ThemeProvider,
  useTheme,
  withTheme,
}
