import * as React from 'react'
export const SvgHumm = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    role="img"
    viewBox="0 0 361 72"
    width="80"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Humm</title>
    <path
      fill="#FF6C00"
      d="M323.511 20.3c-7.714 0-14.113 4.467-16.952 8.729-2.029-5.787-6.904-8.729-13.909-8.729-7.236 0-13.2 3.833-15.68 6.657a11.261 11.261 0 00-4.778-4.992c-2.319-1.364-5.239-2.03-8.637-2.03-1.873 0-3.672.269-5.357.73-6.023 1.128-10.87 4.81-13.243 8.364-2.029-5.787-6.903-8.729-13.909-8.729-7.714 0-14.01 4.365-16.141 7.21v-5.986h-15.632v49.032h15.632V38.88c1.626-2.029 4.568-4.772 8.728-4.772 4.772 0 6.501 2.845 6.501 6.598V70.55h15.631V38.879c1.128-1.508 3.103-3.403 5.787-4.283.516-.15 1.052-.274 1.611-.381a9.58 9.58 0 011.438-.108c3.747 0 5.664 1.836 6.27 4.499v31.95H276.632V38.73c1.648-2.013 4.547-4.622 8.61-4.622 4.772 0 6.495 2.845 6.495 6.598V70.55h15.632V38.879c1.525-2.029 4.568-4.772 8.831-4.772 4.67 0 6.495 2.845 6.495 6.598V70.55h15.535V35.025c0-10.049-5.685-14.724-14.719-14.724zM179.465 70.556h15.631V21.524h-15.631v31.672c-1.825 2.335-4.875 4.7729.341 4.772-5.078 0-8.122-2.029-8.122-7.918V21.524h-15.637V56.45c0 9.034 4.976 15.331 16.142 15.331 8.224 0 13.909-3.656 16.952-7.107v5.883h.006zM127.11 42.03c0-5.99-3.146-7.918-8.122-7.918-4.568 0-7.612 2.54-9.442 4.773v31.677H93.914V2.844h15.632v24.671c2.947-3.451 8.734-7.21 16.952-7.21 11.166 0 16.244 6.292 16.244 15.332v34.925H127.11V42.03zM76.285 1.98L42.43 35.838a1.358 1.358 0 01-1.922 0 1.358 1.358 0 010-1.922L64.17 10.253l-.521-.81C57.369 3.59 48.946 0 39.681 0c-9.695 0-18.477 3.924-24.844 10.264C12.55 12.588 5.1 12.98 0 12.394c3.156 4.054 6.238 10.222 5.577 14.06a35.276 35.276 0 00-1.095 8.739c0 19.438 15.756 35.193 35.193 35.193.204 0 .403-.005.607-.005v.005h34.592v-23.93C87.054 34.236 87.52 14.761 76.285 1.98z"
    />
    <path
      fill="#fff"
      d="M24.983 21.573a4.958 4.958 0 01-4.552-3 5.726 5.726 0 01-.398-2.04c.006-.124.011-.248.022-.371 0-.038.01-.075.01-.118a2.678 2.678 0 002.486 1.675 2.685 2.685 0 000-5.368c-.032 0-.064.01-.097.01.741-.44 1.6-.697 2.523-.697a4.953 4.953 0 014.955 4.954 4.948 4.948 0 01-4.95 4.955z"
    />
    <path
      fill="#FF6C00"
      d="M355.063 35.208l-1.746-3.146c.809-.283 1.708-1.079 1.708-2.569 0-1.592-1.079-2.85-2.979-2.85h-4.289v8.565h2.209V32.33h1.13l1.438 2.877h2.529zm-3.351-4.803h-1.746V28.57h1.746c.591 0 1.066.32 1.066.912 0 .603-.475.924-1.066.924z"
    />
    <path
      fill="#FF6C00"
      d="M358.086 23.912c-1.868-1.868-4.395-2.912-7.087-2.912s-5.219 1.044-7.087 2.912C342.044 25.78 341 28.308 341 31s1.044 5.22 2.912 7.088c1.868 1.868 4.395 2.912 7.087 2.912s5.219-1.044 7.087-2.912c1.868-1.868 2.912-4.396 2.912-7.088.055-2.637-.989-5.165-2.912-7.088zm-14.559 7.143c0 4.12 3.352 7.473 7.472 7.473 4.12 0 7.472-3.352 7.472-7.473 0-4.12-3.352-7.473-7.472-7.473-4.12 0-7.472 3.352-7.472 7.473z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgHumm
