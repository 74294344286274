import * as React from 'react'
export const SvgWechat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <defs>
      <linearGradient
        id="wechat_svg__a"
        x1="50.056%"
        x2="50.056%"
        y1="94.15%"
        y2=".437%"
      >
        <stop offset="0%" stopColor="#78D431" />
        <stop offset="100%" stopColor="#9EEE69" />
        <stop offset="100%" stopColor="#9EEE69" />
      </linearGradient>
      <linearGradient
        id="wechat_svg__b"
        x1="50.089%"
        x2="50.089%"
        y1="93.535%"
        y2="-.036%"
      >
        <stop offset="0%" stopColor="#E4E6E6" />
        <stop offset="100%" stopColor="#F5F5FF" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#wechat_svg__a)"
        d="M0 7.065c0 2.12 1.18 4.056 3.003 5.338.16.105.24.262.24.471 0 .053-.026.131-.026.183-.134.524-.375 1.387-.402 1.413-.027.079-.054.131-.054.21 0 .157.134.288.295.288a.387.387 0 00.161-.053l1.903-1.073a.92.92 0 01.456-.13c.08 0 .188 0 .268.026.885.261 1.85.392 2.842.392 4.799 0 8.686-3.166 8.686-7.065C17.372 3.166 13.485 0 8.686 0 3.887 0 0 3.166 0 7.065"
        transform="translate(0 2)"
      />
      <path
        fill="url(#wechat_svg__b)"
        d="M16.78 18.288c.828 0 1.63-.105 2.353-.316.053-.027.133-.027.214-.027a.88.88 0 01.374.106l1.578.896c.053.027.08.053.134.053.133 0 .24-.105.24-.237 0-.053-.026-.106-.026-.185 0-.026-.214-.738-.321-1.186-.027-.053-.027-.106-.027-.158 0-.159.08-.29.214-.396C23.037 15.758 24 14.15 24 12.356c0-3.269-3.236-5.932-7.22-5.932-3.986 0-7.222 2.637-7.222 5.932 0 3.27 3.236 5.932 7.221 5.932z"
        transform="translate(0 2)"
      />
      <path
        fill="#187E28"
        d="M6.907 6.784c0 .61-.493 1.094-1.116 1.094a1.097 1.097 0 01-1.117-1.094c0-.61.494-1.093 1.117-1.093s1.116.483 1.116 1.093m5.791 0c0 .61-.494 1.094-1.117 1.094a1.097 1.097 0 01-1.116-1.094c0-.61.493-1.093 1.116-1.093.623 0 1.117.483 1.117 1.093"
      />
      <path
        fill="#858C8C"
        d="M18.28 12.525c0 .532.433.957.976.957a.963.963 0 00.977-.957.963.963 0 00-.977-.957.963.963 0 00-.977.957m-4.814 0c0 .532.434.957.977.957a.963.963 0 00.977-.957.963.963 0 00-.977-.957.963.963 0 00-.977.957"
      />
    </g>
  </svg>
)
export default SvgWechat
