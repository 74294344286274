import * as React from 'react'
export const SvgElo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={60} height={40} {...props}>
    <path d="M60 35c0 2.75-2.25 5-5 5H5c-2.75 0-5-2.25-5-5V5c0-2.75 2.25-5 5-5h50c2.75 0 5 2.25 5 5z" />
    <path
      d="M55 0H5C2.25 0 0 2.25 0 5v30c0 2.75 2.25 5 5 5h50c2.75 0 5-2.25 5-5V5c0-2.75-2.25-5-5-5zm0 1c2.206 0 4 1.794 4 4v30c0 2.206-1.794 4-4 4H5c-2.206 0-4-1.794-4-4V5c0-2.206 1.794-4 4-4z"
      fill={props.fill || '#fff'}
      opacity={0.25}
    />
    <path
      d="M49.31 21.627a2.793 2.793 0 01-3.621 2.087l-.62 1.852a4.748 4.748 0 006.155-3.548zm-5.878 2.994l1.293-1.463a2.781 2.781 0 01-.941-2.091c0-.832.364-1.578.941-2.089l-1.294-1.463a4.734 4.734 0 00-1.6 3.552 4.73 4.73 0 001.601 3.554zm2.258-6.201a2.792 2.792 0 013.62 2.091l1.914-.39a4.745 4.745 0 00-6.152-3.553l.618 1.852zm-5.729-4.232v9.158l1.589.659-.752 1.806-1.572-.653a1.786 1.786 0 01-.774-.65c-.174-.27-.304-.639-.304-1.136v-9.183zm-7.24 2.138a4.743 4.743 0 00-4.046 7.331l8.59-3.672a4.742 4.742 0 00-4.544-3.659zm-2.846 5.036a2.792 2.792 0 014.907-2.095zm4.726 1.7a2.772 2.772 0 01-3.437.366l-1.038 1.654a4.703 4.703 0 002.452.729 4.72 4.72 0 003.385-1.35z"
      fill={props.fill || '#fff'}
    />
    <path
      d="M21.028 20.918a4.573 4.573 0 01-5.931 3.42l-1.016 3.035a7.774 7.774 0 0010.082-5.812z"
      fill={props.fill || '#ed412f'}
    />
    <path
      d="M11.398 25.821l2.12-2.397a4.562 4.562 0 01-1.543-3.425c0-1.363.597-2.586 1.543-3.423l-2.121-2.396A7.754 7.754 0 008.777 20a7.75 7.75 0 002.621 5.821"
      fill={props.fill || '#1aa5df'}
    />
    <path
      d="M15.097 15.662a4.577 4.577 0 015.932 3.424l3.135-.639a7.774 7.774 0 00-10.081-5.82z"
      fill={props.fill || '#ffca32'}
    />
    <path d="M56.249.8c1.654 0 3 1.435 3 3.2v32c0 1.765-1.346 3.199-3 3.199h-52.5c-1.654 0-3-1.434-3-3.199V4c0-1.765 1.346-3.2 3-3.2h52.5m0-.8h-52.5C1.687 0 0 1.8 0 4.001v32C0 38.2 1.688 40 3.749 40h52.5C58.312 40 60 38.2 60 36.001v-32C60 1.801 58.312 0 56.249 0" />
  </svg>
)
export default SvgElo
