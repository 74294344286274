import * as React from 'react'

import { AccountIcon } from '@thg-commerce/gravity-icons/src'

import { AccountIconWrapper, SubmenuAccountIcon } from '../styles'

export const AccountDropdownIcon = ({
  accountIcon,
}: {
  accountIcon: string
}) => {
  return (
    <AccountIconWrapper>
      {accountIcon ? (
        <SubmenuAccountIcon
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 47.43 47.43"
        >
          <path d={accountIcon}></path>
        </SubmenuAccountIcon>
      ) : (
        <AccountIcon />
      )}
    </AccountIconWrapper>
  )
}
