import * as React from 'react'
export const SvgLocationCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} {...props}>
    <g fill="#4285F4" fillRule="evenodd">
      <circle cx={12} cy={12} r={10} opacity={0.296} />
      <circle cx={12} cy={12} r={5.5} stroke="#FFF" />
    </g>
  </svg>
)
export default SvgLocationCircle
