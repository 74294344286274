import * as React from 'react'

import { NavigationItem, NavigationItemType } from '../../NavigationTree'
import { NavigationEventData } from '../Navigation'

import { Brand } from './Brands/Brands'
import { Brands } from './Brands'
import { CardNavigationContent } from './CardNavigationContent'
import { ImageCardNavigationContent } from './ImageCardNavigationContent'
import { ImageThumbnailContent } from './ImageThumbnailContent'
import { TextNavigationContent } from './TextNavigationContent'
import { WaterfallMenuDropdown } from './WaterfallMenuDropdown'

interface NavigationContentProps {
  item: NavigationItem
  brandsData: { brands: Brand[] }
  i18nText: {
    viewAllBrands: string
    navAriaLabel: string
  }
  routes: {
    viewAllBrandsLink: string
    listExtension: string
  }
  dynamicWidth: boolean
  hasWaterfallMenu: boolean
  enableThreeTierNav: boolean
  onClick?: (value?: string) => void
  enableAlternateNavGrid?: boolean
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  navigationOnClickCallback?: (data: NavigationEventData) => void
  navigationTopLevel?: string
  enableMasonryGridMenu?: boolean
}

export const NavigationContent = ({
  item,
  brandsData,
  i18nText,
  routes,
  dynamicWidth,
  hasWaterfallMenu,
  enableThreeTierNav,
  onClick,
  enableAlternateNavGrid,
  enableMasonryGridMenu,
  navSubLevelPromotionList,
  navLevelThreePromotionList,
  navigationOnClickCallback,
  navigationTopLevel,
}: NavigationContentProps) => {
  switch (item.type) {
    case NavigationItemType.TEXT:
      if (hasWaterfallMenu) {
        return (
          <WaterfallMenuDropdown
            showDetachedList={true}
            navigationItem={item}
          />
        )
      }
      return (
        <TextNavigationContent
          dynamicWidth={dynamicWidth}
          item={item}
          enableThreeTierNav={enableThreeTierNav}
          enableAlternateNavGrid={enableAlternateNavGrid}
          enableMasonryGridMenu={enableMasonryGridMenu}
          navSubLevelPromotionList={navSubLevelPromotionList}
          navLevelThreePromotionList={navLevelThreePromotionList}
          onClick={(e) => onClick && onClick(e)}
          navigationTopLevel={navigationTopLevel}
          navigationOnClickCallback={navigationOnClickCallback}
        />
      )
    case NavigationItemType.WATERFALL:
      return (
        <WaterfallMenuDropdown showDetachedList={false} navigationItem={item} />
      )
    case NavigationItemType.TEXT_CARD:
      return (
        <CardNavigationContent
          dynamicWidths={dynamicWidth}
          mobile={false}
          i18nHomeText={''}
          item={item}
        />
      )
    case NavigationItemType.IMAGE_CARD:
      return (
        <ImageCardNavigationContent
          dynamicWidths={dynamicWidth}
          mobile={false}
          i18nHomeText={''}
          item={item}
          {...(onClick && { onClick: () => onClick(item.displayName) })}
        />
      )
    case NavigationItemType.THUMBNAIL_CARD:
      return (
        <ImageThumbnailContent
          dynamicWidths={dynamicWidth}
          mobile={false}
          i18nHomeText={''}
          item={item}
          mobileImageHeight="44px"
          mobileImageWidth="44px"
        />
      )
    case NavigationItemType.BRANDS:
      if (brandsData?.brands) {
        return (
          <Brands
            brandsData={brandsData}
            i18nText={{
              viewAllBrands: i18nText.viewAllBrands,
              navAriaLabel: i18nText.navAriaLabel,
            }}
            viewAllBrandsLink={routes.viewAllBrandsLink}
            listExtension={routes.listExtension}
          />
        )
      }
      return null

    default:
      return (
        <TextNavigationContent
          dynamicWidth={dynamicWidth}
          item={item}
          enableThreeTierNav={enableThreeTierNav}
          enableAlternateNavGrid={enableAlternateNavGrid}
          navSubLevelPromotionList={navSubLevelPromotionList}
          navLevelThreePromotionList={navLevelThreePromotionList}
        />
      )
  }
}
