import * as React from 'react'
export const SvgDeliveryUnavailable = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg width={40} height={20} {...props}>
    <path
      fill={props.fill || '#ddd'}
      d="M7 14a3 3 0 110 6 3 3 0 010-6zm17 0a3 3 0 110 6 3 3 0 010-6zM20.024 0c.865 0 1.566.701 1.566 1.566v.862h4.771A5.639 5.639 0 0132 8.067v7.679c0 .692-.561 1.253-1.253 1.253h-2.234c.011-.123.017-.247.017-.372 0-2.258-1.899-4.089-4.24-4.089-2.343 0-4.242 1.831-4.242 4.089 0 .125.006.25.017.372h-8.902c.012-.123.018-.247.018-.372 0-2.258-1.899-4.089-4.241-4.089-2.342 0-4.241 1.831-4.241 4.089 0 .125.006.25.017.372h-1.15A1.567 1.567 0 010 15.432V1.566C0 .701.701 0 1.566 0h18.458zm7 5.005h-1.193v4.46h3.856V7.669a2.663 2.663 0 00-2.663-2.663z"
      fill-rule="evenodd"
    ></path>
  </svg>
)
export default SvgDeliveryUnavailable
