import * as React from 'react'

import { ChevronRight } from '@thg-commerce/gravity-icons/src'
import { KeyboardKeys } from '@thg-commerce/gravity-theme'

import { NavigationItem, NavigationLink } from '../../../../NavigationTree'
import {
  HeadingWrapper,
  StyledLevelThreeList,
  StyledLevelTwoList,
  StyledLink,
  WaterfallMenuWrapper,
} from '../styles'

import { WaterfallLink } from './WaterfallLink/'

export enum WaterfallLevel {
  two = 2,
  three = 3,
}

const Heading = (props: {
  displayText: string
  href?: string
  index?: number
  'data-testid'?: string
}) => {
  return (
    <HeadingWrapper>
      <StyledLink
        data-testid={props['data-testid']}
        href={props.href}
        data-level-three-index={props.index}
      >
        {props.displayText}
      </StyledLink>
    </HeadingWrapper>
  )
}

const getLevelTwoLinks = (
  navigationItems: NavigationItem[],
  setLevelThreeShowing: React.Dispatch<React.SetStateAction<number | null>>,
  levelThreeShowing?: number | null,
) => {
  return navigationItems.map((levelTwoNavigationItem, index) => {
    if (!levelTwoNavigationItem.subNavigation) {
      return (
        <WaterfallLink
          key={`level-two-link-${index}`}
          data-testid={`level-two-link-${index}`}
          href={levelTwoNavigationItem.link?.url}
          displayText={levelTwoNavigationItem.displayName}
          selectionHandler={() => {
            setLevelThreeShowing(null)
          }}
          index={index}
          level={WaterfallLevel.two}
        />
      )
    }
    return (
      <WaterfallLink
        key={`level-two-link-${index}`}
        data-testid={`level-two-link-${index}`}
        href={levelTwoNavigationItem.link?.url}
        displayText={levelTwoNavigationItem.displayName}
        selectionHandler={() => {
          setLevelThreeShowing(index)
        }}
        deselectionHandler={() => {
          setLevelThreeShowing(null)
        }}
        displayIcon={index === levelThreeShowing}
        levelThreeShowing={levelThreeShowing}
        index={index}
        level={WaterfallLevel.two}
      >
        <ChevronRight />
      </WaterfallLink>
    )
  })
}

const leaveLevelThree = (
  listKeyboardEvent: React.KeyboardEvent<HTMLUListElement | HTMLLIElement>,
  setLevelThreeShowing: React.Dispatch<React.SetStateAction<number | null>>,
  levelThreeShowing?: number | null,
) => {
  listKeyboardEvent.preventDefault()
  listKeyboardEvent.stopPropagation()
  setLevelThreeShowing(null)
  const nextElement = document.querySelector(
    `[data-link-index="${levelThreeShowing}"]`,
  ) as HTMLElement
  nextElement && nextElement.focus()
}

const levelThreeListEventHandler = (
  listKeyboardEvent: React.KeyboardEvent<HTMLUListElement>,
  setLevelThreeShowing: React.Dispatch<React.SetStateAction<number | null>>,
  levelThreeShowing?: number | null,
) => {
  switch (listKeyboardEvent.key) {
    case KeyboardKeys.Escape:
    case KeyboardKeys.ArrowLeft:
      leaveLevelThree(
        listKeyboardEvent,
        setLevelThreeShowing,
        levelThreeShowing,
      )
  }
}

const levelThreeLinkEventHandler = (
  levelThreeKeyEvent: React.KeyboardEvent<HTMLLIElement>,
  setLevelThreeShowing: React.Dispatch<React.SetStateAction<number | null>>,
  isLastLink: boolean,
  levelThreeShowing?: number | null,
) => {
  if (isLastLink) {
    leaveLevelThree(levelThreeKeyEvent, setLevelThreeShowing, levelThreeShowing)
  }
}

const getLevelThreeLinks = (
  levelTwoNavigationItems: NavigationItem[],
  setLevelThreeShowing: React.Dispatch<React.SetStateAction<number | null>>,
  levelThreeShowing?: number | null,
) => {
  return levelTwoNavigationItems.map(
    (levelTwoNavigationItem, levelTwoIndex) => {
      return (
        levelTwoNavigationItem.subNavigation && (
          <StyledLevelThreeList
            key={`level-three-list-${levelTwoIndex}`}
            data-testid="level-three-column"
            showing={levelTwoIndex === levelThreeShowing}
            onKeyDown={(listKeyboardEvent) => {
              levelThreeListEventHandler(
                listKeyboardEvent,
                setLevelThreeShowing,
                levelThreeShowing,
              )
            }}
          >
            <Heading
              displayText={levelTwoNavigationItem.displayName}
              href={levelTwoNavigationItem.link?.url}
              index={levelTwoIndex}
              data-testid={`level-three-heading-${levelTwoIndex}`}
            />
            {levelTwoNavigationItem.subNavigation.map(
              (levelThreeNavigationItem, levelThreeIndex) => {
                return (
                  <WaterfallLink
                    key={`level-three-link-${levelThreeIndex}`}
                    index={levelThreeIndex}
                    level={WaterfallLevel.three}
                    data-testid={`level-three-link-${levelTwoIndex}-${levelThreeIndex}`}
                    href={levelThreeNavigationItem.link?.url}
                    displayText={levelThreeNavigationItem.displayName}
                    handleLevelThreeKeyPress={(levelThreeLinkKeyEvent) =>
                      levelThreeLinkEventHandler(
                        levelThreeLinkKeyEvent,
                        setLevelThreeShowing,
                        levelTwoNavigationItem.subNavigation?.length ===
                          levelThreeIndex + 1,
                        levelThreeShowing,
                      )
                    }
                  />
                )
              },
            )}
          </StyledLevelThreeList>
        )
      )
    },
  )
}

export const WaterfallMenu = (props: {
  levelOneLink: { displayName: string; link: NavigationLink }
  navigationItems: NavigationItem[]
}) => {
  const [levelThreeShowing, setLevelThreeShowing] = React.useState<
    number | null
  >(null)

  return (
    <WaterfallMenuWrapper
      onMouseLeave={() => {
        setLevelThreeShowing(null)
      }}
    >
      <StyledLevelTwoList
        onKeyDown={(e) => {
          if (e.key === KeyboardKeys.Escape) {
            setLevelThreeShowing(null)
          }
        }}
      >
        <Heading
          displayText={props.levelOneLink.displayName}
          href={props.levelOneLink.link.url}
        />
        {getLevelTwoLinks(
          props.navigationItems,
          setLevelThreeShowing,
          levelThreeShowing,
        )}
      </StyledLevelTwoList>
      {getLevelThreeLinks(
        props.navigationItems,
        setLevelThreeShowing,
        levelThreeShowing,
      )}
    </WaterfallMenuWrapper>
  )
}
