import { HeaderComponent } from '@thg-commerce/gravity-patterns/Header/theme'
import { Alignment } from '@thg-commerce/gravity-patterns/Header/types'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing, zIndex, ZIndexLevel } from '@thg-commerce/gravity-theme'

import { HeaderZIndexLevel } from '../Header/types'

const ICON_WRAPPER_WIDTH = '48px'

enum SlotGridArea {
  SLOT_GRID_AREAS = 'slotsGridAreas',
  ALTERNATE_GRID_AREAS = 'alternateGridAreas',
}

export const Header = styled.header<{
  sticky: boolean
  extendBackground: boolean
  useAlternateGrid?: boolean
  enableThreeTierNav?: boolean
}>`
  display: grid;
  ${zIndex(HeaderZIndexLevel.Header)};
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.patterns.header.backgroundColor};
  height: ${(props) =>
    props.theme.patterns.header.headerLogo.mobile.headerHeight};
  width: 100%;
  border-bottom: ${(props) =>
    props.theme.patterns.header.navigation.borderBottom};
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) => props.enableThreeTierNav && `border-bottom: none`};
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    top: ${(props) =>
      props.sticky &&
      props.theme.patterns.header.enabledComponents.lg.includes(
        HeaderComponent.SUBMENU,
      )
        ? '-32px'
        : '0'};
    height: ${(props) =>
      props.useAlternateGrid
        ? `calc(${props.theme.patterns.header.headerLogo.desktop.verticalMargin} * 2 +
        ${props.theme.patterns.header.headerLogo.desktop.height})`
        : `calc((${props.theme.patterns.header.headerLogo.desktop.verticalMargin} * 2 +
        ${props.theme.patterns.header.headerLogo.desktop.height}) + ${props.theme.patterns.header.navigation.height})`};

    &::before {
      ${(props) =>
        props.extendBackground &&
        `
        content: '';
        position: absolute;
        width: 100%;
        background-color: ${
          props.theme.patterns.header.subNavigation.mobile.footerBackgroundColor
        };
        height: ${spacing(4)};
        ${zIndex(HeaderZIndexLevel.Background)};
        top: 0;
        left: 0;
        `}
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      ${zIndex(HeaderZIndexLevel.Background)};
      top: ${(props) =>
        `calc(${
          props.theme.patterns.header.headerLogo.desktop.verticalMargin
        } * ${props.enableThreeTierNav ? '4' : '2'} +
                ${props.theme.patterns.header.headerLogo.desktop.height})`};
      ${(props) =>
        `border-bottom: solid ${props.theme.patterns.header.bottomSeparator.color} ${props.theme.patterns.header.bottomSeparator.width};`}
      ${(props) =>
        props.enableThreeTierNav &&
        `height:${props.theme.patterns.header.navigation.threeTierNav.secondTier.height};
        background-color: ${props.theme.patterns.header.navigation.threeTierNav?.backgroundColor};
        margin-bottom: ${props.theme.patterns.header.navigation.threeTierNav.secondTier.height};
        `}
      ${(props) =>
        props.enableThreeTierNav &&
        `background-color: ${props.theme.patterns.header.navigation.threeTierNav?.backgroundColor}`};
    }
  }
`

export const Container = styled.div<{
  useAlternateGrid: boolean
  useTemplateRows: boolean
  enableThreeTierNav?: boolean
}>`
  ${zIndex(HeaderZIndexLevel.Header)};
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  display: -ms-grid;
  grid-template-areas: ${(props) =>
    props.theme.patterns.header[
      props.useAlternateGrid
        ? SlotGridArea.ALTERNATE_GRID_AREAS
        : SlotGridArea.SLOT_GRID_AREAS
    ].xs.area};
  grid-template-columns: ${(props) =>
    props.theme.patterns.header[
      props.useAlternateGrid
        ? SlotGridArea.ALTERNATE_GRID_AREAS
        : SlotGridArea.SLOT_GRID_AREAS
    ].xs.colSpan};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    grid-template-columns: ${(props) =>
      props.theme.patterns.header[
        props.useAlternateGrid
          ? SlotGridArea.ALTERNATE_GRID_AREAS
          : SlotGridArea.SLOT_GRID_AREAS
      ].lg.colSpan};
    grid-template-areas: ${(props) =>
      props.theme.patterns.header[
        props.useAlternateGrid
          ? SlotGridArea.ALTERNATE_GRID_AREAS
          : SlotGridArea.SLOT_GRID_AREAS
      ].lg.area};
    margin: ${(props) =>
      props.enableThreeTierNav ? `0 auto ${spacing(2)} auto` : '0 auto'};
    max-width: calc(${(props) => props.theme.site.siteWidth} - ${spacing(3)});
    align-items: ${(props) => props.theme.patterns.header.alignItems};

    ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
      grid-template-rows: ${(props) =>
        props.useTemplateRows && `${spacing(4)} auto`};
    }
  }
`

export const DesktopWrapper = styled.div`
  display: none;
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: contents;
  }
`

export const NavigationInnerWrapper = styled.div`
  ${zIndex(HeaderZIndexLevel.Header)};
  background: ${(props) => props.theme.patterns.header.backgroundColor};
`

export const SearchWrapper = styled.div<{
  mobileSearch: boolean
  fullWidth?: boolean
}>`
  grid-area: search;
  width: ${(props) => (props.fullWidth ? '100%' : ICON_WRAPPER_WIDTH)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: ${(props) => (props.mobileSearch ? 'fit-content' : '100%')};
    display: flex;
    justify-content: ${(props) => props.theme.patterns.header.search.alignment};
    align-items: center;
    margin-right: ${(props) =>
      props.theme.patterns.header.search.alignment === Alignment.flexEnd
        ? spacing(2)
        : '0'};
  }
`
export const ExposedSearchWrapper = styled.div`
  width: 100%;
  ${zIndex(ZIndexLevel.Base5)};

  border-top: ${(props) => props.theme.patterns.header.navigation.borderTop};

  border-bottom: ${(props) =>
    props.theme.patterns.header.navigation.borderBottom};

  form {
    border: none;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: none;
  }
`

export const AccountWrapper = styled.div`
  grid-area: account;
  width: ${ICON_WRAPPER_WIDTH};
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: 100%;
    ${(props) =>
      props.theme.patterns.header.account?.margin.bottom &&
      `margin-bottom:${props.theme.patterns.header.account.margin.bottom}`};
  }
`

export const AccountTriggerWrapper = styled.div`
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    display: inline-block;
    justify-content: center;
  }
`

export const AccountIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BasketWrapper = styled.div`
  grid-area: basket;
  width: ${ICON_WRAPPER_WIDTH};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: 100%;
    ${(props) =>
      props.theme.patterns.header.basket?.margin.bottom &&
      `margin-bottom:${props.theme.patterns.header.basket.margin.bottom}`};
  }
`
export const LinkWrapper = styled.div`
  grid-area: link;
  width: ${ICON_WRAPPER_WIDTH};
  display: flex;
  justify-content: center;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: 100%;
    ${(props) =>
      props.theme.patterns.header.link?.margin.bottom &&
      `margin-bottom:${props.theme.patterns.header.link.margin.bottom}`};
  }
`

export const WishlistWrapper = styled.div`
  grid-area: wishlist;
  width: ${ICON_WRAPPER_WIDTH};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) =>
      props.theme.patterns.header.wishlist?.margin.bottom &&
      `margin-bottom:${props.theme.patterns.header.wishlist.margin.bottom}`};
  }
`
export const FlyoutWrapper = styled.div`
  grid-area: flyout;
  width: ${ICON_WRAPPER_WIDTH};
`

export const SubmenuWrapper = styled.div`
  grid-area: submenu;
  position: relative;
  width: 100%;
`

export const SubmenuAccountIcon = styled.svg`
  height: ${(props) =>
    props.theme.patterns.header.subNavigation.dropdowns.account.icon.height};
  width: ${(props) =>
    props.theme.patterns.header.subNavigation.dropdowns.account.icon.width};
`

export const WishlistLink = styled.a`
  display: flex;
  justify-content: center;
  fill: ${(props) => props.theme.patterns.header.wishlist.icon.color};

  svg {
    width: ${(props) => props.theme.patterns.header.wishlist.icon.width};
    height: ${(props) => props.theme.patterns.header.wishlist.icon.height};
  }
`
