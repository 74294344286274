import { Request, Response, NextFunction } from 'express'

import { createStore } from './store'

type WithConfig<T> = Partial<T> & {
  brand?: string
  subsite?: string
}

interface CacheConfig {
  key: (req: WithConfig<Request>) => string
  ttlSeconds: number
  staleSeconds?: number
}

export const cacheMiddleware = (config: CacheConfig) => {
  const store = createStore<
    { req: Request; res: Response; next: NextFunction },
    { statusCode: number; headers: any; body: any }
  >({
    key: (args) => config.key(args.req),
    ttlSeconds: config.ttlSeconds,
    staleSeconds: config.staleSeconds,
    lookup: async (args) =>
      new Promise((resolve, reject) => {
        const write = args.res.write
        const end = args.res.end

        const chunks: any[] = []

        args.res.write = function (chunk: any) {
          chunks.push(chunk)
          return write(chunk)
        }

        args.res.end = function (chunk: any) {
          if (chunk) {
            chunks.push(chunk)
          }

          args.res.write = write
          args.res.end = end

          if (args.res.statusCode !== 200) {
            reject()
          } else {
            resolve({
              body: Buffer.concat(chunks).toString('utf8'),
              headers: args.res.getHeaders(),
              statusCode: args.res.statusCode,
            })
          }

          return this
        }

        args.next()
      }),
  })

  return async (req: Request, res: Response, next: NextFunction) => {
    const response = await store.get({
      req,
      res,
      next,
    })

    if (!response) {
      return res.status(500).end()
    }

    return res
      .status(response.statusCode)
      .set(response.headers)
      .end(response.body)
  }
}
