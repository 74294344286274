import {
  NavigationDataGA4,
  pushToEventGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'

interface InitialEvent {
  event: string
  navigation_element: string
  navigation_type: string
  menu_division?: string
  menu_sub_division?: string
  menu_department?: string
}

interface EventSchemaItem {
  propertyKey: string
  label: string
}

interface EventSchema {
  [key: string]: EventSchemaItem
}

export type EventData = {
  category?: string
  sub_category?: string
  department?: string
}
export const globalGA4 = (eventSchema: EventSchema, data: EventData) => {
  const initialObject: InitialEvent = {
    event: 'navigation',
    navigation_element: 'Main Menu',
    navigation_type: 'Main Menu Click',
  }
  const resultObject = Object.entries(eventSchema).reduce(
    (acc, [_, value]) => {
      const { propertyKey, label } = value

      if (propertyKey === 'category' && data.category) {
        acc[label] = data.category
      }

      if (propertyKey === 'sub_category' && data.sub_category) {
        acc[label] = data.sub_category
      }

      if (propertyKey === 'department' && data.department) {
        acc[label] = data.department
      }
      return acc
    },
    { ...initialObject },
  )

  pushToEventGA4<NavigationDataGA4>(resultObject)
}
