import * as React from 'react'
import { Property } from 'csstype'

import { css, styled } from '@thg-commerce/gravity-patterns/theme'
import { mq, spacing, Text } from '@thg-commerce/gravity-theme'

import HeaderDropdown, {
  HeaderDropdownProps,
} from '../HeaderDropdown/HeaderDropdown'
import { HeaderSlots } from '../HeaderSlots'
import { HeaderSlot } from '../types'

import { SessionSettingsTrigger } from './SessionSettingsTrigger/'

export interface SubmenuI18nText {
  sessionSettingsChangeText: string
  sessionSettingsYourSettingsAriaLabel: string
  sessionSettingsButtonOpenAriaLabel: string
  linkOneText?: string
  linkOneHref?: string
  linkTwoText?: string
  linkTwoHref?: string
  ariaLabel: string
  slotText?: { text: string; url: string }[]
}
export interface SubmenuProps {
  i18nText: SubmenuI18nText
  isDesktop: boolean
  showSessionSettings?: boolean
  sessionSettingsModal: (close?: () => void) => React.ReactElement
  sessionSettingsTriggerText: string
  showLinkOne?: boolean
  showLinkTwo?: boolean
  flagPath?: string
  accountDropdownProps: HeaderDropdownProps
  submenuSlots: HeaderSlot[]
}

const StyledSubmenu = styled.nav<{ isMobile: boolean }>`
  background-color: ${(props) =>
    props.theme.patterns.header.subNavigation.mobile.footerBackgroundColor};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    background-color: ${(props) =>
      props.theme.patterns.header.subNavigation.backgroundColor};

    ${(props) =>
      `border-bottom: solid ${props.theme.patterns.header.subNavigation.bottomSeparator.color} ${props.theme.patterns.header.subNavigation.bottomSeparator.width};`}
  }
`

const SubmenuContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    max-width: ${(props) => props.theme.site.siteWidth};
    width: 100%;
    height: 100%;
    padding: 0 ${spacing(2)};
    margin: 0 auto;
  }
`

const submenuButtonCommonStyles = css`
  ${(props) =>
    Text(
      props.theme.patterns.header.subNavigation.mobile.font.entry,
      props.theme.patterns.header.subNavigation.mobile.font.style,
    )};
  text-transform: ${(props) =>
    props.theme.patterns.header.subNavigation.mobile.font.transform};
  ${(props) =>
    props.theme.patterns.header.subNavigation.mobile.font.textColor &&
    `color: ${props.theme.patterns.header.subNavigation.mobile.font.textColor}`};
  display: flex;
  width: 100%;
  padding: ${(props) =>
    props.theme.patterns.header.navigation.subMenu.submenuLinksSpacing};
  text-decoration: ${(props) =>
    props.theme.patterns.header.subNavigation.mobile.font.textDecoration};
  border: 2px solid transparent;
  gap: ${spacing(1)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) =>
      Text(
        props.theme.patterns.header.subNavigation.desktop.font.entry,
        props.theme.patterns.header.subNavigation.desktop.font.style,
      )};
    text-transform: ${(props) =>
      props.theme.patterns.header.subNavigation.desktop.font.transform};
    text-decoration: ${(props) =>
      props.theme.patterns.header.subNavigation.desktop.font.textDecoration};
    display: flex;
    align-items: center;
    height: ${spacing(4)};
    padding: 0 ${spacing(2)};
    width: auto;
    ${(props) =>
      props.theme.patterns.header.subNavigation.desktop.font.textColor &&
      `color: ${props.theme.patterns.header.subNavigation.desktop.font.textColor}`};
  }

  &:hover,
  &:active,
  &:focus {
    ${(props) =>
      Text(
        props.theme.patterns.header.subNavigation.mobile.font.entry,
        props.theme.patterns.header.subNavigation.mobile.font.style,
      )};
    text-decoration: ${(props) =>
      props.theme.patterns.header.subNavigation.mobile.font.textDecoration};
    color: ${(props) =>
      props.theme.patterns.header.subNavigation.mobile.font.textColor};
    ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
      background-color: ${(props) =>
        props.theme.patterns.header.subNavigation.hoverColor};
      ${(props) =>
        Text(
          props.theme.patterns.header.subNavigation.desktop.hoverFont.entry,
          props.theme.patterns.header.subNavigation.desktop.hoverFont.style,
        )};
      text-transform: ${(props) =>
        props.theme.patterns.header.subNavigation.desktop.hoverFont.transform};
      text-decoration: ${(props) =>
        props.theme.patterns.header.subNavigation.desktop.hoverFont
          .textDecoration};
      ${(props) =>
        props.theme.patterns.header.subNavigation.desktop.hoverFont.textColor &&
        `color: ${props.theme.patterns.header.subNavigation.desktop.hoverFont.textColor}`};
    }
  }

  &:focus {
    outline: none;
    border: 2px solid
      ${(props) => props.theme.button.low.regular.focus.borderColor};
  }
`

const SubmenuLink = styled.a<{ color?: Property.Color }>`
  ${submenuButtonCommonStyles};
  ${(props) => props.color && `color: ${props.color}`};
`

const SubmenuAccountDropdown = styled(HeaderDropdown)``

export const SubmenuSlotLink = ({
  text,
  textColor,
  url,
  icon,
  ariaLabel,
}: {
  text?: string
  textColor?: Property.Color
  url?: string
  icon?: React.ReactElement
  ariaLabel?: string
}) => {
  return (
    <SubmenuLink href={url} color={textColor} aria-label={ariaLabel}>
      {icon || null}
      {text}
    </SubmenuLink>
  )
}

export const Submenu = (props: SubmenuProps) => {
  const sessionSettingsTriggerProps = {
    i18nText: {
      sessionSettingsYourSettingsAriaLabel:
        props.i18nText.sessionSettingsYourSettingsAriaLabel,
      sessionSettingsButtonOpenAriaLabel:
        props.i18nText.sessionSettingsButtonOpenAriaLabel,
      sessionSettingsChangeText: props.i18nText.sessionSettingsChangeText,
    },
    sessionSettingsModal: props.sessionSettingsModal,
    flagPath: props.flagPath,
    sessionSettingsTriggerText: props.sessionSettingsTriggerText,
    isMobile: !props.isDesktop,
  }

  return (
    <StyledSubmenu
      data-testid="submenu"
      aria-label={props.i18nText.ariaLabel}
      isMobile={!props.isDesktop}
    >
      <SubmenuContainer>
        <HeaderSlots
          headerType="submenu"
          i18nText={props.i18nText.slotText}
          slotConfig={props.submenuSlots}
          link={<SubmenuSlotLink />}
          accountDropdown={
            <SubmenuAccountDropdown
              inSubmenu={true}
              {...props.accountDropdownProps}
            />
          }
          sessionSettings={
            <SessionSettingsTrigger {...sessionSettingsTriggerProps} />
          }
        />
      </SubmenuContainer>
    </StyledSubmenu>
  )
}
