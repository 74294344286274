import * as React from 'react'

export interface LinkElement {
  type: 'link'
  props: React.LinkHTMLAttributes<HTMLLinkElement> & { key?: string }
}

export interface MetaElement {
  type: 'meta'
  props: React.MetaHTMLAttributes<HTMLMetaElement> & { key?: string }
}

type Elements = LinkElement | MetaElement

const identifierMap: { [key in Extract<Elements, 'type'>]: string[] } = {
  link: ['rel'],
  meta: ['property', 'name'],
}

const buildKey = (props: any, identifiers: string[]) => {
  return identifiers
    .map((identifier) => props[identifier] || null)
    .filter((identifier) => identifier)?.[0]
}

export const renderer = (elements: Elements[]) => {
  return elements.map((element) => {
    const key =
      element.props.key ||
      `${element.type}_${buildKey(element.props, identifierMap[element.type])}`
    return React.createElement(element.type, {
      ...element.props,
      key,
    })
  })
}
